import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getAllArtesFinalesPedidoPilotado } from '../../../api/carteleriaApi';
import { Buscador } from '../../Pedido/Buscador';
import ContainerDb from '../PedidoPilotado/ContainerDb';
import { FormatCompuestoContext } from '../../../context/FormatCompuestoContext';
import Checkbox from '@mui/material/Checkbox';
import { handleChangeInputSearch, handleClearInput, handleKeyDown } from '../../../helpers/buscadorHelper';
import ContainerSelected from './ContainerSelected';

const AddFormatCompuesto = ({ newFormat }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { aaffSelected, setAaffSelected, required, setRequired } = useContext(FormatCompuestoContext);

  const [valueSearch, setValueSearch] = useState('');
  const [valueSearchAfter, setValueSearchAfter] = useState(null);
  const [aaffArray, setAaffArray] = useState([]);
  const [page, setPage] = useState(1);

  // PETICION PARA TRAER TODOS LOS ARTES FINALES UNICAMENTE CON LA IMAGEN, CODIGO, NOMBRE Y FORMATOS
  const { data, isLoading, isFetching } = useQuery(['all-aaff-pedido-pilotado', page, valueSearchAfter], async () => {
    const token = await getAccessTokenSilently();
    return await getAllArtesFinalesPedidoPilotado(token, valueSearchAfter, page);
  });

  useEffect(() => {
    setAaffArray([]);
    if (page !== 1) {
      setPage(1);
    }
  }, [valueSearchAfter]);

  useEffect(() => {
    if (isFetching) return;

    if (aaffSelected?.length > 0 && data?.data?.length > 0) {
      aaffSelected.forEach((aaff) => {
        aaff.formats.forEach((format) => {
          const aaffIndex = data.data.findIndex((a) => a._id === aaff._id);
          if (aaffIndex === -1) return;
          const formatIndex = data.data[aaffIndex].formats.findIndex((f) => f._id === format._id);
          if (formatIndex === -1) return;

          data.data[aaffIndex].formats[formatIndex].selected = true;
        });
      });
    }

    // Filtrar data.data para evitar duplicados en aaffArray
    setAaffArray((prev) => {
      const filteredData = data.data.filter((newAaff) => {
        return !prev.some((existingAaff) => existingAaff._id === newAaff._id);
      });
      return [...prev, ...filteredData];
    });
  }, [isFetching, aaffSelected]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '1rem',
          height: '3.5rem',
        }}
      >
        <span>
          <Buscador
            label="Buscar por código o nombre"
            value={valueSearch}
            onChange={(e) => handleChangeInputSearch(e, setValueSearch)}
            onKeyDown={(e) => handleKeyDown(e, setValueSearchAfter)}
            onClear={() => handleClearInput(setValueSearch, setValueSearchAfter)}
            width="100"
          />
        </span>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Checkbox checked={required} onChange={() => setRequired(!required)} size="small" />
            <p style={{ margin: '0' }}>Obligatorio/s</p>
          </div>
          {required && (
            <span
              style={{
                opacity: '0.8',
                fontStyle: 'italic',
                textAlign: 'right',
              }}
            >
              * La cantidad indica cuántos formatos se asociarán al formato que se está creando *
            </span>
          )}
        </div>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
        {/* TODO: Renderizar todos los formatos/Artes disponibles para asociar */}
        {/* CONTENEDOR CON TODOS LOS ARTES DE LA DB */}
        <ContainerDb
          isLoading={isLoading}
          setDataArray={setAaffArray}
          dataArray={aaffArray}
          dataSelected={aaffSelected}
          data={data}
          isFetching={isFetching}
          setDataSelected={setAaffSelected}
          setPage={setPage}
          tienda={false}
          compuesto={true}
        />

        {/* SACAR A UN COMPONENTE INDEPENDIENTE */}
        <ContainerSelected
          required={required}
          setRequired={setRequired}
          newFormat={newFormat}
          aaffSelected={aaffSelected}
          aaffArray={aaffArray}
          setAaffArray={setAaffArray}
          setAaffSelected={setAaffSelected}
        />
      </div>
    </div>
  );
};

export default AddFormatCompuesto;
