import { IoMdAdd } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';

const NewMerchandisingCard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCreateNew = () => {
    navigate(`${location.pathname}/add-merchandising`);
  };

  return (
    <div onClick={handleCreateNew} className="files-card-container files-card-container__new-merchandising">
      <div className="new-merchandising">
        <IoMdAdd size="24px" />
        <div className="display-sb-c new-merchandising-title">Nueva guía</div>
      </div>
    </div>
  );
};

export default NewMerchandisingCard;
