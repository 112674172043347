import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MasterContext } from '../../context/MasterContext';
import ArteFinalSidebar from '../../Components2/Sidebar/ArteFinalSidebar';
import cls from 'classnames';
import { getAllCategories } from '../../api/carteleriaApi';
import CategoryRow from '../common/CategoryRow';

export const FilterMenuMaster = () => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    inputValueSearch,
    currentOption,
    validateStatus,
    statusSelected,
    centerTypeSelected,
    centerSelected,
    setSuperIdCategory,
    superIdCategory,
    categorySelected,
    setCategorySelected,
  } = useContext(MasterContext);

  const { data: allCategoriesParent } = useQuery(
    [
      'allCategoriesParent',
      {
        idParentCategory: superIdCategory?._id,
        validateStatus,
        statusSelected,
        centerTypeSelected,
        centerSelected: centerSelected?._id,
        inputValueSearch,
      },
    ],
    async () => {
      const token = await getAccessTokenSilently();
      return await getAllCategories(token, {
        idParentCategory: superIdCategory?._id,
        validateStatus,
        statusSelected,
        idCenterType: centerTypeSelected,
        centerSelected: centerSelected?._id
          ? {
              comunidad: centerSelected.comunidad,
              centerType: centerSelected.centerType,
              imagenAnterior: centerSelected.imagenAnterior,
              imagenNueva: centerSelected.imagenNueva,
            }
          : null,
        inputValueSearch,
        panelMaster: true,
      });
    },
  );

  const handleBackCategory = () => {
    // echar una categoria para atras y si ya no hay ninguna pues dejar un array vacio
    if (categorySelected.length > 1) {
      const newCategorySelected = categorySelected.slice(0, categorySelected.length - 1);
      setCategorySelected(newCategorySelected);
      setSuperIdCategory({
        _id: newCategorySelected[newCategorySelected.length - 1]._id,
        parentCategory: newCategorySelected[newCategorySelected.length - 1].parentCategory,
      });
    } else {
      setCategorySelected([]);
      setSuperIdCategory(null);
    }
  };

  const handleCategory = (category) => {
    const isCategorySelected = categorySelected.some((selectedCategory) => selectedCategory._id === category._id);

    if (!isCategorySelected) {
      setCategorySelected([...categorySelected, category]);
    }
  };

  const handleCategoryClick = (category) => {
    if (superIdCategory === category) {
      setSuperIdCategory(null);
      setCategorySelected([]);
    } else {
      setCategorySelected([category]);
      setSuperIdCategory({
        _id: category._id,
        parentCategory: category.parentCategory,
      });
      handleCategory(category);
    }
  };

  const containerCategoriesClass = cls({
    'container-categories': true,
    'test-animation': currentOption === 'categories',
  });

  return (
    <div className="container-filter-menu-master">
      <ArteFinalSidebar context={MasterContext} />
      <div className={containerCategoriesClass}>
        <div className="container-arrow-title">
          {categorySelected?.length > 0 && <ArrowBackIcon onClick={handleBackCategory} className="pointer" />}
          <Typography className="title-master-articulos-filter-category">MENÚ</Typography>
        </div>
        <div style={{ minHeight: '500px' }}>
          {allCategoriesParent?.data && (
            <div className="category-list">
              <ul style={{ listStyleType: 'none', padding: '1rem 0 0 0.5rem' }}>
                {allCategoriesParent?.data?.length === 0 ? (
                  <span className="not-more-subcategories">No hay más submenús para filtrar</span>
                ) : (
                  allCategoriesParent?.data.map((category) => (
                    //TODO: Componente por cada categoria
                    <CategoryRow key={category._id} category={category} handleCategoryClick={handleCategoryClick} />
                  ))
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
