import axios from 'axios';

const getUserByAuth0Id = async (token) => {
  const { data } = await axios.get('/api/v1/user/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data?.data;
};

const getCentersByAuth0Id = async (token) => {
  const { data } = await axios.get('/api/v1/user/me/centers', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data.data;
};

export { getUserByAuth0Id, getCentersByAuth0Id };
