import { FormControlLabel, Switch } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import AddFormatCompuesto from './AddFormatCompuesto';
import { FormatCompuestoContext } from '../../../context/FormatCompuestoContext';

const FormatoCompuesto = () => {
  const { compuestoValue, setCompuestoValue } = useContext(FormatCompuestoContext);

  return (
    <div>
      {/* SWITCH PARA CAMBIAR FORMATO DE COMPUESTO A SIMPLE */}
      <FormControlLabel
        label="Formato compuesto"
        control={<Switch checked={compuestoValue} onChange={() => setCompuestoValue(!compuestoValue)} />}
      />

      {/* COMPONENTE PARA LISTAR LOS FORMATOS ASOCIADOS */}
      {compuestoValue && <AddFormatCompuesto newFormat={true} />}
    </div>
  );
};

export default FormatoCompuesto;
