import { Typography } from '@mui/material';

const TitleModal = ({ title }) => {
  return (
    <Typography
      variant="h4"
      component="h2"
      align="center"
      sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
    >
      {title}
    </Typography>
  );
};

export default TitleModal;
