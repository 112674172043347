import React, { useContext, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { handleCreatePilotado } from '../../../helpers/PedidoPilotado/pedidoPilotado';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PilotadosContext } from '../../../context/PilotadosContext';

const NavigateSteps = ({ setCurrentStep, currentStep, steps, disabled }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { asociateData, setTiendaSelected, setAsociateData, setAaffSelected } = useContext(PilotadosContext);
  const { user, getAccessTokenSilently } = useAuth0();
  const [loadingCreate, setLoadingCreate] = useState(false);

  const handleClickSend = async () => {
    try {
      setLoadingCreate(true);

      // Obtener el token
      let token = await getAccessTokenSilently();

      // Mostrar mensaje de carga al usuario

      toast.loading('Creando pedido pilotado...', {
        id: 'loading-create-pilotado',
        duration: Infinity,
      });

      await handleCreatePilotado(asociateData, user, token);

      toast.dismiss('loading-create-pilotado');
      queryClient.invalidateQueries(['pedidos-pilotados']);
      toast.success('Pedido pilotado creado correctamente');
    } catch (error) {
      console.error('Error al crear el pedido pilotado: ', error.message);
    } finally {
      setLoadingCreate(false);
      setTiendaSelected([]);
      setAsociateData([]);
      setAaffSelected([]);
      // navigate('/admin/pilotados');
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          float: 'right',
          marginTop: '2rem',
          gap: '1rem',
        }}
      >
        <button
          onClick={() => setCurrentStep((prev) => prev - 1)}
          disabled={currentStep === 0 || loadingCreate}
          style={{
            padding: '.75rem 1rem',
            backgroundColor: '#3C4145',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          Atras
        </button>
        {
          // si es el ultimo step el boton seran de enviar
          currentStep === steps.length - 1 ? (
            <div className="button-send-pilotado-disabled">
              <button
                onClick={handleClickSend}
                style={{
                  padding: '.75rem 1rem',
                  backgroundImage:
                    disabled || loadingCreate
                      ? 'linear-gradient(95deg, rgb(150, 150, 150) 0%, rgb(120, 120, 120) 50%, rgb(100, 100, 100) 100%)' // Estilo para botón deshabilitado
                      : 'linear-gradient(95deg, rgb(242, 50, 50) 0%, rgb(233, 64, 87) 50%, rgb(200, 30, 30) 100%)', // Estilo normal del botón
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                }}
                disabled={disabled || loadingCreate}
              >
                Enviar
              </button>
            </div>
          ) : (
            <button
              onClick={() => setCurrentStep((prev) => prev + 1)}
              disabled={disabled}
              style={{
                padding: '.75rem 1rem',
                backgroundImage: disabled
                  ? 'linear-gradient(95deg, rgb(150, 150, 150) 0%, rgb(120, 120, 120) 50%, rgb(100, 100, 100) 100%)' // Estilo para botón deshabilitado
                  : 'linear-gradient(95deg, rgb(242, 50, 50) 0%, rgb(233, 64, 87) 50%, rgb(200, 30, 30) 100%)', // Estilo normal del botón
                color: 'white',
                border: 'none',
                borderRadius: '5px',
              }}
            >
              Siguiente
            </button>
          )
        }
      </div>

      {/* TOOLTIP PARA INDICAR QUE TANTO TODOS LOS CENTROS COMO ARTES DEBEN SER MAYOR A 0 EN ALGUNO DE SUS CAMPOS */}
      {disabled && (
        <Tooltip anchorSelect=".button-send-pilotado-disabled">
          <p
            style={{
              margin: '0',
            }}
          >
            Todos los centros y artes deben tener un valor mayor a 0
          </p>
        </Tooltip>
      )}
    </>
  );
};

export default NavigateSteps;
