import axios from 'axios';

export const getStatsMoreOrders = async () => {
  try {
    const response = await axios.get('/api/v1/orderitem/stats/more-orders');
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getStatsNoOrders = async (page) => {
  try {
    const response = await axios.get('/api/v1/orderitem/stats/no-orders', {
      params: { page },
    });
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};
