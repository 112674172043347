import { Button, Popover, Typography } from '@mui/material';

export const ConfirmChangeCenter = ({
  changeCenterPopover,
  handleCloseChangeCenterPopover,
  handleConfirmChangeCenter,
}) => {
  return (
    <Popover
      open={Boolean(changeCenterPopover)}
      anchorEl={changeCenterPopover}
      onClose={handleCloseChangeCenterPopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div style={{ padding: '16px', textAlign: 'center' }}>
        <Typography>
          Al cambiar de tienda, se vaciará tu carrito de la compra. ¿Estás seguro de que deseas proceder?
        </Typography>
        <Button variant="contained" color="error" onClick={() => handleConfirmChangeCenter()} style={{ margin: '8px' }}>
          Sí
        </Button>
        <Button
          variant="contained"
          onClick={handleCloseChangeCenterPopover}
          style={{ margin: '8px', background: '#333' }}
        >
          No
        </Button>
      </div>
    </Popover>
  );
};
