import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { useState, useRef } from 'react';
import { Autocomplete } from '@mui/material';
import { useContext } from 'react';
import { PartidaProyectoContext } from '../../context/PartidaProyectoContext';
import { useEffect } from 'react';
import { ViewerContext } from '../../context/ViewerContext';
import Viewer from '../../helpers/Viewer/Viewer';
import { filterViewer } from '../../helpers/viewerHelper';
import { ConfirmationPopover, VersionPopover } from './Popover/ShowInformation';
import { ProyectoContext } from '../../context/ProyectoContext';
import { updatePartidaProyecto } from '../../api/alcampoApi';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { useAuth0 } from '@auth0/auth0-react';
const BoxInfoPartidaProyecto = ({ data, idPartidaProyecto }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [casillaCantidadTotal, setCasillaCantidadTotal] = useState(data.price * data.quantity.quantity);
  const { isModelLoaded } = useContext(ProyectoContext);
  const [versionPopoverAnchorEl, setVersionPopoverAnchorEl] = useState(null);
  const [confirmationPopoverAnchorEl, setConfirmationPopoverAnchorEl] = useState(null);
  const [valueNewForTextField, setValueNewForTextField] = useState(null);
  const [elementSelectedFromMenuOptions, setelementSelectedFromMenuOptions] = useState(null);

  // useEffect(() => {
  //   if (data && isModelLoaded) {
  //     console.log('entro aquiiiiiiii');
  //     console.log('idddddddddddddd', idPartidaProyecto);
  //     const viewElementsInModel = async () => {
  //       console.log('testtt');
  //       console.log('partidaInfo.code', data.partida.code);
  //       if (!isModelLoaded) return;
  //       console.log('testtt22222');
  //       // const viewerInstance = new Viewer(data._id);
  //       const viewerInstance = new Viewer(idPartidaProyecto);
  //       console.log('viewerInstance', viewerInstance);
  //       const elementsFiltered = await viewerInstance.getDbIdsByCodes([data.partida.code]);
  //       console.log('superDbIds', elementsFiltered);
  //       // filterViewer(elementsFiltered);
  //     };
  //     data.partida.code && viewElementsInModel();
  //   }
  // }, [data, isModelLoaded]);

  const [selectedOption, setSelectedOption] = useState(data.quantity.quantity);
  console.log('quantity', data.quantity.quantity);
  const { quantityVersions } = useContext(PartidaProyectoContext);

  useEffect(() => {
    console.log('dropBox', quantityVersions);
    // console.log('quantityVersions', JSON.stringify(quantityVersions, null, 2));
  }, [quantityVersions]);

  const handleOptionChange = (event, newValue) => {
    if (newValue) {
      // Desenfocar el TextField después de un breve retraso

      // Actualiza el valor seleccionado manualmente
      setSelectedOption(newValue);

      // Abre el ConfirmationPopover
      handleConfirmationPopoverOpen(event, newValue);
    }
  };

  console.log(confirmationPopoverAnchorEl);

  useEffect(() => {
    console.log(' selectedOption: ', selectedOption);
    console.log('valueNewForTextField', valueNewForTextField);
    console.log('casillacCantidadTotal: ', casillaCantidadTotal);
  }, [selectedOption]);

  const handleVersionPopoverClose = () => {
    setVersionPopoverAnchorEl(null);
  };

  const handleConfirmationPopoverOpen = (event, newValue) => {
    setConfirmationPopoverAnchorEl(event.currentTarget);
    console.log('newValue', extractNumber(newValue)); // Obtengo la cantidad
    console.log('newValue2', newValue); // obtengo todo el texto cantidad -version..

    const quantityValue = parseInt(newValue.split('-')[0].trim());
    const versionValue = parseInt(newValue.split(':')[1].trim());

    console.log('cantidad future', quantityValue);
    console.log('version future', versionValue);
    const selectedObject = quantityVersions.find(
      // (item) => item.version.version == versionValue && item.quantity == quantityValue,
      (item) => item.version.version == versionValue,
    );

    console.log('selectedObject', selectedObject); // objeto seleccionado ✔
    setelementSelectedFromMenuOptions(selectedObject);
    setValueNewForTextField(newValue);
  };

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  const handleConfirmationPopoverClose = () => {
    setConfirmationPopoverAnchorEl(false);
  };

  // quantity asociada a esta PP: 64f1a68ed9078a64346fb752
  useEffect(() => {}, [valueNewForTextField]);
  const handleConfirmChange = async () => {
    let token = await getAccessTokenSilently();
    setSelectedOption(valueNewForTextField);
    updatePartidaProyecto(idPartidaProyecto, { quantity: elementSelectedFromMenuOptions._id }, token);
    setCasillaCantidadTotal(elementSelectedFromMenuOptions.quantity * data.price);
    handleConfirmationPopoverClose();

    // setCloseOver(true)
  };

  const extractNumber = (str) => {
    const parts = str?.split(' - ');
    if (parts?.length > 0) {
      const numberStr = parts[0];
      console.log('megaNumbers', numberStr);
      const handleCancelChange = () => {
        handleConfirmationPopoverClose();

        // inputRef.current.blur();
      };
      return numberStr;
    }
    return null;
  };
  const handleCancelChange = () => {
    handleConfirmationPopoverClose();
  };

  const isHighestVersion = (option) => {
    // Encuentro la version mas alta
    console.log('optionnnnnnnnnnnnn', option);
    const highestVersion = quantityVersions?.reduce(
      (maxVersion, versionInfo) => (versionInfo.version > maxVersion ? versionInfo.version : maxVersion),
      0,
    );
    console.log('isHighestVersion', highestVersion);
    return option.version == highestVersion;
  };

  const sumatorioPriceDescompuestos = data.partida.descompuestos.reduce((acumulator, el) => {
    return acumulator + el.price;
  }, 0); // TO: revisar si se actualiza siempre

  const [isFocused, setIsFocused] = useState(false);

  return (
    <Card variant="outlined">
      {' '}
      <CardContent>
        {/* <button onClick={extractNumber(selectedOption)}> test </button> */}
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <TextField
            disabled
            id="outlined-read-only-input-priceDescompuesto"
            label="Precio"
            value={data.price.toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            InputProps={{
              readOnly: true,
              endAdornment: `€/${data.unit}`,
              sx: {
                // textAlign: 'right',
              },
            }}
            variant="filled"
          />
          <TextField
            disabled
            id="outlined-read-only-input-editPrecioDescompuesto"
            autoFocus={false}
            label="Precio Descompuesto"
            value={sumatorioPriceDescompuestos.toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            onBlur={(event) => {
              if (confirmationPopoverAnchorEl && !confirmationPopoverAnchorEl.contains(event.relatedTarget)) {
                event.target.blur(); // Desenfoca el TextField si el foco no se dirige al ConfirmationPopover
              }
            }}
            InputProps={{
              readOnly: true,
              endAdornment: `€/${data.unit}`,
            }}
            sx={{ width: 'auto' }}
            variant="filled"
          />

          <div>
            <Autocomplete
              blurOnSelect
              value={selectedOption.toLocaleString('de-DE', {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4,
              })}
              onSelect={(event) => {
                event.preventDefault();
              }}
              onChange={(e, value) => handleOptionChange(e, value)}
              options={
                quantityVersions
                  ? quantityVersions
                      .sort((a, b) => b.version.version - a.version.version)
                      .map((versionInfo) => `${versionInfo.quantity} - Version: ${versionInfo.version.version}`)
                  : []
              }
              sx={{ width: 200 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cantidad"
                  InputProps={{
                    ...params.InputProps,
                    readOnly: true,
                    autoFocus: false,

                    // Aquí se controla el enfoque// Aquí se controla el enfoque
                  }}
                />
              )}
            />
            <div></div>
          </div>
          {elementSelectedFromMenuOptions &&
            elementSelectedFromMenuOptions.length > 0 &&
            isHighestVersion(elementSelectedFromMenuOptions.version.version) && (
              <VersionPopover anchorEl={selectedOption} onClose={handleVersionPopoverClose} />
            )}
          <TextField
            focused={isFocused}
            disabled
            id="outlined-read-only-input"
            label="Total"
            value={selectedOption.toLocaleString('de-DE', {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            })}
            InputProps={{
              readOnly: true,
              endAdornment: ' €',
            }}
            variant="filled"
          />
          <div
            style={{
              position: 'absolute',
              top: '2%', // Centra verticalmente
              left: '50%', // Centra horizontalmente
              transform: 'translate(-50%, -50%)',
              margin: '-300px', // Centra completamente
            }}
          >
            <ConfirmationPopover
              anchorEl={confirmationPopoverAnchorEl}
              onClose={handleConfirmationPopoverClose}
              onConfirm={handleConfirmChange}
              onCancel={handleCancelChange}
              tabIndex={0}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default BoxInfoPartidaProyecto;
