import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { ProyectoContext } from '../../context/ProyectoContext';
import { PartidasContext } from '../../context/PartidasContext';

const FilterByVersionModel = () => {
  const { availableVersionsByCurrentProject } = useContext(ProyectoContext);
  const { setCurrentVersion, currentVersion, setPartidaQuery, partidaQuery } = useContext(PartidasContext);

  const handleChange = async (e) => {
    setCurrentVersion(e.target.value);
  };

  useEffect(() => {
    console.log('VERSION ACTUAL', currentVersion);
    // cada vez que cambie la version seleccionada, se actualiza el filtro de partidas
    if (currentVersion != ' ') {
      setPartidaQuery({ ...partidaQuery, version: currentVersion });
    } else {
      const { version, ...rest } = partidaQuery;
      setPartidaQuery(rest);
    }
  }, [currentVersion]);

  // EL RETURN VA A SER UN SELECTOR DONDE APAREZCAN TODAS LAS VERSIONES DISPONIBLES DEL MODELO
  // Y AL HACER CLICK EN UNA DE ELLAS APARECERAN SOBRE EL FILTRO ACTIVO TODAS LAS PARTIDAS PROYECTO QUE
  return (
    <FormControl
      size="small"
      sx={{
        width: '100%',
      }}
    >
      <InputLabel id="demo-simple-select-helper-label-version" sx={{ fontSize: '12px' }}>
        Seleccionar versión
      </InputLabel>

      <Select
        style={{ borderColor: 'black' }}
        labelId="demo-simple-select-helper-label-version"
        defaultValue="price"
        value={currentVersion || ''}
        label="Ordenar por"
        color="secondary"
        displayEmpty
        onChange={handleChange}
        sx={{ height: '100%', fontSize: '12px', borderRadius: '8px' }}
        variant="outlined"
      >
        <MenuItem value=" " style={{ fontSize: '12px' }}>
          <em>Todas</em>
        </MenuItem>
        {availableVersionsByCurrentProject.map((version) => (
          <MenuItem value={version._id} style={{ fontSize: '12px' }} disabled={!version.existsGeometry}>
            {version.version}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterByVersionModel;
