import { Button } from '@mui/material';
import { useContext, useEffect } from 'react';
import { toggleDrawer, toggleTiendaModal } from '../../helpers/navbarHelper';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';

const NavbarUser = ({ centerConfirmed, setIsDrawerOpen, setIsTiendaModalOpen }) => {
  const { totalPrice, shoppingCart_v2 } = useContext(ShoppingCartContext);

  useEffect(() => {
    console.log('NavbarUser re-rendered with totalPrice:', totalPrice);
  }, [totalPrice]);

  return (
    <>
      <Button
        disabled={Object.keys(shoppingCart_v2).length === 0}
        color="error"
        variant="contained"
        onClick={() => toggleDrawer(setIsDrawerOpen)}
        startIcon={<LocalGroceryStoreIcon />}
      >
        {totalPrice.toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}{' '}
        €
      </Button>
      <Button
        style={{ backgroundColor: '#646463' }}
        variant="contained"
        onClick={() => toggleTiendaModal(setIsTiendaModalOpen)}
        startIcon={<LocationOnIcon />}
      >
        {centerConfirmed?.name || centerConfirmed?.calle}
      </Button>
    </>
  );
};

export default NavbarUser;
