import React, { useContext } from 'react';
import InfoArteFinalContainer from '../ArteFinalInfo/InfoArteFinalContainer';
import InfoArteFinalTitle from '../ArteFinalInfo/InfoArteFinalTitle';
import InfoArteFinalPrice from '../ArteFinalInfo/InfoArteFinalPrice';
import { numberToPrice } from '../../../../utils/numberFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../context/UserProvider';
import cls from 'classnames';
import DeleteFormat from './DeleteFormat';

const FormatItem = ({ formato, isCustom, numProviderFormat }) => {
  const { userRole } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  const handleRedirectToPageFormat = () => {
    if (location.pathname[location.pathname.length - 1] === '/') {
      navigate(`${location.pathname}format-provider/${formato._id}`);
    } else {
      navigate(`${location.pathname}/format-provider/${formato._id}`);
    }
  };

  return (
    <InfoArteFinalContainer
      classnames={cls(
        'item-arte-final-container',
        'item-arte-final-container--format',
        !formato.active ? 'item-arte-final-container--not-active' : '',
        userRole == 'MasterAdmin' && 'item-arte-final-container--pointer',
      )}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          onClick={handleRedirectToPageFormat}
          style={{ display: 'flex', flexDirection: 'column', gap: '.5rem', width: '100%', padding: '1rem 0 1rem 1rem' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <InfoArteFinalTitle classnames="display-sb-c">{formato.code}</InfoArteFinalTitle>
            <InfoArteFinalTitle style={{ color: '#757575' }}>{formato?.provider?.name}</InfoArteFinalTitle>
          </div>
          <div
            style={{ display: 'flex', justifyContent: isCustom ? 'flex-end' : 'space-between', alignItems: 'center' }}
          >
            {!isCustom && <InfoArteFinalPrice>{numberToPrice(formato.price)} €</InfoArteFinalPrice>}
            <DeleteFormat numProviderFormat={numProviderFormat} formato={formato} />
          </div>
        </div>
      </div>
    </InfoArteFinalContainer>
  );
};

export default FormatItem;
