import React, { useContext, useEffect } from 'react';
import { useProviderFormatByFormatAndProvider } from '../../hooks/useProviderFormat';
import ExsitsProviderFormat from './ExistsProviderFormat';
import NotExistsProviderFormat from './NotExsitsProviderFormat';
import { ArteFinalContext_v2 } from '../../context/ArteFinalContext_v2';

const AccordionChildrenFormat = ({ index, childrenFormat, providerSelected }) => {
  const { data: providerFormat } = useProviderFormatByFormatAndProvider(
    childrenFormat.formatoId,
    providerSelected,
    index,
  );

  const { openModalSelectChildrenFormat, setOpenModalSelectChildrenFormat, setProviderNotFound } =
    useContext(ArteFinalContext_v2);

  useEffect(() => {
    if (index === 0) return;

    if (!providerFormat.data) {
      setProviderNotFound(true);
      return;
    } else {
      setProviderNotFound(false);
    }

    // buscamos el childrenFormat correspondiente y le metemos el formatPrvider
    const newChildrenFormat = openModalSelectChildrenFormat.find(
      (format) => format.formatoId === childrenFormat.formatoId,
    );

    newChildrenFormat.providerFormat = providerFormat.data;
    newChildrenFormat.price = providerFormat.data.price;
    setOpenModalSelectChildrenFormat([...openModalSelectChildrenFormat]);
  }, [providerFormat]);

  return (
    <>
      {index === 0 || providerFormat.data ? (
        <ExsitsProviderFormat index={index} childrenFormat={childrenFormat} providerFormat={providerFormat} />
      ) : (
        <NotExistsProviderFormat childrenFormat={childrenFormat} provider={providerSelected} />
      )}
    </>
  );
};

export default AccordionChildrenFormat;
