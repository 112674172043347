import { Card, CardContent, Typography, Button } from '@mui/material';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import moment from 'moment';
import { useHandleUrl } from '../../hooks/useHandleUrl';

const ProjectCard = ({ data }) => {
  console.log(data);
  const { changeLocationWithNavigate } = useHandleUrl();

  const goToViewer = (idProject) => {
    changeLocationWithNavigate(`/viewer/${idProject}`);
  };

  return (
    <Card
      sx={{
        // maxWidth: 345,
        minHeight: 350,
      }}
    >
      <img
        src={data.thumbnail ? `data:image/png;base64,${data.thumbnail}` : './img/Image_not_available.png'}
        alt={'project_ ' + data.name + '_thumbnail'}
        style={{
          cursor: 'pointer',
          border: '1px solid black',
          width: '98%',
          height: '225px',

          objectFit: 'cover',
          margin: '1%',
        }}
        onClick={() => {
          goToViewer(data.item);
        }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          <HomeWorkOutlinedIcon /> {'  ' + data.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{ minHeight: '50px' }}>
          {data.description ? data.description : 'Sin descripción'}
        </Typography>
        <div style={{ height: '1px', backgroundColor: 'darkgray', marginTop: '10px' }} />
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <>
            <Typography
              style={{
                backgroundColor: '#dc3545',
                color: 'white',
                fontWeight: '600',
                padding: '5px',
                borderRadius: '3px',
              }}
            >
              V{data.versions[0]?.attributes?.versionNumber}
            </Typography>

            <Typography
              variant="body2"
              color="text.secondary"
              style={{ marginLeft: '5px', fontWeight: '600', marginTop: '2%' }}
            >
              Actualizado: {moment(data.versions[0]?.attributes?.lastModifiedTime).locale('es').format('LLL')}
            </Typography>
          </>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProjectCard;
