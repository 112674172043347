import { useGetFiles } from '../../../lib/react-query/fileQuery';
import SkeletonLoader from '../../common/SkeletonLoader';
import FileCard from './FileCard';

const AllFiles = () => {
  const { data: files, isLoading } = useGetFiles();

  return (
    <>
      {isLoading ? (
        <div className="grid-container">
          <SkeletonLoader totalColumns={4} minHeight="150px" />
        </div>
      ) : (
        <div className="grid-container">
          {files?.data?.map((file) => (
            <FileCard key={file._id} file={file} />
          ))}
        </div>
      )}
    </>
  );
};

export default AllFiles;
