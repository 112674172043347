import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ArteFinalContext } from '../../context/ArteFinalContext';
import ArteFinalSidebar from '../../Components2/Sidebar/ArteFinalSidebar';
import { UserContext } from '../../context/UserProvider';
import cls from 'classnames';
import { getAllCategories } from '../../api/carteleriaApi';
import CategoryRow from '../common/CategoryRow';

export const FilterMenu = ({ setCategorySelected, categorySelected }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentOption } = useContext(UserContext);
  const { inputValueSearch, superIdCategory, setSuperIdCategory, centerConfirmed } = useContext(ArteFinalContext);

  const { data: allCategoriesParent } = useQuery(
    ['allCategoriesParent', superIdCategory?._id, centerConfirmed?._id, inputValueSearch],
    async () => {
      const token = await getAccessTokenSilently();
      return await getAllCategories(token, {
        idParentCategory: superIdCategory?._id,
        centerSelected: {
          comunidad: centerConfirmed.comunidad._id,
          centerType: centerConfirmed.centerType,
          imagenAnterior: centerConfirmed.imagenAnterior,
          imagenNueva: centerConfirmed.imagenNueva,
        },
        inputValueSearch,
        panelMaster: false,
      });
    },
  );

  const handleCategory = (category) => {
    const isCategorySelected = categorySelected.some((selectedCategory) => selectedCategory._id === category._id);

    if (!isCategorySelected) {
      setCategorySelected([...categorySelected, category]);
    }
  };

  const handleCategoryClick = (category) => {
    if (superIdCategory === category) {
      setSuperIdCategory(null);
      setCategorySelected([]);
      // handleCategory(category);
      // setPage(1);
    } else {
      setCategorySelected([category]);
      setSuperIdCategory({
        _id: category._id,
        parentCategory: category.parentCategory,
      });
      handleCategory(category);
      // setPage(1);
    }
  };

  const handleBackCategory = () => {
    // echar una categoria para atras y si ya no hay ninguna pues dejar un array vacio
    if (categorySelected.length > 1) {
      const newCategorySelected = categorySelected.slice(0, categorySelected.length - 1);
      setCategorySelected(newCategorySelected);
      setSuperIdCategory({
        _id: newCategorySelected[newCategorySelected.length - 1]._id,
        parentCategory: newCategorySelected[newCategorySelected.length - 1].parentCategory,
      });
    } else {
      setCategorySelected([]);
      setSuperIdCategory(null);
    }
  };

  const containerCategoriesClass = cls({
    'container-categories': true,
    'test-animation': currentOption === 'categories',
  });

  return (
    <div className="container-filter-menu-master">
      <ArteFinalSidebar context={UserContext} />
      <div className={containerCategoriesClass}>
        <div className="container-arrow-title">
          {categorySelected?.length > 0 && <ArrowBackIcon onClick={handleBackCategory} className="pointer" />}
          <Typography className="title-master-articulos-filter-category">MENÚ</Typography>
        </div>
        <div style={{ minHeight: '500px' }}>
          {allCategoriesParent?.data && (
            <div className="category-list">
              <ul style={{ listStyleType: 'none', padding: '1rem 0 0 0.5rem' }}>
                {allCategoriesParent?.data?.length === 0 ? (
                  <span className="not-more-subcategories">No hay más submenús para filtrar</span>
                ) : (
                  allCategoriesParent?.data.map((category) => (
                    //TODO: Componente por cada categoria
                    <CategoryRow key={category._id} category={category} handleCategoryClick={handleCategoryClick} />
                  ))
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
