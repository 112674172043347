import React from 'react';

const CapituloTreeItem = ({ item, handleHeaders, header, last }) => {
  const handleClick = () => {
    handleHeaders(item);
  };

  return (
    <div style={{ fontSize: '12px' }} className="capitulo-tree-item" onClick={handleClick}>
      <p className={`mb-0 capitulo-tree-item__text ${!header.length == 0 && 'capitulo-tree-item__text--header'}`}>
        {item?.capitulo?.name}
      </p>
      <p className="mb-0" style={{ display: 'flex', alignItems: 'center', gap: '.3rem' }}>
        ({item.amountPartidas})
        <p className="mb-0" style={{ fontSize: '.7rem' }}>{`${item.import?.toLocaleString('de-DE', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 4,
        })}€`}</p>
      </p>
    </div>
  );
};

export default CapituloTreeItem;
