import toast from 'react-hot-toast';
import { MdEmail } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { sendEmailStatusOrder } from '../../api/carteleriaApi';
import { useAuth0 } from '@auth0/auth0-react';

const SendEmailStatusOrder = () => {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const handleOnClick = async () => {
    const token = await getAccessTokenSilently();
    toast.promise(sendEmailStatusOrder(token, id), {
      loading: 'Enviando correo...',
      success: 'Correo enviado',
      error: 'Error al enviar correo',
    });
  };

  return (
    <button onClick={handleOnClick} className="buttons-order-title">
      Enviar por correo estado actual del pedido <MdEmail size="15px" />
    </button>
  );
};

export default SendEmailStatusOrder;
