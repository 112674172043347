import React, { useContext, useEffect, useState } from 'react';
import { ProyectoContext } from '../../context/ProyectoContext';
import { CircularProgress } from '@mui/material';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import CardPartidaProject from './CardPartidaProject';
import { motion } from 'framer-motion';
import PaginationPartidas from '../Pagination/PaginationPartidas';

const ListPartidasProyecto = () => {
  const { fetchingPartidasProject, setFetchingPartidasProject, currentPartidasProject } = useContext(ProyectoContext);

  const [partidas, setPartidas] = useState(currentPartidasProject);

  useEffect(() => {
    setPartidas(currentPartidasProject);
  }, [currentPartidasProject]);

  const item = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      {fetchingPartidasProject ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <CircularProgress style={{ color: 'black' }} />
        </div>
      ) : partidas?.data?.length > 0 ? (
        <Row>
          {/* AQUI VA A IR EL TOTAL DE PARTIDAS */}
          <Colxx xxs="12">
            <h6 style={{ display: 'flex', paddingBottom: '1rem', fontStyle: 'italic', justifyContent: 'flex-end' }}>
              Total de partidas: {partidas.total}
            </h6>
          </Colxx>

          {partidas?.data?.map((partida, i) => (
            <Colxx xxs="6" key={i}>
              <motion.div variants={item} initial="hidden" animate="show" exit="hidden">
                <CardPartidaProject partida={partida} />
              </motion.div>
            </Colxx>
          ))}

          <Colxx xxs="12" style={{ position: 'absolute', bottom: '0' }}>
            <PaginationPartidas setLoadingPartidas={() => setFetchingPartidasProject(true)} />
          </Colxx>
        </Row>
      ) : (
        <div>
          <p>No hay partidas disponibles</p>
        </div>
      )}
    </>
  );
};

export default ListPartidasProyecto;
