import axios from 'axios';

export const uploadPdfArteFinalToDo = async (id, formData, token) => {
  try {
    const data = await axios.post(`/api/v1/artefinal/updatepdf/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      params: {
        id,
      },
    });
    return data;
  } catch (error) {
    console.error('Error al subir la imagen: ', error);
    throw new Error(error);
  }
};

export const deletePdfArteFinalToDo = async (id, body, token) => {
  try {
    const data = await axios.post(`/api/v1/artefinal/deletepdf/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        id,
      },
    });
    return data;
  } catch (error) {
    console.error('Error al subir la imagen: ', error);
    throw new Error(error);
  }
};

export const getInfoArteFinal = async (id, token) => {
  try {
    const data = await axios.get(`/api/v1/artefinal/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getInfoArteFinalEdit = async (id, token) => {
  try {
    const data = await axios.get(`/api/v1/artefinal/${id}/edit`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateArteFinalWithCategories = async (id, body, token) => {
  try {
    const data = await axios.patch(`/api/v1/artefinal/${id}/withcategories`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        id,
      },
    });
    return data;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const updateArteFinal = async (id, body, token) => {
  try {
    const data = await axios.patch(`/api/v1/artefinal/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        id,
      },
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getPdfLanguageByArteFinal = async (id) => {
  try {
    const data = await axios.get(`/api/v1/artefinal/${id}/pdf`);
    return data.data;
  } catch (err) {
    throw new Error(err);
  }
};
