import { Modal } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useParentCategoryName, useSubcategories } from '../../../hooks/useCategory';
import FormAddCategory from './FormAddCategory';
import SkeletonLoader from '../../common/SkeletonLoader';
import { handleClose } from '../../../helpers/Category/gestionCategories';
import TitleModal from '../../common/TitleModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postNewCategories } from '../../../api/categorieApi';
import toast from 'react-hot-toast';

const AddSubcategory = () => {
  const { idCategory } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { subcategories, isLoading } = useSubcategories(idCategory, false);
  const { parentCategoryName, isLoading: loadingParentName } = useParentCategoryName(idCategory);
  const mutation = useMutation(postNewCategories, {
    onSuccess: () => {
      queryClient.invalidateQueries(['gestion-categories', idCategory]);
    },
  });

  const toastInfo = () => {
    toast(
      `La categoría "${parentCategoryName.data.name}" no tiene subcategorías. Al crear nuevas subcategorías, los artículos se moveran a la primera subcategoría añadida.`,
      {
        duration: Infinity,
        id: 'no-categories-toast',
        icon: '⚠️',
      },
    );
  };

  const handleCloseModal = () => {
    toast.dismiss('no-categories-toast');
    handleClose(navigate);
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <div className="create-cluster-container-modal">
        <TitleModal title={'CREAR SUBCATEGORÍA'} />
        {isLoading || loadingParentName ? (
          <SkeletonLoader count={1} minHeight="350px" />
        ) : (
          <>
            <FormAddCategory
              handleClose={handleCloseModal}
              idCategory={idCategory}
              parentCategoryName={parentCategoryName}
              categories={subcategories.data}
              mutation={mutation}
            />
            {subcategories.data.length === 0 && toastInfo()}
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddSubcategory;
