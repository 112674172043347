import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useContext } from 'react';
import { Buscador } from '../Pedido/Buscador';
import { MasterContext } from '../../context/MasterContext';
import { UserContext } from '../../context/UserProvider';
import MerchandisingButton from './MerchandisingButton';
import { Colxx } from '../../components/common/CustomBootstrap';
import ManualUsoButton from './ManualUsoButton';
import { handleChangeInputSearch, handleClearInput, handleKeyDown } from '../../helpers/buscadorHelper';

export const HeaderMaster = ({ handleClicAddArte }) => {
  const { setInputValueSearch, inputAfter, setInputAfter } = useContext(MasterContext);
  const { userRole } = useContext(UserContext);

  return (
    <div className="header-container">
      <Colxx xxs="3" className="remove-margin-padding">
        <h2 className="font-size-1-resonsive">CATÁLOGO DE MATERIALES</h2>
      </Colxx>

      <Colxx xxs="9" className="df-jcsb remove-margin-padding">
        <Buscador
          label={'Buscar por nombre, código de artículo o código de formato'}
          value={inputAfter}
          width={'55'}
          onChange={(e) => handleChangeInputSearch(e, setInputAfter)}
          onKeyDown={(e) => handleKeyDown(e, setInputValueSearch)}
          onClear={() => handleClearInput(setInputValueSearch, setInputAfter)}
        />
        {userRole === 'MasterAdmin' ? (
          <>
            <ManualUsoButton path={'/manual-master/doc'} />
            <MerchandisingButton />
            <Button
              size="small"
              variant="contained"
              color="error"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleClicAddArte}
            >
              Artículo
            </Button>
          </>
        ) : (
          <span />
        )}
      </Colxx>
    </div>
  );
};
