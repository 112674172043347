import axios from 'axios';

export const createCompleteOrder = async (token, order) => {
  try {
    await axios.post('/api/v1/order/completeorder', order, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    throw new Error(err);
  }
};