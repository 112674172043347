export const ordenarPorPropiedad = propiedad => {
    return function (a, b) {
      const valorA =
        typeof a[propiedad] === 'string'
          ? a[propiedad].toLowerCase()
          : a[propiedad]
      const valorB =
        typeof b[propiedad] === 'string'
          ? b[propiedad].toLowerCase()
          : b[propiedad]
  
      if (valorA < valorB) {
        return -1
      }
      if (valorA > valorB) {
        return 1
      }
      return 0
    }
  }