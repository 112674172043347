import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getAllCategoriesWithoutFilters } from '../../../api/carteleriaApi';
import { useEffect } from 'react';
import { CategoryTreeItem } from './CategoryTreeItem';
import { useState } from 'react';

export const CategoryTree = ({ categorySelectedToCkeckBox, setCategorySelectedToCkeckBox }) => {
  const [newCategories, setNewCategories] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const { data: allCategoriesTotal, isFetching } = useQuery(['allCategoriesTotal'], async () => {
    const token = await getAccessTokenSilently();
    return await getAllCategoriesWithoutFilters(token);
  });

  useEffect(() => {
    if (!allCategoriesTotal) return;
    const superTransform = transformCategories(allCategoriesTotal);
    setNewCategories(superTransform);
  }, [allCategoriesTotal]);

  const transformCategories = (categories) => {
    const parentCategories = categories.filter((category) => !category.parentCategory);

    const getSubCategories = (parentId) => {
      return categories
        .filter((category) => category.parentCategory === parentId)
        .map((category) => ({
          ...category,
          children: getSubCategories(category._id),
        }));
    };

    const transformedCategories = parentCategories.map((category) => ({
      ...category,
      children: getSubCategories(category._id),
    }));

    return transformedCategories;
  };

  if (isFetching || !newCategories) return <div>Loading...</div>;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        gap: '0.6rem',
        // maxHeight: '400px',
        marginTop: '0.5rem',
      }}
      className="categoryTreeContainer"
    >
      {newCategories?.map((category, i) => (
        <CategoryTreeItem
          key={category._id}
          item={category}
          categorySelectedToCkeckBox={categorySelectedToCkeckBox}
          setCategorySelectedToCkeckBox={setCategorySelectedToCkeckBox}
        />
      ))}
    </div>
  );
};
