export const handleChangeQuantity = (e, data, format, setAaffSelected) => {
  setAaffSelected((prev) => {
    const index = prev.findIndex((a) => a._id === data._id);
    if (index === -1) return prev;
    const formatIndex = prev[index].formats.findIndex((f) => f._id === format._id);
    if (formatIndex === -1) return prev;
    prev[index].formats[formatIndex].quantity = e.target.value === '' ? 1 : parseInt(e.target.value);
    return [...prev];
  });
};

export const handleIncrementQuantity = (data, format, setAaffSelected) => {
  setAaffSelected((prev) => {
    const index = prev.findIndex((a) => a._id === data._id);
    if (index === -1) return prev;
    const formatIndex = prev[index].formats.findIndex((f) => f._id === format._id);
    if (formatIndex === -1) return prev;
    prev[index].formats[formatIndex].quantity = format.quantity + 1;
    return [...prev];
  });
};

export const handleDecrementQuantity = (data, format, setAaffSelected) => {
  format.quantity > 1 &&
    setAaffSelected((prev) => {
      const index = prev.findIndex((a) => a._id === data._id);
      if (index === -1) return prev;
      const formatIndex = prev[index].formats.findIndex((f) => f._id === format._id);
      if (formatIndex === -1) return prev;
      prev[index].formats[formatIndex].quantity = format.quantity - 1;
      return [...prev];
    });
};
