import { useSubcategories } from '../../../hooks/useCategory';
import { BsArrowReturnRight } from 'react-icons/bs';

const DeleteCategoryRow = ({ category }) => {
  const { subcategories } = useSubcategories(category._id, false);

  return (
    <div className="d-flex ai-center g-05 pl-1">
      <div className="d-flex ai-center g-05">
        <BsArrowReturnRight /> {category.name}
      </div>
      {subcategories?.data?.length > 0 && (
        <div className="d-flex ai-center g-05 pl-1">
          {subcategories.data.map((subcategory, index) => (
            <DeleteCategoryRow key={subcategory._id + index} category={subcategory} />
          ))}
        </div>
      )}
    </div>
  );
};

export default DeleteCategoryRow;
