import React from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import Page404 from '../Components2/Page404/Page404';
import ProtectedMain from '../check-auth/ProtectedMain';
import AlcampoLayout from '../layout/AlcampoLayout';
import ViewerLayout from '../layout/ViewerLayout';
import { MecPage, PartidaProyectoPage, MercadoPage } from '../pages';
import Main from '../views/app/alcampo/Main';
import { DataQuantityVersion } from '../Components2/Graphics/DataQuantityVersion';
import { FormProject } from '../Components2/Form/FormProject';
import SelectSection from '../pages/SelectSection';
import ProtectedProjects from '../layout/ProtectedProjects';
import Carteleria from '../pages/Carteleria';
import { ProtectedRolAdmin } from '../layout/ProtectedRolAdmin';
import { DetailOrder } from '../Components2/Admin/DetailOrder';
import { ContainerCenter } from '../ComponentsCarteleria/Centers/ContainerCenter';
import { MasterContainer } from '../ComponentsCarteleria/MasterRol/MasterContainer';
import { ArteFinalSpecification } from '../ComponentsCarteleria/MasterRol/ArteFinal/ArteFinalSpecification';
import CartPage from '../pages/CartPage';
import DetailOrderItem from '../Components2/Admin/DetailOrderItem';
import { DetailOrderProvider } from '../context/DetailOrderContext';
import Orders from '../pages/Orders';
import ProtectedUserRoute from '../layout/ProtectedUserRoute';
import { ProtectedMaster } from '../layout/ProtectedMaster';
import { PageModalFormat } from '../ComponentsCarteleria/MasterRol/Formatos/PageModalFormat';
import CreateCostesInstalacion from '../Components2/Admin/CreateCostesInstalacion';
import AddArteFinal from '../ComponentsCarteleria/MasterRol/ArteFinal/AddArteFinal';
import AddFormat from '../ComponentsCarteleria/MasterRol/Formatos/AddFormat';
import TabPilotado from '../Components2/Admin/tabs/TabPilotado';
import TabOrders from '../Components2/Admin/tabs/TabOrders';
import AddPedidoPilotado from '../ComponentsCarteleria/MasterRol/PedidoPilotado/AddPedidoPilotado';
import PedidoPilotado from '../ComponentsCarteleria/MasterRol/PedidoPilotado/PedidoPilotado';
import GestionTienda from '../ComponentsCarteleria/MasterRol/GestionTiendas/GestionTienda';
import { FormatCompuestoProvider } from '../context/FormatCompuestoContext';
import AddEditCenter from '../ComponentsCarteleria/MasterRol/GestionTiendas/AddEditCenter';
import AddEditClusters from '../ComponentsCarteleria/MasterRol/GestionClusters/AddEditClusters';
import GestionClusters from '../ComponentsCarteleria/MasterRol/GestionClusters/GestionClusters';
import ProtectedDocumentation from '../layout/ProtectedDocumentation';
import Documentation from '../layout/Documentation';
import { PilotadosProvider } from '../context/PilotadosContext';
import { OrdersFilterAdminProvider } from '../context/OrdersFilterAdminContext';
import EditCategory from '../ComponentsCarteleria/MasterRol/GestionCategories/EditCategory';
import DeleteCategory from '../ComponentsCarteleria/MasterRol/GestionCategories/DeleteCategory';
import AddSubcategory from '../ComponentsCarteleria/MasterRol/GestionCategories/AddSubcategory';
import AddCategory from '../ComponentsCarteleria/MasterRol/GestionCategories/AddCategory';
import GestionCategorias from '../ComponentsCarteleria/MasterRol/GestionCategories/GestionCategorias';
import { GestionCategoryProvider } from '../context/GestionCategoryContext';
import MasiveAddAaff from '../ComponentsCarteleria/MasterRol/GestionCategories/MasiveAddAaff';
import GestionMateriales from '../ComponentsCarteleria/MasterRol/GestionMaterials/GestionMateriales';
import AddMaterial from '../ComponentsCarteleria/MasterRol/GestionMaterials/AddMaterial';
import EditMaterial from '../ComponentsCarteleria/MasterRol/GestionMaterials/EditMaterial';
import { DeleteCategoryAuthProvider } from '../context/DeleteCategoryAuthContext';
import { MoveCategoryAuthProvider } from '../context/MoveCategoryAuthContext';
import CantMoveCategory from '../ComponentsCarteleria/MasterRol/GestionCategories/CantMoveCategory';
import ErrorDeleteMaterial from '../ComponentsCarteleria/MasterRol/GestionMaterials/ErrorDeleteMaterial';
import EditArteFinal from '../ComponentsCarteleria/MasterRol/ArteFinal/EditArteFinal';
import AddFormatProvider from '../ComponentsCarteleria/MasterRol/Formatos/AddFormatProvider';
import PageModalFormatProvider from '../ComponentsCarteleria/MasterRol/Formatos/PageModalFormatProvider';
import GestionProveedores from '../ComponentsCarteleria/MasterRol/GestionProveedores/GestionProveedores';
import EditProveedor from '../ComponentsCarteleria/MasterRol/GestionProveedores/EditProveedor';
import ErrorDeleteProveedor from '../ComponentsCarteleria/MasterRol/GestionProveedores/ErrorDeleteProveedor';
import AddProveedor from '../ComponentsCarteleria/MasterRol/GestionProveedores/AddProveedor';
import GestionArchivos from '../ComponentsCarteleria/MasterRol/GestionArchivos/GestionArchivos';
import EditFile from '../ComponentsCarteleria/MasterRol/GestionArchivos/EditFile';
import EditMerchandising from '../ComponentsCarteleria/MasterRol/GestionArchivos/EditMerchandising';
import ErrorDeleteMerchandising from '../ComponentsCarteleria/MasterRol/GestionArchivos/ErrorDeleteMerchandising';
import AddMerchandising from '../ComponentsCarteleria/MasterRol/GestionArchivos/AddMerchandising';
import TabStats from '../Components2/Admin/tabs/TabStats';
import MoreOrders from '../Components2/Admin/Stats/MoreOrders';
import NoOrders from '../Components2/Admin/Stats/NoOrders';
import MoreCentersOrders from '../Components2/Admin/Stats/MoreCentersOrders';
import MoreOrdersCenter from '../Components2/Admin/Stats/MoreOrdersCenter';

const childrenSolicitud = [
  {
    path: 'order-item/:idOrderItem',
    element: <DetailOrderItem />,
  },
  {
    path: 'add-costes-instalacion',
    element: <CreateCostesInstalacion />,
  },
];

export const router = createBrowserRouter([
  {
    element: <AlcampoLayout />,
    children: [
      {
        index: true,
        element: <SelectSection />,
      },
      {
        path: '*',
        element: <Page404 />,
      },
      {
        path: 'error',
        element: <Page404 />,
      },

      /* -------------------------------------------------------------------------- */
      /*                              RUTAS DEL USUARIO                             */
      /* -------------------------------------------------------------------------- */
      {
        path: 'carteleria',
        element: <ProtectedUserRoute />,
        children: [
          {
            index: true,
            element: <Carteleria />,
          },
          {
            path: 'center',
            element: <ContainerCenter />,
          },
          {
            path: 'cart',
            element: <CartPage />,
          },

          {
            path: 'orders',
            element: <Orders />,
          },
          {
            path: 'orders/:id',
            element: (
              <DetailOrderProvider>
                <DetailOrder />
              </DetailOrderProvider>
            ),
            children: [
              {
                path: 'order-item/:idOrderItem',
                element: <DetailOrderItem />,
              },
            ],
          },
        ],
      },

      /* -------------------------------------------------------------------------- */
      /*                               RUTAS DEL ADMIN                              */
      /* -------------------------------------------------------------------------- */
      {
        path: 'admin',
        element: <ProtectedRolAdmin requiredRole={['admin-signage']} />,
        children: [
          {
            index: true,
            element: <Navigate to="orders" />,
          },

          /* -------------------------------------------------------------------------- */
          /*                           SECCION PARA PILOTADOS                           */
          /* -------------------------------------------------------------------------- */
          {
            path: 'pilotados',
            element: (
              <PilotadosProvider>
                <Outlet />
              </PilotadosProvider>
            ),
            children: [
              {
                path: '',
                element: <TabPilotado />,
                children: [
                  {
                    path: 'add-pedido-pilotado',
                    element: <AddPedidoPilotado />,
                  },
                ],
              },
              {
                path: ':idPilotado',
                element: <PedidoPilotado />,
              },
              {
                path: ':idPilotado/:id',
                element: (
                  <DetailOrderProvider>
                    <DetailOrder admin={true} />
                  </DetailOrderProvider>
                ),
                children: childrenSolicitud,
              },
            ],
          },

          /* -------------------------------------------------------------------------- */
          /*                             SECCION PARA ORDERS                            */
          /* -------------------------------------------------------------------------- */
          {
            path: 'orders',
            element: (
              <OrdersFilterAdminProvider>
                <Outlet />
              </OrdersFilterAdminProvider>
            ),
            children: [
              {
                index: true,
                element: <TabOrders />,
              },
              {
                path: ':id',
                element: (
                  <DetailOrderProvider>
                    <DetailOrder admin={true} />
                  </DetailOrderProvider>
                ),
                children: childrenSolicitud,
              },
            ],
          },

          /* -------------------------------------------------------------------------- */
          /*                          SECCION PARA ESTADISTICAS                         */
          /* -------------------------------------------------------------------------- */
          {
            path: 'stats',
            element: <TabStats />,
            children: [
              {
                index: true,
                element: <Navigate to="more-orders" />,
              },
              {
                path: 'more-orders',
                element: <MoreOrders />,
              },
              {
                path: 'no-orders',
                element: <NoOrders />,
              },
              {
                path: 'more-centers',
                element: <MoreCentersOrders />,
              },
              {
                path: 'more-articles-center',
                element: <MoreOrdersCenter />,
              },
            ],
          },
        ],
      },

      /* -------------------------------------------------------------------------- */
      /*                SECCIÓN PARA EL MASTER (QUEDA REVISARLA BIEN)               */
      /* -------------------------------------------------------------------------- */

      {
        path: 'master',
        element: (
          <DeleteCategoryAuthProvider>
            <MoveCategoryAuthProvider>
              <ProtectedMaster requiredRole={['MasterAdmin', 'ViewMaster']} />
            </MoveCategoryAuthProvider>
          </DeleteCategoryAuthProvider>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="articulos" />,
          },
          {
            path: 'articulos',
            element: <MasterContainer />,
            children: [
              {
                path: 'add-artefinal',
                element: <AddArteFinal />,
              },
            ],
          },
          {
            path: 'articulos/:idArte',
            element: (
              <FormatCompuestoProvider>
                <ArteFinalSpecification />
              </FormatCompuestoProvider>
            ),
            children: [
              {
                path: 'edit',
                element: <EditArteFinal />,
              },
              {
                path: 'add-format-provider/:idFormat',
                element: <AddFormatProvider />,
              },
              {
                path: 'format-provider/:idProviderFormat',
                element: <PageModalFormatProvider />,
              },
              {
                path: 'add-format',
                element: <AddFormat />,
              },
              {
                path: 'formats/:idFormat',
                element: <PageModalFormat />,
              },
            ],
          },
          {
            path: 'tienda',
            element: <GestionTienda />,
            children: [
              { path: 'add-center', element: <AddEditCenter edit={false} /> },
              { path: ':centerId/edit-center', element: <AddEditCenter edit={true} /> },
            ],
          },
          {
            path: 'clusters',
            element: <GestionClusters />,
            children: [
              { path: 'add-clusters', element: <AddEditClusters edit={false} /> },
              { path: ':clusterId/edit-clusters', element: <AddEditClusters edit={true} /> },
            ],
          },
          {
            path: 'categorias',
            element: (
              <GestionCategoryProvider>
                <GestionCategorias />
              </GestionCategoryProvider>
            ),
            children: [
              {
                path: 'add-category',
                element: <AddCategory />,
              },
              {
                path: 'add-category/:idCategory',
                element: <AddSubcategory />,
              },
              {
                path: 'edit-category/:idCategory',
                element: <EditCategory />,
              },
              {
                path: 'add-masive-aaff/:idCategory',
                element: <MasiveAddAaff />,
              },
              {
                path: 'delete-category/:idCategory',
                element: <DeleteCategory />,
              },
              {
                path: 'cant-move/:idCategory/:idParentCategory',
                element: <CantMoveCategory />,
              },
            ],
          },
          {
            path: 'materiales',
            element: <GestionMateriales />,
            children: [
              {
                path: 'add-material',
                element: <AddMaterial />,
              },
              {
                path: ':idMaterial/edit-materiales',
                element: <EditMaterial />,
              },
              {
                path: ':idMaterial/error-delete-material',
                element: <ErrorDeleteMaterial type={'eliminar'} />,
              },
              {
                path: ':idMaterial/error-edit-material',
                element: <ErrorDeleteMaterial type={'desactivar'} />,
              },
            ],
          },
          {
            path: 'proveedores',
            element: <GestionProveedores />,
            children: [
              {
                path: 'add-provider',
                element: <AddProveedor />,
              },
              {
                path: ':idProvider/edit-providers',
                element: <EditProveedor />,
              },
              {
                path: ':idProvider/error-edit-provider',
                element: <ErrorDeleteProveedor type={'desactivar'} />,
              },
              {
                path: ':idProvider/error-delete-provider',
                element: <ErrorDeleteProveedor type={'eliminar'} />,
              },
            ],
          },
          {
            path: 'archivos',
            element: <GestionArchivos />,
            children: [
              {
                path: ':idFile/error-delete-file',
                element: <ErrorDeleteMerchandising />,
              },
              {
                path: 'edit/:idFile',
                element: <EditFile />,
              },
              {
                path: 'edit/:idFile/merchandising',
                element: <EditMerchandising />,
              },
              {
                path: 'add-merchandising',
                element: <AddMerchandising />,
              },
            ],
          },
        ],
      },

      /* -------------------------------------------------------------------------- */
      /*                               MANUALES DE USO                              */
      /* -------------------------------------------------------------------------- */
      {
        path: 'manual-user',
        element: <ProtectedDocumentation userRoles={['user-signage']} />,
        children: [
          {
            path: 'doc',
            element: <Documentation docPath="/manual-user/index.html" />,
          },
          // Agrega más rutas de documentación aquí si es necesario
        ],
      },
      {
        path: 'manual-master',
        element: <ProtectedDocumentation userRoles={['MasterAdmin']} />,
        children: [
          {
            path: 'doc',
            element: <Documentation docPath="/manual-master/index.html" />,
          },
          // Agrega más rutas de documentación aquí si es necesario
        ],
      },
      {
        path: 'manual-admin',
        element: <ProtectedDocumentation userRoles={['admin-signage']} />,
        children: [
          {
            path: 'doc',
            element: <Documentation docPath="/manual-admin/index.html" />,
          },
          // Agrega más rutas de documentación aquí si es necesario
        ],
      },

      /* -------------------------------------------------------------------------- */
      /*                            SECCION DE PROYECTOS                            */
      /* -------------------------------------------------------------------------- */
      {
        element: <ProtectedProjects />,
        children: [
          {
            path: '/projects',
            element: <Main />,
          },
          {
            path: 'viewer/:idItem',
            element: <ViewerLayout />,
            children: [
              {
                path: '',
                element: <Navigate to="mec" />,
              },
              {
                path: 'mec',
                element: <MecPage />,
              },
              {
                path: 'mercado',
                element: <MercadoPage />,
              },
              {
                path: 'mec/partida/:idPartidaProyecto',
                element: <PartidaProyectoPage />,
              },
            ],
          },
          {
            path: 'add-project',
            element: <FormProject />,
          },
          {
            path: 'partidaProyecto/:id',
            element: <ProtectedMain component={<DataQuantityVersion />} />,
          },
        ],
      },
    ],
  },
]);
