import React, { useEffect } from 'react';
import { ContainerArte } from '../ComponentsCarteleria/Pedido/ContainerArte';
import { useContext } from 'react';
import { UserContext } from '../context/UserProvider';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import '../css/user.css';
import { getCentersByAuth0Id } from '../api/userApi';

const Carteleria = () => {
  const navigate = useNavigate();
  const [matchCenterConfirmated, setMatchCenterConfirmated] = useState(null);
  const { centerConfirmed } = useContext(UserContext);
  const { getAccessTokenSilently } = useAuth0();
  const { data } = useQuery(['centerConfirmed'], async () => {
    const token = await getAccessTokenSilently();
    return await getCentersByAuth0Id(token);
  });

  useEffect(() => {
    if (data && centerConfirmed) {
      const matchCenter = data?.find(
        (el) => el?.centerType === centerConfirmed?.centerType && el?._id === centerConfirmed?._id,
      );
      setMatchCenterConfirmated(matchCenter);
      if (!matchCenter) {
        localStorage.setItem('centerConfirmed', null);
        navigate('/');
      }
    } else if (!centerConfirmed) {
      navigate('/carteleria/center');
    }
  }, [centerConfirmed, data]);

  return matchCenterConfirmated && <ContainerArte />;
};

export default Carteleria;
