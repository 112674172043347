import { IconButton } from '@mui/material';
import { GrDocumentText } from 'react-icons/gr';
import { IoIosLink, IoMdTrash } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeleteFile } from '../../../hooks/useFiles';
import DeletePopover from '../../common/DeletePopover';

const MerchandisingCard = ({ file }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { handleDeleteClick, deletePopover, handleDeleteClose, handleDeleteConfirm } = useDeleteFile();

  const handleEditFile = () => {
    navigate(`${location.pathname}/edit/${file._id}/merchandising`);
  };

  const handleGoToFile = () => {
    window.open(file.link, '_blank');
  };

  return (
    <div className="files-card-container files-card-container__merchandising">
      <div className="merchandising-title-container">
        <div onClick={handleGoToFile} className="files-card-icon-container files-card-icon-container__merchandising">
          <GrDocumentText className="file-card-icon" color="black" />
        </div>
        <h5>{file.name}</h5>
      </div>
      <div>
        <IconButton onClick={handleEditFile}>
          <IoIosLink size="20px" />
        </IconButton>
        <IconButton id={file._id} onClick={handleDeleteClick}>
          <IoMdTrash size="20px" />
        </IconButton>
      </div>
      <DeletePopover
        textCancel={'No'}
        textConfirm={'Sí'}
        textQuestion={
          '¿Estás seguro de que deseas eliminar esta guía? Una vez eliminada, no se podrá volver a utilizar en la aplicación.'
        }
        handleDeleteClose={handleDeleteClose}
        deletePopover={deletePopover}
        handleDeleteConfirm={handleDeleteConfirm}
      />
    </div>
  );
};

export default MerchandisingCard;
