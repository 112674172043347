export const CHARTS = [
  {
    label: 'Gráfica de barras',
    value: 'bar',
  },
  {
    label: 'Gráfica de líneas',
    value: 'line',
  },
  {
    label: 'Gráfica de área',
    value: 'area',
  },
];
