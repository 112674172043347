import React from 'react';
import { handleRemoveFormat } from '../../../../helpers/PedidoPilotado/pedidoPilotado';
import { Tooltip } from 'react-tooltip';

const SelectedContentArteFinal = ({ aaffArray, aaffSelected, setAaffArray, setAaffSelected }) => {
  return aaffSelected.map((aaff, i) => (
    <div key={aaff._id}>
      <div
        className="aaff-pedido-pilotado"
        style={{
          height: '2rem',
          backgroundColor: '#F7F7F7',
          fontWeight: 'bold',
          borderBottom: i === aaffArray.length - 1 ? 'none' : '1px solid #cecece',
          display: 'flex',
          alignItems: 'center',
          userSelect: 'none',
          gap: '.5rem',
          paddingLeft: '1rem',
          width: '100%',
        }}
      >
        {/* <img
    style={{ width: '5rem', height: '5rem', objectFit: 'contain' }}
    src={aaff.imglink || '/Image_not_available.png'}
    alt={`Image ${i}`}
  /> */}
        <p
          className={`texto-aaff-pedido-pilotado ${
            (aaff.code + ' - ' + aaff.name).length > 53 && `aaff-pedido-pilotado-tooltip-${aaff._id}`
          }`}
          id={`texto-aaff-pedido-pilotado-${aaff._id}`}
        >
          {aaff.code} - {aaff.name}
        </p>
      </div>

      <Tooltip anchorSelect={`.aaff-pedido-pilotado-tooltip-${aaff._id}`}>{aaff.code + ' - ' + aaff.name}</Tooltip>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {aaff.formats.map((format) => (
          <div
            onClick={() => handleRemoveFormat({ setAaffArray, aaffArray, aaff, format, aaffSelected, setAaffSelected })}
            className="format-pedido-pilotado"
            key={format._id}
            style={{
              height: '3rem',
              borderBottom: i === aaffArray.length - 1 ? 'none' : '1px solid #cecece',
              display: 'flex',
              alignItems: 'center',
              userSelect: 'none',
              gap: '.5rem',
              padding: '0 1.5rem',
              cursor: 'pointer',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <span>{format.code + ' - ' + format.name}</span>
              <span>{format.price.toFixed(2) + ' €'}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  ));
};

export default SelectedContentArteFinal;
