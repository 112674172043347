export const LIMIT = 36;

export const IMPRIMIBLES_LANGUAGES = [
  {
    label: 'Castellano',
    property: 'castellano',
  },
  {
    label: 'Catalán',
    property: 'Cataluña',
  },
  {
    label: 'Gallego',
    property: 'Galicia',
  },
  {
    label: 'Euskera',
    property: 'País Vasco',
  },
];
