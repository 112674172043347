import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import { handleSelectCenterType, handleSelectFavouriteCenterType } from '../../../../helpers/Cluster/clusterHelper';
import toast from 'react-hot-toast';
import { CategoryTree } from '../../Tree/CategoryTree';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { useUpdateArteFinal } from '../../../../hooks/useArteFinal';
import { useFormik } from 'formik';

const EditFormArteFinalImprimible = ({ data, allCenterTypes, onClose }) => {
  const { idArte } = useParams();
  const { mutation } = useUpdateArteFinal(idArte);

  const initialValues = {
    code: data.arteFinal.code,
    name: data.arteFinal.name,
    centerType: data.arteFinal.centerType,
    favourite: data.arteFinal.favourite,
    category: data.category,
    isImagenNueva: data.arteFinal.isImagenNueva,
    isImagenAnterior: data.arteFinal.isImagenAnterior,
    active: data.arteFinal.active,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      mutation.mutate(values);
      onClose();
    },
  });

  const selectCenterType = (e) => {
    handleSelectCenterType(e, formik, allCenterTypes);
  };

  const selectFavourite = (e) => {
    handleSelectFavouriteCenterType(e, formik);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="form-create-arte-final">
      <div className="form-create-arte-final-div1">
        <div className="form-create-arte-final-div2">
          <div className="inputs-edit-modal-row-container">
            <Colxx xxs="6">
              <TextField
                id="code"
                name="code"
                label="Código"
                variant="outlined"
                required
                fullWidth
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helpertext={formik.touched.code && formik.errors.code}
              />
            </Colxx>
            <Colxx xxs="6">
              <TextField
                id="name"
                name="name"
                label="Nombre"
                required
                variant="outlined"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helpertext={formik.touched.name && formik.errors.name}
              />
            </Colxx>
          </div>
          <div className="inputs-edit-modal-row-container">
            <Colxx xxs="6">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="centerType-label">Cluster *</InputLabel>
                {allCenterTypes && (
                  <Select
                    labelId="centerType-label"
                    name="centerType"
                    label="Cluster *"
                    style={{ maxWidth: '550px' }}
                    value={formik.values.centerType}
                    onChange={selectCenterType}
                    error={formik.touched.centerType && Boolean(formik.errors.centerType)}
                    helpertext={formik.touched.centerType && formik.errors.centerType}
                    multiple
                    renderValue={(selected) => {
                      return selected
                        .map((selectedId) => {
                          const selectedType = allCenterTypes?.find((centerType) => centerType._id === selectedId).type;
                          return selectedType;
                        })
                        .join(', ');
                    }}
                  >
                    <MenuItem value="all">
                      <Checkbox
                        checked={formik.values.centerType.length === allCenterTypes.length}
                        indeterminate={
                          formik.values.centerType.length > 0 && formik.values.centerType.length < allCenterTypes.length
                        }
                      />
                      <ListItemText primary="Seleccionar/Deseleccionar todos" />
                    </MenuItem>
                    {allCenterTypes?.map((centerType) => (
                      <MenuItem key={centerType._id} value={centerType._id}>
                        <Checkbox checked={formik.values.centerType.indexOf(centerType._id) > -1} />
                        <ListItemText primary={centerType.type} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Colxx>
            <Colxx xxs="6">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="favourite-label">Favorito</InputLabel>
                {allCenterTypes && (
                  <Select
                    disabled={formik.values.centerType.length === 0}
                    labelId="favourite-label"
                    name="favourite"
                    label="Favorito"
                    style={{ maxWidth: '550px' }}
                    value={formik.values.favourite}
                    onChange={selectFavourite}
                    error={formik.touched.favourite && Boolean(formik.errors.favourite)}
                    helpertext={formik.touched.favourite && formik.errors.favourite}
                    multiple
                    renderValue={(selected) => {
                      return selected
                        .map((selectedId) => {
                          const selectedType = allCenterTypes.find((centerType) => centerType._id === selectedId)?.type;
                          return selectedType;
                        })
                        .join(', ');
                    }}
                    onOpen={() => {
                      if (formik.values.centerType.length === 0) {
                        toast.error('No hay opciones disponibles. Por favor, selecciona al menos un cluster');
                      }
                    }}
                  >
                    <MenuItem
                      value="all"
                      onClick={() => {
                        const allSelected = formik.values.centerType.every((centerTypeId) =>
                          formik.values.favourite.includes(centerTypeId),
                        );
                        if (allSelected) {
                          formik.setFieldValue('favourite', []);
                        } else {
                          const selectedIds = formik.values.centerType;
                          formik.setFieldValue('favourite', selectedIds);
                        }
                      }}
                    >
                      <Checkbox
                        checked={formik.values.favourite.length === formik.values.centerType.length}
                        indeterminate={
                          formik.values.favourite.length > 0 &&
                          formik.values.favourite.length < formik.values.centerType.length
                        }
                      />
                      <ListItemText primary="Seleccionar/Deseleccionar todos" />
                    </MenuItem>
                    {allCenterTypes
                      .filter((centerType) => formik.values.centerType.includes(centerType._id))
                      .map((centerType) => (
                        <MenuItem key={centerType._id} value={centerType._id}>
                          <Checkbox checked={formik.values.favourite.indexOf(centerType._id) > -1} />
                          <ListItemText primary={centerType.type} />
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </FormControl>
            </Colxx>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', padding: '1rem', width: '100%' }}>
            <Colxx xxs="4">
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  htmlFor="imagenNueva"
                  shrink={formik.values.regional !== null || formik.values.regional !== ''}
                >
                  Es imagen nueva
                </InputLabel>
                <Select
                  id="imagenNueva"
                  name="isImagenNueva"
                  value={formik.values.isImagenNueva}
                  onChange={formik.handleChange}
                  error={formik.touched.isImagenNueva && Boolean(formik.errors.isImagenNueva)}
                  helperText={formik.touched.isImagenNueva && formik.errors.isImagenNueva}
                  displayEmpty
                  input={<OutlinedInput label="Es imagen nueva" />}
                >
                  <MenuItem value={true}>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Colxx>

            <Colxx xxs="4">
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  htmlFor="imagenAnterior"
                  shrink={formik.values.regional !== null || formik.values.regional !== ''}
                >
                  Es imagen anterior
                </InputLabel>
                <Select
                  id="imagenAnterior"
                  name="isImagenAnterior"
                  value={formik.values.isImagenAnterior}
                  onChange={formik.handleChange}
                  error={formik.touched.isImagenAnterior && Boolean(formik.errors.isImagenAnterior)}
                  helperText={formik.touched.isImagenAnterior && formik.errors.isImagenAnterior}
                  displayEmpty
                  input={<OutlinedInput label="Es imagen anterior" />}
                >
                  <MenuItem value={true}>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Colxx>

            <Colxx xxs="4">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="active">Activo</InputLabel>
                <Select
                  labelId="active"
                  id="active"
                  name="active"
                  label="Activo"
                  value={formik.values.active}
                  onChange={formik.handleChange}
                  error={formik.touched.active && Boolean(formik.errors.active)}
                  helpertext={formik.touched.active && formik.errors.active}
                >
                  <MenuItem value={true}>Sí</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                <FormHelperText>{formik.touched.active && formik.errors.active}</FormHelperText>
              </FormControl>
            </Colxx>
          </div>
        </div>
        <div className="category-select-container">
          <p style={{ fontSize: '1rem' }}>Categoría *</p>
          <CategoryTree
            categorySelectedToCkeckBox={formik.values.category}
            setCategorySelectedToCkeckBox={(value) => formik.setFieldValue('category', value)}
          />
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
        <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
          Guardar
        </Button>
        <Button type="button" variant="contained" color="error" endIcon={<CloseIcon />} onClick={onClose}>
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default EditFormArteFinalImprimible;
