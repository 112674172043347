import React, { useContext } from 'react';
import { PointContext } from '../../context/PointContext';
import ImageCarteleria from './ImageCarteleria';

const CarteleriaMercado = () => {
  const { points, setSelectedPoint, selectedPoint, arePointsActive, setIsPdfContainerLoading } =
    useContext(PointContext);

  if (!selectedPoint || selectedPoint?.length == 0) return <p>Seleccione un punto de cartelería en el mapa.</p>;
  if (selectedPoint?.length > 0)
    return (
      <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
        {selectedPoint.map((point) => (
          <ImageCarteleria point={point} />
        ))}
      </div>
    );
  //   return <p>{JSON.stringify(selectedPoint)}</p>;
};

export default CarteleriaMercado;
