import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { handleRemoveFormat } from '../../../helpers/PedidoPilotado/pedidoPilotado';
import {
  handleChangeQuantity,
  handleDecrementQuantity,
  handleIncrementQuantity,
} from '../../../helpers/Compuesto/compuestoHelper';

const FormatAssociated = ({ required, aaffArray, setAaffArray, aaffSelected, setAaffSelected, data, format }) => {
  return (
    <Row style={{ margin: '0', padding: '.5rem 0' }}>
      <Colxx
        xxs={required ? '8' : '10'}
        style={{ cursor: 'default', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      >
        <span>{format.code + '-' + format.name + ' / ' + data.name}</span>
      </Colxx>

      {required && (
        <Colxx style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '.2rem' }} xxs="3">
          {/* TODO: Input para la cantidad  */}
          <RemoveCircleIcon
            onClick={() => handleDecrementQuantity(data, format, setAaffSelected)}
            style={{ cursor: 'pointer' }}
            fontSize="small"
          />
          <span
            style={{
              border: '1px solid rgba(0,0,0,0.3)',
              // width: '35%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input
              min="1"
              type="number"
              value={format.quantity}
              onChange={(e) => handleChangeQuantity(e, data, format, setAaffSelected)}
              style={{ width: '100%', border: 'none' }}
            />
          </span>
          <AddCircleIcon
            onClick={() => handleIncrementQuantity(data, format, setAaffSelected)}
            style={{ cursor: 'pointer' }}
            fontSize="small"
          />
        </Colxx>
      )}
      <Colxx style={{ display: 'flex', justifyContent: 'flex-end' }} xxs={required ? '1' : '2'}>
        {/* TODO: Eliminar asociacion  */}
        <span
          onClick={() =>
            handleRemoveFormat({ setAaffArray, aaffArray, aaff: data, format, aaffSelected, setAaffSelected })
          }
          style={{ border: '1px solid #C62828', borderRadius: '4px', cursor: 'pointer', backgroundColor: '#ffd4d8' }}
        >
          <ClearIcon fontSize="small" style={{ color: '#C62828' }} />
        </span>
      </Colxx>
    </Row>
  );
};

export default FormatAssociated;
