import React, { useContext } from 'react';
import { ModalFormatos } from './ModalFormatos';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormatCompuestoContext } from '../../../context/FormatCompuestoContext';

const AddFormat = () => {
  const { setCompuestoValue, setRequired, setAaffSelected } = useContext(FormatCompuestoContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCloseModalFormatos = () => {
    setCompuestoValue(false);
    setRequired(false);
    setAaffSelected([]);
    navigate(location.pathname.replace('/add-format', ''));
  };

  return <ModalFormatos isOpen={true} onClose={handleCloseModalFormatos} />;
};

export default AddFormat;
