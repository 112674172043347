import axios from 'axios';

export const getCategoriesTreeByArte = async (idArte) => {
  try {
    const data = await axios.get(`/api/v1/artefinalcategory/getcategoriesbyarte/${idArte}`);
    return data.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getGestionCategories = async () => {
  try {
    const data = await axios.get('/api/v1/category/gestioncategories');

    return data.data;
  } catch (err) {
    console.error('Error al traer las categorias para gestionar: ', err.message);
  }
};

export const getGestionSubcategories = async (categoryId) => {
  try {
    const data = await axios.get(`/api/v1/category/gestioncategories/${categoryId}`);

    return data.data;
  } catch (err) {
    console.error('Error al traer las subcategorias para gestionar: ', err.message);
  }
};

export const getParentCategoryName = async (categoryId) => {
  try {
    const data = await axios.get(`/api/v1/category/gestioncategories/${categoryId}/name`);

    return data.data;
  } catch (err) {
    console.error('Error al traer el nombre de la categoria padre: ', err.message);
  }
};
export const postNewCategories = async (categories) => {
  try {
    // Verificar si todas las categorías son nuevas
    const allNew = categories.every((category) => category.new);

    const promises = categories.map((category) => {
      if (category.new) {
        const payload = {
          name: category.name,
          index: category.index,
          ...(category.parentCategory && { parentCategory: category.parentCategory }),
        };
        return axios.post('/api/v1/category', payload);
      } else {
        return axios.patch(`/api/v1/category/${category._id}`, { index: category.index });
      }
    });

    const results = await Promise.all(promises);
    const responseData = results.map((result) => result.data.data._id);

    // Si todas las categorías son nuevas y tienen una categoría padre, mover los artes finales a la primera subcategoría creada
    if (allNew) {
      const parentCategoryId = categories[0]?.parentCategory;
      if (parentCategoryId) {
        // Tomar el ID de la primera nueva subcategoría
        const firstNewSubcategoryId = responseData[0];
        await moveFinalArtsToSubcategory(parentCategoryId, firstNewSubcategoryId);
      }
    }

    return responseData;
  } catch (err) {
    console.error('Error al crear o actualizar las subcategorías:', err.message);
    throw err;
  }
};

// Función para mover los artes finales a la nueva subcategoría
async function moveFinalArtsToSubcategory(parentCategoryId, subcategoryId) {
  await axios.post('/api/v1/artefinalcategory/move-arts', {
    fromCategory: parentCategoryId,
    toCategory: subcategoryId,
  });
}

export const editCategory = async (params) => {
  try {
    const data = await axios.patch(`/api/v1/category/${params.idCategory}`, {
      name: params.newCategoryName,
      disabled: params.disabled,
      important: params.important,
    });

    return data.data;
  } catch (err) {
    console.error('Error al editar la categoria: ', err.message);
    throw err;
  }
};

export const moveCategory = async (params) => {
  try {
    const data = await axios.patch(`/api/v1/category/gestioncategories/move/${params.id}`, {
      index: params.index,
      parentCategory: params.parentCategory,
    });

    return data;
  } catch (err) {
    console.error('Error al mover la categoria: ', err.message);
    throw err;
  }
};

export const getAllSubcategories = async (parentCategory) => {
  try {
    const data = await axios.get(`/api/v1/category/gestioncategories/subcategories/${parentCategory}`);
    return data.data;
  } catch (err) {
    console.error('Error al traer todas las subcategorias: ', err.message);
    throw err;
  }
};

export const getSubcategoriesExist = async (parentCategory) => {
  try {
    const data = await axios.get(`/api/v1/category/gestioncategories/existsubcategories/${parentCategory}`);
    return data.data;
  } catch (err) {
    console.error('Error al traer las subcategorias: ', err.message);
    throw err;
  }
};

export const getAaffAvailable = async (categoryId, page, valueSearch) => {
  try {
    const data = await axios.get(`/api/v1/category/gestioncategories/aaffavailable/${categoryId}`, {
      params: {
        page,
        valueSearch,
      },
    });
    return data.data;
  } catch (err) {
    console.error('Error al traer los artes finales disponibles: ', err.message);
    throw err;
  }
};

export const addAaffToCategory = async (params) => {
  try {
    const data = await axios.post(`/api/v1/category/gestioncategories/addaaff/${params.idCategory}`, {
      aaffSelected: params.aaffSelected,
    });
    return data.data;
  } catch (err) {
    console.error('Error al añadir los artes finales a la categoria: ', err.message);
    throw err;
  }
};

/* -------------------------------------------------------------------------- */
/*         FUNCIONES PARA ELIMINAR UNA CATEGORIA JUNTO A SUBCATEGORIAS        */
/* -------------------------------------------------------------------------- */

export const checkDeleteCategory = async (categoryId) => {
  try {
    let res = await axios.get(`/api/v1/category/gestioncategories/${categoryId}/checkdelete`);
    return res.data;
  } catch (err) {
    const customError = new Error(err.response?.data?.message);
    customError.status = err.response?.data?.status;
    throw customError;
  }
};

export const getDeleteCategory = async (categoryId) => {
  try {
    const data = await axios.get(`/api/v1/category/gestioncategories/${categoryId}/getdelete`);
    return data.data;
  } catch (err) {
    console.error('Error al traer la categoria para eliminar: ', err.message);
    throw err;
  }
};

export const deleteCategory = async (categories) => {
  try {
    const data = await axios.patch('/api/v1/category/gestioncategories/deletecategorywithsubcategories', {
      categories,
    });
    return data.data;
  } catch (err) {
    console.error('Error al eliminar la categoria: ', err.message);
    throw err;
  }
};

export const getCantMoveCategory = async (idCategory, idParentCategory) => {
  try {
    const [categoryNames, treeCategory] = await Promise.all([
      axios.get(`/api/v1/category/gestioncategories/${idCategory}/cantmove/${idParentCategory}`),
      axios.get(`/api/v1/category/gestioncategories/${idCategory}/categorytree`),
    ]);

    return { categoryNames: categoryNames.data, categoryTree: treeCategory.data };
  } catch (err) {
    console.error('Error al traer las categorias para mover: ', err.message);
    throw err;
  }
};

export const moveAaffAndCategory = async (params) => {
  try {
    const data = await axios.post(`/api/v1/category/gestioncategories/moveaaffandcategory`, {
      idCategory: params.idCategory,
      idCategoryToMove: params.idCategoryToMove,
      idParentCategory: params.idParentCategory,
    });
    return data.data;
  } catch (err) {
    console.error('Error al mover los artes finales y la categoria: ', err.message);
    throw err;
  }
};
