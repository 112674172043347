import {
  Card,
  CardContent,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect } from 'react';
import '../../css/loader.css';
import { useState } from 'react';

export const DescompuestosByPartidaProyecto = ({ data, dataAllDescompuestos }) => {
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    console.log('supercardsss', data);
    const dataDescompuestos = data.partida.descompuestos.map((el) => ({
      nombre: el.name,
      codigo: el.code,
      unidad: el.unit,
      descripcion: el.description || '',
      precio: el.price,
    }));
    setDataTable(dataDescompuestos);
  }, [data]);

  const [selectedRoes, setSelectedRoes] = useState([]); // Filas seleccionadas
  const handleRowSelected = (event, codigo) => {
    if (event.target.checked) {
      setSelectedRoes([...selectedRoes, codigo]);
    } else {
      setSelectedRoes(selectedRoes.filter((row) => row !== codigo));
    }
  };

  useEffect(() => {
    console.log('filas seleccionadas', selectedRoes);
  }, [selectedRoes]);

  return (
    <TableContainer component={Paper} style={{ padding: '20px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: '#333' }}>
            <TableCell style={{ color: '#fff' }}> Nombre </TableCell>
            <TableCell style={{ color: '#fff' }} align="right">
              {' '}
              Codigo{' '}
            </TableCell>
            <TableCell style={{ color: '#fff' }} align="right">
              {' '}
              Unidad{' '}
            </TableCell>
            <TableCell style={{ color: '#fff' }} align="right">
              {' '}
              Precio{' '}
            </TableCell>
            {/* <TableCell style={{ color: '#fff' }} align="right">
              Seleccionar
            </TableCell> */}
            {/* <TableCell align="right"> Descipcion </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTable ? (
            dataTable.map((row) => (
              <TableRow key={row.codigo} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.nombre}</TableCell>
                <TableCell>{row.codigo}</TableCell>
                <TableCell>{row.unidad}</TableCell>
                <TableCell>{row.precio}</TableCell>
                {/* <TableCell>{row.descipcion}</TableCell> */}
                {/* <TableCell>
                  <Checkbox
                    checked={selectedRoes.includes(row.codigo)}
                    onChange={(event) => handleRowSelected(event, row.codigo)}
                  />
                </TableCell> */}
              </TableRow>
            ))
          ) : (
            // TODO: en lugar de esto podriamos poner un mensaje indicando que no hay DESCOMPUESTOS
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell> </TableCell>
              <TableCell></TableCell>
              <TableCell>
                {' '}
                <div class="custom-loader-table"></div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
