import React, { useState, useEffect, useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox } from '@mui/material';
import GlobalSearch from './GlobalSearch';
import { ClipLoader } from 'react-spinners';

const OrderFilter = ({
  filterName,
  filterList,
  filterValues,
  setFilterValues,
  search,
  searchValue,
  setSearchValue,
  loading,
  openMenu,
  handleMenuOpen,
  menuName,
}) => {
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [positionReady, setPositionReady] = useState(false);
  const filterButtonRef = useRef(null);
  const menuRef = useRef(null);

  const menuOpen = openMenu === menuName; // Determinar si el menú actual está abierto

  const updateMenuPosition = () => {
    if (filterButtonRef.current) {
      const rect = filterButtonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.height,
        left: 0,
      });
      setPositionReady(true);
    }
  };

  const handleClick = () => {
    if (!menuOpen) {
      handleMenuOpen(menuName);
      setPositionReady(false);
    } else {
      handleMenuOpen(null);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      updateMenuPosition();
    }
  }, [menuOpen]);

  const handleChangeStatus = (id) => {
    if (filterValues.includes(id)) {
      setFilterValues(filterValues.filter((value) => value !== id));
    } else {
      setFilterValues([...filterValues, id]);
    }
  };

  return (
    <>
      <div
        ref={filterButtonRef}
        onClick={handleClick}
        id="filter-button"
        className="center-filter"
        style={{ position: 'relative', display: 'inline-block' }}
      >
        <span className="font-filters">{filterName}</span>
        {!menuOpen ? <KeyboardArrowDownIcon /> : <CloseIcon />}

        {filterValues.length > 0 && (
          <span className="number-filters-container">
            <span className="number-filters">{filterValues.length}</span>
          </span>
        )}
        {menuOpen && positionReady && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={menuRef}
            style={{
              position: 'absolute',
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              maxHeight: '600px',
              marginTop: '0.5rem',
              background: 'white',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              zIndex: 1000,
              width: '300px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '10px' }}>
              {search && (
                <GlobalSearch
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  placeholder={`Buscar ${filterName}`}
                />
              )}

              <div style={{ overflow: 'auto', maxHeight: '410px', width: '100%' }}>
                {loading ? (
                  <div style={{ padding: '10px', textAlign: 'center' }}>
                    <ClipLoader size={24} color={'#d63636'} loading={loading} />
                  </div>
                ) : filterList.length === 0 ? (
                  <div style={{ margin: '1rem' }}>No se ha encontrado ninguna coincidencia </div>
                ) : (
                  filterList.map((filter) => (
                    <div className="status-filter" onClick={() => handleChangeStatus(filter.value)} key={filter.value}>
                      <Checkbox size="small" checked={filterValues.includes(filter.value)} />
                      <span style={{ padding: '0 1rem 0 0' }}>{filter.label}</span>
                    </div>
                  ))
                )}
              </div>

              <button className="delete-selection-button" onClick={() => setFilterValues([])}>
                Borrar selección
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderFilter;
