import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import queryString from 'query-string';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ProyectoContext } from '../../context/ProyectoContext';

export const AlertVersion = () => {
  const [isnineteen, setIsnineteen] = useState(false);
  const { version, setVersion } = useContext(ProyectoContext);
  const location = useLocation();

  useEffect(() => {
    console.log(location);

    const parsed = queryString.parse(location.search);
    let parsedAsNumbers = Object.keys(parsed).reduce((acc, key) => {
      acc[key] = parseInt(parsed[key], 10);
      return acc;
    }, {});

    const extractedVersion = parsed.version;
    parsedAsNumbers = parsedAsNumbers.version;

    if (parsedAsNumbers < 19) {
      setVersion('19');
    } else {
      setVersion(extractedVersion);
    }
    let original = location.pathname;
    let originalversion = location.pathname + '?version=19';
    console.log(location.pathname);
    setIsnineteen(location.search === '?version=19');
  }, [location.search, location.pathname]);

  useEffect(() => {
    console.log(isnineteen);
    console.log(version);
  }, [isnineteen]);
  return (
    <>
      {location.search !== '?version=19' && version !== undefined ? (
        <div
          style={{
            gap: '10px',
            borderRadius: '4px',
            backgroundColor: '#FFF8E3',
            border: '1px solid #FFD700',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '.6rem 1rem',
          }}
        >
          <div style={{ width: '60%', height: 'auto' }}>
            <h6>
              <ReportProblemIcon fontSize="small" style={{ color: '#CB9700' }} /> Versión antigua
            </h6>
            <p style={{ margin: '0', color: '#CB9700' }}>Le recomendamos que use la ultima versión del visor</p>
          </div>
          <Link
            to={location.pathname}
            className="hover-update-version"
            style={{
              backgroundColor: '#f5f5f5',
              borderRadius: '5px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              padding: '8px 16px',
              color: 'black',
              border: 'none',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            Actualizar
          </Link>
        </div>
      ) : (
        <div
          style={{
            borderRadius: '4px',
            backgroundColor: '#E7F9F1',
            border: '1px solid #25BD59',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '.6rem 1rem',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', height: 'auto', gap: '.6rem' }}>
            <h6>
              <CheckCircleIcon fontSize="small" style={{ color: '#25BD59' }} />
            </h6>
            <p style={{ margin: '0', color: '#25BD59' }}>Esta utilizando la ultima version!</p>
          </div>
        </div>
      )}
    </>
  );
};
