import toast from 'react-hot-toast';

export const handleChangeInputSearch = (e, setInputAfter) => {
  setInputAfter(e.target.value);
};

export const handleKeyDown = (e, setInputValueSearch) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    const value = e.target.value;
    if (value.length < 3 && value.length > 0) {
      toast.error('Introduce al menos 3 caracteres', {
        id: 'error-search',
        duration: 2000,
      });
    } else {
      setInputValueSearch(value);
    }
  }
};

export const handleClearInput = (setInputValueSearch, setInputAfter) => {
  setInputValueSearch('');
  setInputAfter('');
};
