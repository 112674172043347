import React from 'react';
import { DataQuantityVersion } from '../Components2/Graphics/DataQuantityVersion';
import GraphicLayout from '../layout/ViewerContentComponents/GraphicLayout';
import InfoLayout from '../layout/ViewerContentComponents/InfoLayout';
import { InfoPartidaProyecto } from '../Components2/Graphics/InfoPartidaProyecto';
import { PartidaProyectoProvider } from '../context/PartidaProyectoContext';

const PartidaProyectoPage = () => {
  return (
    <>
      <PartidaProyectoProvider>
        <InfoLayout>
          <InfoPartidaProyecto />
        </InfoLayout>
        <GraphicLayout>
          <DataQuantityVersion />
        </GraphicLayout>
      </PartidaProyectoProvider>
    </>
  );
};

export default PartidaProyectoPage;
