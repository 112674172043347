import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { CHARTS } from '../../../constants/typeCharts';
import useQueryParams from '../../../hooks/useQueryParams';

const SelectTypeChart = () => {
  const [{ chart }, handleParam] = useQueryParams(['chart']);

  return (
    <div className="w-30">
      <FormControl fullWidth variant="outlined">
        <InputLabel id="select-type-chart-label">Tipo de Gráfico</InputLabel>
        <Select
          labelId="select-type-chart-label"
          value={chart || ''}
          onChange={(e) => handleParam({ chart: e.target.value })}
          displayEmpty
          label="Tipo de Gráfico"
        >
          {CHARTS.map((chart, index) => (
            <MenuItem key={index} value={chart.value}>
              {chart.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectTypeChart;
