import toast from 'react-hot-toast';

export const handleCloseModal = (navigate, location, position) => {
  const url = location.pathname.split('/').slice(0, -position).join('/');
  navigate(url);
};

export const handleSubmitCreateMaterial = (params) => {
  params.e.preventDefault();
  params.mutation.mutate(params.formData, {
    onSuccess: () => {
      toast.success('Material creado exitosamente');
      handleCloseModal(params.navigate, params.location, 1);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
};

export const handleSubmitEditMaterial = (params) => {
  params.e.preventDefault();
  params.mutation.mutate(params.formData, {
    onSuccess: () => {
      toast.success('Material editado exitosamente');
      handleCloseModal(params.navigate, params.location, 2);
    },
  });
};
