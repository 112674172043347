import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import '../../css/navVertical.css';
import StoreIcon from '@mui/icons-material/Store';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
// import SpritesHelpers from '../Points/SpritePointsFunction';

export const NavbarVer = () => {
  const location = useLocation();
  const [id, setId] = useState('');
  const [version, setVersion] = useState('');

  useEffect(() => {
    const pathParts = location.pathname.split(':');
    const idWithSuffix = pathParts[pathParts.length - 1];
    const idParts = idWithSuffix.split('/');
    const extractedId = idParts[0];
    setId(extractedId);

    const parsed = queryString.parse(location.search);
    const extractedVersion = parsed.version;
    console.log(extractedVersion);
    setVersion(extractedVersion);
  }, [location.search, location.pathname]);

  return (
    <div className="navbarV">
      <NavLink
        to={`/viewer/urn:adsk.wipprod:dm.lineage:${id}/mec${version ? `?version=${version}` : ''}`}
        // onClick={() => SpritesHelpers.hideSprites()}
      >
        <QueryStatsIcon /> Mec
      </NavLink>
      <NavLink
        to={`/viewer/urn:adsk.wipprod:dm.lineage:${id}/mercado${version ? `?version=${version}` : ''}`}
        // onClick={() => SpritesHelpers.showSprites()}
      >
        <StoreIcon /> Mercado
      </NavLink>
    </div>
  );
};
