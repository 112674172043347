import React, { useContext } from 'react';
import { TextField } from '@mui/material';
import {
  handleChangeFormatoCustom,
  handleFormatoChange,
  updateNameCustom,
} from '../../helpers/Admin/cardContentArteFinal';
import UpdateIcon from '@mui/icons-material/Update';
import FooterCard from './FooterCard';
import DropdownOption from '../common/SelectOption';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import { ArteFinalContext_v2 } from '../../context/ArteFinalContext_v2';
import SelectMaterial from './SelectMaterial';

const MAX_LENGTH = 30;

const ContentArteNotDescargable = ({ arteFinal }) => {
  const shouldTruncate = arteFinal.nameArteFinal.length > MAX_LENGTH;
  const displayText = shouldTruncate
    ? `${arteFinal.nameArteFinal.substring(0, MAX_LENGTH)}...`
    : arteFinal.nameArteFinal;

  const { shoppingCart_v2, setShoppingCart_v2 } = useContext(ShoppingCartContext);
  const { formatSelected, setFormatSelected, setFormatProviderSelected } = useContext(ArteFinalContext_v2);

  return (
    <div className="content-arte-not-descargable">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          {shouldTruncate ? (
            <p style={{ fontWeight: 'bold', margin: '0', cursor: 'default' }}>{displayText.toUpperCase()}</p>
          ) : (
            <p style={{ fontWeight: 'bold', margin: '0' }}>{arteFinal.nameArteFinal.toUpperCase()}</p>
          )}
          <p style={{ fontStyle: 'italic', margin: '0' }}>{arteFinal.codeArteFinal}</p>
        </div>

        <DropdownOption
          options={arteFinal.formatos.map((formato) => ({
            value: formato.formatoId,
            formatName: formato.formatoName,
            formatoPrice: formato.formatoPrice,
            formatoCode: formato.formatoCode,
            formatoMaterial: formato.formatoMaterial,
            formatoCustom: formato.formatoCustom,
            arteFinal: arteFinal._id,
          }))}
          id={`select-formatos-arte-final-${arteFinal._id}`}
          onChange={(value) =>
            handleFormatoChange({
              setFormatSelected,
              setFormatProviderSelected,
              arteFinal,
              event: value,
            })
          }
        />

        {arteFinal.formatos.find((formato) => formato?.formatoId === formatSelected?.formatoId)?.formatoId ===
          formatSelected?.formatoId &&
          formatSelected?.formatoCustom && (
            <div className="w-100 d-flex fd-c gap-1">
              <TextField
                size="small"
                variant="outlined"
                type="text"
                placeholder="Ej: 300 X 400"
                defaultValue={
                  shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId]?.formatoName !== undefined
                    ? shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId]?.formatoName
                    : ''
                }
                InputProps={{
                  endAdornment: shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId]?.formatoName ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <UpdateIcon
                        sx={{ color: 'action.active', cursor: 'pointer' }}
                        onClick={() =>
                          updateNameCustom({ arteFinalId: arteFinal._id, formatSelected, setShoppingCart_v2 })
                        }
                      />
                    </div>
                  ) : null,
                  style: {
                    textAlign: 'center',
                  },
                }}
                inputProps={{
                  style: {
                    textAlign: 'center',
                  },
                }}
                style={{ padding: '0 1rem' }}
                onChange={(e) => handleChangeFormatoCustom({ e, setFormatSelected })}
              />
              <SelectMaterial />
            </div>
          )}
      </div>

      <FooterCard key={arteFinal._id} />
    </div>
  );
};

export default ContentArteNotDescargable;
