import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { useContext, useEffect } from 'react';
import { PartidasContext } from '../../context/PartidasContext';

const objectSort = {
  price: 'Precio: de menor a mayor',
  '-price': 'Precio: de mayor a menor',
};

const SortByInput = ({ sortValue, onChange }) => {
  const { setCurrentPage, partidaQuery, setPartidaQuery } = useContext(PartidasContext);

  const handleChange = async (e) => {
    onChange(e.target.value);
    // []
  };

  useEffect(() => {
    if (sortValue != ' ') {
      setPartidaQuery({ ...partidaQuery, sort: sortValue });
      setCurrentPage(1);
    } else {
      const { sort, ...rest } = partidaQuery;
      setPartidaQuery(rest);
      setCurrentPage(1);
    }
  }, [sortValue]);

  return (
    <FormControl
      size="small"
      sx={{
        width: '100%',
      }}
    >
      {/* <Tooltip title={objectSort[sortValue] || 'Defecto'} placement="top"> */}
      <InputLabel id="demo-simple-select-helper-label" sx={{ fontSize: '12px' }}>
        Filtrar por precio
      </InputLabel>
      {/* </Tooltip> */}

      <Select
        style={{ borderColor: 'black' }}
        labelId="demo-simple-select-helper-label"
        defaultValue="price"
        value={sortValue || ''}
        label="Ordenar por"
        color="secondary"
        displayEmpty
        onChange={handleChange}
        sx={{ height: '100%', fontSize: '12px', borderRadius: '8px' }}
        variant="outlined"
      >
        <MenuItem value=" " style={{ fontSize: '12px' }}>
          <em>Defecto</em>
        </MenuItem>
        <MenuItem value="price" style={{ fontSize: '12px' }}>
          De menor a mayor
        </MenuItem>
        <MenuItem value="-price" style={{ fontSize: '12px' }}>
          De mayor a menor
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SortByInput;
