import { Modal, Typography, Alert, AlertTitle } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { oneFormatWtithArteFinal } from '../../../api/carteleriaApi';
import { InfoModalFormat } from './InfoModalFormat';
import { FormatCompuestoContext } from '../../../context/FormatCompuestoContext';

export const PageModalFormat = () => {
  const [isModalOpenFormat, setIsModalOpenFormat] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { idFormat } = useParams();
  const { setAaffSelected, setRequired, setCompuestoValue } = useContext(FormatCompuestoContext);
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: infoOneFormat,
    refetch,
    isFetching,
  } = useQuery(['oneFormatMaster', idFormat], async () => {
    const token = await getAccessTokenSilently();
    return await oneFormatWtithArteFinal(token, idFormat);
  });
  useEffect(() => {
    // Cada vez que traigamos la data tenemos que modificar el estado de artes seleccionados para que se muestren en el modal
    setCompuestoValue(false);
    if (isFetching) return;

    const childrenFormat = infoOneFormat?.data?.childrenFormat;

    // Verificar si childrenFormat existe y tiene formatos
    if (childrenFormat?.format?.length > 0) {
      setCompuestoValue(true);
    }

    // Verificar si childrenFormat es requerido
    if (childrenFormat?.required) {
      setRequired(true);
    }

    // Verificar si childrenFormat tiene formatos antes de proceder
    if (childrenFormat?.format?.length > 0) {
      const aaffsChildrens = childrenFormat.format.reduce((acc, curr) => {
        if (!acc[curr.arteFinal._id]) {
          acc[curr.arteFinal._id] = {
            ...curr.arteFinal,
            formats: [curr],
          };
        } else {
          acc[curr.arteFinal._id].formats.push(curr);
        }
        return acc;
      }, {});

      // Una vez tenemos ya los artesFinales con sus formatos seteamos aaffSelected para que se muestren en el modal
      setAaffSelected(
        Object.values(aaffsChildrens).map((aaff) => ({
          code: aaff.code,
          createdAt: aaff.createdAt,
          formats: aaff.formats.map((format) => ({
            childrenFormat: format.childrenFormat,
            code: format.code,
            isCustom: format.isCustom,
            name: format.name,
            quantity: format.quantity?.quantity || 1,
            _id: format._id,
          })),
          name: aaff.name,
          _id: aaff._id,
        })),
      );
    } else {
      // Si no hay formatos, podemos establecer aaffSelected en un array vacío o algún valor predeterminado
      setAaffSelected([]);
    }
  }, [isFetching, infoOneFormat]);

  useEffect(() => {
    if (idFormat) {
      setIsModalOpenFormat(true);
    }
  }, [idFormat]);

  const handleCloseModalFormatos = () => {
    setIsModalOpenFormat(false);
    navigate(`${location.pathname.replace(`/formats/${idFormat}`, '')}`);
  };

  if (isFetching) return;
  return (
    <Modal open={isModalOpenFormat} onClose={handleCloseModalFormatos}>
      <div className="modal-create-edit-format">
        <Typography
          variant="h4"
          component="h2"
          align="center"
          sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
        >
          Información del formato
        </Typography>
        {infoOneFormat?.data?.isCustom ? (
          <Alert severity="warning">
            <AlertTitle>Formato personalizado</AlertTitle>
            Para este formato solo puedes modificar determinados campos.
          </Alert>
        ) : null}

        {infoOneFormat && (
          <InfoModalFormat
            data={infoOneFormat.data}
            handleCloseModalFormatos={handleCloseModalFormatos}
            refetch={refetch}
          />
        )}
      </div>
    </Modal>
  );
};
