import React from 'react';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { getTextColorBasedOnBackground } from '../../helpers/colorsHelper';
import { ClipLoader } from 'react-spinners';

export const Selector = ({ value, setValue, options, bgColor, loading }) => {
  const handleChangeStatus = (option) => {
    setValue({
      label: option.label,
      value: option.value,
      color: option.color,
    });
  };

  return (
    <>
      <Menu
        menuButton={
          <MenuButton
            className="menu-button-selector"
            style={{
              color: getTextColorBasedOnBackground(bgColor),
              backgroundColor: bgColor && bgColor,
            }}
          >
            {value}
          </MenuButton>
        }
        transition
      >
        {loading ? (
          <div style={{ padding: '10px', textAlign: 'center' }}>
            <ClipLoader size={24} color={'#d63636'} loading={loading} />
          </div>
        ) : (
          options?.map((option) => (
            <MenuItem key={option.value} onClick={() => handleChangeStatus(option)}>
              {option.label}
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};

export default Selector;
