import { Modal } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { handleClose } from '../../../helpers/Category/gestionCategories';
import TitleModal from '../../common/TitleModal';
import { useEditCategoryMutation, useParentCategoryName } from '../../../hooks/useCategory';
import SkeletonLoader from '../../common/SkeletonLoader';
import FormEditCategory from './FormEditCategory';

const EditCategory = () => {
  const { idCategory } = useParams();
  const navigate = useNavigate();
  const { parentCategoryName, isLoading } = useParentCategoryName(idCategory);
  const { mutation } = useEditCategoryMutation(idCategory);

  return (
    <Modal open onClose={() => handleClose(navigate)}>
      <div className="create-cluster-container-modal">
        <TitleModal title={'EDITAR CATEGORÍA'} />
        {isLoading ? (
          <SkeletonLoader count={1} minHeight="125px" />
        ) : (
          <FormEditCategory
            handleClose={() => handleClose(navigate)}
            categoryName={parentCategoryName}
            mutation={mutation}
            idCategory={idCategory}
          />
        )}
      </div>
    </Modal>
  );
};

export default EditCategory;
