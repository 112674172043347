import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ViewerContext } from '../../context/ViewerContext';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse } from 'reactstrap';
import PartidaProyecto from '../../helpers/Partida/PartidaProyecto';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const PreviewAllPartidas = ({ partida, setAllPartidasExcludeExists }) => {
  const { uniqueProperties } = useContext(ViewerContext);
  const [changeParam, setChangeParam] = useState(false);
  const [propertyChosen, setPropertyChosen] = useState(null);
  const [paramValue, setParamValue] = useState(partida.param);
  const [openSelectParams, setOpenSelectParams] = useState(false);
  const [options, setOptions] = useState(uniqueProperties || null);
  const [loading, setLoading] = useState(true);
  const [unitValue, setUnitValue] = useState(partida.unit);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [previewQuantity, setPreviewQuantity] = useState(partida.previewQuantity);
  const [changePrice, setChangePrice] = useState(false);
  const [priceValue, setPriceValue] = useState(partida.price);
  const [recoverPrice, setRecoverPrice] = useState(null);

  // TENEMOS QUE HACER UN USEEFFECT PARA QUE CADA VEZ QUE CAMBIE PRECIO, QUANTITY, UNIT Y PARAM SE ACTUALICE LA PARTIDA
  useEffect(() => {
    // ya esta todo actualizado, solo falta actualizar la partida
    setAllPartidasExcludeExists((prev) => {
      const newPartidas = [...prev];
      const index = newPartidas.findIndex((part) => part.code === partida.code);
      newPartidas[index] = {
        ...newPartidas[index],
        param: paramValue,
        unit: unitValue,
        price: priceValue,
        previewQuantity,
      };
      return newPartidas;
    });
  }, [priceValue, previewQuantity, unitValue, paramValue]);

  useEffect(() => {
    if (uniqueProperties) {
      setOptions([{ displayName: 'Unidad', unit: 'ud' }, ...uniqueProperties]);
      setLoading(false);
    }
  }, [uniqueProperties]);

  useEffect(() => {
    if (propertyChosen) {
      setParamValue(propertyChosen.displayName);
      setUnitValue(propertyChosen.unit);
    } else {
      setParamValue(partida.param);
      setUnitValue(partida.unit);
    }
  }, [propertyChosen]);

  useEffect(() => {
    const getQuantity = async () => {
      const partidaProyectoInstance = new PartidaProyecto(partida.code, paramValue, partida.price);
      const quantity = await partidaProyectoInstance.getTotalQuantity();
      setPreviewQuantity(quantity);
    };

    getQuantity();
  }, [paramValue]);

  return (
    <div
      style={{
        backgroundColor: '#ebebeb',
      }}
    >
      <div
        onClick={() => setOpenCollapse(!openCollapse)}
        style={{ display: 'flex', alignItems: 'center', gap: '1rem', cursor: 'pointer' }}
      >
        {!openCollapse ? (
          <KeyboardArrowRightIcon style={{ fontSize: '2rem' }} />
        ) : (
          <KeyboardArrowDownIcon style={{ fontSize: '2rem' }} />
        )}
        {/* CÓDIGO Y NOMBRE DE LA PARTIDA */}
        {partida.label && <p className="m-0">{partida.label}</p>}
      </div>

      <Collapse isOpen={openCollapse}>
        {/* VISTA PREVIA DE TODAS LAS PARTIDAS */}
        <div style={{ padding: '1rem', backgroundColor: 'white', border: '1px solid black' }}>
          <div>
            <p className="m-0">
              {' '}
              <span style={{ fontWeight: 'bold' }}>Vista previa de la cantidad: </span>
              {previewQuantity.toLocaleString('es-ES', {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4,
              })}
            </p>
          </div>

          {/* POSIBILIDAD DE CAMBIAR PARAMETRO */}
          {changeParam && (
            <Autocomplete
              id="asynchronous-demo"
              open={openSelectParams}
              style={{ margin: '1rem 0' }}
              onOpen={() => {
                setOpenSelectParams(true);
              }}
              onClose={() => {
                setOpenSelectParams(false);
              }}
              required
              color="secondary"
              isOptionEqualToValue={(option, property) => option === property}
              getOptionLabel={(option) => option.displayName} // Cambia esta línea
              onChange={(e, va) => setPropertyChosen(va)}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccionar parámetro"
                  className="autocomplete-partida-input"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          )}

          {/* PARAMETRO SELECCIONADO */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '.4rem', paddingTop: '1rem' }}>
            {!changeParam ? (
              <p onClick={() => setChangeParam(true)} className="change-param-unit">
                Cambiar parametro y unidad
              </p>
            ) : (
              <p
                onClick={() => {
                  setChangeParam(false);
                  setPropertyChosen(null);
                }}
                className="change-param-unit"
              >
                Mantener parametro y unidad por defecto
              </p>
            )}
            <div style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
              <p className="title-param-unit">Parametro: </p>
              <p className="param-unit">{paramValue}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
              <p className="title-param-unit">Unidad: </p>
              <p className="param-unit">{unitValue}</p>
            </div>
          </div>

          {/* PRECIO SELECCIONADO */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '.4rem', paddingTop: '1rem' }}>
            <p onClick={() => setChangePrice(true)} className="change-param-unit">
              Cambiar precio
            </p>

            <div style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
              <p className="title-param-unit">Precio: </p>
              {!changePrice ? (
                <p className="param-unit">{priceValue} €</p>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input onChange={(e) => setRecoverPrice(e.target.value)} defaultValue={priceValue} type="number" />
                  <CancelIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setRecoverPrice(null);
                      setChangePrice(false);
                    }}
                  />
                  <CheckCircleIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setPriceValue(recoverPrice);
                      setChangePrice(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default PreviewAllPartidas;
