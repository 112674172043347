import { useQuery } from '@tanstack/react-query';
import { getStatsCenter, getStatsMoreCentersOrders } from '../../api/centerApi';
import { getAllCenters } from '../../api/carteleriaApi';
import useQueryParams from '../../hooks/useQueryParams';

export const useGetMoreCentersOrders = () => {
  return useQuery({
    queryKey: ['stats-more-centers-orders'],
    queryFn: () => getStatsMoreCentersOrders(),
  });
};

export const useGetAllCenters = () => {
  return useQuery({
    queryKey: ['all-centers'],
    queryFn: () => getAllCenters(),
  });
};

export const useGetCenterStats = () => {
  const [{ center }] = useQueryParams(['center']);

  return useQuery({
    queryKey: ['center-stats', center],
    queryFn: () => getStatsCenter(center),
    enabled: !!center,
    retry: false,
  });
};
