import { useNavigate } from 'react-router-dom';
import { useDeleteProvider } from '../../../hooks/useProvider';
import TooltipItem from '../../../components/common/TooltipItem';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import DeletePopover from '../../common/DeletePopover';
import { StyledTableCell, StyledTableRow } from '../../../Components2/RecycledComponents';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const fields = [
  { field: 'code' },
  { field: 'proveedor' },
  { field: 'descripcion corta' },
  { field: 'activo' },
  { field: '' },
];

const TableProveedores = ({ proveedores }) => {
  const navigate = useNavigate();
  const { deletePopover, handleDeleteClick, handleDeleteClose, handleDeleteConfirm } = useDeleteProvider();

  return (
    <>
      {proveedores?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {fields?.map((field, index) => (
                  <StyledTableCell align="left" key={index}>
                    {field?.field?.toUpperCase()}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {proveedores?.map((provider, index) => (
                <React.Fragment key={provider._id + index}>
                  <StyledTableRow
                    style={{
                      background: provider?.active || provider?.active === null ? 'initial' : 'rgb(201, 201, 201)',
                    }}
                  >
                    <StyledTableCell align="left">
                      <p className="cell-clusters">{provider?.code}</p>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p className="cell-clusters">{provider?.name}</p>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p className="cell-clusters">{provider?.shortName}</p>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p className="cell-clusters">{provider?.active ? 'Activo' : 'No activo'}</p>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className="container-icons-edit-delete-master">
                        <EditIcon
                          className="tooltip-edit-provider-icon"
                          style={{ cursor: 'pointer' }}
                          onClick={() => navigate(`${provider?._id}/edit-providers`)}
                        />
                        <DeleteForeverIcon
                          id={provider._id}
                          onClick={handleDeleteClick}
                          className="tooltip-delete-provider-icon"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <DeletePopover
                    textCancel={'No'}
                    textConfirm={'Sí'}
                    textQuestion={
                      '¿Estás seguro de que deseas eliminar este provider? Una vez eliminado, no se podrá volver a utilizar en la aplicación.'
                    }
                    handleDeleteClose={handleDeleteClose}
                    deletePopover={deletePopover}
                    handleDeleteConfirm={handleDeleteConfirm}
                  />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <h3 style={{ fontWeight: 'bolder' }}>No hay proveedores para los filtros seleccionados</h3>
      )}
      {/* TOOLTIP PARA LOS ICONOS */}
      <TooltipItem content="Editar proveedor" className="tooltip-edit-provider-icon" position="top" />
      <TooltipItem content="Eliminar proveedor" className="tooltip-delete-provider-icon" position="top" />
    </>
  );
};

export default TableProveedores;
