import { MdOutlineReportGmailerrorred } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import { useGetNameProvider } from '../../lib/react-query/providerQuery';

const NotExistsProviderFormat = ({ childrenFormat, provider }) => {
  const { data } = useGetNameProvider(provider);

  return (
    <>
      <div className="not-exists-provider-format">
        <div>{childrenFormat.arteFinal.code + ' - ' + childrenFormat.arteFinal.name}</div>
        <MdOutlineReportGmailerrorred
          className={`outline-none tooltip-error-not-exists-provider-format-${provider}`}
          color="#B62929"
          fontSize="20px"
        />
      </div>
      <Tooltip
        anchorSelect={`.tooltip-error-not-exists-provider-format-${provider}`}
      >{`El proveedor ${data.data} no esta asociado al formato`}</Tooltip>
    </>
  );
};

export default NotExistsProviderFormat;
