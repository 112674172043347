import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCluster } from '../helpers/Cluster/clusterHelper';

function useDeleteCluster() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const [deletePopover, setDeletePopover] = useState(null);
  const [clusterId, setClusterId] = useState('');

  const handleDeleteClose = () => {
    setDeletePopover(null);
  };

  const handleDeleteClick = (event) => {
    setClusterId(event.currentTarget.id);
    setDeletePopover(event.currentTarget);
  };

  const { mutate: handleDeleteConfirm } = useMutation({
    mutationFn: async () => {
      handleDeleteClose();
      let token = await getAccessTokenSilently();
      await deleteCluster(clusterId, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('allCentersTypes');
    },
  });

  return { deletePopover, handleDeleteClose, handleDeleteClick, handleDeleteConfirm };
}

export default useDeleteCluster;
