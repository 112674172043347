import React, { useContext } from 'react';
import Viewer from './Viewer';
import ButtonExtensionPoint from '../ButtonExtension/ButtonExtensionPoint';
import SelectVersion from './SelectVersion';
import ButtonsViewer from './ButtonsViewer/ButtonsViewer';
import { ProyectoContext } from '../../context/ProyectoContext';
import SpritesPoints from '../Points/SpritesPoints';

const ViewerContainer = () => {
  const { isModelLoaded } = useContext(ProyectoContext);  
  return (
    <>
      {isModelLoaded && <SelectVersion />}
      {isModelLoaded && <ButtonsViewer />}
      {isModelLoaded && <ButtonExtensionPoint />}
      {isModelLoaded && <SpritesPoints />}

      <Viewer />
    </>
  );
};

export default ViewerContainer;
