import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserProvider';
import { Outlet, useNavigate } from 'react-router-dom';
import { OrdersFiltersUserProvider } from '../context/OrdersFiltersUserContext';

const ProtectedUserRoute = () => {
  const { userRole } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (userRole !== 'user-signage') {
      navigate('/error');
    }
    setLoading(false);
  }, [userRole]);

  return (
    !loading && (
      <OrdersFiltersUserProvider>
        <div style={{ flex: 1, overflowY: 'auto' }}>
          <Outlet />
        </div>
      </OrdersFiltersUserProvider>
    )
  );
};

export default ProtectedUserRoute;
