import { BrowserRouter as Router, Routes, Route, RouterProvider } from 'react-router-dom';
import Navbar from './Components2/Navbar/Navbar';
import './App.css';
import { router } from './router';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { PointProvider } from './context/PointContext';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'react-hot-toast';
import { UserProvider } from './context/UserProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{ zIndex: 9999999999 }}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
      {/* <AlcampoLayout> */}
      {/* <ViewerProvider> */}
      {/* <UserProvider> */}
      <PointProvider>
        <RouterProvider router={router} />
      </PointProvider>
      {/* </UserProvider> */}

      {/* </ViewerProvider> */}
      {/* </AlcampoLayout> */}
    </QueryClientProvider>
  );
}

export default App;
