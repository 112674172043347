import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button className="btn default btn-danger" style={{ marginRight: '1rem' ,width:'100%'}} onClick={() => loginWithRedirect()}>
      Iniciar Sesión
    </button>
  );
};

export default LoginButton;
