export const formatCurrency = (amount, decimals = 2) => {
  if (typeof amount !== 'number') {
    throw new TypeError('El importe debe ser un número');
  }

  // Convertir el número a string con los decimales especificados
  const fixedAmount = amount.toFixed(decimals);

  // Separar en parte entera y parte decimal
  const [integerPart, decimalPart] = fixedAmount.split('.');

  // Añadir separador de miles a la parte entera
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Si no hay decimales, devolver solo la parte entera
  return decimals === 0 ? formattedIntegerPart : `${formattedIntegerPart},${decimalPart}`;
};

export const formatDate = (date) => {
  if (!(date instanceof Date)) {
    throw new TypeError('La fecha debe ser un objeto Date');
  }

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('es-ES', options);
};
