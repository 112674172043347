import React, { useState } from 'react';
import { Modal, Step, StepLabel, Stepper } from '@mui/material';
import CreateFormArteFinal from './ArteFinalInfo/CreateFormArteFinal';
import { useCreateArteFinal } from '../../../hooks/useArteFinal';
import CreateFormatToNewArteFinal from '../Formatos/CreateFormatToNewArteFinal.jsx';
import FormAssociateProviderFormat from '../Formatos/FormAssociateProviderFormat.jsx';

const AddArteFinal = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { formikArteFinal, formikFormat, formikProviderFormat, handleClose } = useCreateArteFinal(setActiveStep);

  const steps = [{ label: 'Artículo' }, { label: 'Formato' }, { label: 'Proveedor' }];

  const handleGoBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal onClose={handleClose} open>
      <div className="create-arte-final-container-modal">
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Stepper style={{ marginBottom: '2rem', marginTop: '1rem', userSelect: 'none' }} activeStep={activeStep}>
            {steps.map(({ label }) => {
              const stepProps = {};
              const labelProps = {};
              if (formikArteFinal.values.isDescargable) return null;
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 0 && <CreateFormArteFinal formik={formikArteFinal} onClose={handleClose} />}
          {activeStep === 1 && <CreateFormatToNewArteFinal formik={formikFormat} goBack={handleGoBack} />}
          {activeStep === 2 && <FormAssociateProviderFormat formik={formikProviderFormat} goBack={handleGoBack} />}
        </div>
      </div>
    </Modal>
  );
};

export default AddArteFinal;
