import React from 'react';
import { Tooltip } from 'react-tooltip';
import {
  changeCollapseCluster,
  handleClickAddCluster,
  handleClickAddFormat,
  handleClickAddTienda,
} from '../../../../helpers/PedidoPilotado/pedidoPilotado';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const DbContentTienda = ({ tiendaArray, tiendaSelected, setTiendaArray, setTiendaSelected }) => {
  return tiendaArray.map(
    (cluster, i) =>
      cluster.centers.some((tienda) => !tienda.selected) && (
        <div key={'db-' + cluster._id._id}>
          <div
            onClick={() => changeCollapseCluster(cluster._id._id, setTiendaArray)}
            className="aaff-pedido-pilotado"
            style={{
              cursor: 'pointer',
              height: '2rem',
              backgroundColor: '#F7F7F7',
              fontWeight: 'bold',
              borderBottom: i === tiendaArray.length - 1 ? 'none' : '1px solid #cecece',
              display: 'flex',
              alignItems: 'center',
              userSelect: 'none',
              gap: '.5rem',
              padding: '0 1rem',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <span className={`arrow-button-pedido-pilotado ${!cluster.collapse && 'rotate-arrow-pedido-pilotado'}`}>
                  <KeyboardArrowRightIcon />
                </span>
                <p
                  className={`texto-aaff-pedido-pilotado ${
                    cluster._id.type.length > 53 && `cluster-pedido-pilotado-tooltip-${cluster._id._id}`
                  }`}
                  id={`texto-cluster-pedido-pilotado-db-${cluster._id._id}`}
                >
                  {`${cluster._id.type} (${cluster?.centers?.reduce(
                    (acc, center) => (!center.selected ? acc + 1 : acc),
                    0,
                  )} tiendas)`}
                </p>
              </div>
              <AddCircleOutlineRoundedIcon
                style={{ zIndex: '999999' }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickAddCluster({ cluster, setTiendaArray, setTiendaSelected, tiendaSelected });
                }}
              />
            </div>
          </div>

          <Tooltip anchorSelect={`.cluster-pedido-pilotado-tooltip-${cluster._id._id}`}>
            {`${cluster._id.type} (${cluster.count} tiendas)`}
          </Tooltip>

          <div className={`list-tienda-pedido-pilotado ${!cluster.collapse && 'show-list-tienda-pedido-pilotado'}`}>
            {cluster.centers.map(
              (center) =>
                !center.selected && (
                  <div
                    onClick={() =>
                      handleClickAddTienda({
                        tiendaArray,
                        setTiendaArray,
                        cluster,
                        center,
                        tiendaSelected,
                        setTiendaSelected,
                      })
                    }
                    className="format-pedido-pilotado"
                    key={center._id}
                    style={{
                      height: '3rem',
                      borderBottom: i === tiendaArray.length - 1 ? 'none' : '1px solid #cecece',
                      display: 'flex',
                      alignItems: 'center',
                      userSelect: 'none',
                      gap: '.5rem',
                      padding: '0 1.5rem',
                      cursor: 'pointer',
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <span>{center.code + ' - ' + (center?.name || center.calle)}</span>
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      ),
  );
};

export default DbContentTienda;
