import React from 'react';

const GlobalSpinner = () => {
  return (
    <div className="loadingio-spinner-eclipse-2saj4c1jge">
      <div className="ldio-yzbaupudwr">
        <div></div>
      </div>
    </div>
  );
};

export default GlobalSpinner;
