import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { handleAddCompuesto, handleCloseModal } from '../../helpers/Compuesto/pedidoCompuesto';
import { Box, Modal, Typography } from '@mui/material';
import AccordionChildrenFormat from './AccordionChildrenFormat';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import { ArteFinalContext_v2 } from '../../context/ArteFinalContext_v2';
import { Tooltip } from 'react-tooltip';
import SkeletonLoader from '../common/SkeletonLoader';

const ChildrenFormatModal = () => {
  const { setShoppingCart_v2 } = useContext(ShoppingCartContext);
  const { openModalSelectChildrenFormat, setOpenModalSelectChildrenFormat, requiredFormatChildren, providerNotFound } =
    useContext(ArteFinalContext_v2);
  const [providerSelected, setProviderSelected] = useState(null);

  useEffect(() => {
    if (!openModalSelectChildrenFormat) return;
    setProviderSelected(openModalSelectChildrenFormat[0].providerFormat.provider._id);
  }, [openModalSelectChildrenFormat]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: '5rem',
    transform: 'translate(-50%, -50%)',
    minWidth: '700px',
    maxWidth: '750px',
    bgcolor: '#F8F8F8',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  if (!providerSelected) return null;

  return (
    <Modal open={!!openModalSelectChildrenFormat} onClose={() => handleCloseModal(setOpenModalSelectChildrenFormat)}>
      <Box
        sx={{
          ...style,
          padding: '3rem !important',
          maxHeight: '800px',
          width: '1000px',
          maxWidth: '900px',
          overflowY: 'auto',
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          align="center"
          sx={{
            fontWeight: 'bold',
            marginBottom: '1.5rem',
            backgroundColor: '#3C4145',
            color: 'white',
            userSelect: 'none',
          }}
        >
          {requiredFormatChildren ? 'Arte final compuesto por:' : 'Accesorios para el arte final:'}
        </Typography>

        <Suspense fallback={<SkeletonLoader count={openModalSelectChildrenFormat?.length} minHeight="40px" />}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '2rem' }}>
            {/* AQUI METEMOS A LOS CHILDREN FORMAT INDICANDO EL ARTE FINAL Y SU FORMATO */}
            <div>
              {openModalSelectChildrenFormat?.map((childrenFormat, i) => (
                <AccordionChildrenFormat
                  key={i}
                  index={i}
                  providerSelected={providerSelected}
                  childrenFormat={childrenFormat}
                />
              ))}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
              {/* AQUI VAMOS A INDICAR EL TOTAL DE PRODUCTOS SELECCIONADOS Y LA SUMA TOTAL DE ESTOS */}
              {!providerNotFound && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '2rem', justifyContent: 'flex-end' }}>
                  <p style={{ fontSize: '1.3rem', fontWeight: 'bold', margin: '0' }}>
                    Subtotal (
                    {openModalSelectChildrenFormat
                      ?.filter((childrenFormat) => childrenFormat.checked) // Filtra solo los elementos con checked en true
                      .reduce((acc, childrenFormat) => acc + childrenFormat.quantity, 0)}{' '}
                    productos):{' '}
                  </p>
                  <p style={{ fontSize: '1.3rem', fontWeight: 'bold', margin: '0' }}>
                    {openModalSelectChildrenFormat
                      ?.filter((childrenFormat) => childrenFormat.checked) // Filtra solo los elementos con checked en true
                      ?.reduce((acc, childrenFormat) => acc + childrenFormat.quantity * childrenFormat.formatoPrice, 0) // Calcula el subtotal
                      .toFixed(2) + ' €'}
                  </p>
                </div>
              )}

              {/* AQUI AÑADIMOS LOS BOTONES PARA AÑADIR O CANCELAR */}
              <Row style={{ margin: '0', justifyContent: 'flex-end', gap: '1rem' }}>
                <button
                  onClick={() => handleCloseModal(setOpenModalSelectChildrenFormat)}
                  style={{ padding: '.5rem 1rem', backgroundColor: '#898989', color: 'white', border: 'none' }}
                >
                  Cancelar
                </button>
                <button
                  className={'disabled-add-provider-not-found'}
                  disabled={providerNotFound}
                  onClick={() =>
                    handleAddCompuesto(
                      openModalSelectChildrenFormat,
                      setShoppingCart_v2,
                      setOpenModalSelectChildrenFormat,
                    )
                  }
                  style={{ padding: '.5rem 1rem', backgroundColor: '#B30B00', color: 'white', border: 'none' }}
                >
                  Añadir
                </button>
              </Row>
            </div>
          </div>
          {providerNotFound && (
            <Tooltip anchorSelect=".disabled-add-provider-not-found">
              {providerNotFound &&
                'Para poder añadir un compuesto, todos sus formatos hijos deben tener asociado el mismo proveedor, por favor, ponte en contacto con el administrador.'}
            </Tooltip>
          )}
        </Suspense>
      </Box>
    </Modal>
  );
};

export default ChildrenFormatModal;
