import React, { useContext, useEffect, useState } from 'react';
import { Modal, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import ImageIcon from '@mui/icons-material/Image';
import StoreIcon from '@mui/icons-material/Store';
import PropTypes from 'prop-types';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SelectArteFinal from './SelectArteFinal';
import NavigateSteps from './NavigateSteps';
import { nextDisabled } from '../../../helpers/PedidoPilotado/pedidoPilotado';

import SelectTienda from './SelectTienda';
import RelArteFinalTienda from './RelArteFinalTienda';
import { PilotadosContext } from '../../../context/PilotadosContext';

const ModalAddPedidoPilotado = ({ onClose }) => {
  const { aaffSelected, tiendaSelected, asociateData } = useContext(PilotadosContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    let disabledF = nextDisabled(aaffSelected, tiendaSelected, asociateData, currentStep);
    setDisabled(disabledF);
  }, [aaffSelected, tiendaSelected, currentStep, asociateData]);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient(95deg, rgb(242, 50, 50) 0%, rgb(233, 64, 87) 50%, rgb(200, 30, 30) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient(95deg, rgb(242, 50, 50) 0%, rgb(233, 64, 87) 50%, rgb(200, 30, 30) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage: 'linear-gradient(95deg, rgb(242, 50, 50) 0%, rgb(233, 64, 87) 50%, rgb(200, 30, 30) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage: 'linear-gradient(95deg, rgb(242, 50, 50) 0%, rgb(233, 64, 87) 50%, rgb(200, 30, 30) 100%)',
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <ImageIcon />,
      2: <StoreIcon />,
      3: <AddBusinessIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const steps = ['Seleccionar artes finales', 'Seleccionar centro/s o cluster/s', 'Asociar artes finales a centros'];

  return (
    <Modal open={true} onClose={onClose}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '1200px',
          padding: '20px',
          justifyItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          boxShadow: 24,
        }}
      >
        <div>
          <Typography
            variant="h4"
            component="h2"
            align="center"
            // color="error"
            sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
          >
            Crear Pedido Pilotado
          </Typography>
          {/* TODO: Pasos para poder crear un pedido pilotado */}
          <Stepper
            style={{ marginBottom: '3rem' }}
            alternativeLabel
            activeStep={currentStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* SI ESTAMOS EN EL STEP 1 MOSTRAREMOS UN FORMULARIO, EN EL 2 OTRO Y EN EL 3 OTRO */}
          {currentStep === 0 && <SelectArteFinal />}
          {currentStep === 1 && <SelectTienda />}
          {currentStep === 2 && <RelArteFinalTienda />}

          {/* BOTONES PARA NAVEGAR ENTRE LAS STEPS */}
          <NavigateSteps disabled={disabled} steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddPedidoPilotado;
