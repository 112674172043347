import { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const CategoryTreeItem = ({ item, categorySelectedToCkeckBox, setCategorySelectedToCkeckBox }) => {
  const [isExapanded, setIsExapanded] = useState(false);

  const handleClickCheckbox = (item) => {
    let newCategories;

    if (categorySelectedToCkeckBox.length > 0) {
      if (!categorySelectedToCkeckBox.includes(item._id)) {
        newCategories = [...categorySelectedToCkeckBox, item._id];
      } else {
        newCategories = categorySelectedToCkeckBox.filter((id) => id !== item._id);
      }
    } else {
      newCategories = [item._id];
    }

    setCategorySelectedToCkeckBox(newCategories);
  };

  const handleClickItem = () => {
    // comprobar si el item tiene hijos
    if (item.children.length === 0) {
      handleClickCheckbox(item);
    } else {
      setIsExapanded((oldExpanded) => !oldExpanded);
    }
  };

  return (
    <div className="dad1">
      <div
        onClick={handleClickItem}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          gap: '0.4rem',
        }}
        className="dad2"
      >
        {item.children.length > 0 ? (
          isExapanded ? (
            <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} />
          ) : (
            <KeyboardArrowRightIcon style={{ cursor: 'pointer' }} />
          )
        ) : (
          <input
            key={item._id}
            type="checkbox"
            style={{ cursor: 'pointer' }}
            checked={categorySelectedToCkeckBox.find((category) => category === item._id)}
          />
        )}
        {/* <p>{item.name}</p> */}
        <p style={{ margin: '0' }}>{item.name}</p>
      </div>

      {isExapanded &&
        item.children &&
        item.children.map((child, i) => (
          <div
            style={{
              marginLeft: '1rem',
            }}
            key={i}
            // className="whattttt the hell"
          >
            <CategoryTreeItem
              key={i}
              item={child}
              categorySelectedToCkeckBox={categorySelectedToCkeckBox}
              setCategorySelectedToCkeckBox={setCategorySelectedToCkeckBox}
            />
          </div>
        ))}
    </div>
  );
};
