import { useParams } from 'react-router-dom';
import { useGetDeleteCategory, useParentCategoryName, useSubcategories } from '../../../hooks/useCategory';
import { Button, Modal } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import { TbPointFilled } from 'react-icons/tb';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import DeleteCategoryRow from './DeleteCategoryRow';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCategory } from '../../../api/categorieApi';
import toast from 'react-hot-toast';
import { useCloseModal } from '../../../hooks/useCloseModal';

const DeleteCategory = () => {
  const { idCategory } = useParams();
  const { parentCategoryName, isLoading } = useParentCategoryName(idCategory);
  const { subcategories } = useSubcategories(idCategory, false);
  const { categories } = useGetDeleteCategory(idCategory);
  const queryClient = useQueryClient();

  const closeModal = useCloseModal(2);

  const mutation = useMutation(deleteCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries('gestion-categories');
      closeModal();
      toast.success('Categoría eliminada correctamente');
    },
    onError: () => {
      toast.error('Error al eliminar la categoría');
    },
  });

  if (isLoading) return;

  const handleDelete = () => {
    mutation.mutate(categories.data);
  };

  return (
    <Modal open onClose={closeModal}>
      <div className="create-cluster-container-modal">
        <IoMdClose onClick={closeModal} size="18px" className="f-right pointer" />
        <div className="center-form">
          <p className="fs-italic">Se eliminarán las siguientes categorías. ¿Está seguro de que desea continuar?</p>

          <div className="fd-column d-flex g-05">
            <div className="d-flex ai-center g-05">
              <TbPointFilled /> {parentCategoryName.data.name}
            </div>
            {subcategories?.data?.map((subcategory, index) => (
              <DeleteCategoryRow key={subcategory._id + index} category={subcategory} />
            ))}
          </div>
        </div>
        <div className="button-group-tiendas">
          <Button
            onClick={handleDelete}
            type="button"
            variant="contained"
            className="bg-color-button-confirm"
            endIcon={<SendIcon />}
          >
            Confirmar
          </Button>
          <Button type="button" variant="contained" color="error" endIcon={<CloseIcon />} onClick={closeModal}>
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteCategory;
