import { Modal } from '@mui/material';
import { useParams } from 'react-router-dom';
import TitleModal from '../../common/TitleModal';
import FormAddMasiveAaff from './FormAddMasiveAaff';
import { useMutation } from '@tanstack/react-query';
import { addAaffToCategory } from '../../../api/categorieApi';
import { useCloseModal } from '../../../hooks/useCloseModal';

const MasiveAddAaff = () => {
  const { idCategory } = useParams();
  const closeModal = useCloseModal(2);

  const mutation = useMutation(addAaffToCategory, {
    onSuccess: () => {
      closeModal();
    },
  });

  return (
    <Modal open onClose={closeModal}>
      <div className="container-modal-pilotado">
        <TitleModal title={'AÑADIR ARTÍCULOS'} />
        <FormAddMasiveAaff idCategory={idCategory} mutation={mutation} handleClose={closeModal} />
      </div>
    </Modal>
  );
};

export default MasiveAddAaff;
