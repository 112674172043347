import axios from 'axios';

export const getFiles = async () => {
  try {
    const res = await axios.get('/api/v1/file');
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getFile = async (id) => {
  try {
    const res = await axios.get(`/api/v1/file/${id}`);
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const editFile = async (data) => {
  try {
    const { file, type, ...rest } = data;

    // Validación: si el tipo es 'archivo', el campo file no puede ser null
    // if (type === 'archivo' && !file) {
    //   throw new Error('El archivo no puede estar vacío');
    // }

    let url = `/api/v1/file/${data._id}`;
    let formData = new FormData();

    if (file) {
      let name = '?file_name=' + rest.name;
      let acl = '&acl=public-read';
      let fileType = '&content_type=' + file.type; // Cambié el nombre para no sobreescribir la variable 'type'

      url += name + acl + fileType;
      formData.append('file', file);
    }

    // Añadir los datos adicionales al FormData
    for (const key in rest) {
      formData.append(key, rest[key]);
    }

    const res = await axios.patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message || 'Error desconocido');
  }
};

export const getGuiasMerchandising = async (token) => {
  try {
    const res = await axios.get('/api/v1/merchandising', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getGuiaMerchandising = async (token, id) => {
  try {
    const res = await axios.get(`/api/v1/merchandising/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const editGuiaMerchandising = async (data, token) => {
  try {
    const { type, file, url: link, ...rest } = data;

    let url = `/api/v1/merchandising/${data._id}`;

    let formData = new FormData();

    if (file) {
      let name = '?file_name=' + rest.name;
      let acl = '&acl=public-read';
      let type = '&content_type=' + file.type;

      url += name + acl + type;
      formData.append('file', file);
    }

    for (const key in rest) {
      formData.append(key, rest[key]);
    }

    formData.append('link', link);

    const res = await axios.patch(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (err) {
    const errorMessage = err.response?.data?.message || err.message || 'Error desconocido';
    throw new Error(errorMessage);
  }
};

export const deleteGuiaMerchandising = async (id, token) => {
  try {
    const res = await axios.delete(`/api/v1/merchandising/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (err) {
    const error = err.response?.data?.status || err.message || 'Error desconocido';
    throw new Error(error);
  }
};

export const getClustersByMerchandising = async (id, token) => {
  try {
    const res = await axios.get(`/api/v1/merchandising/${id}/clusters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (err) {
    const error = err.response?.data?.status || err.message || 'Error desconocido';
    throw new Error(error);
  }
};

export const createMerchandising = async (data, token) => {
  try {
    const { url: link, file, type, ...rest } = data;

    if (type === 'archivo' && !file) {
      throw new Error('El archivo no puede estar vacío');
    }

    let url = `/api/v1/merchandising`;
    let formData = new FormData();

    if (file) {
      let name = '?file_name=' + rest.name;
      let acl = '&acl=public-read';
      let type = '&content_type=' + file.type;

      url += name + acl + type;
      formData.append('file', file);
    }

    for (const key in rest) {
      formData.append(key, rest[key]);
    }
    formData.append('link', link);

    const res = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    const error = err.response?.data?.message || err.message || 'Error desconocido';
    throw new Error(error);
  }
};
