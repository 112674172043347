import { Card, Dialog, DialogContent, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import CloseIcon from '@mui/icons-material/Close';

const CardOrderItem = ({
  item,
  quantity,
  children,
  arteFinalImageOpen,
  setArteFinalImageOpen,
  parentImage,
  setParentImage,
}) => {
  const closeModal = () => {
    setArteFinalImageOpen(false);
  };

  return (
    <>
      <Card style={{ padding: '1rem', marginBottom: '1rem' }}>
        <Row>
          <Colxx xxs="4">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              {children && (
                <span>
                  {item?.arteFinal?.name || item.arteFinalName} <br />{' '}
                  <strong>{item?.formatCode || item.arteFinalCode}</strong>
                </span>
              )}
              <img
                onClick={() => {
                  setParentImage(item?.arteFinal?.imglink || item.imglink);
                  setArteFinalImageOpen(true);
                }}
                src={item?.arteFinal?.imglink || item?.imglink || '/image_not_available.png'}
                alt="Imagen del arte final"
                style={{
                  cursor: 'pointer',
                  objectFit: 'contain',
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '150px',
                  minWidth: '200px',
                  maxWidth: '250px',
                  maxHeight: '300px',
                }}
              />
            </div>
          </Colxx>
          <Colxx xxs="5">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <strong>Código</strong>
              <p style={{ whiteSpace: 'nowrap' }}>{item.formatCode}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <strong>Proveedor</strong>
              <p>{item?.provider.name}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem', marginBottom: '1rem' }}>
              <strong>Cantidad</strong>
              <p>{quantity}</p>
            </div>
          </Colxx>

          <Colxx xxs="3">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <strong>Formato</strong>
              <p>{item.custom || item.format.name}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <strong>Material</strong>
              <p>{item?.materialCustom?.nombre ||item?.material?.nombre || 'Por determinar'}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <strong>Precio</strong>
              <p>{item?.formatPrice?.toFixed(2) + ' €' || 'Por determinar'}</p>
            </div>
          </Colxx>
        </Row>
      </Card>

      <Dialog
        open={arteFinalImageOpen}
        onClose={closeModal}
        maxWidth="xl"
        fullScreen
        PaperProps={{
          style: {
            // backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backgroundColor: '#222222',
            boxShadow: 'none',
            overflow: 'hidden',
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeModal}
          aria-label="close"
          style={{
            position: 'absolute',
            color: '#fff',
            top: '10px',
            right: '10px',
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <DialogTitle style={{ color: '#fff', textAlign: 'center' }}>
            {' '}
            Vista previa{' '}
          </DialogTitle> */}
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // padding: 0,
            // height: '100vh',
          }}
        >
          <img
            crossOrigin="anonymous"
            src={parentImage}
            alt="Imagen a pantalla completa"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CardOrderItem;
