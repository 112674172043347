import { useState } from 'react';
import { DialogWithImg } from './DialogWithImg';
import CartItemImageArteFinal from '../Cart/CartItem/CartItemImageArteFinal';
import ContentArteNotDescargable from './ContentArteNotDescargable';
import ContentArteDescargable from './ContentArteDescargable';

export const OneArteFinal = ({ arteFinal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="card-arte-final">
        <CartItemImageArteFinal
          itemId={arteFinal._id}
          onClick={() => openModal(arteFinal._id, arteFinal.nameArteFinal)}
          styleContainer={{
            userSelect: 'none',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
          }}
          styleImg={{ cursor: 'pointer', objectFit: 'contain', height: '100%', width: '90%' }}
          img={arteFinal?.imglink}
        />

        {/* SI EL ARTE FINAL NO ES DESCARGABLE MOSTRAREMOS EL SIGUIENTE COMPONENTE */}
        {arteFinal?.isDescargable ? (
          <ContentArteDescargable arteFinal={arteFinal} />
        ) : (
          <ContentArteNotDescargable arteFinal={arteFinal} />
        )}
      </div>

      <DialogWithImg
        name={arteFinal.nameArteFinal}
        code={arteFinal.codeArteFinal}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        imgFullScreen={arteFinal?.imglink}
      />
    </>
  );
};
