import { createContext, useState } from 'react';

export const OrdersFiltersUserContext = createContext();

export const OrdersFiltersUserProvider = ({ children }) => {
  /* -------------------------------------------------------------------------- */
  /*               ESTADOS PARA MANEJAR LOS FILTROS SELECCIONADOS               */
  /* -------------------------------------------------------------------------- */
  const [statusFilter, setStatusFilter] = useState([]);
  const [centersFilter, setCentersFilter] = useState([]);
  const [createStartDateFilter, setCreateStartDateFilter] = useState(null);
  const [createEndDateFilter, setCreateEndDateFilter] = useState(null);
  const [deliveryStartDateFilter, setDeliveryStartDateFilter] = useState(null);
  const [deliveryEndDateFilter, setDeliveryEndDateFilter] = useState(null);
  const [orderFilter, setOrderFilter] = useState({});

  const data = {
    statusFilter,
    setStatusFilter,
    centersFilter,
    setCentersFilter,
    createStartDateFilter,
    setCreateStartDateFilter,
    createEndDateFilter,
    setCreateEndDateFilter,
    deliveryStartDateFilter,
    setDeliveryStartDateFilter,
    deliveryEndDateFilter,
    setDeliveryEndDateFilter,
    orderFilter,
    setOrderFilter,
  };

  return <OrdersFiltersUserContext.Provider value={data}>{children}</OrdersFiltersUserContext.Provider>;
};
