import { Button, Menu, MenuItem } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAllMerchandising } from '../../api/carteleriaApi';
import { useAuth0 } from '@auth0/auth0-react';

const MerchandisingButton = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, isError } = useQuery(['merchandising'], () => {
    return getAccessTokenSilently().then((token) => {
      return getAllMerchandising(token);
    });
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPdf = (item) => {
    window.open(item.link, '_blank');
    handleClose();
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="error"
        startIcon={<PictureAsPdfIcon />}
        onClick={handleClick}
        disabled={!data}
      >
        GUÍAS MERCHANDISING
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {data
          ? data?.data.map((item) => (
              <MenuItem className="font-size-07-responsive" key={item._id} onClick={() => openPdf(item)}>
                {item.name}
              </MenuItem>
            ))
          : null}
      </Menu>
    </>
  );
};

export default MerchandisingButton;
