import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

const VersionPopover = ({ anchorEl, onClose }) => (
  <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Typography sx={{ p: 2 }}>Esta es la versión más alta.</Typography>
  </Popover>
);

const ConfirmationPopover = ({ anchorEl, onClose, onConfirm, onCancel }) => (
  <Popover
    open={Boolean(anchorEl)}
    style={{left: '30%'}}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Typography sx={{ p: 2 }}>
      ¿Estás seguro de que quieres cambiar la cantidad?
      <Button onClick={onConfirm} variant="contained" sx={{ mr: 1, background: '#333' }}>
        Confirmar
      </Button>
      <Button onClick={onCancel} variant="contained" color="error">
        Cancelar
      </Button>
    </Typography>
  </Popover>
);

export { VersionPopover, ConfirmationPopover };
