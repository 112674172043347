import { Button } from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';

const ManualUsoButton = ({ path }) => {
  const handleButtonClick = () => {
    window.open(path, '_blank', 'noopener,noreferrer');
  };

  return (
    <Button size="small" variant="contained" startIcon={<InfoIcon />} onClick={handleButtonClick} style={{ backgroundColor: '#646463' }}>
      MANUAL DE USO
    </Button>
  );
};

export default ManualUsoButton; 
