import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getInfoArteFinal,
  getInfoArteFinalEdit,
  getPdfLanguageByArteFinal,
  updateArteFinal,
  updateArteFinalWithCategories,
} from '../api/arteFinalApi';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { createArteFinal, createArteFinalImprimible } from '../helpers/ArteFinal/arteFinalHelper';
import { useFormik } from 'formik';

export const useUpdateArteFinal = (idArte) => {
  const { handleClose } = useCloseEditArteFinal();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (values) => {
      let token = await getAccessTokenSilently();
      await updateArteFinalWithCategories(idArte, values, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['info-arte-final', idArte],
      });
      queryClient.invalidateQueries({
        queryKey: ['categories-tree-by-arte', idArte],
      });
      queryClient.invalidateQueries('all-artes-finales-master');
      toast.success('Arte final actualizado con éxito');
      handleClose();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return { mutation };
};

export const useGetPdfLanguages = (idArte) => {
  return useQuery({
    queryKey: ['pdf-languages', idArte],
    queryFn: () => getPdfLanguageByArteFinal(idArte),
  });
};

export const useCloseEditArteFinal = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    let newPath = location.pathname;

    if (location.pathname.endsWith('/')) {
      newPath = newPath.slice(0, -1);
    }

    newPath = newPath.substring(0, newPath.lastIndexOf('/'));

    navigate(newPath || '/', { replace: true });
  };

  return { handleClose };
};

export const useGetEditArteFinal = (id) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['info-arte-final-edit', id],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getInfoArteFinalEdit(id, token);
    },
  });
};

export const useGetInfoArteFinal = (id) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading } = useQuery(['info-arte-final', id], async () => {
    const token = await getAccessTokenSilently();
    return getInfoArteFinal(id, token);
  });

  return { data, isLoading };
};

export const useValidateArteFinal = (idArte) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (values) => {
      let token = await getAccessTokenSilently();
      updateArteFinal(idArte, values, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['info-arte-final', idArte],
      });
      queryClient.invalidateQueries('all-artes-finales-master');
      toast.success('¡Actualización de la validación exitosa!');
    },
    onError: () => {
      toast.error('Error al actualizar la validación');
    },
  });

  return { mutation };
};

export const useCreateArteFinal = (setActiveStep) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const handleClose = () => {
    let newPath = location.pathname;

    if (location.pathname.endsWith('/')) {
      newPath = newPath.slice(0, -1);
    }

    newPath = newPath.substring(0, newPath.lastIndexOf('/'));

    navigate(newPath || '/', { replace: true });
  };

  const mutationFirstStep = useMutation(createArteFinalImprimible, {
    onSuccess: () => {
      queryClient.invalidateQueries('all-artes-finales-master');
      handleClose();
    },
    onError: () => {
      toast.error('Error al crear el arte final imprimible');
    },
  });

  const mutationLastStep = useMutation(createArteFinal, {
    onSuccess: () => {
      queryClient.invalidateQueries('all-artes-finales-master');
      handleClose();
    },
    onError: () => {
      toast.error('Error al crear el arte final');
    },
  });

  const initialValuesArteFinal = {
    code: '',
    name: '',
    category: [],
    centerType: [],
    favourite: [],
    active: true,
    isMontaje: false,
    isDescargable: false,
    minQuantity: 1,
    regional: null,
    isImagenNueva: true,
    isImagenAnterior: true,
    formatCustom: false,
    image: null,
  };

  const formikArteFinal = useFormik({
    initialValues: initialValuesArteFinal,
    onSubmit: async (values) => {
      if (values.category.length === 0) {
        toast.error('Debe seleccionar al menos una categoría');
        return;
      }
      if (!values.isDescargable) {
        setActiveStep(1);
      } else {
        const token = await getAccessTokenSilently();
        mutationFirstStep.mutate({ token, body: values });
      }
    },
  });

  const initialValuesFormat = {
    code: '',
    name: '',
    material: '',
  };

  const formikFormat = useFormik({
    initialValues: initialValuesFormat,
    onSubmit: () => setActiveStep(2),
  });

  const initialValuesProviderFormat = {
    code: '',
    provider: '',
    price: 0,
  };

  const formikProviderFormat = useFormik({
    initialValues: initialValuesProviderFormat,
    onSubmit: async (values) => {
      const token = await getAccessTokenSilently();
      mutationLastStep.mutate({
        token,
        bodyArteFinal: formikArteFinal.values,
        bodyFormat: formikFormat.values,
        bodyProviderFormat: values,
      });
    },
  });

  return { formikArteFinal, formikFormat, formikProviderFormat, handleClose };
};
