import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { useFormik } from 'formik';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { useGetProviderFormat, useUpdateProviderFormat } from '../../../hooks/useFormats';

const PageModalFormatProvider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { idProviderFormat, idArte } = useParams();
  const { data: providerFormat, isLoading } = useGetProviderFormat(idProviderFormat);
  const { mutation } = useUpdateProviderFormat(idArte);

  const formik = useFormik({
    initialValues: {
      code: '',
      provider: '',
      format: '',
      arteFinal: '',
      price: '',
      active: false,
    },
    onSubmit: async (values) => {
      mutation.mutate({
        ...values,
        price: typeof values.price === 'number' ? values.price : parseFloat(values.price.replace(',', '.')),
        _id: idProviderFormat,
      });
      handleClose();
    },
  });

  useEffect(() => {
    if (!isLoading && providerFormat) {
      formik.setValues({
        code: providerFormat.data.code,
        provider: providerFormat.data.provider._id,
        format: providerFormat.data.format,
        arteFinal: providerFormat.data.arteFinal,
        price: providerFormat.data.price,
        active: providerFormat.data.active,
      });
    }
  }, [isLoading, providerFormat]);

  const handleClose = () => {
    const pathSegments = location.pathname.split('/').filter((segment) => segment.length > 0);
    if (pathSegments.length > 2) {
      const newPath = `/${pathSegments.slice(0, -2).join('/')}`;
      navigate(newPath);
    } else {
      navigate('/');
    }
  };

  const handlePriceChange = (event) => {
    const value = event.target.value;
    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
      formik.setFieldValue('price', value);
    }
  };

  return (
    <Modal open onClose={handleClose}>
      <div className="modal-create-edit-format">
        {isLoading ? (
          <div className="modal-create-edit-format-loading-container">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Typography
              variant="h4"
              component="h2"
              align="center"
              sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
            >
              Información del proveedor asociado
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="code"
                    label="Código *"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    name="provider"
                    label="Proveedor"
                    value={providerFormat.data.provider.shortName}
                  />
                </Grid>
                {!providerFormat.data.format.isCustom && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="price"
                      label="Precio *"
                      value={formik.values.price}
                      onChange={handlePriceChange}
                      error={formik.touched.price && Boolean(formik.errors.price)}
                      helperText={formik.touched.price && formik.errors.price}
                    />
                  </Grid>
                )}             
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="status-format-provider">Estado</InputLabel>
                    <Select
                      fullWidth
                      name="active"
                      label="Estado"
                      labelId="status-format-provider"
                      value={formik.values.active}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={true}>Activo</MenuItem>
                      <MenuItem value={false}>Inactivo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
                <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
                  Confirmar
                </Button>
                <Button type="button" variant="contained" color="error" endIcon={<CloseIcon />} onClick={handleClose}>
                  Cancelar
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PageModalFormatProvider;
