import React, { useContext, useEffect, useState } from 'react';
import { Card, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import SearchInput from './SearchInput';
import SortByInput from './SortByInput';
import { PartidasContext } from '../../context/PartidasContext';
import PriceFilter from './PriceFilter';
import FilterByVersionModel from './FilterByVersionModel';

const FiltersPartidaProyecto = () => {
  const { inputSearch, setInputSearch, sortValue, setSortValue } = useContext(PartidasContext);
 

  return (
    <Card
      style={{
        position: 'relative',
      }}
      className="filters-info-container"
    >
      <Row style={{ width: '100%' }}>
        <Colxx xxs="4">
          {/* BUSCADOR */}
          <SearchInput value={inputSearch} onChange={(value) => setInputSearch(value)} />
        </Colxx>

        <Colxx
          style={{
            marginTop: '.2rem',
          }}
          xxs="3"
        >
          {/* AQUI VA A IR EL FILTRO DE LAS VERSIONES (TODAS LAS VERSONES DISPONIBLES DEL MODELO) */}

          <FilterByVersionModel />
        </Colxx>

        <Colxx
          style={{
            marginTop: '.2rem',
          }}
          xxs="2"
        >
          {/* PRECIO UNITARIO */}
          <PriceFilter />
        </Colxx>

        <Colxx
          style={{
            marginTop: '.2rem',
          }}
          xxs="3"
        >
          {/* ORDENAR POR */}
          <SortByInput sortValue={sortValue} onChange={(value) => setSortValue(value)} />
        </Colxx>
      </Row>
    </Card>
  );
};

export default FiltersPartidaProyecto;
