import FormatItem from './FormatInfo/FormatItem';
import { useParams } from 'react-router-dom';
import FormatItemTitle from './FormatInfo/FormatItemTitle';
import AddFormatProvider from './FormatInfo/AddFormatProvider';
import { useGetFormatsByArteFinal } from '../../../hooks/useFormats';
import SkeletonLoader from '../../common/SkeletonLoader';
import NotFormats from './FormatInfo/NotFormats';

export const FormatsSpecificationsByArte = () => {
  const { idArte } = useParams();
  const { data: formats, isLoading } = useGetFormatsByArteFinal(idArte);

  if (isLoading) return <SkeletonLoader count={3} minHeight={75} />;
  if (formats.data.length === 0) return <NotFormats />;
  return (
    <div className="format-container format-container--column">
      {formats.data.map((format) => (
        <div key={format._id}>
          <FormatItemTitle totalFormats={formats.data.length} format={format} />
          <div className="format-container format-container--grid">
            {format.providerFormat
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((formato) => (
                <FormatItem
                  key={formato._id}
                  numProviderFormat={format.providerFormat.length}
                  isCustom={format.isCustom}
                  formato={formato}
                />
              ))}
            <AddFormatProvider format={format} />
          </div>
        </div>
      ))}
    </div>
  );
};
