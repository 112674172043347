import { useSearchParams } from 'react-router-dom';

const useQueryParams = (params, defaultValues = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueryParams = () => {
    return params.reduce((acc, param) => {
      acc[param] = searchParams.get(param) || defaultValues[param] || null;
      return acc;
    }, {});
  };

  const handleParam = (params, paramsReset = []) => {
    setSearchParams((prevState) => {
      let prevParams = Object.fromEntries(prevState.entries());

      // Si resetPage es true, eliminar el parámetro si existe
      if (paramsReset.length > 0) {
        paramsReset.forEach((param) => {
          if (prevParams[param]) {
            delete prevParams[param];
          }
        });
      }

      return new URLSearchParams({
        ...prevParams,
        ...params,
      });
    });
  };

  return [getQueryParams(), handleParam];
};

export default useQueryParams;
