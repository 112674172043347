import { useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { UserContext } from '../../../context/UserProvider';
import { Switch } from '@mui/material';
import { ConfirmarPedido } from '../../Pedido/ConfirmarPedido';
import { useValidateArteFinal } from '../../../hooks/useArteFinal';

const HeaderArteFinal = ({ infoArteFinal }) => {
  const { idArte } = useParams();
  const { userRole } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [openPopoverSwitch, setOpenPopoverSwitch] = useState(null);
  const { mutation } = useValidateArteFinal(idArte);

  const handleReturnAllArtes = () => {
    let newPath = location.pathname;

    if (location.pathname.endsWith('/')) {
      newPath = newPath.slice(0, -1);
    }

    newPath = newPath.substring(0, newPath.lastIndexOf('/'));

    navigate(newPath || '/', { replace: true });
  };

  const handleOpenSwitchPopover = (e) => {
    setOpenPopoverSwitch(e.currentTarget);
  };

  const handleCloseSwitchPopover = () => {
    setOpenPopoverSwitch(null);
  };

  const handleSwitchChange = async () => {
    const body = { isValidate: !infoArteFinal.isValidate };
    mutation.mutate(body);
    handleCloseSwitchPopover();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', height: '35px', alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '1rem',
          textAlign: 'center',
          marginLeft: '1rem',
        }}
      >
        <p
          style={{ marginRight: '1rem', cursor: 'pointer', fontSize: 'large' }}
          onClick={handleReturnAllArtes}
          className="return-tooltip-catalogo"
        >
          Volver
        </p>
        <Tooltip anchorSelect=".return-tooltip-catalogo" place="bottom">
          <span>Volver a todos los artes finales</span>
        </Tooltip>
        <p style={{ fontSize: 'large' }}> {'>'} </p>
        <p style={{ marginLeft: '1rem', fontSize: 'large' }}>{infoArteFinal.code} - </p>
        <p style={{ fontSize: 'large' }}>
          {infoArteFinal.name.length > 50 ? infoArteFinal.name.substring(0, 50) + '...' : infoArteFinal.name}
        </p>
        {userRole === 'MasterAdmin' && (
          <div style={{ margin: '0 0 1rem 4rem' }}>
            <Switch
              className="switch-validate-tooltip"
              checked={infoArteFinal.isValidate}
              onChange={(e) => handleOpenSwitchPopover(e)}
            />
          </div>
        )}
        {infoArteFinal.isValidate ? (
          <Tooltip anchorSelect=".switch-validate-tooltip" place="bottom">
            <span>Invalidar Arte Final</span>
          </Tooltip>
        ) : (
          <Tooltip anchorSelect=".switch-validate-tooltip" place="bottom">
            <span>Validar Arte Final </span>
          </Tooltip>
        )}
      </div>

      <ConfirmarPedido
        confirmarPedidoPopover={openPopoverSwitch}
        handleCloseConfirmarPedidoPopover={handleCloseSwitchPopover}
        handleDeleteConfirm={handleSwitchChange}
        mensaje="¿Está seguro que desea cambiar el estado de validación del arte final?"
      />
    </div>
  );
};

export default HeaderArteFinal;
