import SingleOrderItem from './SingleOrderItem';

const AaffNoCompuesto = ({ aaffNoCompuesto }) => {
  return (
    <div className="aaff-no-compuesto-container">
      <SingleOrderItem item={aaffNoCompuesto}  image={aaffNoCompuesto.imglink} />
    </div>
  );
};

export default AaffNoCompuesto;
