import React, { useState, useEffect, useContext } from 'react';
import { ViewerContext } from '../../context/ViewerContext';

import '../../css/structure.css';
import zIndex from '@mui/material/styles/zIndex';
const Viewer = () => {
  // getData();

  const { viewerRef } = useContext(ViewerContext);
  return (
    <>
      <div>
        <div id="forgeViewer" className="forge-viewer h-96" style={{ zIndex: 55 }} ref={viewerRef} />
        <div id="label" />
      </div>
    </>
  );
};

export default Viewer;
