import '../../css/detail.css';
import { getCostesInstalacionOrder, getOrderOne, updateOrder } from '../../api/carteleriaApi';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams, useLocation, Outlet } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es'; // the locale you want
import { ProductosTable } from './ProductosTable';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import OrderComments from '../common/OrderComments';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Tooltip } from 'react-tooltip';
import toast from 'react-hot-toast';
import { CiExport } from 'react-icons/ci';
import { getTextColorBasedOnBackground } from '../../helpers/colorsHelper';
import { generateOrderPdf } from '../../helpers/Orders/orderHelper';
import CostesInstalacion from './CostesInstalacion';
import SendEmailStatusOrder from './SendEmailStatusOrder';
import SelectorStatus from './SelectorStatus';
import { useEffect, useState } from 'react';
import { useUpdateMultipleOrderItems } from '../../lib/react-query/orderItemQuery';

export const DetailOrder = ({ admin = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { getAccessTokenSilently, user } = useAuth0();
  const { mutate } = useUpdateMultipleOrderItems();

  const { data, isFetching, refetch } = useQuery(['order', id], async () => {
    const token = await getAccessTokenSilently();
    return await getOrderOne(token, id);
  });

  const {
    data: costeInstalacion,
    isFetching: isFetchingCostesInstalacion,
    refetch: refetchCostesInstalacion,
  } = useQuery(['costes-instalacion', id], async () => {
    const token = await getAccessTokenSilently();
    return await getCostesInstalacionOrder(token, id);
  });

  // ESTADOS NECESARIOS PARA EL FUNCIONAMIENTO DEL COMPONENTE
  const [status, setStatus] = useState(null);
  const [deliverableDate, setDeliverableDate] = useState(undefined);
  const [orderItemnsWithQuantity, setOrderItemnsWithQuantity] = useState({});
  const [customFormatsModified, setCustomFormatsModified] = useState({});

  const breadcrumbs = [
    <span
      className="breadcrumb-hover"
      style={{ cursor: 'pointer' }}
      onClick={() =>
        navigate(location.pathname.replace(location.pathname.split('/')[location.pathname.split('/').length - 1], ''))
      }
      key="1"
    >
      Solicitudes
    </span>,
    <h2 style={{ userSelect: 'none' }} key="2">
      Detalles de la solicitud
    </h2>,
  ];

  const formatDate = (date) => {
    if (!date) return null;
    return moment(date).format('DD/MM/YYYY');
  };

  const handleClickRestoreAll = () => {
    setDeliverableDate(undefined);
    setStatus(null);
    setOrderItemnsWithQuantity({});
    setCustomFormatsModified({});
  };

  const handleClickUpdateOrder = async () => {
    toast.loading('Actualizando solicitud', {
      id: 'updating-order',
    });

    const token = await getAccessTokenSilently();
    // *: Si se ha modificado algún proveedor de un custom format, modificar los orderItems
    if (Object.keys(customFormatsModified).length > 0) {
      await mutate({ token, ordersItem: customFormatsModified });
    }

    const result = await updateOrder(token, id, {
      user: { name: user.nickname, email: user.email, id: user.sub },
      status: status?.value,
      deliverableDate: deliverableDate,
      orderItems: orderItemnsWithQuantity,
    });

    toast.dismiss('updating-order');

    if (result === 'success') {
      toast.success('Solicitud actualizada correctamente');
    } else {
      toast.error('Error al actualizar la solicitud');
    }

    setDeliverableDate(undefined);
    setStatus(null);
    setOrderItemnsWithQuantity({});
    setCustomFormatsModified({});
    refetch();
  };

  const handleClickGeneratePdf = async (order, costeInstalacion) => {
    toast.loading('Generando PDF', {
      id: 'generating-pdf',
    });
    const token = await getAccessTokenSilently();
    const result = await generateOrderPdf(token, order, costeInstalacion?.data?.costeInstalacionOrders);
    toast.dismiss('generating-pdf');
    if (result === 'success') {
      toast.success('PDF generado correctamente');
    } else {
      toast.error('Error al generar el PDF');
    }
  };

  const isButtonDisabled = () => {
    return (
      !status &&
      deliverableDate === undefined &&
      Object.keys(orderItemnsWithQuantity).length === 0 &&
      Object.keys(customFormatsModified).length === 0
    );
  };

  return (
    <>
      <div className="parentDetail">
        <div className="cardDetail">
          {/* <h2 className="title-order">Detalles del pedido</h2> */}
          <div style={{ alignItems: 'center', marginBottom: '1rem', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>

              <>
                <button
                  onClick={() => handleClickGeneratePdf(data.data, costeInstalacion)}
                  className="buttons-order-title"
                >
                  PDF <CiExport size="15px" />
                </button>
                {admin && <SendEmailStatusOrder />}
              </>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              {isButtonDisabled() ? null : (
                <RestartAltIcon
                  onClick={handleClickRestoreAll}
                  className="tooltip-restore-all"
                  style={{ cursor: 'pointer' }}
                />
              )}
              <Tooltip anchorSelect=".tooltip-restore-all" place="top">
                <span>Restaurar valores originales</span>
              </Tooltip>

              {admin && (
                <button
                  onClick={handleClickUpdateOrder}
                  style={{
                    opacity: '0.85',
                    borderColor: 'transparent',
                    backgroundColor: isButtonDisabled() ? '#868788' : '#E0001A',
                  }}
                  disabled={isButtonDisabled()}
                  className="btn btn-primary"
                >
                  Actualizar solicitud
                </button>
              )}
            </div>
          </div>

          {isFetching && <div className="custom-loader"></div>}

          {!isFetching && (
            <>
              <div className="general-data">
                <div className="columna line">Usuario </div>
                <div className="columna line">{data.data.order[0].user}</div>
                <div className="columna line">Nombre de contacto</div>
                <div className="columna line">{data.data.order[0].contactName}</div>
                <div className="columna line">Teléfono de contacto</div>
                <div className="columna line">{data.data.order[0].contactPhone}</div>
                <div className="columna line">Extensión</div>
                <div className="columna line">{data.data.order[0].extension || 'Sin extensión'}</div>
                <div className="columna line"> Creado el</div>
                <div className="columna line">{formatDate(data.data.order[0].createdAt)}</div>
                <div className="columna line">Estado de la solicitud</div>
                <div className="columna line">
                  {admin ? (
                    <SelectorStatus data={data} status={status} setStatus={setStatus} />
                  ) : (
                    <span
                      style={{
                        padding: '.5rem 1rem',
                        backgroundColor: data.data.order[0].colorStatus,
                        color: getTextColorBasedOnBackground(data.data.order[0].colorStatus),
                      }}
                    >
                      {data.data.order[0].status}
                    </span>
                  )}
                </div>

                <div className="columna line">Montaje</div>
                <div className="columna line">{data.data.order[0].montaje ? 'Si' : 'No'}</div>
                <div className="columna line ">Fecha de entrega </div>
                <div className="columna line ">
                  <>
                    {admin ? (
                      <DatePicker
                        wrapperClassName={`${!admin && 'cursor-default'} test-date-picker`}
                        className={`${!admin && 'cursor-default'}`}
                        icon={<CalendarMonthIcon />}
                        selected={
                          deliverableDate ||
                          (data?.data?.order[0]?.deliverableDate
                            ? moment(data.data.order[0].deliverableDate).toDate()
                            : null)
                        }
                        placeholderText="Fecha de entrega por determinar"
                        readOnly={!admin}
                        disabled={!admin}
                        onChange={(date) => setDeliverableDate(date)}
                        locale={es}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                      />
                    ) : (
                      <span>
                        {formatDate(data?.data?.order[0]?.deliverableDate) || 'Fecha de entrega por determinar'}
                      </span>
                    )}
                  </>
                </div>
              </div>

              {/* AQUI VA A IR EL APARTADO DE COSTES DE INSTALACION */}
              {data?.data?.order && (
                <CostesInstalacion
                  data={costeInstalacion}
                  isFetching={isFetchingCostesInstalacion}
                  refetch={refetchCostesInstalacion}
                  admin={admin}
                  orderId={id}
                />
              )}

              {/* AQUI VAN A IR TODOS LOS ORDER ITEMS */}
              <h2 className="title-order">Productos</h2>
              {/*//! Desde aqui tengo que cambiarlo a una tabla NORMAL */}
              {data?.data?.orderItems && (
                <ProductosTable
                  admin={admin}
                  data={data?.data?.orderItems}
                  setOrderItemnsWithQuantity={setOrderItemnsWithQuantity}
                  orderItemnsWithQuantity={orderItemnsWithQuantity}
                  customFormatsModified={customFormatsModified}
                  setCustomFormatsModified={setCustomFormatsModified}
                />
              )}

              <div style={{ marginBottom: '3rem', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <h2 className="title-order" style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                  Importe total de la solicitud:{' '}
                  {(
                    data?.data?.orderItems.reduce(
                      (acc, orderItem) =>
                        acc + (orderItem?.totalPrice ? orderItem.totalPrice : orderItem.price) * orderItem.quantity,
                      0,
                    ) +
                    costeInstalacion?.data?.costeInstalacionOrders.reduce(
                      (acc, costeInstalacionOrder) =>
                        acc + costeInstalacionOrder.costeInstalacion.coste * costeInstalacionOrder.cantidad,
                      0,
                    )
                  ).toFixed(2) + ' €'}
                </h2>
              </div>

              {/* Comentarios del pedido */}
              <OrderComments orderId={data.data.order[0]._id} admin={admin} />
            </>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
};
