import { useEffect } from 'react';
import useQueryParams from '../../../hooks/useQueryParams';
import SelectTypeChart from './SelectTypeChart';
import TypeCharts from './TypeCharts';

const StatsChart = ({ data, keys, isLoading }) => {
  const [{ chart }, handleParam] = useQueryParams(['chart']);

  useEffect(() => {
    if (!chart) {
      handleParam({ chart: 'bar' });
    }
  }, [chart]);

  if (isLoading) return <div className="mt-3 custom-loader" />;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SelectTypeChart />
      </div>
      <TypeCharts type={chart} data={data.data} keys={keys} />
    </>
  );
};

export default StatsChart;
