import toast from 'react-hot-toast';

/* -------------------------------------------------------------------------- */
/*                       FUNCION PARA MOVER CATEGORÍA                         */
/* -------------------------------------------------------------------------- */
export const moveCategory = (params) => {
  const updateCategories = [...params.categoriesOrder];
  const [removed] = updateCategories.splice(params.dragIndex, 1);
  updateCategories.splice(params.hoverIndex, 0, removed);

  updateCategories.forEach((subcat, index) => {
    subcat.index = index + 1;
  });

  params.setCategoriesOrder(updateCategories);
};

/* -------------------------------------------------------------------------- */
/*           FUNCION PARA CAMBIAR EL NOMBRE DE LA NUEVA CATEGORÍA          */
/* -------------------------------------------------------------------------- */
export const handleChangeNameNewCategory = (e, setNewCategory) => {
  setNewCategory(e.target.value);
};

/* -------------------------------------------------------------------------- */
/*                      FUNCION PARA AÑADIR CATEGORÍA                      */
/* -------------------------------------------------------------------------- */
export const handleAddCategory = (params) => {
  if (params.newCategory.length < 3) {
    params.toast.error('El nombre de la categoría debe tener al menos 3 caracteres', {
      id: 'error-category-name',
    });
  } else {
    params.setCategoriesOrder((prev) => [
      ...prev,
      {
        name: params.newCategory,
        index: params.categoriesOrder.length + 1,
        new: true,
        ...(params.idCategory && { parentCategory: params.idCategory }), // Añadir parentCategory solo si idCategory está presente
      },
    ]);
    params.setNewCategory('');
  }
};

/* -------------------------------------------------------------------------- */
/*                 FUNCION PARA ELIMINAR CATEGORÍA AÑADIDA                 */
/* -------------------------------------------------------------------------- */
export const handleDeleteCategory = (index, categoriesOrder, setCategoriesOrder) => {
  const updateCategories = [...categoriesOrder];
  updateCategories.splice(index, 1);

  updateCategories.forEach((subcat, index) => {
    subcat.index = index + 1;
  });

  setCategoriesOrder(updateCategories);
};

/* -------------------------------------------------------------------------- */
/*                   FUNCION PARA CREAR NUEVAS CATEGORÍAS                  */
/* -------------------------------------------------------------------------- */
export const handleCreateNewCategories = async (params) => {
  params.e.preventDefault();
  if (params.categoriesOrder.length === params.categories.length) {
    params.toast.error('No se ha añadido ninguna categoría', {
      id: 'error-no-categories-added',
    });
    return;
  }

  params.mutation.mutate(params.categoriesOrder, {
    onSuccess: () => {
      params.toast.success('Categorías creadas con éxito');
      params.toast.dismiss('no-categories-toast');
      handleClose(params.navigate);
    },
    onError: (err) => {
      params.toast.error('Error al crear categorías');
      console.error(err);
    },
  });
};

/* -------------------------------------------------------------------------- */
/*                      FUNCION PARA EDITAR UNA CATEGORIA                     */
/* -------------------------------------------------------------------------- */
export const handleEditCategory = async (params) => {
  params.e.preventDefault();
  if (params.newCategoryName.length < 3) {
    params.toast.error('El nombre de la categoría debe tener al menos 3 caracteres', {
      id: 'error-category-name',
    });
    return;
  }
  params.mutation.mutate(
    {
      idCategory: params.category,
      newCategoryName: params.newCategoryName,
      disabled: params.disabled,
      important: params.important,
    },
    {
      onSuccess: () => {
        params.toast.success('Categoría editada con éxito');
        handleClose(params.navigate);
      },
      onError: (err) => {
        params.toast.error('Error al editar la categoría');
        console.error(err);
      },
    },
  );
};

/* -------------------------------------------------------------------------- */
/*                        FUNCION PARA CERRAR EL MODAL                        */
/* -------------------------------------------------------------------------- */
export const handleClose = (navigate) => {
  navigate('/master/categorias');
};

/* -------------------------------------------------------------------------- */
/*                       FUNCIONES PARA MOVER CATEGORÍAS                      */
/* -------------------------------------------------------------------------- */
export const handleClickIcon = (params) => {
  // Ocultar el tooltip del icono clicado
  if (params.tooltipRef.current) {
    params.tooltipRef.current.hideTooltip();
  }

  let pathname = params.location.pathname;

  if (pathname.endsWith('/')) {
    pathname = pathname.slice(0, -1);
  }

  params.navigate(`${pathname}/${params.newLocation}/${params.categoryId}`);
};

export const handleMoveCategory = (params) => {
  params.mutation.mutate(
    { id: params.id, index: params.index, parentCategory: params.parentCategory },
    {
      onSuccess: () => {
        params.setCategoryMove(null);
        toast.success('Categoría movida con éxito');
      },
      onError: (err) => {
        if (err.response?.data?.status === 'cant-move-category') {
          params.authorize();
          setTimeout(() => {
            params.navigate(`/master/categorias/cant-move/${params.id}/${err.response.data.data}`);
          }, 100);
        } else {
          toast.error('Error al mover la categoría');
        }
      },
    },
  );
};

export const getIndex = (categoryMove, category, direction) => {
  let index;
  if (direction === 'down' && category.parentCategory === categoryMove.parentCategory) {
    if (category.index > categoryMove.index) {
      index = category.index;
    } else {
      index = category.index + 1;
    }
  } else if (direction === 'up' && category.parentCategory === categoryMove.parentCategory) {
    if (category.index < categoryMove.index) {
      index = category.index;
    } else {
      index = category.index !== 1 ? category.index - 1 : category.index;
    }
  } else if (direction === 'into') {
    index = 1;
  } else {
    // ESTAMOS MOVIENDO ENTRE CATEGORIAS PADRE
    if (direction === 'down') {
      index = category.index + 1;
    } else {
      index = category.index;
    }
  }
  return index;
};

export const getParentCategory = (direction, category) => {
  if (direction === 'into') {
    return category._id;
  } else {
    return category.parentCategory;
  }
};

export const handleClickSelectedAaff = (params) => {
  // Encontrar el índice del aaff seleccionado en aaffSelected
  const aaffIndex = params.aaffSelected.findIndex((a) => a._id === params.aaff._id);

  // Si no está en aaffSelected, añadirlo
  if (aaffIndex === -1) {
    params.setAaffSelected((prev) => [...prev, { ...params.aaff }]);
  }

  // Encontrar el índice del aaff en aaffNoSelected
  const aaffNoSelectedIndex = params.aaffNoSelected.findIndex((a) => a._id === params.aaff._id);

  // Remover el aaff de aaffNoSelected
  if (aaffNoSelectedIndex !== -1) {
    params.setAaffNoSelected((prev) => {
      const temp = [...prev];
      temp[aaffNoSelectedIndex].selected = true;
      return temp;
    });
  }
};

export const handleRemoveSelectedAaff = (params) => {
  const aaffIndex = params.aaffSelected.findIndex((a) => a._id === params.aaff._id);

  // Remover el aaff de aaffSelected
  if (aaffIndex !== -1) {
    params.setAaffSelected((prev) => prev.filter((a) => a._id !== params.aaff._id));
  }

  // Buscar el aaff en aaffArray y cambiar su propiedad selected a false
  const aaffArrayIndex = params.aaffNoSelected.findIndex((a) => a._id === params.aaff._id);
  if (aaffArrayIndex !== -1) {
    params.setAaffNoSelected((prev) => {
      const temp = [...prev];
      temp[aaffArrayIndex].selected = false;
      return temp;
    });
  }
};

export const handleCreateAaffMasiveToCategory = async (params) => {
  params.e.preventDefault();
  if (params.aaffSelected.length === 0) {
    toast.error('No se ha añadido ningún artículo', {
      id: 'error-no-aaff-added',
    });
    return;
  }

  params.mutation.mutate(
    { idCategory: params.idCategory, aaffSelected: params.aaffSelected },
    {
      onSuccess: () => {
        toast.success('Artículos añadidos con éxito');
      },
      onError: (err) => {
        toast.error('Error al añadir los artículos');
        console.error(err);
      },
    },
  );
};
