import Table from '@mui/material/Table';
import { Button, Popover } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell, StyledTableRow } from '../RecycledComponents';
import Paper from '@mui/material/Paper';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useLocation, useNavigate } from 'react-router-dom';
import { DetailOrderContext } from '../../context/DetailOrderContext';
import { useContext } from 'react';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { Tooltip as Tooltip2 } from 'react-tooltip';
import toast from 'react-hot-toast';
import SelecteProviderCustom from './ElementsTable/SelecteProviderCustom';

export const ProductosTable = ({
  data,
  orderItemnsWithQuantity,
  setOrderItemnsWithQuantity,
  customFormatsModified,
  setCustomFormatsModified,
  admin,
}) => {
  const { setItemOrderIds } = useContext(DetailOrderContext);

  const navigate = useNavigate();
  const location = useLocation();

  const handleIncrement = (item) => {
    const orderId = item._id;

    // TODO: Lo primero es comprobar que la operacion no de 0, si va a dar 0 tendremos que sacar un moda o algo del estilo para confirmar que se quiere eliminar el producto

    if (orderItemnsWithQuantity[orderId]?.quantity + 1 === data.find((item) => item._id === orderId).quantity) {
      // Eliminar el item del objeto
      const newOrderItemnsWithQuantity = { ...orderItemnsWithQuantity };
      delete newOrderItemnsWithQuantity[orderId];

      // Utiliza newOrderItemnsWithQuantity según tus necesidades
      setOrderItemnsWithQuantity(newOrderItemnsWithQuantity);
      return;
    }

    // Si el item no existe, lo agregamos con cantidad ajustada
    if (!orderItemnsWithQuantity.hasOwnProperty(orderId)) {
      setOrderItemnsWithQuantity({
        ...orderItemnsWithQuantity,
        [orderId]: {
          formatChildren:
            item?.formatChildren?.length > 0
              ? item.formatChildren.map((child) => ({
                  ...child,
                  // Dividir la cantidad de este hijo entre la cantidad total de formatChildren y multiplicar por la nueva cantidad del pedido
                  quantity: Math.max((child.quantity / item.quantity) * (item.quantity + 1), 0),
                }))
              : [],
          quantity: Math.max(item.quantity + 1, 0),
        },
      });
    } else {
      const newOrderItemsWithQuantity = {
        ...orderItemnsWithQuantity,
        [orderId]: {
          formatChildren:
            orderItemnsWithQuantity[orderId]?.formatChildren?.length > 0
              ? orderItemnsWithQuantity[orderId].formatChildren.map((child) => ({
                  ...child,
                  // Dividir la cantidad de este hijo entre la cantidad total de formatChildren y multiplicar por la nueva cantidad del pedido
                  quantity: Math.max(
                    (child.quantity / orderItemnsWithQuantity[orderId].quantity) *
                      (orderItemnsWithQuantity[orderId].quantity + 1),
                    0,
                  ),
                }))
              : [],
          quantity: Math.max(orderItemnsWithQuantity[orderId].quantity + 1, 0),
        },
      };

      setOrderItemnsWithQuantity(newOrderItemsWithQuantity);
    }
  };

  const handleDecrement = (item) => {
    const orderId = item._id;

    // SI HAY  LA CANTIDAD ES 1 Y NO HAY MAS DE UN ITEM, NO SE PUEDE ELIMINAR MOSTRAR ALERTA INDICANDOLO
    if (
      (data?.length > 1
        ? Object.keys(orderItemnsWithQuantity).length > 0
          ? !Object.values(orderItemnsWithQuantity).some((item) => item.quantity > 0)
          : false
        : true) &&
      (orderItemnsWithQuantity[orderId]?.quantity == 1 ||
        (orderItemnsWithQuantity[orderId]?.quantity == undefined && data[0].quantity == 1))
    ) {
      toast.error('No puede dejar la solicitud sin productos', {
        id: 'error-delete-last-item',
      });
      return;
    }

    if (orderItemnsWithQuantity[orderId]?.quantity - 1 === data.find((item) => item._id === orderId).quantity) {
      // Eliminar el item del objeto
      const newOrderItemnsWithQuantity = { ...orderItemnsWithQuantity };
      delete newOrderItemnsWithQuantity[orderId];

      // Utiliza newOrderItemnsWithQuantity según tus necesidades
      setOrderItemnsWithQuantity(newOrderItemnsWithQuantity);
      return;
    }

    // Si el item no existe, lo agregamos con cantidad ajustada
    if (!orderItemnsWithQuantity.hasOwnProperty(orderId)) {
      setOrderItemnsWithQuantity({
        ...orderItemnsWithQuantity,
        [orderId]: {
          formatChildren:
            item?.formatChildren?.length > 0
              ? item.formatChildren.map((child) => ({
                  ...child,
                  // Dividir la cantidad de este hijo entre la cantidad total de formatChildren y multiplicar por la nueva cantidad del pedido
                  quantity: Math.max((child.quantity / item.quantity) * (item.quantity - 1), 0),
                }))
              : [],
          quantity: Math.max(item.quantity - 1, 0),
        },
      });
    } else {
      const newOrderItemsWithQuantity = {
        ...orderItemnsWithQuantity,
        [orderId]: {
          formatChildren:
            orderItemnsWithQuantity[orderId]?.formatChildren?.length > 0
              ? orderItemnsWithQuantity[orderId].formatChildren.map((child) => ({
                  ...child,
                  // Dividir la cantidad de este hijo entre la cantidad total de formatChildren y multiplicar por la nueva cantidad del pedido
                  quantity: Math.max(
                    (child.quantity / orderItemnsWithQuantity[orderId].quantity) *
                      (orderItemnsWithQuantity[orderId].quantity - 1),
                    0,
                  ),
                }))
              : [],
          quantity: Math.max(orderItemnsWithQuantity[orderId].quantity - 1, 0),
        },
      };

      setOrderItemnsWithQuantity(newOrderItemsWithQuantity);
    }
  };

  const handleNavigateOrderItem = (id) => {
    setItemOrderIds(data.map((item) => item._id));
    const hasTrailingSlash = location.pathname.endsWith('/');
    const url = hasTrailingSlash ? `${location.pathname}order-item/${id}` : `${location.pathname}/order-item/${id}`;

    navigate(url);
  };

  const sortedData = (data) => {
    return [...data].sort((a, b) => {
      if (a.arteFinal.code < b.arteFinal.code) return -1;
      if (a.arteFinal.code > b.arteFinal.code) return 1;
      if (a.arteFinal.name < b.arteFinal.name) return -1;
      if (a.arteFinal.name > b.arteFinal.name) return 1;
      if (a.formatId.code < b.formatId.code) return -1;
      if (a.formatId.code > b.formatId.code) return 1;
      return 0;
    });
  };

  return (
    <div style={{ marginBottom: '3rem' }}>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Pieza</StyledTableCell>
              <StyledTableCell>Comentario</StyledTableCell>
              <StyledTableCell>Imagen</StyledTableCell>
              <StyledTableCell>Formato</StyledTableCell>
              <StyledTableCell>Precio Unitario</StyledTableCell>
              <StyledTableCell>Precio total</StyledTableCell>
              <StyledTableCell>Proveedor (solo personalizable)</StyledTableCell>
              <StyledTableCell>Cantidad</StyledTableCell>
              {/* <StyledTableCell>Acciones</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData(data).map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell className="mayus detail-order-row" style={{ borderLeft: '1px solid #d6d6d6' }}>
                  <span onClick={() => handleNavigateOrderItem(item._id)}>
                    {item.code} - {item.arteFinal.name}
                  </span>
                  {/* si tiene formatChildren ponemos un icono */}
                  {item?.formatChildren?.length > 0 && (
                    <DynamicFeedIcon
                      className={`children-format-icon`}
                      style={{
                        userSelect: 'none',
                        outline: 'none',
                        cursor: 'pointer',
                        marginRight: '0.5rem',
                        float: 'right',
                      }}
                    />
                  )}
                </StyledTableCell>

                <StyledTableCell>
                  {item.comment ? (
                    <div
                      style={{
                        borderRadius: '40px',
                        border: '1px solid #7CCA6B',
                        color: '#7CCA6B',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Incluye
                    </div>
                  ) : (
                    <div
                      style={{
                        borderRadius: '40px',
                        border: '1px solid #EA9180',
                        color: '#EA9180',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      No incluye
                    </div>
                  )}
                </StyledTableCell>

                <StyledTableCell>
                  {item.image ? (
                    <div
                      style={{
                        borderRadius: '40px',
                        border: '1px solid #7CCA6B',
                        color: '#7CCA6B',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Incluye
                    </div>
                  ) : (
                    <div
                      style={{
                        borderRadius: '40px',
                        border: '1px solid #EA9180',
                        color: '#EA9180',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      No incluye
                    </div>
                  )}
                </StyledTableCell>

                <StyledTableCell className="mayus">
                  {item.custom ? (
                    <>
                      <span>FORMATO PERSONALIZADO:</span>
                      <br />
                      {item.custom}
                    </>
                  ) : (
                    item.formatId.name
                  )}
                </StyledTableCell>

                <StyledTableCell>{(item?.totalPrice || item.price).toFixed(2) + ' €'}</StyledTableCell>

                <StyledTableCell>
                  {(item?.totalPrice * item.quantity || item.price * item.quantity).toFixed(2) + ' €'}
                </StyledTableCell>
                <StyledTableCell>
                  {item.custom ? (
                    <SelecteProviderCustom
                      item={item}
                      customFormatsModified={customFormatsModified}
                      setCustomFormatsModified={setCustomFormatsModified}
                    />
                  ) : null}
                </StyledTableCell>

                <StyledTableCell className="mayus">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '.25rem' }}>
                    {admin &&
                      (data?.length > 1 &&
                      (Object.keys(orderItemnsWithQuantity).length > 0
                        ? // COMPROBAR SI ALGUNO DE LOS OBJETOS QUE HAY EN EL ARRAY TIENE DE QUANTITY MAS DE 1
                          Object.values(orderItemnsWithQuantity).some((item) => item.quantity > 0)
                        : true) &&
                      (orderItemnsWithQuantity[item._id]?.quantity === 1 ||
                        (!orderItemnsWithQuantity[item._id] &&
                          data.find((orderItem) => orderItem._id === item._id).quantity === 1)) ? (
                        <PopupState variant="popover" popupId="demo-popup-popover">
                          {(popupState) => (
                            <div>
                              <RemoveCircleRoundedIcon
                                style={{ color: '#707070', cursor: 'pointer' }}
                                fontSize="small"
                                {...bindTrigger(popupState)}
                              />
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'left',
                                }}
                              >
                                <div style={{ padding: '1rem' }}>
                                  <p style={{ fontSize: '.75rem' }}>
                                    Si actualiza la solicitud con la cantidad en 0, se eliminará el formato ¿Desea
                                    continuar?
                                  </p>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      gap: '1rem',
                                      marginTop: '.5rem',
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      style={{
                                        fontSize: '.7rem',
                                        padding: '5px 5px',
                                        minWidth: '0',
                                        backgroundColor: '#898989',
                                      }}
                                      onClick={() => {
                                        // handleRemoveProperty(id);
                                        // addModulo()
                                        popupState.setOpen(false);
                                      }}
                                    >
                                      No
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      style={{
                                        fontSize: '.75rem',
                                        padding: '5px 5px',
                                        minWidth: '0',
                                        background: '#E4253B',
                                        borderColor: 'transparent',
                                        color: '#fff',
                                      }}
                                      onClick={() => {
                                        handleDecrement(item);
                                        popupState.setOpen(false);
                                      }}
                                    >
                                      Sí
                                    </Button>
                                  </div>
                                </div>
                              </Popover>
                            </div>
                          )}
                        </PopupState>
                      ) : (
                        <RemoveCircleRoundedIcon
                          style={{ color: '#707070', cursor: 'pointer' }}
                          fontSize="small"
                          onClick={() => handleDecrement(item)}
                        />
                      ))}

                    <span style={{ fontSize: '16px', fontWeight: '600' }}>
                      {orderItemnsWithQuantity[item._id] !== null && orderItemnsWithQuantity[item._id] !== undefined
                        ? orderItemnsWithQuantity[item._id]?.quantity
                        : item.quantity}
                    </span>

                    {admin && (
                      <AddCircleRoundedIcon
                        onClick={() => handleIncrement(item)}
                        fontSize="small"
                        style={{ cursor: 'pointer', color: '#707070' }}
                      />
                    )}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}

            {/* VAMOS A PONER EN LA ULTIMA COLUMNA DEL FOOTER EL SUBTOTAL DE LOS PRODUCTOS */}
          </TableBody>
        </Table>
      </TableContainer>
      {/* INDICAMOS EL TOTAL */}

      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <span
            style={{
              fontSize: '1.1rem',
              padding: '1rem',
              lineHeight: '1.1rem',
              borderRight: '1px solid #E1E1E1',
              borderBottom: '1px solid #E1E1E1',
              borderLeft: '1px solid #E1E1E1',
            }}
          >
            Total productos:{' '}
            {data.reduce((acc, item) => acc + (item?.totalPrice || item.price) * item.quantity, 0).toFixed(2)} €
          </span>
        </div>
      </div>

      <Tooltip2 style={{ zIndex: '100000' }} anchorSelect=".children-format-icon">
        <span>Este arte final es compuesto</span>
      </Tooltip2>
    </div>
  );
};
