import { createContext, useContext, useState } from 'react';

const MoveCategoryAuthContext = createContext();

export const MoveCategoryAuthProvider = ({ children }) => {
  const [authorized, setAuthorized] = useState(false);

  const authorize = () => setAuthorized(true);
  const deauthorize = () => setAuthorized(false);

  return (
    <MoveCategoryAuthContext.Provider value={{ authorized, authorize, deauthorize }}>
      {children}
    </MoveCategoryAuthContext.Provider>
  );
};

export const useMoveCategoryAuth = () => useContext(MoveCategoryAuthContext);
