import React, { useContext } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { changeSidebarArteFinalOption } from '../../helpers/ArteFinal/SidebarArteFinal';

export const ArteFinalSidebar = ({ context }) => {
  const { currentOption, setCurrentOption } = useContext(context);

  return (
    <div className={`sidebar-container ${currentOption ? 'sidebar-container__open' : ''}`}>
      <MenuIcon
        fontSize="large"
        className={`icon-sidebar-arte-final ${
          currentOption === 'categories' ? 'icon-sidebar-arte-final__selected' : ''
        }`}
        onClick={() => changeSidebarArteFinalOption('categories', currentOption, setCurrentOption)}
      />

      <SearchIcon fontSize="large" className={`icon-sidebar-arte-final`} />
    </div>
  );
};

export default ArteFinalSidebar;
