import { useGetGuiasMerchandising } from '../../../lib/react-query/fileQuery';
import SkeletonLoader from '../../common/SkeletonLoader';
import FileCard from './FileCard';
import MerchandisingCard from './MerchandisingCard';
import NewMerchandisingCard from './NewMerchandisingCard';

const FilesMerchandising = () => {
  const { data: guias, isLoading } = useGetGuiasMerchandising();

  return (
    <>
      {isLoading ? (
        <div className="grid-container">
          <SkeletonLoader totalColumns={4} minHeight="85px" />
        </div>
      ) : (
        <div className="grid-container grid-container__overflow">
          {guias?.data?.map((guia) => (
            <MerchandisingCard key={guia._id} file={guia} />
          ))}
          <NewMerchandisingCard />
        </div>
      )}
    </>
  );
};

export default FilesMerchandising;
