import { Button, IconButton } from '@mui/material';
import { useCartItemQuantity } from '../../../hooks/useCart';

const CartItemQuantity = ({ children, item, formato, required }) => {
  const { handleOnClick, handleRemove } = useCartItemQuantity(item, formato, required, children);

  return (
    <div style={{ display: 'flex', fontSize: '1rem', alignItems: 'center' }}>
      <p style={{ margin: 0, fontSize: '1rem' }}>Cantidad: </p>
      <IconButton
        style={{ aspectRatio: '1/1', width: '30px', height: '30px' }}
        disabled={formato.quantity < 1}
        onClick={() => handleOnClick(-1)}
      >
        -
      </IconButton>
      <p style={{ margin: 0, fontSize: '1rem' }}>{Math.max(formato.quantity, formato.minQuantity || 1)}</p>
      <IconButton style={{ aspectRatio: '1/1', width: '30px', height: '30px' }} onClick={() => handleOnClick(1)}>
        +
      </IconButton>
      {children && (
        <Button size="small" color="error" onClick={handleRemove}>
          Eliminar
        </Button>
      )}
    </div>
  );
  //   return <p style={{ marginBottom: 0, fontSize: '1rem' }}>{`Cantidad: ${formato.quantity}`}</p>;
};

export default CartItemQuantity;
