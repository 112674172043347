import { Autocomplete, Button, FormControl, Grid, Modal, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCreateProviderFormat, useGetAvailableProviders } from '../../../hooks/useFormats';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useCheckFormatIsCustom } from '../../../hooks/useFormat';
import SkeletonLoader from '../../common/SkeletonLoader';

const AddFormatProvider = () => {
  const { idFormat, idArte } = useParams();
  const { isCustom, loadingCheck } = useCheckFormatIsCustom(idFormat);
  const { data: proveedores, isLoading } = useGetAvailableProviders(idFormat);
  const { mutation } = useCreateProviderFormat(idArte);
  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      code: '',
      provider: '',
      format: idFormat,
      arteFinal: idArte,
      price: '0',
      active: true,
    },
    onSubmit: async (values) => {
      mutation.mutate({
        ...values,
        price: parseFloat(values.price.replace(',', '.')),
      });
      handleClose();
    },
  });

  const handleAutocompleteChange = (event, newValue) => {
    formik.setFieldValue('provider', newValue ? newValue._id : '');
  };

  const handleClose = () => {
    const pathSegments = location.pathname.split('/').filter((segment) => segment.length > 0);
    if (pathSegments.length > 2) {
      const newPath = `/${pathSegments.slice(0, -2).join('/')}`;
      navigate(newPath);
    } else {
      navigate('/');
    }
  };

  const handlePriceChange = (event) => {
    const value = event.target.value;
    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
      formik.setFieldValue('price', value);
    }
  };

  return (
    <Modal open={true} onClose={handleClose}>
      <div className="modal-create-edit-format">
        <Typography
          variant="h4"
          component="h2"
          align="center"
          sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
        >
          Información del proveedor a asociar
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          {loadingCheck ? (
            <SkeletonLoader count={1} minHeight="56px" />
          ) : (
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="code"
                  label="Código"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    options={isLoading ? [] : proveedores?.data || []}
                    getOptionLabel={(option) => option.name || ''}
                    value={proveedores?.data?.find((proveedor) => proveedor._id === formik.values.provider) || null}
                    onChange={handleAutocompleteChange}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    renderInput={(params) => <TextField {...params} label="Proveedor" variant="outlined" fullWidth />}
                    noOptionsText="Selecciona uno de los proveedores disponibles"
                    loading={isLoading}
                  />
                </FormControl>
              </Grid>

              {!isCustom && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="price"
                    label="Precio *"
                    value={formik.values.price}
                    onChange={handlePriceChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>
              )}
            </Grid>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
            <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
              Confirmar
            </Button>
            <Button variant="contained" color="error" endIcon={<CloseIcon />} onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddFormatProvider;
