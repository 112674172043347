import React from 'react';
import { Tooltip } from 'react-tooltip';

const TooltipItem = ({ content, className, position }) => {
  return (
    <Tooltip
      opacity={0.65}
      style={{
        padding: '4px 8px',
      }}
      noArrow={true}
      delayShow="250"
      offset="1"
      anchorSelect={`.${className}`}
      place={position}
    >
      {content}
    </Tooltip>
  );
};
export default TooltipItem;
