import { useLocation, useNavigate } from 'react-router-dom';

export const useHandleCloseModal = (position) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCloseModal = () => {
    // Divide la URL en segmentos
    const segments = location.pathname.split('/');

    // Verifica si la URL termina en una barra
    const endsWithSlash = location.pathname.endsWith('/');

    // Ajusta la posición para tener en cuenta las barras finales
    const adjustedPosition = endsWithSlash ? position + 1 : position;

    // Asegúrate de no eliminar más segmentos de los disponibles
    const finalPosition = Math.min(adjustedPosition, segments.length - 1);

    // Construye la URL, asegurándose de eliminar la barra final si es necesario
    let url = segments.slice(0, -finalPosition).join('/');

    // Asegúrate de que la URL no termine en una barra
    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }

    navigate(url || '/'); // Redirige a la raíz si la URL resultante está vacía
  };

  return { handleCloseModal };
};
