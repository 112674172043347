import { Outlet } from 'react-router-dom';
import AllFiles from './AllFiles';
import FilesMerchandising from './FilesMerchandising';

const GestionArchivos = () => {
  return (
    <div className="container-master-user">
      <h2 className="f-w-600">ARCHIVOS</h2>
      <AllFiles />
      <h2 className="f-w-600 mt-5">GUÍAS MERCHANDISING</h2>
      <FilesMerchandising />
      <Outlet />
    </div>
  );
};

export default GestionArchivos;
