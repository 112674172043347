import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';

const GlobalSearch = ({ searchValue, setSearchValue, placeholder }) => {
  const [localValue, setLocalValue] = useState(searchValue);

  const debouncedSetSearchValue = useDebounce(setSearchValue, 300); // Ajusta el delay según sea necesario

  useEffect(() => {
    debouncedSetSearchValue(localValue);
  }, [localValue, debouncedSetSearchValue]);

  const handleChange = (e) => {
    setLocalValue(e.target.value);
  };

  return (
    <div className="global-search">
      <input
        style={{ width: '100%', padding: '0.25rem' }}
        type="text"
        placeholder={placeholder}
        value={localValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default GlobalSearch;
