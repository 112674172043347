import React, { createContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getRole } from '../api/alcampoApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getCentersByAuth0Id } from '../api/userApi';

// TODO: ABSTRAER TODO LO DE LOS CENTROS AL USUARIO PORQUE AQUI ESTA LA APP ENTERA

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();

  // const [userAutodesk, setUserAutodesk] = useState(null);
  // const [loadiProfile, setLoadiProfile] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const { data: centersByUser } = useQuery(['AllCenters'], async () => {
    const token = await getAccessTokenSilently();
    return await getCentersByAuth0Id(token);
  });

  // * state para el componente <ContainerCenter> el cual sirve para almacenar el centro seleccionado
  // const [autocompleteValue, setAutocompleteValue] = useState(
  //   JSON.parse(localStorage.getItem('centerSelected')) || null,
  // );
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [centerConfirmed, setCenterConfirmed] = useState(JSON.parse(localStorage.getItem('centerConfirmed')) || null);
  const [currentOption, setCurrentOption] = useState(null);

  useEffect(() => {
    if (centersByUser) {
      // TODO: Comprobar que si está guardado en el localStorage el item, coincide con alguno de los centersByUser
      const centerConfirmed = JSON.parse(localStorage.getItem('centerConfirmed'));
      if (centerConfirmed) {
        const matchCenter = centersByUser.find((el) => el._id === centerConfirmed._id);
        if (!matchCenter) {
          localStorage.setItem('centerConfirmed', null);
          setCenterConfirmed(null);
        } else {
          setCenterConfirmed(centerConfirmed);
        }
      } else {
        setCenterConfirmed(null);
      }
    }
  }, [centersByUser]);

  useEffect(() => {
    if (centersByUser) {
      // Comprobar que el item que se le pasa
      localStorage.setItem('centerConfirmed', JSON.stringify(centerConfirmed));
    }
  }, [centerConfirmed, centersByUser]);

  useEffect(() => {
    if (!user) return;
    getRole(user).then((res) => {
      if (res === 'user-signage' && location.pathname === '/') {
        navigate('/carteleria');
      } else if (res === 'admin-signage' && location.pathname === '/') {
        navigate('/admin');
      } else if (res === 'ViewMaster' && location.pathname === '/') {
        navigate('/master');
      }
      setUserRole(res);
    });
  }, [user]);

  const data = {
    // userAutodesk,
    autocompleteValue,
    setAutocompleteValue,
    centerConfirmed,
    setCenterConfirmed,
    userRole,
    currentOption,
    setCurrentOption,
  };

  return userRole ? (
    <UserContext.Provider value={data}>{children}</UserContext.Provider>
  ) : (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress style={{ color: 'black' }} />
    </div>
  );
};
