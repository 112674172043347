import NavbarAlcampo from '../Components2/Navbar/NavbarAlcampo';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { UserContext } from '../context/UserProvider';
import { ShoppingCartProvider } from '../context/ShoppingCartContext';

const Alcampo = () => {
  const { user, isAuthenticated } = useAuth0();
  const { userRole } = useContext(UserContext);

  return (
    <div
      style={{
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
      }}
    >
      {userRole === 'user-signage' ? (
        <ShoppingCartProvider>
          <NavbarAlcampo user={isAuthenticated && user} />
          <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
            <Outlet />
          </div>
        </ShoppingCartProvider>
      ) : (
        <>
          <NavbarAlcampo user={isAuthenticated && user} />
          <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};

export default Alcampo;
