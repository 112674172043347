import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from './useQuery';

export const useHandleUrl = () => {
  const { idItem } = useParams();
  const navigate = useNavigate();
  let query = useQuery();
  let version = useMemo(() => query.get('version'), [query]);

  const changeLocationWithNavigate = (path) => {
    return version ? navigate(`${path}?version=${version}`) : navigate(path);
  };

  const changeLocation = (path) => {
    return version ? `${path}?version=${version}` : path;
  };
  return { idItem, version, changeLocationWithNavigate, changeLocation };
};
