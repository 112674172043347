import { CircularProgress } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { InfoArteFinal } from './InfoArteFinal';
import ArteFinalDescargable from './ArteFinalDescargable';
import { useGetInfoArteFinal } from '../../../hooks/useArteFinal';
import ArteFinalNotDescargable from './ArteFinalNotDescargable';
import HeaderArteFinal from './HeaderArteFinal';

export const ArteFinalSpecification = () => {
  const { idArte } = useParams();
  const { data: infoArteFinal, isLoading } = useGetInfoArteFinal(idArte);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh', width: '100%' }}>
        <CircularProgress color="error" />{' '}
      </div>
    );
  }

  return (
    <>
      <div style={{ height: '100%', margin: '2rem' }}>
        <HeaderArteFinal infoArteFinal={infoArteFinal.data} />

        <div style={{ height: '85%', overflowY: 'auto', padding: '1rem' }}>
          <InfoArteFinal infoArteFinal={infoArteFinal?.data} />
          {infoArteFinal?.data.isDescargable ? (
            <ArteFinalDescargable />
          ) : (
            <ArteFinalNotDescargable customFormat={infoArteFinal?.data.formatCustom} />
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
};
