import React from 'react';
import DraggableCategory from './DraggableCategory';

const CategoryList = ({ categories, moveCategory, handleDelete, isNewCategory }) => {
  if (categories.length === 0) {
    return (
      <div className="gestion-container-category-subcategories-empty">
        <span>No hay subcategorías</span>
      </div>
    );
  }

  return (
    <div className="gestion-container-category-subcategories">
      {categories.map((category, index) => (
        <DraggableCategory
          key={category._id || category.name}
          index={index}
          isNewCategory={isNewCategory}
          category={category}
          moveCategory={moveCategory}
          handleDelete={handleDelete}
        />
      ))}
    </div>
  );
};

export default CategoryList;
