import React from 'react';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es'; // the locale you want

const OrderDateFilter = ({ filterName, startDate, setStartDate, finishDate, setFinishDate, delivery }) => {
  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleChangeFinishDate = (date) => {
    if (!startDate) {
      setStartDate(date);
    } else {
      setFinishDate(date);
    }
  };

  return (
    <div>
      <span className="font-filters">{filterName}</span>
      <div style={{ display: 'flex', gap: '.5rem', marginTop: '0.75rem', justifyContent: 'space-between' }}>
        <DatePicker
          onKeyDown={(e) => e.preventDefault()}
          selected={startDate || null}
          placeholderText="Fecha inicial"
          onChange={(date) => handleChangeStartDate(date)}
          locale={es}
          dateFormat="dd/MM/yyyy"
          maxDate={finishDate ? new Date(finishDate) : new Date()}
          isClearable={!finishDate}
        />
        <DatePicker
          onKeyDown={(e) => e.preventDefault()}
          style={{ cursor: 'pointer' }}
          selected={finishDate || null}
          placeholderText="Fecha final"
          onChange={(date) => handleChangeFinishDate(date)}
          locale={es}
          dateFormat="dd/MM/yyyy"
          minDate={new Date(startDate)}
          maxDate={!delivery && new Date()}
          isClearable
        />
      </div>
    </div>
  );
};

export default OrderDateFilter;
