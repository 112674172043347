import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { checkCenterTypeStatusByCenter } from '../../../api/carteleriaApi';

function useValidateCenter() {
  const { getAccessTokenSilently } = useAuth0();

  const mutationCheckCenterTypeStatus = useMutation({
    mutationFn: async ({ active, clusterId }) => {
      if (!active) return { centerTypeActive: true };
      const token = await getAccessTokenSilently();
      return await checkCenterTypeStatusByCenter(token, clusterId);
    },
  });

  const checkCenterTypeStatus = (active, clusterId) => {
    return mutationCheckCenterTypeStatus.mutateAsync({ active, clusterId });
  };

  return { checkCenterTypeStatus };
}

export default useValidateCenter;
