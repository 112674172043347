import axios from 'axios';

/* -------------------------------------------------------------------------- */
/*                                    AUTH                                    */
/* -------------------------------------------------------------------------- */
export const getProfileAutodesk = async (token) => {
  try {
    const { data } = await axios.get('/api/v1/auth/profile', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

// TODO: revisar si se puede eliminar, I think this is not used, therefor it can be deleted
export const getRolesByUser = async (userId) => {
  try {
    const { data } = await axios.get(`/api/v1/auth0/${userId}/role`);

    return data;
  } catch (err) {
    throw new Error(err);
  }
};

// aqui voy a añadir la función en la que traigo el rol del usuario (admin o no admin) por si uso TANSTACK
export const getRole = async (user) => {
  
  try {
    const { data } = await axios.get('/api/v1/authTest', {
      params: {
        userId: user.sub,
      },
    });
    return data.data[0].name;
  } catch (e) {
    throw new Error(e);
  }
};

/* -------------------------------------------------------------------------- */
/*                                     APS                                    */
/* -------------------------------------------------------------------------- */
export const getVersionsByItem = async (token, itemId, version = null) => {
  try {
    const currentVersion = version ? version : 'not-defined';
    const versions = await axios.get(
      `/api/v1/hubs/b.1ae2ac7b-1db9-4b0d-aeec-cf6e1d5a698b/projects/b.5debd4e0-df48-4326-af59-7916804ca9f7/contents/${itemId}/versions/${currentVersion}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return versions;
  } catch (e) {
    return e;
  }
};

export const getAllVersionsByItem = async (token, itemId) => {
  const { data } = await axios.get(
    `/api/v1/hubs/b.1ae2ac7b-1db9-4b0d-aeec-cf6e1d5a698b/projects/b.5debd4e0-df48-4326-af59-7916804ca9f7/contents/${itemId}/versions`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  console.log('data', data);
  return data;
};
export const getHubsTree = async (token) => {
  try {
    const { data } = await axios.get(`/api/v1/hubs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};
export const getProject = async (id, token) => {
  console.log(id);
  if (id.length > 0) {
    try {
      const { data } = await axios.get(`/api/v1/hubs/${id}/projects`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('data', data);

      return data;
    } catch (e) {
      throw new Error(e);
    }
  } else {
    console.log('no hacemos nada');
  }
};
export const getProjectContents = async (token, idHub, idProject, idFolder = null) => {
  console.log('folder', idFolder);
  let data;
  if (idFolder == undefined || idFolder === null) {
    data = await axios.get(`/api/v1/hubs/${idHub}/projects/${idProject}/contents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    data = await axios.get(`/api/v1/hubs/${idHub}/projects/${idProject}/contents?folderId=${idFolder}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  console.log(data);
  return data.data;
};

/* -------------------------------------------------------------------------- */
/*                                  PROYECTO                                  */
/* -------------------------------------------------------------------------- */

export const getProjectImported = async (proyectoId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/proyecto/${proyectoId}/getprice`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getAllCapitulosSubcapitulosPartidasProyecto = async (proyectoId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/proyecto/${proyectoId}/partidaProyecto/allwithcapitulossubcapitulos`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getProyectoByItem = async (itemId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/proyecto/itemId/${itemId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    throw new Error(e);
  }
};
export const addProyectoItem = async (body, token) => {
  try {
    const { data } = await axios.post('/api/v1/proyecto', body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('data', data);
    return data;
  } catch (e) {}
  console.log('body', body);
};

export const getAllProjects = async (token, page) => {
  const pageNumber = page || 1;

  // console.log(pageNumber);
  try {
    const { data } = await axios.get(`/api/v1/proyecto/${pageNumber}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(data);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

/* -------------------------------------------------------------------------- */
/*                               PARTIDAPROYECTO                              */
/* -------------------------------------------------------------------------- */

export const getPartidasProyectoByProyecto = async (proyecto, token) => {
  try {
    const { data } = await axios.get(`/api/v1/proyecto/${proyecto}/partidaProyecto/byproject'`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data?.data?.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getPartidaProyectoById = async (idPartidaProyecto, token) => {
  try {
    const { data } = await axios.get(`/api/v1/partidaProyecto/${idPartidaProyecto}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const updatePartidaProyecto = async (partidaProyectoId, body, token) => {
  try {
    const { data } = await axios.patch(`/api/v1/partidaProyecto/${partidaProyectoId}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const deletePartidaProyecto = async (idPartidaProyecto, token) => {
  try {
    await axios.delete(`/api/v1/partidaProyecto/${idPartidaProyecto}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    console.log('error:', e);
  }
};

export const getQuantityVersionsByPartidaProyecto = async (idPartidaProyecto, token) => {
  try {
    const { data } = await axios.get(`/api/v1/partidaProyecto/${idPartidaProyecto}/quantityversion/bypartidaproyecto`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (err) {
    console.error('Error al hacer la petición:', err);
  }
};

export const getCodesByCapitulo = async (capituloId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/capituloProyecto/${capituloId}/partidaProyecto/getcodesbycapitulo`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    throw new Error(e);
  }
};

/* -------------------------------------------------------------------------- */
/*                                   VERSION                                  */
/* -------------------------------------------------------------------------- */
export const getVersionByItem = async (itemId, version, token) => {
  try {
    const { data } = await axios.get(`/api/v1/version/itemId/${itemId}/version/${version}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getVersionByProjectAndNumberVersion = async (project, version, token) => {
  try {
    const data = await axios.get(`/api/v1/proyecto/${project}/version/versionnumber/${version}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    console.log('error', e);
    return e;
    // if (error.response.status == 404) {
    //   return e.response;
    // }
    // throw new Error(e);
  }
};

export const createVersion = async (token, projectId, version, existsGeometry = true) => {
  try {
    const { data } = await axios.post(
      `/api/v1/proyecto/${projectId}/version/`,
      {
        version: parseInt(version),
        existsGeometry,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getAllVersionsByProject = async (projectId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/proyecto/${projectId}/version/getversionsbyproject`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

/* -------------------------------------------------------------------------- */
/*                               QUANTITYVERSION                              */
/* -------------------------------------------------------------------------- */

export const createQuantityVersion = async (body, token) => {
  const { data } = await axios.post(`/api/v1/quantityversion`, {
    ...body,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

/* -------------------------------------------------------------------------- */
/*                                    DRIVE                                   */
/* -------------------------------------------------------------------------- */

export const getDriveByDescription = async (description, token) => {
  try {
    const { data } = await axios.get(`/api/v1/drive/${description}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getAllFiles = async (token) => {
  try {
    const { data } = await axios.get(`/api/v1/drive`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

/* -------------------------------------------------------------------------- */
/*                                    PARTIDA                                 */
/* -------------------------------------------------------------------------- */

export const getRemainingPartidas = async (proyectoId) => {
  try {
    const { data } = await axios.get(`/api/v1/partida/remaining/${proyectoId}`);
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getAllPartidas = async (token) => {
  try {
    const { data } = await axios.get(`/api/v1/partida`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getPartidaById = async (partidaId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/partida/${partidaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

/* -------------------------------------------------------------------------- */
/*                                    PARTIDA PROYECTO                        */
/* -------------------------------------------------------------------------- */
export const createPartidaProyecto = async (partidaProyecto, token) => {
  try {
    const { data } = await axios.post(`/api/v1/partidaProyecto`, partidaProyecto, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const createMasivePartidasProyecto = async (arrayPartidas, currentProject, token) => {
  try {
    const { data } = await axios.post(
      `/api/v1/partidaProyecto/partidasProyectoMasivas`,
      {
        proyecto: currentProject,
        arrayPartidas,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data.data;
  } catch (error) {
    console.log('error', error);
  }
};

export const getPartidasProyectoByProyectoId = async (proyectoId, query, token) => {
  try {
    const { data } = await axios.get(`/api/v1/partidaProyecto/proyecto/${proyectoId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: query,
    });
    return data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getAllPartidasByProject = async (projectId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/proyecto/${projectId}/partidaProyecto/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getPartidasProyectoNotMatchWithVersion = async (proyecto, version, token) => {
  try {
    const { data } = await axios.get(
      `/api/v1/proyecto/${proyecto}/partidaProyecto/filterbyversionnotmatch/version/${version}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getAllCodesByPartidasProyecto = async (proyectoId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/proyecto/${proyectoId}/partidaProyecto/codes/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

/* -------------------------------------------------------------------------- */
/*                              CAPITULO PROYECTO                             */
/* -------------------------------------------------------------------------- */

export const getSubcapitulos = async (capituloId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/capituloProyecto/${capituloId}/subcapitulos`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getCapitulosByProject = async (token, projectId, query) => {
  try {
    const { data } = await axios.get(`/api/v1/capituloProyecto/proyecto/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: query,
    });
    return data.data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const getCapituloProjectByCapituloAndProject = async (capituloId, projectId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/capituloProyecto/capitulo/${capituloId}/proyecto/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const createCapituloProject = async (body, token) => {
  try {
    const { data } = await axios.post(`/api/v1/capituloProyecto`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

export const updateCapituloProject = async (capituloProjectId, body, token) => {
  try {
    const { data } = await axios.patch(`/api/v1/capituloProyecto/${capituloProjectId}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

/* -------------------------------------------------------------------------- */
/*                                  CAPITULO                                  */
/* -------------------------------------------------------------------------- */

export const getCapituloById = async (capituloId, token) => {
  try {
    const { data } = await axios.get(`/api/v1/capitulo/${capituloId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (e) {
    console.log('error', e);
  }
};

/* -------------------------------------------------------------------------- */
/*                                   MODEL DERIVATIVE                                  */
/* -------------------------------------------------------------------------- */

export const getThumbnail = async (itemId, token) => {
  try {
    console.log('itemId', itemId);
    const { data } = await axios.get(`/api/v1/modelderivative/thumbnail/${itemId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('data', data);
    return data.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const checkIfUrnHasGeometry = async (urn, token) => {
  try {
    const data = await axios.get(`/api/v1/modelderivative/objectTree/${urn}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};
