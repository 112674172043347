import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Tooltip, Cell, CartesianGrid, BarChart, XAxis, YAxis, Bar } from 'recharts';

const PrecioCapitulosGraphic = ({ data }) => {
  const [height, setHeight] = useState(300);
  const [outerRadius, setouterRadius] = useState(80);
  const [width, setWidth] = useState(600);

  useEffect(() => {
    console.log('dataaa', data);
  }, [data]);

  return (
    <BarChart
      width={500}
      height={270}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)} />
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis
        // domain={['auto', 'auto']}
        tickFormatter={(tick) => {
          return tick.toLocaleString();
        }}
      />
      <Bar
        dataKey="value"
        name="Precio"
        fill="#8884d8"
        //  shape={<TriangleBar />}
        label={{ position: 'top' }}
        maxBarSize={50}
        unit={'€'}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            //  stroke={entry.stroke}
            //  strokeWidth={1}
            fill={entry.color}
          />
        ))}
      </Bar>
    </BarChart>
  );
};

export default PrecioCapitulosGraphic;
