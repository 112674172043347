import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getAvailableProviders,
  getFormatsByArteFinal,
  getProviderFormat,
  getProvidersFormat,
  postProviderFormat,
  updateProviderFormat,
} from '../api/formatsApi';
import { deleteOneFormat } from '../api/carteleriaApi';
import toast from 'react-hot-toast';

export const useGetFormatsByArteFinal = (idArte) => {
  return useQuery({
    queryKey: ['formats-arte-final', idArte],
    queryFn: () => getFormatsByArteFinal(idArte),
  });
};

export const useGetProvidersFormat = (idFormat, selected) => {
  return useQuery({
    queryKey: ['provider-formats', idFormat],
    queryFn: () => getProvidersFormat(idFormat),
    enabled: !!idFormat && selected,
  });
};

export const useDeleteFormat = (formatId) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(() => deleteOneFormat(formatId), {
    onSuccess: () => {
      queryClient.invalidateQueries('formats-arte-final');
      queryClient.invalidateQueries('info-arte-final');
      toast.success('Formato eliminado');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return { mutation };
};

export const useGetAvailableProviders = (formatId) => {
  return useQuery({
    queryKey: ['available-providers', formatId],
    queryFn: () => getAvailableProviders(formatId),
  });
};

export const useCreateProviderFormat = (idArte) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(postProviderFormat, {
    onSuccess: () => {
      queryClient.invalidateQueries(['formats-arte-final', idArte]);
      toast.success('Proveedor añadido');
    },
    onError: () => {
      toast.error('Error al añadir el proveedor');
    },
  });

  return { mutation };
};

export const useGetProviderFormat = (idProviderFormat) => {
  return useQuery({
    queryKey: ['provider-format', idProviderFormat],
    queryFn: () => getProviderFormat(idProviderFormat),
  });
};

export const useUpdateProviderFormat = (idArte) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateProviderFormat, {
    onSuccess: () => {
      queryClient.invalidateQueries('info-arte-final');
      queryClient.invalidateQueries(['formats-arte-final', idArte]);
      toast.success('Proveedor actualizado');
    },
    onError: (err) => {
      if (err.message === 'error-disable-provider-associated') {
        toast.error('No se puede activar porque el proveedor asociado está desactivado');
      }
    },
  });

  return { mutation };
};
