import { useState } from 'react';
import { handleCloseModal, handleSubmitCreateMaterial } from '../../../helpers/Material/materialHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createMaterial } from '../../../api/materialApi';
import FormMaterial from './FormMaterial';

const AddMaterial = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    nombre: '',
    active: true,
  });

  const mutation = useMutation(createMaterial, {
    onSuccess: () => {
      queryClient.invalidateQueries('gestion-materiales');
    },
  });

  return (
    <FormMaterial
      title="Crear material"
      handleSubmit={(e) => handleSubmitCreateMaterial({ e, mutation, formData, navigate, location })}
      formData={formData}
      handleChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
      handleCloseModal={() => handleCloseModal(navigate, location, 1)}
    />
  );
};

export default AddMaterial;
