import React, { useState } from 'react';
import ButtonViewPdf from './ButtonViewPdf';
import CustomButton from '../../../../ui/Button/CustomButton';
import ModalDeletePdf from './ModalDeletePdf';
import { useParams } from 'react-router-dom';
import { deletePdfArteFinalToDo } from '../../../../api/arteFinalApi';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';

const ButtonsPdf = ({ pdfs, property }) => {
  const { idArte } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeletePdf = async () => {
    try {
      const token = await getAccessTokenSilently();
      await deletePdfArteFinalToDo(idArte, { language: property }, token);
      setIsModalOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['pdf-languages', idArte],
      });
      toast.success('Pdf eliminado correctamente');
    } catch (e) {
      return toast.error('Error al eliminar el pdf. Inténtalo de nuevo');
    }
  };

  return (
    <div className="buttons-pdf-container">
      <ButtonViewPdf pdf={pdfs[property]} />
      <CustomButton onClick={() => setIsModalOpen(true)}>Eliminar</CustomButton>
      {isModalOpen && (
        <ModalDeletePdf isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleDeletePdf={handleDeletePdf} />
      )}
    </div>
  );
};

export default ButtonsPdf;
