import axios from 'axios';

export const getFormatsByArteFinal = async (idArteFinal) => {
  try {
    const { data } = await axios.get(`/api/v1/format/artefinal/${idArteFinal}`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const getProvidersFormat = async (idFormat) => {
  try {
    const { data } = await axios.get(`/api/v1/providerformat/providersbyformat/${idFormat}`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const getAvailableProviders = async (formatId) => {
  try {
    const data = await axios.get(`/api/v1/proveedor/available-providers/${formatId}`);
    return data.data;
  } catch (err) {
    console.error('Error al traer los proveedores disponibles: ', err.message);
    throw new Error(err);
  }
};

export const postProviderFormat = async (providerFormat) => {
  try {
    await axios.post('/api/v1/providerformat', providerFormat);
  } catch (err) {
    throw new Error(err);
  }
};

export const getProviderFormat = async (idProviderFormat) => {
  try {
    const data = await axios.get(`/api/v1/providerformat/${idProviderFormat}`);
    return data.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateProviderFormat = async (providerFormat) => {
  try {
    await axios.patch(`/api/v1/providerformat/${providerFormat._id}`, providerFormat);
  } catch (err) {
    throw new Error(err.response?.data?.status || err.message);
  }
};

export const checkFormatIsCustom = async (idFormat) => {
  try {
    const { data } = await axios.get(`/api/v1/format/${idFormat}/checkiscustom`);
    return data.data;
  } catch (e) {
    console.error(e);
  }
};
