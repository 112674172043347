import React, { useState } from 'react';
import InfoArteFinalTitle from '../ArteFinalInfo/InfoArteFinalTitle';
import InfoArteFinalContainer from '../ArteFinalInfo/InfoArteFinalContainer';
import cls from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SimpleModal from '../../../../ui/Modal/SimpleModal';
import { useDeleteFormat } from '../../../../hooks/useFormats';

const FormatItemTitle = ({ format, totalFormats }) => {
  const navigate = useNavigate();
  const { idArte } = useParams();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutation } = useDeleteFormat(format._id, idArte);

  const handleClick = () => {
    if (location.pathname.endsWith('/')) {
      navigate(`${location.pathname}formats/${format._id}`);
    } else {
      navigate(`${location.pathname}/formats/${format._id}`);
    }
  };

  return (
    <div>
      <InfoArteFinalContainer
        classnames={cls(`item-arte-final-container${format.active ? '--format-title' : '--format-title-not-active'}`)}
      >
        <div onClick={handleClick} className="title-container">
          <InfoArteFinalTitle classnames="display-sb-c">{format?.code} -</InfoArteFinalTitle>
          <InfoArteFinalTitle style={{ color: '#757575' }}>
            {format?.name}
            {!format.isCustom && (
              <>
                {' - '}
                {format?.material ? (
                  format.material.nombre
                ) : (
                  <span style={{ color: '#B22222' }}>Sin material asociado</span>
                )}
              </>
            )}
          </InfoArteFinalTitle>
        </div>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </InfoArteFinalContainer>
      <SimpleModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title="Eliminar formato"
        tooltip={totalFormats === 1}
        titleTooltip="No se puede eliminar el formato porque es el único asociado al arte final."
        description="¿Estás seguro de que deseas eliminar el formato? Esto también eliminará todos los proveedores asociados."
        handleConfirm={mutation.mutate}
      />
    </div>
  );
};

export default FormatItemTitle;
