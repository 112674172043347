import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useGetAllMaterials } from '../../../hooks/useMateriales';

const CreateFormatToNewArteFinal = ({ formik, goBack }) => {
  const { data: allMaterials, isLoading: loadingMaterials } = useGetAllMaterials();

  const handleAutocompleteChange = (event, newValue) => {
    formik.setFieldValue('material', newValue ? newValue._id : '');
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="code"
            label="Código"
            variant="outlined"
            required
            fullWidth
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helpertext={formik.touched.code && formik.errors.code}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="name"
            label="Medida"
            variant="outlined"
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helpertext={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={loadingMaterials ? [] : allMaterials?.data || []}
            getOptionLabel={(option) => option.nombre || ''}
            value={allMaterials?.data?.find((material) => material._id === formik.values.material) || null}
            onChange={handleAutocompleteChange}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            renderInput={(params) => <TextField required {...params} label="Material" variant="outlined" fullWidth />}
            noOptionsText="Sin material"
            loading={loadingMaterials}
          />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
        <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
          Siguiente
        </Button>
        <Button type="button" variant="contained" color="error" onClick={goBack}>
          Atras
        </Button>
      </div>
    </form>
  );
};

export default CreateFormatToNewArteFinal;
