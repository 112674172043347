import { Popover, Typography, Button } from '@mui/material';

const DeletePopover = ({
  deletePopover,
  handleDeleteClose,
  handleDeleteConfirm,
  textQuestion,
  textConfirm,
  textCancel,
}) => {
  return (
    <Popover
      open={!!deletePopover}
      anchorEl={deletePopover}
      onClose={handleDeleteClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.05)', // Box-shadow muy discreto
          border: '1px solid #ddd', // Borde ligero
          borderRadius: '8px', // Borde más suave
        },
      }}
    >
      <div style={{ padding: '16px', textAlign: 'center' }}>
        <Typography>{textQuestion}</Typography>
        <Button variant="contained" color="error" onClick={handleDeleteConfirm} style={{ margin: '8px' }}>
          {textConfirm}
        </Button>
        <Button variant="contained" onClick={handleDeleteClose} style={{ margin: '8px', background: '#333' }}>
          {textCancel}
        </Button>
      </div>
    </Popover>
  );
};

export default DeletePopover;
