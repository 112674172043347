import { useEffect, useState } from 'react';
import InfoLayout from '../layout/ViewerContentComponents/InfoLayout';
import { Button, Row } from 'reactstrap';
import GraphicLayout from '../layout/ViewerContentComponents/GraphicLayout';
import Main from '../Components2/Main/Main';

import CapitulosGraphic from '../Components2/Graphics/CapitulosGraphic';
const MecPage = () => {
  return (
    <>
      <InfoLayout>
        <Main />
      </InfoLayout>
      <GraphicLayout>
        <CapitulosGraphic />
      </GraphicLayout>
    </>
  );
};

export default MecPage;
