import React, { useEffect, useState } from 'react';
import { useGetAllProvidersFormatByFormatId } from '../../../lib/react-query/formatProviderQuery';

const providersToShow = (allProvidersFormats, orderItem) => {
  // * De todos los providers, mostrar solo los providers activos. Si el provider seleccionado para la orderItem está desactivado, mostrarlo también
  return allProvidersFormats.filter(
    (providerFormat) => providerFormat.active || providerFormat.provider._id === orderItem.provider._id,
  );
};
function eliminarClaveSiExiste(obj, clave) {
  if (obj.hasOwnProperty(clave)) {
    delete obj[clave];
  }
  return obj || {};
}

const SelecteProviderCustom = ({ item, customFormatsModified, setCustomFormatsModified }) => {
  const { data, isLoading } = useGetAllProvidersFormatByFormatId(item.formatId._id);
  const [selectedValue, setSelectedValue] = useState(item.provider._id);

  const handleOnChange = (e) => {
    const providerInBd = item.provider._id;
    const newValue = e.target.value;

    setSelectedValue(newValue);

    // *: Si el e.target.value es diferente a item.provider._id, ver si en setCustomFormatsModified está añadido esa orderItem. Si no es así, añadirlo. Si está añadido modificarlo.
    const isModifiedFormat = newValue !== providerInBd;

    const valueNewFormatProvider = data.data.find((p) => p.provider._id === newValue);
    if (isModifiedFormat) {
      setCustomFormatsModified((prevValue) => ({
        ...prevValue,
        [item._id]: valueNewFormatProvider._id,
      }));
    } else {
      // *: Si es igual, revisar si está añadido y, si es así, eliminarlo
      setCustomFormatsModified((prevValue) => {
        const newValue = eliminarClaveSiExiste(prevValue, item._id);
        // Asegúrate de que siempre retornes un nuevo objeto vacío para forzar la actualización
        return Object.keys(newValue).length === 0 ? {} : newValue;
      });
    }
  };

  useEffect(() => {
    // Si customFormatsModified es un objeto vacío, restablecer el valor del select al defaultValue
    if (Object.keys(customFormatsModified).length === 0) {
      setSelectedValue(item.provider._id);
    }
  }, [customFormatsModified, item.provider._id]);

  if (isLoading) return <p>Cargando...</p>;

  return (
    <div>
      <select style={{ maxWidth: '220px' }} value={selectedValue} onChange={handleOnChange}>
        {providersToShow(data.data, item).map((provider) => (
          <option value={provider.provider._id}>{provider.provider.name}</option>
        ))}
      </select>
    </div>
  );
};

export default SelecteProviderCustom;
