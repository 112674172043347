import axios from 'axios';
import toast from 'react-hot-toast';

export const getProviders = async () => {
  try {
    const res = await axios.get('/api/v1/proveedor');
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getNameProvider = async (provider) => {
  try {
    const res = await axios.get(`/api/v1/proveedor/${provider}/getname`);
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getGestionProviders = async (params) => {
  try {
    const res = await axios.get('/api/v1/proveedor/gestionproveedores', {
      params: params,
    });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteProvider = async (providerId) => {
  try {
    await axios.patch(`/api/v1/proveedor/${providerId}/delete`);
    toast.success('Proveedor eliminado exitosamente');
  } catch (err) {
    throw new Error(err.response?.data?.status || err.message);
  }
};

export const getProvider = async (providerId) => {
  try {
    const res = await axios.get(`/api/v1/proveedor/${providerId}`);
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const editProvider = async (provider) => {
  try {
    const { _id, ...rest } = provider;
    await axios.patch(`/api/v1/proveedor/${_id}`, rest);
  } catch (err) {
    throw new Error(err.response?.data?.status || err.message);
  }
};

export const getAaffByProveedor = async (idProvider, type) => {
  try {
    const res = await axios.get(`/api/v1/proveedor/${idProvider}/aaff`, {
      params: { type },
    });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const createProveedor = async (provider) => {
  try {
    await axios.post('/api/v1/proveedor', provider);
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};
