import { useEffect, useState } from 'react';
import { handleChangeInputSearch, handleClearInput, handleKeyDown } from '../../../helpers/buscadorHelper';
import { useAaffAvailable } from '../../../hooks/useCategory';
import { Buscador } from '../../Pedido/Buscador';
import ContainerAaffNoSelected from './ContainerAaffNoSelected';
import ContainerAaffSelected from './ContainerAaffSelected';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { handleCreateAaffMasiveToCategory } from '../../../helpers/Category/gestionCategories';

const FormAddMasiveAaff = ({ idCategory, handleClose, mutation }) => {
  const { aaffAvailable, isLoading, isFetching, valueSearchAfter, setValueSearchAfter, setPage, page } =
    useAaffAvailable(idCategory);
  const [valueSearch, setValueSearch] = useState('');
  const [aaffNoSelected, setAaffNoSelected] = useState([]);
  const [aaffSelected, setAaffSelected] = useState([]);

  useEffect(() => {
    if (isFetching) return;
    if (aaffSelected.length > 0 && aaffAvailable?.data?.length > 0) {
      aaffSelected.forEach((aaff) => {
        const aaffIndex = aaffAvailable.data.findIndex((a) => a._id === aaff._id);
        if (aaffIndex === -1) return;
        aaffAvailable.data[aaffIndex].selected = true;
      });
    }
    setAaffNoSelected((prev) => [...prev, ...aaffAvailable.data]);
  }, [isFetching]);

  useEffect(() => {
    setAaffNoSelected([]);
    if (page !== 1) {
      setPage(1);
    }
  }, [valueSearchAfter]);

  // Tenemos que mostrar un listado de los artes finales disponibles para añadir a la categoria
  return (
    <div className="p-1">
      <div className="container-buscador">
        <Buscador
          label="Buscar por código o nombre"
          value={valueSearch}
          onChange={(e) => handleChangeInputSearch(e, setValueSearch)}
          onKeyDown={(e) => handleKeyDown(e, setValueSearchAfter)}
          onClear={() => handleClearInput(setValueSearch, setValueSearchAfter)}
          width="25rem"
        />
      </div>
      <div className="container-aaff-selected-noselected">
        <ContainerAaffNoSelected
          isLoading={isLoading}
          isFetching={isFetching}
          aaffNoSelected={aaffNoSelected}
          setAaffNoSelected={setAaffNoSelected}
          aaffSelected={aaffSelected}
          setAaffSelected={setAaffSelected}
          data={aaffAvailable}
          setPage={setPage}
        />
        <div className="container-arrow-icons">
          <WestIcon />
          <EastIcon />
        </div>
        <ContainerAaffSelected
          setAaffNoSelected={setAaffNoSelected}
          aaffNoSelected={aaffNoSelected}
          aaffSelected={aaffSelected}
          setAaffSelected={setAaffSelected}
        />
      </div>
      <div className="button-group-tiendas">
        <Button
          type="button"
          onClick={(e) =>
            handleCreateAaffMasiveToCategory({
              e,
              aaffSelected,
              idCategory,
              mutation,
            })
          }
          variant="contained"
          className="bg-color-button-confirm"
          endIcon={<SendIcon />}
        >
          Confirmar
        </Button>
        <Button type="button" variant="contained" color="error" endIcon={<CloseIcon />} onClick={handleClose}>
          Cancelar
        </Button>
      </div>
    </div>
  );
};

export default FormAddMasiveAaff;
