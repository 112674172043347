import React from 'react';
import { Popover, Typography, Button } from '@mui/material';

const DeleteFormat = ({
  deletePopover,
  handleDeleteClose,
  handleDeleteConfirm,
  selectedFormat,
  setArteDeleteFromResumenPedido,
}) => {
  const { idArteFinal, idFormato, packArteFinal, packFormat } = selectedFormat || {};

  return (
    <Popover
      open={Boolean(deletePopover)}
      anchorEl={deletePopover}
      onClose={handleDeleteClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div style={{ padding: '16px', textAlign: 'center' }}>
        {packArteFinal && !packFormat ? (
          <Typography>¿Estás seguro que quieres eliminar este arte final compuesto?</Typography>
        ) : (
          <Typography>¿Estás seguro que quieres eliminar este formato?</Typography>
        )}
        <Button
          variant="contained"
          color="error"
          onClick={() =>
            handleDeleteConfirm(idArteFinal, idFormato, packArteFinal, packFormat, setArteDeleteFromResumenPedido)
          }
          style={{ margin: '8px' }}
        >
          Sí
        </Button>
        <Button variant="contained" onClick={handleDeleteClose} style={{ margin: '8px', background: '#333' }}>
          No
        </Button>
      </div>
    </Popover>
  );
};

export default DeleteFormat;
