import React, { useState, useEffect, useRef, useContext } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';
import FormatSelected from '../Pedido/FormatSelected';
import { ArteFinalContext_v2 } from '../../context/ArteFinalContext_v2';

const DropdownOption = ({ onChange, options, id }) => {
  const { formatSelected } = useContext(ArteFinalContext_v2);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    setIsOpen(false);
    onChange(value);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedOption = options.find((option) => option.value === formatSelected?.formatoId);

  return (
    <div className="custom-select-container" ref={dropdownRef}>
      <div className="custom-dropdown" id={id} onClick={handleToggle}>
        {selectedOption ? <FormatSelected selected={true} option={selectedOption} /> : 'Select an option'}
        {!isOpen ? <IoIosArrowDown className="arrow-icon" /> : <IoIosArrowUp className="arrow-icon" />}
      </div>
      {isOpen && (
        <ul className="custom-dropdown-menu">
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionClick(option.value)}>
              <FormatSelected selected={false} option={option} onClick={(e) => e.stopPropagation()} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownOption;
