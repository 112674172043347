import ImageCartSpinner from '../../../components/Spinners/ImageCartSpinner';

const CartItemImageArteFinal = ({
  onClick = null,
  styleContainer = null,
  styleImg = null,
  img,
  height,
  width,
}) => {

  return (
    <div
      style={{
        ...styleContainer,
      }}
      onClick={onClick}
    >
      {
        <img
          src={img ? img : '/Image_not_available.png'}
          // placeholder={<ImageCartSpinner height={height} width={width} />}
          style={{
            ...styleImg,
          }}
        />
      }
      {/* {!loadedImgAAFF && !img && <ImageCartSpinner height={height} width={width} />} */}
    </div>
  );
};

export default CartItemImageArteFinal;
