import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, useTheme, Button, MenuItem, Select, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Piechart } from '../Graphics/Piechart';
import { Barchart } from '../Graphics/Barchart';
import { Label } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';

export const DetailGraphic = ({ areaData, moduleData, shelvesData, type }) => {
  const theme = useTheme();
  const [datos, setDatos] = useState(null);
  const [typeData, setTypeData] = useState('');
  const [typeGraphic, setTypeGraphic] = useState('');
  const [color, setcolor] = useState([]);
  const [openModalOptionGraphic, setopenModalOptionGraphic] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    height: '85vh', // Ajusta la altura máxima del modal
    bgcolor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto', // Oculta el desbordamiento del contenido
    zIndex: 200000,
    padding: '0',
  };

  useEffect(() => {
    console.log('tipo de grafico ', typeGraphic);
    console.log('tipo de datos ', typeData);
    console.log('color', color);
  }, [typeGraphic, typeData]);

  const switcher = () => {
    switch (typeData) {
      case 'mercado':
        setDatos(areaData);
        break;
      case 'modulos':
        setDatos(moduleData);
        break;
      case 'baldas':
        setDatos(shelvesData);
        break;
      default:
        setDatos(areaData);
        break;
    }
  };

  useEffect(() => {
    // setDatos(areaData);

    if (areaData) {
      switcher();
      changeColor();
    }
  }, [areaData, moduleData, shelvesData, typeData]);

  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const changeColor = () => {
    // const colorsArray = new Array(datos?.length + 1).fill().map(() => generateRandomColor());

    if (datos) {
      const colorsArray = new Array(datos?.length + 1).fill().map(() => generateRandomColor());
      console.log('array de colores', colorsArray);
      setcolor(colorsArray);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleOpenModalGraphic = () => {
    setopenModalOptionGraphic(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCloseModalGrapic = () => {
    setopenModalOptionGraphic(false);
  };
  const changeData = (value) => {
    setTypeData(value);
  };

  const changeDataType = (value) => {
    setTypeGraphic(value);
    console.log(value);
  };

  return (
    <>
      {typeData && (
        <>
          <p
            style={{
              margin: '0',
              padding: '3px 0  0 5px',
              backgroundColor: 'rgb(51, 51, 51)',
              color: 'white',
              fontWeight: '600',
            }}
          >
            {typeData}
          </p>{' '}
          {/* <div style={{ width: '100%', height: '1px', backgroundColor: 'grey' }} /> */}
        </>
      )}
      <div>
        {typeGraphic === 'redondo' && <Piechart data={datos} color={color} detail={false} />}
        {typeGraphic === 'barras' && <Barchart data={datos} color={color} detail={false} />}
      </div>
      {typeData && typeGraphic ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '-10px',
            marginBottom: '5px',
          }}
        >
          <Button
            variant="text"
            onClick={handleOpenModal}
            style={{
              backgroundColor: '#8c9cbb',
              color: 'black',
              fontSize: '0.6rem',
              marginTop: '5px',
              marginRight: '10px',
            }}
          >
            Ampliar gráfico <OpenInNewIcon />
          </Button>
          <Button
            variant="contained"
            onClick={handleOpenModalGraphic}
            style={{ backgroundColor: 'rgb(51, 51, 51)', fontSize: '0.6rem', marginTop: '5px' }}
          >
            Editar gráfico
          </Button>
        </div>
      ) : (
        <div
          onClick={handleOpenModalGraphic}
          style={{
            color: 'rgb(51, 51, 51)',
            cursor: 'pointer',
            textAlign: 'center',
            marginTop: '25%',
          }}
        >
          <AddCircleOutlineIcon style={{ fontSize: '6rem' }} />
          <p style={{ fontSize: '1.4rem' }}>Añadir gráfico</p>
        </div>
      )}
      <Modal
        open={openModal}
        value={typeData}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              width: '100%',
              margin: '15px 0',
              padding: '0 10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {typeData && (
              <>
                <p
                  style={{
                    margin: '0',
                    padding: '3px 0  0 5px',
                    fontSize: '2rem',
                    color: 'rgb(51, 51, 51)',
                    fontWeight: '600',
                  }}
                >
                  {typeData}
                </p>{' '}
                {/* <div style={{ width: '100%', height: '1px', backgroundColor: 'grey' }} /> */}
              </>
            )}
            <CloseIcon
              style={{ color: 'rgb(51, 51, 51)', fontSize: '2.2rem', cursor: 'pointer' }}
              onClick={handleCloseModal}
            />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', maxHeight: '100%', width: '100%', overflow: 'hidden' }}
          >
            <div style={{ margin: 'auto' }}>
              <Typography
                id="modal-modal-description"
                sx={{
                  mt: 2,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
              >
                {/* quitar las condiciones para que funciones */}
              </Typography>
              {typeGraphic == 'redondo' && <Piechart data={datos} color={color} detail={true} />}
              {typeGraphic == 'barras' && <Barchart data={datos} color={color} detail={true} />}
            </div>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}></div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openModalOptionGraphic}
        onClose={handleCloseModalGrapic}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          // sx={style}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',

            backgroundColor: 'white',
            overflow: 'auto', // Oculta el desbordamiento del contenido
            zIndex: 200000,
          }}
        >
          <div
            style={{
              width: '100%',
              height: '50px',
              backgroundColor: 'rgb(51, 51, 51)',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CloseIcon
              style={{ color: 'white', fontSize: '2.2rem', margin: '5px', cursor: 'pointer' }}
              onClick={handleCloseModalGrapic}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
              width: '100%',
              overflow: 'hidden',
              padding: '30px',
            }}
          >
            <Label style={{ textAlign: 'center' }}>
              <h3>Selecciona los datos</h3>
            </Label>
            <Select
              style={{ marginTop: '8px' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeData || ''}
              onChange={(event) => setTypeData(event.target.value)} // Actualiza el estado typeData
            >
              <MenuItem
                value="mercado"
                onClick={() => changeData('mercado')} // Llama a la función con el valor deseado
              >
                Mercados - m2
              </MenuItem>
              <MenuItem
                value="modulos"
                onClick={() => changeData('modulos')} // Llama a la función con el valor deseado
              >
                Modulos - unidades
              </MenuItem>
              <MenuItem
                value="baldas"
                onClick={() => changeData('baldas')} // Llama a la función con el valor deseado
              >
                Baldas - unidades
              </MenuItem>
            </Select>

            <Label style={{ textAlign: 'center', marginTop: '20px' }}>
              <h3>Selecciona el tipo de gráfico</h3>
            </Label>

            <Select
              style={{ marginTop: '8px' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeGraphic}
              label=" "
              onChange={(event) => changeDataType(event.target.value)}
            >
              <MenuItem
                value="barras"
                onClick={() => {
                  changeDataType('barras');
                }}
              >
                Gráfico de columnas
              </MenuItem>
              <MenuItem
                value="redondo"
                onClick={() => {
                  changeDataType('redondo');
                }}
              >
                Gráfico circular
              </MenuItem>
            </Select>

            <Button
              variant="contained"
              style={{ marginTop: '10px', backgroundColor: 'rgb(51, 51, 51)' }}
              onClick={handleCloseModalGrapic}
            >
              guardar{' '}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
