import React, { useEffect, useState } from 'react';
import BoxInfoPartidaProyecto from './BoxInfoPartidaProyecto';
import { DescompuestosByPartidaProyecto } from './DescompuestosByPartidaProyecto';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPartidaProyectoById } from '../../api/alcampoApi';
import DeleteWithConfirmation from './Popover/DeleteWithConfirmation';
import { useQuery } from '@tanstack/react-query';
import { ProyectoContext } from '../../context/ProyectoContext';
import { useContext } from 'react';
import Viewer from '../../helpers/Viewer/Viewer';
import { CapitulosContext } from '../../context/CapitulosContext';
import { useHandleUrl } from '../../hooks/useHandleUrl';
import { useAuth0 } from '@auth0/auth0-react';

export const InfoPartidaProyecto = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { isModelLoaded, currentProject } = useContext(ProyectoContext);
  const { codesActive, setCodesActive } = useContext(CapitulosContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { changeLocationWithNavigate } = useHandleUrl();
  const { idPartidaProyecto } = useParams();
  const { data, isFetching } = useQuery(['partidas-partidaProyecto', idPartidaProyecto], () => {
    return getAccessTokenSilently().then((token) => getPartidaProyectoById(idPartidaProyecto, token));
  });
  const [isLoading, setIsLoading] = useState(true);

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };
  useEffect(() => {
    if (data) {
      setIsLoading(false); // Datos disponibles, ya no estamos cargando
    }
  }, [data]);

  useEffect(() => {
    console.log('SUPER-DAATAAAAAAAAAA', data);
  }, [data]);

  const handleClickGoBackToPartidasProyecto = () => {
    const viewerClass = new Viewer(currentProject?._id);
    viewerClass.getDbIdsByCodes(codesActive);
    changeLocationWithNavigate(`${location.pathname.replace(`/partida/${idPartidaProyecto}`, '')}`);
  };

  useEffect(() => {
    if (!data) return;
    setCodesActive([data.partida.code]);
  }, [idPartidaProyecto, data]);

  useEffect(() => {
    if (!isModelLoaded) return;

    const viewElementsInModel = async () => {
      const viewerInstance = new Viewer(idPartidaProyecto);
      await viewerInstance.getDbIdsByCodes([data.partida.code]);
    };

    viewElementsInModel();
  }, [isModelLoaded, codesActive]);

  return (
    <div className="container">
      {isLoading ? (
        <div class="custom-loader"></div>
      ) : (
        <div>
          <div>
            <p style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem' }}>
              {' '}
              <div style={{ display: 'flex' }}>
                <span style={{ cursor: 'pointer' }} onClick={handleClickGoBackToPartidasProyecto}>
                  <strong>Partidas </strong>
                </span>{' '}
                - <p>{data.partida.code}</p>- <p>{data.partida.name}</p>
              </div>
              <DeleteWithConfirmation idPartidaProyecto={idPartidaProyecto} />
            </p>
            <div style={{ display: 'flex' }}>
              <ArrowDropDownIcon />
              <h2>
                {' '}
                {data.partida.code} - {data.partida.name}
              </h2>
            </div>

            <div style={{ display: 'flex' }}>
              <p className={`description ${isExpanded ? 'expanded' : ''}`}>{data.partida.description} </p>
              <ArrowDropDownIcon className={`expand-icon ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand} />
            </div>
            <BoxInfoPartidaProyecto data={data} idPartidaProyecto={idPartidaProyecto} />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem' }}>
            <div style={{ display: 'flex' }}>
              <ArrowDropDownIcon />
              <h2> Descompuestos </h2>
            </div>
            <div style={{ margin: '5px' }}>
              {/* <AddCircleIcon style={{ cursor: 'pointer' }} /> */}
              {/* <DeleteIcon style={{ cursor: 'pointer' }} /> */}
              {/* <RemoveRedEyeIcon style={{ cursor: 'pointer' }} onClick={viewElementsInModel} /> */}
            </div>
          </div>

          <DescompuestosByPartidaProyecto data={data} />
        </div>
      )}
    </div>
  );
};
