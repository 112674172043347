import toast from 'react-hot-toast';
import { handleEditCategory } from '../../../helpers/Category/gestionCategories';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FormControlLabel, Switch, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

const FormEditCategory = ({ categoryName, mutation, handleClose, idCategory }) => {
  const navigate = useNavigate();

  const [newCategoryName, setNewCategoryName] = useState(categoryName?.data?.name);
  const [disabled, setDisabled] = useState(categoryName?.data?.disabled);
  const [important, setImportant] = useState(categoryName?.data?.important);

  return (
    <form
      onSubmit={(e) =>
        handleEditCategory({
          e,
          mutation,
          category: idCategory,
          newCategoryName,
          disabled,
          important,
          navigate,
          toast,
        })
      }
    >
      <TextField
        label="Nombre de la categoría"
        variant="outlined"
        type="text"
        minRows={3}
        required
        fullWidth
        onChange={(e) => setNewCategoryName(e.target.value)}
        value={newCategoryName}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '1rem 0' }}>
        {!categoryName?.data?.parentCategory && (
          <FormControlLabel
            control={<Switch onChange={(e) => setDisabled(!e.target.checked)} checked={!disabled} />}
            label="Visible"
          />
        )}
        {!categoryName?.data?.parentCategory && (
          <FormControlLabel
            control={<Switch onChange={(e) => setImportant(e.target.checked)} checked={important} />}
            label="Importante"
          />
        )}
      </div>
      <div className="button-group-tiendas">
        <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
          Confirmar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="error"
          endIcon={<CloseIcon />}
          onClick={() => handleClose(navigate)}
        >
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default FormEditCategory;
