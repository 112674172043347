import axios from 'axios';

export const getStatsMoreCentersOrders = async () => {
  try {
    const response = await axios.get('/api/v1/center/stats/more-orders');
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getStatsCenter = async (centerId) => {
  try {
    const response = await axios.get(`/api/v1/center/stats/${centerId}`);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};
