import { getDataExcelPilotado } from '../../api/carteleriaApi';
import { utils, writeFile } from 'xlsx-js-style';

export const generateExcelPilotado = async (token, idPilotado) => {
  try {
    const res = await getDataExcelPilotado(token, idPilotado);

    console.log('VEAMOS EL RES: ', res);

    const arteFinalData = res.data[0].arteFinal;

    const fecha = res?.createdAt;

    const { año, fechaFormateada } = obtenerInformacionFecha(fecha);

    const rows = [
      [
        { v: '', t: 's' },
        { v: '', t: 's', s: { fill: { fgColor: { rgb: 'CCFFCC' } } } },
      ],
      [
        { v: '', t: 's' },
        { v: 'Responsable: ' + res?.responsable, t: 's', s: { fill: { fgColor: { rgb: 'CCFFCC' } } } },
      ],
      [
        { v: '', t: 's' },
        { v: '', t: 's', s: { fill: { fgColor: { rgb: 'CCFFCC' } } } },
      ],
      [
        { v: '', t: 's' },
        {
          v: 'A partir del ' + fechaFormateada,
          t: 's',
          s: { font: { bold: true }, alignment: { horizontal: 'center' }, fill: { fgColor: { rgb: 'CCFFCC' } } },
        },
      ],
    ];

    const rowFormatCode = [
      { v: '', t: 's' },
      { v: '', t: 's', s: { fill: { fgColor: { rgb: 'CCFFCC' } } } },
      { v: '', t: 's', s: { fill: { fgColor: { rgb: 'CCFFCC' } } } },
    ];
    const rowArteFinalName = [
      { v: '', t: 's' },
      {
        v: año,
        t: 's',
        s: { font: { bold: true }, fill: { fgColor: { rgb: 'CCFFCC' } }, alignment: { horizontal: 'center' } },
      },
      { v: '', t: 's', s: { fill: { fgColor: { rgb: 'CCFFCC' } } } },
    ];
    const rowFormatName = [
      { v: '', t: 's' },
      { v: '', t: 's', s: { fill: { fgColor: { rgb: 'CCFFCC' } } } },
      { v: '', t: 's', s: { fill: { fgColor: { rgb: 'CCFFCC' } } } },
    ];

    const tiradaCoste = [
      { v: '', t: 's' },
      { v: '', t: 's', s: { fill: { fgColor: { rgb: 'CCFFCC' } } } },
      { v: '', t: 's' },
    ];
    const tiradaCosteValue = [
      { v: '', t: 's' },
      { v: '', t: 's', s: { fill: { fgColor: { rgb: 'CCFFCC' } } } },
      { v: '', t: 's' },
    ];

    // Iteramos sobre cada arteFinal
    arteFinalData.forEach((arteFinal) => {
      // Creamos una fila para este arteFinal
      rowFormatCode.push({
        v: arteFinal.formatCode,
        t: 's',
        s: { alignment: { horizontal: 'center' }, fill: { fgColor: { rgb: 'CCFFCC' } }, font: { bold: true, sz: 11 } },
      });
      rowFormatCode.push(null);
      rowArteFinalName.push({
        v: arteFinal.name,
        t: 's',
        s: { alignment: { horizontal: 'center' }, fill: { fgColor: { rgb: 'CCFFCC' } }, font: { bold: true, sz: 11 } },
      });
      rowArteFinalName.push(null);
      rowFormatName.push({
        v: arteFinal.formatName,
        t: 's',
        s: { alignment: { horizontal: 'center' }, fill: { fgColor: { rgb: 'CCFFCC' } }, font: { bold: true, sz: 11 } },
      });
      rowFormatName.push(null);

      tiradaCoste.push({
        v: 'tirada',
        t: 's',
        s: { alignment: { horizontal: 'center' } },
      });

      tiradaCoste.push({
        v: 'coste',
        t: 's',
        s: { alignment: { horizontal: 'center' } },
      });

      tiradaCosteValue.push({
        v:
          //  Sacar el total de este formato (suma de todas sus quantitys)
          res.data
            .reduce(
              (acc, value) =>
                acc + value.arteFinal.find((item) => item.formatCode === arteFinal.formatCode)?.quantity || 0,
              0,
            )
            .toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        t: 's',
        s: { alignment: { horizontal: 'center' } },
      });

      tiradaCosteValue.push({
        v: arteFinal.price.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        t: 's',
        s: { font: { bold: true }, alignment: { horizontal: 'center' } },
      });
    });

    rows.push(rowFormatCode);
    rows.push(rowArteFinalName);
    rows.push(rowFormatName);
    rows.push(tiradaCoste);
    rows.push(tiradaCosteValue);

    // Objeto para agrupar los datos por centerType
    let dataAgrupedByCenterType = {};

    // Iterar sobre los datos
    res.data.forEach((item) => {
      // Obtener el centerType del elemento actual
      const centerType = item.centerType.nameCenterType;

      // Verificar si el centerType ya está en el objeto
      if (!dataAgrupedByCenterType.hasOwnProperty(centerType)) {
        // Si no está, inicializarlo como un array vacío
        dataAgrupedByCenterType[centerType] = [];
      }

      // Agregar el elemento al array correspondiente al centerType
      dataAgrupedByCenterType[centerType].push(item);
    });

    //  Ahora una vez tenemos agrupados todos los centros vamos a ir añadiendo filas, primero añadimos la fila de la key que sera el centerType y justo debajo todos sus values que seran los centros y asi hasta terminar con todas las keys
    // Iterar sobre las claves (centerType) del objeto agrupado
    Object.keys(dataAgrupedByCenterType).forEach((centerType, i) => {
      // Añadir una fila para el centerType actual
      const centros = dataAgrupedByCenterType[centerType];
      // rows.push([
      //   { v: '', t: 's' },
      //   {
      //     v: centerType,
      //     t: 's',
      //     s: {
      //       fill: { fgColor: { rgb: 'E2EFD9' } },
      //       font: { bold: true },
      //       border: { bottom: { style: 'thin', color: { rgb: '000000' } } },
      //     },
      //   },
      //   {
      //     v: i === 0 ? 'CENTRO DE COSTES' : '',
      //     t: 's',
      //     s: { fill: { fgColor: { rgb: 'E2EFD9' } }, font: { bold: true } },
      //   },
      // ]);

      // Añadir columnas para cantidad y precio total de cada arte final
      // arteFinalData.forEach((arteFinal) => {
      //   const totalQuantity = centros.reduce((acc, centro) => {
      //     const quantity = centro.arteFinal.find((item) => item.formatCode === arteFinal.formatCode)?.quantity || 0;
      //     return acc + quantity;
      //   }, 0);

      //   const totalPrice = arteFinal.price * totalQuantity;

      //   // Formatear números a dos decimales con , para decimales y . para millares
      //   const formattedQuantity = totalQuantity.toLocaleString('es-ES', {
      //     minimumFractionDigits: 0,
      //     maximumFractionDigits: 2,
      //   });
      //   const formattedPrice = totalPrice.toLocaleString('es-ES', {
      //     minimumFractionDigits: 2,
      //     maximumFractionDigits: 2,
      //   });

      //   rows[rows.length - 1].push(
      //     {
      //       v: formattedQuantity,
      //       t: 's',
      //       s: { fill: { fgColor: { rgb: 'E2EFD9' } }, alignment: { horizontal: 'center' }, font: { bold: true } },
      //     },
      //     {
      //       v: formattedPrice,
      //       t: 's',
      //       s: { fill: { fgColor: { rgb: 'E2EFD9' } }, alignment: { horizontal: 'center' }, font: { bold: true } },
      //     },
      //   );
      // });

      // Añadimos una columna para el precio total de cada centerType
      // const totalImporteCenterType = centros.reduce((acc, centro) => {
      //   return (
      //     acc +
      //     arteFinalData.reduce((acc2, arteFinal) => {
      //       const quantity = centro.arteFinal.find((item) => item.formatCode === arteFinal.formatCode)?.quantity || 0;
      //       return acc2 + arteFinal.price * quantity;
      //     }, 0)
      //   );
      // }, 0);

      // rows[rows.length - 1].push({
      //   v: totalImporteCenterType.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      //   t: 's',
      //   s: { fill: { fgColor: { rgb: '8cb36f' } }, alignment: { horizontal: 'center' }, font: { bold: true } },
      // });

      centros.forEach((centro) => {
        rows.push([
          {
            v: centro._id.centerCode,
            t: 's',
            s: { fill: { fgColor: { rgb: 'FFFF00' } }, alignment: { horizontal: 'center' } },
          },
          { v: centro._id.centerName || centro._id.centerCalle, t: 's', s: { fill: { fgColor: { rgb: 'FFFF00' } } } },
          { v: centro._id.centerCentroCostes, t: 's', s: { alignment: { horizontal: 'center' } } },
        ]);
        // Hacemos lo mismo que con los centerTypes pero con los centros
        arteFinalData.forEach((arteFinal) => {
          const quantity = centro.arteFinal.find((item) => item.formatCode === arteFinal.formatCode)?.quantity || 0;
          const price = arteFinal.price * quantity;

          // Formatear números a dos decimales con , para decimales y . para millares
          const formattedQuantity = quantity.toLocaleString('es-ES', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
          const formattedPrice = price.toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          rows[rows.length - 1].push(
            {
              v: formattedQuantity,
              t: 's',
              s: { fill: { fgColor: { rgb: 'FFFF00' } }, alignment: { horizontal: 'center' } },
            },
            {
              v: formattedPrice,
              t: 's',
              s: { fill: { fgColor: { rgb: 'FFFF00' } }, alignment: { horizontal: 'center' } },
            },
          );
        });
        //  Como ultima columna de cada centro sera el precio total de ese centro
        const totalImporteCentro = arteFinalData.reduce((acc, arteFinal) => {
          const quantity = centro.arteFinal.find((item) => item.formatCode === arteFinal.formatCode)?.quantity || 0;
          return acc + arteFinal.price * quantity;
        }, 0);

        rows[rows.length - 1].push({
          v: totalImporteCentro.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          t: 's',
          s: { fill: { fgColor: { rgb: 'f5bfbf' } }, alignment: { horizontal: 'center' }, font: { bold: true } },
        });
      });
    });

    //  Una vez que hemos añadido todas las filas vamos a añadir una fila con el total de cada arte final
    rows.push([
      { v: '', t: 's' },
      { v: 'TOTAL', t: 's', s: { fill: { fgColor: { rgb: 'FFCC99' } } } },
      { v: '', t: 's', s: { fill: { fgColor: { rgb: 'FFCC99' } } } },
    ]);

    arteFinalData.forEach((arteFinal) => {
      const totalQuantity = res.data.reduce(
        (acc, value) => acc + value.arteFinal.find((item) => item.formatCode === arteFinal.formatCode)?.quantity || 0,
        0,
      );
      const totalPrice = arteFinal.price * totalQuantity;

      // Formatear números a dos decimales con , para decimales y . para millares
      const formattedQuantity = totalQuantity.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      const formattedPrice = totalPrice.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

      rows[rows.length - 1].push(
        {
          v: formattedQuantity,
          t: 's',
          s: { fill: { fgColor: { rgb: 'FFCC99' } }, alignment: { horizontal: 'center' }, font: { bold: true } },
        },
        {
          v: formattedPrice,
          t: 's',
          s: { fill: { fgColor: { rgb: 'FFCC99' } }, alignment: { horizontal: 'center' }, font: { bold: true } },
        },
      );
    });

    // AÑADIMOS UNA ULTIMA COLUMNA QUE SEA EL TOTAL IMPORTE QUE ES EL LA SUMA DE LA MULTIPLICACION DEL PRECIO POR TODAS LAS CANTIDADES DE TODOS LOS ARTES
    const totalImporte = res.data.reduce((acc, value) => {
      return (
        acc +
        arteFinalData.reduce((acc2, arteFinal) => {
          return (
            acc2 +
              arteFinal.price * value.arteFinal.find((item) => item.formatCode === arteFinal.formatCode)?.quantity || 0
          );
        }, 0)
      );
    }, 0);

    rows[rows.length - 1].push({
      v: totalImporte.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      t: 's',
      s: { fill: { fgColor: { rgb: 'FC9292' } }, alignment: { horizontal: 'center' }, font: { bold: true } },
    });

    // Creamos la hoja de cálculo
    const ws = utils.aoa_to_sheet(rows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Pilotado');

    // Definir el área de fusión específica para las filas 5, 6 y 7
    const mergeCells = [];

    // Iterar sobre las filas 5, 6 y 7 para fusionar sus columnas

    for (let row = 4; row <= 6; row++) {
      let mergeStart = -1;
      let mergeEnd = -1;
      for (let col = 3; col < rows[row].length; col++) {
        if (rowFormatCode[col] !== null) {
          if (mergeStart === -1) {
            mergeStart = col;
            mergeEnd = col + 1;
          } else {
            mergeEnd = col;
          }
        } else {
          if (mergeStart !== -1) {
            mergeCells.push({ s: { r: row, c: mergeStart }, e: { r: row, c: mergeEnd } });
            mergeStart = -1;
            mergeEnd = -1;
          }
        }
      }
      if (mergeStart !== -1 && mergeEnd !== -1) {
        mergeCells.push({ s: { r: row, c: mergeStart }, e: { r: row, c: mergeEnd } });
      }
    }

    // Aplicar el área de fusión a la hoja de cálculo
    if (mergeCells.length > 0) {
      if (!ws['!merges']) {
        ws['!merges'] = [];
      }
      mergeCells.forEach((merge) => {
        ws['!merges'].push(merge);
      });
    }

    // Definir el estilo con la fuente Arial
    const estiloArial = { font: { name: 'Arial' } };

    // Aplicar el estilo a todas las celdas de la hoja de cálculo
    const range = utils.decode_range(ws['!ref']);
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = { c: col, r: row };
        const cell = ws[utils.encode_cell(cellAddress)];
        if (!cell) continue;
        const style = cell.s || {};
        // Verificar si ya existe la propiedad de fuente
        if (style.font) {
          // Fusionar el estilo de Arial con el estilo existente sin sobrescribir otros parámetros de fuente
          style.font.name = 'Arial';
        } else {
          // Si no existe la propiedad de fuente, agregar el estilo Arial
          Object.assign(style, estiloArial);
        }
        cell.s = style;
      }
    }

    // Ajustar el ancho de las columnas manualmente
    // Obtener dinámicamente el número de columnas
    const maxColumns = Math.max(...rows.map((row) => row.length));

    // Crear un array de índices de columnas basado en el número máximo de columnas
    const columnIndexes = Array.from({ length: maxColumns }, (_, index) => index);

    columnIndexes.forEach((colIndex) => {
      let totalWidth = 0;
      let totalColumns = 0;

      // Iterar sobre las celdas en la columna
      const range = utils.decode_range(ws['!ref']);
      for (let row = range.s.r; row <= range.e.r; row++) {
        const cellAddress = { c: colIndex, r: row };
        const cell = ws[utils.encode_cell(cellAddress)];
        if (cell && cell.v) {
          // Calcular el ancho de la celda combinada
          let mergedWidth = 0;
          const merges = ws['!merges'] || [];
          merges.forEach((merge) => {
            if (merge.s.c <= colIndex && merge.e.c >= colIndex && merge.s.r <= row && merge.e.r >= row) {
              for (let i = merge.s.c; i <= merge.e.c; i++) {
                mergedWidth += ws['!cols'][i] ? ws['!cols'][i].width || 0 : 0;
              }
            }
          });
          totalWidth += mergedWidth > 0 ? mergedWidth : ws['!cols']?.[colIndex] ? ws['!cols'][colIndex].width || 0 : 0;
          totalColumns++;
        }
      }

      // Calcular el ancho promedio de las celdas combinadas
      const averageWidth = totalColumns > 0 ? totalWidth / totalColumns : 0;

      // Establecer el ancho de la columna basado en el ancho promedio
      ws['!cols'] = ws['!cols'] || [];
      ws['!cols'][colIndex] = { width: averageWidth + 40 };
    });

    // Escribir el archivo Excel
    writeFile(wb, `pilotado_${res.idPilotado}.xlsx`);
  } catch (err) {
    console.error('Error al generar el excel del pilotado: ', err);
  }
};

const obtenerInformacionFecha = (fecha) => {
  // Convertir la cadena de fecha a objeto Date
  const fechaObj = new Date(fecha);

  // Obtener el año
  const año = fechaObj.getFullYear();

  // Obtener el mes y el día
  const meses = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];
  const mesIndex = fechaObj.getMonth();
  const dia = fechaObj.getDate();
  const mes = meses[mesIndex];

  // Formatear la fecha
  const fechaFormateada = `${dia} de ${mes}`;

  return { año, fechaFormateada };
};
