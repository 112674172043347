import { IconButton } from '@mui/material';
import { STATS } from '../../../constants/menuStats';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const MenuStats = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (link) => {
    const currentPath = location.pathname;
    const currentStat = STATS.find((stat) => currentPath.includes(stat.link));

    if (currentStat) {
      const newPath = currentPath.replace(currentStat.link, link);
      navigate(newPath.replace(/\/{2,}/g, '/'));
    } else {
      navigate(`${currentPath}/${link}`.replace(/\/{2,}/g, '/'));
    }
  };

  return STATS.map((stat, index) => (
    <div
      key={index}
      className={`${
        location.pathname.includes(stat.link) ? 'menu-estadisticas-item-selected' : ''
      } menu-estadisticas-item`}
    >
      <div className="d-flex jc-space-between ai-center">
        <h3>{stat.label}</h3>
        <IconButton onClick={() => handleClick(stat.link)}>
          {location.pathname.includes(stat.link) ? <IoEyeOffOutline /> : <IoEyeOutline />}
        </IconButton>
      </div>
      <div className="line-space"></div>
      <div className="menu-estadisticas-item-description">
        <div className="menu-estadisticas-item-point" />

        {/* Tooltip para descripciones largas */}
        <p
          className="menu-estadisticas-description m-0"
          data-tooltip-id={`tooltip-${index}`}
          data-tooltip-content={stat.description}
        >
          {stat.description}
        </p>
        <ReactTooltip style={{ maxWidth: '300px' }} id={`tooltip-${index}`} place="top" effect="solid" />
      </div>
    </div>
  ));
};

export default MenuStats;
