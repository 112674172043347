import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteMaterial, getAaffByMaterial, getGestionMateriales, getMaterial } from '../api/materialApi';
import { useContext, useEffect, useState } from 'react';
import { GestionMaterialContext } from '../context/GestionMaterialContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllMaterials } from '../api/carteleriaApi';
import { useAuth0 } from '@auth0/auth0-react';

export const useMateriales = () => {
  const LIMIT = 12;
  const { inputValueSearch } = useContext(GestionMaterialContext);
  const [activeFilter, setActiveFilter] = useState('all');
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [inputValueSearch]);

  const { data: materiales, isLoading } = useQuery(['gestion-materiales', page, inputValueSearch, activeFilter], () =>
    getGestionMateriales({
      page,
      limit: LIMIT,
      inputValueSearch,
      activeFilter,
    }),
  );

  return { materiales, activeFilter, setActiveFilter, setPage, isLoading, limit: LIMIT, page };
};

export const useDeleteMateriales = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [deletePopover, setDeletePopover] = useState(null);
  const [materialId, setMaterialId] = useState('');

  const handleDeleteClose = () => {
    setDeletePopover(null);
  };

  const handleDeleteClick = (event) => {
    setMaterialId(event.currentTarget.id);
    setDeletePopover(event.currentTarget);
  };

  const { mutate: handleDeleteConfirm } = useMutation({
    mutationFn: async () => {
      handleDeleteClose();
      await deleteMaterial(materialId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('gestion-materiales');
    },
    onError: (err) => {
      if (err.message === 'error-delete-format-associated') {
        navigate(`${location.pathname}/${materialId}/error-delete-material`);
      }
    },
  });

  return { deletePopover, handleDeleteClose, handleDeleteClick, handleDeleteConfirm };
};

export const useEditMaterial = (materialId) => {
  const { data: material, isLoading } = useQuery(['material', materialId], () => getMaterial(materialId));

  return { material, isLoading };
};

export const useGetAaffByMaterial = (idMaterial) => {
  const { data, isLoading } = useQuery(['aaff-asociated', idMaterial], () => getAaffByMaterial(idMaterial));

  return { aaffAsociated: data?.data, materialName: data?.materialName, isLoading };
};

export const useGetAllMaterials = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['all-materials'],
    queryFn: async () => {
      let token = await getAccessTokenSilently();
      return getAllMaterials(token);
    },
  });
};
