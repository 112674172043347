import { Tab, Tabs } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

const TabsNavigate = () => {
  const location = useLocation();

  const tabsInfo = [
    { label: 'Solicitudes', path: '/admin/orders' },
    { label: 'Estadísticas', path: '/admin/stats' },

    // { label: 'Pedidos pilotados', path: '/admin/pilotados' },
  ];

  return (
    <Tabs value={tabsInfo.findIndex((tab) => location.pathname.includes(tab.path))}>
      {tabsInfo.map((tab, index) => (
        <NavLink className={'tabs-navigate-admin'} to={tab.path}>
          <Tab key={index} label={tab.label} />
        </NavLink>
      ))}
    </Tabs>
  );
};

export default TabsNavigate;
