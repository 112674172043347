import { useGetCategoriesTreeByArte } from '../../../../hooks/useCategory';
import SkeletonLoader from '../../../common/SkeletonLoader';
import InfoArteFinalContainer from './InfoArteFinalContainer';
import InfoArteFinalText from './InfoArteFinalText';
import InfoArteFinalTitle from './InfoArteFinalTitle';

const CategorySection = ({ idArte }) => {
  const { data: categoriesByArteFinal, isLoading } = useGetCategoriesTreeByArte(idArte);

  const handleCategoriesToShow = (categories) => {
    const allCategoriesHandled = categories.map((categoryArray) =>
      categoryArray.map((category) => category.name).join(' > '),
    );

    return allCategoriesHandled;
  };

  return (
    <div className="main-info-section-container">
      <InfoArteFinalContainer>
        <InfoArteFinalTitle>Categorías</InfoArteFinalTitle>
        {isLoading ? (
          <SkeletonLoader count={1} minHeight={'35px'} />
        ) : (
          handleCategoriesToShow(categoriesByArteFinal.data)
            .sort()
            .map((row, index) => <InfoArteFinalText key={index}>{row}</InfoArteFinalText>)
        )}
      </InfoArteFinalContainer>
    </div>
  );
};

export default CategorySection;
