import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from '../../hooks/useDebounce';

export const Buscador = ({ label, width, value, onChange, onKeyDown, onClear }) => {
  const [inputValue, setInputValue] = useState(value);

  // Usamos el hook de debounce para retrasar la actualización del valor de entrada
  const debouncedSetInputValue = useDebounce(onChange, 300); // 300 ms de delay

  useEffect(() => {
    debouncedSetInputValue({ target: { value: inputValue } });
  }, [inputValue, debouncedSetInputValue]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleClear = () => {
    setInputValue('');
    onClear();
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder={label ? label : 'Busca por nombre'}
      className={`w-search-${width}`}
      sx={{
        marginBottom: '0',
        backgroundColor: 'white',
      }}
      value={inputValue}
      onChange={handleInputChange}
      onKeyDown={onKeyDown}
      InputProps={{
        endAdornment: inputValue ? (
          <HighlightOffIcon sx={{ color: 'action.active', cursor: 'pointer' }} onClick={handleClear} />
        ) : (
          <SearchIcon
            style={{
              fontSize: '30px',
              cursor: 'pointer',
            }}
          />
        ),
      }}
    />
  );
};
