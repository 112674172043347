import { useGetMoreCentersOrders } from '../../../lib/react-query/centerQuery';
import StatsChart from './StatsChart';

const MoreCentersOrders = () => {
  const { data: centers, isLoading } = useGetMoreCentersOrders();

  return <StatsChart data={centers} keys={{ pedidos: 'Pedidos', importe: 'Importe' }} isLoading={isLoading} />;
};

export default MoreCentersOrders;
