import React from 'react';
import '../../css/error404.css';
import logo from '../../assets/img/logo/logot.png';

const Page404 = () => {
  return (
    <>
    <div className="error-container">
      <h1 className="error-code">404</h1>
      <p className="error-text">Lo sentimos, la página que estás buscando no se encuentra disponible.</p>


    </div>
    <div className='image-container'>
    <img src={logo} height={50} width={50} className="flying-bird" alt="Flying Bird" />
    </div>
    </>
  );
};

export default Page404;
