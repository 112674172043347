import cls from 'classnames';

const InfoArteFinalTitle = ({ classnames, children, ...props }) => {
  return (
    <p className={cls('item-arte-final-header', classnames)} {...props}>
      {children}
    </p>
  );
};

export default InfoArteFinalTitle;
