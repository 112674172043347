import React from 'react';
import * as yup from 'yup';
import { Modal, Typography } from '@mui/material';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import useEditCluster from './useEditCluster';
import { Colxx } from '../../../components/common/CustomBootstrap';
import GlobalSpinner from '../../../Components2/common/GlobalSpinner';
import useGetAllMerchandising from '../GestionTiendas/useGetAllMerchandising';
import useCreateCluster from './useCreateCluster';
import toast from 'react-hot-toast';

const validationSchema = yup.object().shape({
  type: yup.string().required('El tipo de cluster es obligatorio'),
  merchandising: yup.string().required('El merchandising es obligatorio'),
  active: yup.boolean().required('El campo activo, es obligatorio'),
});

const isOpen = true;

function CenterModal({ mode }) {
  const navigate = useNavigate();
  const isEditMode = mode === 'edit';

  const handleOnClose = () => {
    navigate('/master/clusters');
  };

  const { postCluster, isLoading: isCreating } = useCreateCluster();
  const { editingCluster, checkCentersStatus, center, isLoading: isFetchingCenter } = useEditCluster(isEditMode);

  const isLoading = isCreating || (isEditMode && isFetchingCenter);

  const initialValues = {
    merchandising: center?.merchandising || '',
    active: center?.active != null ? center?.active : true,

    type: center?.type || '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (isEditMode) {
        try {
          const validateCheck = await checkCentersStatus(values.active);
          if (validateCheck.allCentersActive) {
            await editingCluster(values);
            handleOnClose();
          } else {
            toast.error('No se puede invalidar el cluster porque tiene centros asociados activos', {
              id: 'error-cluster-active',
            });
          }
        } catch (error) {
          toast.error('Error al comprobar los centros asociados al cluster: ' + error.message);
        }
      } else {
        postCluster(values, {
          onSuccess: () => {
            handleOnClose();
          },
        });
      }
    },
    enableReinitialize: true,
  });

  const { merchandising } = useGetAllMerchandising();
  //   const { allCenterTypes } = useGetAllCenterTypes();

  const getErrorText = (field) => {
    return formik.touched[field] && formik.errors[field] ? formik.errors[field] : '';
  };

  return (
    <Modal open={isOpen} onClose={handleOnClose}>
      <div className="create-cluster-container-modal">
        {!isLoading ? (
          <div className="center-form-title">
            <Typography
              variant="h4"
              component="h2"
              align="center"
              sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
            >
              {isEditMode ? 'EDITAR CLUSTER' : 'CREAR CLUSTER'}
            </Typography>
            <form className="center-form" onSubmit={formik.handleSubmit}>
              <div className="cluster-row-md">
                <Colxx xxs="4">
                  <TextField
                    id="type"
                    name="type"
                    label="Tipo"
                    variant="outlined"
                    required
                    fullWidth
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={getErrorText('type')}
                  />
                </Colxx>

                <Colxx xxs="4">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={formik.touched.merchandising && Boolean(formik.errors.merchandising)}
                  >
                    <InputLabel htmlFor="merchandising" shrink={formik.values.merchandising !== ''}>
                      Merchandising*
                    </InputLabel>
                    <Select
                      id="merchandising"
                      name="merchandising"
                      value={formik.values.merchandising}
                      onChange={formik.handleChange}
                      displayEmpty
                      input={<OutlinedInput label="Comunidad" />}
                    >
                      {merchandising?.data?.map((merchandising) => (
                        <MenuItem key={merchandising._id} value={merchandising._id}>
                          {merchandising.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{getErrorText('comunidad')}</FormHelperText>
                  </FormControl>
                </Colxx>
              </div>

              <div className="cluster-row-md">
                <Colxx xxs="4">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={formik.touched.active && Boolean(formik.errors.active)}
                  >
                    <InputLabel id="active">Activo*</InputLabel>
                    <Select
                      labelId="active"
                      id="active"
                      name="active"
                      label="Activo"
                      value={formik.values.active}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={true}>Sí</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                    <FormHelperText>{getErrorText('active')}</FormHelperText>
                  </FormControl>
                </Colxx>
                <Colxx xxs="4"></Colxx>
              </div>

              <div className="button-group-tiendas">
                <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
                  Confirmar
                </Button>
                <Button type="button" variant="contained" color="error" endIcon={<CloseIcon />} onClick={handleOnClose}>
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className="spinner-container">
            <GlobalSpinner />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default CenterModal;
