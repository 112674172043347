import cls from 'classnames';

const CustomButton = ({ classname, variant, children, ...props }) => {
  const typeClass = () => {
    switch (variant) {
      case 'success':
        return 'custom-button--success';
      case 'secondary':
        return 'custom-button--secondary';
      case 'default':
        return;
    }
  };

  return (
    <button className={cls('custom-button', classname, typeClass())} {...props}>
      {children}
    </button>
  );
};

export default CustomButton;
