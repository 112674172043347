import { CardMedia } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useContext, useState } from 'react';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { modifiedIsDeleteArteFinalMaster } from '../../../api/carteleriaApi';
import OneArteMasterSpinner from '../../../components/Spinners/OneArteMasterSpinner';
import { DialogWithImg } from '../../Pedido/DialogWithImg';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UploadImageArteFinal from './UploadImageArteFinal';
import InfoArteFinalContainer from './ArteFinalInfo/InfoArteFinalContainer';
import InfoArteFinalTitle from './ArteFinalInfo/InfoArteFinalTitle';
import InfoArteFinalText from './ArteFinalInfo/InfoArteFinalText';
import TitleSection from './ArteFinalInfo/TitleSection';
import ButtonsImagesArteFinal from './ButtonsImagesArteFinal';
import DeletePopover from '../../common/DeletePopover';
import { useQueryClient } from '@tanstack/react-query';
import { UserContext } from '../../../context/UserProvider';
import CategorySection from './ArteFinalInfo/CategorySection';
import AddLinksToArteFinal from './AddLinksToArteFinal';

export const InfoArteFinal = ({ infoArteFinal }) => {
  const { idArte } = useParams();
  const { userRole } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const [modalImgFullScreen, setModalImgFullScreen] = useState(false);
  const [deletePopover, setDeletePopover] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const openModalImgFullScreen = () => {
    setModalImgFullScreen(true);
  };

  const closeModalImgFullScreen = () => {
    setModalImgFullScreen(false);
  };

  //*Modal
  const handleClicEditArte = () => {
    // navegar hasta la url correspondiente
    const hasTrailingSlash = location.pathname.endsWith('/');
    if (!hasTrailingSlash) {
      navigate(`${location.pathname}/edit`);
    } else {
      navigate(`${location.pathname}edit`);
    }
  };

  const formatBoolean = (bool) => (bool ? 'Si' : 'No');

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY - HH:mm:ss ');
  };

  const handleDeleteClick = (event) => {
    setDeletePopover(event.currentTarget);
  };

  const handleDeleteClose = () => {
    setDeletePopover(null);
  };

  const handleDeleteConfirm = async () => {
    const token = await getAccessTokenSilently();
    await modifiedIsDeleteArteFinalMaster(token, idArte, { isDelete: true });
    queryClient.invalidateQueries('all-artes-finales-master');
    navigate('/master');
    handleDeleteClose();
  };

  return (
    <div className="one-arte-final-container">
      <div className="main-info-container">
        <TitleSection title={`${infoArteFinal.code || ''} - ${infoArteFinal.name}`} classnames="display-c-c w-100">
          {' '}
          <div className="title-buttons-main-info-container w-100">
            {userRole === 'MasterAdmin' && (
              <DeleteIcon className="delete-icon-main-info tooltip-delete-icon" onClick={handleDeleteClick} />
            )}
            {userRole === 'MasterAdmin' && (
              <EditIcon className="edit-icon-main-info tooltip-edit-icon" onClick={handleClicEditArte} />
            )}
          </div>
        </TitleSection>

        {/* SECCIONA PARA LAS CATEGORIAS */}
        <CategorySection idArte={idArte} />

        <div className="main-info-section-container">
          <InfoArteFinalContainer>
            <InfoArteFinalTitle>Cluster</InfoArteFinalTitle>
            <InfoArteFinalText>
              {infoArteFinal?.centerType
                .sort((a, b) => a.index - b.index)
                .map((cluster) => cluster.type)
                .join(', ') || 'Sin clusters asociados'}
            </InfoArteFinalText>
          </InfoArteFinalContainer>
        </div>

        <InfoArteFinalContainer>
          <InfoArteFinalTitle>Favoritos</InfoArteFinalTitle>
          <InfoArteFinalText>
            {infoArteFinal?.favourite.map((favourite) => favourite.type).join(', ') || 'Sin favoritos asociados'}
          </InfoArteFinalText>
        </InfoArteFinalContainer>

        {!infoArteFinal?.isDescargable && (
          <div className="main-info-section-container">
            <InfoArteFinalContainer>
              <InfoArteFinalTitle>Regionalidad</InfoArteFinalTitle>
              <InfoArteFinalText>{infoArteFinal?.regional?.name || 'Sin regionalidad asociada'}</InfoArteFinalText>
            </InfoArteFinalContainer>
          </div>
        )}

        {!infoArteFinal?.isDescargable && (
          <div className="main-info-section-container">
            <InfoArteFinalContainer>
              <InfoArteFinalTitle>Cantidad mínima</InfoArteFinalTitle>
              <InfoArteFinalText> {infoArteFinal?.minQuantity || 1}</InfoArteFinalText>
            </InfoArteFinalContainer>
            <InfoArteFinalContainer>
              <InfoArteFinalTitle>Montaje</InfoArteFinalTitle>
              <InfoArteFinalText>{formatBoolean(infoArteFinal?.isMontaje)}</InfoArteFinalText>
            </InfoArteFinalContainer>
          </div>
        )}
        <div className="main-info-section-container">
          <InfoArteFinalContainer>
            <InfoArteFinalTitle>Imagen nueva</InfoArteFinalTitle>
            <InfoArteFinalText>{formatBoolean(infoArteFinal?.isImagenNueva)}</InfoArteFinalText>
          </InfoArteFinalContainer>
          <InfoArteFinalContainer>
            <InfoArteFinalTitle>Imagen anterior</InfoArteFinalTitle>
            <InfoArteFinalText>{formatBoolean(infoArteFinal?.isImagenAnterior)}</InfoArteFinalText>
          </InfoArteFinalContainer>
        </div>
        <div className="main-info-section-container">
          <InfoArteFinalContainer>
            <InfoArteFinalTitle>Activo</InfoArteFinalTitle>
            <InfoArteFinalText>{formatBoolean(infoArteFinal?.active)}</InfoArteFinalText>
          </InfoArteFinalContainer>
          <InfoArteFinalContainer>
            <InfoArteFinalTitle>Creado el</InfoArteFinalTitle>
            <InfoArteFinalText>{formatDate(infoArteFinal?.createdAt)}</InfoArteFinalText>
          </InfoArteFinalContainer>
        </div>
      </div>
      <DeletePopover
        handleDeleteConfirm={handleDeleteConfirm}
        handleDeleteClose={handleDeleteClose}
        deletePopover={deletePopover}
        textQuestion={'¿Estás seguro que quieres eliminar este arte final?'}
        textConfirm={'Sí'}
        textCancel={'No'}
      />
      <Tooltip anchorSelect=".tooltip-edit-icon" place="top">
        <span>Editar arte final</span>
      </Tooltip>
      <Tooltip anchorSelect=".tooltip-delete-icon" place="top">
        <span>Eliminar arte final</span>
      </Tooltip>
      <div className="main-info-image-container">
        <UploadImageArteFinal userRole={userRole} />
        <AddLinksToArteFinal userRole={userRole} />
        <CardMedia
          component="img"
          alt="ArteFinal Image"
          height="450"
          placeholder={<OneArteMasterSpinner />}
          image={infoArteFinal?.imglink || '/Image_not_available.png'}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/Image_not_available.png';
          }}
          className="image-arte-fina-container"
          style={{
            objectFit: 'contain',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            objectPosition: 'top',
            height: 'fit-content',
            maxHeight: '600px',
          }}
          onClick={openModalImgFullScreen}
        />
        <ButtonsImagesArteFinal png={infoArteFinal?.imglink} name={infoArteFinal?.name} />
      </div>

      <DialogWithImg
        isModalOpen={modalImgFullScreen}
        closeModal={closeModalImgFullScreen}
        imgFullScreen={infoArteFinal?.imglink}
        code={infoArteFinal?.code}
        name={infoArteFinal?.name}
      />
    </div>
  );
};
