import { useContext, useEffect } from 'react';
import { findLeafNodes, getBulkPropertiesAsync, viewer } from '../../helpers/viewerHelper';
import { PointContext } from '../../context/PointContext';
import { useLocation } from 'react-router-dom';
import SpritesHelpers from '../Points/SpritePointsFunction';

function getCenterBoundingBox(dbIds) {
  dbIds.forEach(function (item, index) {
    viewer.select([item.dbId]);
    const center = viewer.utilities.getBoundingBox().getCenter();
    item.center = center;
    // console.log(dbIds.length, index)
    if (dbIds.length == index + 1) {
      viewer.clearSelection();
    }
  });
  return dbIds;
}

const ButtonExtensionPoint = () => {
  const { setArePointsActive, setPoints, setSelectedPoint } = useContext(PointContext);
  let location = useLocation();
  console.log('PAAAATHHH', location.pathname);

  // const changeVisibilityMarkups = async (state) => {
  //   const dataViz = await viewer.getExtension('Autodesk.DataVisualization');
  //   console.log('')
  //   SpritesHelpers
  // };

  useEffect(() => {
    if (!location.pathname.includes('mercado')) {
      const toolbar = viewer.getToolbar(true);
      // Comprobar si el botón de cartelería ha sido creado.
      // Si ha sido creado, hacerlo invisible
      if (toolbar.getControl('customControlGroup')) {
        toolbar.getControl('customControlGroup').setVisible(false);
        SpritesHelpers.hideSprites();
        setArePointsActive(false);
      }
    } else {
      // Comprobar si ha sido creado el botón
      const toolbar = viewer.getToolbar(true);

      if (!toolbar.getControl('customControlGroup')) {
        // createButton();
        const myControlGroup = new window.Autodesk.Viewing.UI.ControlGroup('customControlGroup');

        const button = new window.Autodesk.Viewing.UI.Button('toolbar-markup');
        button.setIcon('adsk-icon-markup');
        button.setState(0);

        button.setToolTip('Cartelería');
        myControlGroup.addControl(button);
        toolbar.addControl(myControlGroup);
        button.onClick = async (ev) => {
          if (button.getState() === 0) {
            console.log('entra en este onClick');
            button.setState(1);
            setSelectedPoint(null);
            setArePointsActive(false);
            // changeVisibilityMarkups(false);
            SpritesHelpers.hideSprites();
          } else if (button.getState() === 1) {
            button.setState(0);
            setArePointsActive(true);
            SpritesHelpers.showSprites();
          }
        };
        // viewer.addEventListener(button.STATE_CHANGED, (e) => {
        //   console.log('button state changed', e);
        // });
      } else {
        const button = toolbar
          ?.getControl('customControlGroup')
          ?._controls.find((item) => item._id == 'toolbar-markup');
        toolbar.getControl('customControlGroup').setVisible(true);
        button.setState(0);
        SpritesHelpers.showSprites();
        setArePointsActive(true);

        // Activar botón
        // SpritesHelpers.showSprites();
      }
    }
  }, [location]);

  return;
};

export default ButtonExtensionPoint;
