import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { getAllCostesInstalacion } from '../api/carteleriaApi';

export const useGetAllCostesInstalacion = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['all-costes-instalacion'],
    queryFn: async () => {
      let token = await getAccessTokenSilently();
      return getAllCostesInstalacion(token);
    },
  });
};
