import { useContext, useState } from 'react';
import TitleSection from './ArteFinalInfo/TitleSection';
import { UserContext } from '../../../context/UserProvider';
import CustomButton from '../../../ui/Button/CustomButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useCreateFormatCustom } from '../../../hooks/useFormat';
import { FormatsSpecificationsByArte } from './FormatsSpecificationsByArte';
import FormProviderAsociate from '../Formatos/FormProviderAsociate';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Modal } from '@mui/material';

const ArteFinalNotDescargable = ({ customFormat }) => {
  const { idArte } = useParams();
  const { userRole } = useContext(UserContext);
  const { mutation } = useCreateFormatCustom(idArte);
  const navigate = useNavigate();
  const location = useLocation();
  const [openPopoverFormatCustom, setOpenPopoverFormatCustom] = useState(null);

  const handleClicFormatos = (e) => {
    const hasTrailingSlash = location.pathname.endsWith('/');

    if (!hasTrailingSlash) {
      navigate(`${location.pathname}/add-format`);
    } else {
      navigate(`${location.pathname}add-format`);
    }
  };

  const handleOpenPopoverFormatCustom = (e) => {
    setOpenPopoverFormatCustom(e.currentTarget);
  };

  const handleClosePopoverFormatCustom = () => {
    setOpenPopoverFormatCustom(null);
  };

  const handleConfirmFormatCustom = async (providerFormatValues) => {
    const body = {
      code: 'Personalizable',
      name: 'Personalizable',
      isCustom: true,
      arteFinal: idArte,
    };

    mutation.mutate({ bodyFormat: body, providerFormatValues });
    handleClosePopoverFormatCustom();
  };

  const validationSchemaProvider = yup.object().shape({
    code: yup.string().required('El código es obligatorio'),
    provider: yup.string().required('El proveedor es obligatorio'),
  });

  const formikProvider = useFormik({
    initialValues: {
      code: '',
      provider: null,
      format: null,
      arteFinal: idArte,
      price: 0,
      active: true,
    },
    validationSchema: validationSchemaProvider,
    onSubmit: async (values) => {
      handleConfirmFormatCustom(values);
    },
  });

  return (
    <>
      <div className="imprimibles-section">
        <TitleSection title="Formatos" classnames="display-sb-c w-100">
          {userRole === 'MasterAdmin' && (
            <div style={{ display: 'flex', gap: '3rem', height: '30px' }}>
              <CustomButton onClick={(e) => handleClicFormatos(e)} classname="custom-button-formato">
                <AddCircleOutlineIcon />
                Formato
              </CustomButton>

              {customFormat ? (
                <div>
                  <CustomButton classname="custom-button-formato button-tooltip-custom" disabled={true}>
                    <AddCircleOutlineIcon />
                    Formato Personalizado
                  </CustomButton>
                  <Tooltip anchorSelect=".button-tooltip-custom" place="top">
                    <span>No puedes añadir un formato personalizado porque ya hay uno</span>
                  </Tooltip>
                </div>
              ) : (
                <CustomButton classname="custom-button-formato" onClick={(e) => handleOpenPopoverFormatCustom(e)}>
                  <AddCircleOutlineIcon />
                  Formato Personalizado
                </CustomButton>
              )}
            </div>
          )}
        </TitleSection>
      </div>
      <FormatsSpecificationsByArte />
      <Modal open={openPopoverFormatCustom} onClose={handleClosePopoverFormatCustom}>
        <div className="modal-create-edit-format">
          <FormProviderAsociate goBack={handleClosePopoverFormatCustom} formik={formikProvider} custom={true} />
        </div>
      </Modal>
    </>
  );
};

export default ArteFinalNotDescargable;
