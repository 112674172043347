import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createMerchandising,
  deleteGuiaMerchandising,
  editFile,
  editGuiaMerchandising,
  getClustersByMerchandising,
  getFile,
  getFiles,
  getGuiaMerchandising,
  getGuiasMerchandising,
} from '../../api/filesApi';
import toast from 'react-hot-toast';
import { useHandleCloseModal } from '../../hooks/useHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../context/UserProvider';
import { getCenterByMerchandising } from '../../api/carteleriaApi';

export const useGetFiles = (suspense = false) => {
  return useQuery({
    queryKey: ['files'],
    queryFn: () => getFiles(),
    suspense,
  });
};

export const useGetFile = (id) => {
  return useQuery({
    queryKey: ['file', id],
    queryFn: () => getFile(id),
  });
};

export const useEditFile = () => {
  const queryClient = useQueryClient();
  const { handleCloseModal } = useHandleCloseModal(2);

  const mutation = useMutation({
    mutationFn: (data) => editFile(data),
    onSuccess: () => {
      queryClient.invalidateQueries('files');
      handleCloseModal();
      toast.success('Archivo editado correctamente', {
        id: 'edit-file-validate',
      });
    },
    onError: (err) => {
      toast.error(err.message, {
        id: 'edit-file-error',
      });
    },
  });

  return { mutation };
};

export const useGetGuiasMerchandising = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(['guias-merchandising'], async () => {
    const token = await getAccessTokenSilently();
    return getGuiasMerchandising(token);
  });
};

export const useGetGuiaMerchandising = (idFile) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(['guia-merchandising', idFile], async () => {
    const token = await getAccessTokenSilently();
    return getGuiaMerchandising(token, idFile);
  });
};

export const useEditGuiaMerchandising = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { handleCloseModal } = useHandleCloseModal(3);

  const mutation = useMutation({
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently();
      return editGuiaMerchandising(data, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('guias-merchandising');
      handleCloseModal();
      toast.success('Guía editada correctamente', {
        id: 'edit-merchandising',
      });
    },
    onError: (err) => {
      toast.error(err.message, {
        id: 'edit-merchandising-error',
      });
    },
  });

  return { mutation };
};

export const useDeleteGuiaMerchandising = (fileId) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const mutation = useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();
      return deleteGuiaMerchandising(fileId, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('guias-merchandising');
      toast.success('Guía eliminada correctamente', {
        id: 'delete-merchandising',
      });
    },
    onError: (err) => {
      if (err.message === 'error-delete-cluster-associated') {
        navigate(`${location.pathname}/${fileId}/error-delete-file`);
      }
    },
  });

  return { mutation };
};

export const useGetClustersByMerchandising = () => {
  const { idFile } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading } = useQuery({
    queryKey: ['clusters-by-merchandising', idFile],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getClustersByMerchandising(idFile, token);
    },
  });

  return { merchandisingName: data?.merchandisingName, clustersAsociated: data?.data, isLoading };
};

export const useCreateMerchandising = () => {
  const { handleCloseModal } = useHandleCloseModal(1);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0(); // Obtener el token

  const mutation = useMutation({
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently(); // Obtener el token antes de la llamada
      return createMerchandising(data, token); // Pasar el token a la función
    },
    onSuccess: () => {
      handleCloseModal();
      queryClient.invalidateQueries('guias-merchandising'); // Invalidar caché después de éxito
      toast.success('Guía creada correctamente', {
        id: 'create-merchandising',
      });
    },
    onError: (err) => {
      toast.error(err.message, {
        id: 'create-merchandising-error',
      });
    },
  });

  return { mutation };
};

export const useGetMerchandisingLink = () => {
  const { centerConfirmed } = useContext(UserContext);
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['merchandising-link', centerConfirmed._id],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getCenterByMerchandising(token, centerConfirmed._id);
    },
  });
};
