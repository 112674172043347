import React from 'react';
import { TextField, IconButton } from '@mui/material';
import { IoMdAddCircleOutline } from 'react-icons/io';

const CategoryForm = ({ newCategory, handleChange, handleAdd }) => (
  <div className="subcategory-form-container">
    <TextField
      value={newCategory}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleAdd();
        }
      }}
      onChange={handleChange}
      label="Nombre"
      variant="outlined"
      fullWidth
    />
    <IconButton onClick={handleAdd}>
      <IoMdAddCircleOutline />
    </IconButton>
  </div>
);

export default CategoryForm;
