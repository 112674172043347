import { IconButton } from '@mui/material';
import { GrDocumentText } from 'react-icons/gr';
import { IoIosLink } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';

const FileCard = ({ file }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleEditFile = () => {
    navigate(`${location.pathname}/edit/${file._id}`);
  };

  const handleGoToFile = () => {
    window.open(file.url, '_blank');
  };
  return (
    <div className="files-card-container files-card-container__file">
      <div className="file-card-icons-container">
        <div onClick={handleGoToFile} className="files-card-icon-container files-card-icon-container__file">
          <GrDocumentText className="file-card-icon" />
        </div>
        <IconButton onClick={handleEditFile}>
          <IoIosLink size="20px" />
        </IconButton>
      </div>
      <h3>{file.name}</h3>
    </div>
  );
};

export default FileCard;
