export const handleSelectCenterType = (e, formik, allCenterTypes) => {
  const value = e.target.value;

  if (value.includes('all')) {
    if (formik.values.centerType.length === allCenterTypes.length) {
      formik.setFieldValue('centerType', []);
    } else {
      const allIds = allCenterTypes.map((centerType) => centerType._id);
      formik.setFieldValue('centerType', allIds);
    }
  } else {
    const orderedValues = allCenterTypes
      .filter((centerType) => value.includes(centerType._id))
      .map((centerType) => centerType._id);

    console.log(orderedValues);

    formik.setFieldValue('centerType', orderedValues);
  }
};

export const handleSelectFavouriteCenterType = (e, formik) => {
  const value = e.target.value;

  console.log(value);

  if (value.includes('all')) {
    if (formik.values.favourite.length === formik.values.centerType.length) {
      // Deselect all
      formik.setFieldValue('favourite', []);
    } else {
      // Select all
      const allIds = formik.values.centerType.map((centerType) => centerType);
      formik.setFieldValue('favourite', allIds);
    }
  } else {
    formik.setFieldValue('favourite', value);
  }
};
