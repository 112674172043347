import React from "react"
import ContentLoader from "react-content-loader"

const OneArteMasterSpinner = (props) => (
  <ContentLoader 
    speed={2}
    width={700}
    height={450}
    viewBox="0 0 700 450"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="250" y="9" rx="0" ry="8" width="539" height="475" />
  </ContentLoader>
)

export default OneArteMasterSpinner