import { useContext, useMemo } from 'react';
import { ShoppingCartContext } from '../context/ShoppingCartContext';
import { useMutation } from '@tanstack/react-query';
import { postOrder } from '../helpers/Cart/cartHelper';
import { ArteFinalContext } from '../context/ArteFinalContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export const useCartItemQuantity = (item, formato, required, children) => {
  const { shoppingCart_v2, setShoppingCart_v2 } = useContext(ShoppingCartContext);

  const handleOnClick = (value) => {
    let copyShoppingCart = { ...shoppingCart_v2 };

    // lo primero es chequear si el arte final es un compuesto
    if (shoppingCart_v2[item.id]?.packArteFinal && !children) {
      const packArteFinal = shoppingCart_v2[item.id]?.packArteFinal;
      const packFormat = shoppingCart_v2[item.id]?.formatos[formato.formatoId]?.packFormat;

      let newShoppingCart = { ...shoppingCart_v2 };

      Object.keys(newShoppingCart).forEach((key) => {
        if (newShoppingCart[key]?.packArteFinal === packArteFinal) {
          newShoppingCart[key].formatos = Object.keys(newShoppingCart[key].formatos).reduce((acc, keyFormat) => {
            if (newShoppingCart[key].formatos[keyFormat].packFormat === packFormat) {
              const format = newShoppingCart[key].formatos[keyFormat];

              if (format?.childrenFormat || required) {
                const originalQuantity = format?.originalQuantity || 0;
                const currentQuantity = format?.quantity || 0;
                const minQuantity = format?.minQuantity || 1;

                const newQuantity = Math.max(
                  currentQuantity + (originalQuantity && required ? value * originalQuantity : value),
                  originalQuantity > minQuantity ? originalQuantity : minQuantity,
                );

                const prevFormato = newShoppingCart[key]?.formatos?.[keyFormat] || {};

                if (newQuantity === 0) {
                  const { [keyFormat]: removedFormat, ...newFormatos } = newShoppingCart[key].formatos;

                  if (Object.keys(newFormatos).length === 0) {
                    delete newShoppingCart[key];
                  } else {
                    newShoppingCart[key].formatos = newFormatos;
                  }
                } else {
                  newShoppingCart[key].formatos[keyFormat] = {
                    ...prevFormato,
                    quantity: newQuantity,
                  };
                }
              }
            }
            return newShoppingCart[key]?.formatos || {};
          }, {});
        }
      });

      setShoppingCart_v2(newShoppingCart);
    } else {
      if (shoppingCart_v2[item.id]?.formatos?.[formato.formatoId]?.quantity) {
        const newQuantity = Math.max(
          shoppingCart_v2[item.id].formatos[formato.formatoId].quantity + value,
          // la cantidad minima del formato
          shoppingCart_v2[item.id].formatos[formato.formatoId].minQuantity || 1,
        );

        // Si la cantidad es 0, eliminar del carrito. Si solo hay un formato, eliminar también el arte final
        if (newQuantity === 0) {
          // Si solo hay un formato, eliminar también el arte final
          if (Object.keys(copyShoppingCart[item.id].formatos).length === 1) {
            delete copyShoppingCart[item.id];
            setShoppingCart_v2(copyShoppingCart);
            return;
          }

          delete copyShoppingCart[item.id].formatos[formato.formatoId];
          setShoppingCart_v2(copyShoppingCart);
          return;
        }

        copyShoppingCart[item.id].formatos[formato.formatoId].quantity = newQuantity;
        setShoppingCart_v2(copyShoppingCart);
      }
    }
  };

  const handleRemove = () => {
    let copyShoppingCart = { ...shoppingCart_v2 };

    // SI ES COMPUESTO ELIMINAR TODOS LOS FORMATOS ASOCIADOS
    if (shoppingCart_v2[item.id]?.packArteFinal) {
      const packArteFinal = shoppingCart_v2[item.id]?.packArteFinal;
      const packFormat = shoppingCart_v2[item.id]?.formatos[formato.formatoId]?.packFormat;

      let newShoppingCart = { ...shoppingCart_v2 };

      Object.keys(newShoppingCart).forEach((key) => {
        if (newShoppingCart[key]?.packArteFinal === packArteFinal) {
          newShoppingCart[key].formatos = Object.keys(newShoppingCart[key].formatos).reduce((acc, keyFormat) => {
            if (newShoppingCart[key].formatos[keyFormat].packFormat === packFormat) {
              const { [keyFormat]: removedFormat, ...newFormatos } = newShoppingCart[key].formatos;

              if (Object.keys(newFormatos).length === 0) {
                delete newShoppingCart[key];
              } else {
                newShoppingCart[key].formatos = newFormatos;
              }
            }
            return newShoppingCart[key]?.formatos || {};
          }, {});
        }
      });

      setShoppingCart_v2(newShoppingCart);
      return;
    } else {
      // Si solo hay un formato, eliminar también el arte final
      if (Object.keys(copyShoppingCart[item.id].formatos).length === 1) {
        delete copyShoppingCart[item.id];
        setShoppingCart_v2(copyShoppingCart);
        return;
      }
      delete copyShoppingCart[item.id].formatos[formato.formatoId];
      setShoppingCart_v2(copyShoppingCart);
    }
  };

  return {
    handleOnClick,
    handleRemove,
  };
};

export const useCartUtils = () => {
  const { shoppingCart_v2, setShoppingCart_v2 } = useContext(ShoppingCartContext);

  const handleRemovePack = (idPack) => {
    const newShoppingCart_v2 = { ...shoppingCart_v2 };
    // recorremos todos los artes y los que tenga de packArteFinal el idPack los borramos del shoppingCart
    for (const key in newShoppingCart_v2) {
      if (newShoppingCart_v2[key].packArteFinal === idPack) {
        delete newShoppingCart_v2[key];
      }
    }
    setShoppingCart_v2(newShoppingCart_v2);
  };

  return {
    handleRemovePack,
  };
};

export const usePostOrder = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();
  const { shoppingCart_v2, setShoppingCart_v2, imagesShoppingCart } = useContext(ShoppingCartContext);
  const { centerConfirmed } = useContext(ArteFinalContext);

  const mutation = useMutation({
    mutationFn: async (params) => {
      let token = await getAccessTokenSilently();
      postOrder({
        shoppingCart: shoppingCart_v2,
        imagesShoppingCart,
        token,
        user,
        centerConfirmed,
        isMontaje: params.isMontaje,
        nameContactShoppingCart: params.nameContactShoppingCart,
        phoneContactShoppingCart: params.phoneContactShoppingCart,
        extension: params.extension,
        costesAsignados: params.costesAsignados,
      });
    },
    onSuccess: () => {
      setShoppingCart_v2({});
      navigate('/carteleria/orders');
      toast.success('Solicitud enviada correctamente', { duration: 2000, position: 'top-center' });
    },
    onError: (error) => {
      console.error('Error al enviar la solicitud', error);
      toast.error('Error al enviar la solicitud', { duration: 2000, position: 'top-center' });
    },
  });

  return { mutation };
};

export const useGetTotalByItem = (item) => {
  const { shoppingCart_v2 } = useContext(ShoppingCartContext);

  const totalByItem = useMemo(() => {
    return Object.values(item?.formatos).reduce((total, el) => {
      // Verifica si el formatoCustom no está presente o es falso

      if (el?.childrenFormat?.format?.length > 0 && el?.totalChildren !== el?.childrenFormat?.format?.length) {
        return (
          total +
          el.childrenFormat.format.reduce(
            (acc, curr) => acc + curr.formatoPrice * Math.max(el.quantity, el.minQuantity),
            0,
          )
        );
      }
      if (!el.formatoCustom) {
        return total + el.price * Math.max(el.quantity, item.minQuantity);
      }
      // Si es formatoCustom, devuelve el total sin cambios
      return total;
    }, 0);
  }, [item, shoppingCart_v2]);

  return {
    totalByItem,
  };
};
