const SelectTypeFile = ({ options, optionSelected, onClick }) => {
  return (
    <div className={'two-options-select-wrapper justify-center'}>
      {options.map((option, index) => (
        <div key={index} className={`${optionSelected !== option ? 'padding-1' : ''}`}>
          <button
            key={index}
            type="button"
            className={`option-select ${optionSelected === option ? 'optionselected' : ''}`}
            onClick={() => onClick(option)}
          >
            {option}
          </button>
        </div>
      ))}
    </div>
  );
};

export default SelectTypeFile;
