import { Card } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Buscador } from '../../Pedido/Buscador';
import { useAuth0 } from '@auth0/auth0-react';
import ContainerDb from './ContainerDb';
import { useQuery } from '@tanstack/react-query';
import { getAllTiendasPedidoPilotado } from '../../../api/carteleriaApi';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import ContainerSelected from './ContainerSelected';
import { handleChangeInputSearch, handleClearInput, handleKeyDown } from '../../../helpers/buscadorHelper';
import { PilotadosContext } from '../../../context/PilotadosContext';

const SelectTienda = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { tiendaSelected, setTiendaSelected } = useContext(PilotadosContext);

  const [valueSearch, setValueSearch] = useState('');
  const [valueSearchAfter, setValueSearchAfter] = useState(null);
  const [tiendaArray, setTiendaArray] = useState([]);
  const [page, setPage] = useState(1);

  // TRAER TODAS LAS TIENDAS JUNTO A SUS CLUSTERS
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['all-tiendas-pedido-pilotado', page, valueSearchAfter],
    async () => {
      const token = await getAccessTokenSilently();
      return await getAllTiendasPedidoPilotado(token, valueSearchAfter, page);
    },
  );

  useEffect(() => {
    setTiendaArray([]);
    if (page !== 1) {
      setPage(1);
    }
  }, [valueSearchAfter]);

  useEffect(() => {
    if (isFetching) return;
    if (tiendaSelected.length > 0 && data?.data?.length > 0) {
      tiendaSelected.forEach((cluster) => {
        cluster.centers.forEach((tienda) => {
          const clusterIndex = data.data.findIndex((c) => c._id._id === cluster._id._id);

          if (clusterIndex === -1) return;
          const tiendaIndex = data.data[clusterIndex].centers.findIndex((t) => t._id === tienda._id);
          if (tiendaIndex === -1) return;
          data.data[clusterIndex].centers[tiendaIndex].selected = true;
        });
      });
    }
    setTiendaArray((prev) => [...prev, ...data.data]);
  }, [isFetching]);

  return (
    <div>
      <h1>2. Selecciona los centros</h1>
      <Card style={{ padding: '1rem' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <Buscador
            label="Buscar por código, nombre o calle"
            value={valueSearch}
            onChange={(e) => handleChangeInputSearch(e, setValueSearch)}
            onKeyDown={(e) => handleKeyDown(e, setValueSearchAfter)}
            onClear={() => handleClearInput(setValueSearch, setValueSearchAfter)}
            width="25rem"
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ContainerDb
            isLoading={isLoading}
            setDataArray={setTiendaArray}
            dataArray={tiendaArray}
            dataSelected={tiendaSelected}
            data={data}
            isFetching={isFetching}
            setDataSelected={setTiendaSelected}
            setPage={setPage}
            tienda={true}
          />
          {/* BOTONES SIN FUNCIONALIDAD PARA INDICAR QUE SON LISTAS QUE SE PUEDE TRANSFERIR LA DATA */}
          <div
            style={{
              height: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <WestIcon />
            <EastIcon />
          </div>
          {/* CONTENEDOR CON TODOS LOS ARTES SELECCIONADOS */}
          <ContainerSelected
            setDataArray={setTiendaArray}
            dataArray={tiendaArray}
            dataSelected={tiendaSelected}
            setDataSelected={setTiendaSelected}
            tienda
          />
        </div>
      </Card>
    </div>
  );
};

export default SelectTienda;
