export const handleConvert = (imageUrl) => {
  return new Promise((resolve, reject) => {
    // Crear un nuevo objeto Image
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Permite cargar imágenes de dominios diferentes
    img.src = imageUrl;

    // Esperar a que la imagen cargue
    img.onload = () => {
      // Crear un canvas y dibujar la imagen en él
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      // Dibujar un fondo blanco
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Dibujar la imagen encima del fondo blanco

      ctx.drawImage(img, 0, 0);

      // Convertir el canvas a un Blob
      canvas.toBlob((blob) => {
        if (blob) {
          // Convertir el Blob a un File si es necesario
          const file = new File([blob], 'image.jpg', { type: blob.type });
          // Resolver la promesa con el File
          resolve(file);
        } else {
          reject('Error al generar el blob');
        }
      }, 'image/jpeg'); // Puedes cambiar el tipo MIME si es necesario
    };

    img.onerror = (error) => {
      reject('Error al cargar la imagen:', error);
    };
  });
};
export const blobToDataURL = (blob, callback) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.onerror = function () {
      reject(new Error('Error al leer el blob'));
    };
    reader.readAsDataURL(blob);
  });
};
