import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
// import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
// import 'react-image-lightbox/style.css';
// import 'video.js/dist/video-js.css';
import './assets/css/sass/themes/gogo.light.greysteel.scss';
import './styles/_index.css'
import reportWebVitals from './reportWebVitals';
import { Auth0ProviderWithConfig } from './auth0-config/auth0-provider-with-config';

 if (process.env.REACT_APP_NODE_ENV != 'development') {
   console.log = () => {};
   console.debug = () => {};
   console.info = () => {};
   console.warn = () => {};
  //  console.error = () => {};
 }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0ProviderWithConfig>
    <App />
  </Auth0ProviderWithConfig>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
