import FormatAssociated from './FormatAssociated';

const ContainerSelected = ({ required, newFormat, aaffSelected, setAaffSelected, aaffArray, setAaffArray }) => {
  return (
    <div style={{ border: '1px solid rgba(0,0,0,0.2)', overflowY: 'auto', height: '300px' }}>
      {!newFormat || aaffSelected?.length > 0 ? (
        aaffSelected.map((data, index) =>
          data.formats.map((format, indexFormat) => (
            <FormatAssociated
              key={index + '_' + indexFormat}
              required={required}
              aaffArray={aaffArray}
              setAaffArray={setAaffArray}
              aaffSelected={aaffSelected}
              setAaffSelected={setAaffSelected}
              data={data}
              format={format}
            />
          )),
        )
      ) : (
        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          No hay formatos asociados
        </span>
      )}
    </div>
  );
};

export default ContainerSelected;
