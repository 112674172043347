import { getAllCenterTypes } from '../../../api/carteleriaApi';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';

export function useGetAllCenterTypes() {
  const { getAccessTokenSilently } = useAuth0();

  const { data: allCenterTypes, isLoading: isLoadingCTypes } = useQuery(['allCenterTypes'], async () => {
    const token = await getAccessTokenSilently();
    return await getAllCenterTypes(token, { sort: 'index' });
  });

  return { allCenterTypes, isLoadingCTypes };
}
