import { useState, useEffect, useContext } from 'react';
import { Box, Drawer, Popper } from '@mui/material';
import { getProyectoByItem, getRole } from '../../api/alcampoApi';
import { getAllProjects } from '../../api/alcampoApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { HomeWorkOutlined, ExpandMore, ExpandLess } from '@mui/icons-material';
import { PopoverAccount } from './PopoverAccount';
import { UserContext } from '../../context/UserProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { toggleDrawer, toggleTiendaModal } from '../../helpers/navbarHelper';
import { ResumenPedido } from '../../ComponentsCarteleria/Pedido/ResumenPedido';
import { CenterSelected } from '../../ComponentsCarteleria/Pedido/CenterSelected';
import NavbarUser from './NavbarUser';
import ResumenPedido_v2 from '../../ComponentsCarteleria/Pedido/ResumenPedido_v2';

const NavbarAlcampo = ({ user }) => {
  const { userRole, centerConfirmed, autocompleteValue, setCenterConfirmed, setAutocompleteValue } =
    useContext(UserContext);
  const [projectList, setProjectList] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const { idItem } = useParams();
  const { getAccessTokenSilently, user: test } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTiendaModalOpen, setIsTiendaModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { data: userAccordingToAuth0 } = useQuery(['rolUserLogueado'], () => getRole(user), {
    enabled: !!user,
  });
  const handleClick = (event) => {
    console.log('le has dado a tema de cuentas');
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  //Cambiar por context cuando se compruebe toda la info
  //TODO: Cambiar esto al contexto de los proyectos
  useEffect(() => {
    if (!location.pathname.includes('/viewer/')) return;
    getAccessTokenSilently().then((token) => {
      getAllProjects(token)
        .then((res) => {
          setProjectList(res.data.data);
        })
        .catch((e) => console.log('e', e));
    });
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // console.log('DONDEEE ESTASSSSS', location);
    if (idItem) {
      getAccessTokenSilently().then((token) => {
        getProyectoByItem(idItem, token)
          .then((res) => {
            setSelectedProject(res);
          })
          .catch((e) => console.log('e', e));
      });
    }
  }, [location]);

  const goToProject = (idProject) => {
    handleClick();
    navigate(`/viewer/${idProject}`);
  };

  const handleGoToAdminOrMaster = () => {
    if (!userAccordingToAuth0) return;
    if (userAccordingToAuth0 == 'admin-signage') {
      navigate('/admin');
    } else if (userAccordingToAuth0 == 'MasterAdmin') {
      navigate('/master');
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <nav className="navbar">
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              paddingLeft: '1rem',
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={() => navigate('/')}
          >
            <img src="https://alcampo.fra1.digitaloceanspaces.com/recursos/Logotipo_alcampo1.png" height="65px" />
          </div>
          {
            // searchParams.get('item') &&
            location.pathname.includes('/viewer/') && (
              <div>
                <div
                  aria-describedby={id}
                  type="button"
                  onClick={handleClick}
                  style={{ border: '1px solid black', padding: '5px 10px', marginLeft: '50px' }}
                >
                  <HomeWorkOutlined style={{ margin: '0 8px' }} /> {selectedProject && selectedProject[0].name}
                  {open ? <ExpandLess /> : <ExpandMore />}
                </div>
                <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 999999999999999999 }}>
                  <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', padding: '0' }}>
                    {projectList &&
                      projectList.map((project, index) => {
                        return (
                          <div
                            onClick={() => goToProject(project.item)}
                            style={{
                              // border: '1px black solid',
                              cursor: 'pointer',
                              padding: '5px 10px',
                              display: 'flex',
                            }}
                          >
                            <HomeWorkOutlined style={{ margin: '0 8px' }} />{' '}
                            <p style={{ margin: '0' }}> {project.name}</p>
                          </div>
                        );
                      })}
                  </Box>
                </Popper>
              </div>
            )
          }
        </div>

        <div
          style={{
            display: 'flex',
            marginRight: '2%',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            flexDirection: 'row',
          }}
        >
          {/* BOTONES PARA EL CARRITO Y LA TIENDA */}
          {userRole === 'user-signage' && centerConfirmed && (
            <NavbarUser
              setIsTiendaModalOpen={setIsTiendaModalOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              centerConfirmed={centerConfirmed}
            />
          )}
          <PopoverAccount user={user} />
        </div>
        {/* {<LogoutButton />} */}
      </nav>
      {userRole === 'user-signage' && (
        <>
          <Drawer anchor="right" open={isDrawerOpen} onClose={() => toggleDrawer(setIsDrawerOpen)}>
            <div style={{ width: '550px' }}>
              <ResumenPedido_v2 toggleDrawer={() => toggleDrawer(setIsDrawerOpen)} />

              {/* <ResumenPedido toggleDrawer={() => toggleDrawer(setIsDrawerOpen)} /> */}
            </div>
          </Drawer>
          <CenterSelected
            autocompleteValue={autocompleteValue}
            centerConfirmed={centerConfirmed}
            setCenterConfirmed={setCenterConfirmed}
            setAutocompleteValue={setAutocompleteValue}
            isTiendaModalOpen={isTiendaModalOpen}
            toggleTiendaModal={() => toggleTiendaModal(setIsTiendaModalOpen)}
          />
        </>
      )}
    </>
  );
};

export default NavbarAlcampo;
