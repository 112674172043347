import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedMain = ({ component }) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  if (isLoading) {
    return <div className="loadings" />;
  }
  if (!isAuthenticated) {
    loginWithRedirect();
  } else {
    return component;
  }
};

export default ProtectedMain;
