import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

export const Piechart = ({ data, color, detail }) => {
  const [colors, setColors] = useState(color);
  const [details, setDetails] = useState(detail);
  const [outerRadius, setouterRadius] = useState(90);
  const [height, setHeight] = useState(500);
  const [width, setWidth] = useState(500);

  useEffect(() => {
    details ? setouterRadius(210) : setouterRadius(60);
    details ? setHeight(700) : setHeight(220);
    details ? setWidth(950) : setWidth(300);
  }, [details]);

  useEffect(() => {
    console.log(data);
    color.map((item) => {
      console.log(item);
    });
  }, []);

  const isDataEmpty = !data || data.length === 0;

  return (
    <>
      {isDataEmpty ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%', height: '210px' }}
        >
          <p>No hay datos seleccionados</p>
        </div>
      ) : (
        <PieChart width={width} height={height}>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={outerRadius}
            fill="#82ca9d"
            label={({ name, value, percent }) => `${name}  (${(percent * 100).toFixed(2)}%)`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={color[index]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      )}
    </>
  );
};
