import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormControl } from '@mui/material';
import { useContext } from 'react';
import { Input, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { PartidasContext } from '../../context/PartidasContext';
import { Colxx } from '../../components/common/CustomBootstrap';

const PriceFilter = () => {
  const { setPriceFilter, priceFilter, resetPriceFilter, setCurrentPage, setPartidaQuery, partidaQuery } =
    useContext(PartidasContext);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [minPrice, setMinPrice] = useState(priceFilter[0]);
  const [maxPrice, setMaxPrice] = useState(priceFilter[1]);
  const location = useLocation();

  useEffect(() => {
    handleResetPriceFilter();
  }, [location.pathname]);

  useEffect(() => {
    setPriceFilter([minPrice * 1, maxPrice * 1]);
  }, [minPrice, maxPrice]);

  const handleResetPriceFilter = async () => {
    // setMinPrice(limitsPriceFilter[0]);
    // setMaxPrice(limitsPriceFilter[1]);
    setIsActiveFilter(false);
    resetPriceFilter();
    // const data = await runQuery(currentProject._id, { limit, page: 1 });
    // dispatch(setCurrentPartidas(data));
    // setCurrentLevel(null);
  };

  const handleChangeFilterPrice = async () => {
    if (!minPrice) {
      setMinPrice(priceFilter[0]);
    }
    if (!maxPrice) {
      setMaxPrice(priceFilter[1]);
    }
    setIsActiveFilter(false);
    // const modulesCheckedIds = modulesFilter.map((modulo) => modulo._id);
    // aqui añadimo a partida query el filtro de precio
    setPartidaQuery({
      ...partidaQuery,
      min: minPrice,
      max: maxPrice,
    });
    setCurrentPage(1);
  };

  const handleChangeMinPrice = (value) => {
    setMinPrice(value.replace(/,/g, '.'));
  };

  const handleChangeMaxPrice = (value) => {
    setMaxPrice(value.replace(/,/g, '.'));
  };

  return (
    <FormControl size="small" sx={{ width: '100%' }}>
      <Row
        id="container"
        style={{ display: 'flex', alignItems: 'center' }}
        className={`${isActiveFilter && 'container-filter-price-partidas'}`}
      >
        <Colxx xxs={isActiveFilter ? '4' : '5'} className="p-0">
          <Input
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                {
                  minPrice == 0 && maxPrice == 0 ? handleResetPriceFilter() : handleChangeFilterPrice();
                }
              }
            }}
            value={minPrice}
            onChange={(e) => handleChangeMinPrice(e.target.value)}
            className="input-minmax-filter-price-partidas"
            onFocus={() => setIsActiveFilter(true)}
            placeholder="Min"
          />
        </Colxx>
        <Colxx style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="p-0" xxs="1">
          {' - '}
        </Colxx>
        <Colxx xxs={isActiveFilter ? '4' : '5'} className="p-0">
          <Input
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                {
                  minPrice == 0 && maxPrice == 0 ? handleResetPriceFilter() : handleChangeFilterPrice();
                }
              }
            }}
            value={maxPrice}
            onChange={(e) => handleChangeMaxPrice(e.target.value)}
            className="input-minmax-filter-price-partidas"
            onFocus={() => setIsActiveFilter(true)}
            placeholder="Max"
          />
        </Colxx>
        {isActiveFilter && (
          <Colxx xxs="3" className="pr-0">
            <button
              className="button-ok-filter-price-partidas"
              onClick={minPrice == 0 && maxPrice == 0 ? handleResetPriceFilter : handleChangeFilterPrice}
            >
              OK
            </button>
          </Colxx>
        )}
      </Row>
    </FormControl>
  );
};

export default PriceFilter;
