import { Card } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { Tooltip } from 'react-tooltip';
import EuroIcon from '@mui/icons-material/Euro';
import { FaDropbox } from 'react-icons/fa';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { ProyectoContext } from '../../context/ProyectoContext';
import { useHandleUrl } from '../../hooks/useHandleUrl';

const CardPartidaProject = ({ partida }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { version } = useContext(ProyectoContext);
  const { changeLocation } = useHandleUrl();

  const handleNavigateToPartida = () => {
    navigate(`${location.pathname}/partida/${partida._id}`);
  };

  return (
    <>
      <NavLink to={changeLocation(`${location.pathname}/partida/${partida._id}`)}>
        <Card
          className="animate-card"
          style={{
            height: location.search !== '?version=19' && version !== undefined ? '165px' : '176px',
            padding: '1rem',
            marginBottom: '1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          // onClick={handleNavigateToPartida}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '.6rem' }}>
            <p
              className={`my-tooltip-code-name-partida-project-${partida._id}`}
              style={{
                whiteSpace: 'unset',
                WebkitLineClamp: '2',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                cursor: 'pointer',
                margin: '0',
              }}
              data-tooltip-content={`${partida?.partida?.code} - ${partida.partida.name}`}
              data-tooltip-place="top"
            >
              {partida?.partida?.code} - {partida.partida.name}
            </p>
            <div className="card-partida-item__price" style={{ display: 'flex', alignItems: 'center', gap: '.2rem' }}>
              <p className="m-0 card-partida-item__text-price">{partida.price}</p>{' '}
              <p className="m-0 card-partida-item__text-price">€</p>
            </div>
          </div>
          <p
            className={`my-tooltip-description-partida-project-${partida?._id}`}
            style={{
              whiteSpace: 'unset',
              WebkitLineClamp: '2',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              cursor: 'pointer',
              fontSize: '12px',
              opacity: '0.7',
            }}
            data-tooltip-content={partida?.partida?.description}
            data-tooltip-place="top"
          >
            {partida.partida.description}
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '.4rem' }}>
              <FaDropbox style={{ fontSize: '16px', opacity: '0.8' }} />
              <p
                style={{
                  paddingTop: '.15rem',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: '600',
                }}
                className="mb-0"
              >
                {partida?.quantity?.quantity?.toLocaleString('de-DE', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                })}
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '.4rem' }}>
              <EuroIcon style={{ fontSize: '16px', opacity: '0.8' }} />
              <p
                style={{
                  paddingTop: '.15rem',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: '600',
                }}
                className="mb-0"
              >
                {(partida?.price * partida?.quantity?.quantity).toLocaleString('de-DE', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>
        </Card>
      </NavLink>
      {/* <Tooltip
        anchorSelect={`.my-tooltip-description-partida-project-${partida._id}`}
        type="dark"
        effect="solid"
        style={{ maxWidth: '500px', maxHeight: '150px', overflow: 'auto', zIndex: 9999999999 }}
      >
        {partida.partida.description}
      </Tooltip> */}
      <Tooltip
        anchorSelect={`.my-tooltip-code-name-partida-project-${partida._id}`}
        type="dark"
        effect="solid"
        style={{ maxWidth: '500px', zIndex: 9999999999 }}
      >
        {`${partida?.partida?.code} - ${partida.partida?.name}`}
      </Tooltip>
    </>
  );
};

export default CardPartidaProject;
