import React, { useContext } from 'react';
import { Buscador } from '../../Pedido/Buscador';
import { PilotadosContext } from '../../../context/PilotadosContext';
import { handleChangeInputSearch, handleClearInput, handleKeyDown } from '../../../helpers/buscadorHelper';

const SearchCenterPilotado = () => {
  const { valueCenterSearch, setValueCenterSearch, setValueCenterSearchAfter } = useContext(PilotadosContext);

  return (
    <Buscador
      value={valueCenterSearch}
      width="30rem"
      onKeyDown={(e) => handleKeyDown(e, setValueCenterSearchAfter)}
      onClear={() => handleClearInput(setValueCenterSearchAfter, setValueCenterSearch)}
      onChange={(e) => handleChangeInputSearch(e, setValueCenterSearch)}
      label="Buscar por nombre, calle o código de centro"
    />
  );
};

export default SearchCenterPilotado;
