import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCodesByCapitulo } from '../api/alcampoApi';
import Viewer from '../helpers/Viewer/Viewer';
import { ProyectoContext } from './ProyectoContext';
import { useAuth0 } from "@auth0/auth0-react";

export const CapitulosContext = createContext();

export const CapitulosProvider = ({ children }) => {
    const [header, setHeader] = useState([]);
    const [capituloSelected, setCapituloSelected] = useState({});
    const [codesActive, setCodesActive] = useState([]);
    const { currentProject, isModelLoaded } = useContext(ProyectoContext)
    const { getAccessTokenSilently } = useAuth0();




    useEffect(() => {

        const getCodes = async () => {
            let token = await getAccessTokenSilently();
            const { codes } = await getCodesByCapitulo(capituloSelected?._id, token);
            setCodesActive(codes);
        }

        if (capituloSelected?._id) {
            getCodes();
        } else {
            setCodesActive([]);
        }
    }, [capituloSelected]);

    useEffect(() => {
        if (!isModelLoaded) return;
        const viewerClass = new Viewer(currentProject?._id);
        viewerClass.getDbIdsByCodes(codesActive);
    }, [codesActive, isModelLoaded]);

    const data = {
        header,
        setHeader,
        capituloSelected,
        setCapituloSelected,
        codesActive,
        setCodesActive,
    };

    return <CapitulosContext.Provider value={data}>{children}</CapitulosContext.Provider>;
};
