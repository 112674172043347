import React, { useContext, useEffect, useState } from 'react';
import { useTable } from 'react-table';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { PilotadosContext } from '../../../../context/PilotadosContext';

const AsociateTable = () => {
  const { tiendaSelected, aaffSelected, asociateData, setAsociateData } = useContext(PilotadosContext);

  const [centerTypesSelected, setCenterTypesSelected] = useState([]);
  const [formatSelected, setFormatSelected] = useState(null);
  const [formatoSelectedAfterClick, setFormatoSelectedAfterClick] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setCenterTypesSelected(
      tiendaSelected.map((cluster) => {
        return {
          centerType: cluster._id.type,
          centerId: cluster._id._id,
        };
      }),
    );
  }, [tiendaSelected]);

  const [columns, setColumns] = useState([]);

  const [quantitySelected, setQuantitySelected] = useState(0);

  useEffect(() => {
    // vamos a recorrer primero los centros para añadir CENTROS como header y todos sus respectivos centros
    if (asociateData.length > 0) return;
    let newData = [];
    tiendaSelected.forEach((cluster) => {
      cluster.centers.forEach((center) => {
        let row = {
          center:
            center._id + '%' + center.code + ' - ' + (center?.name || center.calle) + ` ( ${cluster?._id?.type} )`,
          centerType: cluster?._id?._id,
        };
        aaffSelected.forEach((aaff) => {
          aaff.formats.forEach((format) => {
            row[`${format._id}`] = 0;
          });
        });
        newData.push(row);
      });
    });
    setAsociateData(newData);
  }, []);

  useEffect(() => {
    let newColumns = [
      {
        accessor: 'center',
        Header: 'Centro',
      },
    ];

    const columnsArtes = aaffSelected.map((aaff) =>
      // POR CADA AAFF RECORREMOS LOS FORMATOS:
      aaff.formats.map((format) => ({
        accessor: `${format._id}`,
        Header: `${aaff.code} - ${aaff.name}%${format.code} - ${format.name}%${format.price.toFixed(2)}`,
      })),
    );

    newColumns.push(...columnsArtes.flat());
    setColumns(newColumns);
  }, []);

  // AQUI VAMOS A GESTIONAR TODAS LAS COLUMNAS QUE VA A TENER NUESTRA TABLA
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: asociateData,
  });

  // Función para actualizar los datos cuando se modifica un valor
  const handleInputChange = (e, row, cell) => {
    const { value } = e.target;
    setQuantitySelected(value || 0);
    const updatedData = [...asociateData];
    updatedData[row.index][cell.column.id] = parseInt(value) || 0;
    setAsociateData(updatedData);
  };

  // Función para manejar el enfoque del input
  const handleInputFocus = (e, format) => {
    setFormatSelected(format);
    setQuantitySelected(e.target.value);

    // Remover la clase de enfoque de todos los td
    document.querySelectorAll('.focused').forEach((element) => {
      element.classList.remove('focused');
    });
    // Agregar la clase de enfoque al td padre del input enfocado
    e.target.parentElement.classList.add('focused');
  };

  const handleOpenMenuCenterTypes = (e, formato) => {
    setFormatoSelectedAfterClick(formato);
    setAnchorEl(e.currentTarget);
  };

  const handleSelectCenterType = (idCenterType) => {
    if (formatoSelectedAfterClick !== formatSelected) {
      setAnchorEl(null);
      return;
    }
    if (idCenterType === 'all') {
      // vamos a asociarle a todos los centros la cantidad seleccionada en el arte final seleccionado
      const updatedData = [...asociateData];
      updatedData.forEach((center) => {
        center[formatSelected] = parseInt(quantitySelected);
      });
      setAsociateData(updatedData);
    } else {
      // vamos a asociarle a todos los centros que tengan el tipo de centro seleccionado en el arte final seleccionado
      const updatedData = [...asociateData];
      updatedData.forEach((center) => {
        if (center.centerType === idCenterType) {
          center[formatSelected] = parseInt(quantitySelected);
        }
      });
      setAsociateData(updatedData);
    }
    setAnchorEl(null);
  };

  return (
    // RENDERIZAMOS LA TABLA
    <>
      <div className="container-table-asociados asociate-table">
        <table {...getTableProps()}>
          {/* RENDERIZAMOS LA CABECERA DE LA TABLA */}
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => {
                  let aaff = column.Header.split('%')[0];
                  let format = column.Header.split('%')[1];
                  let priceFormat = column.Header.split('%')[2];
                  return (
                    <th className="asociate-table" {...column.getHeaderProps()}>
                      {i === 0 ? (
                        <p style={{ marginBottom: '0' }}>{column.render('Header')}</p>
                      ) : (
                        <>
                          <p style={{ marginBottom: '0' }}>{aaff}</p>
                          <p style={{ marginBottom: '0.5rem' }}>{format}</p>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'center' }}>
                            <p style={{ marginBottom: '0', fontStyle: 'italic' }}>{priceFormat} €</p>
                            <IconButton onClick={(e) => handleOpenMenuCenterTypes(e, column.id)}>
                              <AddBusinessIcon />
                            </IconButton>
                          </div>
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {/* RENDERIZAMOS EL BODY DE LA TABLA */}
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    let center = cell.row.original.center.split('%')[1];
                    let format = cell.column.id;
                    return (
                      <td {...cell.getCellProps()} key={index}>
                        {/* Solo renderizar el input si no es la primera columna */}
                        {index !== 0 ? (
                          <input
                            value={cell.value}
                            onChange={(e) => handleInputChange(e, row, cell)}
                            onFocus={(e) => handleInputFocus(e, format)}
                          />
                        ) : (
                          center
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* TODO: Menu para elegir a que tipo de centro le queremos asignar la cantidad */}
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => handleSelectCenterType('all')} style={{ fontWeight: 'bold' }}>
          Todos
        </MenuItem>
        {centerTypesSelected.map((centerType) => (
          <MenuItem onClick={() => handleSelectCenterType(centerType.centerId)}>{centerType.centerType}</MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AsociateTable;
