import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { checkCentersStatusByCluster, editCluster, getOneCluster } from '../../../api/carteleriaApi';

function useEditCluster(isEditMode) {
  const { getAccessTokenSilently } = useAuth0();
  const { clusterId } = useParams();
  const queryClient = useQueryClient();

  const fetchCluster = async () => {
    const token = await getAccessTokenSilently();
    return await getOneCluster(token, clusterId);
  };

  const { data, isLoading, isError, error } = useQuery(['centerType', clusterId], fetchCluster, {
    enabled: isEditMode,
    onError: (err) => {
      toast.error('Error al cargar el cluster: ' + err.message);
    },
  });

  const center = data?.data;

  const mutationCheckCenterStatus = useMutation({
    mutationFn: async (active) => {
      if (active) return { allCentersActive: true };
      const token = await getAccessTokenSilently();
      return await checkCentersStatusByCluster(token, clusterId);
    },
  });

  const mutationEditing = useMutation({
    mutationFn: async (body) => {
      const token = await getAccessTokenSilently();
      return await editCluster(token, body, clusterId);
    },
    onSuccess: () => {
      toast.success('Se ha actualizado el cluster con éxito.');
      queryClient.invalidateQueries(['allCentersTypes']);
    },
    onError: (err) => {
      toast.error('Se ha producido un error al actualizar el cluster: ' + err.message);
    },
  });

  const editingCluster = (values) => {
    return mutationEditing.mutateAsync(values);
  };

  const checkCentersStatus = (active) => {
    return mutationCheckCenterStatus.mutateAsync(active);
  };

  return { editingCluster, checkCentersStatus, center, isLoading, isError, error };
}

export default useEditCluster;
