// FooterCard.js

import { Button } from '@mui/material';
import { CgMathMinus, CgMathPlus } from 'react-icons/cg';
import { useContext, useState } from 'react';
import { ArteFinalContext_v2 } from '../../context/ArteFinalContext_v2';
import { useProviderShoppingCart } from '../../hooks/useProviderFormat';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';

const FooterCard = () => {
  const { shoppingCart_v2 } = useContext(ShoppingCartContext);
  const {
    formatSelected,
    quantitySelected,
    formatProviderSelected,
    materialSelected,
    setOpenModalSelectChildrenFormat,
    setRequiredFormatChildren,
    arteFinal,
    setQuantitySelected,
  } = useContext(ArteFinalContext_v2);
  const { handleAddProviderFormat, handleDecrement, handleIncrement, updateCartManually } = useProviderShoppingCart();

  const [isEditing, setIsEditing] = useState(false);

  const handleAdd = () => {
    const minQuantity = arteFinal.minQuantity || 1;
    if (quantitySelected < minQuantity) {
      setQuantitySelected(minQuantity);
    }
    handleAddProviderFormat(setRequiredFormatChildren, setOpenModalSelectChildrenFormat);
  };

  const handleQuantityClick = () => {
    setIsEditing(true);
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value === '' || parseInt(value, 10) >= 0) {
      setQuantitySelected(value === '' ? '' : parseInt(value, 10));
    }
  };

  const handleBlur = () => {
    const minQuantity = arteFinal.minQuantity || 1;
    setIsEditing(false);
    if (quantitySelected === '' || quantitySelected === 0) {
      setQuantitySelected(0);
      handleDecrement();
    } else if (quantitySelected < minQuantity) {
      setQuantitySelected(minQuantity);
      updateCartManually();
    } else {
      updateCartManually();
    }
  };

  return (
    <div
      className={`${
        !formatSelected.formatoCustom &&
        !shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId] &&
        !formatProviderSelected
          ? 'hidden'
          : ''
      } `}
    >
      <div
        style={{
          display: 'flex',
          justifyItems: 'center',
          justifyContent: 'space-between',
          margin: '1.5rem',
          fontSize: '24px',
        }}
      >
        <CgMathMinus className="icons-style" onClick={handleDecrement} />

        {isEditing ? (
          <input
            type="number"
            value={quantitySelected}
            onChange={handleQuantityChange}
            onBlur={handleBlur}
            autoFocus
            style={{
              width: '60px',
              textAlign: 'center',
              fontSize: '24px',
              background: 'none',
              border: 'none',
              outline: 'none',
            }}
            min="0"
          />
        ) : (
          <h2 className="us-none" onClick={handleQuantityClick} style={{ cursor: 'pointer' }}>
            {quantitySelected}
          </h2>
        )}

        <CgMathPlus className="icons-style" onClick={handleIncrement} />
      </div>
      <div style={{ height: '50px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '0.75rem' }}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleAdd}
            style={{ width: '100%' }}
            disabled={
              quantitySelected === 0 ||
              (formatSelected.formatoCustom && (!formatSelected.formatoName || !materialSelected)) ||
              shoppingCart_v2[arteFinal._id]?.formatos[formatSelected?.formatoId]?.quantity > 0
            }
          >
            Añadir
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FooterCard;
