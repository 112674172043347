import React, { useState, useRef, Suspense, useContext, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCheckDeleteCategory, useSubcategories, useSubcategoriesExist } from '../../../hooks/useCategory';
import SkeletonLoader from '../../common/SkeletonLoader';
import FunctionIconsCategory from './FunctionIconsCategory';
import { GestionCategoryContext } from '../../../context/GestionCategoryContext';
import {
  getIndex,
  getParentCategory,
  handleClickIcon,
  handleMoveCategory,
} from '../../../helpers/Category/gestionCategories';
import { TbLayoutSidebarRightCollapseFilled, TbLayoutBottombarCollapseFilled } from 'react-icons/tb';
import { categoryIcons, moveIconsNoSelected, moveIconsSelected } from '../../../utils/categoryRowIcons';
import DeletePopover from '../../common/DeletePopover';
import { useMoveCategoryAuth } from '../../../context/MoveCategoryAuthContext';

const tooltip = (className, text, ref) => {
  return (
    <Tooltip anchorSelect={className} ref={ref}>
      {text}
    </Tooltip>
  );
};

const CategoryRow = ({ category }) => {
  const { categoryMove, setCategoryMove, allSubcategories, isLoading, mutation } = useContext(GestionCategoryContext);
  const { authorize } = useMoveCategoryAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { subcategories } = useSubcategories(category._id);
  const { isSubcategoriesEmpty } = useSubcategoriesExist(category._id);
  const { deletePopover, handleDeleteClick, handleDeleteClose, handleDeleteConfirm } = useCheckDeleteCategory();
  const createTooltipRef = useRef(null);
  const editTooltipRef = useRef(null);
  const deleteTooltipRef = useRef(null);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickOpenMove = () => {
    setCategoryMove({
      id: category._id,
      index: category.index,
      parentCategory: category.parentCategory,
      disabled: category.disabled,
      important: category.important,
    });
  };

  const handleClickCancelMove = () => {
    setCategoryMove(null);
  };

  const handleClickMove = (direction) => {
    const index = getIndex(categoryMove, category, direction);
    const parentCategory = getParentCategory(direction, category);

    handleMoveCategory({
      id: categoryMove.id,
      index,
      mutation,
      parentCategory: parentCategory,
      setCategoryMove,
      navigate,
      authorize,
    });
  };

  const handleClickOpenAddMasiveAaff = () => {
    const basePath = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;
    navigate(`${basePath}/add-masive-aaff/${category._id}/`);
  };

  return (
    <div className="gestion-container-category-subcategories" key={category._id}>
      <div className={`gestion-categoria-item-icons${categoryMove?.id === category._id ? '__move' : ''}`}>
        <div onClick={handleClick} className="gestion-categoria-item">
          {subcategories.data.length > 0 &&
            (open ? <TbLayoutBottombarCollapseFilled /> : <TbLayoutSidebarRightCollapseFilled />)}
          {category.name}
        </div>
        <div className="status-container" style={{ textAlign: 'start' }}>
          {category.disabled && (
            <div className="container-status-category-row">
              <div className="status-item__disabled" /> <div> Desactivado</div>
            </div>
          )}
          {category.important && (
            <div className="container-status-category-row">
              <div className="status-item__important" /> <div> Importante</div>
            </div>
          )}
        </div>
        <FunctionIconsCategory
          icons={
            !categoryMove
              ? categoryIcons({
                  category,
                  createTooltipRef,
                  editTooltipRef,
                  deleteTooltipRef,
                  handleClickOpenMove,
                  handleClickOpenAddMasiveAaff,
                  handleClickIcon,
                  navigate,
                  location,
                  tooltip,
                  isSubcategoriesEmpty: !isSubcategoriesEmpty?.data,
                  handleDeleteClick,
                })
              : categoryMove?.id === category._id
              ? moveIconsSelected(handleClickCancelMove, createTooltipRef, tooltip)
              : !allSubcategories.includes(category._id) && !isLoading
              ? moveIconsNoSelected({
                  handleClickMove,
                  createTooltipRef,
                  tooltip,
                  cantMove: categoryMove?.disabled || categoryMove?.important,
                })
              : []
          }
        />
      </div>
      <DeletePopover
        textCancel={'No'}
        textConfirm={'Sí'}
        textQuestion={
          '¿Estás seguro de que deseas eliminar la categoría? Una vez eliminada, no se podrá volver a utilizar en la aplicación.'
        }
        handleDeleteClose={handleDeleteClose}
        deletePopover={deletePopover}
        handleDeleteConfirm={handleDeleteConfirm}
      />
      {subcategories.data.length > 0 && open && (
        <div className="gestion-subcategories">
          <Suspense fallback={<SkeletonLoader count={subcategories.data.length} minHeight="35.69px" />}>
            {subcategories.data.map((subcategory) => (
              <CategoryRow key={subcategory._id} category={subcategory} />
            ))}
          </Suspense>
        </div>
      )}
    </div>
  );
};

export default CategoryRow;
