import { Button } from '@mui/material';
import { useContext } from 'react';
import { ArteFinalContext } from '../../context/ArteFinalContext';
import { useState } from 'react';
import { UserContext } from '../../context/UserProvider';
import { Buscador } from './Buscador';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import toast from 'react-hot-toast';
import { Colxx } from '../../components/common/CustomBootstrap';
import InventoryIcon from '@mui/icons-material/Inventory';
import ManualUsoButton from '../MasterRol/ManualUsoButton';
import { handleChangeInputSearch, handleClearInput, handleKeyDown } from '../../helpers/buscadorHelper';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useGetFiles, useGetMerchandisingLink } from '../../lib/react-query/fileQuery';

export const Header = () => {
  const [inputAfter, setInputAfter] = useState('');
  const { setInputValueSearch } = useContext(ArteFinalContext);
  const { centerConfirmed, userRole } = useContext(UserContext);
  const { data: merchanddisingSelected, isLoading: loadingMerchandising } = useGetMerchandisingLink();
  const { data: files } = useGetFiles(true);

  const handleClicButtonPDF = async () => {
    if (merchanddisingSelected?.link) {
      window.open(merchanddisingSelected.link, '_blank');
    } else {
      toast.error('No hay guía de merchandising para este centro', {
        autoClose: 5000,
        id: 'guia-merchandising-link',
      });
    }
  };

  const handleClickFile = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="header-container">
      <Colxx xxs="3" className="remove-margin-padding">
        <h2 className="font-size-1-resonsive">CATÁLOGO DE MATERIALES </h2>
      </Colxx>
      <Colxx xxs="9" className="df-jcsb remove-margin-padding">
        <Buscador
          label={'Buscar por nombre, código de artículo o código de formato'}
          value={inputAfter}
          width={'40'}
          onChange={(e) => handleChangeInputSearch(e, setInputAfter)}
          onKeyDown={(e) => handleKeyDown(e, setInputValueSearch)}
          onClear={() => handleClearInput(setInputValueSearch, setInputAfter)}
        />
        {files.data.map((file) => (
          <Button
            size="small"
            variant="contained"
            color="error"
            startIcon={file.name === 'Colocación' ? <InventoryIcon /> : <CampaignIcon />}
            onClick={() => handleClickFile(file.url)}
          >
            {file.name}
          </Button>
        ))}
        {centerConfirmed?.merchandising?.link && (
          <Button
            disabled={loadingMerchandising}
            color="error"
            variant="contained"
            startIcon={<PictureAsPdfIcon />}
            onClick={handleClicButtonPDF}
          >
            GUÍAS MERCHANDISING
          </Button>
        )}
        {userRole === 'user-signage' && <ManualUsoButton path="/manual-user/doc" />}
      </Colxx>
    </div>
  );
};
