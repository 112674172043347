import React, { useEffect } from "react";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Tooltip } from 'react-tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { createOrderComment, getOrderComments } from "../../api/carteleriaApi";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import CommentCard from "./CommentCard";

const OrderComments = ({ orderId, admin }) => {
    const { user:userAuth0, getAccessTokenSilently } = useAuth0();
    
    const { data: comments, isFetching, refetch } = useQuery(['order-comments', orderId], async () => {
        const token = await getAccessTokenSilently();
        return await getOrderComments(token, orderId);
    });



    const [addComment, setAddComment] = React.useState(false);
    const [commentValue, setCommentValue] = React.useState('');
    const [loadingCreateComment, setLoadingCreateComment] = React.useState(false);

    const handleAddComment = async () => {
        setLoadingCreateComment(true);
        const user = {
            id: userAuth0.sub,
            name: userAuth0.nickname,
            email: userAuth0.email,
            picture: userAuth0.picture
        };
        const comment = commentValue;
        const order = orderId;

        // AQUI SE HACE EL POST DEL COMENTARIO
        const token = await getAccessTokenSilently();
        await createOrderComment(token, { user, comment, order })
        refetch();
        setLoadingCreateComment(false);
        setAddComment(false);
        setCommentValue('');
        toast.success('Comentario agregado correctamente');
    }

    return (
        <> 
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <h2 className='title-order'>Comentarios</h2>
            {(!addComment && admin) && <DriveFileRenameOutlineOutlinedIcon onClick={() => setAddComment(true)} style={{ cursor: 'pointer' }} className="add-order-comment-tooltip title-order" />}
            <Tooltip place="right" anchorSelect=".add-order-comment-tooltip">
                <span>Agregar comentario</span>
            </Tooltip>

        </div>

       {addComment && <div>
        <textarea maxLength="1000" value={commentValue} onChange={(e) => setCommentValue(e.currentTarget.value)} style={{height: '200px', resize: 'none', width: '50%', border: '1px solid #ccc', outline: 'none', fontSize: '13px', lineHeight: '1.42', padding: '12px 15px', color: '#3a3a3a', marginBottom: '-.30rem'}} />
        <div style={{ marginBottom: '2rem',  display: 'flex', gap: '.5rem', justifyContent: 'flex-end', width: '50%', backgroundColor: 'white', padding: '.5rem .25rem'}}>
            <button style={{backgroundColor: '#F3F4F6', border: '1px solid #F3F4F6', borderRadius: '4px', padding: '.5rem 1rem'}} onClick={() => {
                setAddComment(false);
                setCommentValue('');
            }}>Cancelar</button>
            <button onClick={handleAddComment} className="btn btn-primary" disabled={loadingCreateComment || commentValue.length <= 3}  style={{backgroundColor: '#000000', color: 'white', border: '1px solid #000000', borderRadius: '4px', padding: '.5rem 1rem', display: 'flex', alignItems: 'center', gap: '1rem'}}>
                <span>
                Agregar comentario
                </span>
               {loadingCreateComment && <CircularProgress size={20} style={{color: 'white'}} />}
            </button>
        </div>
        </div>}


            {/* AQUI ES DONDE IRAN LOS COMENTARIOS */}
        
        {isFetching ? <div className="custom-loader" /> :
             comments.data.length === 0 ? <p style={{marginTop: '1rem'}}>
                {admin ? !addComment && 'No hay comentarios, si desea agregar uno pulse en el icono justo arriba' : 'Todavia no hay ningun comentario para esta solicitud'}
            </p> : 
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                {comments.data.map((comment, index) => (
                    <CommentCard key={index} comment={comment} />
                ))}
            </div>
               
        }

        </>
        )
}

export default OrderComments;