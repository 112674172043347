import { useContext } from 'react';
import { UserContext } from '../context/UserProvider';
import { useLocation } from 'react-router-dom';
import { useDeleteCategoryAuth } from '../context/DeleteCategoryAuthContext';
import { useMoveCategoryAuth } from '../context/MoveCategoryAuthContext';

export const useMasterAuth = (requiredRole) => {
  let pass = false;
  const { userRole } = useContext(UserContext);
  const { authorized: authorizedDelete } = useDeleteCategoryAuth();
  const { authorized: authorizedMove } = useMoveCategoryAuth();
  const location = useLocation();

  if (requiredRole.includes(userRole)) {
    if (location.pathname.includes('delete-category')) {
      if (authorizedDelete) {
        pass = true;
      }
    } else if (location.pathname.includes('cant-move')) {
      if (authorizedMove) {
        pass = true;
      }
    } else {
      pass = true;
    }
  }

  return { pass };
};
