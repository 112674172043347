import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CenterOptions } from './CenterOptions';
import { useContext } from 'react';
import { UserContext } from '../../context/UserProvider';
import { Card, CardContent, Typography } from '@mui/material';
import { getCentersByAuth0Id } from '../../api/userApi';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';

export const ContainerCenter = () => {
  const { autocompleteValue, setAutocompleteValue, setCenterConfirmed } = useContext(UserContext);
  // const { setShoppingCart } = useContext(ArteFinalContext);
  const { setShoppingCart_v2 } = useContext(ShoppingCartContext);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();
  const { data } = useQuery(['AllCenters'], async () => {
    const token = await getAccessTokenSilently();
    return getCentersByAuth0Id(token);
  });

  const hanleSendCenterSelected = () => {
    if (autocompleteValue) {
      toast.success('Centro seleccionado correctamente');
      // setShoppingCart({});
      setShoppingCart_v2({});
      setCenterConfirmed(autocompleteValue);
      navigate('/carteleria');
    } else {
      toast.error('No has seleccionado ningún centro', {
        id: 'non-center-selected',
      });
    }
  };

  const getImageStatus = () => {
    if (autocompleteValue.imagenAnterior && autocompleteValue.imagenNueva) {
      return 'Imagen Anterior y Nueva';
    } else if (autocompleteValue.imagenAnterior) {
      return 'Imagen Anterior';
    } else if (autocompleteValue.imagenNueva) {
      return 'Imagen Nueva';
    } else {
      return 'Sin imagen';
    }
  };

  return (
    <div className="holaMundoPa" style={{ height: '90%', padding: '2rem' }}>
      {data ? (
        <div style={{ height: '100%' }}>
          <div className="first child" style={{ display: 'flex' }}>
            <CenterOptions
              data={data}
              autocompleteValue={autocompleteValue}
              setAutocompleteValue={setAutocompleteValue}
            />

            <Button variant="contained" color="error" onClick={hanleSendCenterSelected} sx={{ marginLeft: '1rem' }}>
              Continuar
            </Button>
          </div>

          <div
            className="cardWithInfo"
            style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}
          >
            {autocompleteValue ? (
              <Card raised={true}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {/* {autocompleteValue._id} */}
                    Información de tu centro seleccionado:
                  </Typography>
                  <Typography color="text.secondary">Código: {autocompleteValue.code}</Typography>
                  <Typography color="text.secondary">
                    Nombre: {autocompleteValue?.name || autocompleteValue.calle}
                  </Typography>
                  <Typography color="text.secondary">Calle: {autocompleteValue.calle}</Typography>
                  {/* Agrega más información según tus necesidades */}
                  <Typography color="text.secondary">Tipo de centro: {autocompleteValue.centerType.type}</Typography>
                  <Typography color="text.secondary">Municipio: {autocompleteValue.municipio}</Typography>
                  <Typography color="text.secondary">Provincia: {autocompleteValue.provincia}</Typography>
                  <Typography color="text.secondary">Comunidad: {autocompleteValue.comunidad.name}</Typography>
                  <Typography color="text.secondary">Corresponde a: {getImageStatus()}</Typography>
                </CardContent>
              </Card>
            ) : (
              <Typography variant="h6" color="textSecondary">
                No hay información seleccionada.
              </Typography>
            )}
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
