import { useParams } from 'react-router-dom';
import InfoArteFinalContainer from './ArteFinalInfo/InfoArteFinalContainer';
import InfoArteFinalTitle from './ArteFinalInfo/InfoArteFinalTitle';
import TitleSection from './ArteFinalInfo/TitleSection';
import CustomButton from '../../../ui/Button/CustomButton';
import { isNullOrEmptyString } from '../../../utils/textFunctions';
import { IMPRIMIBLES_LANGUAGES } from '../../../utils/constants';
import ButtonViewPdf from './ImprimibleInfo/ButtonViewPdf';
import ButtonsPdf from './ImprimibleInfo/ButtonsPdf';
import InputPdf from './ImprimibleInfo/InputPdf';
import { useGetPdfLanguages } from '../../../hooks/useArteFinal';
import SkeletonLoader from '../../common/SkeletonLoader';

const ArteFinalDescargable = () => {
  const { idArte } = useParams();
  const { data: pdfLanguages, isLoading } = useGetPdfLanguages(idArte);

  const renderPdfSection = () => {
    if (isLoading) {
      return <SkeletonLoader count={4} minHeight={'100px'} />;
    }

    return IMPRIMIBLES_LANGUAGES.map(({ label, property }) => (
      <InfoArteFinalContainer key={property}>
        <InfoArteFinalTitle>{label}</InfoArteFinalTitle>
        <InputPdf pdf={pdfLanguages.data[property]} language={property} />
        {!isNullOrEmptyString(pdfLanguages.data[property]) && (
          <ButtonsPdf pdfs={pdfLanguages.data} property={property} />
        )}
      </InfoArteFinalContainer>
    ));
  };

  return (
    <div className="imprimibles-section">
      <TitleSection title="Ficheros imprimibles" />
      <div className="imprimibles-container">{renderPdfSection()}</div>
    </div>
  );
};

export default ArteFinalDescargable;
