import { Outlet } from 'react-router-dom';
import MenuStats from '../Stats/MenuStats';

const TabStats = () => {
  return (
    <div>
      <div className="container-title-estadisticas">
        <h2>Estadísticas</h2>
      </div>

      <div className="container-estadisticas">
        {/* MENU PARA EL LISTADO DE GRAFICOS */}
        <div className="menu-estadisticas">
          <MenuStats />
        </div>
        {/* CONTENIDO DE LOS GRAFICOS */}
        <div className="contenido-estadisticas">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default TabStats;
