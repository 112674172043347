import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CartItemImageArteFinal from '../Cart/CartItem/CartItemImageArteFinal';

export const DialogWithImg = ({ name, code, isModalOpen, closeModal, imgFullScreen, loadingImgAAFF }) => {
  return (
    <div>
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        maxWidth="xl"
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            boxShadow: 'none',
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeModal}
          aria-label="close"
          style={{
            position: 'absolute',
            color: '#fff',
            top: '10px',
            right: '30px',
            zIndex: 1,
          }}
        >
          <CloseIcon style={{ fontSize: '2.5rem' }} />
        </IconButton>
        <DialogTitle style={{ color: '#fff', textAlign: 'center' }}>
          {name} - {code}
        </DialogTitle>

        <CartItemImageArteFinal
          styleContainer={{
            height: '90vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          styleImg={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
          img={imgFullScreen || '/Image_not_available.png'}
          // loadingImgAAFF={loadingImgAAFF}
        />
      </Dialog>
    </div>
  );
};
