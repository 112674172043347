import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { deleteCenter } from '../helpers/Center/centerHelper';
import { useMutation, useQueryClient } from '@tanstack/react-query';

function useDeleteCenter() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const [deletePopover, setDeletePopover] = useState(null);
  const [centerId, setCenterId] = useState('');

  const handleDeleteClose = () => {
    setDeletePopover(null);
  };

  const handleDeleteClick = (event) => {
    setCenterId(event.currentTarget.id);
    setDeletePopover(event.currentTarget);
  };

  const { mutate: handleDeleteConfirm } = useMutation({
    mutationFn: async () => {
      handleDeleteClose();
      let token = await getAccessTokenSilently();
      await deleteCenter(centerId, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('allCenters');
    },
  });

  return { deletePopover, handleDeleteClose, handleDeleteClick, handleDeleteConfirm };
}

export default useDeleteCenter;
