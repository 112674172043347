import React, { createContext, useContext, useEffect, useState } from 'react';
import Viewer from '../helpers/Viewer/Viewer';
import { ViewerContext } from './ViewerContext';

export const PartidasContext = createContext();

const LIMIT = 6;

export const PartidasProvider = ({ children }) => {

    const initialValue = [0, 1000]

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [partidaQuery, setPartidaQuery] = useState({ limit: LIMIT, currentPage: currentPage })
    const [inputSearch, setInputSearch] = useState('')
    const [sortValue, setSortValue] = useState(' ')
    const [priceFilter, setPriceFilter] = useState(initialValue)
    const [currentVersion, setCurrentVersion] = useState(" ");

  const resetPriceFilter = () => {
    setPriceFilter(initialValue);
  };


    const data = {
      partidaQuery,
      setPartidaQuery,
      currentPage,
      setCurrentPage,
      totalPages,
      setTotalPages,
      LIMIT,
      inputSearch,
      setInputSearch,
      sortValue,
      setSortValue,
      priceFilter,
      setPriceFilter,
      resetPriceFilter,
      currentVersion,
      setCurrentVersion,
  }

  return <PartidasContext.Provider value={data}>{children}</PartidasContext.Provider>;
};
