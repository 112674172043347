import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormProveedor from './FormProveedor';
import { handleCloseModal, handleSubmitCreateProveedor } from '../../../helpers/Proveedor/proveedorHelper';
import { useCreateProvider } from '../../../lib/react-query/providerQuery';

const AddProveedor = () => {
  const { mutation } = useCreateProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    code: '',
    shortName: '',
    name: '',
    active: true,
  });

  return (
    <FormProveedor
      title="Crear proveedor"
      handleSubmit={(e) => handleSubmitCreateProveedor({ e, mutation, formData, navigate, location })}
      formData={formData}
      handleChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
      handleCloseModal={() => handleCloseModal(navigate, location, 1)}
    />
  );
};

export default AddProveedor;
