// icons.js
import { FaRegCircleCheck } from 'react-icons/fa6';
import {
  MdOutlineMoveDown,
  MdOutlineMoveUp,
  MdOutlineDriveFileMove,
  MdDeleteOutline,
  MdOutlineMoveToInbox,
} from 'react-icons/md';
import { CgFileAdd } from 'react-icons/cg';
import { IoAddCircleOutline } from 'react-icons/io5';
import { FiEdit2 } from 'react-icons/fi';

export const moveIconsSelected = (handleClickCancelMove, createTooltipRef, tooltip) => [
  {
    icon: (
      <FaRegCircleCheck
        onClick={handleClickCancelMove}
        size="20px"
        className="gestion-categories-icon gestion-tooltip-cancel-move"
      />
    ),
    tooltip: tooltip('.gestion-tooltip-cancel-move', 'Dejar de mover', createTooltipRef),
  },
];

export const moveIconsNoSelected = (params) =>
  [
    !params.cantMove
      ? {
          icon: (
            <MdOutlineMoveToInbox
              onClick={() => params.handleClickMove('into')}
              size="20px"
              className="gestion-categories-icon gestion-tooltip-into-move"
            />
          ),
          tooltip: params.tooltip('.gestion-tooltip-into-move', 'Mover dentro', params.createTooltipRef),
        }
      : null,
    {
      icon: (
        <MdOutlineMoveUp
          onClick={() => params.handleClickMove('up')}
          size="20px"
          className="gestion-categories-icon gestion-tooltip-up-move"
        />
      ),
      tooltip: params.tooltip('.gestion-tooltip-up-move', 'Mover encima', params.createTooltipRef),
    },
    {
      icon: (
        <MdOutlineMoveDown
          onClick={() => params.handleClickMove('down')}
          size="20px"
          className="gestion-categories-icon gestion-tooltip-down-move"
        />
      ),
      tooltip: params.tooltip('.gestion-tooltip-down-move', 'Mover debajo', params.createTooltipRef),
    },
  ].filter(Boolean);

export const categoryIcons = (params) =>
  [
    // Poner este icono si no tiene más subcategorias
    params.isSubcategoriesEmpty
      ? {
          icon: (
            <CgFileAdd
              onClick={params.handleClickOpenAddMasiveAaff}
              className="gestion-categories-icon gestion-tooltip-add-masive-aaff"
              size="20px"
            />
          ),
          tooltip: params.tooltip('.gestion-tooltip-add-masive-aaff', 'Añadir artículos', params.createTooltipRef),
        }
      : null,
    {
      icon: (
        <MdOutlineDriveFileMove
          onClick={params.handleClickOpenMove}
          className="gestion-categories-icon gestion-tooltip-move"
          size="20px"
        />
      ),
      tooltip: params.tooltip('.gestion-tooltip-move', 'Mover categoría', params.createTooltipRef),
    },
    {
      icon: (
        <IoAddCircleOutline
          onClick={() =>
            params.handleClickIcon({
              categoryId: params.category._id,
              newLocation: 'add-category',
              tooltipRef: params.createTooltipRef,
              navigate: params.navigate,
              location: params.location,
            })
          }
          className="gestion-categories-icon gestion-tooltip-create"
          size="20px"
        />
      ),
      tooltip: params.tooltip('.gestion-tooltip-create', 'Crear subcategoría', params.createTooltipRef),
    },
    {
      icon: (
        <FiEdit2
          onClick={() =>
            params.handleClickIcon({
              categoryId: params.category._id,
              newLocation: 'edit-category',
              tooltipRef: params.editTooltipRef,
              navigate: params.navigate,
              location: params.location,
            })
          }
          className="gestion-categories-icon gestion-tooltip-edit"
          size="20px"
        />
      ),
      tooltip: params.tooltip('.gestion-tooltip-edit', 'Editar categoría', params.editTooltipRef),
    },
    {
      icon: (
        <MdDeleteOutline
          id={params.category._id}
          onClick={params.handleDeleteClick}
          className="gestion-categories-icon gestion-tooltip-delete"
          size="20px"
        />
      ),
      tooltip: params.tooltip('.gestion-tooltip-delete', 'Eliminar categoría', params.deleteTooltipRef),
    },
  ].filter(Boolean); // Eliminar cualquier elemento nulo del array
