import React from 'react';
import { formatCurrency } from '../../../utils/formatNumber';

const CustomTooltip = ({ active, payload, label, dataKeys }) => {
  const [keyLeft, keyRight] = Object.values(dataKeys);

  if (active && payload.length > 0) {
    const leftValue = payload.find((item) => item.dataKey === keyLeft)?.value || 'N/A';
    const rightValue = payload.find((item) => item.dataKey === keyRight)?.value || 'N/A';

    return (
      <div className="custom-tooltip">
        <p className="label">{`Nombre: ${label}`}</p>
        <p className="intro">{`${keyLeft}: ${formatCurrency(leftValue, 0)}`}</p>
        <p className="intro">{`${keyRight}: ${formatCurrency(rightValue)} €`}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
