import { Button } from '@mui/material';
import { Buscador } from '../../Pedido/Buscador';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';

const HeadingGestionCategorias = () => {
  const navigate = useNavigate();

  return (
    <div className="grid-clusters-centers-container">
      <div className="grid-item">
        <h2 className="f-w-600">CATEGORÍAS</h2>
      </div>
      <div className="grid-item-clusters-centers-search">
        <Buscador
          //   value={inputAfter}
          width="85"
          //   onChange={(e) => handleChangeInputSearch(e, setInputAfter)}
          //   onKeyDown={(e) => handleKeyDown(e, setInputValueSearch)}
          //   onClear={() => handleClearInput(setInputValueSearch, setInputAfter)}
        />
        <Button
          variant="contained"
          color="error"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => navigate('add-category')}
          aria-label="add"
          style={{
            height: '2.5rem',
          }}
        >
          Crear Categoría
        </Button>
      </div>
    </div>
  );
};

export default HeadingGestionCategorias;
