import * as yup from 'yup';
import CenterModal from './CenterModal';
import { useParams } from 'react-router-dom';

const validationSchema = yup.object().shape({
  code: yup.string().required('El código es obligatorio'),
  imagenAnterior: yup.boolean().required('Determinar si hay imagen anterior es obligatorio'),
  imagenNueva: yup.boolean().required('Determinar si hay imagen nueva es obligatorio'),
  name: yup.string().required('El nombre es obligatorio'),
  calle: yup.string().required('La calle es obligatoria'),
  comunidad: yup.string().required('La comunidad es obligatoria'),
  centerType: yup.string().required('El cluster es obligatorio'),
  // merchandising: yup.string().required('El merchandising es obligatorio'),
  active: yup.boolean().required('El campo activo, es obligatorio'),
});

function AddEditCenter({ edit }) {
  const { centerId } = useParams();
  if (edit) return <CenterModal validationSchema={validationSchema} mode={'edit'} centerId={centerId} />;
  return <CenterModal validationSchema={validationSchema} />;
}

export default AddEditCenter;
