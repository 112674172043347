import { Button, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import SelectTypeFile from './SelectTypeFile';
import { useState } from 'react';

const FormFile = ({ title, isLoading, handleSubmit, formData, handleChange, handleCloseModal }) => {
  const [fileName, setFileName] = useState('Ningún archivo seleccionado');

  return (
    <Modal open onClose={handleCloseModal}>
      <div className="create-cluster-container-modal">
        <div className="center-form-title">
          <Typography
            variant="h4"
            component="h2"
            align="center"
            sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
          >
            {title}
          </Typography>

          {isLoading ? (
            <div className="custom-loader" />
          ) : (
            <form className="file-edit-form" onSubmit={handleSubmit}>
              <TextField
                name="name"
                label="Nombre del Archivo"
                variant="outlined"
                required
                fullWidth
                value={formData.name}
                onChange={handleChange}
              />
              <div className="d-flex ai-center g-1">
                <Typography variant="body2">Subir Archivo:</Typography>
                <SelectTypeFile
                  onClick={(value) => handleChange({ target: { name: 'type', value } })}
                  optionSelected={formData.type}
                  options={['link', 'archivo']}
                />
              </div>
              {formData.type === 'link' ? (
                <TextField
                  name="url"
                  label="Link del Archivo"
                  variant="outlined"
                  required
                  fullWidth
                  value={formData.url}
                  onChange={handleChange}
                />
              ) : (
                <div className="file-upload-wrapper">
                  <label htmlFor="file" className="file-upload-label">
                    Seleccionar archivo
                  </label>
                  <span className="file-upload-info" id="file-name">
                    {formData.file ? formData.file.name : fileName}
                  </span>
                  <input
                    onChange={(e) => {
                      const file = e.target.files[0];
                      handleChange({ target: { name: 'file', value: file } });
                    }}
                    type="file"
                    name="file"
                    id="file"
                  />
                </div>
              )}

              <div className="button-group-tiendas">
                <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
                  Confirmar
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  endIcon={<CloseIcon />}
                  onClick={handleCloseModal}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FormFile;
