const CategoryRow = ({ category, handleCategoryClick }) => {
  return (
    <li
      key={category._id}
      className={`category-row-li ${category?.important ? 'category-important' : ''}`}
      onClick={() => {
        handleCategoryClick(category);
      }}
    >
      <div>{category.name}</div>
      <div className="total-artes-category">({category?.totalArteFinales})</div>
    </li>
  );
};

export default CategoryRow;
