import React, { useContext, useEffect } from 'react';
import TableAdmin from '../Components2/common/TableAdmin';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getOrders } from '../api/carteleriaApi';
import { Pagination } from '@mui/material';
import { OrdersFiltersUserContext } from '../context/OrdersFiltersUserContext';
import FilterMisSolicitudes from '../Components2/User/Orders/FilterMisSolicitudes';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const Orders = () => {
  const navigate = useNavigate();
  const { user, getAccessTokenSilently } = useAuth0();
  const [page, setPage] = React.useState(1);
  const {
    statusFilter,
    centersFilter,
    createStartDateFilter,
    createEndDateFilter,
    deliveryStartDateFilter,
    deliveryEndDateFilter,
    fetchingFilters,
    setOrderFilter,
    orderFilter,
  } = useContext(OrdersFiltersUserContext);

  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      'orders-by-user',
      page,
      user.sub,
      statusFilter,
      centersFilter,
      createStartDateFilter,
      createEndDateFilter,
      deliveryStartDateFilter,
      deliveryEndDateFilter,
    ],
    async () => {
      let token = await getAccessTokenSilently();
      return await getOrders(
        token,
        page,
        10,
        {
          order: orderFilter,
          estado: statusFilter,
          centro: centersFilter,
          createStartDate: createStartDateFilter,
          createEndDate: createEndDateFilter,
          deliveryStartDate: deliveryStartDateFilter,
          deliveryEndDate: deliveryEndDateFilter,
        },
        user.sub,
      );
    },
  );

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [
    statusFilter,
    centersFilter,
    createStartDateFilter,
    createEndDateFilter,
    deliveryStartDateFilter,
    deliveryEndDateFilter,
  ]);

  useEffect(() => {
    refetch();
  }, [orderFilter]);

  const handleGoBack = () => {
    navigate('/carteleria');
  };

  return (
    <div style={{ height: 'calc(100vh - 100px)' }}>
      <div className="title">
        <h2>Mis solicitudes</h2>
        <div onClick={handleGoBack} className="title-goback">
          <IoMdArrowRoundBack />
          <span>Volver a cartelería</span>
        </div>
      </div>
      <div className="grid-clusters-centers-container">
        <FilterMisSolicitudes />
        <div className="grid-item-clusters-centers-table">
          <div className="core">
            {isLoading || fetchingFilters || (isFetching && data?.data?.length == 0) ? (
              <div className="custom-loader"></div>
            ) : (
              <TableAdmin setOrderFilter={setOrderFilter} byUser listOrder={data?.data} />
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {data?.total > 10 && (
              <Pagination
                showFirstButton
                showLastButton
                page={page}
                onChange={(e, v) => setPage(v)}
                count={Math.ceil(data?.total / 10)}
                color="error"
                variant="outlined"
                size="large"
                sx={{ margin: '2rem' }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
