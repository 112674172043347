import { Modal, Stepper, Step, StepLabel } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { FormatCompuestoContext } from '../../../context/FormatCompuestoContext';
import FormFormatInfo from './FormFormatInfo';
import { useParams } from 'react-router-dom';
import FormProviderAsociate from './FormProviderAsociate';
import { postFormat } from '../../../api/carteleriaApi';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';

const validationSchema = yup.object().shape({
  code: yup.string().required('El código es obligatorio'),
});

const validationSchemaProvider = yup.object().shape({
  code: yup.string().required('El código es obligatorio'),
  provider: yup.string().required('El proveedor es obligatorio'),
});

export const ModalFormatos = ({ isOpen, onClose }) => {
  const { idArte } = useParams();
  const queryClient = useQueryClient();
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { label: 'Crear Formato', show: true },
    { label: 'Asociar proveedor', show: false },
  ];

  const { aaffSelected, compuestoValue, required } = useContext(FormatCompuestoContext);

  const formikProvider = useFormik({
    initialValues: {
      code: '',
      provider: null,
      format: null,
      arteFinal: idArte,
      price: 0,
      active: true,
    },
    validationSchema: validationSchemaProvider,
    onSubmit: async (values) => {
      try {
        await postFormat(formik.values, values, aaffSelected, required, compuestoValue);
        queryClient.invalidateQueries(['formats-arte-final', idArte]);
        toast.success('Formato creado con éxito');
        onClose();
      } catch (error) {
        console.error('Error al crear el Formato:', error);
        toast.error('Error al crear el Formato');
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      name: '',
      description: '',
      arteFinal: idArte,
      active: true,
      isCustom: false,
      material: null,
    },
    validationSchema,
    onSubmit: () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
  });

  const goBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="modal-create-edit-format">
        <Stepper style={{ marginBottom: '2rem', marginTop: '1rem', userSelect: 'none' }} activeStep={activeStep}>
          {steps.map(({ label, show }, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 && <FormFormatInfo formik={formik} onClose={onClose} />}
        {activeStep === 1 && <FormProviderAsociate formik={formikProvider} goBack={goBack} />}
      </div>
    </Modal>
  );
};
