import { useState, useEffect, useContext } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoginButton from '../../auth0-config/login';
import LogoutButton from '../../auth0-config/logout';
import { Opacity } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { UserContext } from '../../context/UserProvider';
import { useNavigate } from 'react-router-dom';

export const PopoverAccount = ({ user }) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const redirectLogoutUrl = process.env.REACT_APP_AUTH0_LOGOUT_URL;
  const { logout: logoutAuth0 } = useAuth0();
  const { userRole } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = async () => {
    let token = await getAccessTokenSilently();

    let { data } = await axios.get('/api/v1/auth/logout', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
    });

    window.location.replace(data.url);
  };

  const login = async () => {
    let token = await getAccessTokenSilently();

    let { data } = await axios.get('/api/v1/auth/login', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
    });

    // console.log('VEAMOS LA DATA URL: ', data.url);

    window.location.replace(data.url);
  };

  //Cambiar por context cuando se compruebe toda la info
  return (
    <>
      <Button
        style={{
          height: '50px',
          width: '50px',
          borderRadius: '40%',
          Opacity: '1',
        }}
      >
        <img
          style={{
            height: '50px',
            width: '50px',
            borderRadius: '50%',
          }}
          src={user.picture || 'https://i38.photobucket.com/albums/e149/eloginko/profile_male_large_zpseedb2954.jpg'}
          alt="Perfil"
          aria-describedby={id}
          onClick={handleClick}
        />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {!user ? (
          // <button
          //   className="btn default btn-danger"
          //   style={{ marginRight: "1rem" }}
          //   onClick={login}
          // >
          //   Iniciar Sesión
          // </button>
          <LoginButton />
        ) : (
          <>
            {userRole === 'user-signage' && (
              <button
                style={{ width: '100%' }}
                onClick={() => {
                  navigate('/carteleria/orders');
                  handleClose();
                }}
                className="btn default"
              >
                Mis solicitudes
              </button>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <button
                style={{ width: '100%' }}
                onClick={() => logoutAuth0({ logoutParams: { returnTo: redirectLogoutUrl } })}
                className="btn default btn-danger"
              >
                Cerrar Sesión
              </button>
            </div>
          </>
        )}
        {/* {!userAutodesk ? (
          <div style={{ textAlign: 'center' }}>
            <button
              onClick={login}
              style={{
                width: 'fit-content',

                cursor: 'pointer',
              }}
              // className="btn btn-secondary"
            >
              <img src="https://atbim.fra1.cdn.digitaloceanspaces.com/symbol-1.svg" height="25px" />
              Autodesk Login
            </button>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }} onClick={logout}>
            <button
              className="btn default"
              style={{
                width: '100%',
                border: 'solid 1px black',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
            >
              <img src="https://atbim.fra1.cdn.digitaloceanspaces.com/symbol-1.svg" height="25px" />
              {userAutodesk.name}
              <LogoutIcon />
            </button>
          </div>
        )} */}
      </Popover>
    </>
  );
};
