import { deleteCosteInstalacionOrder, upadeCosteInstalacionOrder } from '../../api/carteleriaApi';

export const handleChangeQuantity = (params) => {
  const newCostesInstalacionOrderUpdate = params.newCostesInstalacionOrder.map((costeInstalacionOrder) => {
    if (costeInstalacionOrder.costeInstalacion == params.coste._id) {
      return {
        ...costeInstalacionOrder,
        cantidad: params.cantidad,
      };
    }
    return costeInstalacionOrder;
  });

  params.setNewCostesInstalacionOrder(newCostesInstalacionOrderUpdate);
  params.setAnchorEl(null);
  params.setOpenMenuIndex(null);
};

export const handleOnChange = (params) => {
  console.log('params', params);

  const cantidadValue = parseFloat(params.cantidad) || 0; // Convertir la cantidad a un número, si es NaN, establecer a 0
  params.setEditOriginalCostes(
    params.editOriginalCostes.map((costeInstalacionOrder) => {
      if (costeInstalacionOrder._id === params.idCoste) {
        return {
          ...costeInstalacionOrder,
          cantidad: cantidadValue,
        };
      }
      return costeInstalacionOrder;
    }),
  );
};

export const handleClickUpdateOrder = async (params) => {
  console.log('params de update', params);

  const token = await params.getAccessTokenSilently();

  await Promise.all(
    params.editOriginalCostes.map(async (costeInstalacionOrder) => {
      // SI LA CANTIDAD ES 0 ELIMINAR EL COSTE DE INSTALACION ORDER SI NO ACTUALIZARLO
      if (costeInstalacionOrder.cantidad === 0) {
        // Eliminar el coste de instalación order
        await deleteCosteInstalacionOrder(token, costeInstalacionOrder._id);
        // en el estado costesInstalacionV2 eliminar el coste de instalacion para que deje de aparecer en la tabla

        params.refetch();
      } else if (
        costeInstalacionOrder.cantidad !==
        params.originalCostes.find((costeInstalacion) => costeInstalacion._id === costeInstalacionOrder._id).cantidad
      ) {
        await upadeCosteInstalacionOrder(token, costeInstalacionOrder._id, costeInstalacionOrder.cantidad);
        params.refetch();
      }
    }),
  );

  // en el estado editCostesInstalacion modificar el campo originalCantidad con la cantidad actual
};

export const handleClickDelete = async (params) => {
  const token = await params.getAccessTokenSilently();

  await deleteCosteInstalacionOrder(token, params.idCosteOrder);
  params.refetch();
};

export const handleClose = (params) => {
  params.setAnchorEl(null);
  params.setOpenMenuIndex(null);
  // params.setArrowRotated(Array(params.costesInstalacion.length).fill(false)); // Cerrar todos los menús al hacer clic fuera de ellos
};

export const handleOpenModalCreateCosteInstalacionOrder = (params) => {
  // redirigimos a la página para crear un coste de instalación
  const hasTrailingSlash = params.location.pathname.endsWith('/');

  const url = hasTrailingSlash
    ? `${params.location.pathname}add-costes-instalacion`
    : `${params.location.pathname}/add-costes-instalacion`;

  params.navigate(url);
};
