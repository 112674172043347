import React, { useState, useContext, Suspense } from 'react';
import { CircularProgress, Pagination } from '@mui/material';
import { getAllArtesFinales } from '../../api/carteleriaApi';
import { ArteFinal } from './ArteFinal';
import { ArteFinalContext } from '../../context/ArteFinalContext';
import { Header } from './Header';
import { FilterMenu } from '../MasterRol/FilterMenu';
import { LIMIT } from '../../utils/constants';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import SkeletonLoader from '../common/SkeletonLoader';
import BreadcrumbCategories from '../Categories/BreadcrumbCategories';

export const DataLoadingFallback = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '60vh',
      width: '100vw',
    }}
  >
    <CircularProgress />
  </div>
);

export const ContainerArte = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [categorySelected, setCategorySelected] = useState([]);
  const { page, setPage, setSuperIdCategory, superIdCategory, centerConfirmed, inputValueSearch } =
    useContext(ArteFinalContext);

  const { data, isFetching } = useQuery(
    ['all-artes-finales-carteleria', page, superIdCategory?._id, centerConfirmed._id, inputValueSearch],
    () => {
      return getAccessTokenSilently().then((token) => {
        return getAllArtesFinales({
          token,
          page,
          limit: LIMIT,
          superIdCategory: superIdCategory?._id,
          centerConfirmed: {
            centerType: centerConfirmed.centerType,
            imagenAnterior: centerConfirmed.imagenAnterior,
            imagenNueva: centerConfirmed.imagenNueva,
            comunidad: centerConfirmed.comunidad._id,
          },
          inputValueSearch,
        });
      });
    },
  );

  const handleMoveBack = (category) => {
    const matchIndex = categorySelected.findIndex((el) => el._id === category._id);
    if (matchIndex !== -1) {
      const newCategorySelected = categorySelected.slice(0, matchIndex + 1);
      if (category._id == 'esenciales') {
        setCategorySelected(newCategorySelected);
        setSuperIdCategory(null);
      } else {
        setCategorySelected(newCategorySelected);
        setSuperIdCategory({
          _id: category._id,
          parentCategory: category.parentCategory,
        });
      }
    }
  };

  const handleReset = () => {
    setCategorySelected([]);
    setSuperIdCategory(null);
  };

  const hanlePagePagination = (event, value) => {
    setPage(value);
  };

  return (
    <div className="container-master-user">
      <Suspense fallback={<div className="custom-loader" />}>
        <BreadcrumbCategories
          categorySelected={categorySelected}
          handleMoveBack={handleMoveBack}
          handleReset={handleReset}
        />
        <Header />
        <div className="container-filter-filter-artes">
          <FilterMenu categorySelected={categorySelected} setCategorySelected={setCategorySelected} />
          {!isFetching ? (
            <ArteFinal artesFinales={data.data} />
          ) : (
            <div className="grid-container">
              {[...Array(36)].map((_, i) => (
                <SkeletonLoader key={i} count={1} minHeight={'471.47px'} />
              ))}
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {data?.totalResults > 0 && !isFetching && (
            <Pagination
              showFirstButton
              showLastButton
              page={page}
              onChange={hanlePagePagination}
              count={Math.ceil(data?.totalResults / LIMIT)}
              color="error"
              variant="outlined"
              size="large"
              sx={{ margin: '2rem' }}
            />
          )}
        </div>
      </Suspense>
    </div>
  );
};
