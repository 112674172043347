import GraficoQuantityVersion from './GraficoQuantityVersion';

export const DataQuantityVersion = () => {
  // Empleando tanStack obtengo toda la data de PP

  return (
    <div style={{ padding: '1rem' }}>
      <h2>Gráfico Cantidad - Version </h2>
      <GraficoQuantityVersion />
    </div>
  );
};
