import React, { createContext } from "react";

export const DetailOrderContext = createContext();

export const DetailOrderProvider = ({ children }) => {

    const [itemOrderIds, setItemOrderIds] = React.useState([]);

    const data = {
        itemOrderIds,
        setItemOrderIds
    }

    return (
        <DetailOrderContext.Provider value={data}>
            {children}
        </DetailOrderContext.Provider>
    )
}