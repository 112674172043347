import React from 'react';
import CustomButton from '../../../../ui/Button/CustomButton';

const ButtonViewPdf = ({ pdf }) => {
  return (
    <a href={pdf} target="_blank">
      <CustomButton>Visualizar</CustomButton>
    </a>
  );
};

export default ButtonViewPdf;
