import { useParams } from 'react-router-dom';
import { useEditFile, useGetFile } from '../../../lib/react-query/fileQuery';
import { useHandleCloseModal } from '../../../hooks/useHelpers';
import { useEffect, useState } from 'react';
import FormFile from './FormFile';

const EditFile = () => {
  const { idFile } = useParams();
  const { handleCloseModal } = useHandleCloseModal(2);
  const { mutation } = useEditFile();
  const { data: file, isLoading } = useGetFile(idFile);

  const [formData, setFormData] = useState({
    _id: '',
    name: '',
    url: '',
    file: null,
    type: 'link',
  });

  useEffect(() => {
    if (!isLoading) {
      setFormData({
        _id: file.data._id,
        name: file.data.name,
        url: file.data.url,
        file: null,
        type: file.data.url.toLowerCase().includes('digitaloceanspaces') ? 'archivo' : 'link',
      });
    }
  }, [isLoading]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      url: file?.data?.url || '',
      file: null,
    }));
  }, [formData.type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(formData);
  };

  return (
    <FormFile
      title={'Editar archivo'}
      isLoading={isLoading}
      formData={formData}
      handleChange={handleChange}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
    />
  );
};

export default EditFile;
