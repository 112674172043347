import { useContext, useEffect, useState } from 'react';
import { GestionProveedorContext } from '../context/GestionProveedorContext';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetGestionProviders,
  useGetProvider,
  useHandleDeleteProvider,
  useHandleEditProvider,
} from '../lib/react-query/providerQuery';

export const useProviders = (limit = 12) => {
  const { inputValueSearch } = useContext(GestionProveedorContext);
  const [activeFilter, setActiveFilter] = useState('all');
  const [page, setPage] = useState(1);
  const { data: providers, isLoading } = useGetGestionProviders({ page, limit, inputValueSearch, activeFilter });

  useEffect(() => {
    setPage(1);
  }, [inputValueSearch]);

  return { providers, activeFilter, setActiveFilter, setPage, isLoading, limit, page };
};

export const useDeleteProvider = () => {
  const [deletePopover, setDeletePopover] = useState(null);
  const [providerId, setProviderId] = useState('');

  const handleDeleteClose = () => {
    setDeletePopover(null);
  };

  const handleDeleteClick = (event) => {
    setProviderId(event.currentTarget.id);
    setDeletePopover(event.currentTarget);
  };

  const { handleDeleteConfirm } = useHandleDeleteProvider(handleDeleteClose, providerId);

  return { deletePopover, handleDeleteClose, handleDeleteClick, handleDeleteConfirm };
};

export const useEditProvider = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: provider, isLoading } = useGetProvider();
  const { mutation } = useHandleEditProvider();

  return { provider, isLoading, mutation, navigate, location };
};
