import React, { createContext, useContext, useState } from 'react';

const DeleteCategoryAuthContext = createContext();

export const DeleteCategoryAuthProvider = ({ children }) => {
  const [authorized, setAuthorized] = useState(false);

  const authorize = () => setAuthorized(true);
  const deauthorize = () => setAuthorized(false);

  return (
    <DeleteCategoryAuthContext.Provider value={{ authorized, authorize, deauthorize }}>
      {children}
    </DeleteCategoryAuthContext.Provider>
  );
};

export const useDeleteCategoryAuth = () => useContext(DeleteCategoryAuthContext);
