import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';
import { formatCurrency } from '../../../utils/formatNumber';

const ReusableBarChart = ({ data, dataKeys, width = 500, height = 300 }) => {
  const [keyLeft, keyRight] = Object.values(dataKeys);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 60,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis
          dataKey="name"
          angle={0}
          textAnchor="middle"
          interval={0}
          tickFormatter={(value) => (value.length > 20 ? `${value.slice(0, 20)}...` : value)}
        />

        <YAxis
          yAxisId="left"
          tickFormatter={(value) => `${formatCurrency(value, 0)}`}
          orientation="left"
          stroke="#e57373"
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="#b0bec5"
          tickFormatter={(value) => `${formatCurrency(value, 0)} €`}
        />

        <Tooltip content={<CustomTooltip dataKeys={dataKeys} />} />
        <Legend />

        {/* Barra para la key izquierda */}
        <Bar yAxisId="left" dataKey={keyLeft} fill="#e57373" fillOpacity={0.8} barSize={30} />

        {/* Barra para la key derecha */}
        <Bar yAxisId="right" dataKey={keyRight} fill="#b0bec5" fillOpacity={0.8} barSize={30} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ReusableBarChart;
