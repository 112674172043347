import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

export const Auth0ProviderWithConfig = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

  if (!(domain && clientId && redirectUri)) {
    throw new Error('Missing Auth0 environment variables');
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      // redirectUri={redirectUri}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: 'https://dev-alcampoapi.com',
      }}
    >
      {children}
    </Auth0Provider>
  );
};
