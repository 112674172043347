import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import { HiDownload } from 'react-icons/hi';
import { generateExcelPilotado } from '../../../helpers/PedidoPilotado/pedidoPilotadoExcel';
import { IconButton } from '@mui/material';
import { formatDate } from '../../../helpers/Utils';
import { IoIosArrowBack } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleClickBackPedidosPilotados } from '../../../helpers/PedidoPilotado/pedidoPilotado';
import { Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';

const HeaderPilotado = ({ admin, createdAt, numCentros, idPilotado }) => {
  const { getAccessTokenSilently } = useAuth0();

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Row style={{ padding: '1rem' }}>
        <Colxx xxs="1">
          <IconButton
            onClick={() => handleClickBackPedidosPilotados(navigate, location)}
            className="back-pedidos-pilotados"
          >
            <IoIosArrowBack />
          </IconButton>
        </Colxx>

        <Colxx xxs="10">
          <div className="header-pilotado-container">
            <div className="header-pilotado-div">
              <span className="header-pilotado-title">Administrador:</span>
              <span className="header-pilotado-text">{admin}</span>
            </div>
            <div className="header-pilotado-div">
              <span className="header-pilotado-title">Nº Tiendas:</span>
              <span className="header-pilotado-text">{numCentros}</span>
            </div>
            <div className="header-pilotado-div">
              <span className="header-pilotado-title">Creado el:</span>
              <span className="header-pilotado-text">{formatDate(createdAt)}</span>
            </div>
            <div className="header-pilotado-div">
              <span className="header-pilotado-title">Descargar excel:</span>
              <IconButton
                onClick={async () => {
                  let token = await getAccessTokenSilently();
                  generateExcelPilotado(token, idPilotado);
                }}
                style={{ display: 'flex', alignItems: 'center', borderRadius: '40px' }}
              >
                <PiMicrosoftExcelLogoFill />
                <HiDownload size="14px" />
              </IconButton>
            </div>
          </div>
        </Colxx>
      </Row>
      <Tooltip anchorSelect=".back-pedidos-pilotados">
        <span>Voler a los pedidos pilotados</span>
      </Tooltip>
    </>
  );
};

export default HeaderPilotado;
