import React, { useState, useEffect } from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

export const Barchart = ({ data, detail }) => {
  console.log(data);
  const [details, setDetails] = useState(detail);
  const [height, setHeight] = useState(550);
  const [width, setWidth] = useState(450);

  useEffect(() => {
    details ? setHeight(600) : setHeight(220);
    details ? setWidth(800) : setWidth(300);
  }, [details]);

  const isDataEmpty = !data || data.length === 0;
  const dynamicTicks = Array.from({ length: 6 }, (_, index) =>
    (index * (Math.max(...data.map((item) => item.value)) / 5)).toFixed(0),
  );

  return (
    <>
      {isDataEmpty ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%', height: '210px' }}
        >
          <p>No hay datos seleccionados</p>
        </div>
      ) : (
        <BarChart
          width={width}
          height={height}
          data={data}
          background="transparent"
          classNameName="w-full lg:w-1/2 pr-0 lg:pr-2"
        >
          <Bar dataKey="value" fill="#dc86b2" stroke="#C66D9B" strokeWidth={3} />
          <CartesianGrid
            stroke="#c6d2d0"
            vertical={false}
            horizontal={true}
            strokeDasharray="2 2"
            strokeDashoffset="2"
          />
          <Tooltip cursor={false} />
          <XAxis dataKey="name" />
          <YAxis dataKey="value" ticks={dynamicTicks} />
        </BarChart>
      )}
    </>
  );
};
