import { createContext, useContext, useEffect, useState } from 'react';
import { ShoppingCartContext } from './ShoppingCartContext';

export const ArteFinalContext_v2 = createContext();

export const ArteFinalProvider_v2 = ({ children, arteFinal }) => {
  const { shoppingCart_v2 } = useContext(ShoppingCartContext);

  const initialFormat = shoppingCart_v2[arteFinal._id]
    ? shoppingCart_v2[arteFinal._id].formatos && Object.keys(shoppingCart_v2[arteFinal._id].formatos).length > 0
      ? Object.values(shoppingCart_v2[arteFinal._id]?.formatos)[0]
      : {
          ...arteFinal.formatos[0],
          arteFinal: arteFinal._id,
        }
    : {
        ...arteFinal.formatos[0],
        arteFinal: arteFinal._id,
      };

  const initialFormatProvider = shoppingCart_v2[arteFinal._id]?.formatos
    ? Object.values(shoppingCart_v2[arteFinal._id].formatos)[0].providerFormat
    : '';

  const initialQuantity = shoppingCart_v2[arteFinal._id]?.formatos
    ? Object.values(shoppingCart_v2[arteFinal._id].formatos)[0].quantity || 0
    : 0;

  const initialMaterial = shoppingCart_v2[arteFinal._id]?.formatos
    ? Object.values(shoppingCart_v2[arteFinal._id].formatos).find((format) => format.formatoCustom)?.material || null
    : null;

  const [formatSelected, setFormatSelected] = useState(initialFormat);
  const [formatProviderSelected, setFormatProviderSelected] = useState(initialFormatProvider);
  const [quantitySelected, setQuantitySelected] = useState(initialQuantity);
  const [materialSelected, setMaterialSelected] = useState(initialMaterial);

  /* -------------------------------------------------------------------------- */
  /*                    ESTADOS PARA CONTROLAR LOS COMPUESTOS                   */
  /* -------------------------------------------------------------------------- */
  const [openModalSelectChildrenFormat, setOpenModalSelectChildrenFormat] = useState(null);
  const [requiredFormatChildren, setRequiredFormatChildren] = useState(true);
  const [providerNotFound, setProviderNotFound] = useState(false);

  // cada vez que cambia el proveedor o el formato actualizar la cantidad seleccionada
  useEffect(() => {
    const providerFormat = shoppingCart_v2[arteFinal._id]?.formatos?.[formatSelected.formatoId];
    if (providerFormat) {
      setFormatProviderSelected(providerFormat.providerFormat);
      setQuantitySelected(providerFormat.quantity);
    } else {
      setQuantitySelected(0);
    }
  }, [formatSelected]);

  const data = {
    formatSelected,
    setFormatSelected,
    formatProviderSelected,
    setFormatProviderSelected,
    quantitySelected,
    setQuantitySelected,
    arteFinal,
    materialSelected,
    setMaterialSelected,
    openModalSelectChildrenFormat,
    setOpenModalSelectChildrenFormat,
    requiredFormatChildren,
    setRequiredFormatChildren,
    providerNotFound,
    setProviderNotFound,
  };

  return <ArteFinalContext_v2.Provider value={data}>{children}</ArteFinalContext_v2.Provider>;
};
