import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHandleUrl } from '../../hooks/useHandleUrl';
import { ViewerContext } from '../../context/ViewerContext';
import { ProyectoContext } from '../../context/ProyectoContext';
import { useQueryClient } from '@tanstack/react-query';

const SelectVersion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { version } = useHandleUrl();
  const [currentVersion, setCurrentVersion] = useState(null);
  const { versions } = useContext(ViewerContext);
  const { availableVersionsByCurrentProject } = useContext(ProyectoContext);

  useEffect(() => {
    if (versions) {
      // console.log('VEAMOS LAS VERSIONES A ELEGIR: ', versions);
      if (version) {
        setCurrentVersion(version);
      } else {
        setCurrentVersion(versions[0].attributes.versionNumber);
      }
    }
  }, [versions]);

  useEffect(() => {
    console.log('A VER PORQUE NO SE ACTUALIZAN LAS VERSIONES DISPONIBLES: ', availableVersionsByCurrentProject);
  }, [availableVersionsByCurrentProject]);

  const handleChange = async (event) => {
    setCurrentVersion(event.target.value);

    navigate({
      pathname: location.pathname,
      search: `?version=${event.target.value}`,
    });

    // queryClient.invalidateQueries(['versions', currentProject.id]);
  };

  if (!versions) return;
  return (
    <div style={{ zIndex: 99, top: '1rem', left: '1rem', position: 'absolute' }}>
      <div
        style={{
          position: 'relative',
          padding: '.5rem 1rem',
          backgroundColor: 'white',
          borderRadius: '.2rem',
          boxShadow: '0px 0px 14px 0px rgba(0,0,0,0.5)',
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        <div>
          <p style={{ marginBottom: '0' }}>Versión:</p>
          <select style={{ cursor: 'pointer' }} value={currentVersion || ''} onChange={handleChange}>
            {versions.map((item) => {
              const versionExists = availableVersionsByCurrentProject.some(
                (version) => version.version === item.attributes.versionNumber && version.existsGeometry,
              );

              const versionNotAvailable = !availableVersionsByCurrentProject.some(
                (version) => version.version === item.attributes.versionNumber,
              );

              let backgroundColor = '#d04d52';
              let color = 'white';

              if (versionExists) {
                backgroundColor = 'white';
                color = 'black';
              } else if (versionNotAvailable) {
                backgroundColor = '#8c9cb9';
              }

              return (
                <option
                  disabled={!versionExists && !versionNotAvailable}
                  style={{
                    backgroundColor: backgroundColor,
                    color: color,
                  }}
                  key={item.attributes.versionNumber}
                  value={item.attributes.versionNumber}
                >
                  {item.attributes.versionNumber}
                </option>
              );
            })}
          </select>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '.4rem' }}>
            <span className="square-legend" />
            <p className="m-0">Versiones sin abrir</p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '.4rem' }}>
            <span className="square-legend" style={{ backgroundColor: '#d04d52' }} />
            <p className="m-0">Versiones no disponibles</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectVersion;
