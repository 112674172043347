import { IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCartUtils } from '../../hooks/useCart';
import SingleOrderItem from './SingleOrderItem';

const AaffCompuesto = ({ aaffCompuesto, idPack }) => {
  const { handleRemovePack } = useCartUtils();

  const namePack = Object.values(aaffCompuesto).find((arteFinal) =>
    Object.values(arteFinal.formatos).some((formato) => formato?.childrenFormat?.format?.length > 0),
  )?.name;

  const required = Object.values(aaffCompuesto).some((arteFinal) =>
    Object.values(arteFinal.formatos).some(
      (formato) => formato.childrenFormat?.format?.length > 0 && formato.childrenFormat.required,
    ),
  );

  return (
    <div style={{ margin: '1rem 0', border: '1px solid #adacac' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '1rem 1rem 0 1rem',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <Typography variant="h5">{namePack}</Typography>
          <IconButton onClick={() => handleRemovePack(idPack)}>
            <DeleteIcon style={{ color: '#D32F2F' }} />
          </IconButton>
        </div>
        <span style={{ fontWeight: 700, fontSize: '1.2rem', marginBottom: '0' }}>
          {Object.values(aaffCompuesto)
            .reduce((acc, arteFinal) => {
              return (
                acc +
                Object.values(arteFinal.formatos).reduce((acc, formato) => {
                  return acc + formato.quantity * formato.formatoPrice;
                }, 0)
              );
            }, 0)
            .toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + '€'}
        </span>
      </div>

      {aaffCompuesto.map((arteFinal) => {
        return (
          <div key={arteFinal.id} style={{ margin: '1rem', display: 'flex', flexDirection: 'column' }}>
            <SingleOrderItem
              required={
                Object.values(arteFinal.formatos).some((formato) => formato?.childrenFormat?.format?.length > 0)
                  ? true
                  : required
              }
              opcional={!required}
              compuesto
              item={arteFinal}
              image={arteFinal.imglink}
            />
          </div>
        );
      })}
    </div>
  );
};

export default AaffCompuesto;
