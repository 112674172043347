import { Card } from '@mui/material';
import { DetailGraphic } from '../Models/DetailGraphic';

export const GrapiCard = ({ areaData, moduleData, shelvesData, type }) => {
  return (
    <>
      <Card sx={{ width: '100%', height: '98%' }}>
        {/* {areaData.length === 0 && (
          <p style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>No hay datos seleccionados</p>
        )} */}
        <DetailGraphic areaData={areaData} moduleData={moduleData} shelvesData={shelvesData} type={type} />
      </Card>
    </>
  );
};
