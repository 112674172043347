import React, { useContext, useEffect } from 'react';
import { ViewerContext } from '../../context/ViewerContext';
import { handleFetchProperties, viewer } from '../../helpers/viewerHelper';
import { PointContext } from '../../context/PointContext';
import SpritesHelpers from './SpritePointsFunction';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const SpritesPoints = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { setPoints, setSelectedPoint, selectedPoint } = useContext(PointContext);
  const location = useLocation();

  const createSprites = async () => {
    let token = await getAccessTokenSilently();
    const points = await handleFetchProperties(viewer, setPoints, token);
    SpritesHelpers.handlePaintSprites(viewer, points, setSelectedPoint, selectedPoint);
  };

  useEffect(() => {
    // Comprobar que la location es mercado.
    // if (!location.pathname.includes('mercado')) {
    //   console.log('HIDE the points');
    //   SpritesHelpers.hideSprites();
    // }

    SpritesHelpers.areSpritesCreated(viewer).then((res) => {
      // comprobar si los sprites están creados
      if (res === true) {
        // Si están creados, activar sprites
        // SpritesHelpers.showSprites();
        console.log('AVAILABLE');

        return;
      } else {
        if (location.pathname.includes('mercado')) {
          createSprites();
        }
      }
    });
  }, [location]);

  // useEffect(() => {
  //   if (!location.pathname.includes('mercado')) {
  //     console.log('HIDE the points');
  //     SpritesHelpers.hideSprites();
  //   }
  // }, [location]);

  return;
};

export default SpritesPoints;
