import { Autocomplete, TextField } from '@mui/material';
import { useGetAllMaterials } from '../../hooks/useMateriales';
import { useContext } from 'react';
import { ArteFinalContext_v2 } from '../../context/ArteFinalContext_v2';
import SkeletonLoader from '../common/SkeletonLoader';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';

const SelectMaterial = () => {
  const { data: allMaterials, isLoading: loadingMaterials } = useGetAllMaterials();
  const { materialSelected, setMaterialSelected, formatSelected, arteFinal } = useContext(ArteFinalContext_v2);
  const { shoppingCart_v2 } = useContext(ShoppingCartContext);

  if (loadingMaterials) {
    return (
      <div className="custom-select-container">
        <SkeletonLoader count={1} minHeight="40px" />
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setMaterialSelected(newValue);
  };

  const isOptionEqualToValue = (option, value) => option._id === value._id;

  return (
    <div className="custom-select-container">
      <Autocomplete
        size="small"
        value={materialSelected}
        onChange={handleChange}
        readOnly={shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId]?.quantity > 0}
        disabled={shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId]?.quantity > 0}
        options={allMaterials.data}
        getOptionLabel={(option) => option.nombre}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => <TextField {...params} label="Selecciona un material" variant="outlined" />}
      />
    </div>
  );
};

export default SelectMaterial;
