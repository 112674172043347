import { useState } from 'react';
import { useDeleteGuiaMerchandising } from '../lib/react-query/fileQuery';

export const useDeleteFile = () => {
  const [deletePopover, setDeletePopover] = useState(null);
  const [fileId, setFileId] = useState('');

  const handleDeleteClose = () => {
    setDeletePopover(null);
  };

  const handleDeleteClick = (event) => {
    setFileId(event.currentTarget.id);
    setDeletePopover(event.currentTarget);
  };

  const { mutation } = useDeleteGuiaMerchandising(fileId);

  const handleDeleteConfirm = () => {
    setDeletePopover(null);
    mutation.mutate();
  };

  return { handleDeleteClick, deletePopover, handleDeleteClose, handleDeleteConfirm };
};
