import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getOrderItemDetail } from '../../api/carteleriaApi';
import { Box, Card, Dialog, DialogContent, DialogTitle, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import OrderItemSpinner from '../../components/Spinners/OrderItemSpinner';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DetailOrderContext } from '../../context/DetailOrderContext';
import CardOrderItem from './CardOrderItem';
import FilterIcon from '@mui/icons-material/Filter';

export const DetailOrderItem = () => {
  const { idOrderItem } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const { itemOrderIds } = useContext(DetailOrderContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [arteFinalImageOpen, setArteFinalImageOpen] = useState(false);
  const [parentImage, setParentImage] = useState(null);

  const { data, isFetching } = useQuery(['orderItem', idOrderItem], async () => {
    const token = await getAccessTokenSilently();
    return await getOrderItemDetail(token, idOrderItem);
  });

  const handleCloseModal = () => {
    // quitar las dos ultimas partes de la url
    const url = location.pathname.split('/').slice(0, -2).join('/');
    navigate(url);
  };

  const closeModalArteFinalImage = () => {
    setIsModalOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: '5rem',
    transform: 'translate(-50%, -50%)',
    minWidth: '800px',
    maxHeight: '90vh',
    overflowY: 'auto',
    bgcolor: '#F8F8F8',
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Modal
        open
        onClose={handleCloseModal}
        // onClose={handleClose}
      >
        <Box sx={{ ...style, padding: '5rem' }}>
          {isFetching && <OrderItemSpinner />}
          {!isFetching && (
            <>
              <ArrowBackIosIcon
                onClick={() => {
                  const index = itemOrderIds.indexOf(idOrderItem);
                  if (index > 0) {
                    const prevId = itemOrderIds[index - 1];
                    navigate(`${location.pathname.replace(idOrderItem, prevId)}`);
                  }
                }}
                aria-disabled
                style={{
                  color:
                    // si es el primer item, deshabilitar el boton
                    itemOrderIds.indexOf(idOrderItem) === 0 ? 'gray' : 'black',
                  cursor: itemOrderIds.indexOf(idOrderItem) === 0 ? 'default' : 'pointer',
                  position: 'absolute',
                  top: '50%',
                  left: '10px',
                }}
              />
              <ArrowForwardIosIcon
                onClick={() => {
                  const index = itemOrderIds.indexOf(idOrderItem);
                  if (index < itemOrderIds.length - 1) {
                    const nextId = itemOrderIds[index + 1];
                    navigate(`${location.pathname.replace(idOrderItem, nextId)}`);
                  }
                }}
                style={{
                  color:
                    // si es el ultimo item, deshabilitar el boton
                    itemOrderIds.indexOf(idOrderItem) === itemOrderIds.length - 1 ? 'gray' : 'black',
                  cursor: itemOrderIds.indexOf(idOrderItem) === itemOrderIds.length - 1 ? 'default' : 'pointer',
                  position: 'absolute',
                  top: '50%',
                  right: '5px',
                }}
              />

              <CloseIcon
                style={{
                  position: 'absolute',
                  top: '1rem',
                  right: '1rem',
                  cursor: 'pointer',
                }}
                onClick={handleCloseModal}
              />

              <div
                style={{
                  padding: '1rem',
                  backgroundColor: '#E4253B',
                  margin: '2rem 0',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '.5rem',
                }}
              >
                <h6 className="MuiTypography-root MuiTypography-h6 css-1anx036" style={{ color: 'white' }}>
                  {' '}
                  {data.data[0].arteFinalName}{' '}
                </h6>
                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                  <h3 className="MuiTypography-root MuiTypography-h3 css-18h9l83" style={{ color: 'white' }}>
                    {data.data[0].formatCode}
                  </h3>
                  {data?.data[0]?.formatChildren?.length > 0 && (
                    <FilterIcon
                      onClick={() => {
                        setParentImage(data.data[0].imglink);
                        setArteFinalImageOpen(true);
                      }}
                      className="open-image-aaff-parent"
                      style={{ color: 'white', outline: 'none' }}
                    />
                  )}
                </div>
              </div>

              {/* AQUI VAMOS A COMPROBAR SI EL ORDER ITEM TIENE formatChildren o no y dependiendo de eso vamos a mostrar una cosa u otra */}
              {data?.data[0].formatChildren?.length > 0 ? (
                <>
                  <CardOrderItem
                    parentImage={parentImage}
                    setParentImage={setParentImage}
                    arteFinalImageOpen={arteFinalImageOpen}
                    setArteFinalImageOpen={setArteFinalImageOpen}
                    item={data.data[0]}
                    quantity={data.data[0].quantity}
                    children
                  />
                  {data.data[0].formatChildren.map((formatChild) => (
                    <CardOrderItem
                      parentImage={parentImage}
                      setParentImage={setParentImage}
                      arteFinalImageOpen={arteFinalImageOpen}
                      setArteFinalImageOpen={setArteFinalImageOpen}
                      children
                      key={formatChild._id}
                      quantity={formatChild?.quantity?.quantity || 1}
                      item={formatChild}
                    />
                  ))}
                </>
              ) : (
                <CardOrderItem
                  parentImage={parentImage}
                  setParentImage={setParentImage}
                  arteFinalImageOpen={arteFinalImageOpen}
                  setArteFinalImageOpen={setArteFinalImageOpen}
                  item={data.data[0]}
                  quantity={data.data[0].quantity}
                />
              )}

              <Card style={{ padding: '1rem' }}>
                <Row>
                  <Colxx xxs="6">
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem', marginBottom: '1rem' }}>
                      <strong>Imagen</strong>
                      {data.data[0]?.image?.link ? (
                        <img
                          onClick={() => setIsModalOpen(true)}
                          src={data.data[0].image.link}
                          alt={data.data[0].image.key}
                          style={{ width: '200px', height: '200px', objectFit: 'cover', cursor: 'pointer' }}
                        />
                      ) : (
                        <p>El usuario no ha añadido ninguna imagen</p>
                      )}
                    </div>
                  </Colxx>
                  <Colxx xxs="6">
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem', marginBottom: '1rem' }}>
                      <strong>Comentario</strong>
                      <p>{data.data[0].comment || 'El usuario no ha dejado ningun comentario'}</p>
                    </div>
                  </Colxx>
                </Row>
              </Card>

              {data?.data[0]?.image?.link && (
                <Dialog
                  open={isModalOpen}
                  onClose={closeModalArteFinalImage}
                  maxWidth="xl"
                  fullScreen
                  PaperProps={{
                    style: {
                      // backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      backgroundColor: '#222222',
                      boxShadow: 'none',
                      overflow: 'hidden',
                    },
                  }}
                >
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={closeModalArteFinalImage}
                    aria-label="close"
                    style={{
                      position: 'absolute',
                      color: '#fff',
                      top: '10px',
                      right: '10px',
                      zIndex: 1,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  {/* <DialogTitle style={{ color: '#fff', textAlign: 'center' }}>
            {' '}
            Vista previa{' '}
          </DialogTitle> */}
                  <DialogContent
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // padding: 0,
                      // height: '100vh',
                    }}
                  >
                    <img
                      crossOrigin="anonymous"
                      src={data.data[0].image.link}
                      alt="Imagen a pantalla completa"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </DialogContent>
                </Dialog>
              )}
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default DetailOrderItem;
