import toast from 'react-hot-toast';
import {
  createCosteInstalacionOrder,
  createOrder,
  createOrderItem,
  uploadFileDigitalOcean,
} from '../../api/carteleriaApi';
import { createCompleteOrder } from '../../api/cartApi';

export const getCostesAsignados = (costesInstalacion, centerConfirmed, totalCostes) => {
  const costes = [];

  switch (centerConfirmed.comunidad.name) {
    case 'Canarias':
      if (totalCostes === 0) {
        return [];
      } else if (totalCostes > 0 && totalCostes < 5) {
        costesInstalacion.data.forEach((coste) => {
          if (coste.localizacion === 'Canarias' && coste.tipoCoste.includes('media jornada')) {
            costes.push(coste);
          }
        });
      } else if (totalCostes >= 5 && totalCostes < 10) {
        costesInstalacion.data.forEach((coste) => {
          if (coste.localizacion === 'Canarias' && coste.tipoCoste.includes('jornada completa')) {
            costes.push(coste);
          }
        });
      } else if (totalCostes >= 10) {
        costesInstalacion.data.forEach((coste) => {
          if (coste.localizacion === 'Canarias' && coste.tipoCoste.includes('jornada completa')) {
            costes.push(coste);
          }
        });
        const jornadasCompletas = Math.ceil((totalCostes - 10) / 10);
        for (let i = 0; i < jornadasCompletas; i++) {
          costesInstalacion.data.forEach((coste) => {
            if (coste.localizacion === 'Canarias' && coste.tipoCoste.includes('jornada completa')) {
              costes.push(coste);
            }
          });
        }
      }
      break;

    default:
      if (totalCostes === 0) {
        return [];
      } else if (totalCostes > 0 && totalCostes < 5) {
        costesInstalacion.data.forEach((coste) => {
          if (coste.localizacion === 'Peninsula' && coste.tipoCoste.includes('media jornada')) {
            costes.push(coste);
          }
        });
      } else if (totalCostes >= 5 && totalCostes <= 10) {
        costesInstalacion.data.forEach((coste) => {
          if (coste.localizacion === 'Peninsula' && coste.tipoCoste.includes('jornada completa 1º dia')) {
            costes.push(coste);
          }
        });
      } else if (totalCostes > 10 && totalCostes <= 20) {
        costesInstalacion.data.forEach((coste) => {
          if (coste.localizacion === 'Peninsula' && coste.tipoCoste.includes('jornada completa 1º dia')) {
            costes.push(coste);
          }
          if (coste.localizacion === 'Peninsula' && coste.tipoCoste.includes('jornada completa 2º y sucesivos')) {
            costes.push(coste);
          }
        });
      } else if (totalCostes > 20) {
        costesInstalacion.data.forEach((coste) => {
          if (coste.localizacion === 'Peninsula' && coste.tipoCoste.includes('jornada completa 1º dia')) {
            costes.push(coste);
          }
        });
        const jornadasCompletas = Math.ceil((totalCostes - 10) / 10);
        for (let i = 0; i < jornadasCompletas; i++) {
          costesInstalacion.data.forEach((coste) => {
            if (coste.localizacion === 'Peninsula' && coste.tipoCoste.includes('jornada completa 2º y sucesivos')) {
              costes.push(coste);
            }
          });
        }
      }
      break;
  }

  return costes;
};

export const getTotalItemsAndCostes = (shoppingCart) => {
  let totalItems = 0;
  let totalItemsCostes = 0;
  for (const key in shoppingCart) {
    if (shoppingCart[key].montaje) {
      for (const key2 in shoppingCart[key].formatos) {
        // para los costes tambien hay que tener en cuenta si tiene hijos
        if (shoppingCart[key].formatos[key2]?.childrenFormat?.format?.length > 0) {
          totalItemsCostes += shoppingCart[key].formatos[key2].childrenFormat.format.reduce(
            (acc, curr) =>
              acc +
              // la quantity es la del formato
              shoppingCart[key].formatos[key2].quantity,
            0,
          );
        } else {
          totalItemsCostes += shoppingCart[key].formatos[key2].quantity;
        }
      }
    }
    for (const key2 in shoppingCart[key].formatos) {
      // si tiene hijos hay que tenerlo en cuenta para la cantidad total
      if (shoppingCart[key].formatos[key2]?.childrenFormat?.format?.length > 0) {
        totalItems += shoppingCart[key].formatos[key2].childrenFormat.format.reduce(
          (acc, curr) =>
            acc +
            // la quantity es la del formato
            shoppingCart[key].formatos[key2].quantity,
          0,
        );
      } else {
        totalItems += shoppingCart[key].formatos[key2].quantity;
      }
    }
  }

  return { totalItems, totalItemsCostes };
};

/* -------------------------------------------------------------------------- */
/*                      FUNCION PARA CREAR UNA SOLICITUD                      */
/* -------------------------------------------------------------------------- */
export const postOrder = async (params) => {
  const orderItems = Object.entries(params.shoppingCart)
    .flatMap(([arteFinalId, arteFinal]) =>
      Object.entries(arteFinal.formatos).map(([formatoId, formato]) => {
        if (formato?.childrenFormat?.format?.length > 0 && formato?.packFormat) {
          const item = {
            formatoId: formato.formatoId,
            format: formato.providerFormat._id,
            formatChildren: Object.entries(params.shoppingCart).flatMap(([_, arteFinal]) =>
              Object.entries(arteFinal.formatos).flatMap(([formatoId, formatoChildren]) =>
                formatoChildren?.packFormat === formato.packFormat && formatoId !== formato.formatoId
                  ? [{ id: formatoChildren.providerFormat._id, quantity: formatoChildren.quantity || 1 }]
                  : [],
              ),
            ),
            quantity: formato.quantity,
            comment: formato.comment,
          };
          if (formato.formatoCustom) {
            item.custom = formato.formatoName;
            // item.material = MATERIAL ELEGIDO POR EL USUARIO
          }
          return item;
        } else if (!formato?.packFormat && !(formato?.childrenFormat?.format?.length > 0)) {
          const item = {
            formatoId: formato.formatoId,
            format: formato.providerFormat._id,
            quantity: formato.quantity,
            comment: formato.comment,
          };
          if (formato.formatoCustom) {
            item.custom = formato.formatoName;
            item.material = formato.material._id;
            // item.material = MATERIAL ELEGIDO POR EL USUARIO
          }
          return item;
        } else {
          return null;
        }
      }),
    )
    .filter((item) => item !== null);

  const orderItemsWithImages = orderItems.map((item) =>
    params.imagesShoppingCart[item.formatoId] ? { ...item, image: params.imagesShoppingCart[item.formatoId] } : item,
  );

  const order = {
    user: {
      id: params.user.sub,
      name: params.user.name || null,
      email: params.user.email,
    },
    center: params.centerConfirmed._id,
    montaje: params.isMontaje,
    contactName: params.nameContactShoppingCart,
    contactPhone: params.phoneContactShoppingCart,
    extension: params.extension,
    orderItems: orderItemsWithImages,
    costesInstalacion: params.isMontaje ? params.costesAsignados : [],
  };

  try {
    // Función para procesar y subir las imágenes
    const processOrderItems = async (orderItemsWithImages) => {
      const processedOrderItems = orderItemsWithImages.map(async (orderItemWithImage) => {
        if (orderItemWithImage.image) {
          const { image, ...rest } = orderItemWithImage;
          let imgName = `${rest.formatoId}-${image.name}`;
          let res = await uploadFileDigitalOcean(image, `userimg/${imgName}`);
          return {
            ...rest,
            image: { link: res.url, key: res.key },
          };
        } else {
          return orderItemWithImage;
        }
      });
      return await Promise.all(processedOrderItems);
    };

    // Procesa los items para subir las imágenes
    const processedOrderItemsWithImages = await processOrderItems(orderItemsWithImages);

    // Actualiza el objeto order con los items procesados
    order.orderItems = processedOrderItemsWithImages;

    // Crea la orden completa
    await createCompleteOrder(params.token, order);
  } catch (error) {
    throw new Error(error);
  }
};
