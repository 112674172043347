import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../context/UserProvider';

const isAuthenticated = (userRole, allowedRoles) => {
  return allowedRoles.includes(userRole);
};

const ProtectedDocumentation = ({ userRoles }) => {
  const { userRole } = useContext(UserContext);

  return isAuthenticated(userRole, userRoles) ? <Outlet /> : <Navigate to="/error" />;
};

export default ProtectedDocumentation;
