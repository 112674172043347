import React, { useState } from 'react';
import InfoLayout from '../layout/ViewerContentComponents/InfoLayout';
import { Row } from 'reactstrap';
import GraphicLayout from '../layout/ViewerContentComponents/GraphicLayout';
import { Colxx } from '../components/common/CustomBootstrap';
import { useLocation } from 'react-router-dom';
import { GrapiCard } from '../components/recycle/GrapiCard';
import Mercado from '../Components2/Sidebar/Mercado';
import TabsMercado from '../Components2/Mercado/TabsMercado';
import { MercadoGraphicProvider } from '../context/MercadoGraphicContext';

const MercadoPage = () => {
  const [graficoArray, setGraficoArray] = useState([1]);
  const location = useLocation();
  // const [openModal, setOpenModal] = useState(false);
  const [areaData, setAreaData] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [shelvesData, setShelvesData] = useState([]);

  return (
    <>
      <MercadoGraphicProvider>
        <InfoLayout>
          <Row style={{ padding: '0', margin: '0', height: '100%' }}>
            <Colxx className="sidebar-tree" xxs="12" style={{ padding: '0' }}>
              <Mercado  />
            </Colxx>
          </Row>
        </InfoLayout>
        <GraphicLayout>
          <TabsMercado />
        </GraphicLayout>
      </MercadoGraphicProvider>
    </>
  );
};

export default MercadoPage;
