import { Typography } from '@mui/material';
import React from 'react';

const NotFormats = () => {
  return (
    <Typography variant="h6" component="div" style={{ marginTop: '4rem', display: 'flex', justifyContent: 'center' }}>
      No hay formatos disponibles
    </Typography>
  );
};

export default NotFormats;
