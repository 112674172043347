import { IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CartItemImageArteFinal from '../Cart/CartItem/CartItemImageArteFinal';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { Tooltip } from 'react-tooltip';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

const ItemArteFinalCarrito = ({
  idArteFinal,
  arteFinal,
  styleContainer,
  styleImg,
  handleDeleteClick,
  compuesto,
  required,
}) => {
  const arteFinalCompuesto = Object.values(arteFinal.formatos).some(
    (formato) => formato?.childrenFormat?.length > 0 && formato?.childrenFormat?.length !== formato?.totalChildren,
  );

  return (
    <div key={idArteFinal} className="container-info-carrito">
      <div
        key={arteFinal.id}
        className="item-arte-final-cart"
        style={{
          '&:hover': {
            transform: 'scale(1.05)',
            transition: 'all 0.3s ease-in-out',
          },
        }}
      >
        <div>
          <>
            {/* SI ES COMPUESTO PONEMOS EL ICONO DE COMPUESTO */}
            {arteFinalCompuesto && (
              <DynamicFeedIcon
                className="children-format-icon"
                style={{
                  userSelect: 'none',
                  outline: 'none',
                  cursor: 'pointer',
                  marginRight: '0.5rem',
                  float: 'right',
                }}
              />
            )}

            {/* imagen del arte final */}
            <div className="d-grid grid-col1-2">
              {/* IR A BUSCAR LA IMAGEN DEL ARTE FINAL */}

              {/* IMPORTAR COMPONENTE DE IMAGEN */}
              <CartItemImageArteFinal
                itemId={arteFinal.id}
                itemCode={arteFinal.code}
                styleContainer={styleContainer}
                styleImg={styleImg}
                img={arteFinal?.imglink}
              />

              {/* NOMBRES DE LOS FORMATOS */}
              <div>
                <Typography style={{ marginBottom: '.2rem' }} variant="body2">
                  {arteFinal.name}
                </Typography>
                <Typography style={{ fontSize: '12px', fontStyle: 'italic' }} variant="body2">
                  Cantidad mínima:{' '}
                  {arteFinal.originalQuantity > arteFinal.minQuantity
                    ? arteFinal.originalQuantity
                    : arteFinal.minQuantity}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {Object.values(arteFinal.formatos).map((formato, index) => {
                    return (
                      <div key={formato + '-' + index}>
                        <div
                          key={formato.formatoId}
                          style={{ width: '100%', display: 'flex', justifyContent: 'space-between', cursor: 'default' }}
                        >
                          {/* AQUI IRA LA IMAGEN DEL ARTE FINAL */}

                          {/* NOMBRE DEL FORMATO, PRECIO, CANTIDAD Y BOTÓN DE ELIMINAR */}
                          <div className={`${formato.formatoCustom ? 'formato-details-custom' : ''}  formato-details`}>
                            {/* Nombre del formato */}
                            <Typography style={{ fontSize: '12px' }} variant="body2">
                              {formato.formatoCustom
                                ? formato.formatoName
                                : `${formato.providerFormat.code} - ${formato.formatoName}`}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
                              <Typography style={{ fontSize: '14px', fontWeight: 'bold' }} variant="body2">
                                {formato.formatoCustom ? 'A determinar' : formato.price.toFixed(2) + ' €'}
                              </Typography>
                              {/* Cantidad y botón de eliminar */}
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '.3rem',
                                }}
                              >
                                <Typography style={{ fontSize: '12px' }} variant="body2">
                                  {formato.quantity < arteFinal.minQuantity ? arteFinal.minQuantity : formato.quantity}
                                </Typography>
                                {(!compuesto || !required) && (
                                  <IconButton
                                    size="small"
                                    onClick={(e) => handleDeleteClick(e, arteFinal.id, formato.formatoId)}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* AQUI DEBAJO INDENTAMOS LOS HIJOS SI LOS TIENE */}
                        {formato?.childrenFormat?.length > 0 &&
                          formato?.childrenFormat?.length !== formato?.totalChildren && (
                            <div style={{ display: 'flex', marginLeft: '.5rem', gap: '.25rem', marginTop: '.5rem' }}>
                              <SubdirectoryArrowRightIcon style={{ color: '#7C7C7C' }} />
                              <div>
                                {formato?.childrenFormat?.map((child, index) => (
                                  <>
                                    <div
                                      key={child.formatoId}
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        cursor: 'default',
                                        marginTop: '.40rem',
                                      }}
                                    >
                                      <div className={'formato-details'}>
                                        <Typography
                                          className={`name-children-format name-children-format-${index}`}
                                          style={{ fontSize: '12px' }}
                                          variant="body2"
                                        >
                                          {child.arteFinal.name}
                                        </Typography>
                                        <Typography style={{ fontSize: '12px' }} variant="body2">
                                          {child.formatoCustom
                                            ? 'A presupuestar'
                                            : `${child.formatoPrice.toFixed(2)} €`}
                                        </Typography>
                                      </div>
                                    </div>
                                    <Tooltip anchorSelect={`.name-children-format-${index}`}>
                                      <span>{child.arteFinal.name}</span>
                                    </Tooltip>
                                  </>
                                ))}
                              </div>
                            </div>
                          )}
                        {/* AHORA RECORRECMOS LOS HIJOS Y MOSTRAMOS CODIGO DEL ARTE, NOMBRE DEL ARTE, SU FORMATO Y PRECIO  */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      {/* TOOLTIP PARA EL ICONO DE COMPUESTO */}
      <Tooltip anchorSelect=".children-format-icon">
        <span>Este arte final es compuesto</span>
      </Tooltip>

      {/* ))} */}
    </div>
  );
};

export default ItemArteFinalCarrito;
