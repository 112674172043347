import React, { useState, useEffect, useRef } from 'react';
import { InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Select } from '@mui/material';
import { addProyectoItem } from '../../api/alcampoApi';
import { toast } from 'react-hot-toast';
import { getHubsTree, getProject, getProjectContents } from '../../api/alcampoApi';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { CancelTwoTone } from '@mui/icons-material';
import { TreeModel } from '../Tree/TreeModel';
import { initTree } from '../Sidebar/sidebar';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const FormProject = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const regex = /urn:adsk\.wipprod:dm\.lineage:([a-zA-Z0-9-]+)/;
  const [refVisible, setRefVisible] = useState(false);
  const [huberId, sethuberId] = useState('');
  const [projectId, setprojectId] = useState('');
  const [folderId, setfolderId] = useState(null);
  const [allData, setAllData] = useState([]);
  const [hub, setHub] = useState([]);
  const [project, setProject] = useState([]);
  const [content, setContent] = useState([]);
  const [point, setPoints] = useState(null);
  const [infoData, setInfoData] = useState([]);
  const [currentFolder, setCurrentFolder] = useState([]);
  const [IsModelLoaded, setIsModelLoaded] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [handleResetPoints, setHandleResetPoints] = useState(null);
  const [seletedUrn, setSeletedUrn] = useState('');
  const [counter, setCounter] = useState(0);
  const [formData, setFormData] = useState({
    code: '',
    name: '',
    item: '',
    description: '',
  });
  const treeContainerRef = useRef(null);

  const createTreeNode = (id, text, parent = null) => {
    return {
      id,
      text,
      parent,
    };
  };
  const isInvalid = formData.item && !formData.item.match(regex);
  useEffect(() => {
    console.log(huberId);
  }, [huberId]);
  const getProyectos = async (id) => {
    let ids = id.split('|')[1]; //obtenemos el id limpio

    console.log(ids);

    const token = await getAccessTokenSilently();
    let proyecto = await getProject(ids, token);
    console.log(proyecto);
    let projectos = [];
    for (let dato in proyecto) {
      setprojectId(proyecto[0].id);
      const nombreProyecto = proyecto[dato].attributes.name;
      const proyectoNode = createTreeNode(`project|${id}|${proyecto[0].id}`, nombreProyecto, `hub|${ids}`);
      projectos.push(proyectoNode);
    }
    setAllData((prevData) => [...prevData, projectos]);
    setProject(projectos);
  };
  const structureAllData = () => {
    //organizar la data
    let todaInfo = [hub, project, content]; // Assuming hub, project, and content are defined elsewhere

    let flattenedTodaInfo = [].concat(...todaInfo);
    console.log('contenido c', content);
    console.log('camilo', flattenedTodaInfo);
    console.log('soria ', allData.flat());
  };
  const organizeContent = (contenido, idhub, idProyecto, folderId) => {
    const contenidoResume = contenido.map((content) => {
      if (content.type === 'folders') {
        return createTreeNode(
          `folder|${idhub}|${idProyecto}|${content.id}`,
          content.attributes.displayName,
          !folderId ? idProyecto : folderId,
        );
      } else {
        return createTreeNode(`item|${idhub}|${idProyecto}|${content.id}`, content.attributes.displayName);
      }
    });
    console.log(contenidoResume);
    return contenidoResume;
  };
  const renderProject = (item) => {
    // Verifica si el id del proyecto coincide con el id del hub actual
    if (item.id.split('|')[2] === huberId) {
      return (
        <TreeItem
          key={item.id}
          nodeId={item.id}
          label={item.text}
          onClick={() => getAllContent(huberId, projectId, item.id)}
        >
          {content.map((item, index) => {
            return <TreeItem key={index} nodeId={index} label={` ${item.attributes.displayName}`}></TreeItem>;
          })}
        </TreeItem>
      );
    }
    // Si no coinciden, elimina el proyecto del TreeView
    return null;
  };
  function buildTree(data) {
    const tree = new Set();

    data.forEach((item) => {
      if (Array.isArray(item)) {
        // Si es un array, significa que es un conjunto de elementos.
        const subtree = buildTree(item);
        tree.add(subtree);
      } else {
        // Si no es un array, es un elemento individual.
        const node = {
          type: item.type,
          id: item.id,
          attributes: item.attributes,
        };
        tree.add(node);
      }
    });

    return tree;
  }

  const getAllContent = async (idhub, idProyecto, folderId, folderIndex) => {
    if (folderIndex > counter) {
      setCounter(folderIndex);
    } else {
      setCounter(counter + 1);
    }

    let token = await getAccessTokenSilently();

    let contenido;
    if (folderId) {
      contenido = await getProjectContents(token, idhub, idProyecto, folderId);
    } else {
      contenido = await getProjectContents(token, huberId, projectId);
    }

    let arr = [];
    contenido.map((elm) => {
      arr.push({ id: elm.id, index: counter, name: elm.attributes.displayName });
    });

    console.log('contennnniiiddo ', contenido);
    let organizado = organizeContent(contenido, idhub, idProyecto, folderId);
    console.log('organizacion ' + organizado);
    console.log(contenido);
    // setContent([...content, await getProjectContents(idhub, idProyecto,folderId)]);
    setContent(organizado);
    setCurrentFolder(arr);
    console.log('cantona ', contenido);
    if (folderId) {
      console.log('subFolder ', contenido);
    }
    console.log('contador ', counter);

    setAllData((prevData) => [...prevData, organizado]);
  };
  useEffect(() => {
    console.log('milito ', content);
    const tree = buildTree(content);
    structureAllData();
    console.log('arbol', tree);
  }, [content]);
  useEffect(() => {
    if (refVisible) {
      const selector = treeContainerRef.current;
      getAccessTokenSilently().then((token) => 
      initTree(
        token,
        selector,
        (id) => navigate(`/viewer?id=${window.btoa(id).replace(/=/g, '')}`),
        setIsModelLoaded,
        setPoints,
        setSelectedPoint,
        selectedPoint,
      )
      );
    }
  }, [refVisible]);
  useEffect(() => {
    console.log('urn', seletedUrn);
    formData.item = seletedUrn;
  }, [seletedUrn]);
  useEffect(() => {
    console.log(currentFolder);
  }, [currentFolder]);
  const getData = async () => {
    let token = await getAccessTokenSilently();
    let data = await getHubsTree(token);
    const nombreProyecto = data[0].attributes.name;
    console.log(nombreProyecto);
    console.log(data);
    let infoHub = [];
    for (let dato in data) {
      const nombreProyecto = data[dato].attributes.name;
      console.log('me ejecuto');
      sethuberId(data[dato].id);
      const huber = createTreeNode(`hub|${data[dato].id}`, nombreProyecto);
      infoHub.push(huber);
    }
    setAllData((prevData) => [...prevData, infoHub]);
    console.log(infoHub);
    setHub(infoHub);
  };
  useEffect(() => {
    console.log('guardiola ', seletedUrn);
    setFormData((prevFormData) => ({
      ...prevFormData,
      item: seletedUrn,
    }));
  }, [seletedUrn]);
  useEffect(() => {
    console.log('toda la info al mismo nivel', allData);
  }, [allData]);
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    console.log('kiliam npm ', currentFolder);
  });
  useEffect(() => {
    if (refVisible) {
      const selector = treeContainerRef.current;
      getAccessTokenSilently().then((token) =>
      initTree(
        token,
        selector,
        (id) => navigate(`/viewer?id=${window.btoa(id).replace(/=/g, '')}`),
        setIsModelLoaded,
        handleResetPoints,
        setPoints,
        setSelectedPoint,
        selectedPoint,
        setSeletedUrn,
      )
      );

    }
  }, [refVisible]);

  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [hasError, setHasError] = useState(false);

  const clearForm = () => {
    setFormData({
      code: '',
      name: '',
      item: '',
      description: '',
    });
    setSeletedUrn('');
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendData = async (event) => {
    event.preventDefault();
    let correct = true; // Inicializamos correct como true

    // Verificamos si alguno de los campos está vacío
    if (!formData.code || !formData.name || !formData.item) {
      console.log(formData);
      correct = false;
      toast.error('Los campos code, name o item no pueden estar vacíos');
    } else {
      const found = formData.item.match(regex);
      console.log(found);

      if (!found) {
        correct = false;
        toast.error('El campo item no cumple con el formato requerido');
      }
    }

    if (correct) {
      let token = await getAccessTokenSilently();
      const mandato = await addProyectoItem(formData, token);

      if (mandato.status === 'success') {
        console.log('todo correcto');
        clearForm();
        toast.success('Proyecto agregado correctamente');
      } else {
        toast.error('Error de creación de proyecto');
      }
    }

    console.log('veredicto', correct);
  };

  console.log('llegue aqui formulario');
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
          padding: '20px',
          border: '1px solid #ddd',
          borderRadius: '10px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
        }}
        onSubmit={sendData}
      >
        <h2 style={{ textAlign: 'center', marginBottom: '2px' }}>Creación de nuevo proyecto</h2>

        {/* TextField Components */}
        <TextField
          label="Codigo"
          variant="outlined"
          name="code"
          id="outlined-error-helper-text"
          defaultValue="Hello World"
          value={formData.code}
          onChange={handleInputChange}
          error={formData.code.length > 0 && formData.code.length < 2}
          helperText={
            formData.code.length > 0 && formData.code.length < 2 ? 'Tienes que ingresar más de 2 caracteres.' : ''
          }
          required
          style={{ marginBottom: '15px' }}
        />

        <TextField
          label="Nombre"
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          error={formData.name.length > 0 && formData.name.length < 2}
          helperText={
            formData.name.length > 0 && formData.name.length < 2 ? 'Tienes que ingresar más de 2 caracteres.' : ''
          }
          required
          style={{ marginBottom: '15px' }}
        />

        <TextField
          label={seletedUrn.length > 0 ? 'Item' : ''}
          variant="outlined"
          name="item"
          value={seletedUrn}
          onChange={handleInputChange}
          required
          error={isInvalid}
          helperText={isInvalid ? 'Escribe la URN correcta' : ''}
          style={{ marginBottom: '15px', backgroundColor: seletedUrn.length > 0 ? '#FFF' : '#F5F5F5' }}
          disabled
        />
        <InputLabel htmlFor="my-input">Escoge un item (acaban en .rvt)</InputLabel>

        <div
          style={{
            height: '200px',
            paddingLeft: '1rem',
            width: '100%',
            overflow: 'auto', // Agrega un scroll vertical
            paddingBottom: '2rem',
            // Añade padding a la derecha para evitar que el contenido toque el borde
          }}
          ref={(el) => {
            treeContainerRef.current = el;
            setRefVisible(!!el);
          }}
        />
        <TextField
          style={{ marginBottom: '15px' }}
          name="description"
          label="Descripción"
          multiline
          rows={2}
          maxRows={4}
          value={formData.description}
          onChange={handleInputChange}
        />

        <button
          type=""
          style={{
            backgroundColor: isButtonHovered ? '#2E2E2E' : '#2E2E2E',
            borderRadius: '5px',
            border: 'none',
            color: 'white',
            padding: '10px',
            fontSize: '16px',
            cursor: 'pointer',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.3s, transform 0.3s',
          }}
          onMouseEnter={() => setIsButtonHovered(true)}
          onMouseLeave={() => setIsButtonHovered(false)}
        >
          Crear Proyecto
        </button>
      </form>
    </div>
  );
};
