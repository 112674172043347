import React from "react"
import ContentLoader from "react-content-loader"

const OrderItemSpinner = (props) => (
<ContentLoader 
    speed={2}
    width={677}
    height={613}
    viewBox="0 0 677 613"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="-25" y="9" rx="0" ry="0" width="1218" height="82" /> 
    <rect x="-63" y="128" rx="0" ry="0" width="813" height="177" /> 
    <rect x="398" y="274" rx="0" ry="0" width="19" height="8" /> 
    <rect x="2" y="346" rx="0" ry="0" width="826" height="301" />
  </ContentLoader>
)

export default OrderItemSpinner

