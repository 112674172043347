import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { handleClickUpdateOrder } from "../../../helpers/Admin/costesInstalacionHelper";
import { Button, Popover } from "@mui/material";

const UpdateCostesInstalacionButton = ({ refetch, originalCostes, setOriginalCostes, editOriginalCostes, setEditOriginalCostes,  getAccessTokenSilently }) => {
    return (
        // si alguno de los costes  tiene como cantidad 0 mostrar este popover si no mostrar el boton normal
        editOriginalCostes.some((costeInstalacionOrder) => costeInstalacionOrder?.cantidad === 0
        ) ? (
        <PopupState variant="popover" popupId="demo-popup-popover">
			{(popupState) => (
				<div>
				  <button 
                    // onClick={() => handleClickUpdateOrder({ getAccessTokenSilently, editCostesInstalacion, setEditCostesInstalacion })} 
                    style={{
                        opacity: '0.85', 
                        borderColor: 'transparent',  
                        backgroundColor: 
						// que la cantidad de originalCostes sea igual a la cantidad de editOriginalCostes
						originalCostes.every((costeInstalacionOrder) =>
							editOriginalCostes.some((costeInstalacionOrderEdit) => costeInstalacionOrderEdit?.cantidad === costeInstalacionOrder?.cantidad)
                        ) ? '#868788' : '#E0001A'
                    }} 
                    disabled={originalCostes.every((costeInstalacionOrder) =>
						editOriginalCostes.some((costeInstalacionOrderEdit) => costeInstalacionOrderEdit?.cantidad === costeInstalacionOrder?.cantidad)
                    )} 
                    className='btn btn-primary'
                    {...bindTrigger(popupState)}>
                    Actualizar costes
                </button>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "center",
							horizontal: "left",
						}}
					>
						<div style={{ padding: "1rem" }}>
							<p style={{ fontSize: ".75rem" }}>
								Si actualiza con la cantidad en 0, se eliminará el coste de instalación ¿Desea continuar?
							</p>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									gap: "1rem",
									marginTop: ".5rem",
								}}
							>
								<Button
									variant="contained"
									style={{
										fontSize: ".7rem",
										padding: "5px 5px",
										minWidth: "0",
                    backgroundColor: '#898989',
									}}
									onClick={() => {
										// handleRemoveProperty(id);
										// addModulo()
										popupState.setOpen(false);
									}}
								>
									No
								</Button>
								<Button
									variant="outlined"
									style={{
										fontSize: ".75rem",
										padding: "5px 5px",
										minWidth: "0",
										background: "#E4253B",
                    borderColor: 'transparent',
                    color: '#fff'
									}}
									onClick={() => {
										handleClickUpdateOrder({ refetch, setOriginalCostes, originalCostes,
                                             getAccessTokenSilently, editOriginalCostes, setEditOriginalCostes });
										popupState.setOpen(false);
									}}
								>
									Sí
								</Button>
							</div>
						</div>
					</Popover>
				</div>
			)}
		</PopupState>
        ) : (
            <button 
                    onClick={() => handleClickUpdateOrder({ refetch, getAccessTokenSilently, editOriginalCostes, setEditOriginalCostes, originalCostes, setOriginalCostes })} 
                    style={{
                        opacity: '0.85', 
                        borderColor: 'transparent',  
                        backgroundColor: originalCostes.every((costeInstalacionOrder) =>
						editOriginalCostes.some((costeInstalacionOrderEdit) => costeInstalacionOrderEdit?.cantidad === costeInstalacionOrder?.cantidad)
                        ) ? '#868788' : '#E0001A'
                    }} 
                    disabled={originalCostes.every((costeInstalacionOrder) =>
						editOriginalCostes.some((costeInstalacionOrderEdit) => costeInstalacionOrderEdit?.cantidad === costeInstalacionOrder?.cantidad)
                    )} 
                    className='btn btn-primary'>
                    Actualizar costes
                </button>
        )
    )
};

export default UpdateCostesInstalacionButton;