import { Autocomplete, Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import FormatoCompuesto from './FormatCompuesto';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useGetAllMaterials } from '../../../hooks/useMateriales';

const FormFormatInfo = ({ formik, onClose }) => {
  const { data: allMaterials, isLoading: loadingMaterials } = useGetAllMaterials();

  const handleAutocompleteChange = (event, newValue) => {
    formik.setFieldValue('material', newValue ? newValue._id : '');
  };

  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        align="center"
        sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
      >
        Información del formato a crear
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="code"
              label="Código *"
              value={formik.values.code}
              onChange={formik.handleChange}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="name"
              label="Medida"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                options={loadingMaterials ? [] : allMaterials?.data || []}
                getOptionLabel={(option) => option.nombre || ''}
                value={allMaterials?.data?.find((material) => material._id === formik.values.material) || null}
                onChange={handleAutocompleteChange}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                renderInput={(params) => (
                  <TextField required {...params} label="Material" variant="outlined" fullWidth />
                )}
                noOptionsText="Sin material"
                loading={loadingMaterials}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormatoCompuesto />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
          <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
            Continuar
          </Button>
          <Button variant="contained" color="error" endIcon={<CloseIcon />} onClick={onClose}>
            Cancelar
          </Button>
        </div>
      </form>
    </>
  );
};

export default FormFormatInfo;
