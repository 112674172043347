import React, { useContext, useState } from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Button, IconButton, Modal } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import toast from 'react-hot-toast';
import { uploadImageArteFinalToDo } from '../../../api/carteleriaApi';
import { useParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { MasterContext } from '../../../context/MasterContext';
import CustomButton from '../../../ui/Button/CustomButton';

const imageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

const UploadImageArteFinal = ({ userRole }) => {
  const { idArte } = useParams();
  const queryClient = useQueryClient();
  const { refetch } = useContext(MasterContext);
  const { getAccessTokenSilently } = useAuth0();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpenModal = () => setModalOpen(true);
  const handleChangeImage = (event) => {
    const file = event.target.files[0];
    if (!imageTypes.includes(file.type)) {
      toast.error('El archivo no es una imagen válida. Formatos válidos: jpg, jpeg, png');
      return;
    }

    if (file.size > 3000000) {
      toast.error('El archivo es demasiado pesado. Máximo 3MB');
      return;
    }
    setSelectedImage(file);
    setModalOpen(true);
  };

  const handleUploadImage = async () => {
    if (!selectedImage) return toast.error('Selecciona una imagen');
    try {
      const { data } = queryClient.getQueryData(['info-arte-final', idArte]);
      const codeAf = data.code;
      const formData = new FormData();
      formData.append('imagen', selectedImage);
      formData.append('acl', 'public-read');
      formData.append('code', codeAf);
      const token = await getAccessTokenSilently();

      await uploadImageArteFinalToDo(idArte, formData, token);
      setSelectedImage(null);
      setModalOpen(false);
      queryClient.invalidateQueries(['info-arte-final', idArte]);
      refetch();
    } catch (e) {
      console.error(e);
      toast.error('Error al subir la imagen. Inténtelo de nuevo');
    }
  };

  return (
    <>
      <div className="button-edit-arte-final">
        {userRole === 'MasterAdmin' && (
          <CustomButton onClick={handleOpenModal}>
            <AddPhotoAlternateIcon style={{ cursor: 'pointer', color: 'white' }} />
          </CustomButton>
        )}
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            background: 'white',
            borderRadius: '.2rem',
            padding: '2rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <h1>Subir imagen nueva</h1>
          <Button
            variant="contained"
            style={{
              background: 'white',
              color: 'black',
              border: '1px solid #fafafa',
              display: 'flex',
              justifyContent: 'flex-start',
              gap: '.5rem',
            }}
            component="label"
          >
            <AttachFileIcon color="#7f7f7f" style={{ rotate: '30deg' }} />
            {selectedImage ? selectedImage?.name : 'Selecciona una imagen'}
            <input type="file" hidden onChange={handleChangeImage} accept=".jpg, .jpeg, .png" />
          </Button>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" color="error" style={{ marginTop: '1rem' }} onClick={() => setModalOpen(false)}>
              Cancelar
            </Button>
            <Button variant="contained" color="error" style={{ marginTop: '1rem' }} onClick={handleUploadImage}>
              Subir
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadImageArteFinal;
