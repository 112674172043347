import React from 'react';
import { Typography } from '@mui/material';
import { MdOutlineDragIndicator, MdDeleteOutline } from 'react-icons/md';
import { useDraggableItem } from '../../../hooks/useDragAndDrop';

const DraggableCategory = ({ category, index, moveCategory, handleDelete, isNewCategory }) => {
  const [ref, isDragging] = useDraggableItem(index, moveCategory, isNewCategory);

  return (
    <div className="subcategory-row" style={{ opacity: isDragging ? 0.5 : 1 }}>
      {isNewCategory ? (
        <MdOutlineDragIndicator className="drag-icon" style={{ cursor: 'grab' }} />
      ) : (
        <div className="drag-icon" />
      )}
      <div
        ref={ref}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '2rem',
          cursor: isNewCategory ? 'grab' : 'default',
          flex: 2,
        }}
      >
        <Typography>{category.name}</Typography>
        {category.new && <Typography style={{ color: 'green', fontStyle: 'italic' }}>Nueva</Typography>}
      </div>
      {category.new && (
        <MdDeleteOutline onClick={() => handleDelete(index)} className="delete-new-subcategory-icon" size="20px" />
      )}
    </div>
  );
};

export default DraggableCategory;
