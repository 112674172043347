import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export const useCloseModal = (position) => {
  const navigate = useNavigate();
  const location = useLocation();

  const closeModal = useCallback(() => {
    let pathParts = location.pathname.split('/');

    if (pathParts[pathParts.length - 1] === '') {
      pathParts.pop();
    }

    const newLength = pathParts.length - position;

    if (newLength < 1) {
      navigate('/');
    } else {
      navigate(pathParts.slice(0, newLength).join('/'));
    }
  }, [navigate, location.pathname, position]);

  return closeModal;
};
