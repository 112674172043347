import React, { useContext } from 'react';
import { ProyectoContext } from '../../../context/ProyectoContext';
import Viewer from '../../../helpers/Viewer/Viewer';
import { useAuth0 } from '@auth0/auth0-react';

const RegistradosButton = () => {
  const { getAccesTokenSilently } = useAuth0();
  const { currentProject } = useContext(ProyectoContext);

  const handleOnClick = async () => {
    let token = await getAccesTokenSilently();
    const viewerData = new Viewer(currentProject._id);
    viewerData.getDbIdsFromBD(token, currentProject._id);
  };
  return (
    <button className="viewer-button" onClick={handleOnClick}>
      Registrados
    </button>
  );
};

export default RegistradosButton;
