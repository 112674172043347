import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useGetAllCostesInstalacion } from '../../hooks/useCosteInstalacion';
import { getCostesAsignados, getTotalItemsAndCostes } from '../../helpers/Cart/cartHelper';
import { ArteFinalContext } from '../../context/ArteFinalContext';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import toast from 'react-hot-toast';
import { usePostOrder } from '../../hooks/useCart';

const FooterCart = () => {
  const { data: costesInstalacion, isLoading } = useGetAllCostesInstalacion();
  const { centerConfirmed } = useContext(ArteFinalContext);
  const { totalPrice, shoppingCart_v2 } = useContext(ShoppingCartContext);
  const { mutation } = usePostOrder();
  const [nameContactShoppingCart, setNameContactShoppingCart] = useState('');
  const [phoneContactShoppingCart, setPhoneContactShoppingCart] = useState('');
  const [extension, setExtension] = useState('');
  const [isMontaje, setIsMontaje] = useState(false);
  const [loadingCostes, setLoadingCostes] = useState(false);
  const [costesAsignados, setCostesAsignados] = useState([]);
  const [totalCostes, setTotalCostes] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (isLoading) return;
    if (!costesInstalacion || !centerConfirmed) return;

    setLoadingCostes(true);

    const costes = getCostesAsignados(costesInstalacion, centerConfirmed, totalCostes);
    setCostesAsignados(costes);

    setLoadingCostes(false);
  }, [totalCostes, isLoading, centerConfirmed, costesInstalacion]);

  useEffect(() => {
    let { totalItems, totalItemsCostes } = getTotalItemsAndCostes(shoppingCart_v2);
    setTotalItems(totalItems);
    setTotalCostes(totalItemsCostes);
  }, [shoppingCart_v2]);

  const handleOnClick = () => {
    if (!nameContactShoppingCart || !phoneContactShoppingCart) {
      toast.error('Por favor, rellena los campos de contacto', {
        duration: 2000,
        position: 'top-center',
      });
      return;
    }
    mutation.mutate({ isMontaje, nameContactShoppingCart, phoneContactShoppingCart, extension, costesAsignados });
  };

  return (
    <div className="footer-cart-container">
      {/* Nombre del contacto */}
      <div className="d-flex fd-c gap-1">
        <div>
          <p className="m-0 fs-1">Nombre del contacto*</p>
          <input
            value={nameContactShoppingCart}
            onChange={(e) => setNameContactShoppingCart(e.target.value)}
            type="text"
          />
        </div>
        <div>
          <p className="m-0 fs-1">Número de contacto*</p>
          <input
            value={phoneContactShoppingCart}
            onChange={(e) => setPhoneContactShoppingCart(e.target.value)}
            type="number"
          />
        </div>
        <div>
          <p className="m-0 fs-1">Extension</p>
          <input value={extension} onChange={(e) => setExtension(e.target.value)} type="text" />
        </div>
        <div className="mt-1">
          <p className="m-0 fs-1">¿Quiere que se realice el montaje?</p>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={isMontaje}
              name="radio-buttons-group"
              onChange={(e) => setIsMontaje(e.target.value === 'true')}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={`Sí ( ${
                  !loadingCostes
                    ? ' El coste aproximado de montaje es de: ' +
                      costesAsignados
                        .reduce((acc, coste) => {
                          return acc + coste.coste;
                        }, 0)
                        .toFixed(2) +
                      ' € '
                    : 'Calculando costes... '
                })`}
              />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {/* Numero del contacto */}
      <div className="d-flex fd-c gap-1 ai-fe">
        <div className="d-flex fd-c ai-fs w-100">
          <div className="d-flex ai-c jc-sb w-100">
            <p className="fs-1">
              Subtotal {`(${totalItems > 1 ? totalItems + ' productos' : totalItems + ' producto'})`}:{' '}
            </p>
            <p className="fs-1">{`${totalPrice.toFixed(2)} €`}</p>
          </div>
          {
            // si esta el montaje activado, mostramos el coste del montaje si no no
            isMontaje && (
              <div className="d-flex ai-c jc-sb w-100">
                <p className="fs-1">Montaje: </p>
                <p className="fs-1">{`${costesAsignados
                  .reduce((acc, coste) => {
                    return acc + coste.coste;
                  }, 0)
                  .toFixed(2)} €`}</p>
              </div>
            )
          }
        </div>

        <div className="d-flex ai-center gap-2 jc-fe">
          <p className="fw-b fs-1-3">Importe total: </p>
          <p className="fw-b fs-1-3 c-red">{`${
            isMontaje
              ? (
                  totalPrice +
                  costesAsignados.reduce((acc, coste) => {
                    return acc + coste.coste;
                  }, 0)
                ).toFixed(2)
              : totalPrice.toFixed(2)
          } €`}</p>
        </div>
        {/* <div> */}
        <div className="ta-r">
          <Button variant="contained" color="error" onClick={handleOnClick}>
            Enviar solicitud
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FooterCart;
