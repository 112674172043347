import { useContext } from 'react';
import TableAdmin from '../../common/TableAdmin';
import { Button, Pagination } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { handleClickAddPedidoPilotado } from '../../../helpers/PedidoPilotado/pedidoPilotado';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ManualUsoButton from '../../../ComponentsCarteleria/MasterRol/ManualUsoButton';
import FilterSolicitudesAdmin from '../Orders/FilterSolicitudesAdmin';
import FilterPilotadosAdmin from '../Pilotados/FilterPilotadosAdmin';

const TabStructure = ({
  title,
  isLoading,
  isFetching,
  data,
  page,
  setPage,
  limitPerPage,
  pilotados = false,
  context,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isOrdersRoute = location.pathname == '/admin/orders';
  const { setOrderFilter } = useContext(context);

  return (
    <div style={{ height: 'calc(100vh - 100px)' }}>
      {/* COMPONENTE CON PESTAÑAS */}
      <div style={{ height: !pilotados && '74px' }} className="container-title-pilotados">
        <h2 style={{ margin: '0', paddingLeft: '1rem' }}>{title}</h2>
        {isOrdersRoute && (
          <div style={{ marginRight: '1rem' }}>
            <ManualUsoButton path="/manual-admin/doc" />
          </div>
        )}
        {pilotados && (
          <Button
            variant="contained"
            color="error"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => handleClickAddPedidoPilotado(navigate, location)}
            aria-label="add"
            style={{
              margin: '1rem',
              height: '2.5rem',
            }}
          >
            Pedido pilotado
          </Button>
        )}
      </div>
      <div className="grid-clusters-centers-container">
        {pilotados ? <FilterPilotadosAdmin /> : <FilterSolicitudesAdmin />}
        <div className="grid-item-clusters-centers-table">
          <div className="core">
            {isLoading || (isFetching && data?.data?.length == 0) ? (
              <div className="custom-loader"></div>
            ) : (
              <TableAdmin pilotados={pilotados} setOrderFilter={setOrderFilter} listOrder={data?.data} />
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {data?.total > 10 && (
              <Pagination
                showFirstButton
                showLastButton
                page={page}
                onChange={(e, v) => setPage(v)}
                count={Math.ceil(data?.total / 10)}
                color="error"
                variant="outlined"
                size="large"
                sx={{ margin: '2rem' }}
              />
            )}
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default TabStructure;
