import React from 'react';
import ModalAddPedidoPilotado from './ModalAddPedidoPilotado';
import { useLocation, useNavigate } from 'react-router-dom';

const AddPedidoPilotado = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCloseAddModalPedidoPilotado = () => {
    navigate(location.pathname.replace('/add-pedido-pilotado', ''));
  };

  return (
    <>
      <ModalAddPedidoPilotado onClose={handleCloseAddModalPedidoPilotado} />
    </>
  );
};

export default AddPedidoPilotado;
