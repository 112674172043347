import { Modal } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import { useGetClustersByMerchandising } from '../../../lib/react-query/fileQuery';
import { useCloseModal } from '../../../hooks/useCloseModal';
import { TbPointFilled } from 'react-icons/tb';

const ErrorDeleteMerchandising = () => {
  const { merchandisingName, clustersAsociated, isLoading } = useGetClustersByMerchandising();
  const closeModal = useCloseModal(2);

  if (isLoading) return;

  return (
    <Modal open onClose={closeModal}>
      <div className="create-cluster-container-modal">
        <IoMdClose onClick={closeModal} size="18px" className="f-right pointer" />
        <div className="center-form">
          <span className="d-flex ai-center g-05">
            <p>La siguiente guía: </p>
            <p className="fw-bold fs-italic">{merchandisingName}</p>
          </span>
          <p className="fs-italic">{`No se ha podido eliminar, debido a que esta asociada a los siguientes clusters:`}</p>
          <div className="aaff-asociated fd-column d-flex g-05">
            {clustersAsociated.map((cluster, i) => (
              <div className="d-flex ai-center g-05" key={i}>
                <TbPointFilled />
                {cluster.type}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorDeleteMerchandising;
