import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeletePopover from '../../common/DeletePopover';
import { useAuth0 } from '@auth0/auth0-react';
import useDeleteCenter from '../../../hooks/useDeleteCenter';
import TooltipItem from '../../../components/common/TooltipItem';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const fields = [
  { field: 'codigo' },
  { field: 'nombre' },
  { field: 'calle' },
  { field: 'municipio' },
  { field: 'provincia' },
  { field: 'comunidad' },
  { field: 'Imagen Anterior' },
  { field: 'Imagen Nueva' },
  { field: 'cluster' },
  { field: '' },
];

function TableCenters({ centers, isLoading, limit, page, setPage }) {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { deletePopover, handleDeleteClose, handleDeleteConfirm, handleDeleteClick } = useDeleteCenter();
  return (
    <>
      {centers?.data?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {fields?.map((field, index) => (
                  <StyledTableCell className="font-size-07-responsive" align="center" key={index}>
                    {field.field.toUpperCase()}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {centers?.data?.map((center, index) => (
                <React.Fragment key={center._id + index}>
                  <StyledTableRow
                    style={{ background: center?.active || center?.active === null ? 'initial' : 'rgb(201, 201, 201)' }}
                  >
                    <StyledTableCell className="font-size-07-responsive">{center?.code}</StyledTableCell>
                    <StyledTableCell className="font-size-07-responsive">{center?.name}</StyledTableCell>
                    <StyledTableCell className="font-size-07-responsive">{center?.calle}</StyledTableCell>
                    <StyledTableCell className="font-size-07-responsive">{center?.municipio}</StyledTableCell>
                    <StyledTableCell className="font-size-07-responsive">{center?.provincia}</StyledTableCell>
                    <StyledTableCell className="font-size-07-responsive">{center?.comunidad?.name}</StyledTableCell>
                    <StyledTableCell className="font-size-07-responsive">
                      {center?.imagenAnterior ? 'Si' : 'No'}
                    </StyledTableCell>
                    <StyledTableCell className="font-size-07-responsive">
                      {center?.imagenNueva ? 'Si' : 'No'}
                    </StyledTableCell>
                    <StyledTableCell className="font-size-07-responsive">{center?.centerType?.type}</StyledTableCell>
                    <StyledTableCell className="font-size-07-responsive">
                      <div className="container-icons-edit-delete-master">
                        <EditIcon
                          className="tooltip-edit-center-icon"
                          style={{ cursor: 'pointer' }}
                          onClick={() => navigate(`${center._id}/edit-center`)}
                        />
                        <DeleteForeverIcon
                          id={center._id}
                          onClick={handleDeleteClick}
                          className="tooltip-delete-center-icon"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <DeletePopover
                    textCancel={'No'}
                    textConfirm={'Sí'}
                    textQuestion={
                      '¿Estás seguro de que deseas eliminar este centro? Una vez eliminado, no se podrá volver a utilizar en la aplicación.'
                    }
                    handleDeleteClose={handleDeleteClose}
                    deletePopover={deletePopover}
                    handleDeleteConfirm={handleDeleteConfirm}
                  />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <h3 style={{ fontWeight: 'bolder' }}>No hay solicitudes para los filtros seleccionados</h3>
      )}

      {/* TOOLTIP PARA LOS ICONOS */}
      <TooltipItem content="Editar centro" className="tooltip-edit-center-icon" position="top" />
      <TooltipItem content="Eliminar centro" className="tooltip-delete-center-icon" position="top" />
    </>
  );
}

export default TableCenters;
