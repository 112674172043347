import { Card } from '@mui/material';
import CartItemAddComment from './CartItem/CartItemAddComment';
import CartItemUploadFile from './CartItem/CartItemUploadFile';
import CartItemQuantity from './CartItem/CartItemQuantity';
import CartItemImageArteFinal from './CartItem/CartItemImageArteFinal';
import ChildrenFormat from './CartItem/ChildrenFormat';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { Tooltip } from 'react-tooltip';
import { useGetTotalByItem } from '../../hooks/useCart';

const SingleOrderItem = ({ required, item, image, compuesto, opcional }) => {
  const { totalByItem } = useGetTotalByItem(item);

  return (
    <>
      <Card
        style={{
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          marginBottom: '.2rem',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
        }}
      >
        <div style={{ display: 'flex', gap: '2rem' }}>
          <CartItemImageArteFinal
            itemId={item.id}
            itemCode={item.code}
            height={300}
            width={200}
            styleContainer={{
              width: '200px',
              height: '300px',
              border: '1px solid #e5e5e5',
            }}
            img={image}
            styleImg={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />

          <div style={{ margin: '.2rem 0', flex: '1' }}>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '.2rem' }}
            >
              <div style={{ display: 'flex', gap: '1.5rem', alignItems: 'center' }}>
                <p style={{ fontWeight: 700, fontSize: '1.075rem', marginBottom: '0' }}>{item.name}</p>
                {Object.values(item.formatos).some(
                  (formato) =>
                    formato?.childrenFormat?.format?.length > 0 &&
                    formato?.totalChildren !== formato?.childrenFormat?.format?.length,
                ) && (
                  <DynamicFeedIcon
                    style={{ userSelect: 'none', outline: 'none', cursor: 'pointer' }}
                    className={
                      // si alguno de sus formatos tiene childrenFormat, se muestra el icono
                      Object.values(item.formatos).some((formato) => formato?.childrenFormat?.format?.length > 0) &&
                      `children-format-icon-${item.id}`
                    }
                  />
                )}
              </div>
              <span
                style={
                  !compuesto
                    ? { fontWeight: 700, fontSize: '1.2rem', marginBottom: '0' }
                    : { color: 'rgba(0, 0, 0, 0.54)', fontSize: '18px' }
                }
              >
                {totalByItem.toFixed(2)}€
              </span>
            </div>
            <span style={{ fontStyle: 'italic' }}>
              Cantidad mínima: {item.originalQuantity > item.minQuantity ? item.originalQuantity : item.minQuantity}
            </span>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '2rem 0 2rem 2rem' }}>
              {/* SECCION PARA EL FORMATO */}
              {Object.values(item.formatos).map((formato, index) => (
                <div key={index}>
                  <div
                    style={{
                      margin: '.5rem 0',
                      // SI NO ES EL ULTIMO FORMATO, APLICO UNA LINEA SUTIL DE SEPARACION
                      borderBottom: index !== Object.values(item.formatos).length - 1 ? '1px solid #e5e5e5' : 'none',
                    }}
                  >
                    <p style={{ fontSize: '1rem', marginBottom: '.35rem', fontWeight: '700' }}>
                      {formato.formatoCustom
                        ? `${formato.formatoName} (Personalizado)`
                        : `${formato.providerFormat.code} - ${formato.formatoName}`}
                    </p>
                    <p style={{ fontSize: '1rem', marginBottom: '1rem', fontWeight: '700' }}>
                      {!formato.formatoCustom && `Proveedor: ${formato.providerFormat.provider.name}`}
                    </p>

                    <p style={{ marginBottom: '.4rem', fontSize: '1rem' }}>{`Material: ${formato.formatoMaterial}`}</p>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '.4rem' }}>
                      <p style={{ marginBottom: 0, fontSize: '1rem' }}>{`Precio/unidad: ${
                        formato.formatoCustom
                          ? 'A presupuestar'
                          : formato?.childrenFormat?.format?.length > 0 &&
                            formato?.totalChildren !== formato.childrenFormat.format.length
                          ? `${
                              formato.childrenFormat.format
                                .map((child) => child.formatoPrice)
                                .reduce((acc, curr) => acc + curr, 0)
                                .toFixed(2) + ' €'
                            }`
                          : `${formato.price.toFixed(2)}€`
                      }`}</p>
                      {!formato.formatoCustom && (
                        <p style={{ marginBottom: 0, fontSize: '1rem' }}>{`Total: ${
                          formato?.childrenFormat?.format?.length > 0 &&
                          formato?.totalChildren !== formato.childrenFormat.format.length
                            ? `${
                                formato.childrenFormat.format
                                  .map((child) => child.formatoPrice)
                                  .reduce((acc, curr) => acc + curr, 0)
                                  .toFixed(2) + ' €'
                              }`
                            : `${(formato.price * formato.quantity).toFixed(2)}€`
                        }`}</p>
                      )}
                      <CartItemQuantity
                        required={!opcional}
                        children={!compuesto || !required}
                        item={item}
                        formato={formato}
                      />
                      <CartItemAddComment item={item} formato={formato} />
                      <CartItemUploadFile formato={formato} />
                    </div>
                    {formato?.childrenFormat?.length > 0 &&
                      formato?.childrenFormat?.length !== formato?.totalChildren && (
                        <ChildrenFormat quantity={formato.quantity} childrenFormats={formato.childrenFormat} />
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>

      <Tooltip anchorSelect={`.children-format-icon-${item.id}`}>
        <span>Este arte final es compuesto</span>
      </Tooltip>
    </>
  );
};

export default SingleOrderItem;
