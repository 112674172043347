import React, { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { useContext } from 'react';
import { PartidasContext } from '../../context/PartidasContext';
import { ProyectoContext } from '../../context/ProyectoContext';

const handleNumberOfPages = (total, limit) => {
  return Math.ceil(total / limit);
};

const PaginationPartidas = ({ setLoadingPartidas }) => {
  const { currentPartidasProject } = useContext(ProyectoContext);
  const { currentPage, setCurrentPage, LIMIT: limit } = useContext(PartidasContext);

  useEffect(() => {
    console.log('VEAMOS LO QUE TRAE CURRENT PARTIDAS PROJECT: ', currentPartidasProject);
  }, [currentPartidasProject]);

  const numberOfPages = useMemo(
    () => handleNumberOfPages(currentPartidasProject.total, limit),
    [currentPartidasProject.total, limit],
  );


  const handleOnClick = async (e, page) => {
    // setLoadingPartidas();
    setCurrentPage(page);
  };

  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [location.pathname]);

  if (currentPartidasProject.total < limit) return;
  return (
    <div className="container-pagination">
      <Stack spacing={2}>
        <Pagination
          count={numberOfPages}
          page={currentPage}
          showFirstButton
          showLastButton
          className="pagination-cards"
          onChange={(e, page) => handleOnClick(e, page)}
        />
      </Stack>
    </div>
  );
};

export default PaginationPartidas;
