import { createContext, useEffect, useState } from 'react';

export const ShoppingCartContext = createContext();

export const ShoppingCartProvider = ({ children }) => {
  const [shoppingCart_v2, setShoppingCart_v2] = useState(JSON.parse(localStorage.getItem('shoppingCart_v2')) || {});
  const [totalPrice, setTotalPrice] = useState(0);
  const [aaffCompuestos, setAaffCompuestos] = useState({});
  const [aaffNoCompuestos, setAaffNoCompuestos] = useState([]);
  const [imagesShoppingCart, setImagesShoppingCart] = useState({});

  useEffect(() => {
    localStorage.setItem('shoppingCart_v2', JSON.stringify(shoppingCart_v2));
    let total = 0;
    for (const key in shoppingCart_v2) {
      for (const key2 in shoppingCart_v2[key].formatos) {
        total += shoppingCart_v2[key].formatos[key2].quantity * shoppingCart_v2[key].formatos[key2].price || 0;
      }
    }
    setTotalPrice(total);

    const newAaffCompuestos = {};
    const newAaffNoCompuestos = [];

    Object.entries(shoppingCart_v2).forEach(([idArteFinal, arteFinal]) => {
      const namePack = arteFinal?.packArteFinal;

      if (!!namePack) {
        if (newAaffCompuestos[namePack]) {
          newAaffCompuestos[namePack].push(arteFinal);
        } else {
          newAaffCompuestos[namePack] = [arteFinal];
        }
      } else {
        newAaffNoCompuestos.push(arteFinal);
      }
    });

    setAaffCompuestos(newAaffCompuestos);
    setAaffNoCompuestos(newAaffNoCompuestos);
  }, [shoppingCart_v2]);

  const data = {
    shoppingCart_v2,
    setShoppingCart_v2,
    totalPrice,
    setTotalPrice,
    aaffCompuestos,
    setAaffCompuestos,
    aaffNoCompuestos,
    setAaffNoCompuestos,
    imagesShoppingCart,
    setImagesShoppingCart,
  };

  return <ShoppingCartContext.Provider value={data}>{children}</ShoppingCartContext.Provider>;
};
