import { useQuery } from '@tanstack/react-query';
import { getStatsMoreOrders, getStatsNoOrders } from '../../api/statsApi';
import { useState } from 'react';

export const useGetMoreOrders = () => {
  return useQuery({
    queryKey: ['stats-more-orders'],
    queryFn: () => getStatsMoreOrders(),
  });
};

export const useGetNoOrders = () => {
  const [page, setPage] = useState(1);

  const { data, isLoading } = useQuery({
    queryKey: ['stats-no-orders', page],
    queryFn: () => getStatsNoOrders(page),
  });

  return { data, isLoading, setPage, page };
};
