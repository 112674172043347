import { Autocomplete, TextField } from '@mui/material';
import { useEffect } from 'react';
import useQueryParams from '../../../hooks/useQueryParams';
import { useGetAllCenters, useGetCenterStats } from '../../../lib/react-query/centerQuery';
import SelectTypeChart from './SelectTypeChart';
import TypeCharts from './TypeCharts';

const MoreOrdersCenter = () => {
  const [{ center, chart }, handleParam] = useQueryParams(['center', 'chart']);
  const { data: centers, isLoading: isLoadingCenters } = useGetAllCenters();
  const { data: centerStats, isLoading: isLoadingStats, isError } = useGetCenterStats();
  const selectedCenter = centers?.data.find((c) => c._id === center) || null;

  useEffect(() => {
    if (!chart) {
      handleParam({ chart: 'bar' });
    }
  }, [chart, handleParam]);

  const handleCenterChange = (e, value) => {
    if (!value) return handleParam({}, ['center']);
    handleParam({ center: value._id });
  };

  const renderChartOrMessage = () => {
    if (!center) {
      return <h3 className="mt-5">Selecciona un centro para ver los artículos más solicitados</h3>;
    }

    if (isLoadingStats) {
      return <div className="custom-loader" />;
    }

    if (isError) {
      return <h3 className="mt-5">Este centro no existe o está inactivo</h3>;
    }

    if (centerStats?.data?.length > 0) {
      return <TypeCharts type={chart} data={centerStats.data} keys={{ cantidad: 'Cantidad', importe: 'Importe' }} />;
    }

    return <h3 className="mt-5">No se ha solicitado ningún artículo para este centro</h3>;
  };

  return (
    <>
      <div className="display-sb-c">
        <Autocomplete
          disabled={isLoadingCenters}
          className="w-30"
          value={selectedCenter} // Controlar correctamente el valor
          onChange={handleCenterChange}
          options={centers?.data || []}
          getOptionLabel={(option) => `${option.code} - ${option.name}`}
          renderInput={(params) => <TextField {...params} label="Selecciona un centro" variant="outlined" />}
        />
        {centerStats?.data?.length > 0 && <SelectTypeChart />}
      </div>
      {renderChartOrMessage()}
    </>
  );
};

export default MoreOrdersCenter;
