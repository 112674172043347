import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getPedidosPilotados } from '../../../api/carteleriaApi';
import TabStructure from './TabStructure';
import { PilotadosContext } from '../../../context/PilotadosContext';
import { Outlet } from 'react-router-dom';

const TabPilotado = () => {
  const { centersFilter, createStartDateFilter, createEndDateFilter, orderFilter, setOrderFilter } =
    useContext(PilotadosContext);

  const { getAccessTokenSilently } = useAuth0();

  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['pedidos-pilotados', { page, centersFilter, createStartDateFilter, createEndDateFilter }],
    async () => {
      const token = await getAccessTokenSilently();
      return await getPedidosPilotados(token, page, 10, {
        order: orderFilter,
        centro: centersFilter,
        createStartDate: createStartDateFilter,
        createEndDate: createEndDateFilter,
      });
    },
  );

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [centersFilter, createStartDateFilter, createEndDateFilter]);

  useEffect(() => {
    refetch();
  }, [orderFilter]);

  return (
    <>
      <TabStructure
        title="Pedidos pilotados"
        pilotados={true}
        context={PilotadosContext}
        data={data}
        isFetching={isFetching}
        isLoading={isLoading}
        limitPerPage={Math.ceil(data?.total / 10) || 0}
        page={page}
        setPage={setPage}
      />
      <Outlet />
    </>
  );
};

export default TabPilotado;
