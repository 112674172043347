import React, { useEffect, useState, useContext } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { useLocation } from 'react-router-dom';
import { ProyectoContext } from '../../context/ProyectoContext';
import CapitulosPartidasProyecto from '../Sidebar/CapitulosPartidasProyecto';
import NewPartida from '../Sidebar/NewPartida';
import { useQuery } from '@tanstack/react-query';
import { getPartidasProyectoByProyectoId } from '../../api/alcampoApi';
import { PartidasContext } from '../../context/PartidasContext';
import DataProyecto from '../Sidebar/DataProyecto';
import { useAuth0 } from '@auth0/auth0-react';

const Main = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { setFetchingPartidasProject, setCurrentPartidasProject, currentProject } = useContext(ProyectoContext);
  const { setPartidaQuery, partidaQuery, currentPage, LIMIT: limit } = useContext(PartidasContext);
  const {
    data: partidasProject,
    isLoading,
    refetch,
  } = useQuery(
    ['current-partidas-project', currentProject?._id],
    () => {
      return getAccessTokenSilently().then((token) =>
        getPartidasProyectoByProyectoId(currentProject._id, partidaQuery, token),
      );
    },
    {
      enabled: !!currentProject?._id,
    },
  );

  useEffect(() => {
    refetch();
  }, [partidaQuery]);

  useEffect(() => {
    setPartidaQuery({ ...partidaQuery, currentPage: currentPage, limit: limit });
  }, [currentPage, limit]);

  const [openModal, setOpenModal] = useState(false);
  const [typeData, setTypeData] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    if (partidasProject) {
      setCurrentPartidasProject(partidasProject);
    }
  }, [partidasProject]);

  useEffect(() => {
    {
      isLoading ? setFetchingPartidasProject(true) : setFetchingPartidasProject(false);
    }
  }, [isLoading]);
  const data2 = [
    { name: 'A1', value: 100 },
    { name: 'A2', value: 300 },
    { name: 'B1', value: 100 },
    { name: 'B2', value: 80 },
    { name: 'B3', value: 40 },
    { name: 'B4', value: 30 },
    { name: 'B5', value: 50 },
    { name: 'C1', value: 100 },
    { name: 'C2', value: 200 },
    { name: 'D1', value: 150 },
    { name: 'D2', value: 50 },
  ];
  const data1 = [
    { name: 'A1', value: 200 },
    { name: 'A2', value: 350 },
    { name: 'B1', value: 120 },
    { name: 'B2', value: 90 },
    { name: 'B3', value: 60 },
    { name: 'B4', value: 40 },
    { name: 'B5', value: 80 },
    { name: 'C1', value: 180 },
    { name: 'C2', value: 250 },
    { name: 'D1', value: 170 },
    { name: 'D2', value: 70 },
  ];

  const data3 = [
    { name: 'A1', value: 1 },
    { name: 'A2', value: 2 },
    { name: 'B1', value: 2 },
    { name: 'B2', value: 120 },
    { name: 'B3', value: 60 },
    { name: 'B4', value: 45 },
    { name: 'B5', value: 70 },
    { name: 'C1', value: 130 },
    { name: 'C2', value: 180 },
    { name: 'D1', value: 200 },
    { name: 'D2', value: 70 },
  ];
  useEffect(() => {
    typeData == 'dato1' && setData(data1);
    typeData == 'dato2' && setData(data2);
    typeData == 'dato3' && setData(data3);
  }, [typeData]);

  const changeData = (value) => {
    setTypeData(value);
    console.log(data);
  };

  return (
    <>
      {/* LISTADO DE CAPITULOS Y PARTIDAS */}
      <Row style={{ padding: '0', margin: '0', height: '100%' }}>
        <Colxx className="sidebar-tree" xxs="12" style={{ padding: '0' }}>
          <div
            style={{
              backgroundColor: 'white',
              // paddingTop: '1rem',
              height: '100%',
              minWidth: 'fit-content',
              width: '100%',
              // overflow: "auto",
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '.4rem',
                marginBottom: '2rem',
                padding: '0 1rem',
              }}
            >
              {/* APARTADO PARA INDICAR EL PROYECTO, EXPORTAR A EXCEL Y MOSTRAR EL IMPORTE TOTAL DE ESTE MISMO */}
              <DataProyecto />
            </div>
            <CapitulosPartidasProyecto openModal={() => setOpenModal(true)} />
          </div>
        </Colxx>
      </Row>
      {openModal && <NewPartida handleClose={() => setOpenModal(false)} open={openModal} />}
    </>
  );
  // );
};

export default Main;
