import { useParams } from 'react-router-dom';
import { useCloseEditArteFinal, useGetEditArteFinal } from '../../../hooks/useArteFinal';
import EditFormArteFinalImprimible from './ArteFinalInfo/EditFormArteFinalImprimible';
import EditFormArteFinal from './ArteFinalInfo/EditFormArteFinal';
import { useGetAllCenterTypes } from '../../../hooks/useCenterType';
import { Modal } from '@mui/material';

const EditArteFinal = () => {
  const { idArte } = useParams();
  const { data, isFetching } = useGetEditArteFinal(idArte);
  const { handleClose } = useCloseEditArteFinal();
  const { data: allCenterTypes } = useGetAllCenterTypes();

  if (isFetching) return null;

  return (
    <Modal open onClose={handleClose}>
      <div className="create-arte-final-container-modal">
        {data.data.arteFinal.isDescargable ? (
          <EditFormArteFinalImprimible data={data.data} allCenterTypes={allCenterTypes} onClose={handleClose} />
        ) : (
          <EditFormArteFinal data={data.data} allCenterTypes={allCenterTypes} onClose={handleClose} />
        )}
      </div>
    </Modal>
  );
};

export default EditArteFinal;
