import { Modal } from '@mui/material';
import TitleModal from '../../common/TitleModal';
import { handleClose } from '../../../helpers/Category/gestionCategories';
import { useNavigate } from 'react-router-dom';
import { useCategories } from '../../../hooks/useCategory';
import SkeletonLoader from '../../common/SkeletonLoader';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import FormAddCategory from './FormAddCategory';
import { postNewCategories } from '../../../api/categorieApi';

const AddCategory = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { categories, isLoading } = useCategories();
  const mutation = useMutation(postNewCategories, {
    onSuccess: () => {
      queryClient.invalidateQueries(['gestion-categories']);
    },
  });

  return (
    <Modal open onClose={() => handleClose(navigate)}>
      <div className="create-cluster-container-modal">
        <TitleModal title={'CREAR CATEGORÍA'} />
        {isLoading ? (
          <SkeletonLoader count={1} minHeight="350px" />
        ) : (
          <FormAddCategory mutation={mutation} categories={categories.data} handleClose={() => handleClose(navigate)} />
        )}
      </div>
    </Modal>
  );
};

export default AddCategory;
