import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  checkDeleteCategory,
  editCategory,
  getAaffAvailable,
  getCantMoveCategory,
  getCategoriesTreeByArte,
  getDeleteCategory,
  getGestionCategories,
  getGestionSubcategories,
  getParentCategoryName,
  getSubcategoriesExist,
  moveAaffAndCategory,
} from '../api/categorieApi';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeleteCategoryAuth } from '../context/DeleteCategoryAuthContext';
import { useCloseModal } from './useCloseModal';
import { GestionCategoryContext } from '../context/GestionCategoryContext';

export const useGetCategoriesTreeByArte = (idArte) => {
  return useQuery({
    queryKey: ['categories-tree-by-arte', idArte],
    queryFn: () => getCategoriesTreeByArte(idArte),
  });
};

export const useCategories = () => {
  const { data: categories, isLoading } = useQuery(['gestion-categories'], getGestionCategories);

  return { categories, isLoading };
};

export const useSubcategories = (parentCategory, suspense = true) => {
  const { data: subcategories, isLoading } = useQuery(
    ['gestion-categories', parentCategory],
    () => getGestionSubcategories(parentCategory),
    {
      suspense: suspense,
    },
  );

  return { subcategories, isLoading };
};

export const useParentCategoryName = (parentCategory) => {
  const { data: parentCategoryName, isLoading } = useQuery(['parent-category-name', parentCategory], () =>
    getParentCategoryName(parentCategory),
  );

  return { parentCategoryName, isLoading };
};

export const useEditCategoryMutation = (idCategory) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(editCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries(['gestion-categories']);
      queryClient.invalidateQueries(['parent-category-name', idCategory]);
    },
  });

  return { mutation };
};

export const useSubcategoriesExist = (parentCategory) => {
  const { data: isSubcategoriesEmpty } = useQuery(
    ['subcategories-exist', parentCategory],
    () => getSubcategoriesExist(parentCategory),
    {
      suspense: true,
    },
  );

  return { isSubcategoriesEmpty };
};

export const useAaffAvailable = (idCategory) => {
  const [page, setPage] = useState(1);
  const [valueSearchAfter, setValueSearchAfter] = useState(null);
  const {
    data: aaffAvailable,
    isLoading,
    isFetching,
  } = useQuery(['aaff-available', idCategory, page, valueSearchAfter], () =>
    getAaffAvailable(idCategory, page, valueSearchAfter),
  );

  return { aaffAvailable, isLoading, isFetching, setPage, page, setValueSearchAfter, valueSearchAfter };
};

export const useCheckDeleteCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [deletePopover, setDeletePopover] = useState(null);
  const [categoryId, setCategoryId] = useState('');
  const { authorize } = useDeleteCategoryAuth();

  const handleDeleteClose = () => {
    setDeletePopover(null);
  };

  const handleDeleteClick = (event) => {
    setCategoryId(event.currentTarget.id);
    setDeletePopover(event.currentTarget);
  };

  const { mutate: handleDeleteConfirm } = useMutation({
    mutationFn: async () => {
      handleDeleteClose();
      await checkDeleteCategory(categoryId);
    },
    onSuccess: () => {
      authorize();
      setTimeout(() => {
        navigate(`${location.pathname}/delete-category/${categoryId}`);
      }, 100);
    },
    onError: (error) => {
      if (error.status === 'error-delete-aaff-associated') {
        toast.error(error.message);
      }
    },
  });

  return { deletePopover, handleDeleteClose, handleDeleteClick, handleDeleteConfirm };
};

export const useGetDeleteCategory = (idCategory) => {
  const { authorized } = useDeleteCategoryAuth();
  const { data: categories, isLoading } = useQuery(
    ['get-delete-category', idCategory],
    () => getDeleteCategory(idCategory),
    {
      enabled: authorized,
    },
  );

  return { categories, isLoading };
};

export const useCantMoveCategory = (idCategory, idParentCategory) => {
  // TRAEMOS EL NOMBRE DE LAS DOS CATEGORIAS
  const { data, isLoading } = useQuery(['cant-move-category', idCategory, idParentCategory], () =>
    getCantMoveCategory(idCategory, idParentCategory),
  );

  return {
    categoryTree: data?.categoryTree?.data,
    categoryMove: data?.categoryNames?.data?.categoryName,
    categoryToMove: data?.categoryNames?.data?.parentCategoryName,
    isLoading,
  };
};

export const useMoveAaffAndCategory = () => {
  const { setCategoryMove } = useContext(GestionCategoryContext);
  const closeModal = useCloseModal(3);
  const queryClient = useQueryClient();
  const mutation = useMutation(moveAaffAndCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries('gestion-categories');
      queryClient.invalidateQueries('subcategories-exist');
      setCategoryMove(null);
      closeModal();
      toast.success('Categoría movida con éxito');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return { mutation };
};
