import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { isNullOrEmptyString } from '../../../../utils/textFunctions';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import cls from 'classnames';
import UploadPdfToArteFinal from './UploadPdfToArteFinal';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { uploadPdfArteFinalToDo } from '../../../../api/arteFinalApi';

const InputPdf = ({ pdf, language }) => {
  const { idArte } = useParams();
  const existsPdf = !isNullOrEmptyString(pdf);
  const pdfName = existsPdf ? pdf.split('/').pop() : 'No hay pdf asociado';
  const [selectedFile, setSelectedFile] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const inputFileRef = useRef();
  const FILE_TYPE = ['application/pdf'];
  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!FILE_TYPE.includes(file.type)) {
      return toast.error('El archivo no es un pdf. Selecciona un archivo pdf');
    }
    let formData = new FormData();

    formData.append('image', file);
    formData.append('MAX_FILE_SIZE', '500000000'); // 500 MB

    const MAX_FILE_SIZE = '500000000'; // 500 MB
    formData.append('MAX_FILE_SIZE', MAX_FILE_SIZE);

    if (file.size > MAX_FILE_SIZE) {
      return toast.error(`Archivo demasiado grande. Límite ${Number(MAX_FILE_SIZE) / 1000000}MB`);
    }

    setSelectedFile(file);
  };

  const assignColor = () => {
    if (selectedFile) return 'green';
    if (!existsPdf) return '#d32f2f';
    return;
  };

  const handleUploadImage = async () => {
    if (!selectedFile) return toast.error('Selecciona un pdf');

    try {
      const formData = new FormData();
      formData.append('pdf', selectedFile);
      formData.append('acl', 'public-read');
      formData.append('language', language);
      const token = await getAccessTokenSilently();

      await uploadPdfArteFinalToDo(idArte, formData, token);
      setSelectedFile(null);
      queryClient.invalidateQueries({
        queryKey: ['pdf-languages', idArte],
      });
      toast.success('Pdf subido correctamente');
    } catch (e) {
      console.error(e);
      toast.error('Error al subir el pdf. Inténtelo de nuevo');
    }
  };
  return (
    <div className="buttons-pdf-container">
      <button className="button-pdf-download-container">
        <PictureAsPdfIcon style={{ color: assignColor() }} />
        <div className={cls('button-text-pdf', !existsPdf && 'button-not-pdf', selectedFile && 'button-pdf-confirm')}>
          {selectedFile?.name || pdfName}
        </div>

        <input type="file" className="button-upload-file--imprimibles" onChange={handleFileChange} ref={inputFileRef} />
      </button>
      {selectedFile && <UploadPdfToArteFinal handleUpload={handleUploadImage} />}
    </div>
  );
};

export default InputPdf;
