import { useNavigate } from 'react-router-dom';
import { useDeleteMateriales } from '../../../hooks/useMateriales';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { StyledTableCell, StyledTableRow } from '../../../Components2/RecycledComponents';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeletePopover from '../../common/DeletePopover';
import TooltipItem from '../../../components/common/TooltipItem';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';

const fields = [{ field: 'material' }, { field: 'activo' }, { field: '' }];

const TableMateriales = ({ materiales }) => {
  const navigate = useNavigate();
  const { deletePopover, handleDeleteClick, handleDeleteClose, handleDeleteConfirm } = useDeleteMateriales();
  return (
    <>
      {materiales?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {fields?.map((field, index) => (
                  <StyledTableCell align="left" key={index}>
                    {field?.field?.toUpperCase()}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {materiales?.map((material, index) => (
                <React.Fragment key={material._id + index}>
                  <StyledTableRow
                    style={{
                      background: material?.active || material?.active === null ? 'initial' : 'rgb(201, 201, 201)',
                    }}
                  >
                    <StyledTableCell align="left">
                      <p className="cell-clusters">{material?.nombre}</p>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p className="cell-clusters">{material?.active ? 'Activo' : 'No activo'}</p>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className="container-icons-edit-delete-master">
                        <EditIcon
                          className="tooltip-edit-material-icon"
                          style={{ cursor: 'pointer' }}
                          onClick={() => navigate(`${material?._id}/edit-materiales`)}
                        />
                        <DeleteForeverIcon
                          id={material._id}
                          onClick={handleDeleteClick}
                          className="tooltip-delete-material-icon"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <DeletePopover
                    textCancel={'No'}
                    textConfirm={'Sí'}
                    textQuestion={
                      '¿Estás seguro de que deseas eliminar este material? Una vez eliminado, no se podrá volver a utilizar en la aplicación.'
                    }
                    handleDeleteClose={handleDeleteClose}
                    deletePopover={deletePopover}
                    handleDeleteConfirm={handleDeleteConfirm}
                  />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <h3 style={{ fontWeight: 'bolder' }}>No hay materiales para los filtros seleccionados</h3>
      )}

      {/* TOOLTIP PARA LOS ICONOS */}
      <TooltipItem content="Editar material" className="tooltip-edit-material-icon" position="top" />
      <TooltipItem content="Eliminar material" className="tooltip-delete-material-icon" position="top" />
    </>
  );
};

export default TableMateriales;
