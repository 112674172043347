import { Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ItemArteFinalCarrito from './ItemArteFinalCarrito';
import { useContext, useState } from 'react';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteFormat from './DeleteFormat';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ConfirmarPedido } from './ConfirmarPedido';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const ResumenPedido_v2 = ({ toggleDrawer }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const navigate = useNavigate();
  const {
    aaffCompuestos,
    setAaffCompuestos,
    setAaffNoCompuestos,
    aaffNoCompuestos,
    shoppingCart_v2,
    setShoppingCart_v2,
    totalPrice,
  } = useContext(ShoppingCartContext);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [deletePopover, setDeletePopover] = useState(null);
  const [confirmarPedidoPopover, setConfirmarPedidoPopover] = useState(null);

  const handleDeleteClick = (event, idArteFinal, idFormato, packArteFinal, packFormat) => {
    setSelectedFormat({ idArteFinal, idFormato });
    if (packArteFinal) {
      setSelectedFormat((prev) => ({ ...prev, packArteFinal }));
    }
    if (packFormat) {
      setSelectedFormat((prev) => ({ ...prev, packFormat }));
    }
    setDeletePopover(event.currentTarget);
  };

  const createOrderAndOrderItems = async (token, data) => {
    if (!data) {
      return;
    }

    try {
      const { data: responseData } = await axios.post(
        '/api/v1/order/allordersitembyorder',
        data,
        // {
        //   order,
        //   orderItems,
        // },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return responseData;
    } catch (error) {
      console.log('Error al hacer el POST DEL CARRITO', error);
    }
  };

  const handleSendAlert = async () => {
    const orderItems = Object.entries(shoppingCart_v2)
      .map(([arteFinalId, arteFinal]) => {
        // aqui tenemos que traer la imagen del arte final

        return Object.entries(arteFinal.formatos).map(([formatoId, formato]) => {
          return {
            // arteFinalId,
            format: formatoId,
            // formatoId,
            quantity: formato.quantity,

            // vamos a añadir el campo custom solo si el formato.newNameCustom no es undefined sino no añadimos el campo custom
            // formato.newNameCustom !== undefined ? custom: formato.newNameCustom : undefined,
            custom: formato.newNameCustom,
          };
        });
      })
      .flat();

    const token = await getAccessTokenSilently();

    await createOrderAndOrderItems(token, {
      order: {
        user: user.name,
        // comment: `Comentario: ${user.sub}`,
        comment: '',
        // userId: user.sub,
        userId: user.sub.split('|')[1],
      },
      orderItems,
    });

    toast.success('Pedido confirmado ', {
      duration: 2000,
      position: 'top-center',
    });

    toggleDrawer();
  };

  const handleCloseConfirmarPedidoPopover = () => {
    setConfirmarPedidoPopover(null);
  };

  const handleDeleteClose = () => {
    setDeletePopover(null);
  };

  const handleDeleteConfirm = (idArteFinal, idFormato, packArteFinal, packFormat) => {
    const updatedShoppingCart = { ...shoppingCart_v2 };

    // si estoy borrando un formato compuesto tendre que borrar todos los formatos que tengan el mismo idPack

    if (packArteFinal && packFormat) {
      // tenemos que comprobar que en aaffCompuestos haya un pack con el mismo nombre, si lo hay eliminamos todos los formatos que tengan el mismo packFormat
      const pack = aaffCompuestos[packArteFinal];
      // si el pack existe eliminamos todos los formatos que tengan el mismo packFormat si un arteFinal se queda sin formatos lo eliminamos
      if (pack) {
        // recorremos el pack
        pack.forEach((arteFinal) => {
          // ahora dentro de cada pack que tenemos el arte final tenemos que eliminar su formato que tenga el mismo packFormat. Si nos quedamos sin formatos eliminamos el arte final
          const formatos = updatedShoppingCart[arteFinal.id].formatos;
          // si el arte final tiene formatos
          if (formatos) {
            // si el arte final tiene el formato que queremos eliminar
            // recorremos todos los formatos y los que tengan el mismo packFormat los eliminamos
            Object.entries(formatos).forEach(([idFormato, formato]) => {
              if (formato.packFormat === packFormat) {
                delete formatos[idFormato];
              }
            });
            // si el arte final se queda sin formatos lo eliminamos
            if (Object.keys(formatos).length === 0) {
              delete updatedShoppingCart[arteFinal.id];
            }
          }
        });
        setAaffCompuestos({});
        setAaffNoCompuestos([]);
        setShoppingCart_v2(updatedShoppingCart);
      }
    } else if (packArteFinal && !packFormat) {
      const pack = aaffCompuestos[packArteFinal];
      if (pack) {
        pack.forEach((arteFinal) => {
          delete updatedShoppingCart[arteFinal.id];
        });
        setAaffCompuestos({});
        setAaffNoCompuestos([]);
        setShoppingCart_v2(updatedShoppingCart);
      }
    } else {
      if (updatedShoppingCart[idArteFinal]) {
        // Accedo a la propiedad formatos del arte final
        const formatos = updatedShoppingCart[idArteFinal].formatos;

        // Verifico si existe el formato en la propiedad formatos
        if (formatos && formatos[idFormato]) {
          // Elimino el formato del arte final
          delete formatos[idFormato];

          // Verifico si no quedan más formatos, en cuyo caso elimino el arte final también
          if (Object.keys(formatos).length === 0) {
            delete updatedShoppingCart[idArteFinal];
          }

          setAaffCompuestos({});
          setAaffNoCompuestos([]);
          setShoppingCart_v2(updatedShoppingCart);
        }
      }
    }

    // si estoy borrando un formato no compuesto solo borro ese formato
    // Verifico si existe el arte final en shoppingCart

    handleDeleteClose();
  };

  const deleteCarrito = () => {
    setAaffCompuestos({});
    setAaffNoCompuestos([]);
    setShoppingCart_v2({});
    toast.success('Carrito eliminado');
    toggleDrawer();
  };

  return (
    <div className="container">
      <div className="icon-title-resumen-pedido">
        <IconButton
          edge="end"
          color="inherit"
          onClick={toggleDrawer}
          aria-label="close"
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '.5rem',
            right: '1rem',
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography style={{ margin: '1rem 0.5rem' }}>Resumen de la solicitud</Typography>
      </div>
      {/* Mi info del carrito */}
      {/* comprobamos si hay algun arte final compuestos y cuantos packs distintos hay */}
      <div>
        {Object.entries(aaffCompuestos).map(([idPack, aaffCompuesto]) => {
          // ahora sacamos el nombre del pack que vamos a mostrar en pantalla y este nombre va a ser el del arteFinal que en alguno de sus formatos tenga childrenFormat.format.length > 0
          const namePack = Object.values(aaffCompuesto).find((arteFinal) =>
            Object.values(arteFinal.formatos).some((formato) => formato?.childrenFormat?.format?.length > 0),
          )?.name;

          const required = Object.values(aaffCompuesto).some((arteFinal) =>
            Object.values(arteFinal.formatos).some(
              (formato) => formato.childrenFormat?.format?.length > 0 && formato.childrenFormat.required,
            ),
          );

          // ESTO ES UN PACK
          return (
            <div key={idPack} style={{ margin: '1rem 0', border: '1px solid #adacac' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '1rem 1rem 0 1rem',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                  <Typography variant="h6">{namePack}</Typography>
                  <IconButton
                    onClick={(e) =>
                      handleDeleteClick(
                        e,
                        // le pasamos el id del primero
                        Object.values(aaffCompuesto)[0].id,
                        Object.values(aaffCompuesto)[0].formatos[
                          Object.keys(Object.values(aaffCompuesto)[0].formatos)[0]
                        ].formatoId,

                        idPack,
                      )
                    }
                  >
                    <DeleteIcon className="aaffcompuesto" style={{ userSelect: 'none', outline: 'none' }} />
                  </IconButton>
                </div>
                <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '18px' }}>
                  {/* sacamos el total precio del compuesto */}
                  {Object.values(aaffCompuesto)
                    .reduce((acc, arteFinal) => {
                      return (
                        acc +
                        Object.values(arteFinal.formatos).reduce((acc, formato) => {
                          return acc + formato.quantity * formato.formatoPrice;
                        }, 0)
                      );
                    }, 0)
                    .toLocaleString('de-DE', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + '€'}
                </span>
                {/* <Tooltip anchorSelect=".aaffcompuesto">
                  <span>Eliminar arte final compuesto</span>
                </Tooltip> */}
              </div>
              {/* AQUI DEBAJO EL SUBTOTAL DEL ARTE FINAL COMPUESTO */}
              {aaffCompuesto.map((arteFinal, i) => {
                return (
                  <div
                    style={{
                      // si no es el ultimo añadir un borderBottom
                      borderBottom: i !== aaffCompuesto.length - 1 && '1px solid #cecece',
                    }}
                    key={arteFinal.id}
                  >
                    <ItemArteFinalCarrito
                      required={
                        Object.values(arteFinal.formatos).some((formato) => formato?.childrenFormat?.format?.length > 0)
                          ? true
                          : required
                      }
                      compuesto
                      handleDeleteClick={handleDeleteClick}
                      shoppingCart={shoppingCart_v2}
                      styleContainer={{
                        height: '150px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      styleImg={{ cursor: 'pointer', objectFit: 'contain', height: '100%', width: '90%' }}
                      idArteFinal={arteFinal.id}
                      arteFinal={arteFinal}
                      key={arteFinal.id}
                    />
                  </div>
                );
              })}
              {/* aqui tendremos que tener la opcion de eliminar el pack entero */}
            </div>
          );
        })}
      </div>
      {aaffNoCompuestos.map((aaffNoCompuesto) => (
        <ItemArteFinalCarrito
          handleDeleteClick={handleDeleteClick}
          shoppingCart={shoppingCart_v2}
          styleContainer={{
            height: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          styleImg={{ cursor: 'pointer', objectFit: 'contain', height: '100%', width: '90%' }}
          idArteFinal={aaffCompuestos.id}
          arteFinal={aaffNoCompuesto}
          key={aaffNoCompuesto.id}
        />
      ))}
      {/* Total del pedido */}
      <div className="total-pedido">
        <Typography variant="h6">
          Importe Total:{' '}
          {`${totalPrice.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}€`}{' '}
        </Typography>
        <div style={{ display: 'flex', gap: '1rem', paddingTop: '1rem' }}>
          <Button color="error" style={{ border: '1px solid #d32f2f' }} onClick={deleteCarrito}>
            Eliminar carrito
          </Button>
          {Object.keys(shoppingCart_v2).length > 0 ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  navigate('/carteleria/cart');
                  toggleDrawer();
                }}
              >
                Tramitar Solicitud
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      {/*  -------------------------------------------------------------------------- */
      /*                                  POPOVERS                                    */
      /* --------------------------------------------------------------------------  */}
      <DeleteFormat
        key={selectedFormat?.idArteFinal}
        deletePopover={deletePopover}
        handleDeleteClose={handleDeleteClose}
        handleDeleteConfirm={handleDeleteConfirm}
        selectedFormat={selectedFormat}
      />

      <ConfirmarPedido
        confirmarPedidoPopover={confirmarPedidoPopover} // abrir el popover
        // handleOpenConfirmarPedidoPopover={handleOpenConfirmarPedidoPopover} //
        handleCloseConfirmarPedidoPopover={handleCloseConfirmarPedidoPopover}
        handleDeleteConfirm={handleSendAlert}
        mensaje="¿Estás seguro de confirmar el pedido ?"
      />
    </div>
  );
};

export default ResumenPedido_v2;
