import { useQuery } from '@tanstack/react-query';
import { getAllMerchandising } from '../../../api/carteleriaApi';
import { useAuth0 } from '@auth0/auth0-react';

function useGetAllMerchandising() {
  const { getAccessTokenSilently } = useAuth0();
  const {
    data: merchandising,
    error,
    isLoading,
  } = useQuery(['merchandising'], async () => {
    const token = await getAccessTokenSilently();
    return await getAllMerchandising(token);
  });
  return { merchandising, error, isLoading };
}

export default useGetAllMerchandising;
