import React from 'react';
import AsociateTable from './AsociateTable/AsociateTable.jsx';

const RelArteFinalTienda = () => {
  return (
    <div>
      <h1>3. Asociar artes finales a los centros</h1>
      {/* aqui metemos la tabla de tanstack */}
      <div className="background-container-table">
        <AsociateTable />
      </div>
    </div>
  );
};

export default RelArteFinalTienda;
