const HeadingAaffSelector = ({ title, description, children }) => {
  return (
    <div style={{ width: '45%' }}>
      <div className="subcontainer-heading-aaff-selector">
        <h5 style={{ display: 'flex', justifyContent: 'center' }}>{title}</h5>
        <p className="descripction-aaff-selector">{description}</p>
      </div>
      {children}
    </div>
  );
};

export default HeadingAaffSelector;
