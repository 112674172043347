import React, { useContext } from 'react';
import { MercadoGraphicContext } from '../../context/MercadoGraphicContext';
import { GrapiCard } from '../../components/recycle/GrapiCard';

const GraphicsMercado = () => {
  const { graficoArray, setGraficoArray, areaData, moduleData, shelvesData } = useContext(MercadoGraphicContext);
  // Función para agregar una instancia de GrapiCard al array
  const agregarGrafico = () => {
    setGraficoArray((prevArray) => [...prevArray, 1]);
  };

  // Función para quitar la última instancia de GrapiCard del array
  const quitarUltimoGrafico = () => {
    if (graficoArray.length > 0) {
      setGraficoArray((prevArray) => prevArray.slice(0, prevArray.length - 1));
    }
  };
  return (
    <div style={{ flex: 1, overflowX: 'hidden' }}>
      <button
        style={{
          background: 'rgb(51, 51, 51)',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          padding: '5px 10px',
          // margin: '5px 5px 5px 12px',
        }}
        onClick={agregarGrafico}
      >
        Agregar tarjeta
      </button>
      <button
        style={{
          background: '#dd2e40',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          padding: '5px 10px',
          margin: '5px',
        }}
        onClick={quitarUltimoGrafico}
      >
        Quitar última tarjeta
      </button>

      <div
        style={{
          height: '88%',
          // width: '44.5vw',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          overflowY: 'hidden',
          gap: '12px',
          paddingLeft: '12px',
        }}
      >
        {graficoArray.map((item, index) => (
          <div key={index} style={{ flex: '0 0 auto', minWidth: '300px', marginTop: '5px' }}>
            <GrapiCard areaData={areaData} moduleData={moduleData} shelvesData={shelvesData} type={'rendondo'} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GraphicsMercado;
