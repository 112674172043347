import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDataPedidoPilotado, getSolicitudesPedidoPilotado } from '../../../api/carteleriaApi';
import HeaderPilotado from './HeaderPilotado';
import BodyPilotado from './BodyPilotado';
import { Pagination } from '@mui/material';
import { PilotadosContext } from '../../../context/PilotadosContext';

const PedidoPilotado = () => {
  const { idPilotado } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const { orderFilter, valueCenterSearchAfter } = useContext(PilotadosContext);

  const [page, setPage] = useState(1);

  const { data: dataPilotado } = useQuery(['pedido-pilotado', idPilotado], async () => {
    let token = await getAccessTokenSilently();
    return getDataPedidoPilotado(token, idPilotado, 1, 8, {
      order: orderFilter,
      valueCenterSearch: valueCenterSearchAfter,
    });
  });

  const { data, refetch } = useQuery(['pedido-pilotado-orders', idPilotado, page, valueCenterSearchAfter], async () => {
    let token = await getAccessTokenSilently();
    return getSolicitudesPedidoPilotado(token, idPilotado, page, 8, {
      order: orderFilter,
      valueCenterSearch: valueCenterSearchAfter,
    });
  });

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [valueCenterSearchAfter]);

  useEffect(() => {
    refetch();
  }, [orderFilter]);

  return (
    <div>
      <HeaderPilotado
        admin={dataPilotado?.data?.administrador}
        createdAt={dataPilotado?.data?.createdAt}
        numCentros={dataPilotado?.data?.numCentros}
        idPilotado={dataPilotado?.data?.idPilotado}
      />
      <BodyPilotado orders={data?.data} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {data?.total > 8 && (
          <Pagination
            showFirstButton
            showLastButton
            page={page}
            onChange={(e, v) => setPage(v)}
            count={Math.ceil(data?.total / 8)}
            color="error"
            variant="outlined"
            size="large"
            sx={{ margin: '2rem' }}
          />
        )}
      </div>
    </div>
  );
};

export default PedidoPilotado;
