import React from 'react';
import { filterViewer } from '../../../helpers/viewerHelper';

const ShowAllButton = () => {
  const handleOnClick = () => {
    filterViewer([]);
  };

  return (
    <button className="viewer-button" onClick={handleOnClick}>
      Mostrar todos
    </button>
  );
};

export default ShowAllButton;
