import React, { useEffect, useState } from 'react';
import { Input, Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, IconButton, Popover } from '@mui/material';
import {
  handleClickDelete,
  handleOnChange,
  handleOpenModalCreateCosteInstalacionOrder,
} from '../../helpers/Admin/costesInstalacionHelper';
import { useAuth0 } from '@auth0/auth0-react';
import UpdateCostesInstalacionButton from './popover/UpdateCostesInstalacionButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Tooltip } from 'react-tooltip';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getCostesInstalacionOrder } from '../../api/carteleriaApi';
import { useQuery } from '@tanstack/react-query';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

const CostesInstalacion = ({ admin, orderId, data, isFetching, refetch }) => {
  const [originalCostes, setOriginalCostes] = useState([]);
  const [editOriginalCostes, setEditOriginalCostes] = useState([]);

  useEffect(() => {
    if (!data) return;
    setOriginalCostes(data.data.costeInstalacionOrders);
    setEditOriginalCostes(data.data.costeInstalacionOrders);
  }, [isFetching]);

  useEffect(() => {
    console.log('originalCostes', originalCostes);
    console.log('editOriginalCostes', editOriginalCostes);
  }, [originalCostes, editOriginalCostes]);

  const navigate = useNavigate();
  const location = useLocation();
  const [showTooltip, setShowTooltip] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <h2 className="title-order" style={{ marginBottom: '0' }}>
            Costes de Instalación
          </h2>
          {admin && (
            <IconButton
              onClick={() => {
                handleOpenModalCreateCosteInstalacionOrder({ navigate, location });
                setShowTooltip(false); // Ocultar el tooltip al hacer clic en el botón
              }}
              className="create-costeInstalacionOrder-tooltip"
            >
              <AddCircleOutlineOutlinedIcon style={{ cursor: 'pointer' }} />
            </IconButton>
          )}
        </div>
        {admin && (
          <UpdateCostesInstalacionButton
            refetch={refetch}
            originalCostes={originalCostes}
            setOriginalCostes={setOriginalCostes}
            getAccessTokenSilently={getAccessTokenSilently}
            editOriginalCostes={editOriginalCostes}
            setEditOriginalCostes={setEditOriginalCostes}
          />
        )}
      </div>
      <div style={{ marginBottom: '3rem' }}>
        <div
          style={{
            border: '1px solid #E1E1E1',
          }}
        >
          <Row style={{ margin: '0', backgroundColor: 'black', color: 'white', padding: '1rem 1rem' }}>
            <Colxx lg="4" xl="4" xxl="5" style={{ padding: '0' }}>
              Descripcion
            </Colxx>
            <Colxx lg="2" xl="2" xxl="2">
              Cantidad
            </Colxx>
            <Colxx lg="2" xl="2" xxl="2">
              Precio unitario
            </Colxx>
            <Colxx lg="2" xl="2" xxl="2">
              Precio total
            </Colxx>
            {/* <StyledTableCell>Acciones</StyledTableCell> */}
          </Row>
          {originalCostes?.length > 0 ? (
            originalCostes.map((coste, index) => (
              <div
                key={coste._id + '_' + index}
                style={{ borderBottom: index !== originalCostes.length - 1 ? '1px solid #E1E1E1' : 'none' }}
              >
                <Row className="coste-instalacion-row" style={{ margin: '0', padding: '1.5rem 1rem' }}>
                  <Colxx lg="4" xl="4" xxl="5" style={{ padding: '0 1rem 0 0', display: 'flex', alignItems: 'center' }}>
                    {coste.costeInstalacion.nombre +
                      ' - ' +
                      coste.costeInstalacion.localizacion +
                      ' - ' +
                      coste.costeInstalacion.tipoCoste}
                  </Colxx>
                  <Colxx lg="2" xl="2" xxl="2" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    {/* CANTIDAD */}
                    {admin ? (
                      <Input
                        onBlur={
                          // si la cantidad es menor que 0, establecer a 0
                          (e) => {
                            if (e.target.value < 0 || e.target.value === '') {
                              e.target.value = 0;
                            }
                          }
                        }
                        onChange={(e) =>
                          handleOnChange({
                            editOriginalCostes,
                            cantidad: e.target.value,
                            idCoste: coste._id,
                            setEditOriginalCostes,
                          })
                        }
                        type="number"
                        defaultValue={
                          editOriginalCostes.find((costeInstalacionOrder) => costeInstalacionOrder._id === coste._id)
                            .cantidad
                        }
                      />
                    ) : (
                      coste.cantidad
                    )}
                  </Colxx>
                  <Colxx lg="2" xl="2" xxl="2" style={{ display: 'flex', alignItems: 'center' }}>
                    {coste.costeInstalacion.coste + ' €'}
                  </Colxx>
                  <Colxx lg="2" xl="2" xxl="2" style={{ display: 'flex', alignItems: 'center' }}>
                    {(coste.costeInstalacion.coste * coste.cantidad).toFixed(2) + ' €'}
                  </Colxx>
                  {admin && (
                    <Colxx
                      lg="1"
                      xl="1"
                      xxl="1"
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <PopupState variant="popover" popupId="demo-popup-popover">
                        {(popupState) => (
                          <div>
                            <IconButton {...bindTrigger(popupState)}>
                              <DeleteForeverIcon style={{ cursor: 'pointer' }} />
                            </IconButton>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                              }}
                            >
                              <div style={{ padding: '1rem' }}>
                                <p style={{ fontSize: '.75rem' }}>¿Desea eliminar el coste?</p>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: '1rem',
                                    marginTop: '.5rem',
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      fontSize: '.7rem',
                                      padding: '5px 5px',
                                      minWidth: '0',
                                      backgroundColor: '#898989',
                                    }}
                                    onClick={() => {
                                      // handleRemoveProperty(id);
                                      // addModulo()
                                      popupState.setOpen(false);
                                    }}
                                  >
                                    No
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    style={{
                                      fontSize: '.75rem',
                                      padding: '5px 5px',
                                      minWidth: '0',
                                      background: '#E4253B',
                                      borderColor: 'transparent',
                                      color: '#fff',
                                    }}
                                    onClick={() => {
                                      handleClickDelete({ refetch, getAccessTokenSilently, idCosteOrder: coste._id });
                                      popupState.setOpen(false);
                                    }}
                                  >
                                    Sí
                                  </Button>
                                </div>
                              </div>
                            </Popover>
                          </div>
                        )}
                      </PopupState>
                    </Colxx>
                  )}
                </Row>
                {/* MENU PARA ACTUALIZAR LA CANTIDAD */}
              </div>
            ))
          ) : (
            <div style={{ padding: '2rem 1rem' }}>Esta solicitud no tiene ningun coste de instalación</div>
          )}
        </div>
        {originalCostes?.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <span
              style={{
                fontSize: '1.1rem',
                padding: '1rem',
                lineHeight: '1.1rem',
                borderRight: '1px solid #E1E1E1',
                borderBottom: '1px solid #E1E1E1',
                borderLeft: '1px solid #E1E1E1',
              }}
            >
              Total costes:{' '}
              {originalCostes.reduce((acc, coste) => acc + coste.costeInstalacion.coste * coste.cantidad, 0).toFixed(2)}{' '}
              €
            </span>
          </div>
        )}
      </div>
      {/* TOOLTIP PARA AÑADIR UN NUEVO COSTE DE INSTALACIÓN */}
      {showTooltip && (
        <Tooltip place="right-end" anchorSelect=".create-costeInstalacionOrder-tooltip">
          Añadir un nuevo coste de instalación a la solicitud
        </Tooltip>
      )}
      <Outlet />
    </>
  );
};

export default CostesInstalacion;
