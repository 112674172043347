import React, { createContext, useEffect, useState } from 'react';

export const FormatCompuestoContext = createContext();

export const FormatCompuestoProvider = ({ children }) => {
  const [compuestoValue, setCompuestoValue] = useState(false);
  const [aaffSelected, setAaffSelected] = useState([]);
  const [required, setRequired] = useState(false);

  return (
    <FormatCompuestoContext.Provider
      value={{ required, setRequired, compuestoValue, setCompuestoValue, aaffSelected, setAaffSelected }}
    >
      {children}
    </FormatCompuestoContext.Provider>
  );
};
