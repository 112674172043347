import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { getProfileAutodesk } from "../api/alcampoApi";
import { ProyectoProvider } from "../context/ProyectoContext";
import { CapitulosProvider } from "../context/CapitulosContext";
import { PartidasProvider } from "../context/PartidasContext";

const ProtectedProjects = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [userAutodesk, setUserAutodesk] = useState(null);
    const location = useLocation();

    // TODO: Meter aqui la logica para obtener el perfil de autodesk
    useEffect(() => {


        const getUserAutodesk = async () => {
            try {
              let token = await getAccessTokenSilently();
              const res = await getProfileAutodesk(token);
              setUserAutodesk(res);
              return res;
            } catch (err) {
              setUserAutodesk(null);
              loginAutodesk()

            }
          };

        const loginAutodesk = async () => {
            const token = await getAccessTokenSilently(); // Reemplaza esto con tu lógica para obtener el token

            // Realiza una solicitud fetch con la cabecera de autorización y luego redirige

            let { data } = await axios.get('/api/v1/auth/login', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            window.location.replace(data.url);
        };
        getUserAutodesk();
    }, [location.pathname]);

    return !userAutodesk ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <span className="loader-projects-login"></span>

        </div>
    ) : (
        <>
        <ProyectoProvider>
            <CapitulosProvider>
                <PartidasProvider>
                    <Outlet />
                </PartidasProvider>
            </CapitulosProvider>
        </ProyectoProvider>
        </>
    )
};

export default ProtectedProjects;
