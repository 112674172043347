import { DEFAULT_PROPERTY_BY_param } from '../../constants/viewerValues';
import { getBulkPropertiesAsync, getDataByName, startLeafNodes, viewer } from '../viewerHelper';

export default class PartidaProyecto {
  constructor(code, param, price) {
    this.viewer = viewer;
    this.code = code;
    this.param = param || 'ud';
    this.price = price;

    if (!this.viewer) {
      throw new Error('Viewer no existe');
    }
  }

  getTotalAmount = async () => {
    if (!this.quantity) {
      const totalQuantity = await this.getTotalQuantity();
      this.amount = totalQuantity * this.price;
      return this.amount;
    }
    this.amount = this.quantity * this.price;
    return this.amount;
  };

  getQuantityByDbIds = async () => {
    if (this.dbIds.length === 0) {
      return 0;
    }
    const quantityByDbId = await getBulkPropertiesAsync(this.viewer, this.dbIds, [this.param]);
    const quantity = quantityByDbId.reduce((acc, item) => acc + parseFloat(item.properties[0].displayValue), 0);
    return quantity;
  };

  getAllDbIds = async () => {
    // const dbIds = await startLeafNodes();
    // console.log('dbIds');
    // const allDbIds = await getBulkPropertiesAsync(this.viewer, dbIds, ['MED_QTO']);
    const allDbIds = await getDataByName(['MED_QTO']);

    const dbIdsByCode = allDbIds
      .filter((item) => item.properties[0].displayValue.includes(this.code))
      .map((item) => item.dbId);

    // console.log('dbIdsByCode', dbIdsByCode);
    this.dbIds = dbIdsByCode;
    return dbIdsByCode;
  };

  getTotalQuantity = async () => {
    const dbIdsByCode = await this.getAllDbIds();
    if (this.param == 'Unidad') {
      this.quantity = dbIdsByCode.length;
      return dbIdsByCode.length;
    }

    const totalQuantity = await this.getQuantityByDbIds();

    this.quantity = totalQuantity;

    return totalQuantity;
  };
}
