import { Outlet } from 'react-router-dom';
import Page404 from '../Components2/Page404/Page404';
import { MasterProvider } from '../context/MasterContext';
import TabNavigateMaster from '../Components2/Admin/tabs/TabNavigateMaster';
import { GestionCenterProvider } from '../context/GestionCenterContext';
import { GestionClusterProvider } from '../context/GestionClusterContext';
import { GestionMaterialProvider } from '../context/GestionMaterialContext';
import { useMasterAuth } from '../hooks/useMasterAuth';
import { GestionProveedorProvider } from '../context/GestionProveedorContext';

export const ProtectedMaster = ({ requiredRole }) => {
  const { pass } = useMasterAuth(requiredRole);

  return (
    <div>
      {pass ? (
        <>
          <MasterProvider>
            <GestionCenterProvider>
              <GestionClusterProvider>
                <GestionMaterialProvider>
                  <GestionProveedorProvider>
                    <TabNavigateMaster /> <Outlet />
                  </GestionProveedorProvider>
                </GestionMaterialProvider>
              </GestionClusterProvider>
            </GestionCenterProvider>
          </MasterProvider>
        </>
      ) : (
        <Page404 />
      )}
    </div>
  );
};
