import React, { useContext } from 'react';
import SearchCenterPilotado from './SearchCenterPilotado';
import { Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import TableAdmin from '../../../Components2/common/TableAdmin';
import { PilotadosContext } from '../../../context/PilotadosContext';

const BodyPilotado = ({ orders }) => {
  const { setOrderFilter } = useContext(PilotadosContext);

  return (
    <Row style={{ padding: '1rem' }}>
      <Colxx xxs="1" />
      <Colxx xxs="10" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <SearchCenterPilotado />
        {/* TODO: Metemos la tabla de pedidos y le pasamos los pedidos como props */}
        <TableAdmin listOrder={orders} pilotado={true} setOrderFilter={setOrderFilter} />
      </Colxx>
    </Row>
  );
};

export default BodyPilotado;
