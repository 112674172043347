import { IconButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Input } from 'reactstrap';
import { PartidasContext } from '../../context/PartidasContext';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-hot-toast';

const SearchInput = ({ value, onChange }) => {
  const { setPartidaQuery, partidaQuery } = useContext(PartidasContext);

  const [inputFocus, setInputFocus] = useState(false);

  // TENDRE QUE INVALIDAR LA QUERY CUANDO LE DE AL ENTER A LA VEZ QUE CAMBIARE EL STATE DE LA QUERY

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (value.length < 3) {
      toast.error('La búsqueda debe tener al menos 3 caracteres', {
        id: 'search-input-partidasproyecto',
      });
    }

    setPartidaQuery({
      ...partidaQuery,
      search: value,
    });
  };

  const checkAndClearInput = () => {
    onChange('');
    // hay que comprobar si existe search en partidaQuery y si existe borrarlo
    if (partidaQuery.search) {
      const { search, ...remainingPartidaQuery } = partidaQuery;
      setPartidaQuery(remainingPartidaQuery);
    }
  };

  //   TENGO QUE PONER UN ICONO DE UNA X AL FINAL DEL INPUT PARA BORRAR EL VALUE Y ASI QUITAR DE LA QUERY
  //   LA BUSQUEDA

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <IconButton
        // disabled={value.length < 3}
        type="submit"
        className="border-radius-icon-button button-search-submit icon-btn-focus"
        color="primary"
      >
        <SearchIcon />
      </IconButton>
      <Input
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit(e);
          }
        }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        size="small"
        style={{
          borderRight: 'none',
          // borderRadius: "0px",
        }}
        type="text"
        placeholder="Buscar por código o nombre"
        className="input-search-menu outline-none icon-btn-focus"
      />
      <div
        style={{
          backgroundColor: 'white',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #d7d7d7',
          //   borderTopColor: !inputFocus ? '#d7d7d7' : '#d2d2d2',
          borderLeft: 'none',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          paddingRight: '.4rem',
        }}
      >
        <CloseIcon
          style={{
            cursor: 'pointer',
          }}
          onClick={checkAndClearInput}
          fontSize="small"
        />
      </div>
    </form>
  );
};

export default SearchInput;
