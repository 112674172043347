import { createContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAllVersionsByProject, getProyectoByItem } from '../api/alcampoApi';
import { useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import { useHandleUrl } from '../hooks/useHandleUrl';
import { useAuth0 } from '@auth0/auth0-react';

export const ProyectoContext = createContext();

export const ProyectoProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [currentProject, setCurrentProject] = useState(null);
  const [currentPartidasProject, setCurrentPartidasProject] = useState([]); // TODO: revisar
  const [currentCapitulosProject, setCurrentCapitulosProject] = useState([]); // TODO: revisar
  const [fetchingPartidasProject, setFetchingPartidasProject] = useState(true);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [availableVersionsByCurrentProject, setAvailableVersionsByCurrentProject] = useState([]); // TODO: revisar
  const [version, setVersion] = useState(null); // TODO: revisar
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const { idItem } = useHandleUrl();
  const { data: versionsData } = useQuery(
    ['available-versions', currentProject?._id],
    () => {
      return getAccessTokenSilently().then((token) => getAllVersionsByProject(currentProject?._id, token));
    },
    {
      enabled: !!currentProject?._id,
    },
  );

  useEffect(() => {
    setCurrentProject(null);
    if (idItem) {
      // setCurrentProject(null);
      getAccessTokenSilently().then((token) => {
        getProyectoByItem(idItem, token)
          .then((res) => {
            setCurrentProject(res[0]);
          })
          .catch((e) => console.log('e', e));
      });

    }
  }, [idItem]);

  // cada vez que cambie el current project hay que traer las versiones disponibles de ese proyecto

  useEffect(() => {
    if (versionsData) {
      setAvailableVersionsByCurrentProject(versionsData.map((version) => {
        return { version: version.version, _id: version._id, existsGeometry: version?.existsGeometry || null }
      }));

    }
  }, [versionsData]);

  const data = {
    currentProject,
    setCurrentPartidasProject,
    currentPartidasProject,
    fetchingPartidasProject,
    setFetchingPartidasProject,
    currentVersion,
    setCurrentVersion,
    setCurrentCapitulosProject,
    currentCapitulosProject,
    availableVersionsByCurrentProject,
    setAvailableVersionsByCurrentProject,
    version,
    setVersion,
    isModelLoaded,
    setIsModelLoaded,
  };
  return <ProyectoContext.Provider value={data}>{children}</ProyectoContext.Provider>;
};
