import { useAuth0 } from '@auth0/auth0-react';
import { FormControl, MenuItem, Select, InputLabel, Autocomplete, TextField } from '@mui/material';
import { getAllCentersMaster } from '../../api/carteleriaApi';
import { useQuery } from '@tanstack/react-query';
import { ordenarPorPropiedad } from '../../helpers/sortFunctions';

const FiltersArtes = ({
  valueCenterType,
  setValueCenterType,
  valueCenter,
  setValueCenter,
  valueStatus,
  setValueStatus,
  centerTypes,
  fetchingCenterTypes,
  validateStatus,
  setValidateStatus,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data: centers } = useQuery(['centers-master', valueCenterType], async () => {
    const token = await getAccessTokenSilently();
    return await getAllCentersMaster(token, valueCenterType);
  });

  return (
    <div className="ai-c df-jcsb w-100">
      {/* Filtro para Status */}
      <FormControl size="small" style={{ width: '20%' }}>
        <InputLabel id="filter-status-master-label">Estado</InputLabel>
        <Select
          labelId="filter-status-master-label"
          id="filter-status-master"
          value={valueStatus}
          label="Estado"
          onChange={(e) => setValueStatus(e.target.value)}
        >
          <MenuItem value={'all'}>Todos</MenuItem>
          <MenuItem value={'active'}>Activos</MenuItem>
          <MenuItem value={'notActive'}>Inactivos</MenuItem>
        </Select>
      </FormControl>

      {/* FILTRO PARA CENTER TYPE */}
      <FormControl size="small" style={{ width: '25%' }}>
        <InputLabel id="filter-centertype-master-label">Cluster</InputLabel>
        <Select
          labelId="filter-centertype-master-label"
          id="filter-centertype-master"
          value={valueCenterType}
          label="Cluster"
          onChange={(e) => setValueCenterType(e.target.value)}
        >
          <MenuItem value={'all'}>Todos</MenuItem>
          {!fetchingCenterTypes &&
            centerTypes?.data?.sort(ordenarPorPropiedad('index')).map((centerType) => (
              <MenuItem key={centerType._id} value={centerType._id}>
                {centerType.type}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Autocomplete
        size="small"
        id="filter-center-master"
        style={{ width: '30%' }}
        options={centers?.data || []}
        getOptionLabel={(option) => `${option.code} - ${option?.name || option?.calle}`}
        value={valueCenter}
        onChange={(event, newValue) => {
          setValueCenter(newValue);
        }}
        noOptionsText="No existen centros con ese nombre o código"
        renderInput={(params) => (
          <TextField
            {...params}
            // variant="standard"
            label="Centro"
            sx={{ '& label.Mui-focused': { color: 'black' } }}
            placeholder="Ej: ES0001, Utebo.. "
          />
        )}
      />

      {/* // ahora vamos a añadir otro formControl con un select para añadir un filtro de validación: */}
      <FormControl size="small" style={{ width: '20%' }}>
        <InputLabel id="filter-validation-master-label">Validación</InputLabel>
        <Select
          labelId="filter-validation-master-label"
          id="filter-validation-master"
          value={validateStatus}
          label="Validación"
          onChange={(e) => setValidateStatus(e.target.value)}
        >
          <MenuItem value={'all'}>Todos</MenuItem>
          <MenuItem value={'validate'}>Validados</MenuItem>
          <MenuItem value={'notValidate'}>No validados</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default FiltersArtes;
