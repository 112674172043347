import React, { useContext, useState } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import ListCapitulosProyecto from './ListCapitulosProyecto';
import ListPartidasProyecto from './ListPartidasProyecto';
import FiltersPartidaProyecto from './FiltersPartidaProyecto';
import { useLocation } from 'react-router-dom';
import { ProyectoContext } from '../../context/ProyectoContext';

const CapitulosPartidasProyecto = ({ openModal }) => {
  const { version } = useContext(ProyectoContext);
  const location = useLocation();

  return (
    <>
      <Row
        className="m-0"
        style={{ height: location.search !== '?version=19' && version !== undefined ? '620px' : '645px' }}
      >
        <Colxx xxs="12">
          <FiltersPartidaProyecto />
        </Colxx>
        <Colxx xxs="4" style={{ overflow: 'auto', height: '100%', overflowX: 'hidden' }}>
          <ListCapitulosProyecto openModal={openModal} />
        </Colxx>
        <Colxx xxs="8" style={{ height: '100%', overflow: 'auto' }}>
          <ListPartidasProyecto />
        </Colxx>
      </Row>
    </>
  );
};

export default CapitulosPartidasProyecto;
