import axios from 'axios';
import toast from 'react-hot-toast';

export const deleteCenter = async (centerId, token) => {
  try {
    await axios.patch(
      `/api/v1/center/${centerId}/delete`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    toast.success('Centro eliminado exitosamente');
  } catch (err) {
    console.error('Error al eliminar un centro: ', err.message);
    const errorMessage = err.response?.data?.message || err.message;
    toast.error(`${errorMessage}`, { id: 'delete-center-error' });
  }
};
