import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createProveedor,
  deleteProvider,
  editProvider,
  getAaffByProveedor,
  getGestionProviders,
  getNameProvider,
  getProvider,
  getProviders,
} from '../../api/providerApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

export const useCreateProvider = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createProveedor, {
    onSuccess: () => {
      queryClient.invalidateQueries('gestion-proveedores');
    },
  });

  return { mutation };
};

export const useGetGestionProviders = (params) => {
  return useQuery(['gestion-proveedores', params], () => getGestionProviders(params));
};

export const useGetProviders = () => {
  const { data: providers, isLoading: loadingProviders } = useQuery(['providers'], getProviders);

  return { providers, loadingProviders };
};

export const useHandleDeleteProvider = (handleDeleteClose, providerId) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate: handleDeleteConfirm } = useMutation({
    mutationFn: async () => {
      handleDeleteClose();
      await deleteProvider(providerId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('gestion-providers');
    },
    onError: (err) => {
      if (err.message === 'error-delete-format-associated') {
        navigate(`${location.pathname}/${providerId}/error-delete-provider`);
      }
    },
  });

  return { handleDeleteConfirm };
};

export const useGetProvider = () => {
  const { idProvider } = useParams();

  return useQuery(['provider', idProvider], () => getProvider(idProvider));
};

export const useHandleEditProvider = () => {
  const { idProvider } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(editProvider, {
    onSuccess: () => {
      queryClient.invalidateQueries('gestion-providers');
    },
    onError: (err) => {
      if (err.message === 'error-disable-format-associated') {
        navigate(`/master/proveedores/${idProvider}/error-edit-provider`);
      } else if (err.message === 'duplicate') {
        toast.error('Ya existe un proveedor con ese nombre');
      }
    },
  });
  return { mutation };
};

export const useGetNameProvider = (provider) => {
  const { data } = useQuery(['provider', provider], () => getNameProvider(provider), {
    suspense: true,
  });

  return { data };
};
export const useGetAaffByProveedor = (type) => {
  const { idProvider } = useParams();
  const { data, isLoading } = useQuery(['aaff-asociated', { idProvider, type }], () =>
    getAaffByProveedor(idProvider, type),
  );
  return { aaffAsociated: data?.data, proveedorName: data?.proveedorName, isLoading };
};
