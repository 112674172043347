import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useGetProviders } from '../../../lib/react-query/providerQuery';

const FormAssociateProviderFormat = ({ formik, goBack }) => {
  const { providers, loadingProviders } = useGetProviders();

  const handleAutocompleteChange = (event, newValue) => {
    formik.setFieldValue('provider', newValue ? newValue._id : '');
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="code"
            label="Código"
            variant="outlined"
            required
            fullWidth
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helpertext={formik.touched.code && formik.errors.code}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="price"
            label="Precio"
            variant="outlined"
            required
            fullWidth
            type="number"
            min="0"
            value={formik.values.price}
            onChange={formik.handleChange}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helpertext={formik.touched.price && formik.errors.price}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={loadingProviders ? [] : providers?.data || []}
            getOptionLabel={(option) => option.shortName || ''}
            value={providers?.data?.find((provider) => provider._id === formik.values.provider) || null}
            onChange={handleAutocompleteChange}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            renderInput={(params) => <TextField {...params} label="Proveedor" variant="outlined" fullWidth />}
            noOptionsText="Sin proveedor"
            loading={loadingProviders}
          />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
        <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
          Confirmar
        </Button>
        <Button type="button" variant="contained" color="error" onClick={goBack}>
          Atras
        </Button>
      </div>
    </form>
  );
};

export default FormAssociateProviderFormat;
