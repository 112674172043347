import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { Colxx } from '../../../components/common/CustomBootstrap';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';

const FormProveedor = ({ title, handleSubmit, formData, handleChange, handleCloseModal }) => {
  return (
    <Modal open onClose={handleCloseModal}>
      <div className="create-cluster-container-modal">
        <div className="center-form-title">
          <Typography
            variant="h4"
            component="h2"
            align="center"
            sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
          >
            {title}
          </Typography>
          <form className="center-form" onSubmit={handleSubmit}>
            <div className="cluster-row-md">
              <Colxx xxs="6">
                <TextField
                  name="code"
                  label="Código"
                  variant="outlined"
                  required
                  fullWidth
                  value={formData.code}
                  onChange={handleChange}
                />
              </Colxx>
              <Colxx xxs="6">
                <TextField
                  name="name"
                  label="Nombre"
                  variant="outlined"
                  required
                  fullWidth
                  value={formData.name}
                  onChange={handleChange}
                />
              </Colxx>
            </div>
            <div className="cluster-row-md">
              <Colxx xxs="6">
                <TextField
                  name="shortName"
                  label="Descripción corta"
                  variant="outlined"
                  required
                  fullWidth
                  value={formData.shortName}
                  onChange={handleChange}
                />
              </Colxx>
              <Colxx xxs="6">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="active-create-material">Activo</InputLabel>
                  <Select
                    labelId="active-create-material"
                    name="active"
                    label="Activo"
                    value={formData.active}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Sí</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </Colxx>
            </div>

            <div className="button-group-tiendas">
              <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
                Confirmar
              </Button>
              <Button
                type="button"
                variant="contained"
                color="error"
                endIcon={<CloseIcon />}
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default FormProveedor;
