import { Popover, Typography, Button } from '@mui/material';
import { useEffect } from 'react';

export const ConfirmarPedido = ({
  confirmarPedidoPopover,
  handleCloseConfirmarPedidoPopover,
  handleDeleteConfirm,
  mensaje,
}) => {
  return (
    <div>
      <Popover
        open={Boolean(confirmarPedidoPopover)}
        anchorEl={confirmarPedidoPopover}
        onClose={handleCloseConfirmarPedidoPopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '16px', textAlign: 'center' }}>
          <Typography>{mensaje}</Typography>

          <Button variant="contained" color="error" onClick={() => handleDeleteConfirm()} style={{ margin: '8px' }}>
            Sí
          </Button>
          <Button
            variant="contained"
            onClick={handleCloseConfirmarPedidoPopover}
            style={{ margin: '8px', background: '#333' }}
          >
            No
          </Button>
        </div>
      </Popover>
    </div>
  );
};
