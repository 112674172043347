import { Modal } from '@mui/material';
import { useParams } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import { TbPointFilled } from 'react-icons/tb';
import { useGetAaffByMaterial } from '../../../hooks/useMateriales';
import { useCloseModal } from '../../../hooks/useCloseModal';

const ErrorDeleteMaterial = ({ type }) => {
  const { idMaterial } = useParams();
  const { materialName, aaffAsociated, isLoading } = useGetAaffByMaterial(idMaterial);
  const closeModal = useCloseModal(2);

  if (isLoading) return;
  return (
    <Modal open onClose={closeModal}>
      <div className="create-cluster-container-modal">
        <IoMdClose onClick={closeModal} size="18px" className="f-right pointer" />
        <div className="center-form">
          <span className="d-flex ai-center g-05">
            <p>El siguiente material:</p>
            <p className="fw-bold fs-italic">{materialName}</p>
          </span>
          <p className="fs-italic">{`No se ha podido ${type}, debido a que esta asociado a los siguientes artículos:`}</p>
          <div className="pb-1 fd-column d-flex g-05">
            {aaffAsociated.map((aaff, i) => (
              <div className="d-flex ai-center g-05" key={aaff.code + i}>
                <TbPointFilled /> {aaff.code} - {aaff.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorDeleteMaterial;
