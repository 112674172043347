import { createContext, useState } from 'react';

export const PointContext = createContext();

export const PointProvider = ({ children }) => {
  const [arePointsActive, setArePointsActive] = useState(true);
  const [points, setPoints] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [isPdfContainerLoading, setIsPdfContainerLoading] = useState(false);
  const [position, setPosition] = useState(null);
  const [lineVertices, setLineVertices] = useState(null);

  const handleResetPoints = () => {
    setPoints([]);
    setSelectedPoint();
  };

  const data = {
    points,
    setPoints,
    selectedPoint,
    setSelectedPoint,
    arePointsActive,
    setArePointsActive,
    handleResetPoints,
    isPdfContainerLoading,
    setIsPdfContainerLoading,
    position,
    setPosition,
    lineVertices,
    setLineVertices,
  };

  return <PointContext.Provider value={data}>{children}</PointContext.Provider>;
};
