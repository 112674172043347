import cls from 'classnames';

const TitleSection = ({ title = 'Sin título', classnames, children, ...props }) => {
  return (
    <div className={cls('title-section-arte-final', classnames)} {...props}>
      <h2 className="title-text-section-arte-final">{title}</h2>
      {children}
    </div>
  );
};

export default TitleSection;
