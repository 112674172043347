import ReusableAreaChart from './ReusableAreaChart';
import ReusableBarChart from './ReusableBarChart';
import ReusableLineChart from './ReusableLineChart';

const TypeCharts = ({ type, data, keys, width = 500, height = 300 }) => {
  const chartProps = { data, dataKeys: keys, width, height };

  switch (type) {
    case 'bar':
      return <ReusableBarChart {...chartProps} />;
    case 'line':
      return <ReusableLineChart {...chartProps} />;
    case 'area':
      return <ReusableAreaChart {...chartProps} />;
    default:
      return null;
  }
};

export default TypeCharts;
