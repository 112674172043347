import { useQuery } from '@tanstack/react-query';
import { getPaginateCenters } from '../../../api/carteleriaApi';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useState } from 'react';
import { getCentersByAuth0Id } from '../../../api/userApi';
import { GestionCenterContext } from '../../../context/GestionCenterContext';

const limit = 12;
export function useShowCenters() {
  const { inputValueSearch } = useContext(GestionCenterContext);

  const [activeFilter, setActiveFilter] = useState('all');
  const [comunidadFilter, setComunidadFilter] = useState('all');
  const [page, setPage] = useState(1);
  const [centerType, setCenterType] = useState([]);

  // const [filteredIds, setFilteredIds] = useState([]);
  const [filteredIds, setFilteredIds] = useState(null);

  const { getAccessTokenSilently } = useAuth0();
  const { data: centersPaginated, isLoading } = useQuery(
    ['allCenters', page, limit, filteredIds, centerType, activeFilter, comunidadFilter, inputValueSearch],
    async () => {
      const token = await getAccessTokenSilently();
      return await getPaginateCenters(token, {
        page,
        limit,
        filteredIds,
        centerType,
        activeFilter,
        comunidadFilter,
        inputValueSearch,
      });
    },
  );

  const { data: allCenters } = useQuery(['allCenters'], async () => {
    const token = await getAccessTokenSilently();
    return getCentersByAuth0Id(token);
  });

  return {
    centersPaginated,
    allCenters,
    isLoading,
    limit,
    setPage,
    page,
    filteredIds,
    setFilteredIds,
    centerType,
    setCenterType,
    setActiveFilter,
    setComunidadFilter,
    activeFilter,
    comunidadFilter,
  };
}
