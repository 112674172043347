import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = ({ totalColumns = 1, count = 1, minHeight }) => {
  return Array.from({ length: totalColumns }).map((_, index) => (
    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
      <Skeleton key={index} count={count} height={minHeight} />
    </SkeletonTheme>
  ));
};

export default SkeletonLoader;
