import AttachFileIcon from '@mui/icons-material/AttachFile';
import CustomButton from '../../../ui/Button/CustomButton';
import { Button, Modal, TextField } from '@mui/material';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { updatePropertiesArteFinalMaster } from '../../../api/carteleriaApi';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';

const AddLinksToArteFinal = ({ userRole }) => {
  const { idArte } = useParams();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data } = queryClient.getQueryData(['info-arte-final', idArte]);
  const [editableLink, setEditableLink] = useState(data?.linkEditable || '');
  const [pdfLink, setPdfLink] = useState(data?.linkPdf || '');

  const handleOnclose = () => {
    setEditableLink(data?.linkEditable || '');
    setPdfLink(data?.linkPdf || '');
    setIsModalOpen(false);
  };

  const handleOnChange = (e, state) => {
    state(e.target.value);
  };

  const handleOnSubmit = async () => {
    try {
      const token = await getAccessTokenSilently();
      await updatePropertiesArteFinalMaster(token, idArte, {
        linkEditable: editableLink,
        linkPdf: pdfLink,
      });
      toast.success('Links modificados correctamente');

      queryClient.invalidateQueries(['info-arte-final', idArte]);
      setIsModalOpen(false);
    } catch (e) {
      toast.error('Error al modificar los links. Inténtelo de nuevo');

      console.error(e);
    }
  };

  return (
    <>
      <div className="button-edit-arte-final" style={{ right: '5rem' }}>
        {userRole === 'MasterAdmin' && (
          <CustomButton onClick={() => setIsModalOpen(true)}>
            <AttachFileIcon style={{ cursor: 'pointer', color: 'white' }} />
          </CustomButton>
        )}
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleOnclose}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            background: 'white',
            borderRadius: '.2rem',
            padding: '2rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <h1>Añadir links al arte final</h1>
          <TextField label="Link PDF" value={pdfLink} onChange={(e) => handleOnChange(e, setPdfLink)} />
          <TextField label="Link editable" value={editableLink} onChange={(e) => handleOnChange(e, setEditableLink)} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" color="error" style={{ marginTop: '1rem' }} onClick={handleOnclose}>
              Cancelar
            </Button>
            <Button variant="contained" color="error" style={{ marginTop: '1rem' }} onClick={handleOnSubmit}>
              Guardar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddLinksToArteFinal;
