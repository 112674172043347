import { Tab, Tabs } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

const TabsNavigate = () => {
  const location = useLocation();

  const tabsInfo = [
    { label: 'Gestión de artículos', path: '/master/articulos' },
    { label: 'Gestión de centros', path: '/master/tienda' },
    { label: 'Gestión de clusters', path: '/master/clusters' },
    { label: 'Gestión de categorias', path: '/master/categorias' },
    { label: 'Gestión de materiales', path: '/master/materiales' },
    { label: 'Gestión de proveedores', path: '/master/proveedores' },
    { label: 'Gestión de archivos', path: '/master/archivos' },
  ];

  const currentTabIndex = tabsInfo.findIndex((tab) => location.pathname.includes(tab.path));

  return (
    <Tabs value={currentTabIndex}>
      {tabsInfo.map((tab, index) => (
        <NavLink key={index} className={'tabs-navigate-admin'} to={tab.path}>
          <Tab label={tab.label} />
        </NavLink>
      ))}
    </Tabs>
  );
};

export default TabsNavigate;
