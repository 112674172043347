import { createContext, useState } from 'react';

export const GestionProveedorContext = createContext();

export const GestionProveedorProvider = ({ children }) => {
  const [inputValueSearch, setInputValueSearch] = useState(null);
  const [inputAfter, setInputAfter] = useState('');

  const data = {
    inputValueSearch,
    setInputValueSearch,
    inputAfter,
    setInputAfter,
  };

  return <GestionProveedorContext.Provider value={data}>{children}</GestionProveedorContext.Provider>;
};
