import { createOrder, createOrderItem } from '../../api/carteleriaApi';

export const handleClickBackPedidosPilotados = (navigate, location) => {
  // echar un nivel de ruta para atras
  const hasTrailingSlash = location.pathname.endsWith('/');
  if (hasTrailingSlash) {
    navigate(location.pathname.split('/').slice(0, -2).join('/'));
  } else {
    navigate(location.pathname.split('/').slice(0, -1).join('/'));
  }
};

export const handleClickAddPedidoPilotado = (navigate, location) => {
  const hasTrailingSlash = location.pathname.endsWith('/');
  if (!hasTrailingSlash) {
    navigate(`${location.pathname}/add-pedido-pilotado`);
  } else {
    navigate(`${location.pathname}add-pedido-pilotado`);
  }
};

export const handleClickAddFormat = (params) => {
  // al añadirlo tenemos que añadirlo tambien con el index para luego poder recuperar la posicion original en el aaffArray

  const aaffIndex = params.aaffSelected.findIndex((a) => a._id === params.aaff._id);

  if (aaffIndex === -1) {
    params.setAaffSelected((prev) => [...prev, { ...params.aaff, formats: [{ ...params.format, quantity: 1 }] }]);
  }
  if (aaffIndex !== -1) {
    const formatIndex = params.aaffSelected[aaffIndex].formats.findIndex((f) => f._id === params.format._id);
    if (formatIndex === -1) {
      params.setAaffSelected((prev) => {
        const temp = [...prev];
        temp[aaffIndex].formats.push({ ...params.format, quantity: 1 });
        return temp;
      });
    }
  }

  // una vez lo hemos añadido buscamos el formato en el aaffArray y su propiedad selected la cambiamos a true
  const aaffArrayIndex = params.aaffArray.findIndex((a) => a._id === params.aaff._id);
  const formatArrayIndex = params.aaffArray[aaffArrayIndex].formats.findIndex((f) => f._id === params.format._id);
  params.setAaffArray((prev) => {
    const temp = [...prev];
    temp[aaffArrayIndex].formats[formatArrayIndex].selected = true;
    return temp;
  });
};

export const handleClickAddTienda = (params) => {
  const clusterIndex = params.tiendaSelected.findIndex((c) => c._id._id === params.cluster._id._id);

  if (clusterIndex === -1) {
    params.setTiendaSelected((prev) => [...prev, { ...params.cluster, centers: [params.center] }]);
  }

  if (clusterIndex !== -1) {
    const centerIndex = params.tiendaSelected[clusterIndex].centers.findIndex((t) => t._id === params.center._id);
    if (centerIndex === -1) {
      params.setTiendaSelected((prev) => {
        const temp = [...prev];
        temp[clusterIndex].centers.push(params.center);
        return temp;
      });
    }
  }

  const tiendaArrayIndex = params.tiendaArray.findIndex((c) => c._id._id === params.cluster._id._id);
  const centerArrayIndex = params.tiendaArray[tiendaArrayIndex].centers.findIndex((t) => t._id === params.center._id);

  params.setTiendaArray((prev) => {
    const temp = [...prev];
    temp[tiendaArrayIndex].centers[centerArrayIndex].selected = true;
    return temp;
  });
};

export const handleClickAddCluster = (params) => {
  // tenemos que añadir el cluster entero a la lista de seleccionados

  const clusterIndex = params.tiendaSelected.findIndex((c) => c._id._id === params.cluster._id._id);

  if (clusterIndex === -1) {
    // si no existe lo añadimos
    params.setTiendaSelected((prev) => [...prev, params.cluster]);
  }

  if (clusterIndex !== -1) {
    // si existe solo añadimos los centers que falten (ya que en este caso no le pasamos un unico center sino un cluster entero) asi que vamos a añadir unicamente los que no esten selected
    const centers = params.cluster.centers.filter(
      (c) => !params.tiendaSelected[clusterIndex].centers.some((t) => t._id === c._id),
    );

    if (centers.length > 0) {
      params.setTiendaSelected((prev) => {
        const temp = [...prev];
        temp[clusterIndex].centers = [...temp[clusterIndex].centers, ...centers];
        return temp;
      });
    }
  }

  // en tiendaArray todos los centers que no esten en selected: true los pondremos
  params.setTiendaArray((prev) => {
    const temp = [...prev];

    const clusterIndex = temp.findIndex((c) => c._id._id === params.cluster._id._id);
    temp[clusterIndex].centers.map((center) => {
      if (center.selected === false) {
        center.selected = true;
      }
    });
    return temp;
  });
};

export const handleClickRemoveCluster = (params) => {
  const clusterIndex = params.tiendaArray.findIndex((c) => c._id._id === params.cluster._id._id);
  params.setTiendaArray((prev) => {
    const temp = [...prev];

    temp[clusterIndex].centers.forEach((center) => {
      center.selected = false;
    });
    return temp;
  });
  params.setTiendaSelected((prev) => prev.filter((c) => c._id._id !== params.cluster._id._id));
};

export const handleRemoveFormat = (params) => {
  const aaffIndex = params.aaffSelected.findIndex((a) => a._id === params.aaff._id);
  const formatIndex = params.aaffSelected[aaffIndex].formats.findIndex((f) => f._id === params.format._id);

  if (params.aaffSelected[aaffIndex].formats.length === 1) {
    params.setAaffSelected((prev) => prev.filter((a) => a._id !== params.aaff._id));
  } else {
    params.setAaffSelected((prev) => {
      const temp = [...prev];
      temp[aaffIndex].formats = temp[aaffIndex].formats.filter((f) => f._id !== params.format._id);
      return temp;
    });
  }

  // DESPUES DE ELIMINAR BUSCAMOS EL FORMATO EN EL AAFFARRAY Y SU PROPIEDAD SELECTED LA CAMBIAMOS A FALSE
  const aaffArrayIndex = params.aaffArray.findIndex((a) => a._id === params.aaff._id);
  const formatArrayIndex = params.aaffArray[aaffArrayIndex].formats.findIndex((f) => f._id === params.format._id);
  params.setAaffArray((prev) => {
    const temp = [...prev];
    temp[aaffArrayIndex].formats[formatArrayIndex].selected = false;
    return temp;
  });
};

export const handleRemoveTienda = (params) => {
  const clusterIndex = params.tiendaSelected.findIndex((c) => c._id._id === params.cluster._id._id);
  const centerIndex = params.tiendaSelected[clusterIndex].centers.findIndex((t) => t._id === params.center._id);

  if (params.tiendaSelected[clusterIndex].centers.length === 1) {
    params.setTiendaSelected((prev) => prev.filter((c) => c._id._id !== params.cluster._id._id));
  } else {
    params.setTiendaSelected((prev) => {
      let temp = JSON.parse(JSON.stringify(prev));
      const clusterIndex = temp.findIndex((c) => c._id._id === params.cluster._id._id);

      if (clusterIndex !== -1) {
        temp[clusterIndex].centers = temp[clusterIndex].centers.filter((t) => t._id !== params.center._id);
      }

      return temp;
    });
  }

  const tiendaArrayIndex = params.tiendaArray.findIndex((c) => c._id._id === params.cluster._id._id);
  const centerArrayIndex = params.tiendaArray[tiendaArrayIndex].centers.findIndex((t) => {
    return t._id == params.center._id;
  });

  // if (centerArrayIndex === -1) return;

  params.setTiendaArray((prev) => {
    let temp2 = JSON.parse(JSON.stringify(prev));
    const tiendaArrayIndex = temp2.findIndex((c) => c._id._id === params.cluster._id._id);
    const centerArrayIndex = temp2[tiendaArrayIndex].centers.findIndex((t) => t._id === params.center._id);

    if (centerArrayIndex !== -1) {
      temp2[tiendaArrayIndex].centers[centerArrayIndex].selected = false;
    }

    return temp2;
  });
};

export const handleChangeSearch = (value, setValueSearch) => {
  setValueSearch(value);
};

export const handleSearching = (valueSearch, setValueSearchAfter) => {
  // si tiene mas de 3 caracteres se hace la busqueda
  if (valueSearch.length > 2) {
    setValueSearchAfter(valueSearch);
  }
};

export const handleCleanSearch = (setValueSearch, setValueSearchAfter) => {
  setValueSearch('');
  setValueSearchAfter(null);
};

export const nextDisabled = (aaffSelected, tiendaSelected, asociateData, currentStep) => {
  // Si no hay artes seleccionados, devolvemos true
  if (currentStep === 0 && aaffSelected.length === 0) return true;
  // Si no hay tiendas seleccionadas, devolvemos true
  if (currentStep === 1 && tiendaSelected.length === 0) return true;

  // Si estamos en el tercer paso, realizamos la lógica para comprobar que todos los artes tengan un valor mayor a 0 en al menos un centro
  if (currentStep === 2) {
    // Obtener todas las claves de los artes (excepto 'center')
    if (asociateData.length === 0) return true;
    const keys = Object.keys(asociateData[0]).filter((key) => key !== 'center' && key !== 'centerType');

    // Verificar que todos los artes tengan al menos un valor mayor a cero en al menos un centro
    const allArtesHaveValue = keys.every((key) => asociateData.some((row) => row[key] > 0));

    // Verificar que todos los centros tengan al menos un valor mayor a cero en al menos un arte
    const allCentersHaveValue = asociateData.every((row) => keys.some((key) => row[key] > 0));

    // Si tanto todos los artes como todos los centros tienen al menos un valor mayor a cero, devolver false
    console.log('allArtesHaveValue', allArtesHaveValue);
    console.log('allCentersHaveValue', allCentersHaveValue);
    console.log('!allArtesHaveValue && !allCentersHaveValue', !(allArtesHaveValue && allCentersHaveValue));
    return !(allArtesHaveValue && allCentersHaveValue);
  }

  // Por defecto, retornamos false

  return false;
};

export const changeCollapseCluster = (clusterId, setTiendaArray) => {
  setTiendaArray((prev) => {
    const temp = [...prev];
    const clusterIndex = temp.findIndex((c) => c._id._id === clusterId);
    temp[clusterIndex].collapse = !temp[clusterIndex].collapse;
    return temp;
  });
};

function generateUniqueID() {
  const timestamp = Date.now().toString(36); // Convertimos la marca de tiempo actual a base 36
  const randomString = Math.random().toString(36).substr(2, 9); // Generamos una cadena aleatoria y la cortamos para tener 9 caracteres

  // Combinamos la marca de tiempo con la cadena aleatoria y la cortamos para tener una longitud de aproximadamente 25 caracteres
  const uniqueID = timestamp + randomString;
  return uniqueID.substr(0, 25);
}

export const handleCreatePilotado = async (asociateData, user, token) => {
  try {
    const pilotadoId = generateUniqueID();
    const orderPromises = [];

    for (const row of asociateData) {
      const order = {
        pilotadoId,
        center: row['center'].split('%')[0],
        user: {
          id: user.sub,
          name: user.name || null,
          email: user.email,
        },
      };

      const resOrderPromise = createOrder(token, order).then((resOrder) => {
        const orderItems = Object.keys(row)
          .filter((key) => key !== 'center' && key !== 'centerType')
          .map((key) => ({
            order: resOrder.data._id,
            format: key,
            quantity: row[key],
          }));
        return Promise.all(orderItems.map((orderItem) => createOrderItem(token, orderItem)));
      });

      orderPromises.push(resOrderPromise);
    }

    await Promise.all(orderPromises);
  } catch (err) {
    console.error('Error al crear el pedido pilotado: ', err.message);
    throw new Error('Error al crear el pedido pilotado');
  }
};
