import { createContext, useEffect, useState } from 'react';

export const PilotadosContext = createContext();

export const PilotadosProvider = ({ children }) => {
  /* -------------------------------------------------------------------------- */
  /*                    ESTADOS PARA LOS FILTROS DE PILOTADOS                   */
  /* -------------------------------------------------------------------------- */
  const [centersFilter, setCentersFilter] = useState([]);
  const [createStartDateFilter, setCreateStartDateFilter] = useState(null);
  const [createEndDateFilter, setCreateEndDateFilter] = useState(null);
  const [orderFilter, setOrderFilter] = useState({});

  /* -------------------------------------------------------------------------- */
  /*                         ESTADOS PARA CREAR PILOTADO                        */
  /* -------------------------------------------------------------------------- */
  const [aaffSelected, setAaffSelected] = useState([]);
  const [tiendaSelected, setTiendaSelected] = useState([]);
  const [asociateData, setAsociateData] = useState([]); // [ { center: 'center', aaff: 'aaff', format: 'format', quantity: 0 }

  /* -------------------------------------------------------------------------- */
  /*                   ESTADOS PARA LOS FILTROS DE UN PILOTADO                  */
  /* -------------------------------------------------------------------------- */
  const [orderFilterPilotado, setOrderFilterPilotado] = useState({});
  const [valueCenterSearch, setValueCenterSearch] = useState('');
  const [valueCenterSearchAfter, setValueCenterSearchAfter] = useState(null);

  useEffect(() => {
    setAsociateData([]);
  }, [tiendaSelected, aaffSelected]);

  const data = {
    centersFilter,
    setCentersFilter,
    createStartDateFilter,
    setCreateStartDateFilter,
    createEndDateFilter,
    setCreateEndDateFilter,
    orderFilter,
    setOrderFilter,
    aaffSelected,
    setAaffSelected,
    tiendaSelected,
    setTiendaSelected,
    asociateData,
    setAsociateData,
    orderFilterPilotado,
    setOrderFilterPilotado,
    valueCenterSearch,
    setValueCenterSearch,
    valueCenterSearchAfter,
    setValueCenterSearchAfter,
  };

  return <PilotadosContext.Provider value={data}>{children}</PilotadosContext.Provider>;
};
