import chroma from 'chroma-js';

// Función para generar una paleta de colores armoniosos a partir de un color base
export function generateBackground(baseColor, numColores, endColor) {
    // Convertir el color base en un objeto de color de chroma.js
    const color = chroma(baseColor);

    // Generar una escala de colores armoniosos a partir del color base
    const colors = chroma.scale([color.hex(), endColor]).mode('lab').colors(numColores);

    // Devolver la lista de colores en formato hex
    return colors;
}

// export function generateBorder(baseColor, numColores, endColor) {
//     // Convertir el color base en un objeto de color de chroma.js
//     const color = chroma(baseColor);

//     // Generar una escala de colores armoniosos a partir del color base
//     const colors = chroma.scale([color.hex(), endColor]).mode('lab').colors(numColores);

//     // Devolver la lista de colores en formato hex
//     return colors;
// }


export function getTextColorBasedOnBackground(hexColor) {
    // Convertir el color hexadecimal a RGB
    let r = parseInt(hexColor.slice(1, 3), 16);
    let g = parseInt(hexColor.slice(3, 5), 16);
    let b = parseInt(hexColor.slice(5, 7), 16);

    // Calcular el brillo relativo del color (fórmula de luminosidad)
    let brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Determinar si el texto debería ser blanco o negro según el brillo
    return brightness > 128 ? '#000000' : '#FFFFFF';
}
