import { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const MercadoGraphicContext = createContext();

export const MercadoGraphicProvider = ({ children }) => {
  const [graficoArray, setGraficoArray] = useState([1]);
  const location = useLocation();
  const [areaData, setAreaData] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [shelvesData, setShelvesData] = useState([]);

  const data = {
    graficoArray,
    setGraficoArray,
    location,
    areaData,
    setAreaData,
    moduleData,
    setModuleData,
    shelvesData,
    setShelvesData,
  };
  return <MercadoGraphicContext.Provider value={data}>{children}</MercadoGraphicContext.Provider>;
};
