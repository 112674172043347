import React from 'react';
import { Modal, Typography } from '@mui/material';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { useGetAllCenterTypes } from './useGetAllCenterTypes';
import useGetAllRegionals from './useGetAllRegionals';
import useCreateCenter from './useCreateCenter';
import useEditCenter from './useEditCenter';
import GlobalSpinner from '../../../Components2/common/GlobalSpinner';
import useValidateCenter from './useValidateCenter';
import toast from 'react-hot-toast';

const isOpen = true;

function CenterModal({ validationSchema, mode, centerId }) {
  const navigate = useNavigate();
  const isEditMode = mode === 'edit';

  const handleOnClose = () => {
    navigate('/master/tienda');
  };

  const { postCenter, isLoading: isCreating } = useCreateCenter();
  const { editingCenter, center, isLoading: isFetchingCenter } = useEditCenter(isEditMode, centerId);
  const { checkCenterTypeStatus } = useValidateCenter();

  const isLoading = isCreating || (isEditMode && isFetchingCenter);

  const initialValues = {
    code: center?.code || '',
    municipio: center?.municipio || '',
    provincia: center?.provincia || '',
    imagenAnterior: center?.imagenAnterior || false,
    imagenNueva: center?.imagenNueva || false,
    centroCostes: center?.centroCostes || '',
    name: center?.name || '',
    calle: center?.calle || '',
    comunidad: center?.comunidad || '',
    centerType: center?.centerType || '',
    active: center?.active != null ? center?.active : true,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log('VEAMOS LOS VALUES: ', values);
      const validateCheck = await checkCenterTypeStatus(values.active, values.centerType);

      if (validateCheck.centerTypeActive) {
        if (isEditMode) {
          editingCenter(values, {
            onSuccess: () => {
              handleOnClose();
            },
          });
        } else {
          postCenter(values, {
            onSuccess: () => {
              handleOnClose();
            },
          });
        }
      } else {
        toast.error('No se puede guardar el centro como Activo porque el cluster asociado no lo esta', {
          id: 'error-center-active',
        });
      }
    },
    enableReinitialize: true,
  });

  const { regionals } = useGetAllRegionals();
  const { allCenterTypes } = useGetAllCenterTypes();

  const getErrorText = (field) => {
    return formik.touched[field] && formik.errors[field] ? formik.errors[field] : '';
  };

  return (
    <Modal open={isOpen} onClose={handleOnClose}>
      <div className="create-center-container-modal">
        {!isLoading ? (
          <div className="center-form-title">
            <Typography
              variant="h4"
              component="h2"
              align="center"
              sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
            >
              {isEditMode ? 'EDITAR CENTRO' : 'CREAR CENTRO'}
            </Typography>
            <form className="center-form" onSubmit={formik.handleSubmit}>
              <div className="center-row-md">
                <Colxx xxs="4">
                  <TextField
                    id="code"
                    name="code"
                    label="Código"
                    variant="outlined"
                    required
                    fullWidth
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={getErrorText('code')}
                  />
                </Colxx>

                <Colxx xxs="4">
                  <TextField
                    id="name"
                    name="name"
                    label="Nombre*"
                    variant="outlined"
                    fullWidth
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={getErrorText('name')}
                  />
                </Colxx>

                <Colxx xxs="4">
                  <TextField
                    id="centroCostes"
                    name="centroCostes"
                    label="Centro Costes"
                    variant="outlined"
                    fullWidth
                    value={formik.values.centroCostes}
                    onChange={formik.handleChange}
                    error={formik.touched.centroCostes && Boolean(formik.errors.centroCostes)}
                    helperText={getErrorText('centroCostes')}
                  />
                </Colxx>
              </div>

              <div className="center-row-md">
                <Colxx xxs="12">
                  <TextField
                    id="calle"
                    name="calle"
                    type="text"
                    label="Calle..."
                    variant="outlined"
                    fullWidth
                    value={formik.values.calle}
                    onChange={formik.handleChange}
                    error={formik.touched.calle && Boolean(formik.errors.calle)}
                    helperText={getErrorText('calle')}
                  />
                </Colxx>
              </div>

              <div className="center-row-md">
                <Colxx xxs="4">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={formik.touched.comunidad && Boolean(formik.errors.comunidad)}
                  >
                    <InputLabel htmlFor="comunidad" shrink={formik.values.comunidad !== ''}>
                      Comunidad*
                    </InputLabel>
                    <Select
                      id="comunidad"
                      name="comunidad"
                      value={formik.values.comunidad}
                      onChange={formik.handleChange}
                      displayEmpty
                      input={<OutlinedInput label="Comunidad" />}
                    >
                      {regionals?.data?.map((regional) => (
                        <MenuItem key={regional._id} value={regional._id}>
                          {regional.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{getErrorText('comunidad')}</FormHelperText>
                  </FormControl>
                </Colxx>

                <Colxx xxs="4">
                  <TextField
                    id="municipio"
                    name="municipio"
                    label="Municipio"
                    variant="outlined"
                    fullWidth
                    value={formik.values.municipio}
                    onChange={formik.handleChange}
                    error={formik.touched.municipio && Boolean(formik.errors.municipio)}
                    helperText={getErrorText('municipio')}
                  />
                </Colxx>

                <Colxx xxs="4">
                  <TextField
                    id="provincia"
                    name="provincia"
                    label="Provincia"
                    variant="outlined"
                    fullWidth
                    value={formik.values.provincia}
                    onChange={formik.handleChange}
                    error={formik.touched.provincia && Boolean(formik.errors.provincia)}
                    helperText={getErrorText('provincia')}
                  />
                </Colxx>
              </div>

              <div className="center-row-md">
                <Colxx xxs="4">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={formik.touched.imagenNueva && Boolean(formik.errors.imagenNueva)}
                  >
                    <InputLabel htmlFor="imagenNueva" shrink={formik.values.imagenNueva !== ''}>
                      Es imagen nueva*
                    </InputLabel>
                    <Select
                      id="imagenNueva"
                      name="imagenNueva"
                      value={formik.values.imagenNueva}
                      onChange={formik.handleChange}
                      displayEmpty
                      input={<OutlinedInput label="Es imagen nueva" />}
                    >
                      <MenuItem value={true}>Si</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                    <FormHelperText>{getErrorText('imagenNueva')}</FormHelperText>
                  </FormControl>
                </Colxx>

                <Colxx xxs="4">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={formik.touched.imagenAnterior && Boolean(formik.errors.imagenAnterior)}
                  >
                    <InputLabel htmlFor="imagenAnterior" shrink={formik.values.imagenAnterior !== ''}>
                      Es imagen anterior*
                    </InputLabel>
                    <Select
                      id="imagenAnterior"
                      name="imagenAnterior"
                      value={formik.values.imagenAnterior}
                      onChange={formik.handleChange}
                      displayEmpty
                      input={<OutlinedInput label="Es imagen anterior" />}
                    >
                      <MenuItem value={true}>Si</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                    <FormHelperText>{getErrorText('imagenAnterior')}</FormHelperText>
                  </FormControl>
                </Colxx>

                <Colxx xxs="4">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={formik.touched.active && Boolean(formik.errors.active)}
                  >
                    <InputLabel id="active">Activo*</InputLabel>
                    <Select
                      labelId="active"
                      id="active"
                      name="active"
                      label="Activo"
                      value={formik.values.active}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={true}>Sí</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                    <FormHelperText>{getErrorText('active')}</FormHelperText>
                  </FormControl>
                </Colxx>
              </div>

              <div className="center-row-md">
                <Colxx xxs="6">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={formik.touched.centerType && Boolean(formik.errors.centerType)}
                  >
                    <InputLabel htmlFor="centerType" shrink={formik.values.centerType !== ''}>
                      Cluster*
                    </InputLabel>
                    <Select
                      id="centerType"
                      name="centerType"
                      value={formik.values.centerType}
                      onChange={formik.handleChange}
                      displayEmpty
                      input={<OutlinedInput label="Cluster*" />}
                    >
                      {allCenterTypes?.map((centerType) => (
                        <MenuItem key={centerType._id} value={centerType._id}>
                          {centerType.type}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{getErrorText('centerType')}</FormHelperText>
                  </FormControl>
                </Colxx>
              </div>

              <div className="button-group-tiendas">
                <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
                  Confirmar
                </Button>
                <Button type="button" variant="contained" color="error" endIcon={<CloseIcon />} onClick={handleOnClose}>
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className="spinner-container">
            <GlobalSpinner />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default CenterModal;
