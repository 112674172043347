import { useAuth0 } from '@auth0/auth0-react';
import { getAllRegionales } from '../api/carteleriaApi';
import { useQuery } from '@tanstack/react-query';

export const useGetRegionals = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['regionales'],
    queryFn: async () => {
      let token = await getAccessTokenSilently();
      return getAllRegionales(token);
    },
  });
};
