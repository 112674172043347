import React from 'react';
import CartItemImageArteFinal from './CartItemImageArteFinal';

const ChildrenFormat = ({ childrenFormats, quantity }) => {
  return childrenFormats.map((childrenFormat, index) => (
    <div
      style={{
        margin: '0 0 1rem 1rem',
        border: '1px solid rgba(0,0,0, 0.2)',
        padding: '1rem',
        display: 'flex',
        gap: '1rem',
      }}
    >
      <CartItemImageArteFinal
        itemId={childrenFormat.arteFinal._id}
        itemCode={childrenFormat.arteFinal.code}
        height={150}
        width={100}
        styleContainer={{
          width: '100px',
          height: '150px',
          border: '1px solid #e5e5e5',
        }}
        img={childrenFormat.arteFinal.imglink}
        styleImg={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />

      {/* LO PRIMERO ES INDICAR EL NOMBRE DEL ARTE FINAL JUNTO A SU FORMATO */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <p style={{ fontWeight: 700, fontSize: '1.075rem', marginBottom: '0' }}>{childrenFormat.arteFinal.name}</p>
        <p style={{ marginBottom: '0' }}>{childrenFormat.formatoName}</p>
        <p style={{ marginBottom: '0' }}>Cantidad: {quantity}</p>
        <p style={{ marginBottom: '0' }}>Precio unidad: {childrenFormat.formatoPrice.toFixed(2)} €</p>
      </div>
    </div>
  ));
};

export default ChildrenFormat;
