import { postArteFinal, postArteFinalImprimible, uploadFileDigitalOcean } from '../../api/carteleriaApi';

export const createArteFinalImprimible = async (params) => {
  try {
    const { image, ...rest } = params.body;

    if (image) {
      const img = await uploadFileDigitalOcean(image, `aaffimg2/${rest.code}`);
      if (img) {
        await postArteFinalImprimible({ token: params.token, body: { ...rest, imglink: img.url } });
      }
    } else {
      await postArteFinalImprimible({ token: params.token, body: rest });
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const createArteFinal = async (params) => {
  try {
    const { bodyArteFinal, bodyFormat, bodyProviderFormat } = params;
    const { image, ...rest } = bodyArteFinal;

    if (bodyFormat.name == '') {
      bodyFormat.name = 'Sin definir';
    }

    if (image) {
      const img = await uploadFileDigitalOcean(image, `aaffimg2/${rest.code}`);
      if (img) {
        await postArteFinal({
          token: params.token,
          bodyArteFinal: { ...rest, imglink: img.url },
          bodyFormat,
          bodyProviderFormat,
        });
      }
    } else {
      await postArteFinal({ token: params.token, bodyArteFinal: rest, bodyFormat, bodyProviderFormat });
    }
  } catch (err) {
    throw new Error(err);
  }
};
