import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { getFilterCentersUser, getFilterStatus } from '../../../api/carteleriaApi';
import OrderFilter from '../../common/OrderFilter';
import OrderDateFilter from '../../common/OrderDateFilter';
import { OrdersFiltersUserContext } from '../../../context/OrdersFiltersUserContext';

export const FilterMisSolicitudes = () => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    statusFilter,
    setStatusFilter,
    centersFilter,
    setCentersFilter,
    createStartDateFilter,
    setCreateStartDateFilter,
    createEndDateFilter,
    setCreateEndDateFilter,
    deliveryStartDateFilter,
    setDeliveryStartDateFilter,
    deliveryEndDateFilter,
    setDeliveryEndDateFilter,
  } = useContext(OrdersFiltersUserContext);

  const [searchValue, setSearchValue] = useState('');
  const [filteredCenters, setFilteredCenters] = useState([]);
  const [openMenu, setOpenMenu] = useState(null); // Estado para rastrear cuál menú está abierto

  const { data: centers, isLoading: loadingCenters } = useQuery(['filter-centers'], async () => {
    const token = await getAccessTokenSilently();
    return await getFilterCentersUser(token);
  });

  const { data: status, isLoading: loadingStatus } = useQuery(['filter-status'], async () => {
    const token = await getAccessTokenSilently();
    return await getFilterStatus(token);
  });

  useEffect(() => {
    if (loadingStatus) return;
    const newStatusFilter = status
      // Filtrar los estados que no son "Montado" ni "Descartado"
      .filter((status) => status.label !== 'Montado' && status.label !== 'Descartado')
      // Extraer los valores de los estados restantes
      .map((status) => status.value);

    // Actualizar el estado solo si hay nuevos estados filtrados
    if (newStatusFilter && newStatusFilter.length > 0) {
      setStatusFilter(newStatusFilter);
    }
  }, [loadingStatus, status]);

  useEffect(() => {
    const normalizeString = (str) => {
      return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
    };

    if (centers) {
      if (searchValue.length >= 3) {
        const normalizedSearchValue = normalizeString(searchValue);
        const filtered = centers.filter((center) => normalizeString(center.label).includes(normalizedSearchValue));
        setFilteredCenters(filtered);
      } else {
        setFilteredCenters(centers);
      }
    }
  }, [searchValue, centers]);

  const handleMenuOpen = (menuName) => {
    setOpenMenu(menuName);
  };

  return (
    <div className="options options-filter">
      <h4 style={{ fontWeight: 'bold' }}>Filtros</h4>
      <>
        {/* FILTRO DE CENTROS */}
        <OrderFilter
          search={true}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          loading={loadingCenters}
          filterName="Centro"
          filterList={filteredCenters}
          filterValues={centersFilter}
          setFilterValues={setCentersFilter}
          openMenu={openMenu}
          handleMenuOpen={handleMenuOpen}
          menuName="Centro" // Nombre único para el menú
        />

        {/* FILTRO DE ESTADO */}

        <OrderFilter
          loading={loadingStatus}
          filterName="Estado"
          filterList={status}
          filterValues={statusFilter}
          setFilterValues={setStatusFilter}
          openMenu={openMenu}
          handleMenuOpen={handleMenuOpen}
          menuName="Estado" // Nombre único para el menú
        />

        {/* FILTRO DE FECHA DE CREACION */}
        <OrderDateFilter
          filterName="Fecha de creación"
          startDate={createStartDateFilter}
          setStartDate={setCreateStartDateFilter}
          finishDate={createEndDateFilter}
          setFinishDate={setCreateEndDateFilter}
        />

        {/* FILTRO DE FECHA DE ENTREGA */}

        <OrderDateFilter
          delivery={true}
          filterName="Fecha de entrega"
          startDate={deliveryStartDateFilter}
          setStartDate={setDeliveryStartDateFilter}
          finishDate={deliveryEndDateFilter}
          setFinishDate={setDeliveryEndDateFilter}
        />
      </>
    </div>
  );
};

export default FilterMisSolicitudes;
