import React, { useContext } from 'react';
import { CardContent } from '@mui/material';
import { UserContext } from '../../context/UserProvider';
import DownloadIcon from '@mui/icons-material/Download';

const ContentArteDescargable = ({ arteFinal }) => {
  const { centerConfirmed } = useContext(UserContext);

  return (
    <div className="content-arte-not-descargable">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          <p style={{ fontWeight: 'bold' }}>{arteFinal.nameArteFinal}</p>
        </div>

        {/* TODO: Recorremos los lenguages de pdf que tenemos y mostramos unos u otros segun la comunidad de nuestra tienda */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {Object.entries(arteFinal.pdfLanguages).map(([key, value]) => {
            let newKey = '';

            switch (key) {
              case 'castellano':
                newKey = 'Castellano';
                break;
              case 'Cataluña':
                newKey = 'Catalán';
                break;
              case 'País Vasco':
                newKey = 'Euskera';
                break;
              case 'Galicia':
                newKey = 'Gallego';
                break;
              default:
                break;
            }
            return (
              (key === centerConfirmed.comunidad.name || key === 'castellano') && (
                <a href={value || '#'} target="_blank" key={key}>
                  <button className={`${!value && 'button-24-disabled'} button-24`} role="button" disabled={!value}>
                    <span>{newKey}</span>
                    <DownloadIcon />
                  </button>
                </a>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContentArteDescargable;
