import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext, useState } from 'react';
import { UserContext } from '../../../../context/UserProvider';
import { useParams } from 'react-router-dom';
import SimpleModal from '../../../../ui/Modal/SimpleModal';
import { useDeleteProviderFormat } from '../../../../hooks/useProviderFormat';

const DeleteFormat = ({ formato, numProviderFormat }) => {
  const { idArte } = useParams();
  const { userRole } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutation } = useDeleteProviderFormat(formato._id, idArte);

  const handleDeleteFormat = async (e) => {
    e.stopPropagation();
    mutation.mutate();
  };

  return (
    <>
      {userRole === 'MasterAdmin' && (
        <IconButton
          size="small"
          aria-label="delete"
          onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(true);
          }}
          className={`delete-format-tooltip-${formato._id}`}
        >
          <DeleteIcon fontSize="small" style={{ color: 'black' }} />
        </IconButton>
      )}
      <SimpleModal
        tooltip={numProviderFormat === 1}
        isModalOpen={isModalOpen}
        title="Eliminar proveedor"
        titleTooltip="Como es el único proveedor asociado al formato, no se puede eliminar. Si lo necesita, puede deshabilitarlo o editar sus datos."
        description="¿Estás seguro de que quieres eliminar el proveedor asociado al formato?"
        setIsModalOpen={setIsModalOpen}
        handleConfirm={handleDeleteFormat}
      />
    </>
  );
};

export default DeleteFormat;
