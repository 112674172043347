import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEditMaterial } from '../../../hooks/useMateriales';
import FormMaterial from './FormMaterial';
import { handleCloseModal, handleSubmitEditMaterial } from '../../../helpers/Material/materialHelper';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editMaterial } from '../../../api/materialApi';
import toast from 'react-hot-toast';

const EditMaterial = () => {
  const { idMaterial } = useParams();
  const { material, isLoading } = useEditMaterial(idMaterial);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    _id: '',
    nombre: '',
    active: true,
  });

  const mutation = useMutation(editMaterial, {
    onSuccess: () => {
      queryClient.invalidateQueries('gestion-materiales');
    },
    onError: (err) => {
      if (err.message === 'error-disable-format-associated') {
        navigate(`/master/materiales/${idMaterial}/error-edit-material`);
      } else if (err.message === 'duplicate') {
        toast.error('Ya existe un material con ese nombre');
      }
    },
  });

  useEffect(() => {
    if (!isLoading) {
      setFormData({
        _id: material.data._id,
        nombre: material.data.nombre,
        active: material.data.active,
      });
    }
  }, [isLoading]);

  if (isLoading) return;

  return (
    <FormMaterial
      title="Editar material"
      handleSubmit={(e) => handleSubmitEditMaterial({ e, mutation, formData, navigate, location })}
      formData={formData}
      handleChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
      handleCloseModal={() => handleCloseModal(navigate, location, 2)}
    />
  );
};

export default EditMaterial;
