import React, { useContext, useEffect } from 'react';
import { PointContext } from '../../context/PointContext';
import Loader from '../Loader/Loader';
import { viewer } from '../../helpers/viewerHelper';

const handleUrlById = (id) => {
  if (!id) return;
  return `https://drive.google.com/file/d/${id}/preview?embedded=true`;
};

const ImageCarteleria = ({ point }) => {
  console.log('point', point);
  const { isPdfContainerLoading, setIsPdfContainerLoading } = useContext(PointContext);

  // useEffect(() => {
  //   viewer.getProperties(point.dbId, res => console.log(res)).then((res) => console.log('res', res));
  // }, []);
  return (
    <div
      style={{
        height: '250px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h3 style={{ marginBottom: '.5rem' }}>{point.file.name}</h3>
      <iframe
        src={handleUrlById(point?.file?.id)}
        type="application/pdf"
        className="pdf-object"
        onLoad={() => setIsPdfContainerLoading(false)}
        style={{ visibility: isPdfContainerLoading ? 'hidden' : 'visible' }}
      ></iframe>
      {isPdfContainerLoading && <Loader className="loader-center-absolute" size={30} />}
    </div>
  );
};

export default ImageCarteleria;
