import { useParams } from 'react-router-dom';
import { useCantMoveCategory, useMoveAaffAndCategory } from '../../../hooks/useCategory';
import { Button, Modal } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import CategoryTree from './CategoryTree';
import { useContext, useState } from 'react';
import { GestionCategoryContext } from '../../../context/GestionCategoryContext';
import DeletePopover from '../../common/DeletePopover';
import { useCloseModal } from '../../../hooks/useCloseModal';

const CantMoveCategory = () => {
  const { idCategory, idParentCategory } = useParams();
  const { categoryMoveSelected } = useContext(GestionCategoryContext);
  const { categoryMove, categoryToMove, categoryTree, isLoading } = useCantMoveCategory(idCategory, idParentCategory);
  const closeModal = useCloseModal(3);
  const [movePopover, setMovePopover] = useState(false);
  const { mutation } = useMoveAaffAndCategory();

  if (isLoading) return;

  const handleClosePopover = () => setMovePopover(false);

  const handleMoveConfirm = () => {
    handleClosePopover();
    mutation.mutate({
      idCategory: idCategory,
      idCategoryToMove: categoryMoveSelected.id,
      idParentCategory: idParentCategory,
    });
  };

  return (
    <Modal open onClose={closeModal}>
      <div className="modal-move-category">
        <IoMdClose onClick={closeModal} size="18px" className="f-right pointer" />
        <div className="center-form">
          <p className="fs-italic">
            {`La categoría `}
            <strong>{categoryMove}</strong>
            {` no se puede mover a la categoría `}
            <strong>{categoryToMove}</strong>
            {` porque `}
            <strong>{categoryToMove}</strong>
            {` ya contiene artículos. Para mover `}
            <strong>{categoryMove}</strong>
            {`, primero selecciona la categoría destino, donde desea mover los artículos.`}
          </p>
        </div>
        {/* COMPONENTE PARA RENDERIZAR EL ARBOL DE CATEGORIAS */}
        <div className="container-category-tree">
          <CategoryTree categories={categoryTree} />
        </div>
        <div className="button-group-tiendas">
          <Button
            onClick={(e) => setMovePopover(e.currentTarget)}
            type="button"
            variant="contained"
            style={{ backgroundColor: !categoryMoveSelected ? '#DADADA' : '#3c4145' }}
            disabled={!categoryMoveSelected}
            endIcon={<SendIcon />}
          >
            Confirmar
          </Button>
          <Button type="button" variant="contained" color="error" endIcon={<CloseIcon />} onClick={closeModal}>
            Cancelar
          </Button>
        </div>
        <DeletePopover
          textCancel={'No'}
          textConfirm={'Sí'}
          textQuestion={`Se moveran todos los artículos de ${categoryToMove} a ${categoryMoveSelected?.name}. ¿Desea continuar?`}
          handleDeleteClose={handleClosePopover}
          deletePopover={movePopover}
          handleDeleteConfirm={handleMoveConfirm}
        />
      </div>
    </Modal>
  );
};

export default CantMoveCategory;
