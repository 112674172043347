import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { getAllCenterTypesMaster } from '../../api/carteleriaApi';
import { TableArtes } from './TableArtes';
import { useContext } from 'react';
import { HeaderMaster } from './HeaderMaster';
import { Pagination } from '@mui/material';
import FiltersArtes from './FiltersArtes';
import { MasterContext } from '../../context/MasterContext';
import { FilterMenuMaster } from './FilterMenuMaster';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LIMIT } from '../../utils/constants';
import SkeletonLoader from '../common/SkeletonLoader';
import BreadcrumbCategories from '../Categories/BreadcrumbCategories';

export const MasterContainer = () => {
  const {
    categorySelected,
    setCategorySelected,
    setSuperIdCategory,
    centerTypeSelected,
    setCenterTypeSelected,
    centerSelected,
    setCenterSelected,
    statusSelected,
    setStatusSelected,
    validateStatus,
    setValidateStatus,
    page,
    setPage,
    request,
    isFetching,
  } = useContext(MasterContext);

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: centerTypes, isFetching: fetchingCenterTypes } = useQuery(['center-types-master'], async () => {
    const token = await getAccessTokenSilently();
    return await getAllCenterTypesMaster(token);
  });

  const hanlePagePagination = (event, value) => {
    setPage(value);
  };

  const handleClicAddArte = () => {
    const hasTrailingSlash = location.pathname.endsWith('/');
    if (!hasTrailingSlash) {
      navigate(`${location.pathname}/add-artefinal`);
    } else {
      navigate(`${location.pathname}add-artefinal`);
    }
  };

  const handleMoveBack = (category) => {
    const matchIndex = categorySelected.findIndex((el) => el._id === category._id);

    if (matchIndex !== -1) {
      const newCategorySelected = categorySelected.slice(0, matchIndex + 1);
      setCategorySelected(newCategorySelected);
      setSuperIdCategory({
        _id: category._id,
        parentCategory: category.parentCategory,
      });
    }
  };

  const handleReset = () => {
    setCategorySelected([]);
    setSuperIdCategory(null);
  };

  return (
    <>
      <div className="container-master-user">
        <BreadcrumbCategories
          handleMoveBack={handleMoveBack}
          categorySelected={categorySelected}
          handleReset={handleReset}
        />
        <HeaderMaster handleClicAddArte={handleClicAddArte} />

        <div className="container-filter-filter-artes">
          <FilterMenuMaster />
          <div className="container-filter-artes">
            <FiltersArtes
              valueCenter={centerSelected}
              setValueCenter={setCenterSelected}
              valueCenterType={centerTypeSelected}
              setValueCenterType={setCenterTypeSelected}
              valueStatus={statusSelected}
              setValueStatus={setStatusSelected}
              centerTypes={centerTypes}
              fetchingCenterTypes={fetchingCenterTypes}
              validateStatus={validateStatus}
              setValidateStatus={setValidateStatus}
            />
            {!isFetching ? (
              request?.data?.length > 0 ? (
                <TableArtes data={request} centerTypes={centerTypes} />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    height: '60vh',
                  }}
                >
                  <h3> No hay resultados para tu búsqueda</h3>
                </div>
              )
            ) : (
              <div className="grid-container">
                {[...Array(36)].map((_, i) => (
                  <SkeletonLoader key={i} count={1} minHeight={'316px'} />
                ))}
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {request?.totalResults > 0 && !isFetching && (
            <Pagination
              showFirstButton
              showLastButton
              page={page}
              onChange={hanlePagePagination}
              count={Math.ceil(request?.totalResults / LIMIT)}
              color="error"
              variant="outlined"
              size="large"
              sx={{ margin: '2rem' }}
            />
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
};
