import { Modal } from '@mui/material';
import React from 'react';
import CustomButton from '../../../../ui/Button/CustomButton';

const ModalDeletePdf = ({ isModalOpen, setIsModalOpen, handleDeletePdf }) => {
  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} className="modal-pdf-wrapper">
      <div className="modal-pdf-container">
        <h1>Eliminar pdf</h1>
        <p>¿Estás seguro que quieres eliminar el pdf?</p>

        <div className="modal-pdf-buttons-container">
          <CustomButton onClick={() => setIsModalOpen(false)} variant="secondary">
            No
          </CustomButton>
          <CustomButton onClick={handleDeletePdf}>Sí</CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeletePdf;
