import { TextField, Button, Grid, Autocomplete, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import React, { useContext } from 'react';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAuth0 } from '@auth0/auth0-react';
import { updateOneFormat } from '../../../api/carteleriaApi';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import FormatoCompuesto from './FormatCompuesto';
import { FormatCompuestoContext } from '../../../context/FormatCompuestoContext';
import GlobalSpinner from '../../../Components2/common/GlobalSpinner';
import { useGetAllMaterials } from '../../../hooks/useMateriales';

export const InfoModalFormat = ({ data, handleCloseModalFormatos, refetch }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { idFormat, idArte } = useParams();
  const queryClient = useQueryClient();
  const { aaffSelected, required, compuestoValue } = useContext(FormatCompuestoContext);
  const { data: allMaterials, isLoading: loadingMaterials } = useGetAllMaterials();
  const validationSchema = yup.object().shape({
    code: yup.string().required('El código es obligatorio'),
    name: yup.string().required('El nombre es obligatorio'),
  });

  const initialValues = {
    code: data?.code,
    name: data?.name,
    active: data?.active,
    material: data?.material?._id || null,
    description: data?.description,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const token = await getAccessTokenSilently();
        const formattedValues = {
          ...values,
          material: values.material || null,
        };
        await updateOneFormat(token, idFormat, formattedValues, aaffSelected, required, compuestoValue);
        queryClient.invalidateQueries(['formats-arte-final', idArte]);
        toast.success('Formato actualizado con éxito');
        queryClient.invalidateQueries('info-arte-final');
        refetch();
        handleCloseModalFormatos();
      } catch (error) {
        toast.error(error.message, {
          id: 'update-format-error-active-not-providerformat-active',
        });
      }
    },
  });

  const handleAutocompleteChange = (event, newValue) => {
    formik.setFieldValue('material', newValue ? newValue._id : '');
  };

  return loadingMaterials ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
      <GlobalSpinner />
    </div>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="code"
            name="code"
            label="Código"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            disabled={data?.isCustom}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Medida"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            disabled={data?.isCustom}
          />
        </Grid>
        {!data?.isCustom && (
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={loadingMaterials ? [] : allMaterials?.data || []}
              getOptionLabel={(option) => option.nombre || ''}
              value={allMaterials?.data?.find((material) => material._id === formik.values.material) || null}
              onChange={handleAutocompleteChange}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              renderInput={(params) => <TextField {...params} label="Material" variant="outlined" fullWidth />}
              noOptionsText="Sin material"
              loading={loadingMaterials}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="status-format">Estado</InputLabel>
            <Select
              label="Estado"
              onChange={(e) => formik.setFieldValue('active', e.target.value)}
              labelId="status-format"
              value={formik.values.active}
            >
              <MenuItem value={true}>Activo</MenuItem>
              <MenuItem value={false}>Inactivo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormatoCompuesto />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
        <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
          Editar
        </Button>
        <Button variant="contained" color="error" endIcon={<CloseIcon />} onClick={handleCloseModalFormatos}>
          Cancelar
        </Button>
      </div>
    </form>
  );
};
