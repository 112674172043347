import { Tooltip } from 'react-tooltip';
import HeadingAaffSelector from './HeadingAaffSelector';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { handleRemoveSelectedAaff } from '../../../helpers/Category/gestionCategories';

const ContainerAaffSelected = ({ setAaffNoSelected, aaffNoSelected, aaffSelected, setAaffSelected }) => {
  return (
    <HeadingAaffSelector
      title="Artículos seleccionados"
      description="(Haz clic en el artículo para eliminarlo de la lista)"
    >
      <div className="container-aaff-add-masive">
        <div className="subcontainer-aaff-add-masive">
          <div className="container-aaff-selected">
            {aaffSelected.length === 0 ? (
              <div className="description-not-aaff-selected">
                <ImageNotSupportedIcon className="image-not-supported" />
                <p className="fs-14">Selecciona un artículo en el listado de disponibles para añadirlo al listado</p>
              </div>
            ) : (
              // TODO: Esto sacarlo y meterlo aqui
              aaffSelected.map((aaff, i) => (
                <div key={aaff._id}>
                  <div
                    onClick={() =>
                      handleRemoveSelectedAaff({
                        setAaffNoSelected,
                        aaffNoSelected,
                        aaff,
                        aaffSelected,
                        setAaffSelected,
                      })
                    }
                    className="row-aaff-add-masive pointer"
                  >
                    <p
                      className={`texto-aaff-pedido-pilotado ${
                        (aaff.code + ' - ' + aaff.name).length > 53 && `aaff-add-masive-selected-${aaff._id}`
                      }`}
                    >
                      {aaff.code} - {aaff.name}
                    </p>
                  </div>
                  <Tooltip anchorSelect={`.aaff-add-masive-selected-${aaff._id}`}>
                    {aaff.code + ' - ' + aaff.name}
                  </Tooltip>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </HeadingAaffSelector>
  );
};

export default ContainerAaffSelected;
