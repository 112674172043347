import React from 'react';
import { handleClickAddFormat } from '../../../../helpers/PedidoPilotado/pedidoPilotado';
import { Tooltip } from '@mui/material';

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const DbContentArteFinal = ({ aaffArray, setAaffArray, aaffSelected, setAaffSelected }) => {
  return aaffArray.map(
    (aaff, i) =>
      aaff.formats.some((format) => !format.selected) && (
        <div key={aaff._id + i}>
          <div
            className="aaff-pedido-pilotado"
            style={{
              height: '2rem',
              backgroundColor: '#F7F7F7',
              fontWeight: 'bold',
              borderBottom: i === aaffArray.length - 1 ? 'none' : '1px solid #cecece',
              display: 'flex',
              alignItems: 'center',
              userSelect: 'none',
              gap: '.5rem',
              paddingLeft: '1rem',
              width: '100%',
            }}
          >
            <Tooltip
              title={aaff.code + ' - ' + aaff.name}
              disableHoverListener={(aaff.code + ' - ' + aaff.name).length <= 45}
            >
              <p
                className={`texto-aaff-pedido-pilotado ${
                  (aaff.code + ' - ' + aaff.name).length > 45 && `aaff-pedido-pilotado-tooltip-${aaff._id}`
                }`}
                id={`texto-aaff-pedido-pilotado-${aaff._id}`}
              >
                {truncateText(aaff.code + ' - ' + aaff.name, 45)}
              </p>
            </Tooltip>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {aaff.formats.map(
              (format) =>
                !format.selected && (
                  <div
                    onClick={() =>
                      handleClickAddFormat({
                        aaffArray,
                        setAaffArray,
                        aaff,
                        format,
                        aaffSelected,
                        setAaffSelected,
                      })
                    }
                    className="format-pedido-pilotado"
                    key={format._id}
                    style={{
                      height: '3rem',
                      borderBottom: i === aaffArray.length - 1 ? 'none' : '1px solid #cecece',
                      display: 'flex',
                      alignItems: 'center',
                      userSelect: 'none',
                      gap: '.5rem',
                      padding: '0 1.5rem',
                      cursor: 'pointer',
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <span>{format.code + ' - ' + format.name}</span>
                      {/* <span>{format.price.toFixed(2) + ' €'}</span> */}
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      ),
  );
};

export default DbContentArteFinal;
