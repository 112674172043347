import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createPersonalizable } from '../api/carteleriaApi';
import toast from 'react-hot-toast';
import { checkFormatIsCustom } from '../api/formatsApi';

export const useCreateFormatCustom = (idArte) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (values) => {
      let token = await getAccessTokenSilently();
      createPersonalizable(token, values);
    },
    onSuccess: () => {
      toast.success('Formato personalizable añadido correctamente');
      queryClient.invalidateQueries('formats-arte-final');
      queryClient.invalidateQueries('info-arte-final');
    },
    onError: () => {
      toast.success('Error al añadir formato personalizable');
    },
  });

  return { mutation };
};

export const useCheckFormatIsCustom = (idFormat) => {
  const { data: isCustom, isLoading } = useQuery(['is-custom-format', idFormat], () => checkFormatIsCustom(idFormat));

  return { isCustom, loadingCheck: isLoading };
};
