import { Tooltip } from '@mui/material';
import { handleClickSelectedAaff } from '../../../helpers/Category/gestionCategories';
import HeadingAaffSelector from './HeadingAaffSelector';

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const ContainerAaffNoSelected = ({
  isLoading,
  isFetching,
  aaffNoSelected,
  setAaffNoSelected,
  aaffSelected,
  setAaffSelected,
  data,
  setPage,
}) => {
  return (
    <HeadingAaffSelector
      description="(Haz clic en un artículo para añadirlo a la lista de seleccionados)"
      title="Artículos disponibles"
    >
      <div
        className="container-aaff-add-masive"
        onScroll={(e) => {
          if (isLoading || isFetching) return;
          const scrollThreshold = 50;

          if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + scrollThreshold) {
            if (data?.data?.length > 0) {
              setPage((prev) => prev + 1);
            }
          }
        }}
      >
        <div className="d-flex fd-column ai-center w-100">
          <div className="d-flex fd-column w-100">
            {aaffNoSelected.filter((aaff) => !aaff.selected).length === 0 && !isLoading && !isFetching && (
              <div className="d-flex ai-center jc-center w-100 pt-1">
                <p className="texto-aaff-pedido-pilotado">No hay artículos disponibles para su búsqueda</p>
              </div>
            )}
            {aaffNoSelected
              .filter((aaff) => !aaff.selected)
              .map((aaff, i) => (
                <div key={aaff._id + i}>
                  <div
                    onClick={() =>
                      handleClickSelectedAaff({
                        aaff,
                        aaffNoSelected,
                        setAaffNoSelected,
                        aaffSelected,
                        setAaffSelected,
                      })
                    }
                    className="row-aaff-add-masive pointer"
                    style={{
                      borderBottom: i === aaffNoSelected.length - 1 ? 'none' : '1px solid #cecece',
                    }}
                  >
                    <Tooltip
                      title={aaff.code + ' - ' + aaff.name}
                      disableHoverListener={(aaff.code + ' - ' + aaff.name).length <= 53}
                    >
                      <p
                        className={`texto-aaff-pedido-pilotado ${
                          (aaff.code + ' - ' + aaff.name).length > 53 && `aaff-add-masive-selected-${aaff._id}`
                        }`}
                      >
                        {truncateText(aaff.code + ' - ' + aaff.name, 53)}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              ))}
            {(isLoading || isFetching) && (
              <div className="loader-container">
                <div className="loader-aaff-pedido-pilotado">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </HeadingAaffSelector>
  );
};

export default ContainerAaffNoSelected;
