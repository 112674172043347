import { useAuth0 } from '@auth0/auth0-react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createCosteInstalacionOrderSingle, getCostesInstalacion } from '../../api/carteleriaApi';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import toast from 'react-hot-toast';

const CreateCostesInstalacion = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();

  // traer todos los costes de instalacion
  const { data, isFetching } = useQuery(['costes-instalacion'], async () => {
    const token = await getAccessTokenSilently();
    return await getCostesInstalacion(token);
  });
  const [costesInstalacion, setCostesInstalacion] = React.useState([]);
  const [currentCoste, setCurrentCoste] = React.useState(null);
  const [currentCantidad, setCurrentCantidad] = React.useState(0);

  useEffect(() => {
    if (!data) return;
    setCostesInstalacion(
      data.data.map((costeInstalacion) => ({
        coste: costeInstalacion.coste,
        id: costeInstalacion._id,
        label: costeInstalacion.nombre + ' - ' + costeInstalacion.localizacion + ' - ' + costeInstalacion.tipoCoste,
      })),
    );
  }, [data]);

  const location = useLocation();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    // quitar las dos ultimas partes de la url
    const url = location.pathname.split('/').slice(0, -1).join('/');
    navigate(url);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: '5rem',
    transform: 'translate(-50%, -50%)',
    minWidth: '700px',
    maxWidth: '750px',
    bgcolor: '#F8F8F8',
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleOnChangeCosteInstalacion = (value) => {
    setCurrentCoste(value);
  };

  const handleAddCosteInstalacion = async () => {
    // si no hay coste o no hay currentCoste o no hay cantidad no hacer nada
    if (!currentCoste || !currentCantidad) {
      toast.error('Debes seleccionar un coste e introducir una cantidad', {
        id: 'no-coste-cantidad-add-coste-instalacion',
      });
    } else {
      const token = await getAccessTokenSilently();
      const response = await createCosteInstalacionOrderSingle(token, {
        order: id,
        costeInstalacion: currentCoste.id,
        cantidad: currentCantidad,
      });
      if (response?.data?.status == 'success') {
        queryClient.invalidateQueries(['costes-instalacion', id]);
        handleCloseModal();
      } else {
        toast.error('Error al añadir el coste de instalación', {
          id: 'error-add-coste-instalacion',
        });
      }
    }
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <Box sx={{ ...style, padding: '3rem !important' }}>
        <Typography
          variant="h5"
          component="h5"
          align="center"
          // color="error"
          sx={{
            fontWeight: 'bold',
            marginBottom: '1.5rem',
            backgroundColor: '#3C4145',
            color: 'white',
            userSelect: 'none',
          }}
        >
          Añadir coste de instalación
        </Typography>

        <div style={{ marginBottom: '2rem' }}>
          <InputLabel>
            Coste de instalación * {isFetching && <CircularProgress style={{ color: 'black' }} size=".85rem" />}
          </InputLabel>
          <Autocomplete
            onChange={(e, value) => handleOnChangeCosteInstalacion(value)}
            size="small"
            value={currentCoste}
            disabled={isFetching}
            renderInput={(params) => <TextField required size="small" {...params} />}
            options={costesInstalacion}
          />
        </div>

        <Row style={{ margin: '0 0 2rem 0' }}>
          <Colxx style={{ paddingLeft: '0' }} xxs="6">
            <InputLabel>Cantidad *</InputLabel>
            <TextField
              onChange={(e) => setCurrentCantidad(parseFloat(e.target.value))}
              fullWidth
              size="small"
              type="number"
            />
          </Colxx>
          <Colxx style={{ paddingRight: '0' }} xxs="6">
            <InputLabel>Precio</InputLabel>
            <TextField
              value={`${currentCoste ? currentCoste.coste + ' €' : 'Por determinar'}`}
              fullWidth
              size="small"
              disabled
            />
          </Colxx>
        </Row>

        {/* BOTONES PARA AÑADIR O CANCELAR */}
        <Row style={{ margin: '0', justifyContent: 'flex-end', gap: '1rem' }}>
          <button
            onClick={handleCloseModal}
            style={{ padding: '.5rem 1rem', backgroundColor: '#898989', color: 'white', border: 'none' }}
          >
            Cancelar
          </button>
          <button
            onClick={handleAddCosteInstalacion}
            style={{ padding: '.5rem 1rem', backgroundColor: '#B30B00', color: 'white', border: 'none' }}
          >
            Añadir
          </button>
        </Row>
      </Box>
    </Modal>
  );
};

export default CreateCostesInstalacion;
