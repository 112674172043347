import toast from 'react-hot-toast';

/* -------------------------------------------------------------------------- */
/*          DECREMENTAR LA CANTIDAD DE UN AAFF PARA AÑADIR AL CARRITO         */
/* -------------------------------------------------------------------------- */
export const handleDecrement = (params) => {
  params.setQuantity((prev) => {
    const prevQuantity = Math.max((prev[params.arteFinalId]?.[params.idFormato]?.quantity || 0) - 1, 0);
    const prevFormato = prev[params.arteFinalId]?.[params.idFormato] || {};

    if (prevQuantity == 0) return { [params.arteFinalId]: { [params.idFormato]: { quantity: 0 } } };

    return {
      ...prev,
      [params.arteFinalId]: {
        ...prev[params.arteFinalId],
        [params.idFormato]: {
          ...prevFormato,
          quantity: prevQuantity,
        },
      },
    };
  });

  // buscamos en el shoppingCart el arteFinal al que le vamos a modificar la cantidad de uno de sus formatos, si dicho arteFinal tiene packArteFinal tendremos que coger todos los artesFianles con dicho pack y modificar los formatos que tengan el mismo packFormat (SI SON COMPUESTOS OBLIGATORIOS, SI SON OPCIONALES SOLO MODIFICAREMOS EL FORMATO QUE ESTAMOS MODIFICANDO)
  if (
    params.shoppingCart[params.arteFinalId]?.packArteFinal &&
    params.shoppingCart[params.arteFinalId]?.formatos[params.idFormato]?.childrenFormat?.required
  ) {
    const packArteFinal = params.shoppingCart[params.arteFinalId]?.packArteFinal;
    const packFormat = params.shoppingCart[params.arteFinalId]?.formatos[params.idFormato]?.packFormat;

    let newShoppingCart = { ...params.shoppingCart };

    Object.keys(newShoppingCart).forEach((key) => {
      if (newShoppingCart[key]?.packArteFinal === packArteFinal) {
        newShoppingCart[key].formatos = Object.keys(newShoppingCart[key].formatos).reduce((acc, keyFormat) => {
          if (newShoppingCart[key].formatos[keyFormat].packFormat === packFormat) {
            const newQuantity = Math.max(
              (newShoppingCart[key].formatos[keyFormat]?.quantity || 0) -
                (1 * newShoppingCart[key].formatos[keyFormat]?.originalQuantity || 1),
              0,
            );
            const prevFormato = newShoppingCart[key]?.formatos?.[keyFormat] || {};

            if (newQuantity === 0) {
              const { [keyFormat]: removedFormat, ...newFormatos } = newShoppingCart[key].formatos;

              if (Object.keys(newFormatos).length === 0) {
                delete newShoppingCart[key];
              } else {
                newShoppingCart[key].formatos = newFormatos;
              }
            } else {
              newShoppingCart[key].formatos[keyFormat] = {
                ...prevFormato,
                quantity: newQuantity,
              };
            }
          }
          return newShoppingCart[key]?.formatos || {};
        }, {});
      }
    });

    params.setShoppingCart(newShoppingCart);
  } else {
    if (
      params.setShoppingCart &&
      params.shoppingCart &&
      params.shoppingCart[params.arteFinalId] &&
      params.shoppingCart[params.arteFinalId].formatos[params.idFormato]
    ) {
      params.setShoppingCart((prev) => {
        const prevQuantity = Math.max((prev[params.arteFinalId]?.formatos?.[params.idFormato]?.quantity || 0) - 1, 0);
        const prevFormato = prev[params.arteFinalId]?.formatos?.[params.idFormato] || {};

        if (prevQuantity == 0) {
          // si el arteFinal va en pack eliminamos todos los arteFinales con el mismo pack
          if (prev[params.arteFinalId]?.packArteFinal) {
            const packArteFinal = prev[params.arteFinalId]?.packArteFinal;
            const packFormat = prev[params.arteFinalId]?.formatos[params.idFormato]?.packFormat;

            const newShoppingCart = { ...prev };

            Object.keys(newShoppingCart).forEach((key) => {
              if (newShoppingCart[key]?.packArteFinal === packArteFinal) {
                newShoppingCart[key].formatos = Object.keys(newShoppingCart[key].formatos).reduce((acc, keyFormat) => {
                  if (newShoppingCart[key].formatos[keyFormat].packFormat === packFormat) {
                    const { [keyFormat]: removedFormat, ...newFormatos } = newShoppingCart[key].formatos;

                    if (Object.keys(newFormatos).length === 0) {
                      delete newShoppingCart[key];
                    } else {
                      newShoppingCart[key].formatos = newFormatos;
                    }
                  }
                  return newShoppingCart[key]?.formatos || {};
                }, {});
              }
            });

            return newShoppingCart;
          }
          const { [params.arteFinalId]: removededArteFinal, ...newShoppingCart } = prev;
          return newShoppingCart;
        }

        return {
          ...prev,
          [params.arteFinalId]: {
            ...prev[params.arteFinalId],
            formatos: {
              ...prev[params.arteFinalId]?.formatos,
              [params.idFormato]: {
                ...prevFormato,
                quantity: prevQuantity,
              },
            },
          },
        };
      });
    }
  }
};

/* -------------------------------------------------------------------------- */
/*          INCREMENTAR LA CANTIDAD DE UN AAFF PARA AÑADIR AL CARRITO         */
/* -------------------------------------------------------------------------- */
export const handleIncrement = (params) => {
  params.setQuantity((prev) => {
    const prevQuantity = prev[params.arteFinalId]?.[params.idFormato]?.quantity || 0;
    const prevFormato = prev[params.arteFinalId]?.[params.idFormato] || {};

    return {
      ...prev,
      [params.arteFinalId]: {
        ...prev[params.arteFinalId],
        [params.idFormato]: {
          ...prevFormato, // TODO: no se si esto es necesario
          quantity: params.totalQuantity || prevQuantity + 1,
        },
      },
    };
  });

  if (
    params.shoppingCart[params.arteFinalId]?.packArteFinal &&
    params.shoppingCart[params.arteFinalId]?.formatos[params.idFormato]?.childrenFormat?.required
  ) {
    const packArteFinal = params.shoppingCart[params.arteFinalId]?.packArteFinal;
    const packFormat = params.shoppingCart[params.arteFinalId]?.formatos[params.idFormato]?.packFormat;

    const newShoppingCart = { ...params.shoppingCart };

    Object.keys(newShoppingCart).map((key) => {
      if (newShoppingCart[key]?.packArteFinal === packArteFinal) {
        newShoppingCart[key].formatos = Object.keys(newShoppingCart[key].formatos).reduce((acc, keyFormat) => {
          if (newShoppingCart[key].formatos[keyFormat].packFormat === packFormat) {
            const newQuantity =
              params.totalQuantity ||
              (newShoppingCart[key].formatos[keyFormat]?.quantity || 0) +
                (1 * newShoppingCart[key].formatos[keyFormat]?.originalQuantity || 1);
            const prevFormato = newShoppingCart[key]?.formatos?.[keyFormat] || {};

            return {
              ...acc,
              [keyFormat]: {
                ...prevFormato,
                quantity: newQuantity,
              },
            };
          }
          return {
            ...acc,
            [keyFormat]: newShoppingCart[key].formatos[keyFormat],
          };
        }, {});
      }
    });

    params.setShoppingCart(newShoppingCart);
  } else {
    if (
      params.shoppingCart &&
      params.shoppingCart[params.arteFinalId] &&
      params.shoppingCart[params.arteFinalId].formatos[params.idFormato]
    ) {
      params.setShoppingCart((prev) => {
        const prevQuantity = Math.max((prev[params.arteFinalId]?.formatos?.[params.idFormato]?.quantity || 0) + 1, 0);
        const prevFormato = prev[params.arteFinalId]?.formatos?.[params.idFormato] || {};

        return {
          ...prev,
          [params.arteFinalId]: {
            ...prev[params.arteFinalId],
            formatos: {
              ...prev[params.arteFinalId]?.formatos,
              [params.idFormato]: {
                ...prevFormato,
                quantity: params.totalQuantity || prevQuantity,
              },
            },
          },
        };
      });
    }
  }
};

/* -------------------------------------------------------------------------- */
/*            SELECCIONAR O DESELECCIONAR EL CHILDREN DE UN FORMATO           */
/* -------------------------------------------------------------------------- */
export const handleChangeCheckedFormatChildren = (
  formatoId,
  openModalSelectChildrenFormat,
  setOpenModalSelectChildrenFormat,
) => {
  // buscamos en el array de childrenFormat el formato que se ha seleccionado y ponemoDs el check al contrario
  const newChildrenFormat = openModalSelectChildrenFormat.map((childrenFormat) => {
    if (childrenFormat.formatoId == formatoId) {
      return {
        ...childrenFormat,
        checked: !childrenFormat.checked,
      };
    }
    return childrenFormat;
  });
  setOpenModalSelectChildrenFormat(newChildrenFormat);
};

/* -------------------------------------------------------------------------- */
/*                   CAMBIAR CANTIDAD DE LOS CHILDREN FORMAT                  */
/* -------------------------------------------------------------------------- */
export const handleChangeQuantityChildrenFormat = (
  // setSelectQuantity,
  rest,
  // minQuantity,
  formatoId,
  setOpenModalSelectChildrenFormat,
) => {
  if (rest) {
    setOpenModalSelectChildrenFormat((prev) => {
      const newChildrenFormat = prev.map((childrenFormat) => {
        if (childrenFormat.formatoId == formatoId) {
          return {
            ...childrenFormat,
            quantity: Math.max(childrenFormat.quantity - 1, childrenFormat.minQuantity),
          };
        }
        return childrenFormat;
      });
      return newChildrenFormat;
    });
  } else {
    setOpenModalSelectChildrenFormat((prev) => {
      const newChildrenFormat = prev.map((childrenFormat) => {
        if (childrenFormat.formatoId == formatoId) {
          return {
            ...childrenFormat,
            quantity: childrenFormat.quantity + 1,
          };
        }
        return childrenFormat;
      });
      return newChildrenFormat;
    });
  }
};

/* -------------------------------------------------------------------------- */
/*                CERRAR MODAL DE SELECCION DE CHILDREN FORMAT                */
/* -------------------------------------------------------------------------- */
export const handleCloseModal = (setOpenModalSelectChildrenFormat) => {
  setOpenModalSelectChildrenFormat(null);
};

/* -------------------------------------------------------------------------- */
/*                        BOTON PARA AÑADIR AL CARRITO                        */
/* -------------------------------------------------------------------------- */
export const handleAddButtonClick = (params) => {
  // vamos a añadir al array del carrito el proveedor seleccionado
  if (params.selectedFormato.formatoCustom && params.formatoInfo.name == 'Personalizable') {
    toast.error('No se puede agregar al carrito porque no se ingresó un nombre');
    return;
  }

  // vamos a comprobar si el formato tiene childrenFromat
  if (params.selectedFormato?.childrenFormat?.format?.length > 0) {
    params.setRequiredFormatChildren(params.selectedFormato.childrenFormat.required);
    // abrimos modal para seleccionar el childrenFormat

    const format = [];

    const { nameArteFinal, codeArteFinal, ...rest } = params.arteFinal;
    format.push({
      ...params.selectedFormato,
      arteFinal: {
        ...rest,
        code: codeArteFinal,
        name: nameArteFinal,
        minQuantity: params.arteFinal.minQuantity || 1,
      },
      checked: true,
      quantity: params.quantity[params.arteFinalId]?.[params.formatoInfo.formato.formatoId]?.quantity || 1,
      minQuantity: params.selectedFormato.arteFinal?.minQuantity || 1,
    });

    params.selectedFormato.childrenFormat.format.map((childrenFormat, i) =>
      format.push({
        ...childrenFormat,
        checked: true,
        originalQuantity: childrenFormat?.quantity?.quantity || 1,
        minQuantity: childrenFormat.arteFinal?.minQuantity || 1,
        quantity: !params.selectedFormato.childrenFormat.required
          ? childrenFormat.arteFinal?.minQuantity || 1
          : childrenFormat?.quantity?.quantity *
              params.quantity[params.arteFinalId]?.[params.formatoInfo.formato.formatoId]?.quantity || 1,
      }),
    );

    // aqui añadimos al padre con el mismo formato que los hijos

    params.setOpenModalSelectChildrenFormat(format);

    const { childrenFormat, ...formatoWithoutChildren } = params.formatoInfo.formato;
    return;
  }

  params.setShoppingCart((prev) => {
    // UPDATE: Si el arte final YA está en el carrito, actualizo la cantidad del formato
    if (prev[params.arteFinalId]) {
      const existingFormato = prev[params.arteFinalId].formatos[params.formatoInfo.formato.formatoId];
      const newQuantity = params.quantity[params.arteFinalId]?.[params.formatoInfo.formato.formatoId]?.quantity || 0;

      return {
        ...prev,
        [params.arteFinalId]: {
          ...prev[params.arteFinalId],
          formatos: {
            ...prev[params.arteFinalId].formatos,
            [params.formatoInfo.formato.formatoId]: {
              ...params.formatoInfo.formato,
              quantity: newQuantity,
              minQuantity: params.arteFinal.minQuantity || 1,
              newNameCustom: existingFormato?.newNameCustom || params.formatoInfo.name,
            },
          },
        },
      };
    }

    // NEW: Si el arte final no está en el carrito, lo agrego!!
    return {
      ...prev,
      [params.arteFinalId]: {
        id: params.arteFinalId,
        category: params.category,
        name: params.name,
        code: params.code,
        imglink: params.imglink,
        montaje: params.montaje,
        minQuantity: params.arteFinal.minQuantity || 1,
        formatos: {
          [params.formatoInfo.formato.formatoId]: {
            ...params.formatoInfo.formato,
            formatoPrice: Number(params.formatoInfo.formato.formatoPrice.toFixed(2)),
            quantity: params.quantity[params.arteFinalId]?.[params.formatoInfo.formato.formatoId]?.quantity || 1,
            minQuantity: params.arteFinal.minQuantity || 1,
            newNameCustom: params.formatoInfo.name,
          },
        },
      },
    };
  });
};

/* -------------------------------------------------------------------------- */
/*                      AÑADIR ARTE COMPUESTO AL CARRITO                      */
/* -------------------------------------------------------------------------- */
export const handleAddCompuesto = (
  openModalSelectChildrenFormat,
  setShoppingCart,
  setOpenModalSelectChildrenFormat,
) => {
  const selectedChildrenFormat = openModalSelectChildrenFormat.filter((childrenFormat) => childrenFormat.checked);
  if (selectedChildrenFormat.length === 0) {
    toast.error('Debe seleccionar al menos un arte', {
      id: 'error-add-aaff-children',
    });
    return;
  }

  // generamos un identificador random para el pack
  const packArteFinal = Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);
  const packFormat = Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);

  // AHORA RECORREMOS TODOS LOS ARTES QUE VAMOS A AÑADIR AL CARRITO
  selectedChildrenFormat.map((childrenFormat) => {
    // ahora sacamos por cada uno del _id del arteFinal y compronbamos si ya existe en el carrito
    const arteFinalId = childrenFormat.arteFinal._id;
    const formatoId = childrenFormat.formatoId;
    const { arteFinal, checked, ...rest } = childrenFormat;

    // Actualizamos el carrito de compras
    setShoppingCart((prev) => {
      // Verificamos si ya existe el arteFinal en el carrito
      if (prev[arteFinalId]) {
        // Si ya existe, combinamos los nuevos childrenFormat con los formatos existentes
        return {
          ...prev,
          [arteFinalId]: {
            ...prev[arteFinalId],
            formatos: {
              ...prev[arteFinalId].formatos,
              [formatoId]: {
                ...prev[arteFinalId].formatos[formatoId],
                ...rest,
                packFormat,
              },
            },
          },
        };
      } else {
        // Si no existe, creamos un nuevo arteFinal en el carrito con los childrenFormat
        return {
          ...prev,
          [arteFinalId]: {
            packArteFinal,
            id: arteFinalId,
            category: arteFinal.category,
            montaje: arteFinal.isMontaje,
            name: arteFinal.name,
            code: arteFinal.code,
            imglink: arteFinal.imglink,
            minQuantity: arteFinal.minQuantity || 1,
            originalQuantity: rest.originalQuantity || 1,
            formatos: {
              [formatoId]: {
                ...rest,
                packFormat, // aqui tenemos que añadir un identificador para el pack
              },
            },
          },
        };
      }
    });
  });

  handleCloseModal(setOpenModalSelectChildrenFormat);
};
