import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createCosteInstalacionOrder, createOrder, createOrderItem, getAllArtesFinales } from '../api/carteleriaApi';
import { createContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { UserContext } from './UserProvider';
import { useNavigate } from 'react-router-dom';

export const ArteFinalContext = createContext();

export const ArteFinalProvider = ({ children }) => {
  const navigate = useNavigate();
  const [shoppingCart, setShoppingCart] = useState(JSON.parse(localStorage.getItem('shoppingCart')) || {});
  const { centerConfirmed, userRole } = useContext(UserContext);
  const [isMontaje, setIsMontaje] = useState(true);
  const [nameContactShoppingCart, setNameContactShoppingCart] = useState('');
  const [phoneContactShoppingCart, setPhoneContactShoppingCart] = useState('');
  const [extension, setExtension] = useState('');
  const [imagesShoppingCart, setImagesShoppingCart] = useState({});
  const [inputValueSearch, setInputValueSearch] = useState('');
  const [page, setPage] = useState(1);
  const [superIdCategory, setSuperIdCategory] = useState(null);
  const [favouritesToEsencialseCategory, setFavouritesToEsencialseCategory] = useState(false);
  const [arteDeleteFromResumenPedido, setArteDeleteFromResumenPedido] = useState(null);
  const [aaffCompuestos, setAaffCompuestos] = useState({});
  const [aaffNoCompuestos, setAaffNoCompuestos] = useState([]);

  useEffect(() => {
    const newAaffCompuestos = {};
    const newAaffNoCompuestos = [];

    Object.entries(shoppingCart).forEach(([idArteFinal, arteFinal]) => {
      const namePack = arteFinal?.packArteFinal;

      if (!!namePack) {
        if (newAaffCompuestos[namePack]) {
          newAaffCompuestos[namePack].push(arteFinal);
        } else {
          newAaffCompuestos[namePack] = [arteFinal];
        }
      } else {
        newAaffNoCompuestos.push(arteFinal);
      }
    });

    setAaffCompuestos(newAaffCompuestos);
    setAaffNoCompuestos(newAaffNoCompuestos);
  }, [shoppingCart]);

  const { user, getAccessTokenSilently } = useAuth0();

  const totalPedido = useMemo(() => {
    const newTotal = Object.values(shoppingCart).reduce((acc, item) => {
      return (
        acc +
        Object.values(item.formatos).reduce((formatoAcc, formato) => {
          // si el arte final es compuesto entonces sumamos el precio de todos los childrenFormat

          if (formato?.childrenFormat?.length > 0 && formato?.childrenFormat?.length !== formato?.totalChildren) {
            return (
              formatoAcc +
              formato.childrenFormat.reduce(
                (acc, curr) =>
                  acc +
                  curr.formatoPrice * (formato.quantity < formato.minQuantity ? formato.minQuantity : formato.quantity),
                // (formato.quantity <= item.minQuantity
                //   ? item.minQuantity
                //   : Math.ceil(formato.quantity / item.minQuantity) * item.minQuantity),
                0,
              )
            );
          }
          return (
            formatoAcc +
            formato.formatoPrice * (formato.quantity < formato.minQuantity ? formato.minQuantity : formato.quantity)
          );
        }, 0)
      );
    }, 0);

    return newTotal;
  }, [shoppingCart]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [inputValueSearch, superIdCategory, centerConfirmed]);

  useEffect(() => {
    localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
  }, [shoppingCart]);

  const handleSendRequest = async (costesAsignados) => {
    const orderItems = Object.entries(shoppingCart)
      .map(([arteFinalId, arteFinal]) => {
        return Object.entries(arteFinal.formatos).map(([formatoId, formato]) => {
          // comprobar si el formato es compuesto

          if (formato?.childrenFormat?.format?.length > 0 && formato?.packFormat) {
            return {
              format: formatoId,
              // AQUI HAY QUE RECORRER EL CARRITO Y METER LOS FORMATOS QUE TENGAN EL MISMO PACKFORMAT

              formatChildren: Object.entries(shoppingCart).reduce((acc, [arteFinalId, arteFinal]) => {
                return Object.entries(arteFinal.formatos).reduce((acc, [formatoId, formatoChildren]) => {
                  if (formatoChildren?.packFormat === formato.packFormat && formatoId !== formato.formatoId) {
                    return [
                      ...acc,
                      {
                        id: formatoId,
                        quantity: formatoChildren.quantity || 1,
                      },
                    ];
                  }
                  return acc;
                }, acc);
              }, []),

              quantity: formato.quantity,
              comment: formato.comment,
              custom: formato.newNameCustom,
            };
          } else if (!formato?.packFormat && !formato?.childrenFormat?.length > 0) {
            return {
              // arteFinalId,
              format: formatoId,
              // formatoId,
              quantity: formato.quantity,
              comment: formato.comment,

              // vamos a añadir el campo custom solo si el formato.newNameCustom no es undefined sino no añadimos el campo custom
              // formato.newNameCustom !== undefined ? custom: formato.newNameCustom : undefined,
              custom: formato.newNameCustom,
            };
          } else {
            // no quiero devolver nada
            return null;
          }
        });
      })
      .flat()
      .filter((item) => item !== null);

    const orderItemsWithImages = orderItems.map((item) => {
      if (imagesShoppingCart[item.format]) {
        return {
          ...item,
          image: imagesShoppingCart[item.format],
        };
      } else {
        return item;
      }
    });

    const token = await getAccessTokenSilently();
    const order = {
      user: {
        id: user.sub,
        name: user.name || null,
        email: user.email,
      },
      center: centerConfirmed._id,
      montaje: isMontaje,
      contactName: nameContactShoppingCart,
      contactPhone: phoneContactShoppingCart,
      extension,
    };

    // CREAMOS LA ORDER

    const orderCreated = await createOrder(token, order);

    if (isMontaje == true) {
      // hacemos un promise all para crear a la vez los costes de instalacion y los orderItems

      await Promise.all([
        createCosteInstalacionOrder(token, { order: orderCreated.data._id, costesInstalacion: costesAsignados }),
        orderItemsWithImages.map((item) => createOrderItem(token, { ...item, order: orderCreated.data._id })),
      ]);
    } else {
      await Promise.all([
        orderItemsWithImages.map((item) => createOrderItem(token, { ...item, order: orderCreated.data._id })),
      ]);
    }

    toast.success('Solicitud enviada correctamente ', {
      duration: 2000,
      position: 'top-center',
    });
  };

  const handleResetShoppingCart = () => {
    setShoppingCart({});
    setImagesShoppingCart({});
    setIsMontaje(true);
    setInputValueSearch('');
    setSuperIdCategory(null);

    // Pequeña pausa para asegurarse de que las actualizaciones de estado se completen
    setTimeout(() => {
      if (userRole === 'user-signage') {
        navigate('/carteleria/orders');
      } else {
        navigate('/admin');
      }
    }, 0);
  };

  const data = {
    shoppingCart,
    setShoppingCart,
    inputValueSearch,
    setInputValueSearch,
    totalPedido,
    page,
    setPage,
    user,
    handleSendRequest,
    imagesShoppingCart,
    handleResetShoppingCart,
    setImagesShoppingCart,
    isMontaje,
    setIsMontaje,
    superIdCategory,
    setSuperIdCategory,
    centerConfirmed,
    nameContactShoppingCart,
    setNameContactShoppingCart,
    phoneContactShoppingCart,
    setPhoneContactShoppingCart,
    extension,
    setExtension,
    favouritesToEsencialseCategory,
    setFavouritesToEsencialseCategory,
    arteDeleteFromResumenPedido,
    setArteDeleteFromResumenPedido,
    aaffCompuestos,
    aaffNoCompuestos,
    setAaffCompuestos,
    setAaffNoCompuestos,
  };
  return <ArteFinalContext.Provider value={data}>{children}</ArteFinalContext.Provider>;
};
