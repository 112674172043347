import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { ProyectoProvider } from '../context/ProyectoContext';
import { ViewerContext, ViewerProvider } from '../context/ViewerContext';
import { CapitulosProvider } from '../context/CapitulosContext';
import NavbarAlcampo from '../Components2/Navbar/NavbarAlcampo';
import { useAuth0 } from '@auth0/auth0-react';
import { PartidasProvider } from '../context/PartidasContext';
import Main from '../Components2/Main/Main';
import ViewerContentLayout from './ViewerContentLayout';

const ViewerLayout = () => {
  return (
    <ViewerProvider>
      <ViewerContentLayout />
    </ViewerProvider>
  );
};

export default ViewerLayout;
