import React from 'react';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import SelectedContentArteFinal from './ArteFinal/SelectedContentArteFinal';
import SelectedContentTienda from './Tienda/SelectedContentTienda';
import StorefrontIcon from '@mui/icons-material/Storefront';

const ContainerSelected = ({ tienda, setDataArray, dataSelected, setDataSelected, dataArray }) => {
  return (
    <div style={{ width: '45%' }}>
      <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
        <h5 style={{ display: 'flex', justifyContent: 'center' }}>
          {tienda ? 'Centros seleccionados' : 'Artes finales seleccionados'}
        </h5>
        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontStyle: 'italic', height: '40px' }}>
          {tienda
            ? '(Haz clic en un cluster o centro para eliminarlo de la lista)'
            : '(Haz clic en el formato para eliminarlo de la lista)'}
        </p>
      </div>
      <div
        style={{
          border: '1px solid #cecece',
          height: '300px',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {dataSelected.length === 0 ? (
              <div
                style={{
                  gap: '.5rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginTop: '1rem',
                }}
              >
                {tienda ? (
                  <StorefrontIcon style={{ color: '#cecece', width: '60px', height: '60px' }} />
                ) : (
                  <ImageNotSupportedIcon style={{ color: '#cecece', width: '60px', height: '60px' }} />
                )}
                <p style={{ fontSize: '14px' }}>
                  {tienda
                    ? 'Selecciona un centro en el listado de disponibles para añadirlo al listado'
                    : 'Selecciona un arte en el listado de disponibles para añadirlo al listado'}
                </p>
              </div>
            ) : !tienda ? (
              <SelectedContentArteFinal
                aaffArray={dataArray}
                aaffSelected={dataSelected}
                setAaffArray={setDataArray}
                setAaffSelected={setDataSelected}
              />
            ) : (
              <SelectedContentTienda
                tiendaArray={dataArray}
                tiendaSelected={dataSelected}
                setTiendaArray={setDataArray}
                setTiendaSelected={setDataSelected}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerSelected;
