import toast from 'react-hot-toast';

export const handleFormatoChange = (params) => {
  params.setFormatProviderSelected(null);
  const selectedFormat = params.arteFinal.formatos.find((formato) => formato.formatoId === params.event);
  if (selectedFormat) {
    params.setFormatSelected({
      ...selectedFormat,
      arteFinal: params.arteFinal._id,
    });
  }
};

export const handleChangeFormatoCustom = (params) => {
  params.setFormatSelected((prev) => {
    return {
      ...prev,
      formatoName: params.e.target.value,
    };
  });
};

export const updateNameCustom = (params) => {
  if (params.formatSelected.formatoName.length < 3) {
    toast.error('El nombre del formato debe tener al menos 3 caracteres para ser válido.', {
      id: 'error-name-custom',
    });
  } else {
    params.setShoppingCart_v2((prev) => {
      return {
        ...prev,
        [params.arteFinalId]: {
          ...prev[params.arteFinalId],
          formatos: {
            ...prev[params.arteFinalId].formatos,
            [params.formatSelected.formatoId]: {
              ...prev[params.arteFinalId].formatos[params.formatSelected.formatoId],
              formatoName: params.formatSelected.formatoName,
              // newNameCustom: e.target.value,
            },
          },
        },
      };
    });

    toast.success('Nombre del formato actualizado', {
      duration: 2000,
      position: 'top-center',
    });
  }
};
