import InfoArteFinalContainer from '../ArteFinalInfo/InfoArteFinalContainer';
import cls from 'classnames';
import InfoArteFinalTitle from '../ArteFinalInfo/InfoArteFinalTitle';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
const AddFormatProvider = ({ format }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleAddFormatProvider = () => {
    if (location.pathname[location.pathname.length - 1] !== '/') {
      navigate(`${location.pathname}/add-format-provider/${format._id}`);
    } else {
      navigate(`${location.pathname}add-format-provider/${format._id}`);
    }
  };

  return (
    <div onClick={handleAddFormatProvider}>
      <InfoArteFinalContainer
        classnames={cls(
          'item-arte-final-container',
          'item-arte-final-container--format',
          'item-arte-final-container--pointer',
          'add-format-provider-container',
        )}
      >
        <AddIcon />
        <InfoArteFinalTitle classnames="display-sb-c">Nuevo proveedor</InfoArteFinalTitle>
      </InfoArteFinalContainer>
    </div>
  );
};

export default AddFormatProvider;
