import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { StyledTableCell } from '../RecycledComponents';
import { useEffect, useState } from 'react';
import { PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import { HiDownload } from 'react-icons/hi';
import { IconButton } from '@mui/material';
import { generateExcelPilotado } from '../../helpers/PedidoPilotado/pedidoPilotadoExcel';
import { useAuth0 } from '@auth0/auth0-react';
import { formatDate } from '../../helpers/Utils';
import { GoSortDesc } from 'react-icons/go';
import { GoSortAsc } from 'react-icons/go';

const TableAdmin = ({ listOrder, setOrderFilter, byUser, pilotados, pilotado }) => {
  const navigate = useNavigate();

  const [activeDiv, setActiveDiv] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  const [currentOrder, setCurrentOrder] = useState([
    { key: 'createdAt', active: false, order: 'desc' },
    { key: 'priceOrder', active: false, order: 'desc' },
    { key: 'deliverableDate', active: false, order: 'desc' },
  ]);

  useEffect(() => {
    const order = currentOrder.find((order) => order.active === true);
    if (order) {
      setOrderFilter({ key: order.key, order: order.order });
    }
  }, [currentOrder]);

  const handleNavigateOrder = (id) => {
    // Verifica si la URL actual termina con una barra diagonal
    const hasTrailingSlash = window.location.pathname.endsWith('/');

    // Construye la URL adecuadamente, evitando duplicación de barras diagonales
    const url = hasTrailingSlash ? `${window.location.pathname}${id}` : `${window.location.pathname}/${id}`;

    // Navega a la URL construida
    navigate(url);
  };

  const handleClickOrder = (orden, key, active) => {
    if (!active) {
      setCurrentOrder(
        currentOrder.map((order) => {
          if (order.key === key) {
            return { ...order, active: true };
          } else {
            return { ...order, active: false };
          }
        }),
      );
    } else {
      setCurrentOrder(
        currentOrder.map((order) => {
          if (order.key === key) {
            if (orden === 'desc') {
              return { ...order, order: 'asc' };
            } else {
              return { ...order, order: 'desc' };
            }
          } else {
            return order;
          }
        }),
      );
    }
  };

  return (
    <div>
      {listOrder?.length === 0 ? (
        <h3 style={{ fontWeight: 'bolder' }}>
          {pilotado ? 'Centro no encontrado' : 'No hay solicitudes para los filtros seleccionados'}
        </h3>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Nº</StyledTableCell>
                {/* <StyledTableCell>Campaña</StyledTableCell> */}
                {!byUser &&
                  !pilotado &&
                  (pilotados ? (
                    <StyledTableCell>Administrador</StyledTableCell>
                  ) : (
                    <StyledTableCell>Usuario tienda</StyledTableCell>
                  ))}
                {pilotados && !byUser ? (
                  <StyledTableCell>Nº Tiendas</StyledTableCell>
                ) : (
                  <StyledTableCell>Tienda</StyledTableCell>
                )}
                {/* <StyledTableCell>Cliente</StyledTableCell> */}
                {!pilotados && <StyledTableCell>Estado pedido</StyledTableCell>}
                {!pilotado && (
                  <StyledTableCell>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                      <span>Creado el</span>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          className={!currentOrder.find((order) => order.key === 'createdAt').active && 'hidden'}
                          style={{ width: '7px', height: '7px', borderRadius: '50%', backgroundColor: 'green' }}
                        />
                        {currentOrder.find((order) => order.key === 'createdAt').order === 'desc' ? (
                          <GoSortDesc
                            size="20px"
                            onClick={() =>
                              handleClickOrder(
                                'desc',
                                'createdAt',
                                currentOrder.find((order) => order.key === 'createdAt').active,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <GoSortAsc
                            size="20px"
                            onClick={() =>
                              handleClickOrder(
                                'asc',
                                'createdAt',
                                currentOrder.find((order) => order.key === 'createdAt').active,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </div>
                    </div>
                  </StyledTableCell>
                )}
                {!pilotados && (
                  <StyledTableCell>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                      <span>Precio</span>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          className={!currentOrder.find((order) => order.key === 'priceOrder').active && 'hidden'}
                          style={{ width: '7px', height: '7px', borderRadius: '50%', backgroundColor: 'green' }}
                        />
                        {currentOrder.find((order) => order.key === 'priceOrder').order === 'desc' ? (
                          <GoSortDesc
                            size="20px"
                            onClick={() =>
                              handleClickOrder(
                                'desc',
                                'priceOrder',
                                currentOrder.find((order) => order.key === 'priceOrder').active,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <GoSortAsc
                            size="20px"
                            onClick={() =>
                              handleClickOrder(
                                'asc',
                                'priceOrder',
                                currentOrder.find((order) => order.key === 'priceOrder').active,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </div>
                    </div>
                  </StyledTableCell>
                )}
                {pilotados && <StyledTableCell>Descargar excel</StyledTableCell>}
                {!pilotados && (
                  <StyledTableCell>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                      <span>Fecha prevista</span>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          className={!currentOrder.find((order) => order.key === 'deliverableDate').active && 'hidden'}
                          style={{ width: '7px', height: '7px', borderRadius: '50%', backgroundColor: 'green' }}
                        />
                        {currentOrder.find((order) => order.key === 'deliverableDate').order === 'desc' ? (
                          <GoSortDesc
                            size="20px"
                            onClick={() =>
                              handleClickOrder(
                                'desc',
                                'deliverableDate',
                                currentOrder.find((order) => order.key === 'deliverableDate').active,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <GoSortAsc
                            size="20px"
                            onClick={() =>
                              handleClickOrder(
                                'asc',
                                'deliverableDate',
                                currentOrder.find((order) => order.key === 'deliverableDate').active,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </div>
                    </div>
                  </StyledTableCell>
                )}
                {/* <StyledTableCell>Acciones</StyledTableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {listOrder?.map((row, index) => (
                <TableRow
                  onClick={() => activeDiv && handleNavigateOrder(row._id)}
                  key={row._id}
                  className={`${activeDiv && 'table-order-row-hover'} table-order-row`}
                >
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  {/* <StyledTableCell>{row.comment || 'Valor predeterminado'}</StyledTableCell> */}
                  {!byUser && !pilotado && <StyledTableCell>{row.user}</StyledTableCell>}
                  <StyledTableCell>
                    {!pilotados ? row.centerCode + ' - ' + (row?.centerName || row.centerCalle) : row.numCenters}
                  </StyledTableCell>
                  {/* <StyledTableCell>{row.user}</StyledTableCell> */}
                  {!pilotados && (
                    <StyledTableCell style={{ color: row.status.color }}>
                      {row.status.name || row.status}
                    </StyledTableCell>
                  )}
                  {!pilotado && (
                    <StyledTableCell>{formatDate(row.createdAt || 'Valor predeterminado')}</StyledTableCell>
                  )}
                  {!pilotados && (
                    <StyledTableCell>
                      {`${row.priceOrder.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} €` || 'Valor predeterminado'}
                    </StyledTableCell>
                  )}
                  {!pilotados && (
                    <StyledTableCell>
                      {row.deliverableDate ? formatDate(row.deliverableDate) : 'Por determinar'}
                    </StyledTableCell>
                  )}
                  {pilotados && (
                    <StyledTableCell>
                      <IconButton
                        onMouseOver={() => setActiveDiv(false)}
                        onMouseOut={() => setActiveDiv(true)}
                        onClick={async () => {
                          let token = await getAccessTokenSilently();
                          generateExcelPilotado(token, row._id);
                        }}
                        style={{ display: 'flex', alignItems: 'center', borderRadius: '40px' }}
                      >
                        <PiMicrosoftExcelLogoFill />
                        <HiDownload size="14px" />
                      </IconButton>
                    </StyledTableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default TableAdmin;
