import React, { useContext, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { deletePartidaProyecto } from '../../../api/alcampoApi';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProyectoContext } from '../../../context/ProyectoContext';
import Viewer from '../../../helpers/Viewer/Viewer';
import { useQueryClient } from '@tanstack/react-query';
import { CapitulosContext } from '../../../context/CapitulosContext';
import { useHandleUrl } from '../../../hooks/useHandleUrl';
import { useAuth0 } from '@auth0/auth0-react';

export default function DeleteWithConfirmation({ idPartidaProyecto }) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentProject } = useContext(ProyectoContext);
  const { capituloSelected } = useContext(CapitulosContext);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { changeLocationWithNavigate } = useHandleUrl();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirm = async () => {
    setLoadingDelete(true);

    try {
      // Primero, elimina la partida
      let token = await getAccessTokenSilently();
      await deletePartidaProyecto(idPartidaProyecto, token);

      // Luego de eliminar la partida, invalida las consultas
      // const viewerClass = new Viewer(currentProject?._id);
      // viewerClass.getDbIdsByCodes([]);
      await Promise.all([
        queryClient.invalidateQueries(['current-partidas-project', currentProject?._id]),
        queryClient.invalidateQueries(['capitulosProyecto', currentProject?._id, capituloSelected?._id || 'all']),
        queryClient.invalidateQueries(['price-project', currentProject?._id]),
      ]);

      // Finalmente, ejecuta la redirección
      changeLocationWithNavigate(`${location.pathname.replace(`/partida/${idPartidaProyecto}`)}`);
      setAnchorEl(null);
    } catch (error) {
      // Maneja cualquier error que pueda ocurrir durante la eliminación
      console.error('Error al eliminar la partida:', error);
    } finally {
      setLoadingDelete(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'delete-popover' : undefined;

  return (
    <div>
      <Tooltip title="Eliminar partida">
        <IconButton>
          <DeleteIcon onClick={handleClick} />
        </IconButton>
      </Tooltip>
      {/* <Button variant="contained" onClick={handleClick} startIcon={<DeleteIcon />}>
        Delete
      </Button> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '16px', textAlign: 'center' }}>
          <Typography>¿Estás seguro que quieres eliminar esta partida?</Typography>
          <Button
            endIcon={loadingDelete && <CircularProgress style={{ color: 'white' }} size={20} />}
            variant="contained"
            color="error"
            onClick={handleDeleteConfirm}
            style={{ margin: '8px' }}
          >
            Sí
          </Button>
          <Button variant="contained" onClick={handleClose} style={{ margin: '8px', background: '#333' }}>
            No
          </Button>
        </div>
      </Popover>
    </div>
  );
}
