import Alcampo from './Alcampo';
import ProtectedAuth0 from './ProtectedAuth0';
import { UserProvider } from '../context/UserProvider';
import { ArteFinalProvider } from '../context/ArteFinalContext';

const AlcampoLayout = () => {
  return (
    <ProtectedAuth0>
      <UserProvider>
        <ArteFinalProvider>
          <Alcampo />
        </ArteFinalProvider>
      </UserProvider>
    </ProtectedAuth0>
  );
};

export default AlcampoLayout;
