import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

const CommentCard = ({ comment }) => {

    function parseFecha(fecha) {
        const dateObj = new Date(fecha);
        const opcionesHora = {
            hour: "numeric",
            minute: "numeric",
            hour12: false
        };
        const hora = dateObj.toLocaleTimeString([], opcionesHora);
        const fechaFormateada = dateObj.toLocaleDateString();
        return `${hora} - ${fechaFormateada}`;
      }

    return (
        <>
          <Card>
            <CardHeader style={{ padding: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="d-flex flex-row pb-1">
    
                <img
                  // alt={sender.name}
                  // src={sender.thumb}
                  src={comment.user.picture}
                  className="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall"
                />
                <div className=" d-flex flex-grow-1 min-width-zero">
                  <div className="m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                    <div className="min-width-zero">
                      <p className="mb-0 truncate list-item-heading">
                        {comment.user.name}
                        {/* {sender.name} */}
                      </p>
                      <span className="text-small text-muted">
                  {parseFecha(comment.createdAt)}
                </span>
                    </div>
                  </div>
                </div>
              </div>
                
            </CardHeader>
    
            <CardBody style={{ paddingTop: '0' }}>
              <div className="chat-text-left">
                <p className="mb-0 text-semi-muted">
                  {comment.comment}
                  {/* {item.text} */}
                </p>
              </div>
              {/* <Carousel
                onClickThumb={(e) => setOpenImg(e)}
                showThumbs={true} // Muestra las miniaturas
                showArrows={false} // Oculta las flechas de navegación
                showStatus={false} // Oculta el estado (por ejemplo, 1/3)
                showIndicators={false} // Oculta los indicadores de posición
              >
                {note.img.map((img, index) => (
                  <div key={img.name}>
    
                    <img
                      src={img.url}
                      alt={img.name}
                      style={{
                        width: "0px",
                        height: "0px",
                      }}
                    />
                  </div>
                ))}
              </Carousel> */}
            </CardBody>
          </Card>
          <div className="clearfix" />
          {/* {openImg != null && <Lightbox mainSrc={note.img[openImg].url} onCloseRequest={() => setOpenImg(null)} />} */}
        </>
      );

};

export default CommentCard;