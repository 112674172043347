import { createContext, useContext, useEffect, useState } from 'react';
import { getAllArtesFinales } from '../api/carteleriaApi';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { LIMIT } from '../utils/constants';

export const MasterContext = createContext();

export const MasterProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [inputAfter, setInputAfter] = useState('');
  const [categorySelected, setCategorySelected] = useState([]);
  const [superIdCategory, setSuperIdCategory] = useState(null);
  const [inputValueSearch, setInputValueSearch] = useState(null);
  const [centerTypeSelected, setCenterTypeSelected] = useState('all'); //TODO: Mirar donde se esta usando porque peta en algun controller al cmabiarlo a all
  const [centerSelected, setCenterSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState('all');
  const [validateStatus, setValidateStatus] = useState('all');
  const [arteFinalInfo, setArteFinalInfo] = useState(null);
  const [page, setPage] = useState(1);

  /* -------------------------------------------------------------------------- */
  /*                           FILTER CATEGORY MASTER                           */
  /* -------------------------------------------------------------------------- */
  const [currentOption, setCurrentOption] = useState(null);

  const {
    data: request,
    refetch,
    isFetching,
  } = useQuery(
    [
      'all-artes-finales-master',
      page,
      validateStatus,
      statusSelected,
      superIdCategory?._id,
      centerTypeSelected,
      centerSelected?._id,
      inputValueSearch,
    ],
    async () => {
      const token = await getAccessTokenSilently();
      return await getAllArtesFinales({
        token,
        page,
        limit: LIMIT,
        validateAAFF: validateStatus, // favouritesToEsencialseCategory
        activeAAFF: statusSelected, // * active o inactivo
        superIdCategory: superIdCategory?._id,
        centerTypeSelected,
        centerConfirmed: centerSelected?._id
          ? {
              comunidad: centerSelected.comunidad,
              centerType: centerSelected.centerType,
              imagenAnterior: centerSelected.imagenAnterior,
              imagenNueva: centerSelected.imagenNueva,
            }
          : null,
        inputValueSearch,
        panelMaster: true,
      });
    },
  );

  useEffect(() => {
    setCenterSelected(null);
  }, [centerTypeSelected]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [centerSelected, superIdCategory?._id, centerTypeSelected, statusSelected, inputValueSearch, validateStatus]);

  const data = {
    categorySelected,
    setCategorySelected,
    superIdCategory,
    setSuperIdCategory,
    inputValueSearch,
    setInputValueSearch,
    centerTypeSelected,
    setCenterTypeSelected,
    centerSelected,
    setCenterSelected,
    statusSelected,
    setStatusSelected,
    validateStatus,
    setValidateStatus,
    page,
    setPage,
    LIMIT,
    request,
    refetch,
    isFetching,
    inputAfter,
    setInputAfter,
    arteFinalInfo,
    setArteFinalInfo,
    currentOption,
    setCurrentOption,
  };
  return <MasterContext.Provider value={data}>{children}</MasterContext.Provider>;
};

export const useMaster = () => {
  const context = useContext(MasterContext);
  if (context === undefined) {
    throw new Error('useMaster must be used within a MasterProvider');
  }
  return context;
};
