import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GraphicsMercado from './GraphicsMercado';
import CarteleriaMercado from './CarteleriaMercado';
import { PointContext } from '../../context/PointContext';
import Tooltip from '@mui/material/Tooltip';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabsMercado = () => {
  const { arePointsActive } = React.useContext(PointContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="graphics-panel"
          style={{ color: '#d32f2f' }}
          TabIndicatorProps={{ style: { background: '#d32f2f' } }}
        >
          <Tab label="Gráficos" {...a11yProps(0)} className="tabs-mercado" />
          <Tooltip
            title={!arePointsActive && 'Activa la extensión de cartelería del visor para poder visualizar esta pestaña'}
            placement="right-end"
          >
            {arePointsActive ? (
              <Tab label="Cartelería" {...a11yProps(1)} className="tabs-mercado" />
            ) : (
              <span>
                <Tab label="Cartelería" {...a11yProps(1)} disabled={!arePointsActive} />
              </span>
            )}
          </Tooltip>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} className="custom-panel-mui">
        <GraphicsMercado />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} className="custom-panel-mui">
        <CarteleriaMercado />
      </CustomTabPanel>
    </Box>
  );
};

export default TabsMercado;
