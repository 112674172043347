import { createContext, useState } from 'react';

export const GestionClusterContext = createContext();

export const GestionClusterProvider = ({ children }) => {
  const [inputValueSearch, setInputValueSearch] = useState(null);
  const [inputAfter, setInputAfter] = useState('');

  const data = {
    inputValueSearch,
    setInputValueSearch,
    inputAfter,
    setInputAfter,
  };

  return <GestionClusterContext.Provider value={data}>{children}</GestionClusterContext.Provider>;
};
