import React, { useContext, useEffect, useState } from 'react';
import { useGetProvidersFormat } from '../../hooks/useFormats';
import { ArteFinalContext_v2 } from '../../context/ArteFinalContext_v2';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';

const FormatSelected = ({ option, selected }) => {
  const { shoppingCart_v2 } = useContext(ShoppingCartContext);
  const { formatSelected, formatProviderSelected, setFormatProviderSelected, arteFinal } =
    useContext(ArteFinalContext_v2);
  const { data: providers, isLoading } = useGetProvidersFormat(option.value, selected);
  const [isProviderSelectOpen, setIsProviderSelectOpen] = useState(false);

  const cardStyle = { display: 'flex', flexDirection: 'column' };
  const tamañoStyle = { fontWeight: 'bold' };
  const materialStyle = { fontSize: '0.9rem !important', color: 'rgba(0, 0, 0, 0.54)' };

  const handleProviderSelectClick = (e) => {
    e.stopPropagation();
    setIsProviderSelectOpen(!isProviderSelectOpen);
  };

  const handleProviderChange = (e) => {
    const selectedProvider = providers.data.find((provider) => provider._id === e.target.value);
    setFormatProviderSelected(selectedProvider);
  };

  useEffect(() => {
    // si es un formato custom, por defecto pondremos su primer proveedor
    if (option.formatoCustom && providers && providers.data.length > 0) {
      setFormatProviderSelected(providers.data[0]);
    }
  }, [isLoading]);

  return option.formatoCustom ? (
    <div style={tamañoStyle}>{option.formatName}</div>
  ) : (
    <div style={cardStyle}>
      <div style={tamañoStyle}>{option.formatName}</div>
      <div style={materialStyle}>
        {option.formatoMaterial ? `${option.formatoCode} - ${option.formatoMaterial}` : option.formatoCode}
      </div>
      {selected && !isLoading && providers && providers.data.length > 0 && (
        <div>
          <select
            className={`${
              !!shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId]?.quantity > 0 ? 'cursor-default' : ''
            } custom-select-provider`}
            onClick={handleProviderSelectClick}
            onChange={handleProviderChange}
            value={
              shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId]
                ? shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId].providerFormat._id
                : formatProviderSelected?._id || ''
            }
            disabled={!!shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId]?.quantity > 0}
          >
            <option value="">Selecciona el proveedor</option>
            {providers.data.map((provider) => {
              const optionText = `${provider.code} ${provider.provider.shortName} - ${provider.price.toLocaleString(
                'es-ES',
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              )} €`;
              return (
                <option key={provider._id} value={provider._id}>
                  {optionText}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </div>
  );
};

export default FormatSelected;
