import React, { useContext } from 'react';
import { ProyectoContext } from '../../context/ProyectoContext';
import { AlertVersion } from '../Alerts/AlertVersion';
import ExportExcel from './ExportExcel';
import { useQuery } from '@tanstack/react-query';
import { getProjectImported } from '../../api/alcampoApi';
import { useAuth0 } from '@auth0/auth0-react';

const DataProyecto = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentProject } = useContext(ProyectoContext);

  const { data: priceProject } = useQuery(
    ['price-project', currentProject?._id],
    () => {
      return getAccessTokenSilently().then((token) => getProjectImported(currentProject?._id, token));
    },
    {
      enabled: !!currentProject?._id,
    },
  );

  return (
    <div style={{ paddingLeft: '1rem', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <h2 className="montserrat">Partidas</h2>
              <ExportExcel />
            </div>
            {/* <ButtonExportBc3 />
            <ButtonExportERP /> */}
          </div>
          <p className="roboto-condensed mb-0">
            Presupuesto {currentProject?.name}:{' '}
            {priceProject?.toLocaleString('es-ES', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            €
          </p>
        </div>
        {/* AQUI VA LA SECCION DE ACTUALIZAR A LA ULTIMA VERSION */}
        <AlertVersion />
      </div>
    </div>
  );
};

export default DataProyecto;
