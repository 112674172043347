import { Button, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useAuth0 } from '@auth0/auth0-react';
import { updateArteFinalMaster } from '../../api/carteleriaApi';
import toast from 'react-hot-toast';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useMaster } from '../../context/MasterContext';

const FavouriteSelected = ({ row, centerTypes, selectedIdsFavorite, setSelectedIdsFavorite }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const { refetch } = useMaster();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (idArte, centerType) => {
    setSelectedIdsFavorite((prevSelectedIdsFavorite) => {
      return prevSelectedIdsFavorite.map((item) => {
        if (item.idArte === idArte) {
          return {
            ...item,
            centers: item.centers.includes(centerType)
              ? item.centers.filter((center) => center !== centerType)
              : [...item.centers, centerType],
          };
        }
        return item;
      });
    });
  };

  const handleConfirmUpdateFavourite = async () => {
    const token = await getAccessTokenSilently();
    const centersIds = selectedIdsFavorite.find((item) => item.idArte === row._id).centers;
    updateArteFinalMaster(token, row._id, { favourite: centersIds });
    refetch(); // TODO: esto me parece muy poco eficiente, en todo caso como mucho tendriamos que hacer el refetch de la data que se actualiza. Es decir, de la row que se actualiza. No de toda la data.
    toast.success('Favoritos actualizados con éxito');
    handleClose();
  };

  const isFavorite = (idArte, centerType) => {
    const selectedItem = selectedIdsFavorite.find((item) => item.idArte === idArte);
    return selectedItem && selectedItem.centers.includes(centerType);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'orange',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
      padding: '8px 12px',
    },
  }));

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        {!row.isValidate && (
          <BootstrapTooltip title="Pendiente de validación" placement="right-start">
            <IconButton className="alert-message">
              <ReportProblemIcon color="warning" />
            </IconButton>
          </BootstrapTooltip>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <IconButton onClick={handleClick}>
          {selectedIdsFavorite[0]?.centers?.length > 0 ? <StarIcon color="error" /> : <StarBorderIcon />}
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {row?.centerType?.map((centerType) => (
            <MenuItem key={centerType} onClick={() => handleMenuItemClick(row._id, centerType)}>
              {isFavorite(row._id, centerType) ? <StarIcon color="error" /> : <StarBorderIcon />}
              {centerTypes?.data.find((el) => el._id === centerType)?.type}
            </MenuItem>
          ))}

          <div style={{ display: 'flex', gap: '0.5rem', padding: '0.5rem' }}>
            <Button variant="outlined" color="error" onClick={handleConfirmUpdateFavourite}>
              Confirmar
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default FavouriteSelected;
