import cls from 'classnames';

const InfoArteFinalContainer = ({ classnames, children, ...props }) => {
  return (
    <div className={cls('item-arte-final-container', classnames)} {...props}>
      {children}
    </div>
  );
};

export default InfoArteFinalContainer;
