import jsPDF from 'jspdf';
import { getChildrenFormatsByOrderItem, getImageArteFinalDo } from '../../api/carteleriaApi';
import { blobToDataURL, handleConvert } from './reduceImage';

const formatDate = (date) => {
  const dateArray = date.split('T');
  return dateArray[0];
};

export const generateOrderPdf = async (token, orders, costeInstalacion) => {
  try {
    let totalCosteInstalacion = 0;
    let totalProductos = 0;
    // si hay coste de instalacion añadirlo sacar el total y añadirlo al final del pdf
    if (costeInstalacion?.length > 0) {
      costeInstalacion.forEach((coste) => {
        totalCosteInstalacion += coste.cantidad * coste.costeInstalacion.coste;
      });
    }


    // TODO: Aquí hay que cambiar la petición del servidor de getChildrenFormatsByOrderItem
    const allChildrens = await Promise.all(
      orders.orderItems.map(async (item) => {
        // por cada arte final que tenga formatChildren en true iremos a buscar los formatos hijos
        if (item.formatChildren.length > 0) {
          const childrens = await getChildrenFormatsByOrderItem(token, item._id);
          // devolver el array de childrens y dentro de cada uno meter quantity
          return childrens.map((child) => {
            const { arteFinal, formatName, formatCode, formatPrice, formatCustom, provider, material } =
              child.formatChildren;

            return {
              arteFinal,
              format: formatName,
              code: formatCode,
              custom: formatCustom,
              price: formatPrice,
              provider: provider,
              material: material,
              quantity: child.formatChildren.quantity.quantity,
              _id: childrens._id,
            };
          });
        }
      }),
    );

    // Filtrar los elementos undefined del array
    const filteredChildrens = allChildrens.filter((item) => item !== undefined).flat();

    const allItems = [...filteredChildrens, ...orders.orderItems];
    const arteFinalsWithImg = await Promise.all(
      allItems.map(async (item) => {
        if (item?.arteFinal?.imglink) {
          const arteFinalImg = await getImageArteFinalDo(token, item.arteFinal.imglink);
          return {
            ...item,
            arteFinalImg: arteFinalImg,
          };
        } else {
          return {
            ...item,
            arteFinalImg: null,
          };
        }
      }),
    );

    // ordenarlos por _id
    arteFinalsWithImg.sort((a, b) => (a._id > b._id ? 1 : -1));

    const pdf = new jsPDF();

    const logo = new Image();
    logo.src = '/logo_alcampo.png';

    // el logo lo quiero a la derecha y arriba del pdf
    pdf.addImage(logo, 'PNG', 150, 10, 50, 22.59);

    pdf.setFontSize(11);
    pdf.setFillColor('#F4ACAC'); // Color de fondo
    // pdf.rect(115, 30, 100, 7.5, 'F'); // Rectángulo de color de fondo
    // pdf.setDrawColor(0); // Color del borde
    // pdf.setLineWidth(0.5); // Grosor del borde
    pdf.roundedRect(115, 30, 100, 7.5, 4, 4, 'F'); // Rectángulo con borde redondeado en la parte izquierda
    pdf.setFont(undefined, 'bold');
    pdf.text(`Solicitud: ${orders.order[0]?.orderId} / ${orders.order[0].createdAt.split('-')[0]}` || 'Test', 120, 35);
    pdf.text(`Fecha: ${formatDate(orders.order[0].createdAt)}`, 160, 35);

    // poner a la izquierda antes de los orderItems la tienda, nombre de contacto y telefono de contacto
    pdf.setFont(undefined, 'normal');
    pdf.text(`Tienda: ${orders.order[0].centerCode} - ${orders.order[0].centerCalle}`, 20, 45);
    pdf.text(`Email de contacto: ${orders.order[0].user}`, 20, 52);
    pdf.text(`Teléfono de contacto: ${orders.order[0].contactPhone}`, 20, 59);
    pdf.text(`Extensión: ${orders.order[0].extension || 'Sin extensión'}`, 20, 66);

    pdf.text(
      `Fecha de entrega prevista: ${orders.order[0]?.deliverableDate?.split('T')[0] || 'Por determinar'}`,
      20,
      73,
    );

    // ahora titulo en color blanco centrado y con un rectangulo rojo con bordes redondeados
    pdf.setFillColor('#E21D26'); // Color de fondo
    pdf.roundedRect(20, 82, 170, 7.5, 4, 4, 'F'); // Rectángulo con borde redondeado en la parte izquierda
    pdf.setFont(undefined, 'bold');
    pdf.setTextColor('white');
    pdf.text('Artes finales', 105, 87, null, null, 'center');

    pdf.setTextColor('black');

    let orderItems = arteFinalsWithImg.map((orderItem) => {
      totalProductos += orderItem.quantity;
      const img = orderItem.arteFinalImg?.imageUrl || '/image_not_available.png';

      return {
        Extension: orderItem.arteFinalImg?.extension || 'png',
        'Arte final Img': img,
        Width: orderItem.arteFinalImg?.width || 512,
        Height: orderItem.arteFinalImg?.height || 307,
        'Arte final': orderItem.code + ' - ' + orderItem.arteFinal.name,
        code: orderItem.code,
        'Name AF': orderItem.arteFinal.name,
        Formato: orderItem?.custom || orderItem?.formatId?.name || 'Sin definir',
        Personalizado: orderItem?.custom ? 'Si' : 'No',
        Cantidad: orderItem.quantity.toString(),
        Proveedor: orderItem?.provider?.name,
        Material: orderItem?.materialCustom?.nombre || orderItem?.material?.nombre || 'Sin definir',
        PrecioTotal: orderItem?.custom ? 'Por presupuestar' : (orderItem.price * orderItem.quantity).toFixed(2) + ' €',
        Precio: orderItem?.custom ? 'Por presupuestar' : orderItem.price.toFixed(2) + ' €',
      };
    });

    pdf.setFontSize(10);
    pdf.setFont(undefined, 'normal');

    let verticalSpacing = 12; // Distancia inicial entre elementos (reducida en 10 unidades) Esto es la distnacia que hay entre el item y la línea que separa
    let remainingSpace = pdf.internal.pageSize.getHeight() - 109; // Espacio restante en la página

    for (let index = 0; index < orderItems.length; index++) {
      const item = orderItems[index];
      if (item['Arte final Img']) {
        const scaleFactor = 45 / Math.max(item['Width'], item['Height']);
        const scaledWidth = item['Width'] * scaleFactor;
        const scaledHeight = item['Height'] * scaleFactor;
        let totalHeight;

        // calcular que es mas alto la imagen o el texto para ellos cogemos el height de todos los textos y el height de la imagen
        // y el que sea mas alto lo usamos para calcular el totalHeight
        const textHeight = 40; // 5 para el espacio entre la imagen y el texto, 15 para el espacio ocupado por el texto

        // Calcula la altura total del elemento
        if (textHeight > scaledHeight) {
          totalHeight = 5 + textHeight + verticalSpacing + 15; // 5 para el espacio entre la imagen y el texto, 15 para el espacio ocupado por el texto
        } else {
          totalHeight = 5 + scaledHeight + verticalSpacing + 15; // 5 para el espacio entre la imagen y el texto, 15 para el espacio ocupado por el texto
        }

        if (remainingSpace < totalHeight) {
          pdf.addPage();
          remainingSpace = pdf.internal.pageSize.getHeight() - 25; // Reiniciar el espacio restante en la nueva página
        }

        const x = 20;
        const y = pdf.internal.pageSize.getHeight() - remainingSpace;
        const width = Math.min(scaledWidth, pdf.internal.pageSize.getWidth() - x);
        const height = Math.min(scaledHeight, pdf.internal.pageSize.getHeight() - y);

        if (width > 0 && height > 0) {
          const image = await handleConvert(item['Arte final Img']);
          const blobData = await blobToDataURL(image);
          // poner el titulo del arte justo encima de la imagen
          pdf.setFont(undefined, 'bold');
          pdf.text(`Artículo:`, x, y - 2);
          pdf.setFont(undefined, 'normal');
          pdf.text(`${item['Name AF']}`, x + 17, y - 2);

          pdf.setFont(undefined, 'bold');
          pdf.text(`Código ARIBA:`, x, y - 8);
          pdf.setFont(undefined, 'normal');
          pdf.text(`${item.code}`, x + 27, y - 8);
          pdf.addImage(blobData, item['Extension'], x, y + 4, width, height);
          pdf.setFont(undefined, 'normal');

          if (index !== orderItems.length - 1) {
            const nextSpacing = index < orderItems.length - 1 ? totalHeight + verticalSpacing : 0;
            const spaceAfterAddingItem = remainingSpace - totalHeight - nextSpacing;

            // Verificar si estamos en el último elemento de la página actual
            const isLastItemOnPage = spaceAfterAddingItem < 0;

            // Agregar línea horizontal al final de cada elemento, excepto en el último elemento de cada página
            if (!isLastItemOnPage) {
              const lineY = y + totalHeight;
              pdf.setDrawColor(200); // Configurar el color de la línea a un gris más claro
              pdf.line(20, lineY - 15, pdf.internal.pageSize.getWidth() - 20, lineY - 15); // Agregar línea horizontal
            }
          }

          // Aquí agregamos el texto del elemento debajo de la imagen
          // pdf.setFont(undefined, 'bold');
          pdf.text('Formato: ', 70, y + 7); // Reducir el espacio vertical en 5 unidades
          pdf.setFont(undefined, 'normal');
          pdf.text(item['Formato'], 87, y + 7); // Reducir el espacio vertical en 5 unidades
          // pdf.setFont(undefined, 'bold');
          pdf.text('Personalizado: ', 70, y + 12); // Reducir el espacio vertical en 5 unidades
          pdf.setFont(undefined, 'normal');
          pdf.text(item['Personalizado'], 97, y + 12); // Reducir el espacio vertical en 5 unidades
          // pdf.setFont(undefined, 'bold');
          pdf.text('Cantidad: ', 70, y + 17); // Reducir el espacio vertical en 5 unidades
          pdf.setFont(undefined, 'normal');
          pdf.text(item['Cantidad'], 88, y + 17); // Reducir el espacio vertical en 5 unidades
          // pdf.setFont(undefined, 'bold');
          pdf.text('Precio unitario: ', 70, y + 22); // Reducir el espacio vertical en 5 unidades
          pdf.setFont(undefined, 'normal');
          pdf.text(item['Precio'], 95, y + 22); // Reducir el espacio vertical en 5 unidades
          // pdf.setFont(undefined, 'bold');
          pdf.text('Precio total: ', 70, y + 27); // Reducir el espacio vertical en 5 unidades
          pdf.setFont(undefined, 'normal');
          pdf.text(item['PrecioTotal'], 92, y + 27); // Reducir el espacio vertical en 5 unidades
          // pdf.setFont(undefined, 'bold');
          pdf.text('Material: ', 70, y + 32); // Reducir el espacio vertical en 5 unidades
          pdf.setFont(undefined, 'normal');
          pdf.text(item['Material'], 87, y + 32); // Reducir el espacio vertical en 5 unidades
          // pdf.setFont(undefined, 'bold');
          pdf.text('Proveedor: ', 70, y + 37); // Reducir el espacio vertical en 5 unidades
          pdf.setFont(undefined, 'normal');
          pdf.text(item['Proveedor'], 90, y + 37); // Reducir el espacio vertical en 5 unidades


          // comprobaremos si hay comentario y imagen adicional del usuario y si lo hay justo debajo de la imagen lo añadiremos

          remainingSpace -= totalHeight;
        } else {
          console.error('Las dimensiones de la imagen están fuera de los límites permitidos.');
        }
      } else {
        console.error('La imagen del arte final no está cargada correctamente.');
      }
    }

    // Después de mostrar los elementos del pedido (orderItems)

    // LISTADO DE COSTES DE INSTALACION

    const yAfterOrderItems = pdf.internal.pageSize.getHeight() - remainingSpace;

    // ahora titulo en color blanco centrado y con un rectangulo rojo con bordes redondeados

    costeInstalacion.length > 0 && pdf.setFillColor('#E21D26'); // Color de fondo
    costeInstalacion.length > 0 && pdf.roundedRect(20, yAfterOrderItems - 10, 170, 7.5, 4, 4, 'F'); // Rectángulo con borde redondeado en la parte izquierda
    costeInstalacion.length > 0 && pdf.setFont(undefined, 'bold');
    costeInstalacion.length > 0 && pdf.setTextColor('white');
    costeInstalacion.length > 0 && pdf.text('Costes de instalación', 105, yAfterOrderItems - 5, null, null, 'center');
    costeInstalacion.length > 0 && pdf.setTextColor('black');

    // Calcular el espacio restante después de agregar el título de los costes de instalación
    remainingSpace -= 10; // Ajusta según el espacio ocupado por el título y el rectángulo

    // Ahora puedes continuar con el código para mostrar los detalles de los costes de instalación si lo deseas.
    let costesInstalacion = costeInstalacion.map((coste) => ({
      'Coste de instalacion':
        coste.costeInstalacion.nombre +
        ' - ' +
        coste.costeInstalacion.localizacion +
        ' - ' +
        coste.costeInstalacion.tipoCoste,
      Cantidad: coste.cantidad.toString(),
      Precio: coste.costeInstalacion.coste.toFixed(2) + ' €',
      Codigo: coste.costeInstalacion.codigo,
    }));

    costesInstalacion.forEach((item, index) => {
      const textHeight = 12;
      const totalHeight = 5 + textHeight + verticalSpacing + 15;

      if (remainingSpace < totalHeight) {
        pdf.addPage();
        remainingSpace = pdf.internal.pageSize.getHeight() - 25; // Reiniciar el espacio restante en la nueva página
      }

      const x = 20;
      const y = pdf.internal.pageSize.getHeight() - remainingSpace;

      if (index !== costesInstalacion.length - 1) {
        const nextSpacing = index < costesInstalacion.length - 1 ? totalHeight + verticalSpacing : 0;
        const spaceAfterAddingItem = remainingSpace - totalHeight - nextSpacing;

        // Verificar si estamos en el último elemento de la página actual
        const isLastItemOnPage = spaceAfterAddingItem < 0;

        // Agregar línea horizontal al final de cada elemento, excepto en el último elemento de cada página
        if (!isLastItemOnPage) {
          const lineY = y + totalHeight;
          pdf.setDrawColor(200); // Configurar el color de la línea a un gris más claro
          pdf.line(20, lineY - 15, pdf.internal.pageSize.getWidth() - 20, lineY - 15); // Agregar línea horizontal
        }
      }

      pdf.setFont(undefined, 'bold');
      pdf.text(item['Coste de instalacion'], x, y - 2);
      pdf.setFont(undefined, 'normal');
      pdf.text('Cantidad: ', x, y + 7); // Reducir el espacio vertical en 5 unidades
      pdf.text(item['Cantidad'], x + 18, y + 7); // Reducir el espacio vertical en 5 unidades
      pdf.text('Precio: ', x, y + 12); // Reducir el espacio vertical en 5 unidades
      pdf.text(item['Precio'], x + 14, y + 12); // Reducir el espacio vertical en 5 unidades
      pdf.text('Código: ', x, y + 17); // Reducir el espacio vertical en 5 unidades
      pdf.text(item['Codigo'] || '-', x + 14, y + 17); // Reducir el espacio vertical en 5 unidades

      remainingSpace -= totalHeight;
    });

    // RESUMEN DE TOTAL DE PRODUCTOS Y COSTE DE INSTALACION

    let total = 0;
    orders.orderItems.forEach((item) => {
      total += (item?.totalPrice || item.price) * item.quantity;
    });

    // si no hay espacio añadir otra pagina y poner el importe total
    if (remainingSpace < 20) {
      pdf.addPage();
      remainingSpace = pdf.internal.pageSize.getHeight() - 25; // Reiniciar el espacio restante en la nueva página
    }
    const montajeX = 20;
    const montajeY = pdf.internal.pageSize.getHeight() - 30;

    // rectangulo con borde negro que envuelva montaje, portes e importe total

    pdf.setFillColor('white');
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.5);
    pdf.rect(montajeX, montajeY - 6, pdf.internal.pageSize.getWidth() - 40, 15, 'D');

    pdf.setFont(undefined, 'normal');
    pdf.text(`${total.toFixed(2)} € - Productos (${totalProductos})`, montajeX + 5, montajeY);

    const importeTotalX = pdf.internal.pageSize.getWidth() - 38.1;
    const importeTotalY = pdf.internal.pageSize.getHeight() - 25;

    pdf.text(`${totalCosteInstalacion.toFixed(2)} € - Montaje `, montajeX + 5, importeTotalY);

    pdf.setFont(undefined, 'bold');
    pdf.text('Importe neto: ', importeTotalX - 25, importeTotalY);
    pdf.setFont(undefined, 'normal');
    pdf.text((total + totalCosteInstalacion).toFixed(2) + ' €', importeTotalX, importeTotalY);

    remainingSpace -= 20;

    const pageCount = pdf.internal.getNumberOfPages();
    // ahora por cada pagina que haya añadir el numero de pagina en la esquina inferior derecha
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.text(
        `Página ${i} de ${pageCount}`,
        pdf.internal.pageSize.getWidth() - 40,
        pdf.internal.pageSize.getHeight() - 10,
      );
    }

    pdf.save(`${formatDate(orders.order[0].createdAt)}_${orders.order[0]._id}.pdf`);

    return 'success';
  } catch (err) {
    console.log('ERROR AL GENERAR EL PDF: ', err);
    throw new Error(err);
  }
};
