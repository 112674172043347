import React, { useState } from 'react';
import toast from 'react-hot-toast';
import InspireTree, { TreeNode } from 'inspire-tree';
import InspireTreeDOM from 'inspire-tree-dom';
import { launchViewer } from '../../helpers/viewerHelper';

const getJSON = async (url, token) => {
  
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (!res.ok) {
    toast.error(`Error: ${res.status} ${res.statusText}`);
    console.error(await res.text());
    return [];
  }
  return res.json();
};

const createTreeNode = (id, text, icon, children = false, selected = false) => {
  return {
    id,
    text,
    children,
    itree: { state: { selected: selected }, icon },
  };
};

const getHubs = async (token) => {

  const hubs = await getJSON('/api/v1/hubs', token);
  return hubs.map((hub) => createTreeNode(`hub|${hub.id}`, hub.attributes.name, 'icon-hub', true));
};

const getProjects = async (token, hubId) => {
  const projects = await getJSON(`/api/v1/hubs/${hubId}/projects`, token);
  return projects?.map((project) =>
    createTreeNode(`project|${hubId}|${project.id}`, project.attributes.name, 'icon-project', true),
  );
};

const getContents = async (token, hubId, projectId, folderId = null) => {
  const contents = await getJSON(
    `/api/v1/hubs/${hubId}/projects/${projectId}/contents` +
      (folderId ? `?folderId=${folderId}` : '', token),
  );
  //   console.log("CARPETAS PARA ENTRAR A LAS VERSIONES:", contents);
  //   console.log("VAMOS A VER QUE ES LA FOLDER Y DE DONDE VIENE", folderId);
  return contents.map((content) => {
    if (content.type === 'folders') {
      return createTreeNode(
        `folder|${hubId}|${projectId}|${content.id}`,
        content.attributes.displayName,
        'icon-my-folder',
        true,
      );
    } else {
      return createTreeNode(
        `item|${hubId}|${projectId}|${content.id}`,
        content.attributes.displayName,
        'icon-item',
        false,
      );
    }
  });
};

const getVersions = async (
  token,
  hubId,
  projectId,
  itemId,
  setIsModelLoaded,
  handleResetPoints,
  setPoints,
  setSelectedPoint,
  selectedPoint,
) => {

  let lastVersion = null;
  const versions = await getJSON(
    `/api/v1/hubs/b.1ae2ac7b-1db9-4b0d-aeec-cf6e1d5a698b/projects/b.5debd4e0-df48-4326-af59-7916804ca9f7/contents/${itemId}/versions`, token
  );
  // console.log('versions attribute', document.getElementByA)
  lastVersion = versions[0].id;
  // Obtener la URL actual
  const currentURL = window.location.href;

  // Agregar un parámetro de consulta a la URL
  const nuevaURL = `${currentURL}?=${window.btoa(lastVersion).replace(/=/g, '')}`;

  // Establecer la nueva URL como la URL actual
  // window.location.href = nuevaURL;

  // version|urn:adsk.wipprod:fs.file:vf.jpdL8J7GSPiHiWRtoFoIQA?version=4
  // console.log('otra más', document.querySelectorAll('[data-uid]').forEach)

  // console.log('versions', document.querySelector('li[data-uid="version|urn:adsk.wipprod:fs.file:vf.jpdL8J7GSPiHiWRtoFoIQA?version=46"]'))
  // console.log('version', document.querySelector(`li[data-uid="version|${lastVersion}"]`))

  // node.loadChildren().then(() => {
  //     console.log('node', node.children)
  // })
  // node.children[0].state = { checked: true };

  window.history.pushState(null, null, `?id=${window.btoa(lastVersion).replace(/=/g, '')}`);
  launchViewer(
    window.btoa(lastVersion).replace(/=/g, ''),
    '002',
    setIsModelLoaded,
    handleResetPoints,
    setPoints,
    setSelectedPoint,
    selectedPoint,
  );

  // const nodes = versions.map(version => createTreeNode(`version|${version.id}`, version.attributes.createTime, 'icon-version'));

  return versions.map((version, index) => {
    if (index === 0) {
      return createTreeNode(`version|${version.id}`, version.attributes.createTime, 'icon-version', false, true);
    } else {
      return createTreeNode(`version|${version.id}`, version.attributes.createTime, 'icon-version');
    }
  });

  // Agregar clase "selected" al primer elemento de la lista de versiones
  // const firstVersionNode = tree.node(nodes[0].id);
  // firstVersionNode.state('selected', true);
  // node.children[0].state('selected', true);

  // return nodes;

  // return versions.map((version, index) => {
  //     if (index === 0) {
  //         firstVersionNode = createTreeNode(`version|${version.id}`, version.attributes.createTime, 'icon-version');
  //     }
  //     return createTreeNode(`version|${version.id}`, version.attributes.createTime, 'icon-version');
  // })
};

// const test = async (tokens) => {
//     return await getVersions(tokens[1], tokens[2], tokens[3])
//     const divs = document.querySelectorAll('[data-uid]')
//     console.log('divs', divs)
//     const selectedDiv = divs.forEach(div => {
//         console.log('div', div)
//         if (div.getAttribute('data-uid') == 'version|urn:adsk.wipprod:fs.file:vf.jpdL8J7GSPiHiWRtoFoIQA?version=46') {
//             console.log(div);
//         }
//     })

// }

export const initTree = (
  token,
  selector,
  onSelectionChanged,
  setIsModelLoaded,
  handleResetPoints,
  setPoints,
  setSelectedPoint,
  selectedPoint,
  setSeletedUrn
) => {

  
  const tree = new InspireTree({
    data: function (node) {
      if (!node || !node.id) {
        return getHubs(token);

      } else {
        const tokens = node.id.split('|');
        console.log(tokens)
        switch (tokens[0]) {
          case 'hub':
            return  getProjects(token, tokens[1]);
          case 'project':
            return getContents(tokens[1], tokens[2]);
          case 'folder':
            return getContents(tokens[1], tokens[2], tokens[3]);
          // case 'item':
          //   return getVersions(
          //     tokens[1],
          //     tokens[2],
          //     tokens[3],
          //     setIsModelLoaded,
          //     handleResetPoints,
          //     setPoints,
          //     setSelectedPoint,
          //     selectedPoint,
          //   );
          default:
            return [];
        }
      }
    },
  });

  // tree.on('node.collapsed', function (event, node) {
  //     console.log('VAMOS A VER EL NODO CARGADO: ', node)
  // })

  tree.on('node.click', function (event, node) {
    // console.log("NODO SELECCIONADO: ", node);
    event.preventDefault();
    const tokens = node.id.split('|');
    console.log("juan demonio ",tokens)
    let file = event.target.text
    file=file.split('.')
    console.log()
    if(tokens[3]?.includes('wipprod:dm.lineage') && file[1]==='rvt') {
      setSeletedUrn(tokens[3])
    }
    if (tokens[0] === 'version') {
      onSelectionChanged(tokens[1]);
    }
  });

  // tree.on('model.loaded', function () {
  //     const searchParams = new URLSearchParams(window.location.search)
  //     const id = searchParams.get('id')
  //     const versionNodeId = window.atob(id)
  //     const nodes = tree.nodes();
  //     const hasVersions = nodes.some(node => node.id.split('|')[0] === 'version')

  //     if (!hasVersions && id) {
  //         console.log('HAY NODOS DE VERSIONES')
  //         nodes.expandDeep()
  //         // const node = tree.find(node => node.id === `version|${versionNodeId}`)
  //         // console.log('NODO ENCONTRADO', node)

  //         // nodes.collapseDeep()

  //         // node.id.includes(`version|${versionNodeId}`)
  //         // versionNode.expandParents();
  //     } else {
  //         return new InspireTreeDOM(tree, { target: selector })

  //     }

  //     //PRUEBAS
  //     // if (node.hasChildren()) {
  //     //     console.log('Tiene hijos')
  //     // }

  // });

  // tree.on('changes.applied', function () {
  //     const searchParams = new URLSearchParams(window.location.search)
  //     const id = searchParams.get('id')
  //     const versionNodeId = window.atob(id)
  //     const nodes = tree.nodes();
  //     const node = tree.find(node => node.id === `version|${versionNodeId}`)
  //     // console.log('NODO ENCONTRADO', node)
  //     // const node = tree.find(node => node.id === )

  //     if (node !== undefined) {
  //         console.log('NODO ENCONTRADO y no es undefined')
  //         tree.nodes().collapseDeep()
  //         node.expandParents();
  //         return new InspireTreeDOM(tree, { target: selector })
  //     }
  // });

  // tree.on('node.expanded', function (event, node) {

  // });

  // const nodes = tree.nodes();

  // nodes.forEach(node => {
  //     const tokens = node.id.split('|');
  //     console.log('ENTRA LA ITERACION', tokens[0])

  //     if (tokens[0] === 'version') {
  //         console.log('HAY NODOS DE VERSIONES')
  //     } else {
  //         console.log('NO HAY NODOS DE VERSIONES')
  //     }
  // });

  return new InspireTreeDOM(tree, { target: selector });
};
