import { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';
import { Button, TextField } from '@mui/material';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import {
  handleAddCategory,
  handleChangeNameNewCategory,
  handleCreateNewCategories,
  handleDeleteCategory,
  moveCategory,
} from '../../../helpers/Category/gestionCategories';
import { useNavigate } from 'react-router-dom';
import CategoryForm from './CategoryForm';
import CategoryList from './CategoryList';

const FormAddCategory = ({ idCategory = null, parentCategoryName = null, categories, mutation, handleClose }) => {
  const navigate = useNavigate();

  const [categoriesOrder, setCategoriesOrder] = useState(
    categories.map((subcat, index) => ({ ...subcat, index: index + 1, new: false })),
  );
  const [newCategory, setNewCategory] = useState('');

  return (
    <form
      onSubmit={(e) =>
        handleCreateNewCategories({
          e,
          categoriesOrder,
          categories,
          toast,
          navigate,
          mutation,
        })
      }
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <div className={`subcategory-name-container${!idCategory ? '-no-grid' : ''}`}>
        {idCategory && (
          <TextField
            label="Categoría padre"
            variant="outlined"
            disabled
            type="text"
            minRows={3}
            required
            fullWidth
            value={parentCategoryName?.data?.name}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        <CategoryForm
          newCategory={newCategory}
          handleChange={(e) => handleChangeNameNewCategory(e, setNewCategory)}
          handleAdd={() =>
            handleAddCategory({
              newCategory,
              setNewCategory,
              setCategoriesOrder,
              categoriesOrder,
              idCategory,
              toast,
            })
          }
        />
      </div>

      <div className="gestion-create-container-subcategories">
        <MdSubdirectoryArrowRight size="20px" />
        <CategoryList
          isNewCategory={categoriesOrder.filter((subcat) => subcat.new).length > 0}
          categories={categoriesOrder}
          moveCategory={(dragIndex, hoverIndex) =>
            moveCategory({
              dragIndex,
              hoverIndex,
              setCategoriesOrder,
              categoriesOrder,
            })
          }
          handleDelete={(i) => handleDeleteCategory(i, categoriesOrder, setCategoriesOrder)}
        />
      </div>
      <div className="button-group-tiendas">
        <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
          Confirmar
        </Button>
        <Button type="button" variant="contained" color="error" endIcon={<CloseIcon />} onClick={handleClose}>
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default FormAddCategory;
