import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, useState } from 'react';
import { getAllSubcategories, moveCategory } from '../api/categorieApi';

export const GestionCategoryContext = createContext();

export const GestionCategoryProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [categoryMove, setCategoryMove] = useState(null);
  const [categoryMoveSelected, setCategoryMoveSelected] = useState(null);

  const { data: allSubcategories, isLoading } = useQuery(
    ['all-subcategories', categoryMove],
    () => getAllSubcategories(categoryMove.id),
    {
      enabled: !!categoryMove,
    },
  );

  const mutation = useMutation(moveCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries(['gestion-categories']);
    },
  });

  const data = {
    categoryMove,
    setCategoryMove,
    mutation,
    allSubcategories: allSubcategories?.data ? allSubcategories.data : [],
    isLoading,
    categoryMoveSelected,
    setCategoryMoveSelected,
  };

  return <GestionCategoryContext.Provider value={data}>{children}</GestionCategoryContext.Provider>;
};
