import React, { useContext, useEffect, useState } from 'react';
import { PartidasContext } from '../../context/PartidasContext';
import PrecioCapitulosGraphic from './PrecioCapitulosGraphic';
import { ProyectoContext } from '../../context/ProyectoContext';
import { getCapitulosByProject, getSubcapitulos } from '../../api/alcampoApi';
import { generateBackground } from '../../helpers/colorsHelper';
import { useAuth0 } from '@auth0/auth0-react';

const CapitulosGraphic = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { partidaQuery } = useContext(PartidasContext);
  const { currentProject } = useContext(ProyectoContext);

  const [capitulos, setCapitulos] = useState([]);
  const [isSubcapitulo, setIsSubcapitulo] = useState(false);

  useEffect(() => {
    const getCapitulos = async () => {
      let token = await getAccessTokenSilently();
      let capitulosByProject = await getCapitulosByProject(token, currentProject?._id);
      let colors = generateBackground('#660101', capitulosByProject.length, '#ff8282');
      // let strokes = generateBackground('#b32700', capitulosByProject.length, '#ffbba8');
      setCapitulos(
        capitulosByProject.map((capitulo, i) => ({
          color: colors[i],
          // stroke: strokes[i],
          value: capitulo.import,
          name: capitulo.capitulo.name,
        })) || [],
      );
    };

    const getSubcapitulosByCapitulo = async () => {
      setIsSubcapitulo(true);
      let token = await getAccessTokenSilently();
      let subcapitulos = await getSubcapitulos(partidaQuery?.capituloSelected, token);
      let colors = generateBackground('#660101', subcapitulos.length, '#ff8282');

      setCapitulos(
        subcapitulos.map((capitulo, i) => ({
          color: colors[i],
          value: capitulo.import,
          name: capitulo.capitulo.name,
        })) || [],
      );
    };

    if (!partidaQuery?.capituloSelected) {
      // traemos todos los capitulosProyecto
      getCapitulos();
    } else {
      // traemos todos los subcapitulosProyecto
      getSubcapitulosByCapitulo();
    }
  }, [partidaQuery?.capituloSelected, currentProject]);

  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      {/* SI ESTAMOS EN LOS CAPITULOS PADRE QUE SE MUESTRE EL GRAFICO PARA CAPITULOS SI NO PARA SUBCAPITULOS */}
      {capitulos?.length !== 0 &&
        (partidaQuery?.capituloSelected ? (
          <p style={{ color: '#B96F73' }}>PRECIO POR SUBCAPITULOS</p>
        ) : (
          <p style={{ color: '#B96F73' }}>PRECIO POR CAPITULOS</p>
        ))}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          {capitulos?.length == 0 ? (
            isSubcapitulo ? (
              <p>No hay mas subcapitulos</p>
            ) : (
              <p>No hay capitulos</p>
            )
          ) : (
            <PrecioCapitulosGraphic data={capitulos} />
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '.6rem' }}>
          {/* AQUI VA LA LEYENDA */}
          {capitulos?.map((capitulo) => (
            <div style={{ display: 'flex', alignItems: 'center', gap: '.4rem' }}>
              <span style={{ backgroundColor: capitulo.color }} className="square-legend" />
              <p className="m-0">
                {capitulo.name} (
                {capitulo.value?.toLocaleString('de-DE', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
                €
                {/* {calcularPorcentaje(
                  capitulo.value,
                  capitulos.map((capitulo) => capitulo.value),
                )}{' '} */}
                )
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CapitulosGraphic;
