import { Accordion, AccordionDetails, AccordionSummary, Checkbox, IconButton } from '@mui/material';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  handleChangeCheckedFormatChildren,
  handleChangeQuantityChildrenFormat,
} from '../../helpers/Compuesto/pedidoCompuesto';
import { useContext } from 'react';
import { ArteFinalContext_v2 } from '../../context/ArteFinalContext_v2';

const ExsitsProviderFormat = ({ index, childrenFormat, providerFormat }) => {
  const { requiredFormatChildren, openModalSelectChildrenFormat, setOpenModalSelectChildrenFormat } =
    useContext(ArteFinalContext_v2);

  return (
    <Accordion key={index}>
      <Row style={{ margin: '0', alignItems: 'center' }}>
        {!requiredFormatChildren && index !== 0 && (
          <Colxx style={{ paddingRight: '0' }} xxs="1">
            <Checkbox
              onChange={() =>
                handleChangeCheckedFormatChildren(
                  childrenFormat.formatoId,
                  openModalSelectChildrenFormat,
                  setOpenModalSelectChildrenFormat,
                )
              }
              sx={{
                color: '#3C4145',
                '&.Mui-checked': {
                  color: '#3C4145',
                },
              }}
              size="small"
              defaultChecked
            />
          </Colxx>
        )}
        {!requiredFormatChildren && index !== 0 && (
          <Colxx style={{ display: 'flex', alignItems: 'center', gap: '.2rem' }} xxs="2">
            <IconButton
              onClick={() =>
                handleChangeQuantityChildrenFormat(true, childrenFormat.formatoId, setOpenModalSelectChildrenFormat)
              }
              size="small"
            >
              <RemoveIcon />
            </IconButton>
            {childrenFormat.quantity}
            <IconButton
              onClick={() =>
                handleChangeQuantityChildrenFormat(false, childrenFormat.formatoId, setOpenModalSelectChildrenFormat)
              }
              size="small"
            >
              <AddIcon />
            </IconButton>
          </Colxx>
        )}
        <Colxx style={{ paddingLeft: '0' }} xxs={requiredFormatChildren || index === 0 ? '12' : '9'}>
          <AccordionSummary
            id={`panel-${index}-children-header`}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${index}-children`}
          >
            {childrenFormat.arteFinal.code + ' - ' + childrenFormat.arteFinal.name}
          </AccordionSummary>
        </Colxx>
      </Row>

      <AccordionDetails
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          padding: '1rem',
          margin: '0 2rem 2rem 2rem',
          border: '1px solid #ECECEC',
        }}
      >
        {/* AQUI MOSTRAMOS TODA LA INFORMACION DEL FORMATO DEL ARTE FINAL */}
        <Row style={{ margin: '0' }}>
          <Colxx xxs="6">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <span style={{ fontWeight: 'bold' }}>Código de formato: </span>
              <span>{childrenFormat.formatoCode}</span>
            </div>
          </Colxx>
          <Colxx xxs="6">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <span style={{ fontWeight: 'bold' }}>Formato: </span>
              <span>{childrenFormat.formatoName}</span>
            </div>
          </Colxx>
        </Row>
        <Row style={{ margin: '0' }}>
          <Colxx xxs="6">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <span style={{ fontWeight: 'bold' }}>Código del proveedor: </span>
              <span>{index === 0 ? childrenFormat?.providerFormat?.code : providerFormat.data.code}</span>
            </div>
          </Colxx>
          <Colxx xxs="6">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <span style={{ fontWeight: 'bold' }}>Proveedor: </span>
              <span>
                {index === 0 ? childrenFormat?.providerFormat?.provider?.name : providerFormat.data.provider.name}
              </span>
            </div>
          </Colxx>
        </Row>
        <Row style={{ margin: '0' }}>
          <Colxx xxs="3">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <span style={{ fontWeight: 'bold' }}>Cantidad: </span>
              {childrenFormat.quantity}
            </div>
          </Colxx>
          <Colxx xxs="3">
            {!requiredFormatChildren && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                <span style={{ fontWeight: 'bold' }}>Cantidad mínima:</span>
                <span>
                  {childrenFormat.originalQuantity > childrenFormat.minQuantity
                    ? childrenFormat.originalQuantity
                    : childrenFormat.minQuantity || 1}
                </span>
              </div>
            )}
          </Colxx>
          <Colxx xxs="3">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
              <span style={{ fontWeight: 'bold' }}>Precio (unitario):</span>
              <span>{childrenFormat?.price?.toFixed(2) + ' €'}</span>
            </div>
          </Colxx>
        </Row>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExsitsProviderFormat;
