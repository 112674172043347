import React from 'react';
import RegistradosButton from './RegistradosButton';
import NoRegistradosButton from './NoRegistradosButton';
import ShowAllButton from './ShowAllButton';

const ButtonsViewer = () => {
  return (
    <div
      style={{
        position: 'absolute',
        left: '55%',
        top: '1rem',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        display: 'flex',
        gap: '1rem',
      }}
    >
      <ShowAllButton />
      <RegistradosButton />
      <NoRegistradosButton />
    </div>
  );
};

export default ButtonsViewer;
