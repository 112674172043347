import React from 'react';
import { Tooltip } from 'react-tooltip';
import {
  changeCollapseCluster,
  handleClickRemoveCluster,
  handleRemoveTienda,
} from '../../../../helpers/PedidoPilotado/pedidoPilotado';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const SelectedContentTienda = ({ tiendaArray, tiendaSelected, setTiendaArray, setTiendaSelected }) => {
  return tiendaSelected.map((cluster, i) => (
    <div key={cluster._id._id}>
      <div
        onClick={() => changeCollapseCluster(cluster._id._id, setTiendaSelected)}
        className="aaff-pedido-pilotado"
        style={{
          cursor: 'pointer',
          height: '2rem',
          backgroundColor: '#F7F7F7',
          fontWeight: 'bold',
          borderBottom: i === tiendaArray.length - 1 ? 'none' : '1px solid #cecece',
          display: 'flex',
          alignItems: 'center',
          userSelect: 'none',
          gap: '.5rem',
          padding: '0 1rem',
          width: '100%',
        }}
      >
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <span className={`arrow-button-pedido-pilotado ${!cluster.collapse && 'rotate-arrow-pedido-pilotado'}`}>
              <KeyboardArrowRightIcon />
            </span>
            <p
              className={`texto-aaff-pedido-pilotado ${
                cluster._id.type.length > 53 && `cluster-pedido-pilotado-tooltip-${cluster._id._id}`
              }`}
              id={`texto-cluster-pedido-pilotado-selected-${cluster._id._id}`}
            >
              {`${cluster._id.type} (${cluster?.centers?.reduce((acc, center) => acc + 1, 0)} tiendas)`}
            </p>
          </div>
          <RemoveCircleOutlineIcon
            style={{ zIndex: '999999' }}
            onClick={(e) => {
              e.stopPropagation();
              handleClickRemoveCluster({ cluster, setTiendaArray, setTiendaSelected, tiendaArray });
            }}
          />
        </div>
      </div>

      <Tooltip anchorSelect={`.cluster-pedido-pilotado-tooltip-${cluster._id._id}`}>
        {`${cluster._id.type} (${cluster.count} tiendas)`}
      </Tooltip>

      <div className={`list-tienda-pedido-pilotado ${!cluster.collapse && 'show-list-tienda-pedido-pilotado'}`}>
        {cluster.centers.map((center) => (
          <div
            onClick={() => {
              handleRemoveTienda({
                tiendaArray,
                setTiendaArray,
                cluster,
                center,
                tiendaSelected,
                setTiendaSelected,
              });
            }}
            className="format-pedido-pilotado"
            key={center._id}
            style={{
              height: '3rem',
              borderBottom: i === tiendaArray.length - 1 ? 'none' : '1px solid #cecece',
              display: 'flex',
              alignItems: 'center',
              userSelect: 'none',
              gap: '.5rem',
              padding: '0 1.5rem',
              cursor: 'pointer',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <span>{center.code + ' - ' + (center?.name || center.calle)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  ));
};

export default SelectedContentTienda;
