import { Button, IconButton, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import { ShoppingCartContext } from '../../../context/ShoppingCartContext';

const CartItemUploadFile = ({ formato }) => {
  const { imagesShoppingCart, setImagesShoppingCart } = useContext(ShoppingCartContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const inputFileRef = useRef();

  useEffect(() => {
    console.log('VEAMOS LAS IMAGENES ASOCIADAS AL CARRITO: ', imagesShoppingCart);
  }, [imagesShoppingCart]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    let formData = new FormData();

    formData.append('image', file);
    formData.append('MAX_FILE_SIZE', '500000000'); // 500 MB

    const MAX_FILE_SIZE = '25000000'; // 25 MB
    formData.append('MAX_FILE_SIZE', MAX_FILE_SIZE);

    if (file.size > MAX_FILE_SIZE) {
      return toast.error(`Imagen demasiado grande. Límite ${Number(MAX_FILE_SIZE) / 1000000}MB`);
    }
    setSelectedFile(file);

    setImagesShoppingCart((prevState) => ({
      ...prevState,
      [formato.formatoId]: file,
    }));
  };

  const handleRemoveImage = () => {
    inputFileRef.current.value = null;
    setSelectedFile(null);

    let copyimagesShoppingCart = { ...imagesShoppingCart };
    delete copyimagesShoppingCart[formato.formatoId];
    setImagesShoppingCart(copyimagesShoppingCart);
  };

  return (
    <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
      <Button
        component="label"
        variant={selectedFile ? 'contained' : 'outlined'}
        color="error"
        startIcon={<CloudUploadIcon />}
      >
        {selectedFile ? selectedFile.name : 'Subir imagen'}
        <input type="file" className="button-upload-file" onChange={handleFileChange} ref={inputFileRef} />
      </Button>
      {selectedFile && (
        <Tooltip title="Eliminar imagen" placement="right">
          <IconButton onClick={handleRemoveImage}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default CartItemUploadFile;
