import { useEffect, useState } from 'react';
import { getAllProjects } from '../../../api/alcampoApi';
import { Link } from 'react-router-dom';
import { CircularProgress, Container, Grid, Popover, Tooltip } from '@mui/material';
import ProjectCard from '../../../Components2/Main/ProjectCard';
import useSortableData from '../../../hooks/useSortableData';
import { FilterList } from '@mui/icons-material';
// import TablePagination from "@material-ui/core/TablePagination";
import Stack from '@mui/material/Stack';
import { AddCircleOutline, ExpandLess, ExpandMore } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

const Main = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [page, setPage] = useState(1);
  const [limitPage, setlimitPage] = useState(0);
  const [itemsSorted, setItemsSorted] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);

  const { data, refetch, isFetching } = useQuery(['projects'], () => {
    return getAccessTokenSilently().then((token) => getAllProjects(token, page));
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (isFetching) return;

    setItemsSorted(() => {
      const sortableItems = data?.data?.data != null ? [...data.data.data] : [];

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }

      return sortableItems;
    });
  }, [data, isFetching, sortConfig]);

  //Sorting
  const [anchorEl, setAnchorEl] = useState(null);

  const openSort = (event) => {
    setAnchorEl(event.target);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const closeSort = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const [selectedSort, setSelectedSort] = useState({
    type: 'date',
    mode: 'descending',
  });

  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <>
      <Container style={{ marginTop: '3%' }}>
        <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Tooltip title={'Ordenar por:'} placement="top">
              <div
                aria-describedby={id}
                onClick={openSort}
                style={{
                  fontSize: '1.8rem',
                  color: 'gray',
                  margin: '4px',
                  cursor: 'pointer',

                  width: 'fit-content',
                }}
                // className="glyph-icon simple-icon-equalizer"
              >
                <FilterList />
              </div>
            </Tooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={closeSort}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: -7,
                horizontal: 90,
              }}
            >
              <div style={{ padding: '5px 8px' }}>
                Ordenar por:
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: 'lightgray',
                  }}
                />
              </div>
              <div
                style={{
                  fontSize: '1rem',
                  padding: '7px 12px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  requestSort('name');
                  setSelectedSort({
                    type: 'name',
                    mode: selectedSort.mode === 'descending' ? 'ascending' : 'descending',
                  });
                }}
              >
                {selectedSort.type === 'name' ? (
                  <div style={{ display: 'flex' }}>
                    <p
                      style={{
                        color: 'rgb(221, 46, 64)',
                        fontWeight: '800',
                        margin: '0',
                      }}
                    >
                      {' '}
                      Ordenar por nombre
                    </p>

                    {selectedSort.mode === 'descending' ? (
                      <ExpandMore
                        style={{
                          fontSize: '0.8rem',
                          marginLeft: '5px',
                          color: 'rgb(221, 46, 64)',
                          fontWeight: '800',
                        }}
                      />
                    ) : (
                      <ExpandLess
                        style={{
                          fontSize: '0.8rem',
                          marginLeft: '5px',
                          color: 'rgb(221, 46, 64)',
                          fontWeight: '800',
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <p style={{ margin: '0' }}> Ordenar por nombre</p>
                )}
              </div>

              <div
                style={{
                  fontSize: '1rem',
                  padding: '7px 12px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  requestSort('updatedAt');
                  setSelectedSort({
                    type: 'date',
                    mode: selectedSort.mode === 'descending' ? 'ascending' : 'descending',
                  });
                }}
              >
                {selectedSort.type === 'date' ? (
                  <div style={{ display: 'flex' }}>
                    <p
                      style={{
                        color: 'rgb(221, 46, 64)',
                        fontWeight: '800',
                        margin: '0',
                      }}
                    >
                      {' '}
                      Ordenar por fecha
                    </p>
                    {selectedSort.mode === 'descending' ? (
                      <ExpandMore
                        style={{
                          fontSize: '0.8rem',
                          marginLeft: '5px',
                          color: 'rgb(221, 46, 64)',
                          fontWeight: '800',
                        }}
                      />
                    ) : (
                      <ExpandLess
                        style={{
                          fontSize: '0.8rem',
                          marginLeft: '5px',
                          color: 'rgb(221, 46, 64)',
                          fontWeight: '800',
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <p style={{ margin: '0' }}> Ordenar por fecha</p>
                )}
              </div>
            </Popover>
          </div>
          <Link
            to="/add-project"
            style={{
              backgroundColor: '#2E2E2E',
              marginBottom: '5px',
              borderRadius: '5px',
              color: 'white',
              padding: '10px',
              border: 'none',
              fontSize: '14px',
              cursor: 'pointer',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            Añadir proyecto <AddCircleOutline />
          </Link>
        </div>

        <Grid container spacing={2} style={{ marginTop: '2px' }}>
          {isFetching ? (
            <div
              style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>{' '}
            </div>
          ) : data?.data?.data ? (
            itemsSorted.map((proj, index) => {
              return (
                <Grid item xs={4} key={'proj-card' + index}>
                  <ProjectCard data={proj} />
                </Grid>
              );
            })
          ) : (
            <div>No hay proyectos</div>
          )}
        </Grid>

        {data?.data?.data && data?.total > 3 ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Stack spacing={2}>
              <Pagination count={limitPage} page={page} onChange={handleChangePage} />
            </Stack>
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default Main;
