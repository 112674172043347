import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export const CenterOptions = ({ data, autocompleteValue, setAutocompleteValue }) => {
  return (
    <Autocomplete
      fullWidth
      id="tags-standard"
      options={data}
      getOptionLabel={(option) => `${option.code} - ${option?.name || option.calle}`}
      value={autocompleteValue}
      onChange={(event, newValue) => {
        setAutocompleteValue(newValue);
      }}
      noOptionsText="No existen centros con ese nombre o código"
      renderInput={(params) => (
        <TextField
          {...params}
          // variant="standard"
          label="Filtra por código o nombre"
          sx={{ '& label.Mui-focused': { color: 'black' } }}
          placeholder="Ej: ES0001, Utebo.. "
        />
      )}
    />
  );
};
