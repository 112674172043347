import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

export const CenterOptions1 = ({ data, autocompleteValue, setAutocompleteValue,label,ejemplo,optionLabel,handleChange }) => {
  return (
    <Autocomplete
    id="tags-standard"
      options={data?.data}
      getOptionLabel={optionLabel}
      value={autocompleteValue?._id}
      onChange={(event, newValue) => {
        handleChange()
        console.log('newValue',newValue)
        if(newValue != null){
          setAutocompleteValue(newValue._id);

        }else{
          setAutocompleteValue(newValue);

        }
      }}
      noOptionsText="No existen centros con ese nombre o código"
      renderInput={(params) => (
        <TextField
          {...params}
          // variant="standard"
          label={label}
          sx={{ '& label.Mui-focused': { color: 'black' } }}
          placeholder={ejemplo}
        />
      )}
    />
  );
};