import axios from 'axios';

/* -------------------------------------------------------------------------- */
/*                                    CATEGORY                                */
/* -------------------------------------------------------------------------- */

export const getAllCategories = async (token, params) => {
  try {
    const { data } = await axios.get('/api/v1/category', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        idParentCategory: params.idParentCategory,
        validateStatus: params.validateStatus,
        statusSelected: params.statusSelected,
        idCenterType: params.idCenterType,
        centerSelected: params.centerSelected,
        inputValueSearch: params.inputValueSearch,
        panelMaster: params.panelMaster,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCategoriesToMaster = async (
  token,
  id,
  idCenterType,
  comunidad,
  imgOld,
  imgNew,
  inputValueSearch,
) => {
  try {
    const { data } = await axios.get('/api/v1/category/master', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        parentCategory: id,
        idCenterType,
        comunidad,
        imgOld,
        imgNew,
        inputValueSearch,
      },
    });
    return data.data;
  } catch (error) {
    console.log('Error en get categories', error);
  }
};

export const getAllCategoriesWithoutFilters = async (token) => {
  try {
    const { data } = await axios.get('/api/v1/category/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error(error);
  }
};

/* -------------------------------------------------------------------------- */
/*                                    FORMATS                                */
/* -------------------------------------------------------------------------- */

// export const getAllFormats = async (token, id) => {
export const getAllFormats = async (token) => {
  try {
    const { data } = await axios.get('/api/v1/format', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // params: {
      //   category: id,
      // },
    });
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const postFormat = async (bodyFormat, bodyProvider, aaffSelected, required, compuestoValue) => {
  try {
    if (bodyFormat.name === '') {
      bodyFormat.name = 'Sin definir';
    }
    if (compuestoValue) {
      bodyFormat.childrenFormat = {
        format: aaffSelected.flatMap((aaff) =>
          aaff.formats.map((format) => ({
            id: format._id,
            quantity: required ? format.quantity : 1,
          })),
        ),
        required,
      };
    }

    const { data } = await axios.post('/api/v1/format', bodyFormat);

    bodyProvider = {
      ...bodyProvider,
      price:
        typeof bodyProvider.price === 'number' ? bodyProvider.price : parseFloat(bodyProvider.price.replace(',', '.')),
      format: data.data._id,
    };

    await axios.post('/api/v1/providerformat', bodyProvider);
  } catch (error) {
    throw new Error(error);
  }
};

export const oneFormatWtithArteFinal = async (token, id) => {
  try {
    const { data } = await axios.get(`/api/v1/format/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log('ERROR ONE FORMAT WITH ARTE FINAL', error);
  }
};

export const updateOneFormat = async (token, id, body, aaffSelected, required, compuestoValue) => {
  try {
    if (compuestoValue) {
      body.childrenFormat = {
        format: aaffSelected.flatMap((aaff) =>
          aaff.formats.map((format) => ({
            id: format._id,
            quantity: required ? format.quantity : 1,
          })),
        ),
        required,
      };
    } else {
      body.childrenFormat = null;
    }
    const { data } = await axios.patch(`/api/v1/format/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const deleteOneFormatProvider = async (token, id) => {
  try {
    await axios.delete(`/api/v1/providerformat/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const deleteOneFormat = async (id) => {
  try {
    await axios.delete(`/api/v1/format/${id}`);
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

/* -------------------------------------------------------------------------- */
/*                                    ARTE FINAL                              */
/* -------------------------------------------------------------------------- */

export const getAllArtesFinales = async (params) => {
  try {
    const { data } = await axios.get('/api/v1/arteFinal', {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
      params: {
        page: params.page,
        limit: params.limit,
        validateAAFF: params.validateAAFF,
        activeAAFF: params.activeAAFF,
        idCategory: params.superIdCategory,
        idCenterType: params.centerTypeSelected,
        centerSelected: params.centerConfirmed,
        inputValueSearch: params.inputValueSearch,
        panelMaster: params.panelMaster,
      },
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createOrderAndOrderItems = async (token, data) => {
  if (!data) {
    return;
  }

  try {
    const { data: responseData } = await axios.post(
      '/api/v1/order/allordersitembyorder',
      data,
      // {
      //   order,
      //   orderItems,
      // },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return responseData;
  } catch (error) {
    console.log('Error al hacer el POST DEL CARRITO', error);
  }
};

export const getAllArtesFinalesToMaster = async (
  token,
  inputValueSearch,
  page,
  limit,
  idCategory,
  center,
  centerType,
) => {
  try {
    const { data } = await axios.get('/api/v1/arteFinal/allartestomaster', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        inputValueSearch,
        page,
        limit,
        idCategory,
        center,
        centerType,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const oneArteFinalMaster = async (token, id) => {
  try {
    const { data } = await axios.get(`/api/v1/arteFinal/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log('ERROR ONE ARTE FINAL MASTER', error);
  }
};

export const updatePropertiesArteFinalMaster = async (token, id, body) => {
  const data = await axios.patch(`/api/v1/arteFinal/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const updateArteFinalMaster = async (token, id, body) => {
  try {
    const { category, ...rest } = body;

    const data = await axios.patch(`/api/v1/arteFinal/${id}`, rest, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!category) return;
    // Actualizar las relaciones con las categorías seleccionadas
    // Lo primero es eliminar todas las relaciones antiguas del arte final
    await axios.delete(`/api/v1/artefinalcategory/${id}/deleteallbyartefinal`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Crear las nuevas relaciones con las categorías seleccionadas
    const categoryPromises = category.map((cat) =>
      axios
        .post(
          '/api/v1/artefinalcategory',
          { category: cat, arteFinal: id },
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .catch((error) => {
          console.error(`Error al crear relación con la categoría ${cat}:`, error);
        }),
    );

    await Promise.all(categoryPromises);

    return data;
  } catch (error) {
    console.log('ERROR UPDATE ARTE FINAL MASTER', error);
  }
};

export const postArteFinal = async (params) => {
  try {
    const { bodyArteFinal, bodyFormat, bodyProviderFormat } = params;

    console.log('bodyArteFinal', bodyArteFinal);
    console.log('bodyFormat', bodyFormat);
    console.log('bodyProviderFormat', bodyProviderFormat);

    const { data } = await axios.post(
      '/api/v1/arteFinal',
      { bodyArteFinal, bodyFormat, bodyProviderFormat },
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      },
    );
    return data.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postArteFinalImprimible = async (params) => {
  try {
    const { data } = await axios.post(`/api/v1/arteFinal/imprimible`, params.body, {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    });

    return data.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const createPersonalizable = async (token, body) => {
  try {
    const data = await axios.post('/api/v1/format/personalizable', body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const postFormatMaster = async (token, body) => {
  try {
    const data = await axios.post(`/api/v1/format`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const modifiedIsDeleteArteFinalMaster = async (token, id, body) => {
  try {
    const data = await axios.patch(`/api/v1/arteFinal/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log('ERROR MODIFIED IS DELETE ARTE FINAL MASTER', error);
  }
};

export const deleteArteFinalMaster = async (token, id) => {
  try {
    const data = await axios.delete(`/api/v1/arteFinal/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // return data;
  } catch (error) {
    console.log('ERROR DELETE ARTE FINAL MASTER', error);
  }
};

export const getMultiplesImgAAFFDo = async (token, codesAAFF) => {
  try {
    const { data } = await axios.post(
      `/api/v1/do/multipleimg`,
      codesAAFF.map((code) => code.code),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          folder: 'aaffimg2',
        },
      },
    );

    const handleData = data.map((item, i) => {
      if (item == null) {
        return {
          img: null,
          id: codesAAFF[i].id,
        };
      } else {
        return {
          img: item.imageUrl,
          id: codesAAFF[i].id,
        };
      }
    });

    return handleData;
  } catch (e) {
    console.error(e);
  }
};

export const getAllImgAAFFDo = async (token, codesAAFF) => {
  try {
    const promises = codesAAFF.map((aaFF) =>
      axios.get(`/api/v1/do/getimg/${aaFF.code}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          folder: 'aaffimg2',
        },
      }),
    );

    const data = await Promise.all(promises);
    const handleData = data.map((item, i) => {
      if (item.status == '204') {
        return {
          img: null,
          id: codesAAFF[i].id,
        };
      } else {
        return {
          img: item.data.imageUrl,
          id: codesAAFF[i].id,
        };
      }
    });
    return handleData;
  } catch (e) {
    console.error('Hubo un error al traer las imágenes');
  }
};

export const uploadImageArteFinalToDo = async (id, formData, token) => {
  try {
    const data = await axios.post(`/api/v1/artefinal/updateimage/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      params: {
        id,
      },
    });
    return data;
  } catch (error) {
    console.error('Error al subir la imagen: ', error);
    throw new Error(error);
  }
};

export const getImageArteFinalDo = async (token, url) => {
  try {
    const { data } = await axios.get(`/api/v1/do/getimg`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        url,
      },
    });

    return data;
  } catch (err) {
    // si el status es 404 devolvemos null para que no se muestre la imagen
    if (err.response.status === 404) {
      return null;
    }
  }
};

export const getImageArteFinal = async (token, id) => {
  try {
    const { data } = await axios.get(`/api/v1/artefinal/${id}/image`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data?.data;
  } catch (error) {
    console.log('error', error);
  }
};

/* -------------------------------------------------------------------------- */
/*                                    ORDER                                   */
/* -------------------------------------------------------------------------- */
export const getAllOrders = async (token, page, limit, filter) => {
  try {
    // Crear una cadena de parámetros condicionalmente
    let queryParams = `page=${page}&limit=${limit}`;

    if (filter.length > 0) {
      const statuses = filter.map((status) => `status=${status}`).join('&');
      queryParams += `&${statuses}`;
    }

    // Realizar la solicitud GET con los parámetros
    const { data } = await axios.get(`/api/v1/order?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getAllOrdersAdmin = async (token, page, limit, filter) => {
  // *esto es para el admin
  try {
    const { data } = await axios.get('/api/v1/order', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
        filter,
      },
    });

    return data;
  } catch (error) {
    console.error('ERROR ALL ORDERS', error);
  }
};

export const deleteOrder = async (token, id) => {
  try {
    const { veredicto } = await axios.delete(`/api/v1/order/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return veredicto;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderOne = async (token, id) => {
  try {
    //obtenemos el order junto a sus orderItems con el id
    const { data } = await axios.get(`/api/v1/order/${id}/orderwithitems`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const createOrder = async (token, order) => {
  try {
    const { data } = await axios.post('/api/v1/order', order, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (e) {
    console.error(e);
  }
};

export const sendEmailStatusOrder = async (token, id) => {
  try {
    await axios.post(
      `/api/v1/order/sendemailorderstatus/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  } catch (e) {
    console.error(e);
  }
};

/* -------------------------------------------------------------------------- */
/*                                    Item Order                              */
/* -------------------------------------------------------------------------- */
export const editOrderAmount = async (token, itemChoose) => {
  // Utilizar Promise.all para manejar múltiples llamadas asincrónicas
  const updatePromises = itemChoose.map(async (item) => {
    try {
      const id = item.id;
      const count = item.count;

      // Obtener el pedido actual
      const { data } = await axios.get(`/api/v1/orderitem/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Actualizar la cantidad y la fecha updatedAt
      data.data.amount = count;
      data.data.updatedAt = new Date().toISOString(); // Asignar la fecha actual

      // Realizar la actualización usando axios.patch
      const update = await axios.patch(
        `/api/v1/orderitem/${id}`,
        {
          quantity: data.data.amount,
          updatedAt: data.data.updatedAt,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      // Devolver la respuesta de la actualización
      return update.data;
    } catch (error) {
      console.error(error);
      // Manejar errores si es necesario
    }
  });

  // Esperar a que todas las actualizaciones se completen
  const updates = await Promise.all(updatePromises);

  // Puedes manejar las respuestas de actualización aquí
  return updates;
};
export const editOrder = async (token, id, order) => {
  const update = await axios.patch(
    `/api/v1/order/${id}`,
    {
      comment: order.comment,
      status: order.status,
      deliverableDate: order.deliverableDate,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const deleteItem = async (token, id) => {
  try {
    const delit = await axios.delete(`/api/v1/orderitem/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return delit;
  } catch (err) {
    console.error(err);
  }
};

export const getImagesAccordingToFormat = async (token, code) => {
  try {
    const { data } = await axios.get('/api/v1/orderitem/orderitemswithimg', {
      params: {
        code,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createOrderItem = async (token, item) => {
  // *  Comprobar si tiene imagen.
  const hasImage = item.hasOwnProperty('image');
  // * Si no tiene, llamar al endpoint normal
  if (!hasImage) {
    await axios.post('/api/v1/orderitem', item, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    // * Si tiene, llamar al endpoint con imagen
    const { image, ...itemWithoutImage } = item;

    // lo primero subir la imagen a digital ocean y despues guardar la key y url en base de datos

    const imgName = `${itemWithoutImage.format}-${image.name}`;

    const res = await uploadFileDigitalOcean(image, `userimg/${imgName}`);

    // cogemos la Key y la url que nos devuelve la subida de la imagen

    const body = {
      ...itemWithoutImage,
      image: {
        key: res.key,
        link: res.url,
      },
    };

    await axios.post('/api/v1/orderitem', body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

/* -------------------------------------------------------------------------- */
/*                                    CENTER TYPE                             */
/* -------------------------------------------------------------------------- */

export const getAllCenterTypes = async (token, params = {}) => {
  try {
    const { data } = await axios.get(`/api/v1/centerType`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (error) {
    throw new Error(error);
    throw new Error(error);
  }
};

export const createCenterType = async (token, center) => {
  try {
    await axios.post('/api/v1/centertype', center, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const checkCenterTypeStatusByCenter = async (token, centerTypeId) => {
  try {
    const { data } = await axios.get(`/api/v1/centerType/checkstatus/${centerTypeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log('ERROR CHECK CENTER TYPE STATUS BY CENTER', error);
  }
};

export const checkCentersStatusByCluster = async (token, centerTypeId) => {
  try {
    const { data } = await axios.get('/api/v1/center/checkcentersstatus', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        centerTypeId,
      },
    });
    return data;
  } catch (error) {
    console.log('ERROR CHECK CENTERS STATUS BY CLUSTER', error);
  }
};

export const editCluster = async (token, center, centerId) => {
  const { data } = await axios.patch(`/api/v1/centertype/${centerId}`, center, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const getOneCluster = async (token, id) => {
  try {
    const { data } = await axios.get(`/api/v1/centertype/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log('ERROR ONE CLUSTER', error);
  }
};

export const getPaginateClusters = async (token, filters) => {
  try {
    const { data } = await axios.get('/api/v1/centertype/paginate', {
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

/* -------------------------------------------------------------------------- */
/*                                    CENTER                                  */
/* -------------------------------------------------------------------------- */
export const createCenter = async (token, center) => {
  const { data } = await axios.post('/api/v1/center/paginate', center, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const getOneCenter = async (token, id) => {
  try {
    const { data } = await axios.get(`/api/v1/center/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log('ERROR ONE CENTER', error);
  }
};

export const getPaginateCenters = async (token, filters) => {
  try {
    const { data } = await axios.get('/api/v1/center/paginate', {
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCenters = async (token) => {
  try {
    const { data } = await axios.get('/api/v1/center/allcenters', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCenterByMerchandising = async (token, idCenter) => {
  try {
    const { data } = await axios.get('/api/v1/center/merchandising', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        centerId: idCenter,
      },
    });
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const editCenter = async (token, center, centerId) => {
  const { data } = await axios.patch(`/api/v1/center/${centerId}`, center, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

/* -------------------------------------------------------------------------- */
/*                                MERCHANDISING                               */
/* -------------------------------------------------------------------------- */
export const getAllMerchandising = async (token) => {
  try {
    const { data } = await axios.get('/api/v1/merchandising', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

/* -------------------------------------------------------------------------- */
/*                                    DRIVE                                   */
/* -------------------------------------------------------------------------- */
export const uploadImages = async (formData) => {
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //   },
  // }
  // fetch('/api/v1/drive/uploadimage', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': undefined,
  //   },
  //   body: formData,
  // });

  await axios
    .post('/api/v1/drive/uploadimage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {})
    .catch((error) => {
      console.error('Error al enviar la imagen:', error);
    });
};

/* -------------------------------------------------------------------------- */
/*                                   STATUS                                   */
/* -------------------------------------------------------------------------- */

export const getAllStatus = async (token) => {
  try {
    const { data } = await axios.get('/api/v1/status', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    console.log('Error al traer todos los estados: ', error);
  }
};

/* -------------------------------------------------------------------------- */
/*                                   GET ALL FILTERS BY ORDERS                */
/* -------------------------------------------------------------------------- */

export const getFilterCentersUser = async (token) => {
  try {
    const centers = await axios.get('/api/v1/user/me/centers', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return centers.data.data.map((center) => ({
      value: center._id,
      label: `${center.code} - ${center?.name || center.calle}`,
    }));
  } catch (err) {
    console.error('Error al traer los centros para el filtro de solicitudes en user: ', err);
  }
};

export const getFilterCentersAdmin = async (token) => {
  try {
    const centers = await axios.get('/api/v1/center', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return centers.data.data.map((center) => ({
      value: center._id,
      label: `${center.code} - ${center?.name || center.calle}`,
    }));
  } catch (err) {
    console.error('Error al traer los centros para el filtro de solicitudes en admin: ', err);
  }
};

export const getFilterStatus = async (token) => {
  try {
    const status = await axios.get('/api/v1/status', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return status.data.data.map((status) => ({ value: status._id, label: status.name, color: status.color }));
  } catch (err) {
    console.log('Error al traer todos los filtros para los pedidos: ', err);
  }
};

/* -------------------------------------------------------------------------- */

export const createOrderComment = async (token, boddy) => {
  try {
    const { data } = await axios.post('/api/v1/comment', boddy, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    console.log('Error al crear el comentario: ', err);
  }
};

export const getOrderComments = async (token, id) => {
  try {
    const { data } = await axios.get(`/api/v1/comment/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    console.log('Error al traer los comentarios: ', err);
  }
};

export const updateOrder = async (token, id, body) => {
  try {
    const promise = [];

    if (body.status) {
      promise.push(
        axios.post(
          `/api/v1/activity`,
          {
            statusId: body.status,
            orderId: id,
            admin: {
              id: body.user.id,
              name: body.user.name,
              email: body.user.email,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      );
    }

    if (body.deliverableDate) {
      promise.push(
        axios.patch(
          `/api/v1/order/${id}`,
          {
            deliverableDate: body.deliverableDate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      );
    }

    if (Object.keys(body.orderItems).length > 0) {
      // AQUI TENEMOS QUE HACER UN PROMISE ALL PARA ACTUALIZAR TODOS LOS ITEMS DEL PEDIDO
      const orderItems = body.orderItems;
      promise.push(
        await Promise.all(
          Object.keys(orderItems).map(async (key) => {
            const quantity = orderItems[key].quantity;

            // TODO: Si la quantity es 0, no hay que actualizar el orderItem, hay que eliminarlo

            if (quantity === 0) {
              await axios.delete(`/api/v1/orderitem/${key}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            } else {
              // TODO: Si es un compuesto hay que actualizar tambien la quantity de los formatChildren
              await axios.patch(
                `/api/v1/orderitem/${key}`,
                {
                  quantity,
                  // actualizamos todo el array de formatChildren con la quantity si existe
                  formatChildren:
                    orderItems[key]?.formatChildren?.length > 0
                      ? orderItems[key].formatChildren.map((child) => ({
                          id: child.id,
                          quantity: child.quantity,
                        }))
                      : [],
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              );
            }
          }),
        ),
      );
    }

    await Promise.all(promise);

    return 'success';
  } catch (err) {
    console.log('Error al actualizar el pedido: ', err);
  }
};

export const getOrderItemDetail = async (token, id) => {
  try {
    const { data } = await axios.get(`/api/v1/orderitem/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // traemos la imagen del arte final
    // const image = await getImageArteFinalDo(token, data.data[0].arteFinalCode, 'aaffimg2');

    return { ...data };
  } catch (err) {
    console.log('Error al traer el detalle del order item: ', err);
  }
};

export const getOrders = async (token, page, limit, filter, userId) => {
  try {
    const { data } = await axios.get(`/api/v1/order`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
        filter,
        userId,
      },
    });

    return data;
  } catch (err) {
    console.log('Error al traer los pedidos del usuario: ', err);
  }
};

export const getAllCentersMaster = async (token, centerType) => {
  try {
    const { data } = await axios.get('/api/v1/center/master', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        centerType,
      },
    });
    return data;
  } catch (err) {
    console.log('Error al traer todos los centers: ', err.message);
  }
};

export const getAllCenterTypesMaster = async (token) => {
  try {
    const { data } = await axios.get('/api/v1/centerType', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    console.log('Error al traer todos los center types: ', err.message);
  }
};

export const uploadArteFinalImg = async (token, body, code) => {
  try {
    const data = axios.post(`/api/v1/artefinal/${code}/image`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    console.log('Error al subir la imagen: ', err.message);
  }
};

export const uploadFileDigitalOcean = async (file, nameImg) => {
  let formData = new FormData();
  let name = '?file_name=' + nameImg;
  let setAcl = 'public-read';
  let acl = '&acl=' + setAcl;
  let type = '&content_type=' + file.type;

  const url = '/api/v1/do/upload' + name + acl + type;

  formData.append('image', file);
  formData.append('MAX_FILE_SIZE', '20000000'); // 20MB

  try {
    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return res.data;
  } catch (error) {
    console.log('Error al subir el archivo: ', error);
  }
};

/* -------------------------------------------------------------------------- */
/*                           COSTE INSTALACION ORDER                          */
/* -------------------------------------------------------------------------- */

export const createCosteInstalacionOrder = async (token, body) => {
  try {
    const res = await axios.post('/api/v1/costeinstalacionorder/multiple', body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.error('Error al crear el coste de instalación: ', error);
  }
};

export const upadeCosteInstalacionOrder = async (token, id, cantidad) => {
  try {
    const res = await axios.patch(
      `/api/v1/costeinstalacionorder/${id}`,
      { cantidad },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    console.error('Error al actualizar el coste de instalación: ', error);
  }
};

export const deleteCosteInstalacionOrder = async (token, id) => {
  try {
    const res = await axios.delete(`/api/v1/costeinstalacionorder/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.error('Error al eliminar el coste de instalación: ', error);
  }
};

export const getCostesInstalacion = async (token) => {
  try {
    const res = await axios.get(`/api/v1/costeinstalacion`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error('Error al traer los costes de instalación: ', error);
  }
};

export const createCosteInstalacionOrderSingle = async (token, body) => {
  try {
    const res = await axios.post('/api/v1/costeinstalacionorder', body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.error('Error al crear el coste de instalación: ', error);
  }
};

export const getCostesInstalacionOrder = async (token, orderId) => {
  try {
    const res = await axios.get(`/api/v1/costeinstalacionorder/${orderId}/byorder`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error('Error al traer los costes de instalación: ', error);
  }
};

export const getAllCostesInstalacion = async (token) => {
  try {
    const res = await axios.get(`/api/v1/costeinstalacion`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error('Error al traer los costes de instalación: ', error);
  }
};

export const getChildrenFormatsByOrderItem = async (token, id) => {
  try {
    const res = await axios.get(`/api/v1/orderitem/${id}/childrenformats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (error) {
    console.log('Error al traer los formatos hijos: ', error);
  }
};

/* -------------------------------------------------------------------------- */
/*                               PEDIDO PILOTADO                              */
/* -------------------------------------------------------------------------- */

export const getAllArtesFinalesPedidoPilotado = async (token, inputSearch, page) => {
  try {
    const res = await axios.get('/api/v1/arteFinal/pilotado', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page,
        limit: 36,
        inputValueSearch: inputSearch || '',
      },
    });

    return res.data;
  } catch (err) {
    console.error('Error al obtener todos los artes finales para el pedido pilotado', err);
  }
};

export const getAllTiendasPedidoPilotado = async (token, inputSearch, page) => {
  try {
    const res = await axios.get('/api/v1/center/pilotado', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page,
        limit: 36,
        inputValueSearch: inputSearch || '',
      },
    });

    return res.data;
  } catch (err) {
    console.error('Error al obtener todas las tiendas para el pedido pilotado', err);
  }
};

export const getPedidosPilotados = async (token, page, limit, filter) => {
  try {
    const res = await axios.get('/api/v1/order/pilotado', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
        filter,
      },
    });

    return res.data;
  } catch (err) {
    console.error('Error al obtener los pedidos pilotados ', err);
  }
};

export const getDataExcelPilotado = async (token, idPilotado) => {
  try {
    const res = await axios.get(`/api/v1/order/pilotado/${idPilotado}/excel`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (err) {
    console.error('Error al generar el excel del pedido pilotado', err);
  }
};

export const getDataPedidoPilotado = async (token, id) => {
  try {
    const res = await axios.get(`/api/v1/order/pilotado/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('res', res.data);

    return res.data;
  } catch (err) {
    console.error('Error al obtener el pedido pilotado', err);
  }
};

export const getSolicitudesPedidoPilotado = async (token, id, page, limit, filter) => {
  try {
    const res = await axios.get(`/api/v1/order/pilotado/${id}/orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
        filter,
      },
    });

    return res.data;
  } catch (err) {
    console.error('Error al obtener el pedido pilotado', err);
  }
};

/* -------------------------------------------------------------------------- */
/*                                 REGIONALES                                 */
/* -------------------------------------------------------------------------- */
export const getAllRegionales = async (token) => {
  try {
    const res = await axios.get('/api/v1/regional', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (err) {
    throw new Error(err);
    throw new Error(err);
  }
};

/* -------------------------------------------------------------------------- */
/*                                 MATERIALES                                 */
/* -------------------------------------------------------------------------- */
export const getAllMaterials = async (token) => {
  try {
    const res = await axios.get('/api/v1/material?sort=nombre', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};
