import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { getCurrentModelVersion, getDataByName, getUniqueDisplayValues } from '../helpers/viewerHelper';
import PartidaProyecto from '../helpers/Partida/PartidaProyecto';
import { ProyectoContext } from './ProyectoContext';
import {
  createQuantityVersion,
  getAllVersionsByItem,
  getPartidasProyectoNotMatchWithVersion,
  getVersionByProjectAndNumberVersion,
} from '../api/alcampoApi';
import { useHandleUrl } from '../hooks/useHandleUrl';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';

export const ViewerContext = createContext();

export const ViewerProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentProject, setCurrentVersion, isModelLoaded } = useContext(ProyectoContext);
  const [uniqueProperties, setUniqueProperties] = useState([]);
  const viewerRef = useRef(null);
  const { idItem } = useHandleUrl();
  const { data: versions } = useQuery(['versions', idItem], () => {
    return getAccessTokenSilently().then((token) => getAllVersionsByItem(token, idItem));
  });



  useEffect(() => {
    if (isModelLoaded && currentProject) {
      console.log('ViewerContext getVersion');
      // Cada vez que se carga un modelo, hay que comprobar la versión de ese modelo.
      // Si es la primera vez que se carga esa versión, hay que crear un documento en la colección "Versión"
      const currentVersion = getCurrentModelVersion();
      // Comprobar si existe la versión para ese modelo.
      getAccessTokenSilently().then((token) => {
        getVersionByProjectAndNumberVersion(currentProject._id, currentVersion, token)
          .then((res) => {
            // Si existe, se setea el currentVersion
            if (res.status == 200) {
              setCurrentVersion(res.data.data);
              // Comprobar que para todas las partidas proyecto existe una quantityVersion para la currentVersion
              // Traer todas las partidasProyecto por proyecto
              getPartidasProyectoNotMatchWithVersion(currentProject._id, res.data.data._id, token).then((res1) => {
                // Por cada partidaProyecto, calculamos la quantity y creamos la quantityVersion
                for (const item of res1) {
                  const partida = new PartidaProyecto(item.partida.code, item.partida.unit, item.partida.price);
                  partida.getTotalQuantity().then((res2) => {
                    // Crear quantityVersion
                    createQuantityVersion({ version: res.data.data._id, partidaProyecto: item._id, quantity: res2 });
                  });
                }
              });
            }

            // Error capturado de que no existe esa versión y, por tanto, hay que crearla
            if (res?.name == 'AxiosError' && res?.response.status == 404) {
              console.log('No existe la versión');
              // Crear version
              // createVersion(currentProject._id, currentVersion).then((res) => {
              //   setCurrentVersion(res);

              //   // Crear todas las quantityVersion para todas las partidasProyecto
              //   // Traer todas las partidasProyecto por proyecto
              //   getPartidasProyectoByProyectoId(currentProject._id).then((res1) => {
              //     // Por cada partidaProyecto, calculamos la quantity y creamos la quantityVersion
              //     for (const item of res1) {
              //       const partida = new PartidaProyecto(item.partida.code, item.partida.unit, item.partida.price);
              //       partida.getTotalQuantity().then((res2) => {
              //         // Crear quantityVersion
              //         createQuantityVersion({ version: res._id, partidaProyecto: item._id, quantity: res2 });
              //       });
              //     }
              //   });
              //   queryClient.invalidateQueries(['available-versions', currentProject._id]);
              // });
            }
          })
          .catch((e) => console.log('esto es un error', e));
      })


      // Si no existe, crearlo

      // Repasar todas las partidasProyectos y crear un quantityVersion con el quantity y la partidaProyecto

      getDataByName(['MED_QTO']).then((res) => {
        const uniqueDisplayValues = getUniqueDisplayValues(res);
      });
    }
  }, [isModelLoaded, currentProject]);

  const data = {
    viewerRef,
    versions,
    uniqueProperties,
    setUniqueProperties,
  };

  if (!currentProject) return null

  return <ViewerContext.Provider value={data}>{children}</ViewerContext.Provider>;
};
