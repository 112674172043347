import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedAuth0 = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  if (isLoading) {
    return;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  } else {
    getAccessTokenSilently().then((token) => console.log('token', token));
  }

  return <>{children}</>;
};

export default ProtectedAuth0;
