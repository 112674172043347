import { CardContent } from '@mui/material';
import React from 'react';
import CartItemImageArteFinal from '../Cart/CartItem/CartItemImageArteFinal';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import FavouriteSelected from './FavouriteSelected';

export const OneRowArte = ({ row, handleRowClick, centerTypes }) => {
  const [selectedIdsFavorite, setSelectedIdsFavorite] = useState([
    {
      idArte: row._id,
      centers: row.favourite.length > 0 ? row.favourite : [],
    },
  ]);

  const truncateName = (name, maxLength) => {
    if (name?.length > maxLength) {
      return name.slice(0, maxLength) + '...';
    }
    return name;
  };

  // Definir el nombre truncado y si necesita un tooltip
  const truncatedName = truncateName(row.nameArteFinal, 85);
  const needsTooltip = row.nameArteFinal.length > 85;

  return (
    <div>
      <div className="card-arte-final">
        <FavouriteSelected
          row={row}
          centerTypes={centerTypes}
          selectedIdsFavorite={selectedIdsFavorite}
          setSelectedIdsFavorite={setSelectedIdsFavorite}
        />

        <CartItemImageArteFinal
          onClick={() => handleRowClick(row)}
          styleContainer={{
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          styleImg={{
            cursor: 'pointer',
            objectFit: 'contain',
            height: '90%',
            width: '90%',
          }}
          img={row.imglink}
        />

        <CardContent
          sx={{ padding: '0.5rem !important', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <div style={{ textAlign: 'center', height: '80px' }}>
            <p
              className={`tooltip-artefinal-name-${row._id}`}
              style={{ marginBottom: '5px', fontWeight: 'bold' }}
              data-tip={needsTooltip ? row.nameArteFinal : ''}
            >
              {truncatedName.toUpperCase()}
            </p>
            <p style={{ fontStyle: 'italic' }}> {row.codeArteFinal} </p>
          </div>
        </CardContent>
      </div>
      {!row?.active && (
        <div
          onClick={() => handleRowClick(row)}
          style={{
            backgroundColor: '#c9c9c9',
            cursor: 'pointer',
            zIndex: '10',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: '0.5',
            borderRadius: '4px',
          }}
        />
      )}
      {needsTooltip && (
        <Tooltip style={{ zIndex: 1000 }} anchorSelect={`.tooltip-artefinal-name-${row._id}`}>
          {row.nameArteFinal.toUpperCase()}
        </Tooltip>
      )}
    </div>
  );
};
