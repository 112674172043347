import DownloadIcon from '@mui/icons-material/Download';
import CustomButton from '../../../ui/Button/CustomButton';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

const ButtonsImagesArteFinal = ({ name, png }) => {
  const { idArte } = useParams();
  const queryClient = useQueryClient();

  const { data } = queryClient.getQueryData(['info-arte-final', idArte]);

  const downloadImage = async () => {
    if (!png) return toast.error('No hay ninguna imagen subida.');
    try {
      const response = await fetch(png);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = name || 'Imagen'; // Nombre del archivo a descargar
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error('Error al descargar la imagen. Inténtalo otra vez');
      console.error('Error descargando la imagen:', error);
    }
  };

  return (
    <div className="buttons-images-arte-final-container ">
      <CustomButton classname="button-images-arte-final" onClick={downloadImage}>
        <DownloadIcon />
        PNG
      </CustomButton>
      <CustomButton
        classname="button-images-arte-final"
        disabled={!!!data.linkPdf}
        onClick={() => {
          window.open(data.linkPdf, '_blank');
        }}
      >
        <DownloadIcon />
        PDF
      </CustomButton>
      <CustomButton
        classname="button-images-arte-final"
        disabled={!!!data.linkEditable}
        onClick={() => {
          window.open(data.linkEditable, '_blank');
        }}
      >
        <DownloadIcon />
        Editable
      </CustomButton>
    </div>
  );
};

export default ButtonsImagesArteFinal;
