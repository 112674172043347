import { OneArteFinal } from './OneArteFinal';
import ChildrenFormatModal from './ChildrenFormatModal';
import { ArteFinalProvider_v2 } from '../../context/ArteFinalContext_v2';

export const ArteFinal = ({ artesFinales }) => {
  return (
    <>
      {artesFinales?.length > 0 ? (
        <div className="grid-container">
          {artesFinales.map((arteFinal) => (
            <div className="grid-item" key={arteFinal._id}>
              <ArteFinalProvider_v2 arteFinal={arteFinal}>
                <OneArteFinal arteFinal={arteFinal} />
                <ChildrenFormatModal />
              </ArteFinalProvider_v2>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            height: '60vh',
            width: '100%',
          }}
        >
          <h3>No hay resultados para tu búsqueda</h3>
        </div>
      )}
    </>
  );
};
