import { Modal, Tooltip } from '@mui/material';
import CustomButton from '../Button/CustomButton';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const SimpleModal = ({
  isModalOpen,
  setIsModalOpen,
  handleConfirm,
  title = 'Modal sin título',
  description = 'Sin descripción',
  tooltip,
  titleTooltip,
}) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'orange',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
      padding: '8px 12px',
    },
  }));

  return (
    <Modal
      open={isModalOpen}
      onClose={(e) => {
        e.stopPropagation();
        setIsModalOpen(false);
      }}
      className="modal-pdf-wrapper"
    >
      <div onClick={(e) => e.stopPropagation()} className="modal-pdf-container">
        <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
          <h1 style={{ padding: '0' }}>{title}</h1>
          {tooltip && (
            <BootstrapTooltip title={titleTooltip} placement="right-start">
              <IconButton className="alert-message">
                <ReportProblemIcon color="warning" />
              </IconButton>
            </BootstrapTooltip>
          )}
        </div>
        <p>{description}</p>

        <div className="modal-pdf-buttons-container">
          <CustomButton onClick={() => setIsModalOpen(false)} variant="secondary">
            No
          </CustomButton>
          <CustomButton disabled={tooltip} onClick={(e) => handleConfirm(e)}>
            Sí
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default SimpleModal;
