import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';
import { formatCurrency } from '../../../utils/formatNumber';

const ReusableLineChart = ({ data, dataKeys, width = 500, height = 300 }) => {
  const [keyLeft, keyRight] = Object.values(dataKeys); // Extraer dinámicamente las dos keys

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 60,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          angle={0}
          textAnchor="middle"
          interval={0}
          tickFormatter={(value) => (value.length > 20 ? `${value.slice(0, 20)}...` : value)}
        />
        <YAxis
          yAxisId="left"
          tickFormatter={(value) => `${formatCurrency(value, 0)}`}
          orientation="left"
          stroke="#e57373"
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="#b0bec5"
          tickFormatter={(value) => `${formatCurrency(value, 0)} €`}
        />
        <Tooltip content={<CustomTooltip dataKeys={dataKeys} />} />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey={keyLeft}
          stroke="#e57373"
          strokeWidth={2}
          strokeOpacity={0.8}
          connectNulls={true}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey={keyRight}
          stroke="#b0bec5"
          strokeWidth={2}
          strokeOpacity={0.8}
          connectNulls={true}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ReusableLineChart;
