import axios from 'axios';

export const updateMultipleOrdersItems = async (token, orders) => {
  try {
    const promiseOrderItems = Object.entries(orders).forEach(([clave, valor]) => {
      return axios.patch(
        `/api/v1/orderitem/${clave}`,
        { format: valor },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    });

    await Promise.all(promiseOrderItems);
  } catch (e) {
    throw new Error(e);
  }
};
