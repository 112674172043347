import { useNavigate } from 'react-router-dom';
import CartContainer_v2 from '../ComponentsCarteleria/Cart/CartContainer_v2';

const CartPage = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/carteleria');
  };

  return (
    <div style={{ padding: '1rem', height: '100%', overflowY: 'auto' }}>
      <div>
        <p
          style={{ cursor: 'pointer', fontSize: '1.2rem', textAlign: 'left' }}
          className="button-go-back-cart"
          onClick={goBack}
        >{`< Volver al catálogo`}</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ textAlign: 'center', fontSize: '2.5rem', marginBottom: '2rem' }}>Cesta</h1>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CartContainer_v2 />
      </div>
    </div>
  );
};

export default CartPage;
