import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomButton from '../../../../ui/Button/CustomButton';
import { Tooltip } from 'react-tooltip';

const UploadPdfToArteFinal = ({ handleUpload }) => {
  return (
    <>
      <CustomButton
        variant="success"
        classname="anchor-upload-imprimible button-pdf-upload-imprimibles"
        onClick={handleUpload}
      >
        <CloudUploadIcon classname="icon-check-pdf-upload-imprimibles" />
      </CustomButton>
      <Tooltip anchorSelect=".anchor-upload-imprimible">Subir pdf</Tooltip>
    </>
  );
};

export default UploadPdfToArteFinal;
