export const STATS = [
  {
    label: 'Artículos más pedidos',
    description:
      'En esta gráfica se muestran todos los artículos del catálogo que han sido más solicitados, junto con la cantidad de veces que han sido pedidos y el importe total gastado en cada uno de ellos',
    link: '/more-orders',
  },
  {
    label: 'Artículos sin pedidos',
    description: 'En esta tabla se muestran todos los artículos que no han sido pedidos',
    link: '/no-orders',
  },
  {
    label: 'Centros con más pedidos',
    description:
      'En esta gráfica se muestran los centros que han realizado más pedidos, junto con la cantidad de pedidos realizados y el importe total gastado en cada uno de ellos',
    link: '/more-centers',
  },
  {
    label: 'Artículos más pedidos por centro',
    description:
      'En esta gráfica se presentan los artículos más solicitados en cada centro, junto con la cantidad de pedidos realizados y el importe total gastado en cada uno de ellos. Para ver los datos, es necesario seleccionar un centro',
    link: '/more-articles-center',
  },
];
