import React, { useContext, useEffect } from 'react';
import TableCenters from './TableCenters';
import { useShowCenters } from '../GestionTiendas/useShowCenters';
import { useGetAllCenterTypes } from '../GestionTiendas/useGetAllCenterTypes';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Button,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { CenterOptions1 } from './CenterOptions1';
import { useNavigate, Outlet } from 'react-router-dom';
import useGetAllRegionales from '../GestionTiendas/useGetAllRegionals';
import { Buscador } from '../../Pedido/Buscador';
import { GestionCenterContext } from '../../../context/GestionCenterContext';
import { handleChangeInputSearch, handleClearInput, handleKeyDown } from '../../../helpers/buscadorHelper';

export default function GestionTienda() {
  const { inputAfter, inputValueSearch, setInputAfter, setInputValueSearch } = useContext(GestionCenterContext);
  const { allCenterTypes } = useGetAllCenterTypes();
  const navigate = useNavigate();
  const {
    centersPaginated,
    allCenters,
    isLoading,
    limit,
    page,
    setPage,
    filteredIds,
    setFilteredIds,
    centerType,
    setCenterType,
    setActiveFilter,
    setComunidadFilter,
    comunidadFilter,
    activeFilter,
  } = useShowCenters(false);

  const { regionals } = useGetAllRegionales();

  const handlePagePagination = (event, value = 1) => {
    setPage(value);
  };

  const handleChange = (event) => {
    setPage(() => 1);
    const {
      target: { value },
    } = event;
    setCenterType(typeof value === 'string' ? value.split(',') : value);
  };

  const handleActiveFilterChange = (event) => {
    setActiveFilter(event.target.value);
    setPage(1);
  };

  const handleComunidadFilterChange = (event) => {
    setComunidadFilter(event.target.value);
    setPage(1);
  };

  return (
    <>
      {/* --------------------------------------------------------------------------*/
      /*                                   HEADER                                   */
      /* -------------------------------------------------------------------------- */}
      <div className="container-master-user">
        <div className="grid-clusters-centers-container">
          <h2 className="font-size-1-resonsive">CENTROS</h2>
          <div className="grid-item-clusters-centers-search">
            <Buscador
              label={'Buscar por código, nombre o calle'}
              value={inputAfter}
              width="85"
              onChange={(e) => handleChangeInputSearch(e, setInputAfter)}
              onKeyDown={(e) => handleKeyDown(e, setInputValueSearch)}
              onClear={() => handleClearInput(setInputValueSearch, setInputAfter)}
            />
            <Button
              variant="contained"
              color="error"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => navigate('add-center')}
              aria-label="add"
              style={{ height: '2.5rem' }}
            >
              Crear Centro
            </Button>
          </div>
        </div>

        {/* -------------------------------------------------------------------------- */
        /*                                    BODY                                    */
        /* --------------------------------------------------------------------------*/}
        <div className="grid-clusters-centers-container">
          <div className="options options-filter">
            <h4 className="font-size-1-resonsive">Filtros</h4>

            {allCenters?.data?.length > 0 && (
              <CenterOptions1
                data={allCenters}
                handleChange={() => setPage(() => 1)}
                autocompleteValue={filteredIds}
                setAutocompleteValue={setFilteredIds}
                label="Busca por código, nombre o calle"
                ejemplo="Ej: 725 - C/Miraflores, 13-15"
                optionLabel={(option) => `${option.code} - ${option?.name || option.calle}`}
              />
            )}

            <FormControl sx={{ maxWidth: 300, marginBottom: 2 }}>
              <InputLabel size="small" id="centerType-label">
                Selecciona Cluster/s
              </InputLabel>
              <Select
                size="small"
                labelId="centerType-label"
                id="demo-multiple-checkbox"
                multiple
                value={centerType}
                onChange={handleChange}
                input={<OutlinedInput label="cluster" />}
                renderValue={(selected) => {
                  return selected
                    .map((selectedId) => {
                      const selectedType = allCenterTypes?.find((centerType) => centerType?._id === selectedId).type;
                      return selectedType;
                    })
                    .join(', ');
                }}
              >
                {allCenterTypes?.map((centerType1) => (
                  <MenuItem key={centerType1?._id} value={centerType1?._id}>
                    <Checkbox checked={centerType?.indexOf(centerType1?._id) > -1} />
                    <ListItemText primary={centerType1?.type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ maxWidth: 300, marginBottom: 2 }}>
              <InputLabel size="small" htmlFor="comunidadFilter">
                Filtrar por Comunidad
              </InputLabel>
              <Select
                size="small"
                id="comunidadFilter"
                value={comunidadFilter}
                onChange={handleComunidadFilterChange}
                input={<OutlinedInput label="Filtrar por Comunidad" />}
              >
                <MenuItem value="all">
                  <em>Todos</em>
                </MenuItem>
                {regionals?.data?.length > 0 &&
                  regionals?.data.map((regional) => (
                    <MenuItem key={regional?._id} value={regional?._id}>
                      {regional?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl sx={{ maxWidth: 300, marginBottom: 2 }}>
              <InputLabel size="small" htmlFor="activeFilter">
                Filtrar por Activo
              </InputLabel>
              <Select
                size="small"
                id="activeFilter"
                value={activeFilter}
                onChange={handleActiveFilterChange}
                input={<OutlinedInput label="Filtrar por Activo" />}
              >
                <MenuItem value="all">
                  <em>Todos</em>
                </MenuItem>
                <MenuItem value={true}>Activo</MenuItem>
                <MenuItem value={false}>No Activo</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="grid-item">
            {!isLoading ? (
              <div className="core">
                <TableCenters centers={centersPaginated} isLoading={isLoading} />
              </div>
            ) : (
              <div className="custom-loader"></div>
            )}
          </div>
        </div>
        {centersPaginated?.data?.length > 0 && (
          <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <Pagination
              showFirstButton
              showLastButton
              page={page}
              onChange={handlePagePagination}
              count={Math.ceil(centersPaginated?.pagination?.totalItems / limit)}
              color="error"
              variant="outlined"
              size="large"
              sx={{ margin: '2rem' }}
            />
          </div>
        )}
      </div>
      <Outlet></Outlet>
    </>
  );
}
