import { useContext, useState } from 'react';
import { TbLayoutSidebarRightCollapseFilled, TbLayoutBottombarCollapseFilled } from 'react-icons/tb';
import { GestionCategoryContext } from '../../../context/GestionCategoryContext';

const CategoryTree = ({ categories }) => {
  const [openCategories, setOpenCategories] = useState({});
  const { categoryMoveSelected, setCategoryMoveSelected } = useContext(GestionCategoryContext);

  const handleClick = (category, hasChildren) => {
    console.log('category', category);
    console.log('hasChildren', hasChildren);
    if (!hasChildren) {
      if (categoryMoveSelected?.id === category?.id) {
        setCategoryMoveSelected(null); // Deselecciona si el mismo valor se selecciona nuevamente
      } else {
        setCategoryMoveSelected(category);
      }
    } else {
      let categoryId = category.id;
      setOpenCategories((prevOpenCategories) => ({
        ...prevOpenCategories,
        [categoryId]: !prevOpenCategories[categoryId],
      }));
    }
  };

  return categories.map((category) => (
    <div className="gestion-container-category-subcategories" key={category._id}>
      <div className="gestion-categoria-item-icons">
        <div
          onClick={() => handleClick({ name: category.name, id: category._id }, category.children.length > 0)}
          className="gestion-categoria-item"
        >
          {category.children.length > 0 ? (
            openCategories[category._id] ? (
              <TbLayoutBottombarCollapseFilled />
            ) : (
              <TbLayoutSidebarRightCollapseFilled />
            )
          ) : (
            <input type="radio" checked={categoryMoveSelected?.id === category._id} readOnly />
          )}
          {category.name}
        </div>
      </div>
      {category.children && openCategories[category._id] && (
        <div className="gestion-subcategories">
          <CategoryTree categories={category.children} />
        </div>
      )}
    </div>
  ));
};

export default CategoryTree;
