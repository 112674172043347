import { useState } from 'react';
import { useCreateMerchandising } from '../../../lib/react-query/fileQuery';
import FormFile from './FormFile';
import { useHandleCloseModal } from '../../../hooks/useHelpers';

const AddMerchandising = () => {
  const { mutation } = useCreateMerchandising();
  const { handleCloseModal } = useHandleCloseModal(1);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    url: '',
    file: null,
    type: 'link',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(formData);
  };

  return (
    <FormFile
      title="Crear guía de merchandising"
      handleSubmit={handleSubmit}
      formData={formData}
      handleChange={handleChange}
      handleCloseModal={handleCloseModal}
    />
  );
};

export default AddMerchandising;
