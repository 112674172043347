import { useAuth0 } from '@auth0/auth0-react';
import Selector from '../common/Selector';
import { useQuery } from '@tanstack/react-query';
import { getFilterStatus } from '../../api/carteleriaApi';

const SelectorStatus = ({ status, setStatus, data }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data: statusFilter, isLoading } = useQuery(['filter-status'], async () => {
    const token = await getAccessTokenSilently();
    return await getFilterStatus(token);
  });

  const handleChangeStatus = (v) => {
    // si el valor es el mismo que viene de base de datos volvemos a setear el estado a null
    if (v.label === data.data.order[0].status) {
      setStatus(null);
      return;
    } else {
      setStatus(v);
    }
  };

  return (
    <Selector
      loading={isLoading}
      bgColor={status?.color || data.data.order[0].colorStatus}
      value={status?.label || data.data.order[0].status}
      setValue={(v) => handleChangeStatus(v)}
      options={statusFilter}
    />
  );
};

export default SelectorStatus;
