const FunctionIconsCategory = ({ icons }) => {
  return (
    <div className="gestion-container-icons">
      {icons.map((icon, index) => (
        <div key={index}>
          {icon.icon}
          {icon.tooltip}
        </div>
      ))}
    </div>
  );
};

export default FunctionIconsCategory;
