import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useState } from 'react';
import '../../../css/admin.css';
import '../../../css/asociateTable.css';
import { OrdersFilterAdminContext } from '../../../context/OrdersFilterAdminContext';
import { useQuery } from '@tanstack/react-query';
import { getOrders } from '../../../api/carteleriaApi';
import TabStructure from './TabStructure';

const TabOrders = () => {
  const {
    statusFilter,
    centersFilter,
    createStartDateFilter,
    createEndDateFilter,
    deliveryStartDateFilter,
    deliveryEndDateFilter,
    orderFilter,
  } = useContext(OrdersFilterAdminContext);
  const { getAccessTokenSilently } = useAuth0();

  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      'orders-admin',
      {
        page,
        statusFilter,
        centersFilter,
        createStartDateFilter,
        createEndDateFilter,
        deliveryStartDateFilter,
        deliveryEndDateFilter,
      },
    ],
    async () => {
      const token = await getAccessTokenSilently();
      return await getOrders(token, page, 10, {
        order: orderFilter,
        estado: statusFilter,
        centro: centersFilter,
        createStartDate: createStartDateFilter,
        createEndDate: createEndDateFilter,
        deliveryStartDate: deliveryStartDateFilter,
        deliveryEndDate: deliveryEndDateFilter,
      });
    },
  );

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [
    statusFilter,
    centersFilter,
    createStartDateFilter,
    createEndDateFilter,
    deliveryStartDateFilter,
    deliveryEndDateFilter,
  ]);

  useEffect(() => {
    refetch();
  }, [orderFilter]);

  return (
    <TabStructure
      title="Sistema de solicitudes"
      context={OrdersFilterAdminContext}
      isLoading={isLoading}
      isFetching={isFetching}
      limitPerPage={Math.ceil(data?.total / 10) || 0}
      page={page}
      setPage={setPage}
      data={data}
    />
  );
};

export default TabOrders;
