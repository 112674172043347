import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { PilotadosContext } from '../../../context/PilotadosContext';
import { getFilterCentersAdmin } from '../../../api/carteleriaApi';
import OrderFilter from '../../common/OrderFilter';
import OrderDateFilter from '../../common/OrderDateFilter';

export const FilterPilotadosAdmin = () => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    centersFilter,
    setCentersFilter,
    createStartDateFilter,
    setCreateStartDateFilter,
    createEndDateFilter,
    setCreateEndDateFilter,
  } = useContext(PilotadosContext);

  const [searchValue, setSearchValue] = useState('');
  const [filteredCenters, setFilteredCenters] = useState([]);
  const [openMenu, setOpenMenu] = useState(null); // Estado para rastrear cuál menú está abierto

  const { data: centers, isLoading: loadingCenters } = useQuery(['filter-centers'], async () => {
    const token = await getAccessTokenSilently();
    return await getFilterCentersAdmin(token);
  });

  useEffect(() => {
    const normalizeString = (str) => {
      return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
    };

    if (centers) {
      if (searchValue.length >= 3) {
        const normalizedSearchValue = normalizeString(searchValue);
        const filtered = centers.filter((center) => normalizeString(center.label).includes(normalizedSearchValue));
        setFilteredCenters(filtered);
      } else {
        setFilteredCenters(centers);
      }
    }
  }, [searchValue, centers]);

  const handleMenuOpen = (menuName) => {
    setOpenMenu(menuName);
  };

  return (
    <div className="options options-filter">
      <h4 style={{ fontWeight: 'bold' }}>Filtros</h4>
      <>
        {/* FILTRO DE CENTROS */}
        <OrderFilter
          search={true}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          loading={loadingCenters}
          filterName="Centro"
          filterList={filteredCenters}
          filterValues={centersFilter}
          setFilterValues={setCentersFilter}
          openMenu={openMenu}
          handleMenuOpen={handleMenuOpen}
          menuName="Centro" // Nombre único para el menú
        />

        {/* FILTRO DE FECHA DE CREACION */}
        <OrderDateFilter
          filterName="Fecha de creación"
          startDate={createStartDateFilter}
          setStartDate={setCreateStartDateFilter}
          finishDate={createEndDateFilter}
          setFinishDate={setCreateEndDateFilter}
        />
      </>
    </div>
  );
};

export default FilterPilotadosAdmin;
