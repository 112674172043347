import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { MdDeleteForever } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useDeleteCluster from '../../../hooks/useDeleteCluster';
import DeletePopover from '../../common/DeletePopover';
import TooltipItem from '../../../components/common/TooltipItem';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const fields = [{ field: 'cluster' }, { field: 'merchandising' }, { field: 'activo' }, { field: '' }];

function TableClusters({ centers, isLoading, limit, page, setPage }) {
  const navigate = useNavigate();
  const { deletePopover, handleDeleteClick, handleDeleteClose, handleDeleteConfirm } = useDeleteCluster();
  return (
    <>
      {centers?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {fields?.map((field, index) => (
                  <StyledTableCell align="left" key={index}>
                    {field?.field?.toUpperCase()}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {centers?.map((center, index) => (
                <React.Fragment key={center._id + index}>
                  <StyledTableRow
                    style={{ background: center?.active || center?.active === null ? 'initial' : 'rgb(201, 201, 201)' }}
                  >
                    <StyledTableCell align="left">
                      <p className="cell-clusters">{center?.type}</p>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p className="cell-clusters">{center?.merchandising?.name}</p>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p className="cell-clusters">{center?.active ? 'Activo' : 'No activo'}</p>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className="container-icons-edit-delete-master">
                        <EditIcon
                          className="tooltip-edit-cluster-icon"
                          style={{ cursor: 'pointer' }}
                          onClick={() => navigate(`${center?._id}/edit-clusters`)}
                        />
                        <MdDeleteForever
                          id={center._id}
                          onClick={handleDeleteClick}
                          className="tooltip-delete-cluster-icon"
                          style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <DeletePopover
                    textCancel={'No'}
                    textConfirm={'Sí'}
                    textQuestion={
                      '¿Estás seguro de que deseas eliminar este cluster? Una vez eliminado, no se podrá volver a utilizar en la aplicación.'
                    }
                    handleDeleteClose={handleDeleteClose}
                    deletePopover={deletePopover}
                    handleDeleteConfirm={handleDeleteConfirm}
                  />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <h3 style={{ fontWeight: 'bolder' }}>No hay clusters para los filtros seleccionados</h3>
      )}

      {/* TOOLTIP PARA LOS ICONOS */}
      <TooltipItem content="Editar cluster" className="tooltip-edit-cluster-icon" position="top" />
      <TooltipItem content="Eliminar cluster" className="tooltip-delete-cluster-icon" position="top" />
    </>
  );
}

export default TableClusters;
