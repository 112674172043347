import { getAllFiles } from '../api/alcampoApi';
import { PROP_POSTER_FILTER } from '../constants/viewer';
const lineColor = 0x999999;
export var line3d;
export var arrayLine3d = [];
export var allPropertiesModel;
export const labelOffset = new THREE.Vector3(80, 80, 0);

// import "./../extensions/MarkUp3dExtension";
var $ = function (div) {
  return document.getElementById(div);
};

/* global Autodesk, THREE */
export let viewer;
let viewableId;
var tileId = '';

const loadScript = (url, namespace) => {
  if (window[namespace] !== undefined) {
    return Promise.resolve();
  }
  return new Promise(function (resolve, reject) {
    const el = document.createElement('script');
    el.src = url;
    el.onload = resolve;
    el.onerror = reject;
    document.head.appendChild(el);
  });
};

const loadStylesheet = (url) => {
  return new Promise(function (resolve, reject) {
    const el = document.createElement('link');
    el.rel = 'stylesheet';
    el.href = url;
    el.onload = resolve;
    el.onerror = reject;
    document.head.appendChild(el);
  });
};

export const launchViewer = async (
  urn,
  id,
  setIsModelLoaded,
  handleResetPoints,
  setPoints,
  setSelectedPoint,
  selectedPoint,
  setUniqueProperties,
  token,
  div = 'forgeViewer',
) => {
  // await loadScript(
  //   "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.js",
  //   "Autodesk"
  // );
  await loadStylesheet('https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.css');
  tileId = id;
  var options = {
    env: 'AutodeskProduction',
    getAccessToken: function (onSuccess) {
      getForgeToken(token, onSuccess);
    },
    api: 'derivativeV2' + (atob(urn.replace('_', '/')).indexOf('emea') > -1 ? '_EU' : ''), // handle BIM 360 US and EU regions
  };
  console.log('opciones', options);
  Autodesk.Viewing.Initializer(options, () => {
    viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById(div), {
      extensions: ['Autodesk.Viewing.SceneBuilder', 'Autodesk.DataVisualization', 'Autodesk.DocumentBrowser'],
    });

    setIsModelLoaded && setIsModelLoaded(false);
    setPoints && setPoints([]);
    setSelectedPoint && setSelectedPoint(null);
    viewer.start();
    // viewer.setTheme("light-theme");
    viewer.setBackgroundColor(0, 0, 0, 155, 155, 155);
    viewer.impl.toggleGroundShadow(true);
    var spinners = document.getElementsByClassName('forge-spinner');
    var spinner = spinners[0];
    spinner.classList.remove('forge-spinner');
    spinner.classList.add('loader');
    spinner.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328.41 284.78"><defs><style>.cls-1{fill:#110d24;}.cls-2{fill:#100c23;}.cls-3{fill:#0f0b22;}</style></defs><title>Recurso 1</title><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M328.41,284.78H222.54l52.93-91.7C293.23,223.85,310.65,254,328.41,284.78Z"/><path class="cls-1" d="M217.07,284.69H111.3l52.87-91.61Z"/><path class="cls-1" d="M105.78,284.7H0l52.88-91.62Z"/><path class="cls-2" d="M164.17,187c-17.83-30.89-35.25-61.09-52.94-91.73H217.12C199.51,125.74,182,156,164.17,187Z"/><path class="cls-3" d="M55.57,188.27l53-91.72,52.95,91.72Z"/><path class="cls-3" d="M108.53,283.51l-52.94-91.7H161.45Z"/><path class="cls-3" d="M166.87,191.78H272.76L219.83,283.5Z"/><path class="cls-3" d="M111.38,91.44C129,60.93,146.42,30.75,164.17,0c17.7,30.66,35.14,60.85,52.81,91.44Z"/></g></g></svg>';

    var documentId = 'urn:' + urn;
    Autodesk.Viewing.Document.load(
      documentId,
      (doc) =>
        onDocumentLoadSuccess(
          doc,
          setIsModelLoaded,
          handleResetPoints,
          setPoints,
          setSelectedPoint,
          selectedPoint,
          setUniqueProperties,
        ),
      onDocumentLoadFailure,
    );
  });
};

const onDocumentLoadSuccess = (
  doc,
  setIsModelLoaded,
  handleResetPoints,
  setPoints,
  setSelectedPoint,
  selectedPoint,
  setUniqueProperties,
) => {
  // if a viewableId was specified, load that view, otherwise the default view
  viewer.setBackgroundColor(0, 0, 0, 155, 155, 155);
  viewer.impl.toggleGroundShadow(true);
  var viewables = viewableId ? doc.getRoot().findByGuid(viewableId) : doc.getRoot().getDefaultGeometry();

  viewer.loadDocumentNode(doc, viewables).then((i) => {
    // any additional action here?
    viewer.addEventListener(Autodesk.Viewing.MODEL_LAYERS_LOADED_EVENT, async (event) => {
      findPropertynamesCb(viewer, (res) => {
        allPropertiesModel = res;
        setUniqueProperties && setUniqueProperties(allPropertiesModel);
      });
      handleResetPoints && handleResetPoints();
      viewer.overlays.addScene('custom-scene');
      setIsModelLoaded && setIsModelLoaded(true);
      // const points = await handleFetchProperties(viewer, setPoints);
      // handlePaintSprites(viewer, points, setSelectedPoint, selectedPoint);
    });

    // viewer.addEventListener(Autodesk.Viewing.LOAD_MISSING_GEOMETRY, async (event) => {
    //   console.log('LOAD_MISSING_GEOMETRY');
    // });

    // viewer.addEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, async (event) => {
    //   console.log('MODEL_ROOT_LOADED_EVENT');
    // });

    // viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, async (event) => {
    //   console.log('GEOMETRY_LOADED_EVENT');
    // });

    // viewer.addEventListener(Autodesk.Viewing.LOAD_GEOMETRY_EVENT, async (event) => {
    //   console.log('LOAD_GEOMETRY_EVENT');
    // });

    // viewer.addEventListener(Autodesk.Viewing.FRAGMENTS_LOADED_EVENT, () => {
    //   console.log('FRAGMENTS_LOADED_EVENT');
    // });
    // viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_UNAVAILABLE_EVENT, () => {
    //   console.log('OBJECT_TREE_UNAVAILABLE_EVENT');
    // });
    // viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
    //   console.log('OBJECT_TREE_CREATED_EVENT');
    // });
  });
};

const onDocumentLoadFailure = (viewerErrorCode, viewerErrorMsg) => {
  console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode + '\n- errorMessage:' + viewerErrorMsg);
};

const getForgeToken = (token, callback) => {
  fetch('/api/forge/oauth/token', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    res.json().then((data) => {
      callback(data.access_token, data.expires_in);
    });
  });
};

export const getBulkPropertiesAsync = (viewer, dbIds, properties = []) => {
  return new Promise((resolve, reject) => {
    viewer.model.getBulkProperties(
      dbIds,
      properties,
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  });
};

// funcion para comprobar si un codigo existe en el modelo
export const checkCode = async (code) => {
  const dbIds = await findLeafNodes(viewer);
  const allProperties = await getBulkPropertiesAsync(viewer, dbIds, ['MED_QTO']);
  const dbIdsByCode = allProperties.filter((item) => item.properties[0].displayValue == code);
  return dbIdsByCode.length > 0 ? true : false;
};

export const filterViewer = (dbIds) => {
  viewer.isolate(dbIds);
  viewer.fitToView(dbIds);
};

export const fitToView = (dbIds) => {
  viewer.fitToView(dbIds);
};

export const findLeafNodes = (viewer) => {
  return new Promise(function (resolve, reject) {
    viewer.model.getObjectTree(function (tree) {
      let leaves = [];
      tree.enumNodeChildren(
        tree.getRootId(),
        function (dbId) {
          if (tree.getChildCount(dbId) === 0) {
            leaves.push(dbId);
          }
        },
        true,
      );
      resolve(leaves);
    }, reject);
  });
};

export const getCurrentModelVersion = () => {
  const urn = viewer.model.getSeedUrn();
  const currentVersion = atob(urn.replace('_', '/')).split('?version=')[1];
  return currentVersion;
};

export const getAllDbIdsByCode = async (code, unit) => {
  // const dbIds = await findLeafNodes(viewer);
  const dbIds = await startLeafNodes();
  const allProperties = await getBulkPropertiesAsync(viewer, dbIds, ['MED_QTO']);
  const dbIdsByCode = allProperties.filter((item) => item.properties[0].displayValue.includes(code));
  return dbIdsByCode;
};

function getCenterBoundingBox(viewer, dbIds) {
  dbIds.forEach(function (item, index) {
    viewer.select([item.dbId]);
    const center = viewer.utilities.getBoundingBox().getCenter();
    item.center = center;
    if (dbIds.length == index + 1) {
      viewer.clearSelection();
    }
  });
  return dbIds;
}

export const handleFetchProperties = async (viewer, setPoints, token) => {
  const dbIds = await findLeafNodes(viewer);
  const values = await getBulkPropertiesAsync(viewer, dbIds, [PROP_POSTER_FILTER]);
  const valuesWithNotEmptyValues = values.filter(
    ({ properties }) => properties[0].displayValue !== '' && properties[0].displayValue !== null,
  );

  const allFiles = await getAllFiles(token);
  let boundingBox = getCenterBoundingBox(viewer, valuesWithNotEmptyValues);
  boundingBox.forEach((item) => {
    const file = allFiles.find(({ name }) => name?.includes(item.properties[0].displayValue));
    file ? (item.isFile = true) : (item.isFile = false);
    file ? (item.file = file) : (item.file = null);
  });

  setPoints(boundingBox);
  return boundingBox;

  // handlePaintSprites(viewer, boundingBox);
};

export const isolateDbIds = (dbIds) => {
  viewer.isolate(dbIds);
};

export const getDataByName = async (properties) => {
  const dbIds = await startLeafNodes();
  const values = await getBulkPropertiesAsync(viewer, dbIds, properties);
  return values;
};

export const findPropertynamesCb = (viewer, cb) => {
  findLeafNodes(viewer).then((dbIds) => {
    const groupSize = 1000;
    const groups = [];
    for (let i = 0; i < dbIds.length; i += groupSize) {
      groups.push(dbIds.slice(i, i + groupSize));
    }
    const promises = groups.map((group) => {
      return new Promise((resolve, reject) => {
        viewer.model.getBulkProperties(
          group,
          {},
          (res) => {
            let propMap = new Map(); // Usar un Map en lugar de un Set
            for (const result of res) {
              for (const prop of result.properties) {
                if (prop.type === 3 && prop.units != '') {
                  const propKey = prop.displayName;
                  // Si no existe en el Map, lo agregamos
                  if (!propMap.has(propKey)) {
                    propMap.set(propKey, {
                      displayName: prop.displayName,
                      unit: parseUnits(prop.units),
                      displayValue: prop.displayValue,
                    });
                  }
                }
              }
            }
            resolve(Array.from(propMap.values()));
          },
          (err) => {
            reject(err);
          },
        );
      });
    });
    Promise.all(promises)
      .then((propNamesList) => {
        const propNamesMap = new Map(); // Usar un Map temporal
        for (const propNames of propNamesList) {
          for (const propName of propNames) {
            propNamesMap.set(propName.displayName, propName); // Usar displayName como clave en el Map
          }
        }
        const uniquePropNames = Array.from(propNamesMap.values());
        cb(uniquePropNames);
      })
      .catch((err) => {
        console.error(err);
      });
  });
};

export const getUniqueDisplayValues = (values) => {
  const allValues = values.map((item) => item.properties[0].displayValue);
  const separatedValues = allValues.map((item) => item.split(';')).flat();

  const uniqueValues = [...new Set(allValues)].filter((item) => item !== '');
  return uniqueValues;
};

export const parseUnits = (unit) => {
  if (unit.includes('meters')) {
    return 'm';
  } else if (unit.includes('squareMeters')) {
    return 'm2';
  } else if (unit.includes('cubicMeters')) {
    return 'm3';
  } else if (unit.includes('millimeters')) {
    return 'mm';
  } else if (unit.includes('currency')) {
    return '€';
  } else if (unit.includes('wattsPerSquareMeterKelvin')) {
    return 'W/(m2K)';
  } else if (unit.includes('squareMeterKelvinsPerWatt')) {
    return 'W/(m2K)';
  } else if (unit.includes('kilojoulesPerKelvin')) {
    return 'kJ/K';
  } else if (unit.includes('degrees')) {
    return 'º';
  } else if (unit.includes('percentage')) {
    return '%';
  } else if (unit.includes('squareCentimeters')) {
    return 'cm2';
  } else if (unit.includes('centimetersToTheFourthPower')) {
    return 'cm4';
  } else if (unit.includes('litersPerSecond')) {
    return 'l/s';
  } else if (unit.includes('watts')) {
    return 'W';
  } else if (unit.includes('pascals')) {
    return 'Pa';
  } else if (unit.includes('voltAmperes')) {
    return 'VA';
  } else if (unit.includes('amperes')) {
    return 'A';
  } else if (unit.includes('metersPerSecond')) {
    return 'm/s';
  } else if (unit.includes('pascalsPerMeter')) {
    return 'Pa/m';
  } else if (unit.includes('celsius')) {
    return 'ºC';
  } else {
    return unit;
  }
};

const getFileExtensionModel = () => {
  return viewer?.model?.getData()?.loadOptions?.fileExt;
};

/* -------------------------------------------------------------------------- */
/*                                  GET DBIDS                                 */
/* -------------------------------------------------------------------------- */
const getAllLeafComponents = (callback) => {
  var cbCount = 0; // count pending callbacks
  var components = []; // store the results
  var tree; // the instance tree

  function getLeafComponentsRec(parent) {
    cbCount++;
    if (tree.getChildCount(parent) != 0) {
      tree.enumNodeChildren(
        parent,
        function (children) {
          getLeafComponentsRec(children);
        },
        false,
      );
    } else {
      components.push(parent);
    }
    if (--cbCount == 0) callback(components);
  }
  viewer.getObjectTree(function (objectTree) {
    tree = objectTree;
    var allLeafComponents = getLeafComponentsRec(tree.getRootId());
  });
};

const startLeafNodesNwd = () => {
  return new Promise((resolve, reject) => {
    viewer.model.getBulkProperties(
      null,
      ['GUID'],
      (res) => {
        const dbIds = res.map((item) => item.dbId);
        resolve(dbIds);
      },
      (err) => {
        reject(err);
      },
    );
  });
};

const startLeafNodesRvt = () => {
  return new Promise((resolve, reject) => {
    getAllLeafComponents((dbIds) => {
      return resolve(dbIds);
    });
  });
};
export const startLeafNodes = async () => {
  const extension = getFileExtensionModel();
  switch (extension) {
    case 'nwc':
      return startLeafNodesNwd();
    case 'nwd':
      return startLeafNodesNwd();
    case 'rvt':
      return startLeafNodesRvt();
    // case 'ifc':
    //   return startLeafNodesIfc(setAreViewerDbIdsLoaded, setAllPropertyNames)
    default:
      throw new Error('Extension file not found: ' + extension);
  }
};
