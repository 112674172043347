import { useEffect, useState, useContext } from 'react';
import { getBulkPropertiesAsync, viewer } from '../../helpers/viewerHelper';
// import { ViewerContext } from '../../context/ViewerContext';
import { ProyectoContext } from '../../context/ProyectoContext';
import { Checkbox, Tooltip } from '@mui/material';
import data from '../../assets/testModulosHabitacionesAlcampo.json';
import { DataGrid, GridToolbarExport, GridToolbarContainer, esES } from '@mui/x-data-grid';
import { MercadoGraphicContext } from '../../context/MercadoGraphicContext';

const THREE = window.THREE;

const csvOptions = { fields: ['nombre', 'area'] };
const printOptions = { disableToolbarButton: true };

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={csvOptions} printOptions={printOptions} />
    </GridToolbarContainer>
  );
}

//Revit Habitaciones instead of Revit Rooms in some ocassions

const deviceColumns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'nombre',
    headerName: 'Nombre',
    width: 220,
  },
  {
    field: 'mercado',
    headerName: 'Mercado',
    width: 150,
  },
  {
    field: 'categoria',
    headerName: 'Categoría',
    width: 150,
  },
  {
    field: 'estantes',
    headerName: '# de baldas',
    width: 150,
  },
];

const roomColumns = [
  { field: 'id', headerName: 'ID', width: 160 },
  {
    field: 'nombre',
    headerName: 'Nombre',
    width: 300,
  },
  {
    field: 'area',
    headerName: 'Area (m2)',
    width: 130,
  },
];

const Mercado = () => {
  const { setAreaData, setModuleData, setShelvesData } = useContext(MercadoGraphicContext);
  const { isModelLoaded } = useContext(ProyectoContext);
  const [roomsList, setRoomsList] = useState(null);
  // const [mercados, setMercados] = useState(null);
  const [devicesList, setDevicesList] = useState(null);
  const [phase, setPhase] = useState('Existente');
  const [selectedMercado, setSelectedMercado] = useState(null);
  const [currentModules, setCurrentModules] = useState(null);
  const [currentShelves, setCurrentShelves] = useState(null);
  const [totalArea, setTotalArea] = useState(null);
  const [deviceRows, setDeviceRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [roomRows, setroomRows] = useState([]);
  const [rowSelectionModelRooms, setRowSelectionModelRooms] = useState([]);
  const [shelveCount, setShelveCount] = useState(null);

  const handleChange = (event) => {
    // console.log(event.target.id, event.target.checked);
    if (event.target.checked) {
      setPhase(event.target.id);
      if (event.target.id === 'Existente') {
        // console.log(viewer.viewerDocument.getRoot().search({ type: 'geometry' }));
        // viewer.loadDocumentNode();
      } else if (event.target.id === 'Reforma 01') {
        // viewer.loadDocumentNode();
      }
    }
  };

  useEffect(() => {
    getBulkPropertiesAsync(viewer, [], ['externalId', 'Area', 'Nombre', 'Fase', 'Nivel'])
      .then((res) => {
        let roomsList = [];
        let roomArray = [];
        let testingB = new Set();
        let unique = new Set();

        const externalReference = data.map((elem) => {
          //Phase selection
          if (elem.phase === phase) {
            roomArray.push(elem.externalIdHabitacion);
          }
        });

        //Clear one
        const cleanRoomList = new Set(roomArray);

        // Get names
        cleanRoomList.map((ref) => roomsList.push(res.filter((x) => x.externalId.includes(ref))));

        //Need to check floors??? Hardcoded for f1!!

        const floorOneRoomlist = roomsList.filter((x) => x[1].properties[1].displayValue === 'ARQ_P00_SRT');
        // console.log(floorOneRoomlist);

        //clean dupes(arrays/names)

        floorOneRoomlist.map((t) => {
          testingB.add(t[1].properties[0].displayValue);
        });

        //structure

        // console.log(testingB);
        testingB.map((t) => {
          unique.add({ name: t, extIds: [], dbIds: [], area: 0 });
        });

        //Add IDs to rooms

        unique.map((roomRef) => {
          roomsList.map((idRef) => {
            if (idRef[1].properties[0].displayValue === roomRef.name) {
              roomRef.extIds.push(idRef[1].externalId);
              roomRef.dbIds.push(idRef[1].dbId);
              roomRef.area = roomRef.area + idRef[1].properties[2].displayValue;
            }
          });
        });

        let totArea = 0;

        roomsList.map((item) => {
          if (item[1].properties[1].displayValue === 'ARQ_P00_SRT') {
            totArea = totArea + item[1].properties[2].displayValue;
          }
        });

        // console.log(totArea);
        setTotalArea(totArea);
        // console.log('ROOM ROOOOOM ROOOOOOM', roomsList);
        setRoomsList(unique);

        // console.log(roomsList);
        // console.log(unique);
      })

      .catch((e) => console.log('e', e));
  }, [isModelLoaded, phase]);

  // console.log(data);

  const getRoomDevices = (roomIds, setViewer) => {
    // console.log(roomIds);
    viewer.clearThemingColors();

    const cleanRoomIds = [];

    roomIds.flat().map((z) => cleanRoomIds.push(z.split('/')[1]));

    // console.log(cleanRoomIds);

    const prefiltered = cleanRoomIds.map((roomId) => data.filter((x) => x.externalIdHabitacion.includes(roomId)));

    const filteredDevices = prefiltered.flat();

    let filteredDevicesList = [];

    getBulkPropertiesAsync(
      viewer,
      [],
      ['externalId', 'Descripción', 'INF_DescripcionMercado', 'INF_Categoria01', 'INF_NumeroEstantes'],
    )
      .then((res) => {
        filteredDevices.map((ref) => {
          if (ref.phase === phase) {
            filteredDevicesList.push(res.filter((x) => x.externalId.includes(ref.externalIdMobiliario)));
          }
        });

        // const modSet = new Set()
        // let modSet = [];

        const modList = filteredDevicesList.filter(
          (y) => y[1].properties[3]?.displayValue.includes('Modulo'),
          // || y[1].properties[1].displayValue.includes('Caja'),
        );

        let cleanModList = [];

        modList.map((mod) => {
          cleanModList.push(mod[1]);
        });

        setCurrentModules(cleanModList);

        const ShelveList = filteredDevicesList.filter((y) => y[1].properties[1].displayValue.includes('Estante'));
        let cleanShelveList = [];

        ShelveList.map((mod) => {
          cleanShelveList.push(mod[1]);
        });

        setCurrentShelves(cleanShelveList);

        let sc = 0;
        cleanModList.map((x) => (sc += Number.parseInt(x.properties[2].displayValue)));
        setShelveCount(sc);

        let selection = [];
        filteredDevicesList.map((dev) => {
          selection.push(dev[1].dbId);
          viewer.setThemingColor([dev[1].dbId], new THREE.Vector4(255, 0, 238, 1));
        });

        // console.log('SELECTIOOOON', selection);
        if (setViewer === true) {
          viewer.isolate(selection);
          viewer.fitToView(selection);
        }
        // console.log(filteredDevicesList);
        setDevicesList(filteredDevicesList);
      })

      .catch((e) => console.log('e', e));
  };

  useEffect(() => {
    let thisRows = [];
    if (selectedMercado) {
      selectedMercado.map((dev, index) =>
        thisRows.push({
          id: index,
          name: dev.nombre,
          value: dev.area,
        }),
      );
    }
    setAreaData(thisRows);
  }, [selectedMercado]);

  useEffect(() => {
    let thisRows = [];

    if (roomsList) {
      roomsList.map((dev, index) =>
        thisRows.push({
          id: dev.extIds,
          nombre: dev.name,
          area: Number(dev.area.toFixed(2)),
        }),
      );

      // extIds.flat().map((y) => splitIds.push(y.split('/')[1]));
    }
    // console.log(splitIds);
    setroomRows(thisRows);
  }, [roomsList]);

  useEffect(() => {
    let thisRows = [];

    if (devicesList) {
      devicesList.map((dev, index) =>
        thisRows.push({
          id: dev[1].dbId,
          nombre: dev[1].properties[3]?.displayValue,
          mercado: dev[1].properties[1].displayValue,
          categoria: dev[1].properties[0].displayValue,
          estantes: Number(dev[1].properties[2]?.displayValue),
        }),
      );
    }
    setDeviceRows(thisRows.filter((y) => y.nombre?.includes('Modulo')));
  }, [devicesList]);

  useEffect(() => {
    if (currentModules) {
      console.log(currentModules);
      const convertToMd = () => {
        const res = {};

        currentModules.forEach((obj) => {
          const key = obj.properties[1].displayValue;
          if (!res[key]) {
            res[key] = { name: obj.properties[1].displayValue, value: 0 };
          }
          res[key].value += 1;
        });
        return Object.values(res);
      };

      console.log(convertToMd());
      setModuleData(convertToMd());

      const convertToSd = () => {
        const res = {};

        currentModules.forEach((obj) => {
          const key = obj.properties[1].displayValue;
          if (!res[key]) {
            res[key] = { name: obj.properties[1].displayValue, value: 0 };
          }
          res[key].value += Number.parseInt(obj.properties[2].displayValue);
        });
        return Object.values(res);
      };

      console.log(convertToSd());
      setShelvesData(convertToSd());
    }
  }, [currentModules]);

  const IsolateUnique = (dbId) => {
    // viewer.clearThemingColors();
    viewer.setThemingColor([dbId], new THREE.Vector4(200, 210, 238, 1));
    viewer.isolate(dbId);
    viewer.fitToView(dbId);
  };

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => roomRows.find((row) => row.id === id));
    setSelectedMercado(selectedRowsData);
  };

  const areaSum = () => {
    let sum = 0;
    if (selectedMercado && selectedMercado !== 0) {
      selectedMercado.map((x) => (sum = sum + Number.parseFloat(x.area)));
      return sum;
    } else {
      return '---';
    }
  };

  return (
    <div style={{ height: '80vh' }}>
      <div style={{ height: '1px', backgroundColor: 'gray' }} />
      <div style={{ display: 'flex', justifyContent: 'space-around', backgroundColor: '#f2f2f2' }}>
        <p style={{ fontSize: '1.6rem', fontWeight: '600', paddingTop: '10px' }}>
          {/* v{versions[0].attributes.versionNumber} -  */}
          Fase de proyecto
        </p>
        <div style={{ display: 'flex' }}>
          {' '}
          <Checkbox
            id="Existente"
            checked={phase === 'Existente'}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            color="error"
          />{' '}
          <p style={{ fontSize: '1.3rem', margin: '10px 0 0 0' }}>Actual</p>
        </div>
        <div style={{ display: 'flex' }}>
          {' '}
          <Checkbox
            id="Reforma 01"
            checked={phase === 'Reforma 01'}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            color="error"
          />{' '}
          <p style={{ fontSize: '1.3rem', margin: '10px 0 0 0' }}>Reformado</p>
        </div>
      </div>
      <div style={{ height: '1px', backgroundColor: 'gray' }} />
      <div style={{ height: '40%' }}>
        <DataGrid
          sx={{
            '.MuiDataGrid-checkboxInput': {
              color: 'rgb(51, 51, 51) !important',
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={roomRows}
          columns={roomColumns}
          slots={{
            toolbar: CustomToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            onRowsSelectionHandler(newRowSelectionModel);
            setRowSelectionModelRooms(newRowSelectionModel);
            console.log(newRowSelectionModel);
            getRoomDevices(newRowSelectionModel, true);
          }}
          rowSelectionModel={rowSelectionModelRooms}
        />
      </div>
      <div style={{ height: '1px', backgroundColor: 'gray' }} />
      <div style={{ display: 'flex', height: '15%' }}>
        <div
          style={{
            margin: '10px',
            borderRadius: '5px',
            width: '24%',
            backgroundColor: '#333',
            textAlign: 'center',
            paddingTop: '10px',
          }}
        >
          <p style={{ color: 'white', fontWeight: '600', fontSize: '1.2rem' }}>Área Mercado</p>
          {selectedMercado ? (
            <>
              <p style={{ color: 'white', fontWeight: '600', fontSize: '1.4rem' }}>
                {areaSum().toFixed(2) + ' m'}

                <sup style={{ fontSize: '0.8rem' }}>2</sup>
              </p>
            </>
          ) : (
            <p style={{ color: 'white', fontWeight: '600', fontSize: '2rem' }}>---</p>
          )}
        </div>
        <div
          style={{
            margin: '10px',
            borderRadius: '5px',
            width: '24%',
            backgroundColor: '#333',
            textAlign: 'center',
            paddingTop: '10px',
          }}
        >
          <p style={{ color: 'white', fontWeight: '600', fontSize: '1.2rem' }}>Repercusión total</p>
          {selectedMercado && totalArea ? (
            <p style={{ color: 'white', fontWeight: '600', fontSize: '1.4rem' }}>
              {((areaSum() * 100) / totalArea).toFixed(2)}
            </p>
          ) : (
            <p style={{ color: 'white', fontWeight: '600', fontSize: '2rem' }}>---</p>
          )}
        </div>
        <div
          style={{
            margin: '10px',
            borderRadius: '5px',
            width: '24%',
            backgroundColor: '#333',
            textAlign: 'center',
            paddingTop: '5px',
          }}
        >
          <p style={{ color: 'white', fontWeight: '600', fontSize: '1.2rem' }}>Número de módulos</p>
          <p style={{ color: 'white', fontWeight: '600', fontSize: '2rem' }}>
            {currentModules ? currentModules.length : '---'}
          </p>
        </div>
        {/* <Tooltip
          title={
            "Número basado en elementos cuya descripción contenga 'estante', pueden encontrarse diferencias si consideramos otros elementos como colgadores."
          }
        > */}
        <div
          style={{
            margin: '10px',
            borderRadius: '5px',
            width: '24%',
            backgroundColor: '#333',
            textAlign: 'center',
            paddingTop: '5px',
          }}
        >
          <p style={{ color: 'white', fontWeight: '600', fontSize: '1.2rem' }}>Número de baldas</p>
          <p style={{ color: 'white', fontWeight: '600', fontSize: '2rem' }}>{shelveCount ? shelveCount : '---'}</p>
        </div>
        {/* </Tooltip> */}
      </div>
      <div style={{ height: '1px', backgroundColor: 'gray' }} />
      <div style={{ height: '50%' }}>
        {deviceRows.length !== 0 && (
          <DataGrid
            sx={{
              '.MuiDataGrid-checkboxInput': {
                color: 'rgb(51, 51, 51) !important',
              },
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={deviceRows}
            columns={deviceColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
              console.log(newRowSelectionModel);
              IsolateUnique(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
          />
        )}
      </div>
    </div>
  );
};

export default Mercado;
