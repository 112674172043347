import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { createCenterType } from '../../../api/carteleriaApi';
import { useQueryClient } from '@tanstack/react-query';

function useCreateCluster() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const { mutate: postCluster, isLoading } = useMutation({
    mutationFn: async (body) => {
      const token = await getAccessTokenSilently();
      return await createCenterType(token, body);
    },
    onSuccess: () => {
      toast.success('Se ha creado el cluster con éxito.');
      queryClient.invalidateQueries(['allCentersTypes']);
    },
    onError: (error) => {
      toast.error(error.message, {
        id: 'error-duplicate-name-cluster',
      });
    },
  });
  return { postCluster, isLoading };
}

export default useCreateCluster;
