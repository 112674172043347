import React, { useContext } from 'react';
import { ProyectoContext } from '../../../context/ProyectoContext';
import Viewer from '../../../helpers/Viewer/Viewer';

const NoRegistradosButton = () => {
  const { currentProject } = useContext(ProyectoContext);

  const handleOnClick = () => {
    const viewerData = new Viewer(currentProject._id);
    viewerData.getDbIdsNotInBD(currentProject._id);
  };
  return (
    <button className="viewer-button" onClick={handleOnClick}>
      No Registrados
    </button>
  );
};

export default NoRegistradosButton;
