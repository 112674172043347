import { IconButton, Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import exportExcel from '../../assets/archivo-excel.png';
import XLSX from 'xlsx-js-style';
import { ProyectoContext } from '../../context/ProyectoContext';
import { getAllCapitulosSubcapitulosPartidasProyecto } from '../../api/alcampoApi';
import { useAuth0 } from '@auth0/auth0-react';

const ExportExcel = () => {
  const { currentProject } = useContext(ProyectoContext);
  const { getAccessTokenSilently } = useAuth0();

  const handleOnClick = async () => {
    let token = await getAccessTokenSilently();

    // ANTES DE EMPEZAR CON EL EXCEL HAY QUE TRAER TODOS LOS CAPITULOS JUNTOS SU SUBCAPITULOS Y PARTIDAS
    const res = await getAllCapitulosSubcapitulosPartidasProyecto(currentProject._id, token);

    // PASO 1: Crear un nuevo libro de trabajo
    const wb = XLSX.utils.book_new();

    // PASO 2: Crear filas de datos y estilos

    // PRIMERA FILA VA A SER EL NOMBRE DEL PROYECTO JUNTO AL IMPORTE DE ESTE
    let rows = [
      [
        { v: 'PROYECTO', t: 's', s: { font: { sz: 12, bold: true }, width: { wch: 100 } } },
        { v: currentProject.name, t: 's', s: { alignment: { horizontal: 'right' } } },
      ],
      [
        { v: 'IMPORTE', t: 's', s: { font: { sz: 12, bold: true } } },
        {
          v:
            currentProject.import.toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + '€',
          t: 's',
          s: { alignment: { horizontal: 'right' }, fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        },
      ],
      [],

      // AQUI AHORA VAMOS A CONSTRUIR LA CABECERA PARA LAS PARTIDAS Y CAPITULOS
      [
        { v: 'CÓDIGO', t: 's', s: { font: { sz: 12 }, fill: { fgColor: { rgb: 'e15b64' } } } },
        { v: 'NOMBRE', t: 's', s: { font: { sz: 12 }, fill: { fgColor: { rgb: 'e15b64' } } } },
        {
          v: 'UNIDAD',
          t: 's',
          s: { alignment: { horizontal: 'right' }, font: { sz: 12 }, fill: { fgColor: { rgb: 'e15b64' } } },
        },
        {
          v: 'CANTIDAD',
          t: 's',
          s: { alignment: { horizontal: 'right' }, font: { sz: 12 }, fill: { fgColor: { rgb: 'e15b64' } } },
        },
        {
          v: 'PRECIO',
          t: 's',
          s: { alignment: { horizontal: 'right' }, font: { sz: 12 }, fill: { fgColor: { rgb: 'e15b64' } } },
        },
        {
          v: 'IMPORTE',
          t: 's',
          s: { alignment: { horizontal: 'right' }, font: { sz: 12 }, fill: { fgColor: { rgb: 'e15b64' } } },
        },
      ],
    ];

    res.forEach((data) => {
      const capituloRow = [
        { v: '', t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } } } },
        {
          v: data.capitulo.capitulo.name,
          t: 's',
          s: {
            font: { sz: 12, bold: true },
            fill: {
              fgColor: { rgb: 'E9E9E9' },
            },
          },
        },
        { v: '', t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } } } },
        { v: '', t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } } } },
        { v: '', t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } } } },
        {
          v:
            data.capitulo.import.toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + '€',
          t: 's',
          s: { alignment: { horizontal: 'right' }, fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true, sz: 12 } },
        },
      ];
      rows.push(capituloRow);

      data.subcapitulos.forEach((subcapitulo) => {
        // ahora por cada subcapitulo tenemos que añadir las partidas correspondientes
        const subcapituloRow = [
          { v: '', t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } } } },
          { v: subcapitulo.capitulo.name, t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } } },
          { v: '', t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } } } },
          { v: '', t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } } } },
          { v: '', t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } } } },
          {
            v:
              subcapitulo.import.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + '€',
            t: 's',
            s: {
              alignment: { horizontal: 'right' },
              fill: { fgColor: { rgb: 'E9E9E9' } },
              font: { bold: true, sz: 12 },
            },
          },
        ];
        rows.push(subcapituloRow);
        data.partidasProyecto.forEach((partida) => {
          // solo añadimos las partidas que pertenecen al subcapitulo
          if (partida.partida.parentCapitulo !== subcapitulo.capitulo._id) return;
          const partidaRow = [
            { v: partida.partida.code, t: 's' },
            { v: partida.partida.name, t: 's' },
            { v: partida.unit, t: 's', s: { alignment: { horizontal: 'right' } } },
            {
              v: partida.quantity.quantity.toLocaleString('de-DE', {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4,
              }),
              t: 's',
              s: { alignment: { horizontal: 'right' } },
            },
            {
              v:
                partida.price.toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + '€',
              t: 's',
              s: { alignment: { horizontal: 'right' } },
            },
            {
              v:
                (partida.quantity.quantity * partida.price).toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + '€',
              t: 's',
              s: { alignment: { horizontal: 'right' } },
            },
          ];
          rows.push(partidaRow);
        });
      });
    });

    // let row = [
    //   { v: 'Courier: 24', t: 's', s: { font: { name: 'Courier', sz: 24 } } },
    //   { v: 'bold & color', t: 's', s: { font: { bold: true, color: { rgb: 'FF0000' } } } },
    //   { v: 'fill: color', t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } } } },
    //   { v: 'line\nbreak', t: 's', s: { alignment: { wrapText: true } } },
    // ];

    // PASO 3: Crear hoja de cálculo con filas; Añadir hoja de cálculo al libro de trabajo
    const ws = XLSX.utils.aoa_to_sheet(rows);
    const wscols = [{ wch: 20 }, { wch: 50 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
    ws['!cols'] = wscols;
    XLSX.utils.book_append_sheet(wb, ws, 'Presupuesto');

    // PASO 4: Escribir el archivo Excel en el navegador
    XLSX.writeFile(wb, `${currentProject.name.split(' ').join('_')}.xlsx`);
  };

  return (
    <Tooltip title="Exportar capitulos y partidas a excel" placement="right">
      <IconButton
        sx={{ borderRadius: '5px', border: '1px solid rgba(0, 0, 0, 0.1)' }}
        onClick={handleOnClick}
        className="button-icons-title"
      >
        <img src={exportExcel} style={{ width: '20px', height: '20px' }} />
      </IconButton>
    </Tooltip>
  );
};

export default ExportExcel;
