import { getAllCodesByPartidasProyecto } from '../../api/alcampoApi';
import {
  filterViewer,
  fitToView,
  getBulkPropertiesAsync,
  getDataByName,
  getUniqueDisplayValues,
  isolateDbIds,
  viewer,
} from '../viewerHelper';

export default class Viewer {
  constructor(projectId) {
    this.projectId = projectId;
    this.viewer = viewer;
    if (!this.viewer) {
      throw new Error('Viewer no existe 😥');
    }
  }

  // TODO: hacer que las dos primeras promises se ejecuten simultaneamente
  getDbIdsFromBD = async (token) => {
    if (!this.codesBd) {
      this.codesBd = await getAllCodesByPartidasProyecto(this.projectId, token);
      console.log('thisCodes', this.codesBd);
    }

    if (!this.allDbIds) {
      this.allDbIds = await getDataByName(['MED_QTO']);
      console.log('this.allDbIds', this.allDbIds);
    }

    const dbIdsSelected = this.allDbIds
      .filter((item) => this.codesBd.includes(item.properties[0].displayValue))
      .map((item) => item.dbId);

    filterViewer(dbIdsSelected);
  };

  getDbIdsNotInBD = async () => {
    if (!this.codesBd) {
      // this.codesBd = await getAllCodesByPartidasProyecto(this.projectId);
      this.codesBd = await getBulkPropertiesAsync(viewer, [], ['MED_QTO']);
    }

    if (!this.allDbIds) {
      this.allDbIds = await getDataByName(['MED_QTO']);
    }

    const dbIdsSelected = this.allDbIds
      .filter((item) => this.codesBd.includes(item.properties[0].displayValue))
      .map((item) => item.dbId);

    // Quedarse con los elementos de this.allDbIds que no coincidan con dbIdsSelected
    const dbIdsNotInBD = this.allDbIds.filter((item) => !dbIdsSelected.includes(item)).map((item) => item.dbId);
    filterViewer(dbIdsNotInBD);
  };

  getDbIdsByCodes = async (code) => {
    console.log('code', code);
    if (!this.allDbIds) {
      this.allDbIds = await getDataByName(['MED_QTO']);
    }

    const results = [];

    // MAPEAR LOS DBIDS
    // MAPEAR LOS CODES

    for (const dbIds of this.allDbIds) {
      const subArray = dbIds.properties[0].displayValue.split(';');
      for (const subElement2 of subArray) {
        for (const individualCode of code) {
          if (subElement2.includes(individualCode)) {
            results.push(dbIds);
            break;
          }
        }
      }
    }

    console.log('RESULTS: ', results);
    filterViewer(results.map((item) => item.dbId));
    return results;
  };
}
