import React, { useContext, useEffect } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../components/common/CustomBootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserProvider';

const SelectSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole } = useContext(UserContext);
  useEffect(() => {
    if (userRole === 'ViewMaster' && location.pathname === '/') {
      navigate('/master');
    }
    if (userRole === 'user-signage' && location.pathname === '/') {
      navigate('/carteleria');
    }
    if (userRole === 'admin-signage' && location.pathname === '/') {
      navigate('/admin');
    }
  }, [userRole, location.pathname]);

  const handleClickGoProyectos = () => {
    navigate('/projects');
  };

  const handleClickGoCarteleria = () => {
    if (!userRole) return;
    if (userRole == 'MasterAdmin') return navigate('/master');
    const centerConfirmed = JSON.parse(localStorage.getItem('centerConfirmed'));
    // TODO: controlar que si centerConfirmed existe, entonces que no se pueda acceder a /center y que se redirija a /center/carteleria. Además, en el caso contrario, en el que centerConfirmed no exista, que no se pueda acceder a /center/carteleria y que se redirija a /center:

    if (centerConfirmed) {
      navigate('/carteleria');
    } else {
      navigate('/center');
    }
  };

  return (
    // <div style={{ display: 'flex', height: '100%', width: '100%' }}>
    <Row className="select-section-row">
      <Colxx onClick={handleClickGoProyectos} className="select-section-col" xxs="6">
        <div className="select-section-image-container">
          <img
            className="select-section-image"
            src="https://alcampo.fra1.digitaloceanspaces.com/recursos/Modelos-BIM.png"
            alt="projects"
          />
          <div className="select-section-overlay">
            <p className="select-section-text">MODELOS BIM</p>
          </div>
        </div>
      </Colxx>
      <Colxx onClick={handleClickGoCarteleria} className="select-section-col" xxs="6">
        <div className="select-section-image-container">
          <img
            className="select-section-image"
            src="https://alcampo.fra1.digitaloceanspaces.com/recursos/Catalogo-de-materiales.png"
            alt="carteleria"
          />
          <div className="select-section-overlay">
            <div>
              <p className="select-section-text">
                {userRole == 'MasterAdmin' ? 'ADMINISTRAR CATÁLOGO DE MATERIALES' : 'CATÁLOGO DE MATERIALES'}
              </p>
            </div>
          </div>
        </div>
      </Colxx>
    </Row>
    // </div>
  );
};

export default SelectSection;
