import { Card } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Buscador } from '../../Pedido/Buscador';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { getAllArtesFinalesPedidoPilotado } from '../../../api/carteleriaApi';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import ContainerDb from './ContainerDb';
import ContainerSelected from './ContainerSelected';
import { handleChangeInputSearch, handleClearInput, handleKeyDown } from '../../../helpers/buscadorHelper';
import { PilotadosContext } from '../../../context/PilotadosContext';

const SelectArteFinal = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { aaffSelected, setAaffSelected } = useContext(PilotadosContext);

  const [valueSearch, setValueSearch] = useState('');
  const [valueSearchAfter, setValueSearchAfter] = useState(null);
  const [aaffArray, setAaffArray] = useState([]);
  const [page, setPage] = useState(1);

  // PETICION PARA TRAER TODOS LOS ARTES FINALES UNICAMENTE CON LA IMAGEN, CODIGO, NOMBRE Y FORMATOS
  const { data, isLoading, isFetching } = useQuery(['all-aaff-pedido-pilotado', page, valueSearchAfter], async () => {
    const token = await getAccessTokenSilently();
    return await getAllArtesFinalesPedidoPilotado(token, valueSearchAfter, page);
  });

  useEffect(() => {
    // reseteamos el array de artes finales cada vez que se haga una busqueda
    setAaffArray([]);
    if (page !== 1) {
      setPage(1);
    }
  }, [valueSearchAfter]);

  useEffect(() => {
    if (isFetching) return;
    // antes de acutalizar el array vamos a comprobar si hay datos en aaffSelected y si los hay lo recorremos
    if (aaffSelected.length > 0 && data?.data?.length > 0) {
      aaffSelected.forEach((aaff) => {
        // recorremos los formatos de cada arte final y los buscamos en la data que ha venido de data base si esta cambiamos la propidad selected a true
        aaff.formats.forEach((format) => {
          const aaffIndex = data.data.findIndex((a) => a._id === aaff._id);
          if (aaffIndex === -1) return;
          const formatIndex = data.data[aaffIndex].formats.findIndex((f) => f._id === format._id);
          if (formatIndex === -1) return;

          data.data[aaffIndex].formats[formatIndex].selected = true;
        });
      });
    }
    setAaffArray((prev) => [...prev, ...data.data]);
  }, [isFetching]);

  return (
    <div>
      <h1>1. Selecciona los artes finales</h1>
      <Card style={{ padding: '1rem' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <Buscador
            label="Buscar por código o nombre"
            value={valueSearch}
            onChange={(e) => handleChangeInputSearch(e, setValueSearch)}
            onKeyDown={(e) => handleKeyDown(e, setValueSearchAfter)}
            onClear={() => handleClearInput(setValueSearch, setValueSearchAfter)}
            width="25rem"
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* CONTENEDOR CON TODOS LOS ARTES DE LA DB */}
          <ContainerDb
            isLoading={isLoading}
            setDataArray={setAaffArray}
            dataArray={aaffArray}
            dataSelected={aaffSelected}
            data={data}
            isFetching={isFetching}
            setDataSelected={setAaffSelected}
            setPage={setPage}
            tienda={false}
          />
          {/* BOTONES SIN FUNCIONALIDAD PARA INDICAR QUE SON LISTAS QUE SE PUEDE TRANSFERIR LA DATA */}
          <div
            style={{
              height: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <WestIcon />
            <EastIcon />
          </div>

          {/* CONTENEDOR CON TODOS LOS ARTES SELECCIONADOS */}
          <ContainerSelected
            setDataArray={setAaffArray}
            dataArray={aaffArray}
            dataSelected={aaffSelected}
            setDataSelected={setAaffSelected}
          />
        </div>
      </Card>
    </div>
  );
};

export default SelectArteFinal;
