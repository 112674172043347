import { useCloseModal } from '../../../hooks/useCloseModal';
import { Modal } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import { TbPointFilled } from 'react-icons/tb';
import { useGetAaffByProveedor } from '../../../lib/react-query/providerQuery';

const ErrorDeleteProveedor = ({ type }) => {
  const { proveedorName, aaffAsociated, isLoading } = useGetAaffByProveedor(type);

  const closeModal = useCloseModal(2);

  if (isLoading) return;

  return (
    <Modal open onClose={closeModal}>
      <div className="create-cluster-container-modal">
        <IoMdClose onClick={closeModal} size="18px" className="f-right pointer" />
        <div className="center-form">
          <span className="d-flex ai-center g-05">
            <p>El siguiente proveedor: </p>
            <p className="fw-bold fs-italic">{proveedorName}</p>
          </span>
          {type === 'desactivar' && (
            <p className="fs-italic m-1">
              <strong>IMPORTANTE:</strong>
              {` No es necesario eliminar el proveedor asociado. Simplemente con desactivarlo podrás desactivar `}
              <strong>{proveedorName}</strong>
              {` sin problemas.`}
            </p>
          )}
          <p className="fs-italic">{`No se ha podido ${type}, debido a que esta asociado a los siguientes artículos:`}</p>
          <div className="aaff-asociated fd-column d-flex g-05">
            {aaffAsociated.map((aaff, i) => (
              <div className="d-flex ai-center g-05" key={aaff.code + i}>
                <TbPointFilled /> {aaff.code} - {aaff.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorDeleteProveedor;
