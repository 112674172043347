/* global Autodesk, THREE */

import { viewer } from '../../helpers/viewerHelper';

const areSpritesCreated = async (viewer) => {
  const dataVizExtn = await viewer.getExtension('Autodesk.DataVisualization');
  if (dataVizExtn?.viewableData) {
    return true;
  } else {
    return false;
  }
};

const handlePaintSprites = async (viewer, points, setSelectedPoint, selectedPoint) => {
  const onSpriteClick = async (e) => {
    if ((e.type = 'DATAVIZ_OBJECT_CLICK' && e.dbId !== 0)) {
      const clickedPoint = points.filter((point) => point.dbId == e.dbId);

      if (clickedPoint.length === 0) return;
      if (clickedPoint[0].isFile == false) return;

      if (selectedPoint && selectedPoint.find((point) => point.dbId == clickedPoint[0].dbId)) return;
      setSelectedPoint([
        {
          ...clickedPoint[0],
        },
      ]);
    }

    if (e.dbId == 0) {
      // console.log('dbid', e.dbId)
    }
  };

  const dataVizExtn = await viewer.getExtension('Autodesk.DataVisualization');
  const DataVizCore = Autodesk.DataVisualization.Core;
  const viewableType = DataVizCore.ViewableType.SPRITE;
  const spriteColor = new THREE.Color(0xffffff);
  const spriteIconNotFile = 'https://general-projects.fra1.digitaloceanspaces.com/alcampo/redCircle.png';
  const spriteIconFile = 'https://general-projects.fra1.digitaloceanspaces.com/alcampo/greenCircle.png';

  viewer.addEventListener(DataVizCore?.MOUSE_CLICK, onSpriteClick);

  const styleFile = new DataVizCore.ViewableStyle(viewableType, spriteColor, spriteIconFile);
  const styleNotFile = new DataVizCore.ViewableStyle(viewableType, spriteColor, spriteIconNotFile);

  const viewableData = new DataVizCore.ViewableData();
  viewableData.spriteSize = 20; // Sprites as points of size 24 x 24 pixels

  points.forEach((point, index) => {
    const dbId = point.dbId;
    const position = point.center;
    const viewable = new DataVizCore.SpriteViewable(position, point?.isFile ? styleFile : styleNotFile, dbId);

    viewableData.addViewable(viewable);
  });
  await viewableData.finish();
  dataVizExtn.addViewables(viewableData);
  const showViewables = true;
  const enableOcclusion = false;
  dataVizExtn.showHideViewables(showViewables, enableOcclusion);
};

const showSprites = async () => {
  const dataVizExtn = await viewer.getExtension('Autodesk.DataVisualization');
  console.log('dataVizExtn showSprites', dataVizExtn);
  const showViewables = true;
  const enableOcclusion = false;
  dataVizExtn.showHideViewables(showViewables, enableOcclusion);
};

const hideSprites = async () => {
  const dataVizExtn = await viewer.getExtension('Autodesk.DataVisualization');
  console.log('dataVizExtn hideSprites', dataVizExtn);
  const showViewables = false;
  const enableOcclusion = false;
  dataVizExtn.showHideViewables(showViewables, enableOcclusion);
};

const SpritesHelpers = {
  handlePaintSprites,
  areSpritesCreated,
  showSprites,
  hideSprites,
};

export default SpritesHelpers;
