import axios from 'axios';

export const getProviderFormatByFormatAndProvider = async (formatId, providerId) => {
  try {
    const res = await axios.get(`/api/v1/providerformat/provider/${providerId}/format/${formatId}`);
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllProviderFormatsAvailableByFormatId = async (formatId, filters = {}) => {
  console.log('formatId', formatId);
  try {
    const res = await axios.get(`/api/v1/providerformat/providersbyformat/${formatId}`, {
      params: {
        ...filters,
      },
    });
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};
