import { Tooltip } from 'react-tooltip';
import { MdKeyboardArrowRight } from 'react-icons/md';

const BreadcrumbCategories = ({ categorySelected, handleReset, handleMoveBack }) => {
  return (
    <div className="breadcrumb-container">
      {categorySelected.length > 0 ? (
        <div onClick={handleReset} className="breadcrumb-container">
          <span className="pointer fs-12">Todo el catálogo</span>
          <MdKeyboardArrowRight />
        </div>
      ) : (
        <span className="fs-12">Todo el catálogo</span>
      )}
      {categorySelected.length > 0 &&
        categorySelected.map((el, index) => {
          const categoryName = el.name;
          return (
            <span
              key={el._id}
              className={`breadcrumb-container ${index !== categorySelected.length - 1 && 'pointer'} `}
              onClick={() => {
                if (index === categorySelected.length - 1) return;
                handleMoveBack(el);
              }}
            >
              <span className={`fs-12 all-name-tooltip-${el._id}`}>{categoryName}</span>
              {index !== categorySelected.length - 1 && <MdKeyboardArrowRight />}
              <Tooltip title={el.name} placement="bottom">
                <span>{el.name}</span>
              </Tooltip>
            </span>
          );
        })}
    </div>
  );
};

export default BreadcrumbCategories;
