import { TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { ArteFinalContext } from '../../../context/ArteFinalContext';
import { ShoppingCartContext } from '../../../context/ShoppingCartContext';

const CartItemAddComment = ({ item, formato }) => {
  const { shoppingCart, setShoppingCart } = useContext(ArteFinalContext);
  const { setShoppingCart_v2, shoppingCart_v2 } = useContext(ShoppingCartContext);
  const [inputValue, setInputValue] = useState(shoppingCart[item.id]?.formatos[formato.formatoId]?.comment || '');

  const handleOnChangeInput = (e) => {
    // setInputValue(e.target.value);
    // let copyShoppingCart = { ...shoppingCart };
    // copyShoppingCart[item.id].formatos[formato.formatoId].comment = e.target.value;
    // setShoppingCart(copyShoppingCart);

    /* -------------------------------------------------------------------------- */
    /*                                    v_2                                     */
    /* -------------------------------------------------------------------------- */
    setInputValue(e.target.value);
    let copyShoppingCart_v2 = { ...shoppingCart_v2 };
    copyShoppingCart_v2[item.id].formatos[formato.formatoId].comment = e.target.value;
    setShoppingCart_v2(copyShoppingCart_v2);
  };

  return (
    <div style={{ width: '500px', marginTop: '.5rem' }}>
      <TextField
        id="text-field-comment-order-item"
        label="Comentario"
        multiline
        rows={3}
        value={inputValue}
        onChange={handleOnChangeInput}
        style={{ width: '100%' }}
        inputProps={{ maxLength: 255 }}
      />
      <div style={{ display: 'flex', gap: '.5rem', justifyContent: 'flex-end', marginTop: '.5rem' }}></div>
    </div>
  );
};

export default CartItemAddComment;
