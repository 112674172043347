import CategoryRow from './CategoryRow';
import { Outlet } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Suspense } from 'react';
import SkeletonLoader from '../../common/SkeletonLoader';
import { useCategories } from '../../../hooks/useCategory';
import HeadingGestionCategorias from './HeadingGestionCategorias';

const GestionCategorias = () => {
  const { categories, isLoading } = useCategories();

  if (isLoading) return;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container-master-user">
        <HeadingGestionCategorias />
        <div className="grid-clusters-centers-container">
          <div />
          <div className="gestion-container-categorias">
            <Suspense fallback={<SkeletonLoader count={categories.data.length} minHeight="35.69px" />}>
              {categories.data.map((category) => (
                <CategoryRow key={category._id} category={category} />
              ))}
            </Suspense>
          </div>
        </div>
      </div>
      <Outlet />
    </DndProvider>
  );
};

export default GestionCategorias;
