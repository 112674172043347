import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { createCenter } from '../../../api/carteleriaApi';
import { useQueryClient } from '@tanstack/react-query';

function useCreateCenter() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const { mutate: postCenter, isLoading } = useMutation({
    mutationFn: async (body) => {
      const token = await getAccessTokenSilently();
      return await createCenter(token, body);
    },
    onSuccess: () => {
      toast.success('Se ha creado el centro con éxito.');
      queryClient.invalidateQueries(['allCenters']);
    },
    onError: () => {
      toast.error('Se ha producido un error al crear el centro.');
    },
  });
  return { postCenter, isLoading };
}

export default useCreateCenter;
