import axios from 'axios';
import toast from 'react-hot-toast';

export const getGestionMateriales = async (params) => {
  try {
    const data = await axios.get('/api/v1/material/gestionmateriales', {
      params: params,
    });

    return data.data;
  } catch (err) {
    console.error('Error al traer los materiales para gestionar: ', err.message);
  }
};

export const deleteMaterial = async (materialId) => {
  try {
    await axios.patch(`/api/v1/material/${materialId}/delete`);
    toast.success('Material eliminado exitosamente');
  } catch (err) {
    throw new Error(err.response?.data?.status || err.message);
  }
};

export const getMaterial = async (materialId) => {
  try {
    const data = await axios.get(`/api/v1/material/${materialId}`);
    return data.data;
  } catch (err) {
    console.error('Error al traer el material: ', err.message);
  }
};

export const createMaterial = async (material) => {
  try {
    await axios.post('/api/v1/material', material);
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const editMaterial = async (material) => {
  try {
    const { _id, ...rest } = material;
    await axios.patch(`/api/v1/material/${_id}`, rest);
  } catch (err) {
    throw new Error(err.response?.data?.status || err.message);
  }
};

export const getAaffByMaterial = async (materialId) => {
  try {
    const data = await axios.get(`/api/v1/material/${materialId}/aaff`);
    return data.data;
  } catch (err) {
    console.error('Error al traer los aaff asociados al material: ', err.message);
  }
};
