import { Autocomplete, Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useGetProviders } from '../../../lib/react-query/providerQuery';

const FormProviderAsociate = ({ formik, goBack, custom }) => {
  const { providers, loadingProviders } = useGetProviders();

  const handleAutocompleteChange = (event, newValue) => {
    formik.setFieldValue('provider', newValue ? newValue._id : '');
  };

  const handlePriceChange = (event) => {
    const value = event.target.value;
    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
      formik.setFieldValue('price', value);
    }
  };

  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        align="center"
        sx={{ fontWeight: 'bold', marginBottom: '1.5rem', backgroundColor: '#3C4145', color: 'white' }}
      >
        {`Asociar proveedor al formato ${custom ? 'personalizable' : ''}`}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="code"
              name="code"
              label="Código *"
              value={formik.values.code}
              onChange={formik.handleChange}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </Grid>
          {!custom && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="price"
                label="Precio *"
                value={formik.values.price}
                onChange={handlePriceChange}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                options={loadingProviders ? [] : providers?.data || []}
                getOptionLabel={(option) => option.shortName || ''}
                value={providers?.data?.find((provider) => provider._id === formik.values.provider) || null}
                onChange={handleAutocompleteChange}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                renderInput={(params) => (
                  <TextField required {...params} label="Proveedor" variant="outlined" fullWidth />
                )}
                noOptionsText="Sin material"
                loading={loadingProviders}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
          <Button type="submit" variant="contained" style={{ backgroundColor: '#3C4145' }} endIcon={<SendIcon />}>
            Crear
          </Button>
          <Button variant="contained" color="error" endIcon={<CloseIcon />} onClick={goBack}>
            {custom ? 'Cancelar' : 'Atras'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default FormProviderAsociate;
