import { Button, Card, Modal, Typography } from '@mui/material';
import { CenterOptions } from '../Centers/CenterOptions';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import StoreIcon from '@mui/icons-material/Store';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ConfirmChangeCenter } from './ConfirmChangeCenter';
import { useContext } from 'react';
import { getCentersByAuth0Id } from '../../api/userApi';
import toast from 'react-hot-toast';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';

export const CenterSelected = ({
  isTiendaModalOpen,
  toggleTiendaModal,
  centerConfirmed,
  setCenterConfirmed,
  autocompleteValue,
  setAutocompleteValue,
}) => {
  const [changeCenter, setChangeCenter] = useState(false);
  const [changeCenterPopover, setChangeCenterPopover] = useState(null);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  // const { setShoppingCart } = useContext(ArteFinalContext);
  const { setShoppingCart_v2 } = useContext(ShoppingCartContext);

  const res = queryClient.getQueryData(['AllCenters']);

  const { data } = useQuery(
    ['AllCenters'],
    res
      ? null
      : async () => {
          const token = await getAccessTokenSilently();

          return getCentersByAuth0Id(token);
        },
  );

  //* Popover
  const handleOpenChangeCenterPopover = (event) => {
    setChangeCenterPopover(event.currentTarget);
  };

  const handleCloseChangeCenterPopover = () => {
    setChangeCenterPopover(null);
  };
  //* Popver fin

  const handleChangeCenter = () => {
    setChangeCenter(true);
  };

  const handleConfirmChangeCenter = async () => {
    setChangeCenter(false);
    toggleTiendaModal();
    handleCloseChangeCenterPopover();
    setCenterConfirmed(autocompleteValue);
    // setShoppingCart({});
    setShoppingCart_v2({});
    toast.success('Cambio realizado correctamente');

    // const artesFinales = queryClient.getQueryData(['all-artes-finales-carteleria']);
    // if (artesFinales) {
    //   queryClient.invalidateQueries(['all-artes-finales-carteleria']);
    // }
  };

  const handleButtonCancel = () => {
    setChangeCenter(false);
    toggleTiendaModal();
  };

  return (
    <Modal
      open={isTiendaModalOpen}
      onClose={() => toggleTiendaModal()}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div style={{ display: 'flex' }}>
        {/* <div>
          <IconButton
            // TODO: meter el icono de cerrar en el modal
            // edge="end"
            color="red"
            onClick={toggleTiendaModal}
            aria-label="close"
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '350px',
              right: '670px',
              zIndex: 1,
            }}
          >
            <CloseIcon style={{ backgroundColor: 'white', borderRadius: 28 }} />
          </IconButton>
        </div> */}

        <div style={{ width: 500, padding: 55, backgroundColor: 'white', borderRadius: 8 }}>
          <Card style={{ padding: 25, marginBottom: 16 }} raised={true}>
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              Tu tienda seleccionada{' '}
            </Typography>

            <Typography variant="h6" style={{ textAlign: 'center' }}>
              <StoreIcon style={{ marginRight: '1rem' }} />
              {centerConfirmed?.name || centerConfirmed?.calle} - {centerConfirmed?.code}
            </Typography>
          </Card>

          <Button variant="outlined" color="error" fullWidth style={{ gap: '4rem' }} onClick={handleChangeCenter}>
            Cambiar tienda
          </Button>

          {changeCenter && (
            <div style={{ marginTop: '4rem' }}>
              <CenterOptions
                data={data}
                autocompleteValue={autocompleteValue}
                setAutocompleteValue={setAutocompleteValue}
              />
              <div style={{ padding: '16px', textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleOpenChangeCenterPopover}
                  style={{ margin: '8px' }}
                >
                  Confirmar
                </Button>
                <Button variant="contained" onClick={handleButtonCancel} style={{ margin: '8px', background: '#333' }}>
                  Cancelar
                </Button>
              </div>
            </div>
          )}

          <ConfirmChangeCenter
            changeCenterPopover={changeCenterPopover}
            handleCloseChangeCenterPopover={handleCloseChangeCenterPopover}
            handleConfirmChangeCenter={handleConfirmChangeCenter}
          />
        </div>
      </div>
    </Modal>
  );
};
