import { Pagination, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useGetNoOrders } from '../../../lib/react-query/statsQuery';
import { StyledTableCell } from '../../RecycledComponents';
import { formatDate } from '../../../helpers/Utils';

const NoOrders = () => {
  const { data: articulos, isLoading, page, setPage } = useGetNoOrders();

  if (isLoading) return <div className="mt-3 custom-loader" />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Código</StyledTableCell>
              <StyledTableCell>Artículo</StyledTableCell>
              <StyledTableCell>Fecha de creación</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {articulos.data.map((articulo) => (
              <TableRow key={articulo._id}>
                <StyledTableCell>{articulo.code}</StyledTableCell>
                <StyledTableCell>{articulo.name}</StyledTableCell>
                <StyledTableCell>{formatDate(articulo.createdAt)}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {articulos.total > 10 && (
        <Pagination
          showFirstButton
          showLastButton
          page={page}
          onChange={(e, v) => setPage(v)}
          count={Math.ceil(articulos.total / 10)}
          color="error"
          variant="outlined"
          size="large"
          sx={{ margin: '2rem', display: 'flex', justifyContent: 'center' }}
        />
      )}
    </>
  );
};

export default NoOrders;
