import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Page404 from '../Components2/Page404/Page404';
import { UserContext } from '../context/UserProvider';
import TabsNavigate from '../Components2/Admin/tabs/TabsNavigate';

export const ProtectedRolAdmin = ({ requiredRole }) => {
  const { userRole } = useContext(UserContext);

  return (
    <div style={{ flex: 1 }}>
      {requiredRole.includes(userRole) ? (
        <>
          <TabsNavigate />
          <Outlet />
        </>
      ) : (
        <Page404 />
      )}
    </div>
  );
};
