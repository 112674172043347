import { useQuery } from '@tanstack/react-query';
import { getAllCenterTypes } from '../api/carteleriaApi';
import { useAuth0 } from '@auth0/auth0-react';

export const useGetAllCenterTypes = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['centerTypes'],
    queryFn: async () => {
      let token = await getAccessTokenSilently();
      return getAllCenterTypes(token, { sort: 'index' });
    },
  });
};
