import React from 'react';
import DbContentArteFinal from './ArteFinal/DbContentArteFinal';
import DbContentTienda from './Tienda/DbContentTienda';

const ContainerDb = ({
  isLoading,
  data,
  setPage,
  dataArray,
  setDataArray,
  isFetching,
  dataSelected,
  setDataSelected,
  tienda,
  compuesto,
}) => {
  return (
    <div style={{ width: !compuesto && '45%' }}>
      {!compuesto && (
        <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <h5 style={{ display: 'flex', justifyContent: 'center' }}>
            {tienda ? 'Centros disponibles' : 'Artes finales disponibles'}
          </h5>

          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              margin: '0',
              fontStyle: 'italic',
              height: '40px',
            }}
          >
            {tienda
              ? '(Haz clic en un cluster para añadir todos sus centros o en un centro para añadirlo a la lista de seleccionados )'
              : '(Haz clic en un formato para añadirlo a la lista de seleccionados)'}
          </p>
        </div>
      )}
      <div
        style={{
          border: '1px solid #cecece',
          height: '300px',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
        // cada vez que hagamos scroll antes de llegar a los ultimos items seteamos la pagina a la siguiente
        onScroll={(e) => {
          // Selecciona un valor para el umbral (en píxeles) antes de llegar al final
          if (tienda || isLoading || isFetching) return;

          const scrollThreshold = 50; // Por ejemplo, 50 píxeles antes del final

          if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + scrollThreshold) {
            if (data?.data?.length > 0) {
              setPage((prev) => prev + 1);
            }
          }
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {!tienda ? (
              <DbContentArteFinal
                aaffArray={dataArray}
                aaffSelected={dataSelected}
                setAaffArray={setDataArray}
                setAaffSelected={setDataSelected}
                isLoading={isFetching || isLoading}
              />
            ) : (
              <DbContentTienda
                tiendaArray={dataArray}
                setTiendaArray={setDataArray}
                tiendaSelected={dataSelected}
                setTiendaSelected={setDataSelected}
              />
            )}
            {(isLoading || isFetching) && (
              <div className="loader-container">
                <div className="loader-aaff-pedido-pilotado">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerDb;
