import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { getPaginateClusters } from '../../../api/carteleriaApi';
import { GestionClusterContext } from '../../../context/GestionClusterContext';

const limit = 12;
export function useGetAllCenterTypesPaginated() {
  const { inputValueSearch } = useContext(GestionClusterContext);
  const [activeFilter, setActiveFilter] = useState('all');
  const [comunidadFilter, setComunidadFilter] = useState('all');
  const [page, setPage] = useState(1);
  const [centerType, setCenterType] = useState([]);
  const [filteredIds, setFilteredIds] = useState(null);

  const { getAccessTokenSilently } = useAuth0();
  const { data: centerTypesPaginated, isLoading } = useQuery(
    ['allCentersTypes', page, limit, filteredIds, centerType, activeFilter, comunidadFilter, inputValueSearch],
    async () => {
      const token = await getAccessTokenSilently();
      return await getPaginateClusters(token, {
        page,
        limit,
        filteredIds,
        centerType,
        activeFilter,
        comunidadFilter,
        inputValueSearch,
      });
    },
  );

  return {
    centerTypesPaginated,
    isLoading,
    limit,
    setPage,
    page,
    filteredIds,
    setFilteredIds,
    centerType,
    setCenterType,
    setActiveFilter,
    setComunidadFilter,
    activeFilter,
    comunidadFilter,
  };
}
