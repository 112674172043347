import { useContext } from 'react';
import { useMateriales } from '../../../hooks/useMateriales';
import { Buscador } from '../../Pedido/Buscador';
import { GestionMaterialContext } from '../../../context/GestionMaterialContext';
import { handleChangeInputSearch, handleClearInput, handleKeyDown } from '../../../helpers/buscadorHelper';
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Pagination, Select } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Outlet, useNavigate } from 'react-router-dom';
import TableMateriales from './TableMateriales';

const GestionMateriales = () => {
  const navigate = useNavigate();
  const { inputAfter, setInputAfter, setInputValueSearch } = useContext(GestionMaterialContext);
  const { materiales, isLoading, limit, page, setPage, setActiveFilter, activeFilter } = useMateriales();

  const handleActiveFilterChange = (event) => {
    setActiveFilter(event.target.value);
    setPage(1);
  };

  const handlePagePagination = (event, value = 1) => {
    setPage(value);
  };

  return (
    <>
      <div className="container-master-user">
        <div className="grid-clusters-centers-container">
          <div className="grid-item">
            <h2 className="f-w-600">MATERIALES</h2>
          </div>
          <div className="grid-item-clusters-centers-search">
            <Buscador
              value={inputAfter}
              width="85"
              onChange={(e) => handleChangeInputSearch(e, setInputAfter)}
              onKeyDown={(e) => handleKeyDown(e, setInputValueSearch)}
              onClear={() => handleClearInput(setInputValueSearch, setInputAfter)}
            />
            <Button
              variant="contained"
              color="error"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => navigate('add-material')}
              aria-label="add"
              style={{
                height: '2.5rem',
              }}
            >
              Crear Material
            </Button>
          </div>
        </div>
        <div className="grid-clusters-centers-container">
          <div className="options options-filter">
            <h4 style={{ fontWeight: 'bold' }}>Filtros</h4>
            <FormControl sx={{ maxWidth: 300, marginBottom: 2 }}>
              <InputLabel htmlFor="activeFilter">Filtrar por Activo</InputLabel>
              <Select
                id="activeFilter"
                value={activeFilter}
                onChange={handleActiveFilterChange}
                input={<OutlinedInput label="Filtrar por Activo" />}
              >
                <MenuItem value="all">
                  <em>Todos</em>
                </MenuItem>
                <MenuItem value={true}>Activos</MenuItem>
                <MenuItem value={false}>Inactivos</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="grid-item">
            {!isLoading ? (
              <div className="core">
                <TableMateriales materiales={materiales?.data} />
              </div>
            ) : (
              <div className="custom-loader"></div>
            )}
            {/* </div> */}
          </div>
        </div>

        {materiales?.data?.length > 0 && (
          <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <Pagination
              showFirstButton
              showLastButton
              page={page}
              onChange={handlePagePagination}
              count={Math.ceil(materiales?.pagination?.totalItems / limit)}
              color="error"
              variant="outlined"
              size="large"
              sx={{ margin: '2rem' }}
            />
          </div>
        )}
      </div>
      <Outlet />
    </>
  );
};

export default GestionMateriales;
