import { useEditProvider } from '../../../hooks/useProvider';
import { useEffect, useState } from 'react';
import FormProveedor from './FormProveedor';
import { handleCloseModal, handleSubmitEditProveedor } from '../../../helpers/Proveedor/proveedorHelper';

const EditProveedor = () => {
  const { provider, isLoading, mutation, navigate, location } = useEditProvider();
  const [formData, setFormData] = useState({
    _id: '',
    code: '',
    name: '',
    active: true,
  });

  useEffect(() => {
    if (!isLoading) {
      setFormData({
        _id: provider.data._id,
        code: provider.data.code,
        name: provider.data.name,
        shortName: provider.data.shortName,
        active: provider.data.active,
      });
    }
  }, [isLoading]);

  if (isLoading) return;

  return (
    <FormProveedor
      title="Editar proveedor"
      handleSubmit={(e) => handleSubmitEditProveedor({ e, mutation, formData, navigate, location })}
      formData={formData}
      handleChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
      handleCloseModal={() => handleCloseModal(navigate, location, 2)}
    />
  );
};

export default EditProveedor;
