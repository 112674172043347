import { useContext } from 'react';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import AaffCompuesto from './AaffCompuesto';
import AaffNoCompuesto from './AaffNoCompuesto';
import FooterCart from './FooterCart';

const CartContainer_v2 = () => {
  const { shoppingCart_v2, aaffCompuestos, aaffNoCompuestos } = useContext(ShoppingCartContext);

  if (
    !shoppingCart_v2 ||
    Object?.entries(shoppingCart_v2)?.length == 0 ||
    (Object?.entries(shoppingCart_v2)?.length == 0) == undefined
  )
    return (
      <div className="not-elements-container">
        <h2 style={{ textAlign: 'center' }}>No hay elementos en la cesta</h2>
      </div>
    );

  return (
    <div className="not-elements-container">
      {Object.entries(aaffCompuestos).map(([idPack, aaffCompuesto]) => (
        <AaffCompuesto key={idPack + aaffCompuesto.id} idPack={idPack} aaffCompuesto={aaffCompuesto} />
      ))}
      {aaffNoCompuestos.map((aaffNoCompuesto) => (
        <AaffNoCompuesto key={aaffNoCompuesto.id} aaffNoCompuesto={aaffNoCompuesto} />
      ))}
      <FooterCart />
    </div>
  );
};

export default CartContainer_v2;
