import { useParams } from 'react-router-dom';
import { useHandleCloseModal } from '../../../hooks/useHelpers';
import { useEditGuiaMerchandising, useGetGuiaMerchandising } from '../../../lib/react-query/fileQuery';
import { useEffect, useState } from 'react';
import FormFile from './FormFile';

const EditMerchandising = () => {
  const { idFile } = useParams();
  const { handleCloseModal } = useHandleCloseModal(3);
  const { mutation } = useEditGuiaMerchandising();
  const { data: guia, isLoading } = useGetGuiaMerchandising(idFile);

  const [formData, setFormData] = useState({
    _id: '',
    name: '',
    url: '',
    file: null,
    type: '',
  });

  useEffect(() => {
    if (!isLoading) {
      setFormData({
        _id: guia.data._id,
        name: guia.data.name,
        url: guia.data.link,
        file: null,
        type: guia.data.link.includes('digitaloceanspaces') ? 'archivo' : 'link',
      });
    }
  }, [isLoading]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      url: guia?.data?.link || '',
      file: null,
    }));
  }, [formData.type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(formData);
  };

  return (
    <FormFile
      title={'Editar guía de merchandising'}
      isLoading={isLoading}
      formData={formData}
      handleChange={handleChange}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
    />
  );
};

export default EditMerchandising;
