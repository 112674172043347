import { Autocomplete, Box, Button, CircularProgress, Modal, Popover, TextField, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import {
  createCapituloProject,
  createMasivePartidasProyecto,
  createPartidaProyecto,
  createQuantityVersion,
  getAllPartidas,
  getAllPartidasByProject,
  getCapituloById,
  getCapituloProjectByCapituloAndProject,
  getPartidaById,
  getRemainingPartidas,
  updateCapituloProject,
  updatePartidaProyecto,
} from '../../api/alcampoApi';
import { ProyectoContext } from '../../context/ProyectoContext';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import PartidaProyecto from '../../helpers/Partida/PartidaProyecto';
import { CapitulosContext } from '../../context/CapitulosContext';
import { PartidasContext } from '../../context/PartidasContext';
import { ViewerContext } from '../../context/ViewerContext';
import { DEFAULT_PROPERTY_BY_UNIT } from '../../constants/viewerValues';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getAllDbIdsByCode } from '../../helpers/viewerHelper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import PreviewAllPartidas from './PreviewAllPartidas';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import pLimit from 'p-limit';
import { useAuth0 } from '@auth0/auth0-react';

const NewPartida = ({ open, handleClose }) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { currentProject, currentVersion } = useContext(ProyectoContext);
  const { capituloSelected } = useContext(CapitulosContext);
  const { partidaQuery } = useContext(PartidasContext);
  const [partidas, setPartidas] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [openSelectParams, setOpenSelectParams] = useState(false);
  const [loading, setLoading] = useState(true);
  const { uniqueProperties, versions } = useContext(ViewerContext);
  const [options, setOptions] = useState(uniqueProperties || null);
  const [propertyChosen, setPropertyChosen] = useState(null);
  const [changeParam, setChangeParam] = useState(false);
  const [paramValue, setParamValue] = useState(null);
  const [unitValue, setUnitValue] = useState(null);
  const [priceValue, setPriceValue] = useState(null); // [TODO
  const [partidaSelected, setPartidaSelected] = useState(null);
  const [changePrice, setChangePrice] = useState(false);
  const [recoverPrice, setRecoverPrice] = useState(null);
  const [allPartidasProject, setAllPartidasProject] = useState(null);
  const [previewQuantity, setPreviewQuantity] = useState(null);
  const [createAllPartidas, setCreateAllPartidas] = useState(false);
  const [loadingSearchAllPartidas, setLoadingSearchAllPartidas] = useState(false);
  const [firstSearch, setFirstSearch] = useState(true);
  const [allPartidasExcludeExists, setAllPartidasExcludeExists] = useState(null);

  const { data, isFetching } = useQuery(['partidas-newpartidaproyecto'], () => {
    return getAccessTokenSilently().then((token) => getAllPartidas(token));
  });

  useEffect(() => {
    console.log('currentVersion where search', currentVersion);
  }, [currentVersion]);
  useEffect(() => {
    console.log('VEAMOS TODAS LAS PARTIDAS A CREAR: ', allPartidasExcludeExists);
  }, [allPartidasExcludeExists]);

  const handleGetAllPartidas = async () => {
    setLoadingSearchAllPartidas(true);
    !firstSearch && setAllPartidasExcludeExists(null);
    const res = await getRemainingPartidas(currentProject._id);

    // Crear un array de promesas para cada partida
    const promises = res.map(async (partida) => {
      const dbIdCodes = await getAllDbIdsByCode(partida.code);
      if (dbIdCodes.length === 0) return null;

      const partidaProyectoInstance = new PartidaProyecto(
        partida.code,
        DEFAULT_PROPERTY_BY_UNIT[partida.unit][0],
        partida.defaultPrice * 1,
      );
      const totalQuantity = await partidaProyectoInstance.getTotalQuantity();

      return {
        label: `${partida.code} - ${partida.name}`,
        value: partida._id,
        unit: partida.unit,
        param: DEFAULT_PROPERTY_BY_UNIT[partida.unit][0],
        price: partida.defaultPrice,
        code: partida.code,
        previewQuantity: totalQuantity || 0,
      };
    });

    // Esperar a que todas las promesas se resuelvan
    const results = await Promise.all(promises);

    // Filtrar resultados para eliminar las partidas que no deben ser agregadas
    const validResults = results.filter((result) => result !== null);

    setAllPartidasExcludeExists((prev) => [...(prev || []), ...validResults]);

    setLoadingSearchAllPartidas(false);
    setFirstSearch(false);
  };

  useEffect(() => {
    if (uniqueProperties) {
      setOptions([{ displayName: 'Unidad', unit: 'ud' }, ...uniqueProperties]);
      setLoading(false);
    }
  }, [uniqueProperties]);

  // USEEFFECT PARA TRAER TODAS LAS PARTIDAS DE ESTE PROYECTO
  useEffect(() => {
    const getPartidasProject = async () => {
      let token = await getAccessTokenSilently();
      const res = await getAllPartidasByProject(currentProject._id, token);
      setAllPartidasProject(res);
    };
    getPartidasProject();
  }, []);

  const formik = useFormik({
    initialValues: {
      proyecto: currentProject._id,
      partida: '',
      param: '',
      unit: '',
      price: '',
      //   descompuestosPartida: '',
    },
    validationSchema: !createAllPartidas
      ? yup.object({
          //   price: yup.number().required('El precio es obligatorio'),
          partida: yup.string().required('La partida es obligatoria'),
        })
      : '',
    onSubmit: async (values) => {
      if (createAllPartidas) {
        // AHI VAMOS A LLAMAR A LA FUNCION QUE CREA TODAS LAS PARTIDAS A LA VEZ
        submitMutateCreateAll();
        console.log('VEAMOS LOS VALUES: ', values);
      } else {
        const partidaExists = await getAllDbIdsByCode(partidaSelected.code);
        if (partidaExists.length == 0) {
          toast.error('No se ha encontrado la partida en el modelo', {
            id: `no-partida-modelo-${partidaSelected.code}`,
          });
        } else {
          console.log('VEAMOS LOS VALUES: ', values);
          submitMutate(values);
        }
      }
    },
  });

  const submitMutateCreateAll = () => {
    submitMutationCreateAll.mutate();
  };

  const submitMutate = (values) => {
    submitMutation.mutate(values);
  };

  // const handleSubmitCreateAll = async () => {
  //   setLoadingCreate(true);

  //   // Define un límite de 50 promesas concurrentes
  //   const limit = pLimit(20);

  //   const slicedPartidas = allPartidasExcludeExists.slice(0, 20); // Obtén las primeras 20 partidas

  //   const promises = slicedPartidas.map(async (partida) => {
  //     const values = {
  //       proyecto: currentProject._id,
  //       partida: partida.value,
  //       param: partida.param,
  //       unit: partida.unit,
  //       price: partida.price,
  //     };

  //     await limit(() => handleSubmitCreate(values));
  //   });

  //   await Promise.all(promises);
  //   setLoadingCreate(false);
  // };

  const handleSubmitCreateAll = async () => {
    setLoadingCreate(true);
    let token = await getAccessTokenSilently();

    //TODO AQUI TENDRAS QUE LLAMAR A LA FUNCION DEL BACK QUE TE CREA TODAS LAS PARTIDAS PROYECTO Y HACE LAS COMPROBACIONES NECESARIAS AL IGUAL QUE AL CREAR UNA PARTIDA
    // LA DATA ES ESTE ARRAY: allPartidasExcludeExists, recorrerlo y setear cada partida igual que en el for de abajo, para tener todo limpio

    const formatPartidas = allPartidasExcludeExists.map((partida) => {
      return {
        proyecto: currentProject._id,
        partida: partida.value,
        param: partida.param,
        unit: partida.unit,
        price: partida.price,
        quantity: partida.previewQuantity,
        version: currentVersion._id,
      };
    });

    console.log('Brisbaneee', formatPartidas);
    const res = await createMasivePartidasProyecto(formatPartidas, currentProject._id, token);

    // for (const partida of allPartidasExcludeExists) {
    //   const values = {
    //     proyecto: currentProject._id,
    //     partida: partida.value,
    //     param: partida.param,
    //     unit: partida.unit,
    //     price: partida.price,
    //   };

    //   await handleSubmitCreate(values);

    // }

    setLoadingCreate(false);
  };

  const success = () => {
    toast.success('Partida/s creada correctamente');
    queryClient.invalidateQueries(['capitulosProyecto', currentProject?._id, capituloSelected?._id || 'all']);
    queryClient.invalidateQueries(['current-partidas-project', currentProject._id]);
    queryClient.invalidateQueries(['price-project', currentProject._id]);
    handleClose();
    setLoadingCreate(false);
  };

  const error = () => {
    toast.error('Ha ocurrido un error');
    setLoadingCreate(false);
  };

  // TODO ESTA ES LA FUNCION DONDE SE CREA UNA PARTIDA PROYECTO Y AQUI ESTA TODO LO NECESARIO PARA SU CREACION
  const handleSubmitCreate = async (values) => {
    setLoadingCreate(true);
    let token = await getAccessTokenSilently();
    const res = await createPartidaProyecto(values, token);
    // TRAER LA PARTIDA PARA PASAR EL CODE LA UNIT Y EL PRICE O DEFAULTPRICE AL CONSTRUCTOR
    console.log('PP', res);
    const partida = await getPartidaById(res.partida, token);
    console.log('partida asociada a la PP', partida);

    const partidaProyectoInstance = new PartidaProyecto(partida.code, res.param, res.price);
    // const pricePartidaProyecto = await partidaProyectoInstance.getTotalAmount();

    const totalQuantity = await partidaProyectoInstance.getTotalQuantity();
    console.log('totalQuantity', totalQuantity);
    // creamos la quantity con el totalQuantity y la referencia de la version
    const resQuantity = await createQuantityVersion({
      partidaProyecto: res._id,
      quantity: totalQuantity || 0,
      version: currentVersion._id,
    });

    console.log('resQuantity', resQuantity);

    // AHORA QUEDA VER SI LA PARTIDA TIENE PARENT CAPITULO Y SI ES ASÍ COMPROBAR SI ESTE ESTA ASIGNADO
    // LA COLECCIÓN DE CAPITULO PROYECTO Y SI NO LO ESTA COMPROBAR SI ESTE CAPITULO TIENE PARENT CAPITULO
    // LO MISMO QUE ARRIBA Y CREAR LOS DOS PARA ASIGNAR A LA PARTIDA PROYECTO EL CAPITULO PROYECTO CORRESPONDIENTE
    if (partida?.parentCapitulo) {
      let parentCapituloPartidaProyecto = null;
      console.log('VEAMOS EL CAPITULO: ', partida.parentCapitulo);
      const capituloProjectPartidaProyecto = await getCapituloProjectByCapituloAndProject(
        partida.parentCapitulo,
        currentProject._id,
        token,
      );
      console.log('parentCapituloPartidaProyecto', capituloProjectPartidaProyecto);

      if (!capituloProjectPartidaProyecto) {
        // SI NO HAY IR A BUSCAR EL PARENT CAPITULO COMPROBAR SI ESTA TIENE OTRO PARENT CAPITULO Y VOLVER A COMPROBAR LO MISMO

        console.log('CREO QUE AQUI ES DONDE PETA: ', currentProject._id);
        console.log(partida.parentCapitulo);

        parentCapituloPartidaProyecto = await createCapituloProject(
          {
            proyecto: currentProject._id,
            capitulo: partida.parentCapitulo,
          },
          token,
        );

        console.log('parentCapituloPartidaProyecto', parentCapituloPartidaProyecto);

        const capituloCapituloProjectPartidaProyecto = await getCapituloById(partida.parentCapitulo, token);

        console.log('VEAMOS EL CAPITULO POR ID: ', capituloCapituloProjectPartidaProyecto);

        // SI NO TIENE PARENT CAPITULOS NO HACEMOS NADA
        if (capituloCapituloProjectPartidaProyecto.parentCapitulo) {
          const parentCapituloCapituloCapituloProjectPartidaProyecto = await getCapituloProjectByCapituloAndProject(
            capituloCapituloProjectPartidaProyecto.parentCapitulo,
            currentProject._id,
            token,
          );
          if (!parentCapituloCapituloCapituloProjectPartidaProyecto) {
            // AQUI YA CREARIAMOS EL CAPITULO PROYECTO CON EL PARENT DEL CAPITULO Y LUEGO CON EL PROPIO CAPITULO
            const resParentCapitulo = await createCapituloProject(
              {
                proyecto: currentProject._id,
                capitulo: capituloCapituloProjectPartidaProyecto.parentCapitulo,
                subCapitulos: [parentCapituloPartidaProyecto?._id || capituloProjectPartidaProyecto?._id],
              },
              token,
            );

            // actualizo el subcapitulo con el parent
            await updateCapituloProject(
              parentCapituloPartidaProyecto?._id || capituloProjectPartidaProyecto?._id,
              {
                parentCapitulo: resParentCapitulo._id,
              },
              token,
            );
          } else {
            // actualizo el subcapitulo con el parent
            await updateCapituloProject(
              parentCapituloPartidaProyecto?._id || capituloProjectPartidaProyecto?._id,
              {
                parentCapitulo: parentCapituloCapituloCapituloProjectPartidaProyecto?._id,
              },
              token,
            );
          }
        }
      }

      await updatePartidaProyecto(
        res._id,
        {
          quantity: resQuantity.data._id,
          // price: pricePartidaProyecto,
          capitulosProyecto: [parentCapituloPartidaProyecto?._id || capituloProjectPartidaProyecto?._id],
        },
        token,
      );
    } else {
      await updatePartidaProyecto(
        res._id,
        {
          quantity: resQuantity.data._id,
          // price: pricePartidaProyecto,
        },
        token,
      );
    }
    //ACTUALIZAR LA PARTIDA PROYECTO CREADA PARA AÑADIRLE LA QUANTITY Y ASIGNARLE EL CAPITULO PROYECTO
  };

  const submitMutation = useMutation({
    mutationFn: handleSubmitCreate,
    onSuccess: success,
    onError: error,
  });

  const submitMutationCreateAll = useMutation({
    mutationFn: handleSubmitCreateAll,
    onSuccess: success,
    onError: error,
  });

  useEffect(() => {
    if (data && allPartidasProject) {
      data.data.map((partida) => {
        // si la partida ya está en el proyecto, no la añadimos
        if (allPartidasProject?.find((partidaProject) => partidaProject.partida._id === partida._id)) return;
        setPartidas((prev) => [
          ...prev,
          {
            label: `${partida.code} - ${partida.name}`,
            value: partida._id,
            unit: partida.unit,
            price: partida.defaultPrice,
            code: partida.code,
          },
        ]);
      });
    }
  }, [data, allPartidasProject]);

  useEffect(() => {
    if (propertyChosen) {
      setParamValue(propertyChosen?.displayName);
      setUnitValue(propertyChosen?.unit);
    } else if (partidaSelected) {
      setParamValue(DEFAULT_PROPERTY_BY_UNIT[partidaSelected.unit][0]);
      setUnitValue(partidaSelected.unit);
    }
  }, [partidaSelected, propertyChosen]);

  useEffect(() => {
    if (partidaSelected) {
      getAllDbIdsByCode(partidaSelected.code);
    }
    setPriceValue(partidaSelected?.price || 0);
  }, [partidaSelected]);

  useEffect(() => {
    formik.setFieldValue('param', paramValue);
    formik.setFieldValue('unit', unitValue);
    formik.setFieldValue('price', priceValue);
  }, [paramValue, unitValue, priceValue]);

  useEffect(() => {
    const getQuantity = async () => {
      const partidaProyectoInstance = new PartidaProyecto(partidaSelected.code, paramValue, priceValue * 1);
      const totalQuantity = await partidaProyectoInstance.getTotalQuantity();
      setPreviewQuantity(totalQuantity || 0);
    };

    if (paramValue && partidaSelected) {
      getQuantity();
    } else {
      setPreviewQuantity(null);
    }
  }, [paramValue, partidaSelected]);

  useEffect(() => {
    console.log('allPartidasExcludeExists', allPartidasExcludeExists);
  }, [allPartidasExcludeExists]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        marginTop: '1rem',
        // position: 'relative',
        // alignItems: 'center',
      }}
    >
      <Box className="container-box-newpartida">
        <div className="close-modal-newpartida" onClick={handleClose}>
          X
        </div>
        {isFetching ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress style={{ color: 'black' }} />
          </div>
        ) : (
          <>
            {/* ANTES DEL FORMULARIO MENSAJE DE ALERTA POR SI ESTAMOS EN OTRA VERSION QUE NO ES LA MAS RECIENTE */}
            {versions?.length > 0 && currentVersion?.version !== versions[0].attributes.versionNumber && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '1rem',
                  border: '1px solid #A3CFE7',
                  borderRadius: '4px',
                  backgroundColor: '#CDE8F5',
                  gap: '.6rem',
                  marginBottom: '1rem',
                }}
              >
                {/* sacar la version mas reciente y si la current version no corresponde mostrar el texto */}
                <InfoOutlinedIcon style={{ color: '#3A74A1' }} />
                <p className="m-0" style={{ color: '#3A74A1' }}>
                  Tenga en cuenta que al esta en una versión inferior del modelo, va a crear la partida para dicha
                  versión
                </p>
              </div>
            )}

            <form style={{ display: 'flex', flexDirection: 'column', gap: '.6rem' }} onSubmit={formik.handleSubmit}>
              <Autocomplete
                onChange={(e, va) => {
                  if (va?.value === 'all') {
                    setChangeParam(false);
                    setPropertyChosen(null);
                    formik.setFieldValue('partida', '');
                    setPartidaSelected(null);
                    setCreateAllPartidas(true);
                    return;
                  }
                  setCreateAllPartidas(false);
                  setPartidaSelected(va);
                  formik.setFieldValue('partida', va?.value || '');
                  if (!va?.value) {
                    setChangeParam(false);
                    setPropertyChosen(null);
                  }
                }}
                options={[
                  {
                    label: 'Crear todas las partidas a la vez',
                    value: 'all',
                  },
                  ...partidas,
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="partida"
                    error={formik.touched.partida && Boolean(formik.errors.partida)}
                    helperText={formik.touched.partida && formik.errors.partida}
                    label="Seleccionar partida"
                  />
                )}
                renderOption={(props, option) => {
                  if (option.value === 'all') {
                    return (
                      <li
                        {...props}
                        style={{
                          backgroundColor: '#F8F3D6',
                          border: '1px solid #E7DFAB',
                          borderRadius: '4px',
                          color: '#967132',
                          paddingTop: '1rem',
                          paddingBottom: '1rem',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ReportProblemOutlinedIcon fontSize="small" style={{ color: '#967132' }} />
                        <span style={{ marginLeft: '8px' }}>Crear todas las partidas a la vez</span>
                      </li>
                    );
                  } else {
                    // Renderiza otras opciones aquí
                    return <li {...props}>{option.label}</li>;
                  }
                }}
              />

              {/* AQUI VA A IR LA PREVIEW DE LA QUANTITY */}
              {(previewQuantity || previewQuantity === 0) && (
                <div>
                  <p className="m-0">
                    {' '}
                    <span style={{ fontWeight: 'bold' }}>Vista previa de la cantidad: </span>
                    {previewQuantity.toLocaleString('es-ES', {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                  </p>
                </div>
              )}

              {/* SI TENEMOS SELECCIONADO QUE VAMOS A CREAR TODAS A LA VEZ MOSTRAMOS MENSAJE DE ALERTA */}
              {createAllPartidas && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1rem',
                    border: '1px solid #A3CFE7',
                    borderRadius: '4px',
                    backgroundColor: '#CDE8F5',
                    gap: '1rem',
                    marginBottom: '1rem',
                    // maxWidth: '504px',
                    flexDirection: 'column',
                  }}
                >
                  {/* sacar la version mas reciente y si la current version no corresponde mostrar el texto */}
                  <div style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                    <InfoOutlinedIcon style={{ color: '#3A74A1' }} />
                    <p className="m-0" style={{ color: '#3A74A1' }}>
                      Esta acción puede llevar algún tiempo. Se buscarán todas las partidas que aún no han sido creadas,
                      y usted podrá modificar el parámetro/unidad de cada una o dejarlas con los valores
                      predeterminados.
                    </p>
                  </div>

                  {firstSearch ? (
                    <Button
                      onClick={handleGetAllPartidas}
                      style={{
                        backgroundColor: 'white',
                        textTransform: 'none',
                        color: '#3A74A1',
                      }}
                      endIcon={loadingSearchAllPartidas && <CircularProgress size={20} style={{ color: '#3A74A1' }} />}
                    >
                      Buscar partidas
                    </Button>
                  ) : (
                    <PopupState variant="popover" popupId="research-popup">
                      {(popupState) => (
                        <>
                          <Button
                            style={{
                              backgroundColor: 'white',
                              textTransform: 'none',
                              color: '#3A74A1',
                            }}
                            {...bindTrigger(popupState)}
                            endIcon={
                              loadingSearchAllPartidas && <CircularProgress size={20} style={{ color: '#3A74A1' }} />
                            }
                          >
                            Reiniciar busqueda
                          </Button>

                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                          >
                            <div style={{ padding: '1rem' }}>
                              <p style={{ fontSize: '.75rem' }}>
                                ¿Estás seguro de que deseas reiniciar la búsqueda? Se perderán todos los datos que hayas
                                modificado.
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: '1rem',
                                  marginTop: '.5rem',
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    fontSize: '.7rem',
                                    padding: '5px 5px',
                                    minWidth: '0',
                                    backgroundColor: '#5c5c5c',
                                  }}
                                  onClick={() => {
                                    // handleRemoveProperty(id);
                                    // addModulo()
                                    popupState.setOpen(false);
                                  }}
                                >
                                  No
                                </Button>
                                <Button
                                  variant="outlined"
                                  style={{
                                    fontSize: '.75rem',
                                    padding: '5px 5px',
                                    minWidth: '0',
                                    background: '#',
                                  }}
                                  onClick={() => {
                                    handleGetAllPartidas();
                                    popupState.setOpen(false);
                                  }}
                                >
                                  Sí
                                </Button>
                              </div>
                            </div>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  )}
                </div>
              )}

              {/* SI HAY CRAETE ALL PARTIDAS Y NO HAY ALL PARTIDAS EXCLUDE EXISTS MOSTRAR MENSAJE DE QUE NO HAY PARTIDAS */}
              {createAllPartidas &&
                allPartidasExcludeExists &&
                (allPartidasExcludeExists.length === 0 ? (
                  <p style={{ color: '#c82333' }}>No hay partidas que crear</p>
                ) : (
                  // aqui va a ir el listado de partidas
                  // <p>{allPartidasExcludeExists?.length}</p>

                  allPartidasExcludeExists.map((partida) => (
                    <PreviewAllPartidas
                      partida={partida}
                      // ahora tenemos que pasar el estado para poder actualizar las porpiedades de cada partida
                      setAllPartidasExcludeExists={setAllPartidasExcludeExists}
                    />
                  ))

                  // allPartidasExcludeExists.map((partida) => (
                  //   // CONTENEDOR QUE SERA EXACTAMENTE IGUAL QUE CADA PARTIDA INDIVIDUAL MENOS CON EL SELECTOR DE PARTIDA
                  //   <div></div>
                  // ))
                ))}

              {/* POSIBILIDAD DE SELECCIONAR PARAMETRO */}
              {changeParam && formik.values.partida && (
                <Autocomplete
                  id="asynchronous-demo"
                  open={openSelectParams}
                  style={{ margin: '1rem 0' }}
                  onOpen={() => {
                    setOpenSelectParams(true);
                  }}
                  onClose={() => {
                    setOpenSelectParams(false);
                  }}
                  required
                  color="secondary"
                  isOptionEqualToValue={(option, property) => option === property}
                  getOptionLabel={(option) => option.displayName} // Cambia esta línea
                  onChange={(e, va) => setPropertyChosen(va)}
                  options={options}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Seleccionar parámetro"
                      className="autocomplete-partida-input"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              )}

              {/* INDICAR EL PARAMETRO Y UNIDAD DE LA PARTIDA POR DEFECTO */}
              {formik.values.partida && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '.4rem', paddingTop: '1rem' }}>
                  {!changeParam ? (
                    <p onClick={() => setChangeParam(true)} className="change-param-unit">
                      Cambiar parametro y unidad
                    </p>
                  ) : (
                    <p
                      onClick={() => {
                        setChangeParam(false);
                        setPropertyChosen(null);
                      }}
                      className="change-param-unit"
                    >
                      Mantener parametro y unidad por defecto
                    </p>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                    <p className="title-param-unit">Parametro: </p>
                    <p className="param-unit">{paramValue}</p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                    <p className="title-param-unit">Unidad: </p>
                    <p className="param-unit">{unitValue}</p>
                  </div>
                </div>
              )}

              {/* PRECIO DE LA PARTIDA PROYECTO */}
              {formik.values.partida && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '.4rem', paddingTop: '1rem' }}>
                  <p onClick={() => setChangePrice(true)} className="change-param-unit">
                    Cambiar precio
                  </p>

                  <div style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                    <p className="title-param-unit">Precio: </p>
                    {!changePrice ? (
                      <p className="param-unit">{priceValue} €</p>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          onChange={(e) => setRecoverPrice(e.target.value)}
                          defaultValue={priceValue}
                          type="number"
                        />
                        <CancelIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setRecoverPrice(null);
                            setChangePrice(false);
                          }}
                        />
                        <CheckCircleIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setPriceValue(recoverPrice);
                            setChangePrice(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div style={{ paddingTop: '1rem', alignSelf: 'flex-end', gap: '.4rem', display: 'flex' }}>
                <Button
                  endIcon={loadingCreate && <CircularProgress size={20} style={{ color: 'white' }} />}
                  type="submit"
                  style={{
                    padding: '0.2rem 1rem',
                    borderRadius: '40px',
                    color: 'white',
                    backgroundColor: '#343536',
                    textTransform: 'none',
                  }}
                >
                  Crear
                </Button>
                <Button
                  style={{
                    padding: '0.2rem 1rem',
                    borderRadius: '40px',
                    backgroundColor: '#c82333',
                    color: 'white',
                    textTransform: 'none',
                  }}
                  type="button"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default NewPartida;
