import React, { useContext, useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ViewerContainer from '../Components2/Viewer/ViewerContainer';
import {
  createVersion,
  checkIfUrnHasGeometry,
  getProyectoByItem,
  getVersionByProjectAndNumberVersion,
  getVersionsByItem,
} from '../api/alcampoApi';
import { ViewerContext } from '../context/ViewerContext';
import { PointContext } from '../context/PointContext';
import { launchViewer } from '../helpers/viewerHelper';
import { useHandleUrl } from '../hooks/useHandleUrl';
import { NavbarVer } from '../Components2/Sidebar/NavbarVer';
import { useState } from 'react';
import { ProyectoContext } from '../context/ProyectoContext';
import { toast } from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import GlobalSpinner from '../Components2/common/GlobalSpinner';

const ViewerContentLayout = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentProject, setIsModelLoaded, isModelLoaded } = useContext(ProyectoContext);
  const queryClient = useQueryClient();

  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { idItem, version } = useHandleUrl();
  const { handleResetPoints, setPoints, selectedPoint, setSelectedPoint } = useContext(PointContext);
  const { setUniqueProperties, versions } = useContext(ViewerContext);

  useLayoutEffect(() => {
    console.log('useLayoutEffect');
    if (location && idItem && versions) {
      // TODO: Controlar si el item existe en mongo
      getAccessTokenSilently().then((token) => {
        getProyectoByItem(idItem, token)
          .then(async () => {
            // TODO: Comprobar si existe la versión en mongo.
            getVersionByProjectAndNumberVersion(
              currentProject._id,
              version || versions[0].attributes.versionNumber,
              token,
            )
              .then((res) => {
                console.log('res', res);
                console.log('versions', versions);
                // Si existe, comprobar si tiene en false la propiedad existsGeometry. Si es así, redirigir a la última versión
                if (res?.data?.data?.existsGeometry == false) {
                  toast.error(
                    'Este modelo no tiene ninguna geometría que pueda mostrar el visor. Redirigiendo a la última versión...',
                  );
                  setTimeout(() => {
                    return navigate({
                      pathname: location.pathname,
                    });
                  }, 3500);
                }

                // Error capturado de que no existe esa versión
                // TODO: Si no existe, llamar al endpoint de designdata, comprobar que tiene objetos y guardarlo en mongo.
                if (res?.name == 'AxiosError' && res?.response.status == 404) {
                  let urn;
                  if (!version) {
                    urn = versions[0];
                  } else {
                    urn = versions.find((item) => item.attributes.versionNumber == version);
                  }
                  console.log('urn', urn);
                  // * Si el modelo no tiene el número de versión pasada por params
                  // if (!urn) {
                  //   return navigate('/error');
                  // }

                  // Si el modelo tiene esa versión, ir a ver si ese modelo esta vacio y no contiene ninguna geometría
                  if (!urn?.relationships?.derivatives?.data?.id) {
                    createVersion(token, currentProject._id, urn.attributes.versionNumber, false).then((res) =>
                      queryClient.invalidateQueries(['available-versions', currentProject._id]),
                    );
                    toast.error(
                      'Este modelo no tiene ninguna geometría que pueda mostrar el visor. Redirigiendo a la última versión...',
                    );
                    setTimeout(() => {
                      return navigate({
                        pathname: location.pathname,
                      });
                    }, 3500);
                    return;
                  }

                  checkIfUrnHasGeometry(urn.relationships.derivatives.data.id, token).then((res) => {
                    // Status(200) el modelo tiene elementos
                    if (res.status === 200) {
                      createVersion(token, currentProject._id, urn.attributes.versionNumber).then((res) =>
                        queryClient.invalidateQueries(['available-versions', currentProject._id]),
                      );
                    }

                    // Status(204) el modelo no tiene elementos
                    if (res.status == 204) {
                      console.log('entra createversion');
                      createVersion(token, currentProject._id, urn.attributes.versionNumber, false).then((res) =>
                        queryClient.invalidateQueries(['available-versions', currentProject._id]),
                      );
                      toast.error(
                        'Este modelo no tiene ninguna geometría que pueda mostrar el visor. Redirigiendo a la última versión...',
                      );
                      setTimeout(() => {
                        return navigate({
                          pathname: location.pathname,
                        });
                      }, 3500);
                      return;
                    }
                  });
                }
              })
              .catch((e) => console.log(e));
            // return;
            // TODO: Si existe y esa versión no tiene modelo, hacer algo.

            // TODO: Si no existe o si tiene versión. TODO A PARTIR DE AQUÍ

            // TODO: Controlar si la versión pasada no existe.
            // TODO: Controlar si el item no existe

            getVersionsByItem(token, idItem, version)
              .then(async (res) => {
                const handledUrn = window.btoa(res.data.id).replace(/=/g, '');
                launchViewer(
                  handledUrn,
                  '002',
                  setIsModelLoaded,
                  handleResetPoints,
                  setPoints,
                  setSelectedPoint,
                  selectedPoint,
                  setUniqueProperties,
                  token,
                );
              })
              .catch((e) => navigate('/error'));
          })
          .catch((e) => navigate('/error'));
      });
    }
  }, [version, idItem, versions, version]);

  return (
    <div className="app-layout">
      <div className="menu-layout">
        <NavbarVer />
      </div>
      <div className="contenido-layout">{/* <CapitulosPartidasProyecto openModal={() => setOpenModal(true)} /> */}</div>
      <div className="viewer-layout">
        <ViewerContainer />
      </div>

      {!isModelLoaded && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 99999999,
            position: 'fixed', // Cambiado a 'fixed' para que funcione correctamente
            top: 0,
            left: 0,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '.4rem' }}>
            <GlobalSpinner />
            <h2 style={{ color: 'white' }}>Cargando datos de Alcampo...</h2>
          </div>
        </div>
      )}

      <Outlet />
    </div>
  );
};

export default ViewerContentLayout;
