import { useLocation, useNavigate } from 'react-router-dom';
import { OneRowArte } from './OneRowArte';

export const TableArtes = ({ data, centerTypes }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleRowClick = (row) => {
    const path = location.pathname.endsWith('/') ? location.pathname : `${location.pathname}/`;
    navigate(`${path}${row._id}`);
  };

  return (
    <div className="grid-container">
      {data?.data.map((row, index) => (
        <div className="grid-item" key={row._id + index}>
          <OneRowArte row={row} handleRowClick={handleRowClick} centerTypes={centerTypes} />
        </div>
      ))}
    </div>
  );
};
