import { CircularProgress } from '@mui/material';
import React from 'react';

const Loader = ({ className, size = 18 }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }} className={className && className}>
      <CircularProgress color="primary" size={size} className="circular-progress-custom" />
    </div>
  );
};

export default Loader;
