import React, { useContext } from 'react';
import { Button, Card, Spinner } from 'reactstrap';
import { ProyectoContext } from '../../context/ProyectoContext';
import ExpandedIcon from '../common/ExpandedIcon';
import { CapitulosContext } from '../../context/CapitulosContext';
import CapituloTreeItem from './CapitulosTreeItem';
import { useQuery } from '@tanstack/react-query';
import { getCapitulosByProject } from '../../api/alcampoApi';
import { CircularProgress } from '@mui/material';
import { PartidasContext } from '../../context/PartidasContext';
import Viewer from '../../helpers/Viewer/Viewer';
import { useAuth0 } from '@auth0/auth0-react';

const ListCapitulosProyecto = ({ openModal }) => {
  const { currentCapitulosProject, currentProject } = useContext(ProyectoContext);
  const { header, setHeader, setCapituloSelected, capituloSelected, codesActive } = useContext(CapitulosContext);
  const { setPartidaQuery, partidaQuery } = useContext(PartidasContext);
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching } = useQuery(
    ['capitulosProyecto', currentProject?._id, capituloSelected?._id || 'all'],
    () => {
      return getAccessTokenSilently().then((token) => {
        return getCapitulosByProject(token, currentProject._id, capituloSelected);
      });
    },
    {
      enabled: !!currentProject?._id,
    },
  );
  const handlePartidas = async (id) => {
    // const viewerClass = new Viewer(currentProject?._id);

    if (id) {
      // traer todos los codes de todas las partidas que hay dentro para usarlos en una funcion
      // que me permita filtrar el modelo por code
      // viewerClass.getDbIdsByCodes(codesActive);
      setPartidaQuery({ ...partidaQuery, capituloSelected: id });
    } else {
      const { capituloSelected, ...remainingPartidaQuery } = partidaQuery;
      setPartidaQuery(remainingPartidaQuery);
    }
  };

  const handleHeaders = (headers) => {
    if (header.length == 0) {
      setHeader([
        { _id: null, title: 'todos los capítulos' },
        {
          ...headers,
          title: `${headers.capitulo.name}`,
        },
      ]);
    } else {
      setHeader((oldHeader) => [
        ...oldHeader,
        {
          ...headers,
          title: `${headers.capitulo.name}`,
        },
      ]);
    }
    setCapituloSelected({ _id: headers._id, parentCapituloExists: true });
    handlePartidas(headers._id);
  };

  const handleReturnHeader = (item, i) => {
    // const viewerClass = new Viewer(currentProject?._id);
    if (i == 0) {
      // viewerClass.getDbIdsByCodes(codesActive);
      setHeader([]);
      setCapituloSelected({});
    } else {
      setHeader((oldHeader) => oldHeader.slice(0, i + 1));
      setCapituloSelected({ _id: item?._id, parentCapituloExists: true });
    }
    // setCapituloSelectedQuery({_id: item?._id, parentCapituloExists: true})
    handlePartidas(item?._id);
  };
  return (
    <>
      <Card
        style={{
          width: '100%',
          // height: '100%',
          alignItems: 'flex-start',
          overflowY: 'auto',
        }}
        className="filters-info-container"
      >
        <div className="container-capitulos-project">
          <Button onClick={openModal} color="none" className="button-new-partida">
            Crear una nueva partida
          </Button>
          {/* <div style={{ display: "flex" }}>
            {!!capituloSelectedQuery?._id && (
              <ArrowBackIcon onClick={goBackCapitulos} className="pointer" />
            )}{" "}
            <p className="roboto-condensed" style={{ fontWeight: "bold" }}>
              Capítulos
            </p>
          </div> */}

          {/* HEADER PARA VOLVER ATRAS */}
          {header.map((item, i) =>
            i < header.length - 1 ? (
              <div style={{ fontSize: '14px' }} key={i} className="capitulo-tree-header-container">
                <ExpandedIcon />
                <p key={i} onClick={() => handleReturnHeader(item, i)} className="capitulo-tree-header--item-global">
                  Volver a {item?.title}
                </p>
              </div>
            ) : (
              <p key={i} className="capitulo-tree-header--item-text">
                {item?.title}
              </p>
            ),
          )}

          {isFetching || !currentProject?._id ? (
            <div className="container-spinner-capitulos-list">
              <CircularProgress style={{ color: 'black' }} size={20} />
            </div>
          ) : header.length === 0 ? (
            <div className="list-capitulos">
              {data
                .sort((a, b) => (a.capitulo.code > b.capitulo.code ? 1 : -1))
                .map((capitulo, i) => (
                  <CapituloTreeItem
                    key={i}
                    item={capitulo}
                    handleHeaders={handleHeaders}
                    last={i === data.length - 1}
                    header={header}
                  />
                ))}
            </div>
          ) : (
            data[0]?.subCapitulos
              .sort((a, b) => (a.capitulo.code > b.capitulo.code ? 1 : -1))
              .map((capitulo, i) => (
                <CapituloTreeItem
                  key={i}
                  item={capitulo}
                  handleHeaders={handleHeaders}
                  header={header}
                  last={i === data[0]?.subCapitulos.length - 1}
                />
              ))
          )}
        </div>
      </Card>
    </>
  );
};

export default ListCapitulosProyecto;
