import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getQuantityVersionsByPartidaProyecto } from '../api/alcampoApi';
import { useAuth0 } from '@auth0/auth0-react';

// contexto
export const PartidaProyectoContext = createContext();

//provider : lo que envuelve a los componentes donde vamos a usar el contexto

export const PartidaProyectoProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { idPartidaProyecto } = useParams();

  const { data: quantityVersions } = useQuery(['quantity-versions-partida', idPartidaProyecto], () => {
    return getAccessTokenSilently().then((token) => getQuantityVersionsByPartidaProyecto(idPartidaProyecto, token),
    );
  }
  );
  console.log('quantities sin modificar', quantityVersions);

  // const [quantityVersionsByPartida, setQuantityVersionsByPartida] = useState();

  // useEffect(() => {
  //   if (!quantityVersions) return;
  //   quantityVersions.sort((a, b) => a.version - b.version);
  //   // quantityVersions.sort((a, b) => b.version - a.version);
  //   console.log('Data from Context', quantityVersions);
  //   setQuantityVersionsByPartida(quantityVersions);
  // }, [quantityVersions]);

  // const data = { quantityVersionsByPartida, setQuantityVersionsByPartida };
  const data = { quantityVersions };
  return <PartidaProyectoContext.Provider value={data}>{children}</PartidaProyectoContext.Provider>;
};
