import { useContext, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { PartidaProyectoContext } from '../../context/PartidaProyectoContext';
import { ViewerContext } from '../../context/ViewerContext';

const GraficoQuantityVersion = () => {
  const [dataGrafica, setDataGrafica] = useState([]);
  const { quantityVersions } = useContext(PartidaProyectoContext);

  useEffect(() => {
    console.log('quantityVersions', quantityVersions);
  }, [quantityVersions]);

  useEffect(() => {
    if (!quantityVersions) return;

    const objetoConLaVersionMasAlta = quantityVersions.reduce((objetoActual, objetoSiguiente) => {
      return objetoActual.version.version > objetoSiguiente.version.version ? objetoActual : objetoSiguiente;
    }, quantityVersions[0]); // con esto indico que iniciamos con el primer objeto

    console.log('objetoConLaVersionMasAlta', objetoConLaVersionMasAlta.version.version);
    // CreO un ARRAY con todas las versiones del rango 1-N
    const versionArray = Array.from({ length: objetoConLaVersionMasAlta.version.version }, (_, i) => i + 1);

    console.log('versionArray', versionArray);

    // Mapear la información de quantityVersions a dataGrafica
    const infoGrafica = versionArray.map((version) => {
      const dataItem = quantityVersions.find((el) => el.version.version === version);
      return {
        cantidad: dataItem ? dataItem.quantity : 0,
        version,
      };
    });
    console.log('infoGrafica from graficComponente: ', infoGrafica);
    setDataGrafica(infoGrafica);
  }, [quantityVersions]);

  return (
    <div>
      <ResponsiveContainer width="100%" height={270}>
        <LineChart data={dataGrafica} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="version"
            label={{ value: 'Version', position: 'insideBottom', offset: -4, fontSize: 14 }}
            domain={[1, 19]} // Aquí establezco  el dominio fijo
          />
          <YAxis label={{ value: 'Cantidad', angle: -90, position: 'insideLeft', fontSize: 14 }} />
          <Tooltip />
          <Line type="monotone" dataKey="cantidad" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GraficoQuantityVersion;
