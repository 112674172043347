import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { editCenter, getOneCenter } from '../../../api/carteleriaApi';
import { useParams } from 'react-router-dom';
import { useQueryClient, useQuery } from '@tanstack/react-query';

function useEditCenter() {
  const { getAccessTokenSilently } = useAuth0();
  const { centerId } = useParams();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ['center', centerId],
    async () => {
      const token = await getAccessTokenSilently();
      return await getOneCenter(token, centerId);
    },
    { enabled: !!centerId },
  );

  const { mutate: editingCenter } = useMutation({
    mutationFn: async (body) => {
      const token = await getAccessTokenSilently();
      return await editCenter(token, body, centerId);
    },
    onSuccess: () => {
      toast.success('Se ha actualizado el centro con éxito.');
      queryClient.invalidateQueries(['allCenters']);
    },
    onError: () => {
      toast.error('Se ha producido un error al actualizar el centro.');
    },
  });

  const center = data?.data;

  return { editingCenter, center, isLoading };
}

export default useEditCenter;
